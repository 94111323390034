import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, } from '@mui/material'
import { useState } from "react";
import { Props } from "./Custumer.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './Custumer_styles.css'
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { AddCircle } from "@mui/icons-material";
import AddCircleIcon from '@mui/icons-material/AddCircle';

interface Column {
   id: 'numero' | 'codigoProducto' | 'codigoAlternativo';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: 'numero', label: 'Número', minWidth: 100 },
   { id: 'codigoProducto', label: 'Código Producto', minWidth: 100 },
   { id: 'codigoAlternativo', label: 'Código Alternativo', minWidth: 100 },
];

interface Data {
   numero: number;
   codigoProducto: string;
   codigoAlternativo: string;
}

function createDataClienteProducts(
   numero: number,
   codigoProducto: string,
   codigoAlternativo: string,
): Data {
   return { numero, codigoProducto, codigoAlternativo };
}

const rowsDataClienteProducts = [
   createDataClienteProducts(1, 'c001', 'h4001'),
   createDataClienteProducts(2, 'c002', 'h4002'),
   createDataClienteProducts(3, 'c003', 'h4003'),
   createDataClienteProducts(4, 'c004', 'h4004'),
   createDataClienteProducts(5, 'c005', 'h4005'),
]


export const DataModalCustumerProducts: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [ejecutivoComercial, setEjecutivodComercial] = useState('');
   const [estado, setEstado] = useState('');

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   return (
         <Container maxWidth='xl'>
            <Grid container spacing={3}>
            <Grid item xs={12}>
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        CLIENTE - PRODUCTOS
                     </Typography>
                  </Box>
                  
            </Grid>
                     
              

               <Grid item xs={12} sm={4}>
                  <TextField
                     label="Ingrees producto"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={4}>
                  <TextField
                     label="Codigo secundario"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

            

               <Grid item 
                  xs={4}
                  // mt={1}
                  justifyContent='center'
                  
               >
                  <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        // onClick={handleSubmit}
                        // disabled={loading}
                        disableElevation
                        className='btn_save'
                     >
                        <span>Ingresar</span>
                     </Button>
                  </Grid>
               </Grid>
            </Grid>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
               <TableContainer sx={{ maxHeight: 440 }} >
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           <TableCell>Numero</TableCell>
                           <TableCell>Codigo Producto</TableCell>
                           <TableCell>Codigo Alternativo</TableCell>
                           <TableCell>Opciones</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsDataClienteProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.numero}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                    <IconButton
                                    >
                                       <EditIcon />
                                    </IconButton>
                                 </TableCell>
                              </TableRow>
                           );
                        })}

                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsDataClienteProducts.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>

         </Container>
   );
};

