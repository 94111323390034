import React, { useState, useEffect, FunctionComponent, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import { Alert, Backdrop, Button, CircularProgress, FormControl, Grid, Input,MenuItem, Snackbar, TableContainer, Typography } from '@mui/material';
import { saleService } from '@service/services/Sale.service';
import { useReactToPrint } from 'react-to-print';
import { ComponentToPrint } from './SeccionReporte';
//import "./GeneratorTicket.sass";
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_PARMS, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { Protected } from '@/components/layout/Protected';
import { FormatDouble,moneyFormat, moneyFormatInt } from '@/toolbox/helpers/money.helper'
import { dateFormat, dateFormatTime } from '@/toolbox/helpers/date.helper'
import RefreshIcon from '@mui/icons-material/Refresh';
import { useMediaQuery, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import PeopleIcon from '@mui/icons-material/People';
import { Link } from 'react-router-dom';
import { ROUTE_PROVIDER_CREATE } from '@/toolbox/constants/route-map';

export const Provider: FunctionComponent = (props: any) => {

   const [data, setData] = React.useState('');

   const handleChange = (event: any) => {
     setData(event.target.value as string);
   };


   return (
      <Protected>
         <div className='spacing' style={{height:'100vh'}}>
            <Grid container>
            <Typography
                  variant="h3"
                  className='tittle'
                  sx={{ textTransform: 'uppercase'}}
                  >Proveedores</Typography> 
            
               <Grid container mt={2}>
                  <Grid item sm={3} xs={12} className='center'>
                     <Button  className='btnData' component={Link} to={ROUTE_PROVIDER_CREATE} variant="contained" endIcon={<AddIcon/>}>
                     Agregar
                     </Button>
                  </Grid>
                  <Grid item sm={6} xs={12} className='center'>
                  <Paper
                     component="form"
                     sx={{ p: '1px 2px', display: 'flex', alignItems: 'center', width: '100%'}}
                  >
                     <InputBase
                     sx={{ ml: 1, flex: 1 }}
                     placeholder="Buscar"
                     inputProps={{ 'aria-label': 'Buscar' }}
                     />
                     <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon />
                     </IconButton>
                  </Paper>
                  </Grid>
                  <Grid item sm={3} xs={12} className='center'>
                  <FormControl fullWidth sx={{maxWidth: 200 }}>
                     <InputLabel id="demo-simple-select-helper-label">Ordenar</InputLabel>
                        <Select
                           labelId="demo-simple-select-helper-label"
                           id="demo-simple-select-helper"
                           value={data}
                           label="Age"
                           onChange={handleChange}
                        >
                           <MenuItem value={1}>Ascendente</MenuItem>
                           <MenuItem value={2}>Descendente</MenuItem>                                 </Select>
                  </FormControl>
                  </Grid>
               </Grid>

               <Grid container mt={2}>
                  <Grid item xs={12}>
                     <Box mt={1} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <FormControl fullWidth >
                                       <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                             <TableContainer sx={{ maxHeight: 540 }}>
                                             <Table stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                   <TableRow>
                                                      <TableCell>ID</TableCell>
                                                      <TableCell>Rut</TableCell>
                                                      <TableCell>Razon Social</TableCell>
                                                      <TableCell>Propietario</TableCell>
                                                      <TableCell>Direccion</TableCell>
                                                      <TableCell>Cargo</TableCell>
                                                      <TableCell>Nombre Contacto</TableCell>
                                                      <TableCell>Acciones</TableCell>
                                                   </TableRow>
                                                </TableHead>
                     
                                                <TableBody>
                                                   <TableRow >  
                                                      <TableCell>
                                                         1
                                                      </TableCell>

                                                      <TableCell>
                                                         22222222-2
                                                      </TableCell>
                                                               
                                                      <TableCell>
                                                         Innapsis SAC
                                                      </TableCell>

                                                      <TableCell>
                                                         10751110974
                                                      </TableCell>

                                                      <TableCell>
                                                         Av. Los pinos
                                                      </TableCell>

                                                      <TableCell>
                                                         Gerente General
                                                      </TableCell>

                                                      <TableCell>
                                                         Felipe Videla L.
                                                      </TableCell>
      
                                                      <TableCell>
                                                         <div className='sepaEle'>
                                                            <button className='btnIcoE'><RemoveRedEyeIcon/></button>
                                                            <button className='btnIcoD'><EditIcon /></button>
                                                            <button className='btnIcoD'><PeopleIcon /></button>
                                                            <button className='btnIcoD'><DeleteIcon /></button>
                                                         </div>
                                                      </TableCell>
                                                   </TableRow>
                                                   
                                                </TableBody>
                                             </Table>
                                             </TableContainer>
                                             
                                       </Paper>
                                    </FormControl>
                     </Box>
                  </Grid>                 
               </Grid>
            </Grid>
         </div>
      </Protected>

   )
}

