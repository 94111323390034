import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "./TradeBloackManagement.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './TradeBloackManagement_styles.css'
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import Modal from '@mui/material/Modal';
import { DataModal } from "./DataModal";
import { DataRut } from "./DataRut";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import SearchIcon from '@mui/icons-material/Search';
import { LoadingButton } from "@mui/lab";

interface Column {
   id: 'numero' | 'rut' | 'ultimoIngreso'|'UltimoMovimiento' | 'Nbloqueos'|'Estado'| 'Ctacte' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

const columns:readonly Column[] = [
   { id: 'numero', label: 'N°', minWidth: 10 },
   { id: 'rut', label: 'Rut', minWidth: 10 },
   { id: 'ultimoIngreso', label: 'Ultimo Ingreso', minWidth: 10 },
   { id: 'UltimoMovimiento', label: 'Ultimo Movimiento', minWidth: 10 },
   { id: 'Nbloqueos', label: 'N° Bloqueos', minWidth: 10 },
   { id: 'Estado', label: 'Estado', minWidth: 10 },
   { id: 'Ctacte', label: 'Ctacte', minWidth: 10 },
   ];

interface Data {
   numero: number;
   rut: string;
   ultimoIngreso: string;
   UltimoMovimiento: string;
   Nbloqueos: number;
   Estado: string;
   Ctacte: string;
}

function createData(
   numero: number,
   rut: string,
   ultimoIngreso: string,
   UltimoMovimiento: string,
   Nbloqueos: number,
   Estado: string,
   Ctacte: string,
   ):Data {
   return { numero, rut, ultimoIngreso, UltimoMovimiento, Nbloqueos, Estado, Ctacte };
}

const rowsData = [
   createData(1, '123456789', '12/12/12', 'CLIENTE CON DEUDA', 1, 'Activo', '123456789'),
   createData(2, '123456789', '12/12/12', 'CLIENTE CON DEUDA', 1, 'Activo', '123456789'),
   createData(3, '123456789', '12/12/12', 'CLIENTE CON DEUDA', 1, 'Activo', '123456789'),
   createData(4, '123456789', '12/12/12', 'CLIENTE CON DEUDA', 1, 'Activo', '123456789'),
   createData(5, '123456789', '12/12/12', 'CLIENTE CON DEUDA', 1, 'Activo', '123456789'),
]


export const TradeBloackManagement:React.FC<Props> = (props: Props): JSX.Element => {
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   // const [value, setValue] = useState('');
   const [value, setValue] = useState<Date | null>(new Date());
   const [loading, setLoading] = useState(false);
   const [pais, setPais] = useState('');
   const [estado, setEstado]= useState('');
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);
   const [open, setOpen] = useState(false);
   const [openRut, setOpenRut] = useState(false);
   const handleOpenRut = () => setOpenRut(true);
   const handleCloseRut = () => setOpenRut(false);

    const handleChangePais = (event: SelectChangeEvent) => {
      setPais(event.target.value);
    };
    const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
    };
   function handleClick() {
     setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const paises= [
      { value:1, label:'Chile'},
      { value:2, label:'Argentina'},
      { value:3, label:'Brasil'},
      { value:4, label:'Colombia'},
      { value:5, label:'Peru'},
      { value:6, label:'Venezuela'},
   ];
   const estados= [
      { value: '1', label: 'Activo' },
      { value: '2', label: 'Bloqueo Comercial' },
   ]

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 720,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

    const motivosBloqueos = [
      { value: '1', label: 'Cliente Activo' },
      { value: '2', label: 'Cliente con Deuda' },
      { value: '3', label: 'Cliente Inactivo' },
      { value: '4', label: 'Cliente Quiebra' },
      { value: '5', label: 'Morosos' },
      { value: '6', label: 'Problematicos' },
    ]
    return (
        <Protected>
        <Container maxWidth='xl'>
           <Grid container spacing={3}>
               <Grid item xs={12} md={6} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        GESTION DE BLOQUEO COMERCIAL
                     </Typography>
                  </Box>
                  <Box>
                     {/* <Typography variant='subtitle2'  gutterBottom >
                     Resumen de mes de 1 - 2022
                     </Typography> */}
                  </Box>
                  
               </Grid>
                     
               <Grid item xs={12} md={6} className="btn-download">
                  <Box pb={2}>
                  <LoadingButton
                     color="warning"
                     // onClick={handleOpenModal}
                     // loading={loading}
                     // loadingPosition="start"
                     // startIcon={<FileDownloadIcon />}
                     variant="contained"
                     className="btn-download2"
                     >
                     IMPRIMIR
                     </LoadingButton>
                  </Box>
               </Grid>
                     
               <Grid item xs={12} sm={3} display="flex" justifyContent="space-evenly">
                  <TextField
                     label="Rut del Cliente"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
                  <IconButton>
                     <SearchIcon 
                        onClick={handleOpenRut}
                     />
                  </IconButton>
               </Grid>


               <Grid item xs={12} sm={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Stack spacing={3}>
                                 <DatePicker
                                       // views={['year', 'month']}
                                       label="Fecha de Bloqueo"
                                       // minDate={new Date('2012-03-01')}
                                       // maxDate={new Date('2023-06-01')}
                                       value={value}
                                       onChange={(newValue) => {
                                          setValue(newValue);
                                       }}
                                       renderInput={(params) => <TextField  {...params} helperText={null} />}
                                       />
                           </Stack>
                  </LocalizationProvider>
               </Grid>

               <Grid item xs={12} sm={3}>
                  <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={motivosBloqueos}
                           renderInput={(params) => <TextField {...params} label="Motivo Bloqueo" />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <TextField
                     id="filled-read-only-input"
                     label="Usuario Solicitante"
                     defaultValue="12345678-9"
                     InputProps={{
                          readOnly: true,
                     }}
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={6}>
                  <TextField
                     label="Condicion para Desbloquear"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={6}>
                  <TextField
                     label="Observacion"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

            
               <Grid item container
                     xs={12}
                     mt={2}
                  justifyContent='right'
                  >
                        <Grid item>
                           <Button
                              type='submit'
                              variant='contained'
                              // onClick={handleSubmit}
                              // disabled={loading}
                              disableElevation
                              className='btn_save'
                           >
                                    <span>Agregar</span>
                           </Button>
                        </Grid>
               </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={2}>
               LISTADO DE CLIENTES CON ESTADO BLOQUEADO
            </Typography>

            <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:440}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {columns.map((column) => (
                              <TableCell
                              
                                 key={column.id}
                                 align={column.align}
                                 style={{ minWidth: column.minWidth }}
                              >
                                 {column.label}
                              </TableCell>
                           ))}
                           <TableCell>
                              Historial
                           </TableCell>

                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.numero}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                       <IconButton 
                                          >
                                             <SearchIcon 
                                          onClick={handleOpen}
                                       />
                                       </IconButton>
                                 </TableCell>
                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
            </Paper>

         </Container>
            <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <DataModal entity={undefined}/>
                  </Box>
            </Modal>

            <Modal
                  open={openRut}
                  onClose={handleCloseRut}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <DataRut entity={undefined}/>
                  </Box>
            </Modal>
     </Protected>
    );
   };

