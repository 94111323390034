import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "./QuoteBook.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './QuoteBook_styles.css'
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import Modal from '@mui/material/Modal';
import { DataEmail } from "./Modal/DataEmail";
import { DataRut } from "./Modal/DataRut";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import SearchIcon from '@mui/icons-material/Search';
import { LoadingButton } from "@mui/lab";
import { TableResumen } from "./TableResumen";
import BookIcon from '@mui/icons-material/Book';
import { Book, Equalizer, ListAlt } from "@mui/icons-material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ROUTE_EV_QUOTE_BOOKS,ROUTE_EV_EMITION_QUOTE } from "@/toolbox/constants/route-map";
import EqualizerIcon from '@mui/icons-material/Equalizer';
import ListAltIcon from '@mui/icons-material/ListAlt';
import EmailIcon from '@mui/icons-material/Email';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import ArticleIcon from '@mui/icons-material/Article';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import { DataHistorico } from "./Modal/DataHistorico";
import { DataCotizacion } from "./Modal/DataCotizacion";
import { DataCotizacionState } from "./Modal/DataCotizacionState";
import { DataCotizacionResumen } from "./Modal/DataCotizacionResumen";
import { DataCotizacionGrafico } from "./Modal/DataCotizacionGrafico";
import { DataInformeCotizacion } from "./Modal/DataInformeCotizacion";
import Link from '@mui/material/Link';

export const QuoteConfirmEmition:React.FC<Props> = (props: Props): JSX.Element => {
 
    return (
        <Protected>
        <Container maxWidth='xl'>
           <Grid container spacing={1}>
                  <Grid item xs={12} md={12} >
                     <Box>
                        <Typography variant='h6' component='h1' gutterBottom className="tittle">
                           CONFIRMACION DE EMISION
                        </Typography>
                     </Box>
                  </Grid>

                  <Grid item xs={12} md={12} >
                     <Box>
                        <Typography variant='h6' style={{background:'#FF9700', color:'#ffffff'}} component='h1' gutterBottom align="center">
                        Documento Ingresado Correctamente: Folio nº 866
                        </Typography>
                     </Box>
                  </Grid>

                  <Grid item xs={12} md={4} >
                     <Typography variant='subtitle2' gutterBottom >
                        Opciones de Visualizacion
                     </Typography>
                     <Link className="link" href="#"> Visualizar Documento N°866</Link>
                     <br></br>
                     <Link className="link" href="#"> Documento Libro</Link>
                  </Grid>
                  <Grid item xs={12} md={4} >
                     <Typography variant='subtitle2' gutterBottom >
                     Opciones emisión nuevo docuento
                     </Typography>
                     <Link className="link" href="#"> Cotizacion</Link>
                     <br></br>
                     <Link className="link" href="#"> Documento Electrónico</Link>
                  </Grid>
                  <Grid item xs={12} md={4} >
                     <Typography variant='subtitle2' gutterBottom >
                     Opcion imprimir
                     </Typography>
                     <Link className="link" href="#">Imprimir Formato Térmico</Link>
                     {/* <Link href="#"> Documento Libro</Link> */}
                  </Grid>
                
            </Grid>
         </Container>
     </Protected>
    );
   };

