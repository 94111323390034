import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "./GcBusinessArea.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './GcBusinessArea_styles.css'
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


interface Column {
   id: 'numero' | 'nombre' | 'descripcion' | 'cuentaContable' | 'estado';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

const columns:readonly Column[] = [
   { id: 'numero', label: 'Número', minWidth: 100 },
   { id: 'nombre', label: 'Nombre', minWidth: 100 },
   { id: 'descripcion', label: 'Descripción', minWidth: 100 },
   { id: 'cuentaContable', label: 'Cuenta Contable', minWidth: 100 },
   { id: 'estado', label: 'Estado', minWidth: 100 },
   ];

interface Data {
   numero: number;
   nombre: string;
   descripcion: string;
   cuentaContable: string;
   estado: string;
}

function createDataBusiness(
   numero: number,
   nombre: string,
   descripcion: string,
   cuentaContable: string,
   estado: string,
   ):Data {
   return { numero, nombre, descripcion, cuentaContable, estado };
}

const rowsDataBusiness = [
   createDataBusiness(1, 'Gestión de Clientes', 'categorizada pruebas', '123456','ACTIVO'),
   createDataBusiness(2, 'Gestión de Proveedores', 'categorizada pruebas', '123456','ACTIVO'),
   createDataBusiness(3, 'Gestión de Productos', 'categorizada pruebas', '123456','ACTIVO'),
   createDataBusiness(4, 'Gestión de Servicios', 'categorizada pruebas', '123456','ACTIVO'),
   createDataBusiness(5, 'Gestión de Contratos', 'categorizada pruebas', '123456','ACTIVO'),
   createDataBusiness(6, 'Gestión de Facturas', 'categorizada pruebas', '123456','ACTIVO'),
   createDataBusiness(7, 'Gestión de Compras', 'categorizada pruebas', '123456','ACTIVO'),
]


export const GcBusinessArea:React.FC<Props> = (props: Props): JSX.Element => {
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [contable, setContable] = useState('');
   const [costo, setCosto]= useState('');
   const [dataVenta, setVenta]= useState('');
   const [estado, setEstado]= useState('');


   const handleChangeContable = (event: SelectChangeEvent) => {
      setContable(event.target.value);
    };

    const handleChangeCosto = (event: SelectChangeEvent) => {
      setCosto(event.target.value);
    };
    const handleChangeVenta = (event: SelectChangeEvent) => {
      setVenta(event.target.value);
    };
    const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
    };
   function handleClick() {
     setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const contables= [
      { value: '', label: 'Ventas' },
      { value: '1', label: 'Ventas Intergrade' },
      { value: '2', label: 'Ingresos por ventas y servicios' },
      { value: '3', label: 'Ingresos por venta de bienes' },
      { value: '4', label: 'Correcion monetaria activos circulante' },
      { value: '5', label: 'Correcion monetaria activos fijos' },
      { value: '6', label: 'Correcion monetaria otros activos' },
      { value: '6', label: 'Ingresos cta cuadre' },

   ];
   const estados= [
      { value: '1', label: 'Activo' },
      { value: '2', label: 'Inactivo' },
   ]

   const costos= [
      { value: '1', label: 'Operaciones' },
      { value: '2', label: 'Operaciones2' },
      { value: '3', label: 'Operaciones3' },
      { value: '4', label: 'Operaciones4' },
   ];
   const ventas= [
      { value: '1', label: 'De Giro' },
      { value: '2', label: 'Ventas que no son del giro' },
      { value: '3', label: 'Ventas de bienes inmuebles' },
   ];

    return (
        <Protected>
        <Container maxWidth='xl'>
           <Grid container spacing={3}>
               <Grid item xs={12} md={6} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        MANTENEDOR DE ÁREAS DE NEGOCIOS 
                     </Typography>
                  </Box>
                  
               </Grid>
                     
               <Grid item xs={12} md={6} className="btn-download">
                  <Box pb={2}>
                  <LoadingButton
                     color="warning"
                     onClick={handleClick}
                     loading={loading}
                     loadingPosition="start"
                     startIcon={<FileDownloadIcon />}
                     variant="contained"
                     className="btn-download2"
                     >
                     Exportar productos
                     </LoadingButton>
                  </Box>
               </Grid>

               <Grid item xs={12} sm={3} mt={1}>
                  <TextField
                     label="Nombre"
                     id="outlined-size-small"
                     size="small"
                     fullWidth 
                  />
               </Grid>
               <Grid item xs={12} sm={3} mt={1}>
                  <TextField
                     label="Descripción"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <FormControl>
                     <FormLabel id="demo-row-radio-buttons-group-label">Area por Defecto</FormLabel>
                        <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultValue="activo"
                        >
                           <FormControlLabel value="activo" control={<Radio />} label="Activo" />
                           <FormControlLabel value="inactivo" control={<Radio />} label="Inactivo" />
                        </RadioGroup>
                  </FormControl>
               </Grid>
               <Grid item xs={12} sm={3}>
               <FormControl>
                     <FormLabel id="demo-row-radio-buttons-group-label">Area Woopy</FormLabel>
                        <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultValue="activo"
                        >
                           <FormControlLabel value="activo" control={<Radio />} label="Activo" />
                           <FormControlLabel value="inactivo" control={<Radio />} label="Inactivo" />
                        </RadioGroup>
                  </FormControl>
               </Grid>
         
               <Grid item xs={12} sm={3}>
               <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={contables}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Cuenta Contable" />}
                     />
               </Grid>
               
               <Grid item xs={12} sm={3}>
               <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={costos}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Centro Costo" />}
                     />
               </Grid>

               <Grid item xs={12} sm={3}>
               <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={ventas}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Categorización Venta" />}
                     />
               </Grid>

               <Grid item xs={12} sm={3}>
               <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={estados}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Estado" />}
                     />
               </Grid>
            </Grid>       
            <Grid item container
                  xs={12}
                  mt={2}
                 justifyContent='right'
                 >
                     <Grid item>
                        <Button
                           type='submit'
                           variant='contained'
                           // onClick={handleSubmit}
                           // disabled={loading}
                           disableElevation
                           className='btn_save'
                        >
                                 <span>Agregar</span>
                        </Button>
                     </Grid>
               </Grid> 
            <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={2}>
               LISTADO DE REGISTRO INGRESADO
            </Typography>

            <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:440}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           <TableCell>N</TableCell>
                           <TableCell>Nombre</TableCell>
                           <TableCell>Descripcion</TableCell>
                           <TableCell>Cuenta Contable</TableCell>
                           <TableCell>Estado</TableCell>
                           <TableCell>Opcion</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsDataBusiness.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.numero}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                       <IconButton
                                          >
                                             <EditIcon />
                                       </IconButton>
         
                                 </TableCell>
                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsDataBusiness.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
            </Paper>

         </Container>
     </Protected>
    );
   };

