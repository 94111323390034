import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, } from '@mui/material';
import {  ArrowRightIcon } from "@toolbox/constants/icons";
import { Props } from './FormComercial.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import './style.sass'
import "./FormComercial_style.css";
import HomeIcon from '@mui/icons-material/Home';
import SellIcon from '@mui/icons-material/Sell';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

export const FormComercial: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);

   const formas_pagos = [
      { value: '1', label: 'Efectivo' },
      { value: '2', label: 'Cheque' },
      { value: '3', label: 'Transferencia' },
      { value: '4', label: 'Tarjeta de crédito' },
      { value: '5', label: 'Tarjeta de débito' },
      { value: '6', label: 'Tarjeta de crédito y débito' },
   ]

   const vendedores = [
      { value: '1', label: 'Juan Perez' },
      { value: '2', label: 'Pedro Perez' },
      { value: '3', label: 'Juan Perez' },
      { value: '4', label: 'Pedro Perez' },
      { value: '5', label: 'Juan Perez' },
      { value: '6', label: 'Pedro Perez' },
   ]
   const segmentaciones=[
      { value: '1', label: 'Segmentación 1' },
      { value: '2', label: 'Segmentación 2' },
      { value: '3', label: 'Segmentación 3' },
      { value: '4', label: 'Segmentación 4' },
      { value: '5', label: 'Segmentación 5' },
   ]
   
   const entregas=[
      { value: '1', label: 'Auto' },
      { value: '2', label: 'Bus' },
   ]
   
   const rules = useMemo(() => ({
      rut: [
         VALIDATORS.REQUIRED,
      ],
      razon_social: [
         VALIDATORS.REQUIRED,
      ],
      giro_empresa: [
         VALIDATORS.REQUIRED
      ],
      email: [
         VALIDATORS.REQUIRED,
      ],
      pagina_web: [
         VALIDATORS.REQUIRED,
      ],
      observacion: [
         VALIDATORS.REQUIRED,
      ],
      tipo:[
         VALIDATORS.REQUIRED,
      ],
      fantasia: [
         VALIDATORS.REQUIRED,
      ],
      telefono_fijo: [
         VALIDATORS.REQUIRED,
      ],
      fax: [
         VALIDATORS.REQUIRED,
      ],
      activo:[
         VALIDATORS.REQUIRED,
      ],
      direccion:[
         VALIDATORS.REQUIRED,
      ],
      ciudad:[
         VALIDATORS.REQUIRED,
      ],
      geocolizacion:[
         VALIDATORS.REQUIRED,
      ],
      pais:[
         VALIDATORS.REQUIRED,
      ],
      comuna:[
         VALIDATORS.REQUIRED,
      ],


   }), []);


   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'rut':
            props.setvalueEntry(prev => ({ ...prev, rut: value }));
            break;
         case 'razon_social':
            props.setvalueEntry(prev => ({ ...prev, razon_social: value }))
            break;
         case 'giro_empresa':
            props.setvalueEntry(prev => ({ ...prev, giro_empresa: value }))
            break;
         case 'email':
            props.setvalueEntry(prev => ({ ...prev, email: value }));
            break;
         case 'pagina_web':
            props.setvalueEntry(prev => ({ ...prev, pagina_web: value }));
            break;
         case 'observacion':
            props.setvalueEntry(prev => ({ ...prev, observacion: value }));
            break;
         case 'tipo':
            props.setvalueEntry(prev => ({ ...prev, tipo: value }));
            break;
         case 'fantasia':
            props.setvalueEntry(prev => ({ ...prev, fantasia: value }));
            break;
         case 'telefono_fijo':
            props.setvalueEntry(prev => ({ ...prev, telefono_fijo: value }));
            break;
         case 'fax':
            props.setvalueEntry(prev => ({ ...prev, fax: value }));
            break;
         case 'activo':
            props.setvalueEntry(prev => ({ ...prev, activo: value }));
            break;
         case 'direccion':
            props.setvalueEntry(prev => ({ ...prev, direccion: value }));
            break;
         case 'ciudad':
            props.setvalueEntry(prev => ({ ...prev, ciudad: value }));
            break;
         case 'geocolizacion':
            props.setvalueEntry(prev => ({ ...prev, geocolizacion: value }));
            break;
         case 'comuna':
            props.setvalueEntry(prev => ({ ...prev, comuna: value }));
            break;
         case 'pais':
            props.setvalueEntry(prev => ({ ...prev, pais: value }));
            break;
         default:
            break;
      }
   };
   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      props.setvalueEntry(prev => ({ ...prev, [name]: newVal }));
   }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      props.handleNext();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }

   return (
      <Container maxWidth="xl">
         <form >
            <Grid container spacing={3} className={`e-mb-lg`}>
            
            
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={formas_pagos}
                           renderInput={(params) => <TextField {...params} label="Formas de pago" />}
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
                  <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={vendedores}
                           renderInput={(params) => <TextField {...params} label="Vendedor" />}
                  />
               </Grid>
               
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={segmentaciones}
                           renderInput={(params) => <TextField {...params} label="Segmentación" />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={entregas}
                           renderInput={(params) => <TextField {...params} label="Transporte / entrega" />}
                  />
               </Grid>
               
               <Grid item xs={12} sm={6}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <SellIcon/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     type='text'
                     placeholder="Observaciones Ventas"
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={6}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <MonetizationOnIcon/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     type='text'
                     placeholder="Observaciones Cobranzas"
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               
               <Grid item container
                  xs={12}
                 justifyContent='right'
                 >
                     <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                        className='btn_save'
                        
                        endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>Siguiente</span>
                        }
                     </Button>

                     </Grid>
               </Grid>
            </Grid>
         </form>
      </Container>
   );
}
