import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "../AbsencesTardiesGeneral.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import '../AbsencesTardiesGeneral_styles.css'
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { PictureAsPdf } from "@mui/icons-material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';

interface Column {
   id: 'dia' | 'mes' | 'periodo' | 'dias'|'obserbacion'| 'tipoRegistro'|'tipoInasistencia';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

const columns:readonly Column[] = [
   { id: 'dia', label: 'Dia', minWidth: 20 },
   { id: 'mes', label: 'Mes', minWidth: 50 },
   { id: 'periodo', label: 'Periodo', minWidth: 20 },
    { id: 'dias', label: 'Dias', minWidth: 50 },
    { id: 'obserbacion', label: 'Obserbacion', minWidth: 50 },
    { id: 'tipoRegistro', label: 'Tipo Registro', minWidth: 50 },
    { id: 'tipoInasistencia', label: 'Tipo Inasistencia', minWidth: 50 },
   ];

interface Data {
    dia: number;
    mes: string;
    periodo: number;
    dias:number;
    obserbacion:string;
    tipoRegistro:string;
    tipoInasistencia:string;
}

function createData(
   dia: number,
   mes: string,
   periodo: number,
   dias:number,
   obserbacion:string,
   tipoRegistro:string,
   tipoInasistencia:string,
   ):Data {
   return { dia, mes, periodo, dias, obserbacion,tipoRegistro,tipoInasistencia };
}

const rowsData = [
    createData( 1,'Enero',2010,5,'Obserbacion','Descuento Liquidacion','Inasistencia' ),
    createData( 1,'Febrero',2011,5,'Obserbacion','Descuento Liquidacion','Inasistencia' ),
    createData( 1,'Marzo',2012,5,'Obserbacion','Descuento Liquidacion','Inasistencia' ),
    createData( 1,'Abril',2010,5,'Obserbacion','Descuento Liquidacion','Inasistencia' ),
    createData( 1,'Mayo',2012,5,'Obserbacion','Descuento Liquidacion','Inasistencia' ),
    createData( 1,'Junio',2013,5,'Obserbacion','Descuento Liquidacion','Inasistencia' ),
    createData( 1,'Julio',2010,5,'Obserbacion','Descuento Liquidacion','Inasistencia' ),
    createData( 1,'Agosto',2014,5,'Obserbacion','Descuento Liquidacion','Inasistencia' ),
    createData( 1,'Septiembre',2010,5,'Obserbacion','Descuento Liquidacion','Inasistencia' ),
    createData( 1,'Octubre', 2015,5,'Obserbacion','Descuento Liquidacion','Inasistencia')  ,
    createData( 1,'Diciembre',2017,5,'Obserbacion','Descuento Liquidacion','Inasistencia' ),
]

const trabajadores=[
   {label:'Gonzales Nicho Javier', id:'name1'},
   {label:'Vasques Pancracio Vanesa', id:'name2'},
   {label:'Medina Valencia Kiara', id:'name3'},

]
const tiposRegistrado=[
   {label:'Descuento a Liquidacion',id:'Descuento a Liquidacion'}
]
const tiposInasistencia=[
   {label:'Inasistencia',id:'Inasistencia'}
]
export const DataTardie:React.FC<Props> = (props: Props): JSX.Element => {
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value2, setValue2] = useState('');
   const [loading, setLoading] = useState(false);
   const [familyProduct, setFamilyProduct] = useState('');
   const [estado, setEstado]= useState('');
   const [value, setValue] = useState<Date | null>(new Date());

    const handleChangeFamilyProduct = (event: SelectChangeEvent) => {
      setFamilyProduct(event.target.value);
    };
    const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
    };
   function handleClick() {
     setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };


    return (
        <Container maxWidth='xl'>
           <Grid container spacing={3}>
               <Grid item xs={12} md={12} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        DATOS ATRASOS MASIVO 2022-1
                     </Typography>
                  </Box>
                  
               </Grid>

            <Grid item xs={12} sm={3}>
               <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={trabajadores}
                     renderInput={(params) => <TextField {...params} label="Trabajador" />}
                  />
             </Grid>

            <Grid item xs={12} md={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={3}>
                    <DatePicker
                        // views={['year', 'month']}
                        label="Fecha"
                        // minDate={new Date('2012-03-01')}
                        // maxDate={new Date('2023-06-01')}
                        value={value}
                        onChange={(newValue) => {
                            setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} helperText={null} />}
                        />
                    </Stack>
                    </LocalizationProvider>
               </Grid>
               
               <Grid item xs={12} sm={3}>
                  <TextField
                     label="Total Horas"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                     
                  />
             </Grid>
      
             <Grid item xs={12} sm={6}>
                  <TextField
                     label="Obserbacion"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                     
                  />
             </Grid>
               

               <Grid item xs={12} md={3}>
                    <Button
                        type='submit'
                        variant='contained'
                        // onClick={handleSubmit}
                        // disabled={loading}
                        disableElevation
                        className='btn_next'
                        style={{backgroundColor:'#ff9700', color:'#fff'}}
                        >
                        <span>Agregar</span>
                    </Button>
                </Grid>

            </Grid>
            
            <div style={{background:'#16556b', borderRadius:'5px', color:'#ffffff',}} >
                        <Typography 
                        mt={2}
                        mb={2}
                        style={{padding:'3px'}}
                        variant="subtitle2"
                        align='center'
                        >HISTORICO DE INASISTENCIA
                        </Typography>
         </div>

            <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:220}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                                 {column.label}
                              </TableCell>
                           ))}
                           <TableCell>Opcion</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.dia}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                       <IconButton aria-label="delete">
                                          <DeleteIcon style={{color:'red'}}/>
                                       </IconButton>
         
                                       {/* <IconButton aria-label="delete">
                                          <DeleteIcon style={{color:'#16556b'}}/>
                                       </IconButton>
                                       <IconButton aria-label="edit">
                                          <FactCheckIcon  style={{color:'#16556b'}}/>
                                       </IconButton>
                                       <IconButton aria-label="edit">
                                          <PrintIcon style={{color:'#16556b'}}/>
                                       </IconButton> */}
         
                                 </TableCell>

                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
            </Paper>

         </Container>
     
    );
   };

