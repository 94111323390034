import { Button, Grid } from '@mui/material'
import React, { Component } from 'react'

export const ModalButton = (props: any) => {
  
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} textAlign="center" >
                    <Button variant="contained" style={{backgroundColor:'#16556b',color:'#ffffff', fontSize:'14px !important'}}
                    >Original</Button>
                </Grid>
                <Grid item xs={12} textAlign="center">
                    <Button variant="contained"  style={{backgroundColor:'#16556b',color:'#ffffff', fontSize:'14px !important'}}
                  >Cedible</Button>
                </Grid>
                <Grid item xs={12} textAlign="center">
                    <Button variant="contained"  style={{backgroundColor:'#16556b',color:'#ffffff', fontSize:'14px !important'}}
                  >Ambos</Button>
                </Grid>
                <Grid item xs={12} textAlign="center" >
                    <Button variant="contained"  style={{backgroundColor:'#16556b',color:'#ffffff', fontSize:'14px !important'}}
                  >Excel</Button>
                </Grid>
            </Grid>
        </>
        )
  }

