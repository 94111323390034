import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, } from '@mui/material'
import { useState } from "react";
import { Props } from "../QuoteBook.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import '../QuoteBook_styles.css'
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { AddCircle } from "@mui/icons-material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


export const DataEmail: React.FC<Props> = (props: Props): JSX.Element => {
 const contactos=[
      { label:'Juan Vela', value:'Juan Vela'},
      { label:'Juan Vela', value:'Juan Vela'},
      { label:'Juan Vela', value:'Juan Vela'},
      { label:'Juan Vela', value:'Juan Vela'},
 ]

   return (
         <Container maxWidth='xl'>
            <Grid container spacing={3}>
            <Grid item xs={12} md={12} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        DATOS DEL RECEPTOR
                     </Typography>
                  </Box>
                  
               </Grid>
               <Grid item xs={12} sm={6}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={contactos}
                     renderInput={(params) => <TextField {...params} label="Seleccione Contacto" />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <FormControlLabel value="Original" control={<Radio />} label="Original" />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <FormControlLabel value="Original" control={<Radio />} label="Cedible" />
               </Grid>
               <Grid item xs={12} sm={4}>
                  <FormControlLabel control={<Checkbox defaultChecked />} label="XML Intercambio" />
               </Grid>
               <Grid item xs={12} sm={12}>
                  <Typography variant='subtitle2'  gutterBottom >
                        CORREOS ADICIONALES
                  </Typography>               
               </Grid>

               <Grid item xs={12} sm={6}>
                  <TextField
                     label="CC1"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>
               <Grid item xs={12} sm={6}>
                  <TextField
                     label="CC2"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>
               <Grid item xs={12} sm={6}>
                  <TextField
                     label="CC3"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={6}>
                  <TextField
                     label="CC Usuario"
                     id="outlined-size-small"
                     size="small"
                     defaultValue="desarrollo@softnet.cl"
                     fullWidth
                  />
               </Grid>  

            </Grid>
            <Grid item container
                           xs={12}
                           mt={2}
                        justifyContent='center'
                        >
                              <Grid item>
                                 <Button
                                    type='submit'
                                    variant='contained'
                                    
                                    // onClick={handleSubmit}
                                    // disabled={loading}
                                    disableElevation
                                    // component={Link} to={ROUTE_EV_QUOTE_BOOK_RESUME}
                                    className='btn_next'
                                 >
                                          <span>Enviar Email</span>
                                 </Button>
                              </Grid>
                     </Grid>
         </Container>
   );
};

