import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, } from '@mui/material';
import {  ArrowRightIcon } from "@toolbox/constants/icons";
import { Props } from './FormDetall.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import './style.sass'
import "./FormDetall_style.css";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import BusinessIcon from '@mui/icons-material/Business';
import FaxIcon from '@mui/icons-material/Fax';
import LanguageIcon from '@mui/icons-material/Language';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import HomeIcon from '@mui/icons-material/Home';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import DnsIcon from '@mui/icons-material/Dns';

export const FormDetall: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);

   const tiposProductos = [
      { label: 'Contrata', value: 'contrato' },
      { label: 'Cuenta Tercero', value: 'cuentaTercero' },
      { label: 'Equipos e Implementos', value: 'equipoImplementos' },
      { label: 'Gastos y Servicios', value: 'GastosServicios' },
      { label: 'Herramientas', value: 'herramientas' },
      { label: 'Insumos y Produccion', value: 'insumosProduccion' },
      { label: 'Materia Prima', value: 'materiaPrima' },      
   ]
   const impuestosRecargas = [
      { label: 'Impuesto 1', value: 'iva' },
      { label: 'Impuesto 2', value: 'iva2' },
      { label: 'Impuesto 3', value: 'iva3' },
      { label: 'Impuesto 4', value: 'iva4' },
      { label: 'Impuesto 5', value: 'iva5' },
   ]

   const lineasProducto=[
      { label: 'Linea 1', value: 'linea1' },
      { label: 'Linea 2', value: 'linea2' },
      { label: 'Linea 3', value: 'linea3' },
      { label: 'Linea 4', value: 'linea4' },
   ]

   const familiaProductos=[
      { label: 'Familia 1', value: 'familia1' },
      { label: 'Familia 2', value: 'familia2' },
      { label: 'Familia 3', value: 'familia3' },
      { label: 'Familia 4', value: 'familia4' },
   ]
  
   const subFamiliaProductos=[
      { label: 'SubFamilia 1', value: 'subFamilia1' },
      { label: 'SubFamilia 2', value: 'subFamilia2' },
      { label: 'SubFamilia 3', value: 'subFamilia3' },
      { label: 'SubFamilia 4', value: 'subFamilia4' },
   ]

   const estado=[
      { label: 'Activo', value: 'activo' },
      { label: 'Inactivo', value: 'inactivo' },
   ]

   const unidadMedidas=[
      { label: '1/2 Kilo', value: 'unidad1' },
      { label: '1/2 Litro', value: 'unidad2' },
      { label: '1/2 Metro', value: 'unidad3' },
   ]

   const clasificaciones=[
      { label: 'Clasificacion 1', value: 'clasificacion1' },
      { label: 'Clasificacion 2', value: 'clasificacion2' },
      { label: 'Clasificacion 3', value: 'clasificacion3' },
      { label: 'Clasificacion 4', value: 'clasificacion4' },

   ]

   const areasNegocios=[
      { label: 'Area 1', value: 'area1' },
      { label: 'Area 2', value: 'area2' },
      { label: 'Area 3', value: 'area3' },
      { label: 'Area 4', value: 'area4' },
   ]

   const rules = useMemo(() => ({
      nombre_producto: [
         VALIDATORS.REQUIRED,
      ],
      codigo_producto: [
         VALIDATORS.REQUIRED,
      ],
      descripcion: [
         VALIDATORS.REQUIRED
      ],
      linea_producto: [
         VALIDATORS.REQUIRED,
      ],
      codigo_barra: [
         VALIDATORS.REQUIRED,
      ],
      familia_producto: [
         VALIDATORS.REQUIRED,
      ],
      subfamilia_producto:[
         VALIDATORS.REQUIRED,
      ],
      tipo_producto: [
         VALIDATORS.REQUIRED,
      ],
      impuesto_producto: [
         VALIDATORS.REQUIRED,
      ],
      estado_producto: [
         VALIDATORS.REQUIRED,
      ],
      codigo_proveedor: [
         VALIDATORS.REQUIRED,
      ],
      ubicacion_producto: [
         VALIDATORS.REQUIRED,
      ],
      formato_venta: [
         VALIDATORS.REQUIRED,
      ],
      stock_maximo: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      peso_cubicos: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
   

   }), []);


   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'nombre_producto':
            props.setvalueEntry(prev => ({ ...prev, nombre_producto: value }));
            break;
         case 'codigo_producto':
            props.setvalueEntry(prev => ({ ...prev, codigo_producto: value }))
            break;
         case 'descripcion':
            props.setvalueEntry(prev => ({ ...prev, descripcion: value }))
            break;
         case 'linea_producto':
            props.setvalueEntry(prev => ({ ...prev, linea_producto: value }));
            break;
         case 'codigo_proveedor':
            props.setvalueEntry(prev => ({ ...prev, codigo_proveedor: value }));
            break;
         case 'codigo_barra':
            props.setvalueEntry(prev => ({ ...prev, codigo_barra: value }));
            break;
         case 'familia_producto':
            props.setvalueEntry(prev => ({ ...prev, familia_producto: value }));
            break;
         case 'subfamilia_producto':
            props.setvalueEntry(prev => ({ ...prev, subfamilia_producto: value }));
            break;
         case 'impuesto_producto':
            props.setvalueEntry(prev => ({ ...prev, impuesto_producto: value }));
            break;
         case 'estado_producto':
            props.setvalueEntry(prev => ({ ...prev, estado_producto: value }));
            break;
         case 'codigo_proveedor':
            props.setvalueEntry(prev => ({ ...prev, codigo_proveedor: value }));
            break;
         case 'ubicacion_producto':
            props.setvalueEntry(prev => ({ ...prev, ubicacion_producto: value }));
            break;
         case 'formato_venta':
            props.setvalueEntry(prev => ({ ...prev, formato_venta: value }));
            break;
         case 'stock_maximo':
            props.setvalueEntry(prev => ({ ...prev, stock_maximo: value }));
            break;
         case 'peso_cubicos':
            props.setvalueEntry(prev => ({ ...prev, peso_cubicos: value }));
            break;
         default:
            break;
      }
   };
   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      props.setvalueEntry(prev => ({ ...prev, [name]: newVal }));
   }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      props.handleNext();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }

   return (
      <Container maxWidth="xl">
         <form >
            <Grid container spacing={3} className={`e-mb-lg`}>
            <Grid item xs={12} >
               <p  className="tittle3">
                  INFORMACION GENERAL
               </p>
               </Grid>
            <Grid item xs={12} sm={3}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <BusinessCenterIcon/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="codigo_producto"
                     type='text'
                     placeholder="Codigo Producto"
                     value={props.valueEntry.codigo_producto}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.codigo_producto}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />

               </Grid>

               <Grid item xs={12} sm={3}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <BusinessIcon/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="nombre_producto"
                     type='text'
                     placeholder="Nombre Producto"
                     value={props.valueEntry.nombre_producto}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.nombre_producto}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />

               </Grid>
              
               <Grid item xs={12} sm={3}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <DriveFileRenameOutlineIcon/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="codigo_proveedor"
                     type='text'
                     placeholder="Codigo Proveedor"
                     value={props.valueEntry.codigo_proveedor}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.codigo_proveedor}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <DriveFileRenameOutlineIcon/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="codigo_barra"
                     type='text'
                     placeholder="Codigo Barra"
                     value={props.valueEntry.codigo_barra}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.codigo_barra}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
                  <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={lineasProducto}
                           renderInput={(params) => <TextField {...params} label="Linea de Producto" />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={tiposProductos}
                           renderInput={(params) => <TextField {...params} label="Tipo de Producto" />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={familiaProductos}
                           renderInput={(params) => <TextField {...params} label="Familia de Producto" />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={subFamiliaProductos}
                           renderInput={(params) => <TextField {...params} label="Sub Familia de Producto" />}
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
                  <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={impuestosRecargas}
                           renderInput={(params) => <TextField {...params} label="Impuestos y Recargas" />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={estado}
                           renderInput={(params) => <TextField {...params} label="Estado de Producto" />}
                  />
               </Grid>

               <Grid item xs={12} >
               <p  className="tittle3">
                  INVENTARIO
               </p>
               </Grid>



               <Grid item xs={12} sm={3}>
                  <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={unidadMedidas}
                           renderInput={(params) => <TextField {...params} label="Unidad Medida" />}
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
                  <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={clasificaciones}
                           renderInput={(params) => <TextField {...params} label="Clasificacion" />}
                  />
               </Grid>


               <Grid item xs={12} sm={3}>
                  <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={areasNegocios}
                           renderInput={(params) => <TextField {...params} label="Area Negocio" />}
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <DriveFileRenameOutlineIcon/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="ubicacion_producto"
                     type='text'
                     placeholder="Ubicacion Fisica"
                     value={props.valueEntry.ubicacion_producto}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.ubicacion_producto}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               
               <Grid item xs={12} sm={3}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <DriveFileRenameOutlineIcon/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="formato_venta"
                     type='text'
                     placeholder="Formato Venta"
                     value={props.valueEntry.formato_venta}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.formato_venta}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <DriveFileRenameOutlineIcon/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="stock_maximo`"
                     type='text'
                     placeholder="Stock Maximo"
                     value={props.valueEntry.stock_maximo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.stock_maximo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <DriveFileRenameOutlineIcon/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="peso_cubicos"
                     type='text'
                     placeholder="Peso Kg"
                     value={props.valueEntry.peso_cubicos}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.peso_cubicos}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>

               <Grid item container
                  xs={12}
                 justifyContent='right'
                 >
                     <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                        className='btn_save'
                        
                        endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>Siguiente</span>
                        }
                     </Button>

                     </Grid>
               </Grid>
            </Grid>
         </form>
      </Container>
   );
}
