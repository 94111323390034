import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { VALIDATORS } from "@/toolbox/helpers/validation-rules";
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, TablePagination, } from '@mui/material';
import { Input } from '@components/common/Input';
import { Props } from "./LoanByWorker.type";
import { InputRef } from '@/components/common/Input/InputInterfaces';
import './LoanByWorker_style.css';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Link } from 'react-router-dom';
import {  ROUTE_RM_ADD_LOAN } from '@/toolbox/constants/route-map';

interface Column {
   id: 'num' | 'rut' | 'apellidoPaterno' | 'apellidoMaterno' | 'nombre';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: 'num', label: 'Num', minWidth: 100 },
   { id: 'rut', label: 'Rut', minWidth: 100 },
   { id: 'apellidoPaterno', label: 'Apellido Paterno', minWidth: 100 },
   { id: 'apellidoMaterno', label: 'Apellido Materno', minWidth: 100 },
   { id: 'nombre', label: 'Nombre', minWidth: 100 },
];

interface Data {
   num: string;
   rut: string;
   apellidoPaterno: string;
   apellidoMaterno: string;
   nombre: string;
}
function createDataContrato(
   num: string,
   rut: string,
   apellidoPaterno: string,
   apellidoMaterno: string,
   nombre: string
): Data {
   return { num, rut, apellidoPaterno, apellidoMaterno, nombre };
}

const rowsListado = [
   createDataContrato('1', '123191710', 'ALARCON', 'SUAREZ', 'MONICA MELISSA'),
   createDataContrato('2', '22222222-2', 'YUCATAN', 'ALACAHOFA', 'JUAN PEREZ'),
   createDataContrato('3', '2900002-M', 'DOS SANTOS', 'AVEIRO', 'LUIS ALESSANDRO'),
   createDataContrato('4', '17482024-4', 'BLANCO', 'MEJÍA', 'GIOMAR ALEXIS'),
   createDataContrato('5', '2900002-M', 'DOS SANTOS', 'AVEIRO', 'LUIS ALESSANDRO'),
   createDataContrato('6', '17482024-4', 'BLANCO', 'MEJÍA', 'GIOMAR ALEXIS')
]

export const LoanByWorker: React.FC<Props> = (props: Props): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);

   const [open, setOpen] = useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 720,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

   const rules = useMemo(() => ({
      rut_proveedor: [
         VALIDATORS.REQUIRED,
      ],
      numero_contrato: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      titulo: [
         VALIDATORS.REQUIRED
      ],
      descripcion: [
         VALIDATORS.REQUIRED,
      ]

   }), []);
   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'rut_proveedot':
            props.setvalueContrato(prev => ({ ...prev, rut_proveedot: value }));
            break;
         case 'numero_contrato':
            props.setvalueContrato(prev => ({ ...prev, numero_contrato: value }))
            break;
         case 'titulo':
            props.setvalueContrato(prev => ({ ...prev, titulo: value }))
            break;
         case 'descripcion':
            props.setvalueContrato(prev => ({ ...prev, descripcion: value }));
            break;

         default:
            break;
      }
   };

   // const handleInputBlur = (event: any) => {
   //    const newVal = (event.target.value).trim();
   //    const name = event.target.name;
   //    props.setvalueContrato(prev => ({ ...prev, [name]: newVal }));
   // }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }
   return (
      <Protected>
         <Container maxWidth='xl'>
            <form style={{ margin: '8px' }}>
               <Typography variant="h6" gutterBottom mb={3} className="tittle">
                  PRÉSTAMOS
               </Typography>
               <Grid container mt={2} mb={2}>
                  <Typography variant="subtitle1" mb={3}>
                     Seleccione Trabajador con Contrato Vigente
                  </Typography>
               </Grid>
            </form>

            <div className='bg-tittle'>
               <Typography
                  mt={3}
                  variant="h6"
                  align='center'
                  className='tittle-text'
               >Lista de Trabajadores
               </Typography>
            </div>
         </Container>

         <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer component={Paper}>
               <Table aria-label="simple table">
                  <TableHead>
                     <TableRow>
                        <TableCell>Num</TableCell>
                        <TableCell>RUT</TableCell>
                        <TableCell>Apellido Paterno</TableCell>
                        <TableCell>Apellido Materno</TableCell>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Acción</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {rowsListado.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        return (
                           <TableRow hover role="checkbox" tabIndex={-1} key={row.num}>
                              {columns.map((column) => {
                                 const value = row[column.id];
                                 return (
                                    <TableCell key={column.id} align={column.align}>
                                       {column.format && typeof value === 'number'
                                          ? column.format(value)
                                          : value}
                                    </TableCell>
                                 );
                              })}
                              <TableCell>
                                 <Tooltip title='Agregar'>
                                    <IconButton component={Link} to={ROUTE_RM_ADD_LOAN}>
                                       <PersonAddIcon />                                       
                                    </IconButton>
                                 </Tooltip>                                
                              </TableCell>
                           </TableRow>
                        );
                     })}
                  </TableBody>
               </Table>
            </TableContainer>
            <TablePagination
               rowsPerPageOptions={[10, 25, 100]}
               component="div"
               count={rowsListado.length}
               rowsPerPage={rowsPerPage}
               page={page}
               onPageChange={handleChangePage}
               onRowsPerPageChange={handleChangeRowsPerPage}
            />
         </Paper>


      </Protected>
   );
}