import React, { useMemo } from "react";
import {
   BrowserRouter as Router,
   Route,
   Switch,
   Redirect,
} from "react-router-dom";
import * as Routes from "@constants/route-map";
import { useLocalStorage } from "@toolbox/hooks/local-storage.hook";
import { KEY_USER_DATA } from "@toolbox/constants/local-storage";
import { Login } from "@views/Login";
import { Home } from "@views/Home";
import { PrivateRoute, AuthRoute } from "./PrivateRoute";
import { Provider } from "@/views/Adquisitions/Masters/Provider";
import { SteperAccount } from "@/views/SteperAccount";
// ConsullBallot aún no está siendo llaamdo
// import { ConsultBallot } from '@/views/ConsultBallot';
import { OcSupplies } from "@/views/Adquisitions/PurchaseOrden/OCompraInsumos";
import { Rubro } from "@/views/Adquisitions/Masters/Rubro";
import { Bodega } from "@/views/Adquisitions/Masters/Bodega";
import { CenterBeneficio } from "@/views/Adquisitions/Masters/CenterBeneficio";
import { OcProducts } from "@/views/Adquisitions/PurchaseOrden/OCompraProductos";
import { FixedAssets } from "@/views/Adquisitions/Masters/FixedAssets";
import { Contrato } from "@/views/Adquisitions/Masters/Contrato";
import { InsumosOther } from "@/views/Adquisitions/Masters/InsumosOther";
import { ArticleGroup } from "@/views/Adquisitions/Masters/ArticleGroup";
import { StateOC } from "@/views/Adquisitions/Masters/StateOC";
import { DecisionState } from "@/views/Adquisitions/Masters/DecisionState";
import { CurrentAccount } from "@/views/Adquisitions/Masters/CurrentAccount";
import { BuyRequest } from "@/views/Adquisitions/PurchaseRequest/BuyRequest";
import { RutNotRejected } from "@/views/Adquisitions/Masters/RutNotRejected";
import { BuyRequestBook } from "@/views/Adquisitions/PurchaseRequest/BuyRequestBook";
import { OfertRequest } from "@/views/Adquisitions/PurchaseRequest/OfertRequest";
import { OfertRequestBook } from "@/views/Adquisitions/PurchaseRequest/OfertRequestBook";
import { DecisionOc } from "@/views/Adquisitions/PurchaseRequest/DecisionOc";
import { BookPurchaseOrder } from "@/views/Adquisitions/PurchaseOrden/BookPurchaseOrder";
import { InternalGuideOc } from "@/views/Adquisitions/PurchaseRequest/InternalGuideOc";
import { GcProvider } from "@/views/CommercialGesture/Masters/GcProvider";
import { GcMasterSearch } from "@/views/CommercialGesture/Masters/GcMasterSearch";
import { GcClientPerson } from "@/views/CommercialGesture/Masters/GcClientPerson";
import { GcFamilies } from "@/views/CommercialGesture/Masters/GcFamilies";
import { GcLines } from "@/views/CommercialGesture/Masters/GcLines";
import { GcDispatchRoutes } from "@/views/CommercialGesture/Masters/GcDispatchRoutes";
import { GcReportSv } from "@/views/CommercialGesture/Masters/GcReportSv";
import { GcPriceList } from "@/views/CommercialGesture/Masters/GcPriceList";
import { NoSegementation } from "@/views/CommercialGesture/Masters/GcPriceList/Create/NoSegmentation";
import { SalesChanel } from "@/views/CommercialGesture/Masters/GcPriceList/Create/SalesChanel";
import { AddCart } from "@/views/CommercialGesture/Masters/GcPriceList/Create/AddCart";
import { GcVariousConfigurations } from "@/views/CommercialGesture/Masters/GcVariousConfigurations";
import { GcCustumer } from "@/views/CommercialGesture/Masters/GcCustomer";
import { GcCustomerProvider } from "@/views/CommercialGesture/Masters/GcCustomerProvider";
import { GcCustomerProspect } from "@/views/CommercialGesture/Masters/GcCustomerProspect";
import { GcProductService } from "@/views/CommercialGesture/Masters/GcProductService";
import { GcSubFamily } from "@/views/CommercialGesture/Masters/GcSubFamily";
import { GcBusinessArea } from "@/views/CommercialGesture/Masters/GcBusinessArea";
import { GcVendors } from "@/views/CommercialGesture/Masters/GcVendors";
import { GcProductsList } from "@/views/CommercialGesture/Masters/GcProductsList";
import { EvSalesdates } from "@/views/CommercialGesture/SelesStatistics/EvSaleDates";
import { MonthAndPeriod } from "@/views/CommercialGesture/SelesStatistics/EvSaleDates/Create/MonthAndPeriod";
import { SelectionDates } from "@/views/CommercialGesture/SelesStatistics/EvSaleDates/Create/SelectionDates";
import { MonthAndPeriodDay } from "@/views/CommercialGesture/SelesStatistics/EvSaleDates/Create/MonthAndPeriodDay";
import { EvQuoteBooks } from "@/views/CommercialGesture/SelesStatistics/EvQuoteBooks";
import { EvFillRate } from "@/views/CommercialGesture/SelesStatistics/EvFillRate";
import { EvCheckRoyalty } from "@/views/CommercialGesture/SelesStatistics/EvCheckRoyalty";
import { EcClientSell } from "@/views/CommercialGesture/SelesStatistics/EvClientSell";
import { EcSellConsidate } from "@/views/CommercialGesture/SelesStatistics/EvSellConsidate";
import { GcInformRoyalty } from "@/views/CommercialGesture/SelesStatistics/EvInformRoyalty";
import { GcRutaDepacho } from "@/views/CommercialGesture/SelesStatistics/EvRouteDispach";
import { IndicatorMonth } from "@/views/Remuneration/Master/IndicatorsMonth/IndicatorsMonth";

import { Departments } from "@/views/Remuneration/Master/Departments/Departments";
import { Charges } from "@/views/Remuneration/Master/Charges/Charges";
import { ValueExtraHours } from "@/views/Remuneration/Master/ValueExtraHours/ValueExtraHours";
import { SettkementStructure } from "@/views/Remuneration/Master/SettkementStructure/SettkementStructure";
import { SettlementActive } from "@/views/Remuneration/Master/SettlementActive/SettlementActive";
import { Workers } from "@/views/Remuneration/Master/Workers/Workers";
import { LiquidationSalary } from "@/views/Remuneration/Report/LiquidationSalary";
import { PrintWorker } from "@/views/Remuneration/Report/LiquidationSalary/Create/PrintWorker";
import { AddLiquidation } from "@/views/Remuneration/Report/LiquidationSalary/Create/AddLiquidation";
import { MassiveLiquidation } from "@/views/Remuneration/Report/MassiveLiquidation";
import { RemunerationSheet } from "@/views/Remuneration/Report/RemunerationSheet";
import { QuotationSheet } from "@/views/Remuneration/Report/QuotationSheet";
import { QuotationWorkers } from "@/views/Remuneration/Report/QuotationSheet/Create/QuotationWorkers";
import { QuotationEntity } from "@/views/Remuneration/Report/QuotationSheet/Create/QuotationEntity";
import { LegalHoliday } from "@/views/Remuneration/Report/LegalHoliday";
import { MonthlyRemuneration } from "@/views/Remuneration/Report/MonthlyRemuneration";
import { AdditionalForm } from "@/views/Remuneration/Report/MonthlyRemuneration/Create/AdditionalForm";
import { CentralizeWorker } from "@/views/Remuneration/Report/CentralizeWorker";
import { Advances } from "@/views/Remuneration/NewsMonth/Advances/Advances";
import { AbsencesTardies } from "@/views/Remuneration/NewsMonth/AbsencesTardies";
import { GeneralAdvances } from "@/views/Remuneration/NewsMonth/GeneralAdvances";
import { HoursExtraordinaries } from "@/views/Remuneration/NewsMonth/HoursExtraordinaries";
import { OtherHaberes } from "@/views/Remuneration/NewsMonth/OtherHaberes";
import { OtherDescount } from "@/views/Remuneration/NewsMonth/OtherDescounts";
import { AbsencesTardiesGeneral } from "@/views/Remuneration/NewsMonth/AbsencesTardiesGeneral";
import { PaymentWorkers } from "@/views/Remuneration/PaymentManagement/Workers/PaymentWorkers";
import { ContractManagement } from "@/views/Remuneration/ContractManagement/ContratoEstructure/ContractManagement";
import { ContratoWorker } from "@/views/Remuneration/ContractManagement/ContratoWorker/ContratoWorker";
import { PaymentWorker } from "@/views/Remuneration/PaymentManagement/PaymentWorker/PaymentWorker";
import { FinicosWorker } from "@/views/Remuneration/FInicosManagement/FinicosWorker/FinicosWorker";
import { FinicosEstructure } from "@/views/Remuneration/FInicosManagement/FinicosEstructure/FinicosEstructure";
import { InformWorker } from "@/views/Remuneration/ControlClock/InformWorker";
import { PaymentWorkerStepper } from "@/views/Remuneration/PaymentManagement/Workers/Stepper/PaymentWorkerStepper";
import { FormInitialize } from "@/views/Remuneration/PaymentManagement/PaymentWorker/FormInitialize";
import { ResumeByWorker } from "@/views/Remuneration/LoanRemuneration/ResumeByWorker/ResumeByWorker";
import { QuotasState } from "@/views/Remuneration/LoanRemuneration/ResumeByWorker/Create/QuotasState";
import { LoanByWorker } from "@/views/Remuneration/LoanRemuneration/LoanByWorker";
import { AddLoan } from "@/views/Remuneration/LoanRemuneration/LoanByWorker/ViewCrudLoan/AddLoan";
import { TotalSales } from "@/views/Home/InternalViews/VCardTotalSales";
import { TotalProducts } from "@/views/Home/InternalViews/VCardTotalProducts";
import { SalesYear } from "@/views/Home/InternalViews/CardSalesYear";
import { SalesMonth } from "@/views/Home/InternalViews/CardSalesMonth";
import { SalesAvg } from "@/views/Home/InternalViews/VCardSalesAvg";
import { PurchasesByDates } from "@/views/CommercialGesture/PurchaseStatistics/PurchasesByDates/PurchasesByDates";
import { ConsolidatePuchases } from "@/views/CommercialGesture/PurchaseStatistics/ConsolidatedPurchases/ConsolidatePuchases";
import { TradeBloackManagement } from "@/views/CommercialGesture/CreditCommittee/TradeBloackManagement/TradeBloackManagement";
import { PaymentCommercial } from "@/views/CommercialGesture/CreditCommittee/PaymentCommercial/PaymentCommercial";
import { CreditLine } from "@/views/CommercialGesture/CreditCommittee/CreditLine/CreditLine";
import { BuysByProvider } from "@/views/CommercialGesture/PurchaseStatistics/BuysByProvider/BuysByProvider";
import { RequestBook } from "@/views/CommercialGesture/ReportsControl/RequestBook/RequestBook";
import { QuoteBook } from "@/views/CommercialGesture/SellTeam/BussinessBook/QuoteBook/QuoteBook";
import { QuoteBookResumen } from "@/views/CommercialGesture/SellTeam/BussinessBook/QuoteBook/QuoteBookResumen";
import { QuoteFolio } from "@/views/CommercialGesture/SellTeam/BussinessBook/QuoteBook/QuoteFolio";
import { QuoteConfirmEmition } from "@/views/CommercialGesture/SellTeam/BussinessBook/QuoteBook/QuoteConfirmEmition";
import { BranchOffice } from "@/views/Management/BranchOffice/BranchOffice";

import { Users } from "@/views/Management/ERP/Users";
import { BooksSalesNotes } from "@/views/CommercialGesture/SellTeam/BussinessBook/BooksSalesNotes/BooksSalesNotes";
import { BooksExportSalesNotes } from "@/views/CommercialGesture/SellTeam/BussinessBook/BooksExportSalesNotes";
import { BookOcCustomer } from "@/views/CommercialGesture/SellTeam/BussinessBook/BooksOcCustomer";
import { Monthly } from "@/views/CommercialGesture/SellTeam/Statistics/Monthly";
import { SalesNoteBook } from "@/views/CommercialGesture/SellTeam/BooksByUser/SalesNoteBook";
import { SalesBook } from "@/views/CommercialGesture/SellTeam/BooksByUser/SalesBook";
import { Autorization } from "@/views/CommercialGesture/SellTeam/AutorizationM/Autorization";
import { LuQuoteBook } from "@/views/CommercialGesture/SellTeam/BooksByUser/LuQuoteBook";
import { LuQuoteBookResumen } from "@/views/CommercialGesture/SellTeam/BooksByUser/LuQuoteBook/LuQuoteBookResumen";
import { LuQuoteConfirmEmition } from "@/views/CommercialGesture/SellTeam/BooksByUser/LuQuoteBook/LuQuoteConfirmEmition";
import { LuQuoteFolio } from "@/views/CommercialGesture/SellTeam/BooksByUser/LuQuoteBook/LuQuoteFolio";
import { ExportSaleNote } from "@/views/CommercialGesture/Billings/ElectronicSaleDocuments/ElectronicDocument/Vistas/ExportSaleNote";
import { SaleNote } from "@/views/CommercialGesture/Billings/ElectronicSaleDocuments/ElectronicDocument/Vistas/SaleNote";
import { EDQuotes } from "@/views/CommercialGesture/Billings/ElectronicSaleDocuments/ElectronicDocument/Vistas/EDQuotes";
import { ElectronicDispatchGuide } from "@/views/CommercialGesture/Billings/ElectronicSaleDocuments/ElectronicDocument/Vistas/ElectronicDispatchGuide";
import { ElectronicDebitNote } from "@/views/CommercialGesture/Billings/ElectronicSaleDocuments/ElectronicDocument/Vistas/ElectronicDebitNote";
import { NoElectronicSalesDocuments } from "@/views/CommercialGesture/Billings/NoElectronicSalesDocuments";
import { ElectronicExportCreditNote } from "@/views/CommercialGesture/Billings/ElectronicSaleDocuments/DocumentExport/ElectronicExportCreditNote/ElectronicExportCreditNote";
import { ElectronicExportDebitNote } from "@/views/CommercialGesture/Billings/ElectronicSaleDocuments/DocumentExport/ElectronicExportDebitNote/ElectronicExportDebitNote";
import { ElectronicExportInvoice } from "@/views/CommercialGesture/Billings/ElectronicSaleDocuments/DocumentExport/ElectronicExportInvoice/ElectronicExportInvoice";
import { EmitElectronicExportCreditNote } from "@/views/CommercialGesture/Billings/ElectronicSaleDocuments/DocumentExport/ElectronicExportCreditNote/EmitElectronicExportCreditNote/EmitElectronicExportCreditNote";
import { EmitElectronicExportDebitNote } from "@/views/CommercialGesture/Billings/ElectronicSaleDocuments/DocumentExport/ElectronicExportDebitNote/EmitElectronicExportDebitNote/EmitElectronicExportDebitNote";
import { MonthDaily } from "@/views/CommercialGesture/SelesStatistics/EvMarginSell/MonthDaily/MonthDaily";
import { MonthYears } from "@/views/CommercialGesture/SelesStatistics/EvMarginSell/MonthYears/MonthYears";
import { ProductDetail } from "@/views/CommercialGesture/SelesStatistics/EvMarginSell/ProductDetail/ProductDetail";
import { LsQuoteBook } from "@/views/CommercialGesture/SellTeam/BooksByBranch/LsQuoteBook/LsQuoteBook";
import { LsQuoteBookResumen } from "@/views/CommercialGesture/SellTeam/BooksByBranch/LsQuoteBook/LsQuoteBookResumen";
import { LsQuoteConfirmEmition } from "@/views/CommercialGesture/SellTeam/BooksByBranch/LsQuoteBook/LsQuoteConfirmEmition";
import { LsQuoteFolio } from "@/views/CommercialGesture/SellTeam/BooksByBranch/LsQuoteBook/LsQuoteFolio";
import { DocumentsEmission } from "@/views/CommercialGesture/SellTeam/DocumentsEmission";

import { LsBooksSalesNote } from "@views/CommercialGesture/SellTeam/BooksByBranch/LsBooksSalesNote/LsBooksSalesNote";
import { LsQuoteBookResumenNote } from "@/views/CommercialGesture/SellTeam/BooksByBranch/LsBooksSalesNote/LsQuoteBookResumenNote";
import { LsQuoteFolioNote } from "@/views/CommercialGesture/SellTeam/BooksByBranch/LsBooksSalesNote/LsQuoteFolioNote";

import { LsBooksSalesNoteGuide } from "@/views/CommercialGesture/SellTeam/BooksByBranch/LsBooksSalesNoteGuide/LsBooksSalesNoteGuide";
import { LsQuoteBookResumenNoteGuide } from "@/views/CommercialGesture/SellTeam/BooksByBranch/LsBooksSalesNoteGuide/LsQuoteBookResumenNoteGuide";
import { LsQuoteFolioNoteGuide } from "@/views/CommercialGesture/SellTeam/BooksByBranch/LsBooksSalesNoteGuide/LsQuoteFolioNoteGuide";
import { QuotesOption } from "@/views/CommercialGesture/SellTeam/DocumentsEmission/Sale/CuotesOption";
import { NoteSellExenta } from "@/views/CommercialGesture/SellTeam/DocumentsEmission/Sale/NoteSellExenta";
import { EmitNoteSales } from "@/views/CommercialGesture/Billings/ElectronicSaleDocuments/ElectronicDocument/Vistas/SaleNote/EmitNoteSales/EmitNoteSales";
import { EmitElectronicDispatchGuide } from "@/views/CommercialGesture/Billings/ElectronicSaleDocuments/ElectronicDocument/Vistas/ElectronicDispatchGuide/EmitElectronicDispatchGuide/EmitElectronicDispatchGuide";
import { EmitQuotes } from "@/views/CommercialGesture/Billings/ElectronicSaleDocuments/ElectronicDocument/Vistas/EDQuotes/EmitQuotes";
import { EmitReferesDocumentNoteCredit } from "@/views/CommercialGesture/Billings/ElectronicSaleDocuments/ElectronicDocument/Vistas/ElectronicDebitNote/EmitReferesDocumentNoteCredit";
import { CuotesMedia } from "@/views/CommercialGesture/SellTeam/DocumentsEmission/Sale/CuotesMedia";
import { SaleNoteOption } from "@/views/CommercialGesture/SellTeam/DocumentsEmission/Sale/SaleNoteOption";
import { SaleNoteExportOption } from "@/views/CommercialGesture/SellTeam/DocumentsEmission/Sale/SaleNoteExportOption";
import { OfficeGuideOption } from "@/views/CommercialGesture/SellTeam/DocumentsEmission/Sale/OfficeGuideOption";
import { InvoiceOfficeGuides } from "@/views/CommercialGesture/SellTeam/DocumentsEmission/Billing/InvoiceOfficeGuides";
import { ElectronicBillingOption } from "@/views/CommercialGesture/SellTeam/DocumentsEmission/Billing/ElectronicBillingOption";
import { DispatchInvoices } from "@/views/CommercialGesture/SellTeam/DocumentsEmission/Billing/DispatchInvoices";
import { EctronicBillingExport } from "@/views/CommercialGesture/SellTeam/DocumentsEmission/Billing/EctronicBillingExport";
import { ElectronicExentBilling } from "@/views/CommercialGesture/SellTeam/DocumentsEmission/Billing/ElectronicExentBilling";
import { MeansPaymentVoucher } from "@/views/CommercialGesture/SellTeam/DocumentsEmission/Billing/MeansPaymentVoucher";
import { ElectronicTicket } from "@/views/CommercialGesture/SellTeam/DocumentsEmission/Tickets/ElectronicTicket";
import { FTicketElectronic } from "@/views/CommercialGesture/Billings/ElectronicSaleDocuments/ElectronicTicket/FTicketElectronic/FTicketElectronic";
import { EmitTicketElectronic } from "@/views/CommercialGesture/Billings/ElectronicSaleDocuments/ElectronicTicket/FTicketElectronic/EmitTicketElectronic/EmitTicketElectronic";
import { RouteCheets } from "@/views/CommercialGesture/SellTeam/DocumentsEmission/Others/RouteCheets";
import { ElectronicSaleDocuments } from "@/views/CommercialGesture/Billings/ElectronicSaleDocuments";
import { ElectronicBill } from "@/views/CommercialGesture/Billings/ElectronicSaleDocuments/ElectronicDocument/Vistas/ElectronicBill";
import { ElectronicPurchaseInvoice } from "@/views/CommercialGesture/Billings/ElectronicSaleDocuments/ElectronicDocument/Vistas/ElectronicPurchaseInvoice/ElectronicPurchaseInvoice";
import { ElectronicExemptInvoice } from "@/views/CommercialGesture/Billings/ElectronicSaleDocuments/ElectronicDocument/Vistas/ElectronicExemptInvoice";
import { ElectronicCreditNote } from "@/views/CommercialGesture/Billings/ElectronicSaleDocuments/ElectronicDocument/Vistas/ElectronicCreditNote";
import { BusinessClosure } from "@views/CommercialGesture/ReportsControl/BusinessClosure";
import { PurchaseOrder } from "@/views/CommercialGesture/Billings/ElectronicSaleDocuments/ElectronicDocument/Vistas/PurchaseOrder";
import { EvMarginSell } from "@/views/CommercialGesture/SelesStatistics/EvMarginSell";
import { PurchaseOrderConfirm } from "@/views/CommercialGesture/Billings/ElectronicSaleDocuments/ElectronicDocument/Vistas/PurchaseOrder/PurchaseOrderConfirm";
import { CategoryProducts } from "@/views/Rappi/Categorys";
import { ProviderNew } from "@/views/Rappi/Provider/ProviderNew";
import { AllProducts } from "@/views/Rappi/Products";

const AllRoutes: React.FC = () => {
   const [dataUser] = useLocalStorage(KEY_USER_DATA, undefined);

   const moduleHome = [
      <PrivateRoute key={5} exact path={Routes.ROUTE_HOME} component={Home} />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_HOME_TOTAL_SALES}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <TotalSales type={location.state} entity />;
         }}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_HOME_TOTAL_PRODUCTS}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <TotalProducts type={location.state} entity />;
         }}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_HOME_SALES_YEAR}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <SalesYear type={location.state} entity />;
         }}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_HOME_TOTAL_SALES}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <TotalSales type={location.state} entity />;
         }}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_HOME_SALES_MONTH}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <SalesMonth type={location.state} entity />;
         }}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_HOME_SALES_AVG}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <SalesAvg type={location.state} entity />;
         }}
      />,
   ];
   const moduleOcSupplies = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_OC_SUPPLIES}
         component={OcSupplies}
      />,
   ];

   const moduleRubro = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_RUBRO}
         component={Rubro}
      />,
   ];

   const moduleBodega = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_BODEGA}
         component={Bodega}
      />,
   ];

   const moduleCenterBeneficio = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_CENTER_BENEFICIO}
         component={CenterBeneficio}
      />,
   ];

   const moduleFixedAssets = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_FIXED_ASSETS}
         component={FixedAssets}
      />,
   ];

   const moduleContrato = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_CONTRATO}
         component={Contrato}
      />,
   ];

   const moduleInsumosOther = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_INSUMOS_OTHER}
         component={InsumosOther}
      />,
   ];
   const moduleOcProducts = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_OC_PRODUCTS}
         component={OcProducts}
      />,
   ];

   const moduleCurrentAccount = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_CURRENT_ACOUNT}
         component={CurrentAccount}
      />,
   ];

   const moduleArticleGroup = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_ARTICLE_GROUP}
         component={ArticleGroup}
      />,
   ];
   const moduleStateOc = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_STATE_OC}
         component={StateOC}
      />,
   ];
   const moduleDecisionState = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_DECISION_STATE}
         component={DecisionState}
      />,
   ];

   const moduleBuyRequestBook = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_BUY_REQUEST_BOOK}
         component={BuyRequestBook}
      />,
   ];

   const moduleBuyRequest = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_BUY_REQUEST}
         component={BuyRequest}
      />,
   ];
   const moduleRutNotRejected = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_RUT_NOT_REJECTED}
         component={RutNotRejected}
      />,
   ];
   const moduleOfertRequest = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_REQUEST_OFERT}
         component={OfertRequest}
      />,
   ];
   const moduleOfertRequestBook = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_REQUEST_OFERT_BOOK}
         component={OfertRequestBook}
      />,
   ];
   const moduleDecisionOc = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_DECISION_OC}
         component={DecisionOc}
      />,
   ];
   const moduleBookPurchaseOrder = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_BOOK_PURCHASE_ORDER}
         component={BookPurchaseOrder}
      />,
   ];
   const moduleInternalGuideOc = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_INTERNAL_GUIDE_OC}
         component={InternalGuideOc}
      />,
   ];
   const moduleGcProvider = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_GC_PROVIDER}
         component={GcProvider}
      />,
   ];
   const moduleGcMasterSearch = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_GC_MASTER_SEARCH}
         component={GcMasterSearch}
      />,
   ];
   const moduleGcClientPerson = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_GC_CLIENT_PERSON}
         component={GcClientPerson}
      />,
   ];
   const moduleGcFamilies = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_GC_FAMILIES}
         component={GcFamilies}
      />,
   ];
   const moduleGcLines = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_GC_LINES}
         component={GcLines}
      />,
   ];
   const moduleGcDispatchRoutes = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_GC_DISPATCH_ROUTES}
         component={GcDispatchRoutes}
      />,
   ];
   const moduleGcReportSv = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_GC_REPORT_SV}
         component={GcReportSv}
      />,
   ];
   const moduleGcVariousConfigurations = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_GC_VARIOUS_CONFIGURATIONS}
         component={GcVariousConfigurations}
      />,
   ];

   const modulEcSellConsolidate = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_EV_SELL_CONSOLIDATE}
         component={EcSellConsidate}
      />,
   ];
   const moduleGcInformRoyalty = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_EV_INFORM_ROYALTY}
         component={GcInformRoyalty}
      />,
   ];

   const moduleGcRutaDepacho = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_EV_RUTA_DESPACHO}
         component={GcRutaDepacho}
      />,
   ];

   const moduleRmIncatorMonth = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_RM_INDICATOR_MONTH}
         component={IndicatorMonth}
      />,
   ];

   const moduleGcPriceList = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_GC_PRICE_LIST}
         component={GcPriceList}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_GC_PRICE_LIST_SEGMENTATION}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <NoSegementation type={location.state} entity />;
         }}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_GC_PRICE_LIST_CHANEL}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <SalesChanel type={location.state} />;
         }}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_GC_PRICE_LIST_CART}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <AddCart type={location.state} entity />;
         }}
      />,
   ];
   const moduleCustumer = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_GC_CUSTUMER}
         component={GcCustumer}
      />,
   ];
   const moduleGcCustomerProvider = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_GC_CUSTUMER_PROVIDER}
         component={GcCustomerProvider}
      />,
   ];
   const moduleGcGcCustomerProspect = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_GC_CUSTUMER_PROSPECT}
         component={GcCustomerProspect}
      />,
   ];

   const moduleGcProductService = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_GC_PRODUCTS_SERVICE}
         component={GcProductService}
      />,
   ];
   const moduleGcSubFamily = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_GC_SUB_FAMILY}
         component={GcSubFamily}
      />,
   ];
   const moduleGcBusinessArea = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_GC_BUSINESS_AREA}
         component={GcBusinessArea}
      />,
   ];
   const moduleGcVendors = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_GC_VENDOR}
         component={GcVendors}
      />,
   ];
   const moduleGcProductsList = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_GC_PRODUCT_LIST}
         component={GcProductsList}
      />,
   ];

   const moduleEvClientSell = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_EV_CLIENT_SELL}
         component={EcClientSell}
      />,
   ];
   const moduleProvider = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_PROVIDER}
         component={Provider}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_PROVIDER_CREATE}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <SteperAccount entity={location.state} />;
         }}
      />,
   ];
   const moduleEvSalesDates = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_EV_SALES_DATES}
         component={EvSalesdates}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_EV_SALES_DATES_O1}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <MonthAndPeriod type={location.state} entity />;
         }}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_EV_SALES_DATES_O2}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <SelectionDates type={location.state} entity />;
         }}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_EV_SALES_DATES_O3}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <MonthAndPeriodDay type={location.state} entity />;
         }}
      />,
   ];
   const moduleEvQuoteBooks = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_QUOTE_BOOKS}
         component={QuoteBook}
      />,
   ];

   const moduleEvFillRate = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_FILL_RATE}
         component={EvFillRate}
      />,
   ];
   const moduleEvCheckRoyalty = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_CHECK_ROYALTY}
         component={EvCheckRoyalty}
      />,
   ];

   const moduleRmDeparment = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_RM_DEPARMENT}
         component={Departments}
      />,
   ];
   const moduleRmCharges = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_RM_CHARGES}
         component={Charges}
      />,
   ];
   const moduleRmExtraHours = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_RM_EXTRA_HOURS}
         component={ValueExtraHours}
      />,
   ];
   const moduleRmSettkementStructure = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_RM_SETTKEMENT_STRUCTURE}
         component={SettkementStructure}
      />,
   ];
   const moduleRmSettkementActive = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_RM_SETTKEMENT_ACTIVE}
         component={SettlementActive}
      />,
   ];
   const moduleRmWorkers = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_M_WORKERS}
         component={Workers}
      />,
   ];

   const moduleRmLiquidation = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_RM_SALARY_LIQUIDATION}
         component={LiquidationSalary}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_RM_PRINT_WORKER}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <PrintWorker type={location.state} entity />;
         }}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_RM_ADD_LIQUIDATION}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <AddLiquidation type={location.state} entity />;
         }}
      />,
   ];
   const moduleRmMassiveLiquidation = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_RM_MASSIVE_LIQUIDATION}
         component={MassiveLiquidation}
      />,
   ];
   const moduleRmRemmunerationSheet = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_RM_REMUNERATION_SHEET}
         component={RemunerationSheet}
      />,
   ];
   const moduleRmQuotationSheet = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_RM_QUOTATION_SHEET}
         component={QuotationSheet}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_RM_QUOTATION_SHEET_WORKERS}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <QuotationWorkers type={location.state} entity />;
         }}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_RM_QUOTATION_SHEET_ENTITY}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <QuotationEntity type={location.state} entity />;
         }}
      />,
   ];
   const moduleRmLegalHoliday = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_RM_LEGAL_HOLIDAY}
         component={LegalHoliday}
      />,
   ];
   const moduleRmMonthlyRemunerationy = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_RM_MONTHLY_REMUNERATION}
         component={MonthlyRemuneration}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_RM_MONTHLY_REMUNERATION_FORM}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <AdditionalForm type={location.state} entity />;
         }}
      />,
   ];
   const moduleRmCentralizeWorker = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_RM_CENTRALIZE_WORKER}
         component={CentralizeWorker}
      />,
   ];
   const moduleRmAdvance = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_RM_ADVANCE}
         component={Advances}
      />,
   ];
   const moduleRmAbsenceTardies = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_RM_ABSENCES_TARDIES}
         component={AbsencesTardies}
      />,
   ];
   const moduleRmGeneralAdvances = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_RM_GENERAL_ADVANCE}
         component={GeneralAdvances}
      />,
   ];
   const modueleRmHoursExtraordinaries = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_RM_HOURS_EXTRAORDINARY}
         component={HoursExtraordinaries}
      />,
   ];

   const moduleRmOtherHaberes = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_RM_OTHER_HABERES}
         component={OtherHaberes}
      />,
   ];
   const moduleRmOtherDescounts = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_RM_OTHER_DESCOUNTS}
         component={OtherDescount}
      />,
   ];
   const moduleRmAbsenceTardiesGeneral = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_RM_ABSENSES_TARDIES_GENERALES}
         component={AbsencesTardiesGeneral}
      />,
   ];
   const moduleRmPaymentWorkers = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_RM_PAYMENT_WORKERS}
         component={PaymentWorkers}
      />,
   ];

   const moduleRmPaymentWorker = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_RM_PAYMENT_WORKER}
         component={PaymentWorker}
      />,
   ];

   const moduleRmContrateEstructura = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_RM_CONTRATE_STRUCTURE}
         component={ContractManagement}
      />,
   ];
   const moduleRmContrateWorkers = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_RM_CONTRATE_WORKERS}
         component={ContratoWorker}
      />,
   ];

   const moduleRmFinicosEstructure = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_RM_FINICOS_ESTRUCTURA}
         component={FinicosEstructure}
      />,
   ];
   const moduleRmFinicosWorkers = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_RM_FINICOS_WORKER}
         component={FinicosWorker}
      />,
   ];

   const moduleRmInformWorke = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_RM_INFORMATION_WORKER}
         component={InformWorker}
      />,
   ];

   const moduleRmPagoStepper = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_RM_STEPPER}
         component={PaymentWorkerStepper}
      />,
   ];
   const moduleRmPagoWorkerInicializate = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_RN_INICIALIZATION}
         component={FormInitialize}
      />,
   ];
   const moduleGcQuocationSell = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_Ev_QUOTE_BOOK}
         component={QuoteBook}
      />,
   ];

   const moduleRmResumeByWorker = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_RM_RESUME_WORKER}
         component={ResumeByWorker}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_RM_RESUME_QUOTAS}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <QuotasState type={location.state} entity />;
         }}
      />,
   ];
   const moduleGcPurchaseDate = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_CD_PURCHASE_DATE}
         component={PurchasesByDates}
      />,
   ];
   const moduleGcConsolidatedPurchase = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_CD_CONSOLIDATE_PURCHASE}
         component={ConsolidatePuchases}
      />,
   ];

   const moduleGcTradeBloakManagement = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_Cc_BLOACK_COMMERCIAL}
         component={TradeBloackManagement}
      />,
   ];

   const moduleGcPaymentComercial = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_Cc_PAYMENT_COMMERCIAL}
         component={PaymentCommercial}
      />,
   ];

   const moduleGcReportControl = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROTE__Rc_REQUEST_BOOK}
         component={RequestBook}
      />,
   ];

   const moduleRmLoanByWorker = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_RM_LOAN_WORKER}
         component={LoanByWorker}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_RM_ADD_LOAN}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <AddLoan type={location.state} entity />;
         }}
      />,
   ];
   const moduleGcBuysByProvider = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_GC_BUYS_PROVIDER}
         component={BuysByProvider}
      />,
   ];
   const moduleGcCreditLine = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_GC_CREDIT_LINE}
         component={CreditLine}
      />,
   ];
   const moduleMgUsers = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_MG_ERP_USERS}
         component={Users}
      />,
   ];

   const moduleAdBranchOffice = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_AD_BRANCH_OFFICE}
         component={BranchOffice}
      />,
   ];
   const moduleGcBookResume = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_QUOTE_BOOK_RESUME}
         component={QuoteBookResumen}
      />,
   ];
   const moduleEvBooksNotes = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_LE_BOOKS_NOTE}
         component={BooksSalesNotes}
      />,
   ];

   const moduleGcBookFolio = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_EMITION_QUOTE}
         component={QuoteFolio}
      />,
   ];

   const moduleGcConfirmEmition = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_Ec_CONFIRM_EMITION}
         component={QuoteConfirmEmition}
      />,
   ];
   //GESTION COMERCIAL-FACTURACIÓN
   const moduleFacElectronicSaleDocument = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_F_ELECTRONIC_DOCUMENT}
         component={ElectronicSaleDocuments}
      />,
   ];
   const moduleFacESElectronicBill = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_F_ED_ELECTRONIC_BILL}
         component={ElectronicBill}
      />,
   ];
   const moduleFacESElectronicPurchase = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_F_ED_ELECTRONIC_PURCHASE}
         component={ElectronicPurchaseInvoice}
      />,
   ];
   const moduleFacESElectronicExempt = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_F_ED_ELECTRONIC_EXEMPT}
         component={ElectronicExemptInvoice}
      />,
   ];
   const moduleFacESElectronicCredit = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_F_ED_ELECTRONIC_CREDIT}
         component={ElectronicCreditNote}
      />,
   ];
   const modulePurchaseOrder = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_F_DE_PURCHASE_ORDER}
         component={PurchaseOrder}
      />,
   ];

   const moduleExportSaleNote = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_F_ED_EXPORT_SALE_NOTE}
         component={ExportSaleNote}
      />,
   ];

   const moduleSaleNote = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_F_ED_SALE_NOTE}
         component={SaleNote}
      />,
   ];
   const moduleEDQuotes = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_F_ED_QUOTES}
         component={EDQuotes}
      />,
   ];
   const moduleElectronicDispatchGuide = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_F_ED_ELECTRONIC_DISPATCH_GUIDE}
         component={ElectronicDispatchGuide}
      />,
   ];

   const moduleElectronicDebitNote = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_F_ED_ELECTRONIC_DEBIT_NOTE}
         component={ElectronicDebitNote}
      />,
   ];
   const moduleElectronicExportCreditNote = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_F_DE_EX_ELECTRONIC_EXPORT_CREDIT_NOTE}
         component={ElectronicExportCreditNote}
      />,
   ];
   const moduleElectronicExportDebitNote = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_F_DE_EX_ELECTRONIC_EXPORT_DEBIT_NOTE}
         component={ElectronicExportDebitNote}
      />,
   ];

   const moduleElectronicExportInvoice = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_F_DE_EX_ELECTRONIC_EXPORT_INVOICE}
         component={ElectronicExportInvoice}
      />,
   ];

   const moduleEmitElectronicExportCreditNote = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_F_DE_EX_EMIT_ELECTRONIC_EXPORT_CREDIT_NOTE}
         component={EmitElectronicExportCreditNote}
      />,
   ];

   const moduleEmitElectronicExportDebitNote = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_F_DE_EX_EMIT_ELECTRONIC_EXPORT_DEBIT_NOTE}
         component={EmitElectronicExportDebitNote}
      />,
   ];

   const moduleEmitNoteSales = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_F_ED_EMIT_NOTE_SALES}
         component={EmitNoteSales}
      />,
   ];

   const moduleEmitElectronicDispatchGuide = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_F_ED_EMIT_ELECTRONIC_DISPATCH_GUIDE}
         component={EmitElectronicDispatchGuide}
      />,
   ];

   const moduleEmitQuotes = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_F_ED_EMIT_QUOTES}
         component={EmitQuotes}
      />,
   ];
   const moduleEmitReferesDocumentNoteCredit = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_F_ED_EMIT_REFERENS_DOCUMENT_NOTE_CREDIT}
         component={EmitReferesDocumentNoteCredit}
      />,
   ];

   //ROUTES FACTURACION DOCUMENTOS NO ELECTRONICOS
   const moduleFacNoElectronicSaleDocument = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_F_NO_ELECTRONIC_DOCUMENT}
         component={NoElectronicSalesDocuments}
      />,
   ];

   //GESTION COMERCIAL-ESTADISTICA DE VENTA
   const moduleMonthDaily = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_MONTH_DAILY}
         component={MonthDaily}
      />,
   ];
   const moduleMonthYears = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_MONTH_YEARS}
         component={MonthYears}
      />,
   ];
   const moduleProductDetail = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_PRODUCT_DETAIL}
         component={ProductDetail}
      />,
   ];

   //GESTION COMERCIAL-EQUIPO DE VENTA
   const moduleEvDocumentEmission = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_DOCUMENT_EMISSION}
         component={DocumentsEmission}
      />,
      // Opciones de la vista emision de documentos
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_EV_DE_QUOTES}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <QuotesOption />;
         }}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_EV_DE_SALE_NOTE}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <SaleNoteOption />;
         }}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_EV_DE_SALE_NOTE_EXPORT}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <SaleNoteExportOption />;
         }}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_EV_DE_OFFICE_GUIDE}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <OfficeGuideOption />;
         }}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_EV_DE_ELECTRONIC_BILLING}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <ElectronicBillingOption />;
         }}
      />,
   ];
   const moduleEvBooksExportNotes = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_BOOKS_EXPORT_NOTE}
         component={BooksExportSalesNotes}
      />,
   ];
   const moduleEvBookOcCustomer = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_BOOKS_OC_CUSTOMER}
         component={BookOcCustomer}
      />,
   ];

   const moduleEsMonthly = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_ES_MONTHLY}
         component={Monthly}
      />,
   ];

   //EV ->LU -> LIBRO NOTA DE VENTA
   const moduleEvLuBooksNotes = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_LU_BOOKS_NOTE}
         component={SalesNoteBook}
      />,
   ];
   //EV ->LU -> LIBRO VENTAS
   const moduleEvLuBooksSales = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_LU_SALES_BOOKS}
         component={SalesBook}
      />,
   ];
   //EV ->LU -> LIBRO COTIZACIONES
   const moduleEvLuQuoteBooks = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_LU_QUOTE_BOOKS}
         component={LuQuoteBook}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_EV_LU_EMITION_QUOTE}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <LuQuoteFolio entity />;
         }}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_EV_LU_QUOTE_BOOK_RESUME}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <LuQuoteBookResumen entity />;
         }}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_EV_LU_CONFIRM_EMITION}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <LuQuoteConfirmEmition entity />;
         }}
      />,
   ];
   //EV ->LS -> LIBRO COTIZACIONES
   const moduleEvLsQuoteBooks = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_LS_QUOTE_BOOKS}
         component={LsQuoteBook}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_EV_LS_EMITION_QUOTE}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <LsQuoteFolio entity />;
         }}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_EV_LS_QUOTE_BOOK_RESUME}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <LsQuoteBookResumen entity />;
         }}
      />,

      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_EV_LS_CONFIRM_EMITION}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <LsQuoteConfirmEmition entity />;
         }}
      />,
   ];
   const moduleEvAutorization = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_AUTORIZATION}
         component={Autorization}
      />,
   ];

   const moduleEvLsBookSalesNote = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_LS_BOOK_NOTE_SELL}
         component={LsBooksSalesNote}
      />,
   ];
   const moduleEvLsBookSalesNoteResumen = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_lS_BOOK_NOTE_SELL_RESUME}
         component={LsQuoteBookResumenNote}
      />,
   ];
   const moduleEvLsBookSalesNoteFolio = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_LS_BOOK_NOTE_SELL_FOLIO}
         component={LsQuoteFolioNote}
      />,
   ];

   const moduleEvLsBookSalesNoteGuide = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_LS_BOOK_NOTE_SELL_GUIDE}
         component={LsBooksSalesNoteGuide}
      />,
   ];
   const moduleEvLsBookSalesNoteResumenGuide = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_lS_BOOK_NOTE_SELL_RESUME_GUIDE}
         component={LsQuoteBookResumenNoteGuide}
      />,
   ];
   const moduleEvLsBookSalesNoteFolioGuide = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_LS_BOOK_NOTE_SELL_FOLIO_GUIDE}
         component={LsQuoteFolioNoteGuide}
      />,
   ];

   const moduleEvDeNoteSellExenta = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_DE_NOTE_SELL_EXENTA}
         component={NoteSellExenta}
      />,
   ];
   const moduleEvDeCuotesMedia = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_DE_CUOTE_MEDIA}
         component={CuotesMedia}
      />,
   ];
   const moduleEvDeInvoiceOfficeGuides = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_DE_INVOICE_OFFICE_GUIDES}
         component={InvoiceOfficeGuides}
      />,
   ];
   const moduleEvDeDispatchInvoice = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_DE_DISPASH_INVOICE}
         component={DispatchInvoices}
      />,
   ];

   const moduleEvEctronicBillingExport = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_DE_NOTE_BILLING_EXPORT}
         component={EctronicBillingExport}
      />,
   ];
   const modeluEvDeElectronicBillingExenta = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_DE_ELECTRONIC_BILLING_EXENTA}
         component={ElectronicExentBilling}
      />,
   ];

   const moduleEvDeMeansPaymentVoucher = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_DE_MEANS_PAYMENT_VOUCHER}
         component={MeansPaymentVoucher}
      />,
   ];
   const moduleEvDeElectronicTicket = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_DE_ELECTRONIC_TICKET}
         component={ElectronicTicket}
      />,
   ];
   const moduleDeFTicketElectronic = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_DE_BE_TICKET_ELECTRONIC}
         component={FTicketElectronic}
      />,
   ];

   const moduleEmitTicketElectronic = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_EV_DE_BE_EMITION_TICKET_ELECTRONIC}
         component={EmitTicketElectronic}
      />,
   ];

   const moduleRouteCheetsOthers = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_EV_DE_OTHERS_ROUTE_CHEETS}
         component={RouteCheets}
      />,
   ];

   const moduleBusinessClosure = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_RC_BUSINESS_CLOSURE}
         component={BusinessClosure}
      />,
   ];

   const moduleRouteMarginSell = [
      <PrivateRoute
         key={5}
         exact
         path={Routes.ROUTE_EV_DE_MARGIN_SELL}
         component={EvMarginSell}
      />,
   ];
   //RAPPI
   const moduleRappi = [
      <PrivateRoute
         key={12}
         exact
         path={Routes.ROUTE_RAPPI_CATEGORY}
         component={CategoryProducts}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_RAPPI_PROVIDER}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <ProviderNew entity={undefined} />;
         }}
      />,
      <PrivateRoute
         key={13}
         exact
         path={Routes.ROUTE_RAPPI_PRODUCTS}
         component={({ location, ...rest }) => {
            const state = location.state;
            console.log(state);
            return <AllProducts />;
         }}
      />,
   ];

   const routes = useMemo(
      () => {
         return (
            <Router>
               <Switch>
                  {moduleProvider}
                  {/* {moduleConsultBallot} */}
                  {moduleHome}
                  {moduleOcSupplies}
                  {moduleRubro}
                  {moduleBodega}
                  {moduleCenterBeneficio}
                  {moduleOcProducts}
                  {moduleFixedAssets}
                  {moduleContrato}
                  {moduleInsumosOther}
                  {moduleArticleGroup}
                  {moduleStateOc}
                  {moduleDecisionState}
                  {moduleCurrentAccount}
                  {moduleBuyRequest}
                  {moduleRutNotRejected}
                  {moduleBuyRequestBook}
                  {moduleOfertRequest}
                  {moduleOfertRequestBook}
                  {moduleDecisionOc}
                  {moduleBookPurchaseOrder}
                  {moduleInternalGuideOc}
                  {/* GESTION COMERCIAL */}

                  {moduleFacNoElectronicSaleDocument}
                  {/* {moduleConsultBallot} */}
                  {moduleHome}
                  {moduleOcSupplies}
                  {moduleRubro}
                  {moduleBodega}
                  {moduleCenterBeneficio}
                  {moduleOcProducts}
                  {moduleFixedAssets}
                  {moduleContrato}
                  {moduleInsumosOther}
                  {moduleArticleGroup}
                  {moduleStateOc}
                  {moduleDecisionState}
                  {moduleCurrentAccount}
                  {moduleBuyRequest}
                  {moduleRutNotRejected}
                  {moduleBuyRequestBook}
                  {moduleOfertRequest}
                  {moduleOfertRequestBook}
                  {moduleDecisionOc}
                  {moduleBookPurchaseOrder}
                  {moduleInternalGuideOc}

                  {moduleProvider}
                  {/* {moduleConsultBallot} */}
                  {moduleHome}
                  {moduleOcSupplies}
                  {moduleRubro}
                  {moduleBodega}
                  {moduleCenterBeneficio}
                  {moduleOcProducts}
                  {moduleFixedAssets}
                  {moduleContrato}
                  {moduleInsumosOther}
                  {moduleArticleGroup}
                  {moduleStateOc}
                  {moduleDecisionState}
                  {moduleCurrentAccount}
                  {moduleBuyRequest}
                  {moduleRutNotRejected}
                  {moduleBuyRequestBook}
                  {moduleOfertRequest}
                  {moduleOfertRequestBook}
                  {moduleDecisionOc}
                  {moduleBookPurchaseOrder}
                  {moduleInternalGuideOc}
                  {/* GESTION COMERCIAL */}
                  {moduleGcProvider}
                  {moduleGcMasterSearch}
                  {moduleGcClientPerson}
                  {moduleGcFamilies}
                  {moduleGcLines}
                  {moduleGcDispatchRoutes}
                  {moduleGcReportSv}
                  {moduleGcVariousConfigurations}
                  {moduleGcPriceList}
                  {moduleCustumer}
                  {moduleGcCustomerProvider}
                  {moduleGcGcCustomerProspect}
                  {moduleGcProductService}
                  {moduleGcSubFamily}
                  {moduleGcBusinessArea}
                  {moduleGcVendors}
                  {moduleGcProductsList}
                  {moduleEvSalesDates}
                  {moduleEvQuoteBooks}
                  {moduleEvFillRate}
                  {moduleEvCheckRoyalty}
                  {moduleEvClientSell}
                  {modulEcSellConsolidate}
                  {moduleGcInformRoyalty}
                  {moduleGcRutaDepacho}
                  {moduleGcPurchaseDate}
                  {moduleGcConsolidatedPurchase}
                  {moduleGcTradeBloakManagement}
                  {moduleGcPaymentComercial}
                  {moduleGcQuocationSell}
                  {moduleGcBookResume}
                  {moduleGcBookFolio}
                  {moduleGcBuysByProvider}
                  {moduleGcCreditLine}
                  {moduleGcConfirmEmition}
                  {moduleGcReportControl}
                  {moduleEvDocumentEmission}
                  {moduleEvBooksNotes}
                  {moduleEvBooksExportNotes}
                  {moduleEvBookOcCustomer}
                  {moduleEsMonthly}
                  {moduleEvLuBooksNotes}
                  {moduleEvLuBooksSales}
                  {moduleEvAutorization}
                  {moduleFacElectronicSaleDocument}
                  {moduleFacESElectronicBill}
                  {moduleFacESElectronicPurchase}
                  {moduleFacESElectronicExempt}
                  {moduleFacESElectronicCredit}
                  {/* Las 3 pertenecen a ev-lu-libro cotizaciones */}
                  {moduleEvLuQuoteBooks}

                  {/* GESTION COMERCIAL */}
                  {moduleMonthDaily}
                  {moduleMonthYears}
                  {moduleProductDetail}

                  {moduleGcProvider}
                  {moduleGcMasterSearch}
                  {moduleGcClientPerson}
                  {moduleGcFamilies}
                  {moduleGcLines}
                  {moduleGcDispatchRoutes}
                  {moduleGcReportSv}
                  {moduleGcVariousConfigurations}
                  {moduleGcPriceList}
                  {moduleCustumer}
                  {moduleGcCustomerProvider}
                  {moduleGcGcCustomerProspect}
                  {moduleGcProductService}
                  {moduleGcSubFamily}
                  {moduleGcBusinessArea}
                  {moduleGcVendors}
                  {moduleGcProductsList}
                  {moduleEvSalesDates}
                  {moduleEvQuoteBooks}
                  {moduleEvFillRate}
                  {moduleEvCheckRoyalty}
                  {moduleEvClientSell}
                  {modulEcSellConsolidate}
                  {moduleGcInformRoyalty}
                  {moduleGcRutaDepacho}
                  {moduleGcPurchaseDate}
                  {moduleGcConsolidatedPurchase}
                  {moduleGcTradeBloakManagement}
                  {moduleGcPaymentComercial}
                  {moduleGcQuocationSell}
                  {moduleGcBookResume}
                  {moduleGcBookFolio}
                  {moduleGcBuysByProvider}
                  {moduleGcCreditLine}
                  {moduleGcConfirmEmition}
                  {moduleGcReportControl}
                  {moduleEvDocumentEmission}
                  {moduleEvBooksNotes}
                  {moduleEvBooksExportNotes}
                  {moduleEvBookOcCustomer}
                  {moduleEsMonthly}
                  {moduleEvLuBooksNotes}
                  {moduleEvLuBooksSales}
                  {moduleEvAutorization}
                  {moduleFacElectronicSaleDocument}
                  {moduleFacESElectronicBill}
                  {moduleFacESElectronicPurchase}
                  {moduleFacESElectronicExempt}
                  {moduleFacESElectronicCredit}
                  {/* Las 3 pertenecen a ev-lu-libro cotizaciones */}
                  {moduleEvLuQuoteBooks}
                  {moduleFacESElectronicBill}
                  {moduleFacESElectronicPurchase}
                  {moduleFacESElectronicExempt}
                  {moduleFacESElectronicCredit}
                  {/* Las 3 pertenecen a ev-lu-libro cotizaciones */}
                  {moduleEvLuQuoteBooks}

                  {moduleEvLsQuoteBooks}

                  {/* REMUNERATION */}
                  {moduleRmIncatorMonth}
                  {moduleRmWorkers}
                  {moduleRmDeparment}
                  {moduleRmCharges}
                  {moduleRmExtraHours}
                  {moduleRmSettkementStructure}
                  {moduleRmSettkementActive}

                  {moduleRmLiquidation}
                  {moduleRmMassiveLiquidation}
                  {moduleRmRemmunerationSheet}
                  {moduleRmQuotationSheet}
                  {moduleRmLegalHoliday}
                  {moduleRmMonthlyRemunerationy}
                  {moduleRmCentralizeWorker}

                  {moduleRmAdvance}
                  {moduleRmAbsenceTardies}
                  {moduleRmGeneralAdvances}
                  {modueleRmHoursExtraordinaries}
                  {moduleRmOtherHaberes}
                  {moduleRmOtherDescounts}
                  {moduleRmAbsenceTardiesGeneral}

                  {moduleRmPaymentWorkers}
                  {moduleRmPaymentWorker}
                  {moduleRmPagoStepper}
                  {moduleRmPagoWorkerInicializate}

                  {moduleRmContrateWorkers}
                  {moduleRmContrateEstructura}

                  {moduleRmResumeByWorker}
                  {moduleRmLoanByWorker}

                  {moduleRmFinicosEstructure}
                  {moduleRmFinicosWorkers}

                  {moduleRmInformWorke}

                  {moduleAdBranchOffice}
                  {moduleEvLsBookSalesNote}
                  {moduleEvLsBookSalesNoteResumen}
                  {moduleEvLsBookSalesNoteFolio}

                  {moduleEvLsBookSalesNoteGuide}
                  {moduleEvLsBookSalesNoteResumenGuide}
                  {moduleEvLsBookSalesNoteFolioGuide}

                  {moduleRouteCheetsOthers}
                  {moduleBusinessClosure}
                  {moduleRouteCheetsOthers}
                  {modulePurchaseOrder}
                  {moduleRouteMarginSell}
                  {moduleExportSaleNote}
                  {moduleSaleNote}
                  {moduleEDQuotes}
                  {moduleElectronicDispatchGuide}
                  {moduleElectronicDebitNote}
                  {moduleElectronicExportCreditNote}
                  {moduleElectronicExportDebitNote}
                  {moduleElectronicExportInvoice}
                  {moduleEmitElectronicExportCreditNote}
                  {moduleEmitElectronicExportDebitNote}
                  {moduleEmitNoteSales}
                  {moduleEmitElectronicDispatchGuide}
                  {moduleEmitQuotes}
                  {moduleEmitReferesDocumentNoteCredit}

                  {moduleEvDeNoteSellExenta}
                  {moduleEvDeCuotesMedia}
                  {moduleEvDeInvoiceOfficeGuides}
                  {moduleEvDeDispatchInvoice}
                  {moduleEvDeMeansPaymentVoucher}
                  {moduleEvDeElectronicTicket}
                  {moduleDeFTicketElectronic}
                  {moduleEmitTicketElectronic}
                  {/* MANAGEMEN */}
                  {moduleMgUsers}
                  {moduleEvEctronicBillingExport}
                  {modeluEvDeElectronicBillingExenta}
                  {moduleRouteCheetsOthers}
                  {moduleBusinessClosure}

                  {moduleRouteCheetsOthers}

                  {modulePurchaseOrder}
                  {moduleRouteMarginSell}

                  {modulePurchaseOrder}
                  {moduleRouteMarginSell}

                  {/* RAPPI */}
                  {moduleRappi}
                  <AuthRoute
                     exact
                     path={Routes.ROUTE_LOGIN}
                     component={Login}
                  />
                  {
                     <Route
                        path="*"
                        exact={true}
                        component={() => {
                           return <Redirect to={Routes.ROUTE_LOGIN} />;
                        }}
                     />
                  }
               </Switch>
            </Router>
         );
      }, // eslint-disable-next-line
      [JSON.stringify(dataUser)]
   );
   return routes;
};

export default AllRoutes;
