import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, } from '@mui/material'
import { useState } from "react";
import { Props } from "../DispatchInvoices.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import '../DispatchInvoices-styles.css'
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { AddCircle } from "@mui/icons-material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BookIcon from '@mui/icons-material/Book';
import WbShadeIcon from '@mui/icons-material/WbShade';
import Modal from '@mui/material/Modal';

interface Column {
   id: 'rut' | 'razonSocial' | 'fantasia' | 'giro';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}
const columns: readonly Column[] = [
   { id: 'rut', label: 'Barra', minWidth: 100 },
   { id: 'razonSocial', label: 'Razon Social', minWidth: 50 },
   { id: 'fantasia', label: 'Fantasia', minWidth: 50 },
   { id: 'giro', label: 'Giro', minWidth: 50 },
];

interface Data {
   rut: string;
   razonSocial: string;
   fantasia: string;
   giro: string;
}

function createData(
   rut: string,
   razonSocial: string,
   fantasia: string,
   giro: string,
): Data {
   return { rut, razonSocial, fantasia, giro };
}

const rowsData = [
   createData('12345678-9', 'Razon Social 1', 'Fantasia 1', '$1.00'),
   createData('12345678-9', 'Razon Social 2', 'Fantasia 2', '$1.00'),
   createData('12345678-9', 'Razon Social 3', 'Fantasia 3', '$1.00'),
   createData('12345678-9', 'Razon Social 4', 'Fantasia 4', '$1.00'),
   createData('12345678-9',  'Razon Social 5','Fantasia 5', '$1.00'),
]

export const DataRut: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [ejecutivoComercial, setEjecutivoComercial] = useState('');
   const [estado, setEstado] = useState('');

   const handleChangeEjecutivoComercial = (event: SelectChangeEvent) => {
      setEjecutivoComercial(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const estados = [
      { label: 'No Adjudicada', value: 'Enviado' },
      { label: 'Adjudicada', value: 'Adjudicada' },
      { label: 'Anulada', value: 'No Adjudicada' },
   ]
   return (
      <Container maxWidth='xl'>
         <Grid item xs={12} md={12} >
            <Box>
               <Typography variant='h6' component='h1' gutterBottom className="tittle">
                  LISTA DE CLIENTES POR RUT
               </Typography>
            </Box>
            <Box>
               <Typography variant='subtitle2' gutterBottom >
                  Seleccionar Cliente
               </Typography>
            </Box>
         </Grid>

         <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
               <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                     <TableRow>
                        <TableCell>
                           <TextField
                              id="outlined-basic"
                              label="Rut"
                              size="small"
                              fullWidth
                           >
                           </TextField>
                        </TableCell>

                        <TableCell>
                           <TextField
                              id="outlined-basic"
                              label="Razon Social"
                              size="small"
                              fullWidth
                           >
                           </TextField>
                        </TableCell>

                        <TableCell>
                           <TextField
                              id="outlined-basic"
                              label="Fantasia"
                              size="small"
                              fullWidth
                           >
                           </TextField>
                        </TableCell>

                        <TableCell>
                           <TextField
                              id="outlined-basic"
                              label="Giro"
                              size="small"
                              fullWidth
                           >
                           </TextField>
                        </TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        return (
                           <TableRow hover role="checkbox" tabIndex={-1} key={row.rut}>
                              {columns.map((column) => {
                                 const value = row[column.id];
                                 return (
                                    <TableCell key={column.id} align={column.align}>
                                       {column.format && typeof value === 'number'
                                          ? column.format(value)
                                          : value}
                                    </TableCell>
                                 );
                              })}
                           </TableRow>
                        );
                     })}

                  </TableBody>
               </Table>
            </TableContainer>
            <TablePagination
               rowsPerPageOptions={[10, 25, 100]}
               component="div"
               count={rowsData.length}
               rowsPerPage={rowsPerPage}
               page={page}
               onPageChange={handleChangePage}
               onRowsPerPageChange={handleChangeRowsPerPage}
            />
         </Paper>

      </Container>
   );
};

