import * as React from 'react';
import { Protected } from "@/components/layout/Protected";
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import './EcSellConsidate_styles.css';
import { TabMonthInform } from './InformsMonth/TabMonthInform';
import { TabYearInform } from './InformsYear/TabYearInform';
import { TabMonthProducts } from './InformsMonthProduct/TabMonthProducts' 
import { TableMOnth } from './InformsMonth/TableMonth';

interface EcSellConsolidateProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: EcSellConsolidateProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export const EcSellConsidate: React.FunctionComponent<EcSellConsolidateProps> = () => {

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

    return ( 
        <Protected>
          <Grid container spacing={3}>
              <Grid item xs={12} md={12} textAlign="start">
                  <Typography mt={1} variant='h6' component='h1' gutterBottom className="tittle">
                  VENTAS CONSOLIDADAS
                  </Typography>
               </Grid>
            <Grid item xs={12} md={5} >
              <Box  >
                <AppBar position="static">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    // className='tabMain'
                  >
                    <Tab className='tab' label="Informes Mensuales" {...a11yProps(0)} />
                    <Tab className='tab' label="Informes Anuales" {...a11yProps(1)} />
                    <Tab className='tab' label=" Informes Mensuales por Producto" {...a11yProps(2)} />
                  </Tabs>
                </AppBar>
                <SwipeableViews
                  axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                  index={value}
                  onChangeIndex={handleChangeIndex}
                >
                  <TabPanel value={value} index={0} dir={theme.direction}>
                      <TabMonthInform />
                  </TabPanel>
                  <TabPanel value={value} index={1} dir={theme.direction}>
                      <TabYearInform />
                  </TabPanel>
                  <TabPanel value={value} index={2} dir={theme.direction}>
                      <TabMonthProducts />
                  </TabPanel>
                </SwipeableViews>
              </Box>
            </Grid>
            <Grid item xs={12} md={7} >
              <SwipeableViews
                  className='bgTable'
                  axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                  index={value}
                  onChangeIndex={handleChangeIndex}
                >
                  <TabPanel value={value} index={0} dir={theme.direction}>
                      <TableMOnth/>
                  </TabPanel>
                  <TabPanel value={value} index={1} dir={theme.direction}>
                      2
                  </TabPanel>
                  <TabPanel value={value} index={2} dir={theme.direction}>
                      3
                  </TabPanel>
                </SwipeableViews>
            </Grid>
            <Grid item xs={12} md={12} >
              <SwipeableViews
                  axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                  index={value}
                  onChangeIndex={handleChangeIndex}
                >
                  <TabPanel value={value} index={2} dir={theme.direction}>
                      3
                  </TabPanel>
                </SwipeableViews>
            </Grid>
          </Grid>
             
         </Protected>  
     );
}
