import React, { useState, useMemo, useRef, useEffect } from 'react';
import { FunctionComponent } from "react";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateRangePicker, { DateRange } from '@mui/lab/DateRangePicker';
import { Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, Tooltip, } from '@mui/material'
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import Paper from '@mui/material/Paper';
import SendIcon from '@mui/icons-material/Send';

interface Column {
   id: 'numero' | 'rut' | 'nombre' | 'usuario' | 'control' | 'sucursal' | 'estado';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: 'numero', label: 'numero', minWidth: 100 },
   { id: 'rut', label: 'rut', minWidth: 100 },
   { id: 'nombre', label: 'nombre', minWidth: 100 },
   { id: 'usuario', label: 'usuario', minWidth: 100 },
   { id: 'control', label: 'control', minWidth: 100 },
   { id: 'sucursal', label: 'sucursal', minWidth: 100 },
   { id: 'estado', label: 'estado', minWidth: 100 }
];

interface Data {
   numero: number;
   rut: string;
   nombre: string;
   usuario: string;
   control: string;
   sucursal: string;
   estado: string;
}

function createDataUsersActive(
   numero: number,
   rut: string,
   nombre: string,
   usuario: string,
   control: string,
   sucursal: string,
   estado: string,
): Data {
   return { numero, rut, nombre, usuario, control, sucursal, estado };
}

const rowsDataActive = [
   createDataUsersActive(1, '1236643-5', 'nombre', 'usuario', 'NO', 'SUCURSAL 1', 'ACTIVO'),
   createDataUsersActive(2, '1236643-5', 'nombre', 'usuario', 'NO', 'SUCURSAL 1', 'ACTIVO'),
   createDataUsersActive(3, '1236643-5', 'nombre', 'usuario', 'NO', 'SUCURSAL 1', 'ACTIVO'),
   createDataUsersActive(4, '1236643-5', 'nombre', 'usuario', 'NO', 'SUCURSAL 1', 'ACTIVO'),
]

export const ActiveUsers: FunctionComponent = () => {
   const [value, setValue] = React.useState<DateRange<Date>>([null, null]);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };
   return (
      <>
         <Grid container spacing={3}>
            <Grid item xs={12} md={3} textAlign="start" mt={2}>
               <Typography mb={1} variant='h6' gutterBottom className="tittle4">
                  LISTADO DE USUARIOS INGRESADOS
               </Typography>
            </Grid>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
               <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{ minWidth: column.minWidth }}
                              >
                                 {column.label}
                              </TableCell>
                           ))}
                           <TableCell>Opcion</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsDataActive.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.numero}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                    <div className='sepaEle' style={{ display: "flex", justifyContent: "space-evenly" }}>
                                       <Tooltip title='Modificar'>
                                          <IconButton aria-label="delete" >
                                             <EditIcon />
                                          </IconButton>
                                       </Tooltip>
                                       <Tooltip title='Enviar Clave Venta'>
                                          <IconButton aria-label="delete" >
                                             <SendIcon />
                                          </IconButton>
                                       </Tooltip>
                                    </div>
                                 </TableCell>

                              </TableRow>
                           );
                        })}

                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsDataActive.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>
         </Grid>
      </>
   );
}