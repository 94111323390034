import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, } from '@mui/material';
import {  ArrowRightIcon } from "@toolbox/constants/icons";
import { Props } from './FormPrize.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import './style.sass'
import "./FormPrize_style.css";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { MonetizationOn } from '@mui/icons-material';

export const FormPrize: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
    );

   const rules = useMemo(() => ({
      margen: [
         VALIDATORS.REQUIRED,
      ],
      precio_producto: [
         VALIDATORS.REQUIRED,
      ],
      precio_alternativo: [
         VALIDATORS.REQUIRED
      ],
      costo: [
         VALIDATORS.REQUIRED,
      ],
      precio_oferta: [
         VALIDATORS.REQUIRED,
      ],
      fecha_oferta: [
         VALIDATORS.REQUIRED,
      ],

   }), []);


   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'margen':
            props.setvalueEntry(prev => ({ ...prev, margen: value }));
            break;
         case 'precio_producto':
            props.setvalueEntry(prev => ({ ...prev, precio_producto: value }))
            break;
         case 'precio_alternativo':
            props.setvalueEntry(prev => ({ ...prev, precio_alternativo: value }))
            break;
         case 'costo':
            props.setvalueEntry(prev => ({ ...prev, costo: value }));
            break;
         case 'precio_venta':
            props.setvalueEntry(prev => ({ ...prev, precio_venta: value }));
            break;
         case 'precio_oferta':
            props.setvalueEntry(prev => ({ ...prev, precio_oferta: value }));
            break;
         case 'fecha_oferta':
            props.setvalueEntry(prev => ({ ...prev, fecha_oferta: value }));
            break;
         default:
            break;
      }
   };
   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      props.setvalueEntry(prev => ({ ...prev, [name]: newVal }));
   }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      props.handleNext();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }

   return (
      <Container maxWidth="xl">
         <form >
            <Grid container spacing={3} className={`e-mb-lg`}>
            <Grid item xs={12} sm={2}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <BusinessCenterIcon/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="margen"
                     type='text'
                     placeholder="Margen %"
                     value={props.valueEntry.margen}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.margen}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />

               </Grid>
               <Grid item xs={12} sm={1}>
                  <FormControlLabel control={<Checkbox defaultChecked />} label="%" />
               </Grid>

               <Grid item xs={12} sm={3}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <MonetizationOn/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="costo"
                     type='text'
                     placeholder="Costo"
                     value={props.valueEntry.costo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.costo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />

               </Grid>
              
               <Grid item xs={12} sm={3}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <MonetizationOn/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="precio_oferta"
                     type='text'
                     placeholder="Precio Oferta"
                     value={props.valueEntry.precio_oferta}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.precio_oferta}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
               <LocalizationProvider dateAdapter={AdapterDateFns}>
                     <DatePicker
                        views={['year', 'month']}
                        label="Feha Oferta"
                        minDate={new Date('2012-03-01')}
                        maxDate={new Date('2023-06-01')}
                        value={value}
                        onChange={(newValue) => {
                           setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} helperText={null} />}
                     />
                     </LocalizationProvider>
               </Grid>

               <Grid item xs={12} sm={2}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <MonetizationOn/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="precio_producto"
                     type='text'
                     placeholder="Precio Producto"
                     value={props.valueEntry.precio_producto}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.precio_producto}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={1}>
                  <FormControlLabel control={<Checkbox defaultChecked />} label="Exento" />
               </Grid>

               <Grid item xs={12} sm={3}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <MonetizationOn/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="precio_alternativo"
                     type='text'
                     placeholder="Precio Alternativo"
                     value={props.valueEntry.precio_alternativo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.precio_alternativo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={1}>
                  <FormControlLabel control={<Checkbox defaultChecked />} label="No Facturable" />
               </Grid>

               <Grid item container
                  xs={12}
                 justifyContent='right'
                 >
                     <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                        className='btn_save'
                        
                        endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>Siguiente</span>
                        }
                     </Button>

                     </Grid>
               </Grid>
            </Grid>
         </form>
      </Container>
   );
}
