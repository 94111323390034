import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { ArrowRightIcon } from "@/toolbox/constants/icons";
import { VALIDATORS } from "@/toolbox/helpers/validation-rules";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, TablePagination, TableFooter, } from '@mui/material';
import { Input } from '@components/common/Input';
import { Props } from "./AdditionalForm.type";
import BusinessIcon from '@mui/icons-material/Business';
import { InputRef } from '@/components/common/Input/InputInterfaces';
import './AdditionalForm_style.css';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Link } from 'react-router-dom';
import {  ROUTE_RM_MONTHLY_REMUNERATION } from '@/toolbox/constants/route-map';

// Para la tabla de la vista principal
interface Column {
   id: 'num' | 'trabajador' | 'mes' | 'col1' | 'col2' | 'col3' | 'col4' | 'col5' | 'col6' | 'col7' | 'col8' | 'col9' | 'col10' | 'col11' | 'col12' | 'col13' | 'col14' | 'col15';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: 'num', label: 'Codigo', minWidth: 100 },
   { id: 'trabajador', label: 'Fecha', minWidth: 100 },
   { id: 'mes', label: 'Tipo', minWidth: 100 },
   { id: 'col1', label: 'col1', minWidth: 100 },
   { id: 'col2', label: 'col2', minWidth: 100 },
   { id: 'col3', label: 'col3', minWidth: 100 },
   { id: 'col4', label: 'col4', minWidth: 100 },
   { id: 'col5', label: 'col5', minWidth: 100 },
   { id: 'col6', label: 'col6', minWidth: 100 },
   { id: 'col7', label: 'col7', minWidth: 100 },
   { id: 'col8', label: 'col8', minWidth: 100 },
   { id: 'col9', label: 'col9', minWidth: 100 },
   { id: 'col10', label: 'col10', minWidth: 100 },
   { id: 'col11', label: 'col11', minWidth: 100 },
   { id: 'col12', label: 'col12', minWidth: 100 },
   { id: 'col13', label: 'col13', minWidth: 100 },
   { id: 'col14', label: 'col14', minWidth: 100 },
   { id: 'col15', label: 'col15', minWidth: 100 },
];

interface Data {
   num: string;
   trabajador: string;
   mes: string;
   col1: string;
   col2: string;
   col3: string;
   col4: string;
   col5: string;
   col6: string;
   col7: string;
   col8: string;
   col9: string;
   col10: string;
   col11: string;
   col12: string;
   col13: string;
   col14: string;
   col15: string;
}
function createData(
   num: string,
   trabajador: string,
   mes: string,
   col1: string,
   col2: string,
   col3: string,
   col4: string,
   col5: string,
   col6: string,
   col7: string,
   col8: string,
   col9: string,
   col10: string,
   col11: string,
   col12: string,
   col13: string,
   col14: string,
   col15: string,
): Data {
   return {
      num,
      trabajador,
      mes,
      col1,
      col2,
      col3,
      col4,
      col5,
      col6,
      col7,
      col8,
      col9,
      col10,
      col11,
      col12,
      col13,
      col14,
      col15
   };
}

const rowsListado = [
   createData('1', '17482024-4', '2022-03-01', '1000', '2000', '3000', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'),
   createData('2', '17482024-4', '2022-03-01', '1000', '2000', '3000', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'),
   createData('3', '17482024-4', '2022-03-01', '1000', '2000', '3000', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'),

]

export const AdditionalForm: React.FC<Props> = (props: Props): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);


   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 750,
      // height: 600,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

   const trabajador = [
      { label: 'Trabajador 1' },
      { label: 'Trabajador 2' },
      { label: 'Trabajador 3' },
      { label: 'Trabajador 4' },
      { label: 'Trabajador 5' },
   ]
   const periodo = [
      { label: '2022' },
      { label: '2020' },
      { label: '2019' },
      { label: '2018' },
      { label: '2017' },
      { label: '2016' },
      { label: '2015' },
   ]
   const mes = [
      { label: 'Enero' },
      { label: 'Febrero' },
      { label: 'Marzo' },
      { label: 'Abril' },
      { label: 'Mayo' },
      { label: 'Junio' },
      { label: 'Agosto' },
      { label: 'Setiembre' },
      { label: 'Octubre' },
      { label: 'Noviembre sin ti' },
      { label: 'Diciembre' },
      { label: 'TODOS' }
   ]

   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const rules = useMemo(() => ({
      rut_proveedor: [
         VALIDATORS.REQUIRED,
      ],
      numero_contrato: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      titulo: [
         VALIDATORS.REQUIRED
      ],
      descripcion: [
         VALIDATORS.REQUIRED,
      ]

   }), []);
   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'rut_proveedot':
            props.setvalueContrato(prev => ({ ...prev, rut_proveedot: value }));
            break;
         case 'numero_contrato':
            props.setvalueContrato(prev => ({ ...prev, numero_contrato: value }))
            break;
         case 'titulo':
            props.setvalueContrato(prev => ({ ...prev, titulo: value }))
            break;
         case 'descripcion':
            props.setvalueContrato(prev => ({ ...prev, descripcion: value }));
            break;

         default:
            break;
      }
   };

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }
   return (
      <Protected>
         <Container maxWidth='xl'>
            <form style={{ margin: '8px' }}>
               <Grid container mb={4}>
                  <Grid item xs={12} sm={10}>
                     <Typography variant="h6" id="modal-modal-title" mb={2} className='tittle'>
                        FORM ADIC REM
                     </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2} textAlign="end">
                     <Button variant="outlined" component={Link} to={ROUTE_RM_MONTHLY_REMUNERATION} startIcon={<ArrowBackIosIcon />}>
                        Volver
                     </Button>
                  </Grid>
               </Grid>
               <Grid container spacing={3} className={`e-mb-lg`} >

                  <Grid item xs={12} sm={4}>
                     <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={trabajador}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Trabajador" />}
                     />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                     <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={periodo}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Periodo" />}
                     />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                     <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={mes}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Mes" />}
                     />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                     <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <BusinessIcon />
                           </InputAdornment>
                        }
                        name="valor_compra"
                        placeholder="Recargo 30% día domingo(2107)"
                        //value={data.nombre_rz}
                        // value={props.valueContrato.valor_compra}
                        onChange={handleInput}
                        //onBlur={handleInputBlur}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.valor_compra}
                        disableElevation
                        validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                     <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <BusinessIcon />
                           </InputAdornment>
                        }
                        name="valor_compra"
                        placeholder="Remun. variable pagada en vacaciones(2108)"
                        //value={data.nombre_rz}
                        // value={props.valueContrato.valor_compra}
                        onChange={handleInput}
                        //onBlur={handleInputBlur}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.valor_compra}
                        disableElevation
                        validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                     <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <AttachMoneyIcon />
                           </InputAdornment>
                        }
                        name="monto"
                        placeholder="Remun. variable pagada en clausura(2109)"
                        //value={data.nombre_rz}
                        // value={props.valueContrato.vida_financiera}
                        onChange={handleInput}
                        //onBlur={handleInputBlur}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.vida_financiera}
                        disableElevation
                        validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                     <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <BusinessIcon />
                           </InputAdornment>
                        }
                        name="valor_compra"
                        placeholder="Indemnización por feriado legal(2313)2"
                        //value={data.nombre_rz}
                        // value={props.valueContrato.valor_compra}
                        onChange={handleInput}
                        //onBlur={handleInputBlur}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.valor_compra}
                        disableElevation
                        validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                     <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <BusinessIcon />
                           </InputAdornment>
                        }
                        name="valor_compra"
                        placeholder="Indemnización años de servicio(2314)"
                        //value={data.nombre_rz}
                        // value={props.valueContrato.valor_compra}
                        onChange={handleInput}
                        //onBlur={handleInputBlur}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.valor_compra}
                        disableElevation
                        validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                     <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <BusinessIcon />
                           </InputAdornment>
                        }
                        name="valor_compra"
                        placeholder="Indemnización sustitutiva del aviso previo(2315)"
                        //value={data.nombre_rz}
                        // value={props.valueContrato.valor_compra}
                        onChange={handleInput}
                        //onBlur={handleInputBlur}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.valor_compra}
                        disableElevation
                        validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                     <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <BusinessIcon />
                           </InputAdornment>
                        }
                        name="valor_compra"
                        placeholder="Indemnización fuero maternal(2316)"
                        //value={data.nombre_rz}
                        // value={props.valueContrato.valor_compra}
                        onChange={handleInput}
                        //onBlur={handleInputBlur}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.valor_compra}
                        disableElevation
                        validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                     <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <BusinessIcon />
                           </InputAdornment>
                        }
                        name="valor_compra"
                        placeholder="Pago indemnización a todo evento(2331)"
                        //value={data.nombre_rz}
                        // value={props.valueContrato.valor_compra}
                        onChange={handleInput}
                        //onBlur={handleInputBlur}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.valor_compra}
                        disableElevation
                        validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                     <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <BusinessIcon />
                           </InputAdornment>
                        }
                        name="valor_compra"
                        placeholder="Indemnizaciones voluntarias tributables(2417)"
                        //value={data.nombre_rz}
                        // value={props.valueContrato.valor_compra}
                        onChange={handleInput}
                        //onBlur={handleInputBlur}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.valor_compra}
                        disableElevation
                        validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                     <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <BusinessIcon />
                           </InputAdornment>
                        }
                        name="valor_compra"
                        placeholder="Indemnizaciones contractuales tributables(2418)"
                        //value={data.nombre_rz}
                        // value={props.valueContrato.valor_compra}
                        onChange={handleInput}
                        //onBlur={handleInputBlur}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.valor_compra}
                        disableElevation
                        validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                     <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <BusinessIcon />
                           </InputAdornment>
                        }
                        name="valor_compra"
                        placeholder="Aporte empleador indemnización a todo evento(4131)"
                        //value={data.nombre_rz}
                        // value={props.valueContrato.valor_compra}
                        onChange={handleInput}
                        //onBlur={handleInputBlur}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.valor_compra}
                        disableElevation
                        validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                     <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <BusinessIcon />
                           </InputAdornment>
                        }
                        name="valor_compra"
                        placeholder="Gratificación anual (2122)"
                        //value={data.nombre_rz}
                        // value={props.valueContrato.valor_compra}
                        onChange={handleInput}
                        //onBlur={handleInputBlur}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.valor_compra}
                        disableElevation
                        validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                     <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <BusinessIcon />
                           </InputAdornment>
                        }
                        name="valor_compra"
                        placeholder="Impuesto retenido por indemnizaciones(3162)"
                        //value={data.nombre_rz}
                        // value={props.valueContrato.valor_compra}
                        onChange={handleInput}
                        //onBlur={handleInputBlur}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.valor_compra}
                        disableElevation
                        validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                     <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <BusinessIcon />
                           </InputAdornment>
                        }
                        name="valor_compra"
                        placeholder="Columna14"
                        //value={data.nombre_rz}
                        // value={props.valueContrato.valor_compra}
                        onChange={handleInput}
                        //onBlur={handleInputBlur}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.valor_compra}
                        disableElevation
                        validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                     <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <BusinessIcon />
                           </InputAdornment>
                        }
                        name="valor_compra"
                        placeholder="Columna15"
                        //value={data.nombre_rz}
                        // value={props.valueContrato.valor_compra}
                        onChange={handleInput}
                        //onBlur={handleInputBlur}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.valor_compra}
                        disableElevation
                        validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>

                  <Grid item container
                     xs={12}
                     justifyContent='right'
                  >
                     <Grid item>
                        <Button
                           type='submit'
                           variant='contained'
                           onClick={handleSubmit}
                           disabled={loading}
                           disableElevation
                           className='btn_save'
                        >
                           agregar
                        </Button>

                     </Grid>
                  </Grid>
               </Grid>
            </form>
            <Grid container spacing={3} className={`e-mb-lg`} mt={2}>
               <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  <TableContainer component={Paper}>
                     <Table aria-label="simple table">
                        <TableHead>
                           <TableRow>
                              <TableCell>Num</TableCell>
                              <TableCell>Trabajador</TableCell>
                              <TableCell>Mes-Periodo</TableCell>
                              <TableCell>Col1</TableCell>
                              <TableCell>Col2</TableCell>
                              <TableCell>Col3</TableCell>
                              <TableCell>Col4</TableCell>
                              <TableCell>Col5</TableCell>
                              <TableCell>Col6</TableCell>
                              <TableCell>Col7</TableCell>
                              <TableCell>Col8</TableCell>
                              <TableCell>Col9</TableCell>
                              <TableCell>Col10</TableCell>
                              <TableCell>Col11</TableCell>
                              <TableCell>Col12</TableCell>
                              <TableCell>Col13</TableCell>
                              <TableCell>Col14</TableCell>
                              <TableCell>Col15</TableCell>
                              <TableCell>Opción</TableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {rowsListado.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                              return (
                                 <TableRow hover role="checkbox" tabIndex={-1} key={row.num}>
                                    {columns.map((column) => {
                                       const value = row[column.id];
                                       return (
                                          <TableCell key={column.id} align={column.align}>
                                             {column.format && typeof value === 'number'
                                                ? column.format(value)
                                                : value}
                                          </TableCell>
                                       );
                                    })}
                                    <TableCell>
                                       <IconButton
                                       >
                                          <EditIcon />
                                       </IconButton>

                                    </TableCell>
                                 </TableRow>
                              );
                           })}
                        </TableBody>
                     </Table>
                  </TableContainer>
                  <TablePagination
                     rowsPerPageOptions={[10, 25, 100]}
                     component="div"
                     count={rowsListado.length}
                     rowsPerPage={rowsPerPage}
                     page={page}
                     onPageChange={handleChangePage}
                     onRowsPerPageChange={handleChangeRowsPerPage}
                  />
               </Paper>
            </Grid>
         </Container>
      </Protected>
   );
}