import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "../PurchasesByDates.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import '../PurchasesByDates_styles.css'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import { ModalInforms } from '../Modal/ModalInforms';
import Modal from '@mui/material/Modal';


interface Column {
   id: 'tipoDocumento' | 'cantidad' | 'exento' | 'valorNeto'|'ivaREtenido'| 'iva'|'total';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: string) => string;
 }

interface Column1 {
   id: 'int' | 'Fecha' | 'Folio' | 'Tipo'|'Rut'| 'RazonSocial'|'total' |'Usuario';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value2: string) => string;
}


const columns:readonly Column[] = [
   { id: 'tipoDocumento', label: 'Tipo Documento', minWidth: 10 },
   { id: 'cantidad', label: 'Cantidad', minWidth: 10 },
   { id: 'exento', label: 'Exento', minWidth: 10 },
   { id: 'valorNeto', label: 'Valor Neto', minWidth: 10 },
   { id: 'ivaREtenido', label: 'IVA Retenido', minWidth: 10 },
   { id: 'iva', label: 'IVA', minWidth: 10 },
   { id: 'total', label: 'Total', minWidth: 10 },
   ];

const columns1:readonly Column1[] = [
   { id: 'int', label: '#', minWidth: 10 },
   { id: 'Fecha', label: 'Fecha', minWidth: 10 },
   { id: 'Folio', label: 'Folio', minWidth: 10 },
   { id: 'Tipo', label: 'Tipo', minWidth: 10 },
   { id: 'Rut', label: 'Rut', minWidth: 10 },
   { id: 'RazonSocial', label: 'Razon Social', minWidth: 10 },
   { id: 'total', label: 'Total', minWidth: 10 },
   { id: 'Usuario', label: 'Usuario', minWidth: 10 },
   ];

interface Data {
   tipoDocumento: string;
   cantidad: string;
   exento: string;
   valorNeto: string;
   ivaREtenido: string;
   iva: string;
   total: string;
}

interface Data1 {
   int: string;
   Fecha: string;
   Folio: string;
   Tipo: string;
   Rut: string;
   RazonSocial: string;
   total: string;
   Usuario: string;
}

function createData(
   tipoDocumento: string,
   cantidad: string,
   exento: string,
   valorNeto: string,
   ivaREtenido: string,
   iva: string,
   total: string,

   ):Data {
   return { tipoDocumento, cantidad, exento, valorNeto, ivaREtenido, iva, total };
}

function createData1(
   int: string,
   Fecha: string,
   Folio: string,
   Tipo: string,
   Rut: string,
   RazonSocial: string,
   total: string,
   Usuario: string,
   ):Data1 {
   return { int, Fecha, Folio, Tipo, Rut, RazonSocial, total, Usuario };
}

const rowsData = [
   createData('Totales', '$ 0', '$ 0', '$ 0', '$ 0', '$ 0', '$ 0'),
]

const rowsData1 = [
   createData1('1', '03/12/2022', '11245', '1', '1111111-1', '10', '15', '15456'),
]

export const DataAbsence:React.FC<Props> = (props: Props): JSX.Element => {
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value2, setValue2] = useState('');
   const [familyProduct, setFamilyProduct] = useState('');
   const [estado, setEstado]= useState('');
   const [value, setValue] = useState<Date | null>(new Date());
   const [loading, setLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false);

   const handleOpenModal = () => {
      setOpenModal(true);
   };
   const handleCloseModal = () => {
      setOpenModal(false);
   };

   function handleClick() {
     setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 1020,
      height:680,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };
    return (
        <Container maxWidth='xl'>

            <Grid container spacing={3}>
               <Grid item xs={12} md={6} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        LIBRO DE COMPRAS
                     </Typography>
                  </Box>
                  <Box>
                     <Typography variant='subtitle2'  gutterBottom >
                     Periodo: 2022 | Mes: 03 | Estado: LIBRO ABIERTO                   
                     </Typography>
                  </Box>
                  
               </Grid>
                     
               <Grid item xs={12} md={6} className="btn-download">
                  <Box pb={2}>
                  <LoadingButton
                     color="warning"
                     onClick={handleOpenModal}
                     // loading={loading}
                     // loadingPosition="start"
                     // startIcon={<FileDownloadIcon />}
                     variant="contained"
                     className="btn-download2"
                     >
                     Opciones Avanzadas de Libros
                     </LoadingButton>
                  </Box>
               </Grid>
            </Grid>

            
            <div style={{background:'#16556b', borderRadius:'5px', color:'#ffffff',}} >
                        <Typography 
                        mt={2}
                        mb={2}
                        style={{padding:'3px'}}
                        variant="subtitle2"
                        align='center'
                        >Resumen por Tipo de Documentos:	
                        </Typography>
            </div>

            <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:220}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                                 {column.label}
                              </TableCell>
                           ))}
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.tipoDocumento}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'string'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}

                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
              
            </Paper>

           
            <div style={{background:'#16556b', borderRadius:'5px', color:'#ffffff',}}  >
                        <Typography 
                        mt={2}
                        mb={2}
                        style={{padding:'3px'}}
                        variant="subtitle2"
                        align='center'
                        >Listado	
                        </Typography>
            </div>

            <Paper sx={{ width: '100%', overflow: 'hidden'}} >
               <TableContainer sx={{maxHeight:220}} >
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           {columns1.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                                 {column.label}
                              </TableCell>
                              
                           ))}
                           <TableCell>Opcion</TableCell>

                        </TableRow>
                     </TableHead>
                     <TableBody>
                     {rowsData1.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.int}>
                                 {columns1.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'string'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                  <TableCell>
                                       <IconButton aria-label="delete">
                                          <DeleteIcon style={{color:'red'}}/>
                                       </IconButton>
                                       <IconButton aria-label="edit">
                                          <EditIcon style={{color:'green'}}/>
                                       </IconButton>
         
                                 </TableCell>

                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
            </Paper>

            <Modal
                  open={openModal}
                  onClose={handleCloseModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <ModalInforms entity={undefined}/>
                  </Box>
            </Modal>

         </Container>
     
    );
}