import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "./GcCustomerProvider.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './GcCustomerProvider_styles.css'
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import Modal from '@mui/material/Modal';
import { DataModal } from "./DataModal";

interface Column {
   id: 'rut' | 'razonSocial' | 'fantasia'|'Giro' | 'telefono' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

const columns:readonly Column[] = [
   { id: 'rut', label: 'Rut', minWidth: 100 },
   { id: 'razonSocial', label: 'Razon Social', minWidth: 100 },
   { id: 'fantasia', label: 'Fantasia', minWidth: 100 },
   { id: 'Giro', label: 'Giro', minWidth: 100 },
   { id: 'telefono', label: 'Telefono', minWidth: 100 },
   ];

interface Data {
   rut: string;
   razonSocial: string;
   fantasia: string;
   Giro: string;
   telefono: string;
}

function createDataProviders(
   rut: string,
   razonSocial: string,
   fantasia: string,
   Giro: string,
   telefono: string,
   ):Data {
   return { rut, razonSocial, fantasia, Giro, telefono };
}

const rowsDataProviders = [
   createDataProviders('12345678-9', 'Razon Social 1', 'Fantasia 1', 'Giro 1', 'Telefono 1'),
   createDataProviders('12345678-9', 'Razon Social 2', 'Fantasia 2', 'Giro 2', 'Telefono 2'),
   createDataProviders('12345678-9', 'Razon Social 3', 'Fantasia 3', 'Giro 3', 'Telefono 3'),
   createDataProviders('12345678-9', 'Razon Social 4', 'Fantasia 4', 'Giro 4', 'Telefono 4'),
   createDataProviders('12345678-9', 'Razon Social 5', 'Fantasia 5', 'Giro 5', 'Telefono 5'),
   createDataProviders('12345678-9', 'Razon Social 6', 'Fantasia 6', 'Giro 6', 'Telefono 6'),
]


export const GcCustomerProvider:React.FC<Props> = (props: Props): JSX.Element => {
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [pais, setPais] = useState('');
   const [estado, setEstado]= useState('');
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);
   const [open, setOpen] = useState(false);

    const handleChangePais = (event: SelectChangeEvent) => {
      setPais(event.target.value);
    };
    const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
    };
   function handleClick() {
     setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const paises= [
      { value:1, label:'Chile'},
      { value:2, label:'Argentina'},
      { value:3, label:'Brasil'},
      { value:4, label:'Colombia'},
      { value:5, label:'Peru'},
      { value:6, label:'Venezuela'},
   ];
   const estados= [
      { value: '1', label: 'Activo' },
      { value: '2', label: 'Bloqueo Comercial' },
   ]

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 720,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

    return (
        <Protected>
        <Container maxWidth='xl'>
           <Grid container spacing={3}>
               <Grid item xs={12} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        MANTENEDOR DE CLIENTE Y PROVEEDORES EXTRANJEROS
                     </Typography>
                  </Box>
                  
               </Grid>
                     
               <Grid item xs={12} sm={2}>
                  <TextField
                     label="Identificador"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={2}>
                  <TextField
                     label="Razón Social"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={2}>
                  <TextField
                     label="Nombre Fantasía"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={2}>
                  <TextField
                     label="Glosa Giro"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={2}>
                  <TextField
                     label="Teléfono"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={2}>
                  <TextField
                     label="Fax"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
                  <TextField
                     label="Email"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
               <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={estados}
                        renderInput={(params) => <TextField {...params} label="Estado Comercial" />}
                     />
               </Grid>

               <Grid item xs={12} sm={6}>
                  <TextField
                     label="Observación"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={6}>
                  <TextField
                     label="Dirección"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>
               <Grid item xs={12} sm={2}>
                  <TextField
                     label="Comuna"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>
               <Grid item xs={12} sm={2}>
                  <TextField
                     label="Ciudad"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={2}>
               <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={paises}
                        renderInput={(params) => <TextField {...params} label="Pais" />}
               />
               </Grid>
               <Grid item container
                     xs={12}
                     mt={2}
                  justifyContent='right'
                  >
                        <Grid item>
                           <Button
                              type='submit'
                              variant='contained'
                              // onClick={handleSubmit}
                              // disabled={loading}
                              disableElevation
                              className='btn_save'
                           >
                                    <span>Agregar</span>
                           </Button>
                        </Grid>
               </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={2}>
               LISTADO DE REGISTRO INGRESADO
            </Typography>

            <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:440}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           <TableCell>N</TableCell>
                           <TableCell>Nombre</TableCell>
                           <TableCell>Descripcion</TableCell>
                           <TableCell>Estado</TableCell>
                           <TableCell>Exportar</TableCell>
                           <TableCell>Opcion</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsDataProviders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.rut}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                       <IconButton 
                                          >
                                             <ContactPhoneIcon 
                                          onClick={handleOpen}
                                       />
                                       </IconButton>
                                 </TableCell>
                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsDataProviders.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
            </Paper>

         </Container>
            <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <DataModal entity={undefined}/>
                  </Box>
            </Modal>
     </Protected>
    );
   };

