import React, { useState, useMemo, useRef, useEffect } from "react";
import { FunctionComponent } from "react";
import { Grid, Typography } from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import Link from "@mui/material/Link";
import { ROUTE_F_NO_ED_BILLINGS } from "@/toolbox/constants/route-map";

export const DocumentsNoElectronic: FunctionComponent = () => {
   return (
      <>
         <Grid container spacing={2} pl={5} mt={1}>
            <Grid container item>
               <Link href={"ROUTE_F_NO_ED_BILLINGS"} underline="none">
                  <Grid className={"enlacesDoc"}>
                     <ArticleIcon />
                     <Typography variant="subtitle1" ml={2}>
                        FACTURA
                     </Typography>
                  </Grid>
               </Link>
            </Grid>
            <Grid container item>
               <Link href={""} underline="none">
                  <Grid className={"enlacesDoc"}>
                     <ArticleIcon />
                     <Typography variant="subtitle1" ml={2}>
                        FACTURAR EXENTA
                     </Typography>
                  </Grid>
               </Link>
            </Grid>
            <Grid container item>
               <Link href={""} underline="none">
                  <Grid className={"enlacesDoc"}>
                     <ArticleIcon />
                     <Typography variant="subtitle1" ml={2}>
                        GUIA DE DESPACHO
                     </Typography>
                  </Grid>
               </Link>
            </Grid>
            <Grid container item>
               <Link href={""} underline="none">
                  <Grid className={"enlacesDoc"}>
                     <ArticleIcon />
                     <Typography variant="subtitle1" ml={2}>
                        FACTURA DE EXPORTACION(en mantenimiento)
                     </Typography>
                  </Grid>
               </Link>
            </Grid>
            <Grid container item>
               <Link href={""} underline="none">
                  <Grid className={"enlacesDoc"}>
                     <ArticleIcon />
                     <Typography variant="subtitle1" ml={2}>
                        NOTA DE CREDITO DE EXPORTACION (En Mantención NO
                        UTILIZAR)
                     </Typography>
                  </Grid>
               </Link>
            </Grid>
            <Grid container item>
               <Link href={""} underline="none">
                  <Grid className={"enlacesDoc"}>
                     <ArticleIcon />
                     <Typography variant="subtitle1" ml={2}>
                        BOLETAS
                     </Typography>
                  </Grid>
               </Link>
            </Grid>

            <Grid container item>
               <Link href={""} underline="none">
                  <Grid className={"enlacesDoc"}>
                     <ArticleIcon />
                     <Typography variant="subtitle1" ml={2}>
                        BOLETAS DETALLE
                     </Typography>
                  </Grid>
               </Link>
            </Grid>
            <Grid container item>
               <Link href={""} underline="none">
                  <Grid className={"enlacesDoc"}>
                     <ArticleIcon />
                     <Typography variant="subtitle1" ml={2}>
                        BOLETAS EXENTA DETALLE
                     </Typography>
                  </Grid>
               </Link>
            </Grid>
            <Grid container item>
               <Link href={""} underline="none">
                  <Grid className={"enlacesDoc"}>
                     <ArticleIcon />
                     <Typography variant="subtitle1" ml={2}>
                        INTERNO VENTAS
                     </Typography>
                  </Grid>
               </Link>
            </Grid>
            <Grid container item>
               <Link href={""} underline="none">
                  <Grid className={"enlacesDoc"}>
                     <ArticleIcon />
                     <Typography variant="subtitle1" ml={2}>
                        LIQUIDACIÓN DE FACTURA
                     </Typography>
                  </Grid>
               </Link>
            </Grid>
            <Grid container item>
               <Link href={""} underline="none">
                  <Grid className={"enlacesDoc"}>
                     <ArticleIcon />
                     <Typography variant="subtitle1" ml={2}>
                        BOLETAS MASIVAS
                     </Typography>
                  </Grid>
               </Link>
            </Grid>
            <Grid container item>
               <Link href={""} underline="none">
                  <Grid className={"enlacesDoc"}>
                     <ArticleIcon />
                     <Typography variant="subtitle1" ml={2}>
                        VALE DE COMPROBANTE MEDIO DE PAGO ELECTRONICO
                     </Typography>
                  </Grid>
               </Link>
            </Grid>
         </Grid>
      </>
   );
};
