import React, { useState, useMemo, useRef, useEffect } from "react";
import { FunctionComponent } from "react";
import { Grid, Typography } from "@mui/material";

import ArticleIcon from "@mui/icons-material/Article";
import Link from "@mui/material/Link";
import { ROUTE_EV_DE_ELECTRONIC_TICKET } from "@/toolbox/constants/route-map";

export const VtaCommodityNoNac: FunctionComponent = () => {
   return (
      <>
         <Grid container spacing={2} pl={5} mt={1}>
            <Grid container item>
               <Link href={""} underline="none">
                  <Grid className={"enlacesDoc"}>
                     <ArticleIcon />
                     <Typography
                        variant="subtitle1"
                        ml={2}
                        display="block"
                        gutterBottom
                     >
                        FACTURA DE VENTA
                     </Typography>
                  </Grid>
                  <Grid className={"enlacesDoc2"}>
                     <Typography variant="caption" ml={5} mt={1}>
                        DE MERCADERIAS SITUADAS EB EL EXTRANJERO O SITUADAS EB
                        CHILE NO NACIONALIZADAS
                     </Typography>
                  </Grid>
               </Link>
            </Grid>
            <Grid container item>
               <Link href={""} underline="none">
                  <Grid className={"enlacesDoc"}>
                     <ArticleIcon />
                     <Typography
                        variant="subtitle1"
                        ml={2}
                        display="block"
                        gutterBottom
                     >
                        NOTA DE INCREMENTO DE VENTA
                     </Typography>
                  </Grid>
                  <Grid className={"enlacesDoc2"}>
                     <Typography variant="caption" ml={5} mt={1}>
                        CONVENCIONES SOBRE MERCADERIAS SITUADAS EN EL EXTRANJERO
                        O SITUADAS EN CHILE Y NO NACIONALIZADAS
                     </Typography>
                  </Grid>
               </Link>
            </Grid>
            <Grid container item>
               <Link href={""} underline="none">
                  <Grid className={"enlacesDoc"}>
                     <ArticleIcon />
                     <Typography
                        variant="subtitle1"
                        ml={2}
                        display="block"
                        gutterBottom
                     >
                        NOTA DE DISMINUCION DE VENTA
                     </Typography>
                  </Grid>
                  <Grid className={"enlacesDoc2"}>
                     <Typography variant="caption" ml={5} mt={1}>
                        CONVENCIONES SOBRE MERCADERIAS SITUADAS EN EL EXTRANJERO
                        O SITUADAS EN CHILE Y NO NACIONALIZADAS
                     </Typography>
                  </Grid>
               </Link>
            </Grid>
            <Grid container item>
               <Link href={""} underline="none">
                  <Grid className={"enlacesDoc"}>
                     <ArticleIcon />
                     <Typography
                        variant="subtitle1"
                        ml={2}
                        display="block"
                        gutterBottom
                     >
                        NOTA DE INCREMENTO EN EL VALOR DE COMPRAS
                     </Typography>
                  </Grid>
                  <Grid className={"enlacesDoc2"}>
                     <Typography variant="caption" ml={5} mt={1}>
                        CONVENCIONES SOBRE MERCADERIAS SITUADAS EN EL EXTRANJERO
                        O SITUADAS EN CHILE Y NO NACIONALIZADAS
                     </Typography>
                  </Grid>
               </Link>
            </Grid>
            <Grid container item>
               <Link href={""} underline="none">
                  <Grid className={"enlacesDoc"}>
                     <ArticleIcon />
                     <Typography
                        variant="subtitle1"
                        ml={2}
                        display="block"
                        gutterBottom
                     >
                        NOTA DE DISMINUCION EN EL VALOR DE COMPRAS
                     </Typography>
                  </Grid>
                  <Grid className={"enlacesDoc2"}>
                     <Typography variant="caption" ml={5} mt={1}>
                        CONVENCIONES SOBRE MERCADERIAS SITUADAS EN EL EXTRANJERO
                        O SITUADAS EN CHILE Y NO NACIONALIZADAS
                     </Typography>
                  </Grid>
               </Link>
            </Grid>
            <Grid container item>
               <Link href={""} underline="none">
                  <Grid className={"enlacesDoc"}>
                     <ArticleIcon />
                     <Typography
                        variant="subtitle1"
                        ml={2}
                        display="block"
                        gutterBottom
                     >
                        DECLARACION JURADA DE COMPRA
                     </Typography>
                  </Grid>
                  <Grid className={"enlacesDoc2"}>
                     <Typography variant="caption" ml={5} mt={1}>
                        PARA CONVENCIONES SOBRE MERCADERIA SITUADAS EN EL
                        EXTRANJERO O SITUADAS EN CHILE NO NACIONALIZADAS
                     </Typography>
                  </Grid>
               </Link>
            </Grid>
         </Grid>
      </>
   );
};
