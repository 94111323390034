import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, InputAdornment, Modal, IconButton, SelectChangeEvent, Autocomplete, Button, } from '@mui/material'
import { useState, useRef } from "react";
import { Props } from "./BuysByProvider.type"
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import './BuysByProvider_styles.css'
import SearchIcon from '@mui/icons-material/Search';
import { DataModalProvider } from "./ModalListProvider/DataModalProvider";




export const BuysByProvider: React.FC<Props> = (props: Props): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [familyProduct, setFamilyProduct] = useState('');
   const [estado, setEstado] = useState('');
   const [openProvider, setOpenProvider] = useState(false);


   const handleOpenProvider = () => setOpenProvider(true);
   const handleCloseProvider = () => setOpenProvider(false);

   const handleChangeFamilyProduct = (event: SelectChangeEvent) => {
      setFamilyProduct(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const estados = [
      { value: '1', label: 'Activo' },
      { value: '2', label: 'Inactivo' },
   ]

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 800,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

   return (
      <Protected>
         <Container maxWidth='xl' style={{ height: '100vh' }}>
            <Grid container spacing={3}>
               <Grid item xs={12} md={12} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        LISTA DE PROVEEDORES
                     </Typography>
                  </Box>
                  <Typography variant="subtitle2" display="block" gutterBottom mt={1}>
                     Seleccione al proveedor
                  </Typography>

               </Grid>

               <Grid item xs={12} sm={3}>
                  <Input
                     ref={ref => inputRefs.current[2] = ref}
                     appendAdornment={
                        <InputAdornment position="end">
                           <IconButton onClick={handleOpenProvider}>
                              <SearchIcon />
                           </IconButton>

                        </InputAdornment>
                     }
                     name="valor_compra"
                     placeholder="Código"
                     //value={data.nombre_rz}
                     // value={props.valueContrato.valor_compra}
                     // onChange={handleInput}
                     //onBlur={handleInputBlur}
                     // backgroundColor="#F3F3F3"
                     // rules={rules.valor_compra}
                     disableElevation
                     validateOnBlur
                     dense
                     // disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>

               <Grid item xs={12} md={3}>
                  <Button
                     type='submit'
                     variant='contained'
                     // onClick={handleSubmit}
                     // disabled={loading}
                     disableElevation
                     className='btn_next'
                     style={{ backgroundColor: '#ff9700' }}
                  >
                     Seleccionar
                  </Button>
               </Grid>
            </Grid>
         </Container>
         <Modal
            open={openProvider}
            onClose={handleCloseProvider}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataModalProvider entity={undefined} />
            </Box>
         </Modal>
      </Protected>
   );
};

