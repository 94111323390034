import React, { useEffect } from 'react';
import './estilos.css';
// import './img';
// import './main.js';
import banner1 from './img/libros-banner-1.png';
import banner2 from './img/ropa-banner-1.png';
import banner3 from './img/hogar-banner-1.png';
import banner4 from './img/juegos-banner-1.png';
import banner5 from './img/belleza-banner-1.png';
import banner6 from './img/comida-banner-1.png';

import libroGaleria1 from './img/libros-galeria-1.png';
import libroGaleria2 from './img/libros-galeria-2.png';
import libroGaleria3 from './img/libros-galeria-3.png';
import libroGaleria4 from './img/libros-galeria-4.png';
import ropaGaleria1 from './img/ropa-galeria-1.png';
import ropaGaleria2 from './img/ropa-galeria-2.png';
import ropaGaleria3 from './img/ropa-galeria-3.png';
import ropaGaleria4 from './img/ropa-galeria-4.png';
import hogarGaleria1 from './img/hogar-galeria-1.png';
import hogarGaleria2 from './img/hogar-galeria-2.png';
import hogarGaleria3 from './img/hogar-galeria-3.png';
import hogarGaleria4 from './img/hogar-galeria-4.png';
import juegoGaleria1 from './img/juegos-galeria-1.png';
import juegoGaleria2 from './img/juegos-galeria-2.png';
import juegoGaleria3 from './img/juegos-galeria-3.png';
import juegoGaleria4 from './img/juegos-galeria-4.png';
import bellezaGaleria1 from './img/belleza-galeria-1.png';
import bellezaGaleria2 from './img/belleza-galeria-2.png';
import bellezaGaleria3 from './img/belleza-galeria-3.png';
import bellezaGaleria4 from './img/belleza-galeria-4.png';
import comidaGaleria1 from './img/comida-galeria-1.png';
import comidaGaleria2 from './img/comida-galeria-2.png';
import comidaGaleria3 from './img/comida-galeria-3.png';
import comidaGaleria4 from './img/comida-galeria-4.png';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import {
	ROUTE_GENERATOR_DETAIL,
	ROUTE_GENERATOR_NOT_ISSUED,
	ROUTE_PROVIDER,
	ROUTE_CENTER_BENEFICIO,
	ROUTE_HOME,
	ROUTE_OC_SUPPLIES,
	ROUTE_OC_PRODUCTS,
	ROUTE_RUBRO,
	ROUTE_BODEGA,
	ROUTE_FIXED_ASSETS,
	ROUTE_CONTRATO,
	ROUTE_INSUMOS_OTHER,
	ROUTE_STATE_OC,
	ROUTE_ARTICLE_GROUP,
	ROUTE_DECISION_STATE,
	ROUTE_CURRENT_ACOUNT,
	ROUTE_BUY_REQUEST,
	ROUTE_RUT_NOT_REJECTED,
	ROUTE_BUY_REQUEST_BOOK,
	ROUTE_REQUEST_OFERT,
	ROUTE_REQUEST_OFERT_BOOK,
	ROUTE_DECISION_OC,
	ROUTE_BOOK_PURCHASE_ORDER,
	ROUTE_INTERNAL_GUIDE_OC,
	ROUTE_GC_PROVIDER,
	ROUTE_GC_MASTER_SEARCH,
	ROUTE_GC_CLIENT_PERSON,
	ROUTE_GC_FAMILIES,
	ROUTE_GC_LINES,
	ROUTE_GC_DISPATCH_ROUTES,
	ROUTE_GC_REPORT_SV,
	ROUTE_GC_PRICE_LIST,
	ROUTE_GC_VARIOUS_CONFIGURATIONS,
	ROUTE_GC_CUSTUMER,
	ROUTE_GC_CUSTUMER_PROVIDER,
	ROUTE_GC_CUSTUMER_PROSPECT,
	ROUTE_GC_PRODUCTS_SERVICE,
	ROUTE_GC_SUB_FAMILY,
	ROUTE_GC_BUSINESS_AREA,
	ROUTE_GC_VENDOR,
	ROUTE_GC_PRODUCT_LIST,
	ROUTE_EV_SALES_DATES,
	ROUTE_EV_QUOTE_BOOKS,
	ROUTE_EV_FILL_RATE,
	ROUTE_EV_CHECK_ROYALTY,
	ROUTE_EV_CLIENT_SELL,
	ROUTE_EV_SELL_CONSOLIDATE,
	ROUTE_EV_INFORM_ROYALTY,
	ROUTE_CD_PURCHASE_DATE,
	ROUTE_CD_CONSOLIDATE_PURCHASE,
	ROUTE_Cc_BLOACK_COMMERCIAL,
	ROUTE_Cc_PAYMENT_COMMERCIAL,
	ROTE__Rc_REQUEST_BOOK,
	ROUTE_Ev_QUOTE_BOOK,
	ROUTE_ES_MONTHLY,
	ROUTE_EV_AUTORIZATION,

	ROUTE_EV_RUTA_DESPACHO,
	ROUTE_RM_INDICATOR_MONTH,
	ROUTE_RM_DEPARMENT,
	ROUTE_RM_CHARGES,
	ROUTE_RM_EXTRA_HOURS,
	ROUTE_RM_SETTKEMENT_STRUCTURE,
	ROUTE_RM_SETTKEMENT_ACTIVE,
	ROUTE_M_WORKERS,

	ROUTE_RM_SALARY_LIQUIDATION,
	ROUTE_RM_MASSIVE_LIQUIDATION,
	ROUTE_RM_REMUNERATION_SHEET,
	ROUTE_RM_QUOTATION_SHEET,
	ROUTE_RM_LEGAL_HOLIDAY,
	ROUTE_RM_MONTHLY_REMUNERATION,
	ROUTE_RM_CENTRALIZE_WORKER,

	ROUTE_RM_ADVANCE,
	ROUTE_RM_ABSENCES_TARDIES,
	ROUTE_RM_GENERAL_ADVANCE,
	ROUTE_RM_HOURS_EXTRAORDINARY,
	ROUTE_RM_OTHER_HABERES,
	ROUTE_RM_OTHER_DESCOUNTS,
	ROUTE_RM_ABSENSES_TARDIES_GENERALES,
	ROUTE_RM_RESUME_WORKER,
	ROUTE_RM_LOAN_WORKER,

	ROUTE_RM_PAYMENT_WORKERS,
	ROUTE_RN_INICIALIZATION,

	ROUTE_RM_CONTRATE_WORKERS,
	ROUTE_RM_CONTRATE_STRUCTURE,

	ROUTE_RM_FINICOS_ESTRUCTURA,
	ROUTE_RM_FINICOS_WORKER,

	ROUTE_RM_INFORMATION_WORKER,
	ROUTE_GC_BUYS_PROVIDER,
	ROUTE_GC_CREDIT_LINE,


	ROUTE_MG_ERP_USERS,

	ROUTE_AD_BRANCH_OFFICE,
	ROUTE_EV_LE_BOOKS_NOTE,
	ROUTE_EV_BOOKS_EXPORT_NOTE,
	ROUTE_EV_BOOKS_OC_CUSTOMER,
	ROUTE_EV_LU_BOOKS_NOTE,
	ROUTE_EV_LU_SALES_BOOKS,
	ROUTE_EV_LU_QUOTE_BOOKS,
	ROUTE_EV_LS_QUOTE_BOOKS,
	ROUTE_EV_DOCUMENT_EMISSION,
	ROUTE_EV_LS_BOOK_NOTE_SELL,
	ROUTE_EV_LS_BOOK_NOTE_SELL_GUIDE,
	ROUTE_F_ELECTRONIC_DOCUMENT,
	ROUTE_F_NO_ELECTRONIC_DOCUMENT,
	ROUTE_RC_BUSINESS_CLOSURE,
	ROUTE_EV_DE_MARGIN_SELL,
	ROUTE_RAPPI_CATEGORY


} from '@/toolbox/constants/route-map';

type Props = {
}

export const MenuResponsive: React.FC<Props> = (
	props: Props
): JSX.Element => {

	useEffect(() => {
		const btnAdquisiciones = document.getElementById('btn-adquisiciones'),
			btnGestionComercial = document.getElementById('btn-gestionComercial'),
			btnRemuneraciones = document.getElementById('btn-remuneraciones'),
			btnAdministracion=document.getElementById('btn-administracion'),
			btnCerrarMenu = document.getElementById('btn-menu-cerrar'),
			grid_adquisiciones = document.getElementById('gridAdquisiciones'),
			grid_gestion_comercial = document.getElementById('gridGestionComercial'),
			grid_remuneraciones = document.getElementById('gridRemuneraciones'),
			grid_administracion = document.getElementById('gridAdministracion'),			

			gridDefaultAdqui = document.querySelector('#gridAdquisiciones .contenedor-subcategorias .default'),
			gridDefaultGc = document.querySelector('#gridGestionComercial .contenedor-subcategorias .default'),
			gridDefaultRemu = document.querySelector('#gridRemuneraciones .contenedor-subcategorias .default'),
			gridDefaultAdmini = document.querySelector('#gridAdministracion .contenedor-subcategorias .default'),

			contenedorEnlacesNav = document.querySelector('#menu .contenedor-enlaces-nav'),
			contenedorSubCategoria_adquisiciones = document.querySelector('#gridAdquisiciones .contenedor-subcategorias'),
			contenedorSubCategoria_gestionComercial = document.querySelector('#gridGestionComercial .contenedor-subcategorias'),
			contenedorSubCategoria_remuneraciones = document.querySelector('#gridRemuneraciones .contenedor-subcategorias'),
			contenedorSubCategoria_administracion = document.querySelector('#gridAdministracion .contenedor-subcategorias'),
			esDispositivoMovil = () => window.innerWidth <= 800;


		btnAdquisiciones.addEventListener('mouseover', () => {
			if (!esDispositivoMovil()) {
				grid_adquisiciones.classList.add('activo');
				grid_gestion_comercial.classList.remove('activo');
				grid_remuneraciones.classList.remove('activo');
				grid_administracion.classList.remove('activo');

				gridDefaultGc.classList.add('activo');
				gridDefaultRemu.classList.add('activo');
				gridDefaultAdmini.classList.add('activo');
			};

		});
		btnGestionComercial.addEventListener('mouseover', () => {
			if (!esDispositivoMovil()) {
				grid_gestion_comercial.classList.add('activo');
				grid_adquisiciones.classList.remove('activo');
				grid_remuneraciones.classList.remove('activo');
				grid_administracion.classList.remove('activo');

				gridDefaultAdqui.classList.add('activo');
				gridDefaultRemu.classList.add('activo');
				gridDefaultAdmini.classList.add('activo');
				// btnProductos.classList.add('pinta-botones');

				// btnDepartamentos.classList.remove('pinta-botones');
			}
		});
		btnRemuneraciones.addEventListener('mouseover', () => {
			if (!esDispositivoMovil()) {
				grid_remuneraciones.classList.add('activo');
				grid_adquisiciones.classList.remove('activo');
				grid_gestion_comercial.classList.remove('activo');
				grid_administracion.classList.remove('activo');

				gridDefaultAdqui.classList.add('activo');
				gridDefaultGc.classList.add('activo');
				gridDefaultAdmini.classList.add('activo');
			}
		});
		btnAdministracion.addEventListener('mouseover', () => {
			if (!esDispositivoMovil()) {
				grid_administracion.classList.add('activo');
				grid_remuneraciones.classList.remove('activo');
				grid_adquisiciones.classList.remove('activo');
				grid_gestion_comercial.classList.remove('activo');

				gridDefaultAdqui.classList.add('activo');
				gridDefaultGc.classList.add('activo');
				gridDefaultRemu.classList.add('activo');
			}
		});
		// falta implentar la siguiente lógica: Cuando salga el mouse de este (mouseleave), aparte de remover la clase
		// "acitvo, se debe agregar esta misma pero en los otros items, no se puede agregar manualmente porque genera bug
		// cuando el mouse se sale de hover
		grid_adquisiciones.addEventListener('mouseleave', () => {
			if (!esDispositivoMovil()) {
				grid_adquisiciones.classList.remove('activo');
				// btnProductos.classList.remove('pinta-botones');
				// btnDepartamentos.classList.remove('pinta-botones');			
			}
		});
		grid_gestion_comercial.addEventListener('mouseleave', () => {
			if (!esDispositivoMovil()) {
				grid_gestion_comercial.classList.remove('activo');
				btnGestionComercial.classList.remove('pinta-botones');

			}
		});
		grid_remuneraciones.addEventListener('mouseleave', () => {
			if (!esDispositivoMovil()) {
				grid_remuneraciones.classList.remove('activo');
				// btnGestionComercial.classList.remove('pinta-botones');

			}
		});
		grid_administracion.addEventListener('mouseleave', () => {
			if (!esDispositivoMovil()) {
				grid_administracion.classList.remove('activo');
				// btnGestionComercial.classList.remove('pinta-botones');
			}
		});
		// comprobamos que la categoria que seleccionamos tiene el mismo atributo que la subcategoría
		document.querySelectorAll('#menu .categorias a').forEach((elemento) => {
			elemento.addEventListener('click', (e) => {
				if (!esDispositivoMovil()) {
					document.querySelectorAll('#menu .subcategoria').forEach((categoria) => {
						categoria.classList.remove('activo');
						if ((categoria as HTMLInputElement).dataset.categoria == (e.target as HTMLInputElement).dataset.categoria) {
							categoria.classList.add('activo');
						}
					});
				};
			});
		});

		// EventListeners para dispositivo movil.
		document.querySelector('#btn-menu-barras').addEventListener('click', (e) => {
			e.preventDefault();
			if (contenedorEnlacesNav.classList.contains('activo')) {
				contenedorEnlacesNav.classList.remove('activo');
				document.querySelector('body').style.overflow = 'visible';
			} else {
				contenedorEnlacesNav.classList.add('activo');
				document.querySelector('body').style.overflow = 'hidden';
			}
		});

		// Click en boton de todos los departamentos (Para version movil).
		btnAdquisiciones.addEventListener('click', (e) => {
			e.preventDefault();
			grid_adquisiciones.classList.add('activo');
			btnCerrarMenu.classList.add('activo');
		});
		btnGestionComercial.addEventListener('click', (e) => {
			e.preventDefault();
			grid_gestion_comercial.classList.add('activo');
			btnCerrarMenu.classList.add('activo');
		});
		btnRemuneraciones.addEventListener('click', (e) => {
			e.preventDefault();
			grid_remuneraciones.classList.add('activo');
			btnCerrarMenu.classList.add('activo');
		});
		btnAdministracion.addEventListener('click', (e) => {
			e.preventDefault();
			grid_administracion.classList.add('activo');
			btnCerrarMenu.classList.add('activo');
		});

		// Boton de regresar en el menu de categorias
		document.querySelector('#gridAdquisiciones .categorias .btn-regresar').addEventListener('click', (e) => {
			e.preventDefault();
			grid_adquisiciones.classList.remove('activo');
			btnCerrarMenu.classList.remove('activo');
		});
		document.querySelector('#gridGestionComercial .categorias .btn-regresar').addEventListener('click', (e) => {
			e.preventDefault();
			grid_gestion_comercial.classList.remove('activo');
			btnCerrarMenu.classList.remove('activo');
		});
		document.querySelector('#gridRemuneraciones .categorias .btn-regresar').addEventListener('click', (e) => {
			e.preventDefault();
			grid_remuneraciones.classList.remove('activo');
			btnCerrarMenu.classList.remove('activo');
		});
		document.querySelector('#gridAdministracion .categorias .btn-regresar').addEventListener('click', (e) => {
			e.preventDefault();
			grid_administracion.classList.remove('activo');
			btnCerrarMenu.classList.remove('activo');
		});

		document.querySelectorAll('#menu .categorias a').forEach((elemento) => {
			elemento.addEventListener('click', (e) => {
				if (esDispositivoMovil()) {
					contenedorSubCategoria_adquisiciones.classList.add('activo');
					contenedorSubCategoria_gestionComercial.classList.add('activo');
					contenedorSubCategoria_remuneraciones.classList.add('activo');
					contenedorSubCategoria_administracion.classList.add('activo');/////////////////////
					document.querySelectorAll('#menu .subcategoria').forEach((categoria) => {
						categoria.classList.remove('activo');
						if ((categoria as HTMLInputElement).dataset.categoria == (e.target as HTMLInputElement).dataset.categoria) {
							categoria.classList.add('activo');
						}
					});
				}
			});
		});

		// Boton de regresar en el menu de categorias
		document.querySelectorAll('#gridAdquisiciones .contenedor-subcategorias .btn-regresar').forEach((boton) => {
			boton.addEventListener('click', (e) => {
				e.preventDefault();
				contenedorSubCategoria_adquisiciones.classList.remove('activo');
				contenedorSubCategoria_gestionComercial.classList.remove('activo');
				contenedorSubCategoria_remuneraciones.classList.remove('activo');
			});
		});
		document.querySelectorAll('#gridGestionComercial .contenedor-subcategorias .btn-regresar').forEach((boton) => {
			boton.addEventListener('click', (e) => {
				e.preventDefault();
				contenedorSubCategoria_adquisiciones.classList.remove('activo');
				contenedorSubCategoria_gestionComercial.classList.remove('activo');
			});
		});
		document.querySelectorAll('#gridRemuneraciones .contenedor-subcategorias .btn-regresar').forEach((boton) => {
			boton.addEventListener('click', (e) => {
				e.preventDefault();
				contenedorSubCategoria_adquisiciones.classList.remove('activo');
				contenedorSubCategoria_gestionComercial.classList.remove('activo');
				contenedorSubCategoria_remuneraciones.classList.remove('activo');
			});
		});
		document.querySelectorAll('#gridAdministracion .contenedor-subcategorias .btn-regresar').forEach((boton) => {
			boton.addEventListener('click', (e) => {
				e.preventDefault();
				contenedorSubCategoria_adquisiciones.classList.remove('activo');
				contenedorSubCategoria_gestionComercial.classList.remove('activo');
				contenedorSubCategoria_remuneraciones.classList.remove('activo');
				contenedorSubCategoria_administracion.classList.remove('activo');
			});
		});

		btnCerrarMenu.addEventListener('click', (e) => {
			e.preventDefault();
			document.querySelectorAll('#menu .activo').forEach((elemento) => {
				elemento.classList.remove('activo');
			});
			document.querySelector('body').style.overflow = 'visible';
		});
	}, []);

	return (
		<div className="menu" id="menu">
			<div className="contenedor contenedor-botones-menu">
				<button id="btn-menu-barras" className="btn-menu-barras"><i><MenuIcon /></i></button>
				<button id="btn-menu-cerrar" className="btn-menu-cerrar"><i><CloseIcon /></i></button>
			</div>

			<div className="contenedor contenedor-enlaces-nav">
				<div className="enlaces" id="enlaceInicio">
					<a href={ROUTE_HOME}>Inicio</a>
				</div>

				<div className="btn-departamentos" id="btn-gestionComercial">
					<span>Gestión Comercial</span>
					<i><KeyboardArrowDownIcon /></i>
				</div>

				<div className="btn-departamentos" id="btn-adquisiciones">
					<span>Adquisiciones</span>
					<i><KeyboardArrowDownIcon /></i>
				</div>

				<div className="btn-departamentos" id="btn-remuneraciones">
					<span>Remuneraciones</span>
					<i><KeyboardArrowDownIcon /></i>
				</div>
				<div className="btn-departamentos" id="btn-administracion">
					<span>Administración</span>
					<i><KeyboardArrowDownIcon /></i>
				</div>

				{/* <div className="enlaces" >
					<a href={ROUTE_GENERATOR_DETAIL}>Boleta Procesada</a>
				</div>
				<div className="enlaces" >
					<a href={ROUTE_GENERATOR_NOT_ISSUED}>Boleta no Emitida</a>
				</div>
				<div className="enlaces" >
					<a href={ROUTE_GENERATOR_PROCESSED_CANCELED}>Boleta Anulada</a>
				</div> */}
				<div className="enlaces" id="enlaceAyuda">
					<a href="#">Ayuda</a>
				</div>
				<div className="enlaces" id="enlaceAyuda">
					<a href={ROUTE_RAPPI_CATEGORY}>Rappi</a>
				</div>
			</div>

			<div className="contenedor contenedor-grid">
				<div className="gridMenu" id="gridAdquisiciones">
					<div className="categorias">
						<button className="btn-regresar">Regresar</button>
						<h3 className="subtitulo">Categorias</h3>

						<a data-categoria="categoria-adquisiciones-maestros">Maestros<i className="fas fa-angle-right"></i></a>
						<a data-categoria="categoria-adquisiciones-solicitud">Solicitud de Compra<i className="fas fa-angle-right"></i></a>
						<a data-categoria="categoria-adquisiciones-orden">Orden de Compra <i className="fas fa-angle-right"></i></a>
					</div>

					<div className="contenedor-subcategorias">
						<div className="subcategoria default activo" data-categoria="categoria-adquisiciones-maestros">
							<div className="enlaces-subcategoria">
								<button className="btn-regresar">Regresar</button>
								<h3 className="subtitulo" style={{ marginBottom: '8px' }}>Maestros</h3>
								<a href={ROUTE_PROVIDER}>Proveedores</a>
								<a href={ROUTE_FIXED_ASSETS}>Activos Fijos</a>
								<a href={ROUTE_INSUMOS_OTHER}>Insumos y Otros</a>{/*???????*/}
								<a href={ROUTE_CONTRATO}>Contratos</a>
							</div>
							<div className="enlaces-subcategoria">
								<h3 className="subtitulo"></h3>
								<br />
								<br />
								<a href={ROUTE_ARTICLE_GROUP}>Grupos de Articulos</a>
								<a href={ROUTE_CENTER_BENEFICIO}>Centro Beneficio</a>
								<a href={ROUTE_RUT_NOT_REJECTED}>Rut Sin Rechazar</a>
								<a href={ROUTE_STATE_OC}>Estado OC</a>
							</div>
							<div className="enlaces-subcategoria">
								<h3 className="subtitulo"></h3>
								<br />
								<br />
								<a href={ROUTE_DECISION_STATE}>Estado de decision</a>
								<a href={ROUTE_CURRENT_ACOUNT}>Cuenta Corriente</a>
								<a href={ROUTE_RUBRO}>Rubro</a>
								<a href={ROUTE_BODEGA}>Bodegas</a>
							</div>
						</div>

						<div className="subcategoria" data-categoria="categoria-adquisiciones-solicitud">
							<div className="enlaces-subcategoria">
								<button className="btn-regresar">Regresar</button>
								<h3 className="subtitulo" style={{ marginBottom: '8px' }}>Solicitud de Compra</h3>
								<a href={ROUTE_BUY_REQUEST}>Solicitud de Compra</a> {/*???????*/}
								<a href={ROUTE_BUY_REQUEST_BOOK}>Libro Solicitud de Compra</a>
								<a href={ROUTE_REQUEST_OFERT}>Solicitud de Oferta</a>
								<a href={ROUTE_REQUEST_OFERT_BOOK}>Libro de Solicitud de Oferta</a>
							</div>
							<div className="enlaces-subcategoria">
								<h3 className="subtitulo"></h3>
								<br />
								<br />
								<br />
								<a href={ROUTE_DECISION_OC}>Decision OC</a>
								<a href={ROUTE_INTERNAL_GUIDE_OC}>Guia Interna OC</a>
							</div>
						</div>

						<div className="subcategoria" data-categoria="categoria-adquisiciones-orden">
							<div className="enlaces-subcategoria">
								<button className="btn-regresar">Regresar</button>
								<h3 className="subtitulo" style={{ marginBottom: '8px' }}>Orden de Compras</h3>
								<a href={ROUTE_OC_SUPPLIES}>OC Insumos</a>
								<a href={ROUTE_OC_PRODUCTS}>OC Productos</a>
								<a href={ROUTE_BOOK_PURCHASE_ORDER}>Libro Ordenes de Compra</a>
							</div>
						</div>
					</div>
				</div>
				{/* SEGUNDO GRID (GESTIÓN COMERCIAL) */}
				<div className="gridMenu" id="gridGestionComercial">
					<div className="categorias">
						<button className="btn-regresar">Regresar</button>
						<h3 className="subtitulo">Categorias</h3>
						{/* Hemos quitado el href="#" por fines educativos */}
						<a data-categoria="categoria-gComercial-maestros">Maestros<i className="fas fa-angle-right"></i></a>
						<a data-categoria="categoria-gComercial-facturacion">Facturación<i className="fas fa-angle-right"></i></a>
						<a data-categoria="categoria-gComercial-estadisticaV">Estadística de Venta<i className="fas fa-angle-right"></i></a>
						<a data-categoria="categoria-gComercial-estadisticaC">Estadística de Compra<i className="fas fa-angle-right"></i></a>
						<a data-categoria="categoria-gComercial-comite">Comité de Crédito<i className="fas fa-angle-right"></i></a>
						<a data-categoria="categoria-gComercial-equipo">Equipo de Venta<i className="fas fa-angle-right"></i></a>
						<a data-categoria="categoria-gComercial-reporte">Reportes y Control<i className="fas fa-angle-right"></i></a>
					</div>

					<div className="contenedor-subcategorias">
						<div className="subcategoria default activo" data-categoria="categoria-gComercial-maestros">
							<div className="enlaces-subcategoria">
								<button className="btn-regresar">Regresar</button>
								<h3 className="subtitulo">Maestros</h3>
								<a href={ROUTE_GC_CUSTUMER}>Clientes</a>
								<a href={ROUTE_GC_PROVIDER}>Proveedores</a>
								<a href={ROUTE_GC_CUSTUMER_PROVIDER}>Clientes/Proveedores Extranjeros</a>
								<a href={ROUTE_GC_MASTER_SEARCH}>Búsqueda Maestro</a>
								<a href={ROUTE_GC_CUSTUMER_PROSPECT}>Prospecto Clientes</a>

							</div>
							<div className="enlaces-subcategoria">
								<h3 className="subtitulo"></h3>

								<a href={ROUTE_GC_CLIENT_PERSON}>Clientes Personas</a>
								<a href={ROUTE_GC_PRODUCTS_SERVICE}>Productos y Servicios</a>
								<a href={ROUTE_GC_FAMILIES}>Familias</a>
								<a href={ROUTE_GC_SUB_FAMILY}>Sub Familias</a>
								<a href={ROUTE_GC_LINES}>Líneas</a>
								<a href={ROUTE_GC_BUSINESS_AREA}>Área de Negocio</a>


							</div>
							<div className="enlaces-subcategoria">
								{/* <h3 className="subtitulo"></h3> */}
								<br />
								<a href={ROUTE_GC_DISPATCH_ROUTES}>Rutas de despacho</a>
								<a href={ROUTE_GC_VENDOR}>Vendedores</a>
								<a href={ROUTE_GC_REPORT_SV}>Info de sucursal por vendedor</a>
								<a href={ROUTE_GC_VARIOUS_CONFIGURATIONS}>Conf. Varias</a>
								<a href={ROUTE_GC_PRODUCT_LIST}>Lista de Productos</a>
								<a href={ROUTE_GC_PRICE_LIST}>Lista de Precios</a>

							</div>
						</div>

						<div className="subcategoria" data-categoria="categoria-gComercial-facturacion">
							<div className="enlaces-subcategoria">
								<button className="btn-regresar">Regresar</button>
								<h3 className="subtitulo">Facturación</h3>
								<a href={ROUTE_F_ELECTRONIC_DOCUMENT}>Documentos Electrónicos</a>
								<a href={ROUTE_F_NO_ELECTRONIC_DOCUMENT}>Documentos No Electrónicos</a>
								<a href="/">Generar Documentos Masivos</a>
							</div>

						</div>

						<div className="subcategoria" data-categoria="categoria-gComercial-estadisticaV">
							<div className="enlaces-subcategoria">
								<button className="btn-regresar">Regresar</button>
								<h3 className="subtitulo">Estadística de Venta</h3>
								<a href={ROUTE_EV_CLIENT_SELL}>Ventas por Cliente</a>
								<a href={ROUTE_EV_SALES_DATES}>Ventas entre fechas</a>
								<a href={ROUTE_EV_SELL_CONSOLIDATE}>Ventas Consolidadas</a>
								<a href={ROUTE_EV_QUOTE_BOOKS}>Libro Cotizaciones</a>
							</div>
							<div className="enlaces-subcategoria">
								<h3 className="subtitulo"></h3>

								<a href={ROUTE_EV_DE_MARGIN_SELL}>Margen de Venta</a>
								<a href={ROUTE_EV_FILL_RATE}>Fill Rate</a>
								<a href={ROUTE_EV_RUTA_DESPACHO}>Ruta Despachos</a>
								<a href={ROUTE_EV_CHECK_ROYALTY}>Verificación Royalty</a>
								<a href={ROUTE_EV_INFORM_ROYALTY}>Informe Royalty</a>
							</div>
						</div>

						<div className="subcategoria" data-categoria="categoria-gComercial-estadisticaC">
							<div className="enlaces-subcategoria">
								<button className="btn-regresar">Regresar</button>
								<h3 className="subtitulo">Estadística de Compra</h3>
								<a href={ROUTE_GC_BUYS_PROVIDER}>Compras por Proveedor</a>
								<a href={ROUTE_CD_PURCHASE_DATE}>Compras entre Fechas</a>
								<a href={ROUTE_CD_CONSOLIDATE_PURCHASE}>Compras Consolidadas</a>
							</div>
						</div>

						<div className="subcategoria" data-categoria="categoria-gComercial-comite">
							<div className="enlaces-subcategoria">
								<button className="btn-regresar">Regresar</button>
								<h3 className="subtitulo">Comite de Credito</h3>
								<a href={ROUTE_Cc_PAYMENT_COMMERCIAL}>Condiciones Comerciales de Pago o cobro</a>
								<a href={ROUTE_Cc_BLOACK_COMMERCIAL}>Bloqueo Comercial</a>
								<a href={ROUTE_GC_CREDIT_LINE}>Linea de Credito</a>
							</div>
						</div>

						<div className="subcategoria" data-categoria="categoria-gComercial-equipo">
							<div className="enlaces-subcategoria">
								<button className="btn-regresar">Regresar</button>
								{/* <h3 className="subtitulo">Estadística de Venta</h3> */}
								<a href={ROUTE_EV_DOCUMENT_EMISSION}>Emisión de Documentos</a>
								<h4 className="subtitulo">Libros por Empresa</h4>
								<a href={ROUTE_EV_QUOTE_BOOKS}>Libro Cotizaciones</a>
								<a href={ROUTE_EV_LE_BOOKS_NOTE}>Libro Nota de Ventas</a>
								<a href={ROUTE_EV_BOOKS_EXPORT_NOTE}>Libro Nota de Ventas Exportación</a>
								<a href={ROUTE_EV_BOOKS_OC_CUSTOMER}>Libro OC Clientes</a>
							</div>
							<div className="enlaces-subcategoria">
								<h4 className="subtitulo">Estadísticas</h4>
								<a href={ROUTE_ES_MONTHLY}>Mensuales</a>
								<h4 className="subtitulo">Autorización</h4>
								<a href={ROUTE_EV_AUTORIZATION}>Autorizar</a>
								<h4 className="subtitulo">Libros por Usuario</h4>
								<a href={ROUTE_EV_LU_QUOTE_BOOKS}>Libro Cotizaciones</a>

							</div>
							<div className="enlaces-subcategoria">
								<a href={ROUTE_EV_LU_BOOKS_NOTE}>Libro Notas de Venta</a>
								<a href={ROUTE_EV_LU_SALES_BOOKS}>Libro de Ventas</a>
								<h4 className="subtitulo">Libros por Sucursal</h4>
								<a href={ROUTE_EV_LS_QUOTE_BOOKS}>Libro Cotizaciones</a>
								<a href={ROUTE_EV_LS_BOOK_NOTE_SELL}>Libro Notas de Venta</a>
								<a href={ROUTE_EV_LS_BOOK_NOTE_SELL_GUIDE}>Libro Guías de Despacho</a>
							</div>
						</div>

						<div className="subcategoria" data-categoria="categoria-gComercial-reporte">
							<div className="enlaces-subcategoria">
								<button className="btn-regresar">Regresar</button>
								<h3 className="subtitulo">Reportes y Control</h3>
								<a href={ROUTE_RC_BUSINESS_CLOSURE}>Cierre Comercial</a>
								<a href="/">Proceso facturacion notas de venta</a>

								<a href="/">Cierre Comercial</a>
								<a href="">Proceso facturacion notas de venta</a>

								<a href="/">Proceso facturacion ordenes de compra</a>
								<a href={ROUTE_Ev_QUOTE_BOOK}>Libro Pedido</a>

							</div>
						</div>
					</div>
				</div>
				{/*GRID REMUNERACIONES */}
				<div className="gridMenu" id="gridRemuneraciones">
					<div className="categorias">
						<button className="btn-regresar">Regresar</button>
						<h3 className="subtitulo">Categorias</h3>
						{/* Hemos quitado el href="#" por fines educativos */}
						<a data-categoria="categoria-remuneraciones-maestros">Maestros<i className="fas fa-angle-right"></i></a>
						<a data-categoria="categoria-remuneraciones-novedades">Novedades del Mes<i className="fas fa-angle-right"></i></a>
						<a data-categoria="categoria-remuneraciones-informes">Informes<i className="fas fa-angle-right"></i></a>
						<a data-categoria="categoria-remuneraciones-pagos">Gestión de Pagos<i className="fas fa-angle-right"></i></a>
						<a data-categoria="categoria-remuneraciones-contratos">Gestión de Contratos<i className="fas fa-angle-right"></i></a>
						<a data-categoria="categoria-remuneraciones-finiquitos">Gestión de Finiquitos<i className="fas fa-angle-right"></i></a>
						<a data-categoria="categoria-remuneraciones-prestamos">Gestión de Préstamos<i className="fas fa-angle-right"></i></a>
						<a data-categoria="categoria-remuneraciones-reloj">Reloj Control<i className="fas fa-angle-right"></i></a>
					</div>

					<div className="contenedor-subcategorias">
						<div className="subcategoria default activo" data-categoria="categoria-remuneraciones-maestros">
							<div className="enlaces-subcategoria">
								<button className="btn-regresar">Regresar</button>
								<h3 className="subtitulo">Maestros</h3>
								<a href={ROUTE_RM_INDICATOR_MONTH}>Indicadores del Mes</a>
								<a href={ROUTE_M_WORKERS}>Trabajadores</a>
								<a href={ROUTE_RM_DEPARMENT}>Departamentos</a>
								<a href={ROUTE_RM_CHARGES}>Cargos</a>
								<a href={ROUTE_RM_EXTRA_HOURS}>Valor Hrs Extras</a>
							</div>
							<div className="enlaces-subcategoria">
								<h3 className="subtitulo"></h3>
								<a href={ROUTE_RM_SETTKEMENT_STRUCTURE}>Extructura Liquidación</a>
								<a href={ROUTE_RM_SETTKEMENT_ACTIVE}>Liquidación Activa</a>
							</div>
						</div>

						<div className="subcategoria" data-categoria="categoria-remuneraciones-novedades">
							<div className="enlaces-subcategoria">
								<button className="btn-regresar">Regresar</button>
								<h3 className="subtitulo">Novedades del Mes</h3>
								<a href={ROUTE_RM_ADVANCE}>Anticipio</a>
								<a href={ROUTE_RM_GENERAL_ADVANCE}>Anticipo General</a>
								<a href={ROUTE_RM_ABSENCES_TARDIES}>Inasistencias y Atrasos</a>
								<a href={ROUTE_RM_ABSENSES_TARDIES_GENERALES}>Inasistencias y Atraso General</a>

							</div>
							<div className="enlaces-subcategoria">
								<h3 className="subtitulo"></h3>
								<a href={ROUTE_RM_HOURS_EXTRAORDINARY}>Horas Extraordinarias</a>
								<a href={ROUTE_RM_OTHER_HABERES}>Asig. Otros Haberes</a>
								<a href={ROUTE_RM_OTHER_DESCOUNTS}>Asig. Otros Descuentos</a>
							</div>
						</div>

						<div className="subcategoria" data-categoria="categoria-remuneraciones-informes">
							<div className="enlaces-subcategoria">
								<button className="btn-regresar">Regresar</button>
								<h3 className="subtitulo">Informes</h3>
								<a href={ROUTE_RM_SALARY_LIQUIDATION}>Liquidación de Sueldos</a>
								<a href={ROUTE_RM_MASSIVE_LIQUIDATION}>Liquidación masiva</a>
								<a href={ROUTE_RM_REMUNERATION_SHEET}>Planilla de Remuneraciones</a>
								<a href={ROUTE_RM_QUOTATION_SHEET}>Planilla de Cotizaciones</a>

							</div>
							<div className="enlaces-subcategoria">
								<h3 className="subtitulo"></h3>
								<a href={ROUTE_RM_MONTHLY_REMUNERATION}>Planillas mensuales Remuneraciones</a>
								<a href={ROUTE_RM_LEGAL_HOLIDAY}>Control de Feriado legal</a>
								<a href={ROUTE_RM_CENTRALIZE_WORKER}>Centralización por Trabajador </a>
							</div>
						</div>

						<div className="subcategoria" data-categoria="categoria-remuneraciones-pagos">
							<div className="enlaces-subcategoria">
								<button className="btn-regresar">Regresar</button>
								<h3 className="subtitulo">Gestión de Pagos</h3>
								<a href={ROUTE_RM_PAYMENT_WORKERS}>Trabajadores</a>
								<a href={ROUTE_RN_INICIALIZATION}>Pago por Trabajor</a>
							</div>
						</div>

						<div className="subcategoria" data-categoria="categoria-remuneraciones-contratos">
							<div className="enlaces-subcategoria">
								<button className="btn-regresar">Regresar</button>
								<h3 className="subtitulo">Gestión de Contratos</h3>
								<a href={ROUTE_RM_CONTRATE_WORKERS}>Contratos por Trabajador</a>
								<a href={ROUTE_RM_CONTRATE_STRUCTURE}>Estructura de Contrato</a>
							</div>
						</div>

						<div className="subcategoria" data-categoria="categoria-remuneraciones-finiquitos">
							<div className="enlaces-subcategoria">
								<button className="btn-regresar">Regresar</button>
								<h3 className="subtitulo">Gestión de Finiquito</h3>
								<a href={ROUTE_RM_FINICOS_ESTRUCTURA}>Finiquitos por Trabajador</a>
								<a href={ROUTE_RM_FINICOS_WORKER}>Estructura de Finiquito</a>
							</div>
						</div>
						<div className="subcategoria" data-categoria="categoria-remuneraciones-prestamos">
							<div className="enlaces-subcategoria">
								<button className="btn-regresar">Regresar</button>
								<h3 className="subtitulo">Gestión de Préstamos</h3>
								<a href={ROUTE_RM_LOAN_WORKER}>Préstamos por trabajador</a>
								<a href={ROUTE_RM_RESUME_WORKER}>Resumen por trabajador</a>
							</div>
						</div>
						<div className="subcategoria" data-categoria="categoria-remuneraciones-reloj">
							<div className="enlaces-subcategoria">
								<button className="btn-regresar">Regresar</button>
								<h3 className="subtitulo">Reloj Control</h3>
								<a href={ROUTE_RM_INFORMATION_WORKER}>Informe por Trabajadores</a>
							</div>
						</div>

					</div>
				</div>
				{/*GRID ADMINISTRACIÓN */}
				<div className="gridMenu" id="gridAdministracion">
					<div className="categorias">
						<button className="btn-regresar">Regresar</button>
						<h3 className="subtitulo">Categorias</h3>
						{/* Hemos quitado el href="#" por fines educativos */}
						<a data-categoria="categoria-administracion-erp">ERP<i className="fas fa-angle-right"></i></a>
						<a data-categoria="categoria-administracion-factura">Factura Electrónica<i className="fas fa-angle-right"></i></a>					
					</div>

					<div className="contenedor-subcategorias">
						<div className="subcategoria default activo" data-categoria="categoria-administracion-erp">
							<div className="enlaces-subcategoria">
								<button className="btn-regresar">Regresar</button>	
								<h4 className="subtitulo">Usuarios</h4>									
								<a href={ROUTE_MG_ERP_USERS}>Crear Usuarios</a>
								<a href="">Permisos</a>
								<a href="">Últimos Accesos</a>
								<a href="">Control de Acceso</a>
								<h4 className="subtitulo">Workflow</h4>	
								<a href="">Flujo de Trabajo</a>
								<a href="">Grupos de Trabajo</a>
								<a href="">Grupos y Usuarios</a>
								<a href="">Visualización notas de Venta</a>								
							</div>
							<div className="enlaces-subcategoria">
								{/* <h3 className="subtitulo"></h3>*/}	
								<a href="">Cambio de estado notas de venta</a>							
								<a href="">Permisos Página de Inicio</a>
								<h4 className="subtitulo">Acciones Administrador</h4>	
								<a href="">Tesorería</a>
								<a href="">Cierre Anual Contable</a>	
								<a href="">Contactos</a>
								<h4 className="subtitulo">Configuraciones</h4>
								<a href="">Email</a>
								<a href={ROUTE_AD_BRANCH_OFFICE}>Sucursales</a>	
								<a href="">Productos</a>		
								
							</div>
							<div className="enlaces-subcategoria">
								{/* <h3 className="subtitulo"></h3> */}	
								<a href="">Configuración flujo de pago</a>																									
								<a href="">Configuración usuario</a>	
								<a href="">Materia Prima</a>						
								<a href="">Importador</a>
								<a href="">Punto Venta</a>	
								<a href="">Conf. Adicionales</a>	
								<a href="">Aprobadores (Adquisiciones y POS)</a>
								<h4 className="subtitulo">Listado</h4>
								<a href="">Listado de Documentos</a>
								<a href="">Consulta XML</a>	
							</div>							
						</div>

						<div className="subcategoria" data-categoria="categoria-administracion-factura">
							<div className="enlaces-subcategoria">
								<button className="btn-regresar">Regresar</button>
								<h3 className="subtitulo">Libros</h3>
								<a href="">Envío de Libros</a>
								<a href="">Respaldo Libro Ventas</a>
								<h3 className="subtitulo">CAF</h3>
								<a href="">Folios</a>
								<a href="">Anular Folios</a>
							</div>
							<div className="enlaces-subcategoria">
								<h3 className="subtitulo"></h3>
								<a href="">Folios no Usados</a>
								<a href="">Consulta DTE</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
