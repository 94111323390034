import React, { useState, useEffect } from 'react';
import { Protected } from '@/components/layout/Protected';
import { FunctionComponent } from "react";
import "./BusinessClosure.sass";
import "./BusinessClosure-styles.css"
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, InputLabel,} from '@mui/material'
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {  DataRut } from './Modal/DataRut';
import { LoadingButton } from '@mui/lab';

interface Column {
   id: 'num' | 'mes' | 'periodo'|'fecha';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

 const columns:readonly Column[] = [
   { id: 'num', label: 'Num.', minWidth: 50 },
   { id: 'mes', label: 'Mes', minWidth: 50 },
   { id: 'periodo', label: 'Periodo', minWidth: 50 },
   { id: 'fecha', label: 'Fecha', minWidth: 50 }
   ];

   interface Data {
      num: string;
      mes: string;
      periodo: string;
      fecha: string;
   }

   function createData(
      num: string,
      mes: string,
      periodo: string,
      fecha: string,
      ):Data {
      return { num, mes, periodo, fecha};
   }



const rowsData = [
   createData('1', '2', '2021', '2021-02-28'),
   createData('2', '1', '2022', '2021-02-26'),
   createData('3', '3', '2021', '2021-02-26'),
   createData('4', '4', '2020', '2021-02-22'),
   createData('5', '2', '2019', '2021-02-22'),
   createData('6', '6', '2020', '2021-02-22'),
]


export const BusinessClosure: FunctionComponent = (props: any) => {
   const [value, setValue] = useState<Date | null>(new Date());
   const [selectTypeResume, setSelectTypeResume] = useState('');
   const [data, setData] = React.useState<Date | null>(new Date());
   const [valueCalendar, setValueCalendar] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
const [show, setShow] = useState(false);

const handleChangeShow = () => {
   setShow(!show);
}

   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   // const [value, setValue] = useState('');

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const [cambiarEstado, setCambiarEstado] = useState(false);
   const handleCambiarEstado = () => setCambiarEstado(true);

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 720,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };


   return (
      <Protected>
        <Grid container spacing={2}>
         <Grid item xs={12} >
            <Typography className="tittle" variant="h6" gutterBottom>
            MANTENEDOR CIERRES COMERCIALES
            </Typography>

            <Typography variant="subtitle2" gutterBottom>
            Incorpóre la información en el siguiente formulario
            </Typography>
         </Grid>

         <Grid item xs={12} >
                  <Grid container spacing={4}>
                           <Grid item xs={2}>
                                 <FormControl fullWidth>
                                       <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                          Mes
                                 </InputLabel>
                              <NativeSelect
                                    defaultValue={30}
                                    inputProps={{
                                    name: 'Mes',
                                    id: 'uncontrolled-native',
                                    }}
                                 >
                                    <option value={10}>Enero</option>
                                    <option value={20}>Febrero</option>
                                    <option value={30}>Marzo</option>
                                    <option value={40}>Abril</option>
                                    <option value={50}>Mayo</option>
                                    <option value={60}>Junio</option>
                                    <option value={70}>Julio</option>
                                    <option value={80}>Agosto</option>
                                    <option value={90}>Septiembre</option>
                                    <option value={100}>Octubre</option>
                                    <option value={110}>Noviembre</option>
                                    <option value={120}>Diciembre</option>
                              </NativeSelect>
                                 </FormControl>
                           </Grid>

                           <Grid item xs={2}>
                           <FormControl fullWidth>
                                       <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                          Periodo
                                 </InputLabel>
                              <NativeSelect
                                    defaultValue={30}
                                    inputProps={{
                                    name: 'Periodo',
                                    id: 'uncontrolled-native',
                                    }}
                                 >
                                    <option value={10}>2019</option>
                                    <option value={20}>2020</option>
                                    <option value={30}>2021</option>
                                    <option value={30}>2022</option>
                              </NativeSelect>
                                 </FormControl>
                           </Grid>

                           <Grid item xs={2} marginTop={1}>
                           <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack >
                           <DatePicker
                                 // views={['year', 'month']}
                                 label="Fecha"
                                 // minDate={new Date('2012-03-01')}
                                 // maxDate={new Date('2023-06-01')}
                                 value={value}
                                 onChange={(newValue) => {
                                    setValue(newValue);
                                 }}
                                 renderInput={(params) => <TextField  {...params} helperText={null} />}
                                 />
                           </Stack>
                     </LocalizationProvider>   
                           </Grid>
                  </Grid>
         </Grid>
         <Grid item xs={12}>
                  <Grid container>
                  <Button
                           type='submit'
                           variant='contained'
                           onClick={handleChangeShow}
                           // disabled={loading}
                           disableElevation
                           className='btn_save'
                        >
                           Agregar
                        </Button>
                  </Grid>
         </Grid>
      </Grid>

{show &&

         <Grid item xs={12} alignContent='center'>

         <Grid item xs={12}>
         <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={3}>
               LISTADO
            </Typography>

            <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               
               <TableContainer sx={{maxHeight:440}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {columns.map((column) => (
                              <TableCell
                              
                                 key={column.id}
                                 align={column.align} 
                                 style={{ minWidth: column.minWidth }}
                              >
                                 {column.label}
                              </TableCell>
                           ))}
                           <TableCell align='center'>
                              Opcion
                           </TableCell>

                           <TableCell align='center'>
                              Cierre Nota Venta
                           </TableCell>

                           <TableCell align='center'>
                              Cierre Orden Compra
                           </TableCell>
                           

                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.fecha}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>

                                       
                                    );
                                 })}
                                             <TableCell>
                                                <div className='sepaEle'>
                                                   <button className='btnIcoE'><EditIcon /></button>
                                                </div>
                                             </TableCell>

                                             <TableCell>
                                                <div className='sepaEle'>
                                                   <button className='btnIcoE'><FilePresentIcon /></button>
                                                </div>
                                             </TableCell>

                                             <TableCell>
                                                <div className='sepaEle'>
                                                   <button className='btnIcoE'><ShoppingCartIcon /></button>
                                                </div>
                                             </TableCell>
                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
            </Paper> 

         </Grid>

        </Grid>
}
      </Protected >
   )
}

