import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { Box, Stepper, InputAdornment, IconButton, Step, StepLabel, Button, CircularProgress, Grid, CssBaseline, Container, Modal, StepIconProps, Typography, Tooltip, FormControl, Autocomplete, FormLabel, RadioGroup, FormControlLabel, Radio, Checkbox } from '@mui/material'
import { Props } from "./Workers.type"
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import InfoIcon from '@mui/icons-material/Info';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { FormPersonalData } from "./FormPersonalData";
import BusinessIcon from '@mui/icons-material/Business';
import { FormLaborData } from "./FormLaborData";
import BadgeIcon from '@mui/icons-material/Badge';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import PaymentIcon from '@mui/icons-material/Payment';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import WorkIcon from '@mui/icons-material/Work';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import { InputRef } from '@components/common/Input/InputInterfaces';
import "./Workers.css";
import { FormInformation } from './FormInformation';
import { FormBankInformation } from './FormBankInformation';
import { FormFamilies } from './FormFamilies';
import { FormCompensationMutuality } from './FormCompensationMutuality';
import { DataModalFamily } from './ModalFamily/DataModalFamily';
import { DataModalWork } from './ModalAccount/DataModalWork';


const steps = [{ name: 'Datos Personales', icon: null },
{ name: 'Datos Laborales', icon: null },
{ name: 'Información Previsional', icon: null },
{ name: 'Información Bancaria y Pago', icon: null },
{ name: 'Cargas Familiares', icon: null },
{ name: 'Caja Compensación y Mutualidad', icon: null },
]

interface Column {
   id: 'rut' | 'apellidoPaterno' | 'apellidoMaterno' | 'nombres' | 'estado';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: 'rut', label: 'Rut', minWidth: 100 },
   { id: 'apellidoPaterno', label: 'Rut', minWidth: 100 },
   { id: 'apellidoMaterno', label: 'Fantasia', minWidth: 100 },
   { id: 'nombres', label: 'Giro', minWidth: 100 },
   { id: 'estado', label: 'Producto', minWidth: 100 }
];
interface Data {
   rut: string;
   apellidoPaterno: string;
   apellidoMaterno: string;
   nombres: string;
   estado: string;
}

function createDataWorker(
   rut: string,
   apellidoPaterno: string,
   apellidoMaterno: string,
   nombres: string,
   estado: string
): Data {
   return { rut, apellidoPaterno, apellidoMaterno, nombres, estado };
}
const rowsDataWorker = [
   createDataWorker('111111-K', 'ALARCÓN', 'GONZÁLES', 'FERNANDO', 'DESACTIVADO'),
   createDataWorker('911111-K', 'ALARCÓN 2', 'GONZÁLES', 'FERNANFLOW', 'DESACTIVADO'),
   createDataWorker('6662111-K', 'ALARCÓN 3', 'GONZÁLES', 'FERNANDO', 'ACTIVADO'),
   createDataWorker('999911-K', 'ALARCÓN 4', 'GONZÁLES', 'FERNANDO', 'DESACTIVADO'),
   createDataWorker('8291111-K', 'ALARCÓN 5', 'GONZÁLES', 'FERNANDO', 'ACTIVADO')
]


export const Workers: React.FC<Props> = (props: Props): JSX.Element => {

   const [activeStep, setActiveStep] = useState(0);
   const [skipped, setSkipped] = useState(new Set());
   const [error, setError] = useState(null);
   const [openWork, setOpenWork] = useState(false);
   const [openFamily, setOpenFamily] = useState(false);

   const [value, setValue] = useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);

   const handleOpenWork = () => setOpenWork(true);
   const handleCloseWork = () => setOpenWork(false);

   const handleOpenFamily = () => setOpenFamily(true);
   const handleCloseFamily = () => setOpenFamily(false);

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };
   // valores que van en los inputs de cada pestaña del stepper
   const [valueGeneralInformation, setvalueGeneralInformation] = useState({
      codigo: '',
      razonSocial: '',
      giro: '',
      observacion: '',
      email: '',
      web: '',
      cliente: '',
      proveedor: '',
      cesionario: '',
      fantasia: '',
      telefono: '',
      fax: '',
      activo: '',

   });

   const [valueInventario, setvalueInventario] = useState({
      unidadMedida: '',
      stockMinimo: '',
      stockMaximo: '',
      UbicacionFisica: '',
   });

   const [valueInformation, setvalueInformation] = useState({
      situacion_inicial: '',
      fecha_compra: '',
      valor_compra: '',
      numero_factura: '',
      orden_compra: '',
      proveedor: '',
      vida_tributaria: '',
      vida_financiera: '',
      inicio_valorizacion: '',
      inicio_depreciacion: '',
      descripcion: ''
   });

   const [data, setData] = useState('');
   const [open, setOpen] = useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);
   const [select, setSelect] = useState('');
   const inputRefs = useRef<Array<InputRef | null>>([]);

   const handleChange = (event: any) => {
      setData(event.target.value as string);
   };
   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      maxHeight: 620,
      transform: 'translate(-50%, -50%)',
      width: 750,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };
   //  Títulos y estilo a columnas de la tabla
   interface Column {
      id: 'Descripcion' | 'Fecha Ingreso' | 'Fecha Compra' | 'Concepto';
      label: string;
      minWidth?: number;
      align?: 'right';
      format?: (value: number) => string;
   }
   // Valores de la tabla 
   useEffect(() => {
      if (props.entity !== undefined) {
         setvalueGeneralInformation(props.entity);
         setvalueInventario(props.entity);
         setvalueInformation(props.entity);
      }
   }, []);

   const isStepSkipped = (step) => {
      return skipped.has(step);
   };

   const handleNext = () => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
         newSkipped = new Set(newSkipped.values());
         newSkipped.delete(activeStep);
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
   };

   const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
   };


   const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
      [`&.${stepConnectorClasses.alternativeLabel}`]: {
         top: 22,
      },
      [`&.${stepConnectorClasses.active}`]: {
         [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
               'linear-gradient( 95deg,#19c1d1 0%,#1976D2 50%,#1996d1 100%)',
         },
      },
      [`&.${stepConnectorClasses.completed}`]: {
         [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
               'linear-gradient( 95deg,#19c1d1 0%,#1976D2 50%,#1996d1 100%)',
         },
      },
      [`& .${stepConnectorClasses.line}`]: {
         height: 3,
         border: 0,
         backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
         borderRadius: 1,
      },
   }));

   const ColorlibStepIconRoot = styled('div')<{
      ownerState: { completed?: boolean; active?: boolean };
   }>(({ theme, ownerState }) => ({
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 50,
      height: 50,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
      ...(ownerState.active && {
         backgroundImage:
            'linear-gradient( 136deg, #1976D2 0%, #1996d1 50%, #19c1d1 100%)',
         boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      }),
      ...(ownerState.completed && {
         backgroundImage:
            'linear-gradient( 136deg, #1976D2 0%, #1996d1 50%, #19c1d1 100%)',
      }),
   }));


   function ColorlibStepIcon(props: StepIconProps) {
      const { active, completed, className } = props;

      const icons: { [index: string]: React.ReactElement } = {
         1: <AssignmentIcon />,
         2: <WorkIcon />,
         3: <InfoIcon />,
         4: <PaymentIcon />,
         5: <FamilyRestroomIcon />,
         6: <PaymentIcon />,
         
      };

      return (
         <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
         </ColorlibStepIconRoot>
      );
   }

   const mostrarFormulario = () => {
      if (activeStep === 0) {
         return (
            <FormPersonalData
               handleNext={handleNext}
               valueGeneralInformation={valueGeneralInformation}
               setvalueGeneralInformation={setvalueGeneralInformation}
            />
         )
      }
      else if (activeStep === 1) {
         return (
            <FormLaborData
               handleNext={handleNext}
               valueInventario={valueInventario}
               setvalueInventario={setvalueInventario}
            />
         )
      }
      else if (activeStep === 2) {
         return (
            <FormInformation
               handleNext={handleNext}
               valueInformation={valueInformation}
               setvalueInformation={setvalueInformation}
            />
         )
      }
      else if (activeStep === 3) {
         return (
            <FormBankInformation
               handleNext={handleNext}
               valueInformation={valueInformation}
               setvalueInformation={setvalueInformation}
            />
         )
      }
      else if (activeStep === 4) {
         return (
            <FormFamilies
               handleNext={handleNext}
               valueInformation={valueInformation}
               setvalueInformation={setvalueInformation}
            />
         )
      }
      else if (activeStep === 5) {
         return (
            <FormCompensationMutuality
               handleNext={handleNext}
               valueInformation={valueInformation}
               setvalueInformation={setvalueInformation}
            />
         )
      }
   }
   return (
      <Protected>
         <Container maxWidth='xl'>
            <Typography variant="h6" gutterBottom mb={3} className="tittle">
               Mantenedor de Clientes Trabajadores
            </Typography>
            <Grid item container mb={3} xs={12} >
               <Typography variant="subtitle1" >
                  Incorpore sus Trabajadores en el siguiente Formulario.
               </Typography>
            </Grid>
            <Grid container flexDirection='row' spacing={2}>
               <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={12}>
                     <Box>
                        <Box sx={{ mb: 2 }}>
                           <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
                              {steps.map((label, index) => {
                                 return (
                                    <Step key={index}>
                                       <StepLabel StepIconComponent={ColorlibStepIcon} error={error === `Usuario${index}noencontrado` ? true : false}>{label.name}</StepLabel>
                                    </Step>
                                 );
                              })}
                           </Stepper>
                        </Box>
                        {activeStep === steps.length ? (
                           <div>AGREGADO</div>
                           //VALIDAR QUE EL ENVIO DE DATOS
                           // history.push(ROUTE_ACCOUNT)
                        ) : (
                           mostrarFormulario()
                        )}
                     </Box>
                  </Grid>
               </Grid>
            </Grid>

            <div className='bg-tittle'>
               <Typography
                  mt={3}
                  variant="h6"
                  align='center'
                  className='tittle-text'
               >
                  Listado de Trabajadores Ingresados
               </Typography>
            </div>
         </Container>

         <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer component={Paper}>
               <Table aria-label="simple table">
                  <TableHead>
                     <TableRow>
                        <TableCell >Rut</TableCell>
                        <TableCell >Apellido Paterno</TableCell>
                        <TableCell >Apellido Materno</TableCell>
                        <TableCell >Nombres</TableCell>
                        <TableCell >Estado</TableCell>
                        <TableCell >Opciones</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {rowsDataWorker.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        return (
                           <TableRow hover role="checkbox" tabIndex={-1} key={row.rut}>
                              {columns.map((column) => {
                                 const value = row[column.id];
                                 return (
                                    <TableCell key={column.id} align={column.align}>
                                       {column.format && typeof value === 'number'
                                          ? column.format(value)
                                          : value}
                                    </TableCell>
                                 );
                              })}
                              <TableCell>
                                 <Grid>
                                    <Tooltip title={"Actualizar"} placement="bottom-end">
                                       <IconButton
                                          aria-label="Editar"
                                          // onClick={() => handleOpenEnterprise()}
                                          className='btn_delete'
                                       // disabled={loading}
                                       >
                                          <EditIcon />
                                       </IconButton>
                                    </Tooltip>
                                    <Tooltip title={"Cargas Familiares"} placement="bottom-start">
                                       <IconButton
                                          aria-label="Editar"
                                          onClick={() => handleOpenFamily()}
                                          className='btn_delete'
                                       // disabled={loading}
                                       >
                                          <GroupAddIcon />
                                       </IconButton>
                                    </Tooltip>
                                 </Grid>
                                 <Grid>
                                    <Tooltip title={"Historial Laboral"} placement="bottom-end">
                                       <IconButton
                                          aria-label="Ver Ficha"
                                          onClick={() => handleOpenWork()}
                                          className='btn_delete'
                                       // disabled={loading}
                                       >
                                          <WorkIcon />                                          
                                       </IconButton>
                                    </Tooltip>
                                    <Tooltip title={"Certificado Antigüedad"} placement="bottom-start">
                                       <IconButton
                                          aria-label="Ingresar Proveedor"
                                          // onClick={() => handleOpenWork()}
                                          className='btn_delete'
                                       // disabled={loading}
                                       >
                                          <CardMembershipIcon />
                                       </IconButton>
                                    </Tooltip>
                                 </Grid>
                              </TableCell>
                           </TableRow>
                        );
                     })}
                  </TableBody>
               </Table>
            </TableContainer>
            <TablePagination
               rowsPerPageOptions={[10, 25, 100]}
               component="div"
               count={rowsDataWorker.length}
               rowsPerPage={rowsPerPage}
               page={page}
               onPageChange={handleChangePage}
               onRowsPerPageChange={handleChangeRowsPerPage}
            />
         </Paper>
         <Modal
            open={openFamily}
            onClose={handleCloseFamily}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataModalFamily entity={undefined} />
            </Box>
         </Modal>
         <Modal
            open={openWork}
            onClose={handleCloseWork}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataModalWork entity={undefined} />
            </Box>
         </Modal>
         

      </Protected>

   );
};