import { AccountTreeRounded } from "@mui/icons-material";

export const ROUTE_HOME = '/inicio';
export const ROUTE_HOME_TOTAL_SALES= '/inicio-total-ventas';
export const ROUTE_HOME_TOTAL_PRODUCTS = '/inicio-total-productos';
export const ROUTE_HOME_SALES_YEAR = '/inicio-ventas-años';
export const ROUTE_HOME_SALES_MONTH = '/inicio-ventas-mes';
export const ROUTE_HOME_SALES_AVG = '/inicio-ventas-promedio';

export const ROUTE_LOGIN           = '/login';
export const ROUTE_GENERATOR_TICKET= '/generar-ticket';
export const ROUTE_GENERATOR_DETAIL= '/generar-detalle';
export const ROUTE_GENERATOR_NOT_ISSUED= '/generar-no-emitido';
export const ROUTE_GENERATOR_CANCELED= '/generar-ticket-anulado';
export const ROUTE_PROVIDER= '/proveedores';
export const ROUTE_CONSULT_BALLOT= '/consultar-datos';
export const ROUTE_OC_SUPPLIES= '/orden-compra-insumos';
export const ROUTE_PROVIDER_CREATE= '/proveedores/crear';
export const ROUTE_RUBRO= '/rubros';
export const ROUTE_BODEGA= '/bodegas';
export const ROUTE_CENTER_BENEFICIO= '/centros-beneficio';
export const ROUTE_OC_PRODUCTS= '/orden-compra-productos';
export const ROUTE_FIXED_ASSETS= '/activos-fijos';
export const ROUTE_CONTRATO = '/Contrato';
export const ROUTE_INSUMOS_OTHER = '/Insumos-Otros';
export const ROUTE_ARTICLE_GROUP= '/grupo-articulos';
export const ROUTE_STATE_OC= '/estado-orden-compra';
export const ROUTE_DECISION_STATE= '/estado-decision';
export const ROUTE_CURRENT_ACOUNT= '/cuenta-corriente';
export const ROUTE_BUY_REQUEST = '/solicitud-compra';
export const ROUTE_RUT_NOT_REJECTED = '/rut-sin-rechazar';
export const ROUTE_BUY_REQUEST_BOOK= '/libro-solicitud-compra';
export const ROUTE_REQUEST_OFERT= '/solicitud-oferta';
export const ROUTE_REQUEST_OFERT_BOOK= '/libro-solicitud-oferta';
export const ROUTE_DECISION_OC= '/decision-oc';
export const ROUTE_BOOK_PURCHASE_ORDER= '/libro-orden-compra';
export const ROUTE_INTERNAL_GUIDE_OC= '/guia-interna-oc';


// GESTION COMERCIAL
export const ROUTE_EV_MONTH_DAILY= '/diario';
export const ROUTE_EV_MONTH_YEARS= '/mes-periodo';
export const ROUTE_EV_PRODUCT_DETAIL= '/producto-detalle';


// GESTIÓN COMERCIAL (MAESTROS)
export const ROUTE_GC_PROVIDER= '/proveedor';
export const ROUTE_GC_MASTER_SEARCH= '/busqueda-maestro';
export const ROUTE_GC_CLIENT_PERSON= '/cliente-persona';
export const ROUTE_GC_FAMILIES= '/familias';
export const ROUTE_GC_LINES= '/lineas';
export const ROUTE_GC_DISPATCH_ROUTES= '/rutas-despacho';
export const ROUTE_GC_REPORT_SV= '/informe-sucursal-vendedor';
export const ROUTE_GC_PRICE_LIST= '/lista-precios';
export const ROUTE_GC_PRICE_LIST_SEGMENTATION= '/lista-precios-segmentation';
export const ROUTE_GC_PRICE_LIST_CHANEL= '/lista-precios-canal';
export const ROUTE_GC_PRICE_LIST_CART= '/lista-precios-carrito';
export const ROUTE_GC_VARIOUS_CONFIGURATIONS= '/configuraciones-varias';
export const ROUTE_GC_CUSTUMER= '/cliente';
export const ROUTE_GC_CUSTUMER_PROVIDER= '/cliente-proveedor';
export const ROUTE_GC_CUSTUMER_PROSPECT= '/cliente-prospecto';
export const ROUTE_GC_PRODUCTS_SERVICE= '/producto-servicio';
export const ROUTE_GC_SUB_FAMILY= '/subfamilia';
export const ROUTE_GC_BUSINESS_AREA= '/area-negocio';
export const ROUTE_GC_VENDOR= '/vendedores';
export const ROUTE_GC_PRODUCT_LIST= '/lista-productos';

// GESTIÓN COMERCIAL (FACTURACIÓN)
export const ROUTE_F_ELECTRONIC_DOCUMENT= '/documentos-electronicos';
export const ROUTE_F_ED_ELECTRONIC_BILL='/factura-electrónica';
export const ROUTE_F_ED_ELECTRONIC_PURCHASE='/factura-compra-electronica';
export const ROUTE_F_ED_ELECTRONIC_EXEMPT='/factura-exenta-electronica';
export const ROUTE_F_ED_ELECTRONIC_CREDIT='/nota-credito-electronica';
export const ROUTE_F_DE_PURCHASE_ORDER='/factura-orden-compra';
export const ROUTE_F_ED_EXPORT_SALE_NOTE = '/nota-venta-exportacion';
export const ROUTE_F_ED_SALE_NOTE = '/factura-nota-venta';
export const ROUTE_F_ED_QUOTES = '/factura-cotizacion';
export const ROUTE_F_ED_ELECTRONIC_DISPATCH_GUIDE = '/factura-guia-despacho-electronica';
export const ROUTE_F_ED_ELECTRONIC_DEBIT_NOTE = '/factura-nota-debito-electronica';
export const ROUTE_F_ED_EMIT_ELECTRONIC_DISPATCH_GUIDE= '/emitiendo-guia-despacho-electronica';
export const ROUTE_F_ED_EMIT_NOTE_SALES = '/emitiendo-nota-venta';
export const ROUTE_F_ED_EMIT_QUOTES = '/emitiendo-cotizaciones';
export const ROUTE_F_ED_EMIT_REFERENS_DOCUMENT_NOTE_CREDIT = '/emitiendo-referenciar-documento-nota-credito';



// GESTIÓN COMERCIAL (FACTURACIÓN)/DOCUMENTOS NO ELECTRONICOS
export const ROUTE_F_NO_ELECTRONIC_DOCUMENT='/documentos-no-electronicos';
export const ROUTE_F_NO_ED_BILLINGS='/documentos-no-electronicos-factura'
export const ROUTE_F_DE_CONFIRM_EMISION='/factura-confirmar-emision';

// GESTIÓN COMERCIAL (ESTADÍSTICAS DE VENTA)
export const ROUTE_EV_SALES_DATES= '/venta-fechas';
export const ROUTE_EV_SALES_DATES_O1= '/mes-periodo';
export const ROUTE_EV_SALES_DATES_O2= '/seleccion-fechas';
export const ROUTE_EV_SALES_DATES_O3= '/mes-periodo-dia';
export const ROUTE_EV_QUOTE_BOOKS= '/equipo-venta-libro-cotizaciones';
export const ROUTE_EV_FILL_RATE= '/fill-rate';
export const ROUTE_EV_CHECK_ROYALTY= '/verificacion-royalty';
export const ROUTE_EV_CLIENT_SELL= '/cliente-venta';
export const ROUTE_EV_SELL_CONSOLIDATE= '/venta-consolidada';
export const ROUTE_EV_INFORM_ROYALTY= '/informe-royalty';
export const ROUTE_EV_RUTA_DESPACHO= '/ruta-despacho';
export const ROUTE_ES_MONTHLY= '/estadisticas-mensuales';

//GESTION COMERCIAL/EQUIPO DE VENTA/EMISION DE DOCUMENTOS
export const ROUTE_EV_DOCUMENT_EMISSION='/emision-documentos';
export const ROUTE_EV_DE_QUOTES='/emision-documentos-cotizaciones';

export const ROUTE_EV_DE_NOTE_SELL_EXENTA='/nota-venta-exenta';
export const ROUTE_EV_DE_CUOTE_MEDIA='/cotizacion-media';
export const ROUTE_EV_DE_MARGIN_SELL='/margen-venta';


export const ROUTE_EV_DE_SALE_NOTE='/emision-documentos-nota-venta';
export const ROUTE_EV_DE_SALE_NOTE_EXPORT='/emision-documentos-nota-exportacion';
export const ROUTE_F_DE_EX_ELECTRONIC_EXPORT_CREDIT_NOTE='/nota-credito-exportacion-electronica';
export const ROUTE_F_DE_EX_ELECTRONIC_EXPORT_INVOICE='/factura-exportacion-electronica';
export const ROUTE_F_DE_EX_EMIT_ELECTRONIC_EXPORT_CREDIT_NOTE='/emitir-nota-credito-exportacion-electronica';
export const ROUTE_F_DE_EX_EMIT_ELECTRONIC_EXPORT_DEBIT_NOTE='/emitir-nota-debito-exportacion-electronica';
export const ROUTE_F_DE_EX_ELECTRONIC_EXPORT_DEBIT_NOTE='/nota-debito-exportacion-electronica';
export const ROUTE_EV_DE_OFFICE_GUIDE='/emision-documentos-guia-despacho';
export const ROUTE_EV_DE_ELECTRONIC_BILLING='/emision-documentos-facturacion-electronica';
export const ROUTE_EV_DE_INVOICE_OFFICE_GUIDES='/factura-guia-despacho';
export const ROUTE_EV_DE_DISPASH_INVOICE='/factura-despacho';
export const ROUTE_EV_DE_NOTE_BILLING_EXPORT='/nota-factura-exportacion';
export const ROUTE_EV_DE_ELECTRONIC_BILLING_EXENTA='/factura-electronica-exenta';
export const ROUTE_EV_DE_MEANS_PAYMENT_VOUCHER='/vale-comprobante-pago';
export const ROUTE_EV_DE_ELECTRONIC_TICKET='/boleta-electronica';
export const ROUTE_EV_DE_BE_TICKET_ELECTRONIC='/be-boleta-electronica';
export const ROUTE_EV_DE_BE_EMITION_TICKET_ELECTRONIC='/be-emitir-boleta-electronica';
export const ROUTE_EV_DE_OTHERS_ROUTE_CHEETS='/others-route-cheets';
//GESTION COMERCIAL/EQUIPO DE VENTA/LIBROS POR EMPRESA
export const ROUTE_EV_LE_BOOKS_NOTE='/libro-notas-ventas';

//GESTION COMERCIAL/EQUIPO DE VENTA/LIBROS POR USUARIO
export const ROUTE_EV_LU_BOOKS_NOTE='/libro-nota-ventas';
export const ROUTE_EV_LU_SALES_BOOKS='/libro-ventas';
export const ROUTE_EV_LU_QUOTE_BOOKS='/libro-usuario-libro-cotizaciones';
export const ROUTE_EV_LU_QUOTE_BOOK_RESUME='/libro-usuario-cotizacion-resumen';
export const ROUTE_EV_LU_EMITION_QUOTE='/lu-emision-cotizacion';
export const ROUTE_EV_LU_CONFIRM_EMITION='/lu-confirmacion-emision';

//GESTION COMERCIAL/EQUIPO DE VENTA/LIBROS POR SUCURSAL
export const ROUTE_EV_LS_QUOTE_BOOKS='/libro-sucursal-libro-cotizaciones';
export const ROUTE_EV_LS_QUOTE_BOOK_RESUME='/libro-sucursal-cotizacion-resumen';
export const ROUTE_EV_LS_EMITION_QUOTE='/ls-emision-cotizacion';
export const ROUTE_EV_LS_CONFIRM_EMITION='/ls-confirmacion-emision';

export const ROUTE_EV_BOOKS_EXPORT_NOTE='/libro-notas-ventas-exportacion';
export const ROUTE_EV_BOOKS_OC_CUSTOMER='/libro-oc-cliente';

export const ROUTE_EV_LS_BOOK_NOTE_SELL='/libro-sucursal-libro-nota-ventas';
export const ROUTE_EV_lS_BOOK_NOTE_SELL_RESUME='/libro-sucursal-libro-nota-ventas-resumen';
export const ROUTE_EV_LS_BOOK_NOTE_SELL_FOLIO='/libro-sucursal-libro-nota-ventas-folio';

export const ROUTE_EV_LS_BOOK_NOTE_SELL_GUIDE='/libro-sucursal-libro-nota-ventas-guia';
export const ROUTE_EV_lS_BOOK_NOTE_SELL_RESUME_GUIDE='/libro-sucursal-libro-nota-ventas-resumen-guia';
export const ROUTE_EV_LS_BOOK_NOTE_SELL_FOLIO_GUIDE='/libro-sucursal-libro-nota-ventas-folio-guia';

//ESTADISTICA DE COMPRA
export const ROUTE_CD_PURCHASE_DATE= '/compra-fecha';
export const ROUTE_CD_CONSOLIDATE_PURCHASE='/compra-consolidada';
export const ROUTE_GC_BUYS_PROVIDER='/compras-proveedor';

//COMITE CREDITO
export const ROUTE_Cc_BLOACK_COMMERCIAL= '/bloqueo-comercial';
export const ROUTE_Cc_PAYMENT_COMMERCIAL= '/condiciones-comerciales-pago';
export const ROUTE_GC_CREDIT_LINE='/linea-credito';

// REPORTES Y CONTROL
export const ROTE__Rc_REQUEST_BOOK='/libro-pedido';
export const ROUTE_RC_BUSINESS_CLOSURE='/cierre-comercial';


// EQUIPO VENTA
export const ROUTE_Ev_QUOTE_BOOK='/equipo-venta-libro-cotizaciones';
export const ROUTE_EV_QUOTE_BOOK_RESUME='/libro-cotizacion-resumen';
export const ROUTE_EV_EMITION_QUOTE='/emision-cotizacion';
export const ROUTE_Ec_CONFIRM_EMITION='/confirmacion-emision';
export const ROUTE_EV_AUTORIZATION='/autorizacion';
// REMUNERACION
// REMUNERACION (MAESTROS)
export const ROUTE_RM_INDICATOR_MONTH= '/indicador-mes';
export const ROUTE_M_WORKERS= '/trabajadores';
export const ROUTE_RM_DEPARMENT= '/departamento';
export const ROUTE_RM_CHARGES= '/cargos';
export const ROUTE_RM_EXTRA_HOURS= '/horas-extra';
export const ROUTE_RM_SETTKEMENT_STRUCTURE= '/estructura-liquidacion';
export const ROUTE_RM_SETTKEMENT_ACTIVE= '/liquidacion-activa';

// REMUNERACION (INFORMES)
export const ROUTE_RM_SALARY_LIQUIDATION= '/liquidacion-sueldos';
export const ROUTE_RM_PRINT_WORKER= '/liquidacion-sueldos-imprimir';
export const ROUTE_RM_ADD_LIQUIDATION= '/liquidacion-sueldos-agregar';
export const ROUTE_RM_MASSIVE_LIQUIDATION= '/liquidacion-masiva';
export const ROUTE_RM_REMUNERATION_SHEET= '/planilla-remuneraciones';
export const ROUTE_RM_QUOTATION_SHEET= '/planilla-cotizaciones';
export const ROUTE_RM_QUOTATION_SHEET_WORKERS= '/planilla-cotizaciones-trabajadores';
export const ROUTE_RM_QUOTATION_SHEET_ENTITY= '/planilla-cotizaciones-entidades';
export const ROUTE_RM_LEGAL_HOLIDAY= '/feriado-legal';
export const ROUTE_RM_MONTHLY_REMUNERATION= '/remuneracion-mensual';
export const ROUTE_RM_MONTHLY_REMUNERATION_FORM= '/remuneracion-mensual-formulario';
export const ROUTE_RM_CENTRALIZE_WORKER= '/centralizador-trabajador';

// REMUNERACION (NOVEDADES DEL MES)
export const ROUTE_RM_ADVANCE= '/anticipo';
export const ROUTE_RM_GENERAL_ADVANCE= '/anticipo-general';
export const ROUTE_RM_ABSENCES_TARDIES= '/inasistencias-atrasos';
export const ROUTE_RM_HOURS_EXTRAORDINARY='/horas-extraordinarias'
export const ROUTE_RM_OTHER_HABERES='/otros-haberes'
export const ROUTE_RM_OTHER_DESCOUNTS='/otros-descuentos'
export const ROUTE_RM_ABSENSES_TARDIES_GENERALES='/inasistencia-atraso-general'

// REMUNERACION (GESTION DE PAGOS)
export const ROUTE_RM_PAYMENT_WORKERS= '/gestion-pagos-trabajadores';
export const ROUTE_RM_PAYMENT_WORKER='/gestion-pagos-trabajador';
export const ROUTE_RM_STEPPER='/pago';
export const ROUTE_RN_INICIALIZATION='/gestion-pagos';

//REMUNERACION (GESTION DE CONTRATO)
export const ROUTE_RM_CONTRATE_WORKERS='/contrato-trabajadores'
export const ROUTE_RM_CONTRATE_STRUCTURE='/estrucutra-contrato';

//REMUNERACION (GESTION FINICOS)
export const ROUTE_RM_FINICOS_ESTRUCTURA='/estructura-finico';
export const ROUTE_RM_FINICOS_WORKER='/finicos-trabajador';

//REMUNERACION (GESTION DE PRÉSTAMOS)
export const ROUTE_RM_RESUME_WORKER='/resumen-por-trabajador'
export const ROUTE_RM_RESUME_QUOTAS='/informe-estado-coutas'
export const ROUTE_RM_LOAN_WORKER='/prestamos-por-trabajador'
export const ROUTE_RM_ADD_LOAN='/añadir-prestamos';

//REMUNERACION (INFOMEES POR TRABAJADOR)
export const ROUTE_RM_INFORMATION_WORKER='/informe-trabajador';

// ADMINISTRACION
export const ROUTE_AD_BRANCH_OFFICE= '/sucursales';
//MANAGEMENT
//MANAGEMENT(ERP)
export const ROUTE_MG_ERP_USERS='/usuarios';

//RAPPI
export const ROUTE_RAPPI_PRODUCTS='/rappi/productos';
export const ROUTE_RAPPI_CATEGORY='/rappi/categorias';
export const ROUTE_RAPPI_PROVIDER='/rappi/proveedores';


