import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { ArrowRightIcon } from "@/toolbox/constants/icons";
import { VALIDATORS } from "@/toolbox/helpers/validation-rules";
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, TablePagination, } from '@mui/material';
import { Input } from '@components/common/Input';
import { Props } from "./AddCart.type";
import BusinessIcon from '@mui/icons-material/Business';
import { InputRef } from '@/components/common/Input/InputInterfaces';
import AssignmentIcon from '@mui/icons-material/Assignment';
import './AddCart_style.css';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SearchIcon from '@mui/icons-material/Search';
import { SelectChangeEvent } from '@mui/material/Select';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { Link } from 'react-router-dom';
import { ROUTE_GC_FAMILIES, ROUTE_GC_PRICE_LIST } from '@/toolbox/constants/route-map';
import { DataModalProducts } from '../ModalListProducts/DataModalProducts';



// Para la tabla de la vista principal
interface Column {
   id: 'descripcion' | 'cantidad' | 'valor' | 'monto' | 'total';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columnsPrincipal: readonly Column[] = [
   { id: 'descripcion', label: 'Rut', minWidth: 100 },
   { id: 'cantidad', label: 'Razon Social', minWidth: 100 },
   { id: 'valor', label: 'Fantasia', minWidth: 100 },
   { id: 'monto', label: 'Giro', minWidth: 100 },
   { id: 'total', label: 'Telefono', minWidth: 100 },
];

interface Data {
   descripcion: string;
   cantidad: string;
   valor: string;
   monto: string;
   total: string;
}
function createData(
   descripcion: string,
   cantidad: string,
   valor: string,
   monto: string,
   total: string
) {
   return { descripcion, cantidad, valor, monto, total };
}

const rowsListado = [
   createData('Cerveza', '0', '$0', '$0', '$23'),
   createData('Huevos', '1', '$0', '$0', '$200'),
   createData('Producto prueba importacion', '0', '$0', '$0', '$20')
]

export const AddCart: React.FC<Props> = (props: Props): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [openProducts, setOpenProducts] = useState(false);
   const [loading, setLoading] = useState(false);
   const [selectTypeDesc, setSelectTypeDesc] = useState('');
   const [isDisabled, setIsDisabled] = useState<boolean>(false);

   const [open, setOpen] = useState(false);
   const handleOpenProducts = () => setOpenProducts(true);
   const handleCloseProducts = () => setOpenProducts(false);

   const handleChangeTypeDesc = (event: SelectChangeEvent) => {
      setSelectTypeDesc(event.target.value);
   };
   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 720,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };


   function listadoProducts(
      numero: number,
      codigo: string,
      barra: string,
      codigoP: string,
      descripcion: string,
      precio: string,
      stock: string
   ) {
      return { numero, codigo, barra, codigoP, descripcion, precio, stock };
   }

   const rowsListadoProductos = [
      listadoProducts(1, '12', '', '', 'Descripción 1', '0', '-1'),
      listadoProducts(2, '1256', '', '', 'Descripción 2', '0', '2'),
      listadoProducts(3, '003', '', '', 'Descripción 3', '1000', '12'),
      listadoProducts(4, '0002', '', '', 'Descripción 4', '12', '13'),

   ]

   function createData(
      descripcion: string,
      cantidad: string,
      valor: string,
      monto: string,
      total: string
   ) {
      return { descripcion, cantidad, valor, monto, total };
   }

   const rowsListado = [
      createData('Cerveza', '0', '$0', '$0', '$23'),
      createData('Huevos', '1', '$0', '$0', '$200'),
      createData('Producto prueba importacion', '0', '$0', '$0', '$20')
   ]

   const rules = useMemo(() => ({
      rut_proveedor: [
         VALIDATORS.REQUIRED,
      ],
      numero_contrato: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      titulo: [
         VALIDATORS.REQUIRED
      ],
      descripcion: [
         VALIDATORS.REQUIRED,
      ]

   }), []);
   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'rut_proveedot':
            props.setvalueContrato(prev => ({ ...prev, rut_proveedot: value }));
            break;
         case 'numero_contrato':
            props.setvalueContrato(prev => ({ ...prev, numero_contrato: value }))
            break;
         case 'titulo':
            props.setvalueContrato(prev => ({ ...prev, titulo: value }))
            break;
         case 'descripcion':
            props.setvalueContrato(prev => ({ ...prev, descripcion: value }));
            break;

         default:
            break;
      }
   };

   // const handleInputBlur = (event: any) => {
   //    const newVal = (event.target.value).trim();
   //    const name = event.target.name;
   //    props.setvalueContrato(prev => ({ ...prev, [name]: newVal }));
   // }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }
   return (
      <Protected>
         <Container maxWidth='xl'>
            <form style={{ margin: '8px' }}>
               <Grid container mb={4}>
                  <Grid item xs={12} sm={8}>
                     <Typography variant="h6" id="modal-modal-title" mb={2} className='tittle'>
                        LISTA PRECIO PRECIOS BAJOS
                     </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} textAlign="end">
                     <Button variant="outlined" component={Link} to={ROUTE_GC_PRICE_LIST} startIcon={<ArrowBackIosIcon />}>
                        Volver (Lista de Precios)
                     </Button>
                  </Grid>
               </Grid>

               <Grid container spacing={3} className={`e-mb-lg`} >
                  <Grid container mt={2}>
                     <Grid item xs={12}>
                        <Box mt={1} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                           <FormControl fullWidth >
                              <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                 <TableContainer sx={{ maxHeight: 540 }}>
                                    <Table stickyHeader aria-label="sticky table">
                                       <TableHead>
                                          <TableRow>
                                             <TableCell>Código</TableCell>
                                             <TableCell>Descripción</TableCell>
                                             <TableCell>Cantidad</TableCell>
                                             <TableCell>Valor</TableCell>
                                             <TableCell>T.desc</TableCell>
                                             <TableCell>Valor descuento</TableCell>
                                             <TableCell>Total</TableCell>

                                          </TableRow>
                                       </TableHead>

                                       <TableBody>
                                          <TableRow >
                                             <TableCell>
                                                <Input
                                                   ref={ref => inputRefs.current[2] = ref}
                                                   appendAdornment={
                                                      <InputAdornment position="end">
                                                         <IconButton onClick={handleOpenProducts}>
                                                            <SearchIcon />
                                                         </IconButton>

                                                      </InputAdornment>
                                                   }
                                                   name="valor_compra"
                                                   placeholder="Buscar"
                                                   //value={data.nombre_rz}
                                                   // value={props.valueContrato.valor_compra}
                                                   onChange={handleInput}
                                                   //onBlur={handleInputBlur}
                                                   // backgroundColor="#F3F3F3"
                                                   // rules={rules.valor_compra}
                                                   disableElevation
                                                   validateOnBlur
                                                   dense
                                                   disabled={isDisabled}
                                                   className='borderInput'
                                                />
                                             </TableCell>

                                             <TableCell>
                                                Cargando...
                                             </TableCell>

                                             <TableCell>
                                                1
                                             </TableCell>

                                             <TableCell>
                                                Ingresar Datos
                                             </TableCell>

                                             <TableCell>
                                                <FormControl fullWidth sx={{ minWidth: 80 }}>
                                                   <InputLabel id="demo-simple-select-helper-label">Tipo</InputLabel>
                                                   <Select
                                                      labelId="demo-simple-select-helper-label"
                                                      id="demo-simple-select-helper"
                                                      value={selectTypeDesc}
                                                      label="Tipo"
                                                      size='small'
                                                      onChange={handleChangeTypeDesc}
                                                   >
                                                      <MenuItem value="Centro Costo">
                                                         <em>None</em>
                                                      </MenuItem>
                                                      <MenuItem value={1}>%</MenuItem>
                                                      <MenuItem value={2}>$</MenuItem>
                                                   </Select>
                                                </FormControl>

                                             </TableCell>

                                             <TableCell>
                                                0
                                             </TableCell>

                                             <TableCell>
                                                Ingresar Datos
                                             </TableCell>
                                          </TableRow>

                                       </TableBody>
                                    </Table>
                                 </TableContainer>

                              </Paper>
                           </FormControl>
                        </Box>
                     </Grid>
                  </Grid>

                  <Grid item container
                     xs={12}
                     justifyContent='right'
                  >
                     <Grid item>
                        <Button
                           type='submit'
                           variant='contained'
                           onClick={handleSubmit}
                           disabled={loading}
                           disableElevation
                           className='btn_save'
                        >
                           <span>Agregar al carrito</span>
                        </Button>

                     </Grid>
                  </Grid>
               </Grid>
            </form>

            <div className='bg-tittle'>
               <Typography
                  mt={3}
                  variant="h6"
                  align='center'
                  className='tittle-text'
               >Productos Ingresados
               </Typography>
            </div>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
               <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                     <TableHead>
                        <TableRow>
                           <TableCell>Descripción</TableCell>
                           <TableCell align="center">Cantidad</TableCell>
                           <TableCell align="center">Valor</TableCell>
                           <TableCell align="center">Monto Descuento</TableCell>
                           <TableCell align="center">Total</TableCell>
                           <TableCell align="center">Opciones</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsListado.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.descripcion}>
                                 {columnsPrincipal.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell align="center">

                                    <IconButton
                                       aria-label="delete"
                                       // onClick={() => handleDelete(row.id)}
                                       className='btn_delete'
                                    // onClick={() => handleOpen()}
                                    // disabled={loading}
                                    >
                                       <EditIcon />
                                    </IconButton>
                                    <IconButton
                                       aria-label="delete"
                                       // onClick={() => handleDelete(row.id)}
                                       className='btn_delete'
                                    // onClick={() => handleOpen()}
                                    // disabled={loading}
                                    >
                                       <LocalPrintshopIcon />
                                    </IconButton>
                                 </TableCell>
                              </TableRow>
                           );
                        })}
                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsListado.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>
         </Container>
         <Modal
            open={openProducts}
            onClose={handleCloseProducts}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataModalProducts entity={undefined} />
            </Box>
         </Modal>
      </Protected>
   );
}