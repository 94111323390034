import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { ArrowRightIcon } from "@/toolbox/constants/icons";
import { VALIDATORS } from "@/toolbox/helpers/validation-rules";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, TablePagination, } from '@mui/material';
import { Input } from '@components/common/Input';
import { Props } from "./MonthlyRemuneration.type";
import { InputRef } from '@/components/common/Input/InputInterfaces';
import './MonthlyRemuneration_style.css';
import { Link } from 'react-router-dom';
import {  ROUTE_EV_SALES_DATES_O3, ROUTE_GC_FAMILIES, ROUTE_GC_PRICE_LIST_CART, ROUTE_GC_PRICE_LIST_CHANEL, ROUTE_GC_PRICE_LIST_SEGMENTATION, ROUTE_RM_MONTHLY_REMUNERATION_FORM } from '@/toolbox/constants/route-map';

interface Column {
   id: 'id' | 'nombre';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}


const columns: readonly Column[] = [
   { id: 'id', label: 'Rut', minWidth: 100 },
   { id: 'nombre', label: 'Razón Social', minWidth: 100 }
];

interface Data {
   id: string;
   nombre: string;
}
function createDataContrato(
   id: string,
   nombre: string
): Data {
   return { id, nombre };
}


export const MonthlyRemuneration: React.FC<Props> = (props: Props): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);

   const [open, setOpen] = useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const styleCard = {
      width: '100%',
      // maxWidth: 400,
      bgcolor: 'background.paper',
   };
   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 720,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );

   const periodo = [
      { label: '2022' },
      { label: '2020' },
      { label: '2019' },
      { label: '2018' },
      { label: '2017' },
      { label: '2016' },
      { label: '2015' },
   ]
   const mes = [
      { label: 'Enero' },
      { label: 'Febrero' },
      { label: 'Marzo' },
      { label: 'Abril' },
      { label: 'Mayo' },
      { label: 'Junio' },
      { label: 'Agosto' },
      { label: 'Setiembre' },
      { label: 'Octubre' },
      { label: 'Noviembre sin ti' },
      { label: 'Diciembre' },
      { label: 'TODOS' }
   ]
   const centroCosto = [
      { label: 'TODOS' },
      { label: 'Operaciones' },
      { label: 'VENTAS' },
      { label: 'Desarrollo' },
      { label: 'Insumos' },
   ]
   const sucursal = [
      { label: 'Todas las Sucursales' },
      { label: 'Sucursal 1' },
      { label: 'Sucursal 2' },
      { label: 'Sucursal 3' },
      { label: 'Sucursal 4' }
   ]

   const rules = useMemo(() => ({
      rut_proveedor: [
         VALIDATORS.REQUIRED,
      ],
      numero_contrato: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      titulo: [
         VALIDATORS.REQUIRED
      ],
      descripcion: [
         VALIDATORS.REQUIRED,
      ]

   }), []);
   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'rut_proveedot':
            props.setvalueContrato(prev => ({ ...prev, rut_proveedot: value }));
            break;
         case 'numero_contrato':
            props.setvalueContrato(prev => ({ ...prev, numero_contrato: value }))
            break;
         case 'titulo':
            props.setvalueContrato(prev => ({ ...prev, titulo: value }))
            break;
         case 'descripcion':
            props.setvalueContrato(prev => ({ ...prev, descripcion: value }));
            break;

         default:
            break;
      }
   };

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }
   return (
      <Protected>
         <Container maxWidth='xl'>
            <form style={{ margin: '10px' }}>
               <Typography variant="h6" gutterBottom mb={3} className="tittle">
               PLANILLAS MENSUALES REMUNERACIONES
               </Typography>
               <Grid container mt={2} mb={2}>
                  <Typography variant="subtitle1" mb={3}>
                     Seleccione periodo y mes para generar archivo previred
                  </Typography>
               </Grid>

               <Grid container xs={12} className={`e-mb-lg`} sx={{ justifyContent: 'center' }}>
                  <Grid container item xs={11} sx={{ justifyContent: 'space-between' }}>
                     <Grid item xs={12} sm={5} mb={5}>
                        <Grid item container className='bg-tittle' mb={2} p={1}>
                           <Typography gutterBottom variant="h6" component="div">
                           Previred - Mes y Periodo excel
                           </Typography>
                        </Grid>
                        <Grid container sx={styleCard} aria-label="mailbox folders">
                           <Grid item xs={8} md={5} m={1}>
                              <Autocomplete
                                 disablePortal
                                 size='small'
                                 id="combo-box-demo"
                                 options={periodo}
                                 // sx={{ bgcolor: '#F3F3F3'}}
                                 renderInput={(params) => <TextField {...params} label="Periodo" />}
                              />
                           </Grid>                           
                           <Grid item xs={8} md={5} m={1}>
                              <Autocomplete
                                 disablePortal
                                 size='small'
                                 id="combo-box-demo"
                                 options={mes}
                                 // sx={{ bgcolor: '#F3F3F3'}}
                                 renderInput={(params) => <TextField {...params} label="Mes" />}
                              />
                           </Grid>
                           <Grid item xs={8} md={5} m={1}>
                              <Autocomplete
                                 disablePortal
                                 size='small'
                                 id="combo-box-demo"
                                 options={centroCosto}
                                 // sx={{ bgcolor: '#F3F3F3'}}
                                 renderInput={(params) => <TextField {...params} label="Centro Costos" />}
                              />
                           </Grid>
                           <Grid item container
                              xs={12}
                              justifyContent='center'
                              mt={2}
                           >
                              <Grid item>
                                 <Button
                                    type='submit'
                                    variant='contained'
                                    // onClick={handleSubmit}
                                    disabled={loading}
                                    disableElevation
                                    // component={Link}
                                    // to={ROUTE_EV_SALES_DATES_O1}
                                    className='btn_save'
                                 >
                                    Consultar
                                 </Button>
                              </Grid>
                           </Grid>
                        </Grid>
                     </Grid>
                     <Grid item xs={12} sm={5} mb={5}>
                        <Grid item container className='bg-tittle' mb={2} p={1}>
                           <Typography gutterBottom variant="h6" component="div">
                           Genera libro electrónico remuneraciones
                           </Typography>
                        </Grid>
                        <Grid container sx={styleCard} aria-label="mailbox folders">
                           <Grid item xs={8} md={5} m={1}>
                              <Autocomplete
                                 disablePortal
                                 size='small'
                                 id="combo-box-demo"
                                 options={periodo}
                                 // sx={{ bgcolor: '#F3F3F3'}}
                                 renderInput={(params) => <TextField {...params} label="Periodo" />}
                              />
                           </Grid>                        
                           <Grid item xs={8} md={5} m={1}>
                              <Autocomplete
                                 disablePortal
                                 size='small'
                                 id="combo-box-demo"
                                 options={mes}
                                 // sx={{ bgcolor: '#F3F3F3'}}
                                 renderInput={(params) => <TextField {...params} label="Mes" />}
                              />
                           </Grid>
                           <Grid item xs={8} md={5} m={1}>
                              <Autocomplete
                                 disablePortal
                                 size='small'
                                 id="combo-box-demo"
                                 options={centroCosto}
                                 // sx={{ bgcolor: '#F3F3F3'}}
                                 renderInput={(params) => <TextField {...params} label="Centro Costos" />}
                              />
                           </Grid>
                           <Grid item container
                              xs={12}
                              justifyContent='center'
                              mt={2}
                           >
                              <Grid item>
                                 <Button
                                    type='submit'
                                    variant='contained'
                                    // onClick={handleSubmit}
                                    disabled={loading}
                                    disableElevation
                                    // component={Link}
                                    // to={ROUTE_EV_SALES_DATES_O1}
                                    className='btn_save'
                                 >
                                    Consultar
                                 </Button>
                              </Grid>
                           </Grid>
                        </Grid>
                     </Grid>
                     <Grid item xs={12} sm={5} mb={5}>
                        <Grid item container className='bg-tittle' mb={2} p={1}>
                           <Typography gutterBottom variant="h6" component="div">
                           Formulario Adicionales Rem.
                           </Typography>
                        </Grid>
                        <Grid container sx={styleCard} aria-label="mailbox folders">                                             
                           <Grid item container
                              xs={12}
                              justifyContent='center'
                              mt={2}
                           >
                              <Grid item>
                                 <Button
                                    type='submit'
                                    variant='contained'
                                    // onClick={handleSubmit}
                                    disabled={loading}
                                    disableElevation
                                    component={Link}
                                    to={ROUTE_RM_MONTHLY_REMUNERATION_FORM}
                                    className='btn_save'
                                 >
                                 ir al formulario
                                 </Button>
                              </Grid>
                           </Grid>
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
            </form>
         </Container>
      </Protected>
   );
}