import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "../../BooksSalesNotes.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import '../../BooksSalesNotes_styles.css'
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import Modal from '@mui/material/Modal';  
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import SearchIcon from '@mui/icons-material/Search';
import { LoadingButton } from "@mui/lab";

interface Column {
   id: 'resumen' | 'cantidad' | 'total'|'porcentaje' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

const columns:readonly Column[] = [
   { id: 'resumen', label: 'resumen', minWidth: 100 },
   { id: 'cantidad', label: 'cantidad', minWidth: 100 },
   { id: 'total', label: 'total', minWidth: 100 },
   { id: 'porcentaje', label: 'porcentaje', minWidth: 100 },
   ];

interface Data {
   resumen: string;
   cantidad: string;
   total: string;
   porcentaje: string;
}

function createData(
   resumen: string,
   cantidad: string,
   total: string,
   porcentaje: string,
   ):Data {
   return { resumen, cantidad, total, porcentaje };
}

const rowsData = [
   createData('Notas de Ventas Emitidas', '1', '$ 28.156', '100 %'),
   createData('Nota de Venta Facturadas', '0', '$ 0', '0,00 %'),
];



export const TableResumen:React.FC<Props> = (props: Props): JSX.Element => {
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   // const [value, setValue] = useState('');

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };


    return (
        <Container maxWidth='xl'>
            <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:440}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>                        
                        <TableRow>
                           <TableCell>RESUMEN</TableCell>
                           <TableCell>CANTIDAD</TableCell>
                           <TableCell>TOTAL</TableCell>
                           <TableCell>%</TableCell>                           
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.resumen}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                
                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
               
            </Paper>

         </Container>
      
    );
   };

