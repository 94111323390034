import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container } from "@mui/material";
import { useState } from "react";
import { Props } from "../ElectronicSaleDocuments.type";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

import { Link } from "react-router-dom";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {
   ROUTE_F_DE_EX_ELECTRONIC_EXPORT_CREDIT_NOTE,
   ROUTE_F_DE_EX_ELECTRONIC_EXPORT_INVOICE,
   ROUTE_F_DE_EX_ELECTRONIC_EXPORT_DEBIT_NOTE,
} from "@/toolbox/constants/route-map";

export const DocumentExport: React.FC<Props> = (props: Props): JSX.Element => {
   const style = {
      width: "100%",
      // maxWidth: 500,
      bgcolor: "background.paper",
      color: "#000",
      borderRadius: "10px",
   };
   const disable = {
      bgcolor: "#EEEEEB",
   };
   const StyleIcon = {
      color: "rgb(228, 152, 13)",
   };

   return (
      <>
         <List sx={style} component="nav" aria-label="mailbox folders">
            <ListItem
               button
               component={Link}
               to={ROUTE_F_DE_EX_ELECTRONIC_EXPORT_INVOICE}
            >
               <ListItemAvatar>
                  <AssignmentIcon sx={{ color: "rgb(228, 152, 13)" }} />
               </ListItemAvatar>
               <ListItemText primary="FACTURA EXPORTACIÓN ELECTRÓNICA" />
            </ListItem>
            <Divider />
            <ListItem
               button
               component={Link}
               to={ROUTE_F_DE_EX_ELECTRONIC_EXPORT_DEBIT_NOTE}
            >
               <ListItemAvatar>
                  <AssignmentIcon sx={{ color: "rgb(228, 152, 13)" }} />
               </ListItemAvatar>
               <ListItemText primary="NOTA DE DÉBITO EXPORTACIÓN ELECTRÓNICA" />
            </ListItem>
            <Divider />
            <ListItem
               button
               component={Link}
               to={ROUTE_F_DE_EX_ELECTRONIC_EXPORT_CREDIT_NOTE}
            >
               <ListItemAvatar>
                  <AssignmentIcon sx={{ color: "rgb(228, 152, 13)" }} />
               </ListItemAvatar>
               <ListItemText primary="NOTA DE CRÉDITO EXPORTACIÓN ELECTRÓNICA" />
            </ListItem>
         </List>
      </>
   );
};
