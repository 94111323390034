import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
// import { ArrowRightIcon } from "@/toolbox/constants/icons";
import { VALIDATORS } from "@/toolbox/helpers/validation-rules";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, Card, CardActionArea, CardMedia, CardContent, TablePagination, } from '@mui/material';
// import { 2 } from '@components/common/Input';
import { Props } from "./SalesAvg.type";
import { InputRef } from '@/components/common/Input/InputInterfaces';
import './SalesAvg-style.css';
// import imagen from '@assets/img/banner4.jpg';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import { Chart } from "react-google-charts";

import { Link } from 'react-router-dom';
import { ROUTE_HOME } from '@/toolbox/constants/route-map';
// import { ROUTE_BODEGA } from '@/toolbox/constants/route-map';


export const SalesAvg: React.FC<Props> = (props: Props): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [valueT, setValueT] = useState('');
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [openEnterprise, setOpenEnterprise] = useState(false);

   const [open, setOpen] = React.useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);
   // const [open, setOpen] = useState(false);
   // const handleOpen = () => setOpen(true);
   // const handleClose = () => setOpen(false);

   const handleChangeT = (event: any) => {
      setValueT(event.target.value);
   };

   const handleOpenEnterprise = () => setOpenEnterprise(true);
   const handleCloseEnterprise = () => setOpenEnterprise(false);

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };
   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };
   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 650,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const categoriaValue = [
      { label: 'Bebidas' },
      { label: 'Envasados' },
      { label: 'Lacteos' },

   ]

   // DATA PARA DATOS DEL 1ER GRÁFICO DE BARRAS (DIAGRAMA DE PROMEDIOS)
   const dataPromedios = [
      ["Promedios", "Pedidos(u)", "Ganancias($)", "Nuevo($)"],
      ["08:00:00", 800, 2000, 1200],
      ["09:00:00", 470, 3000, 1250],
      ["10:00:00", 530, 1120, 1300],
      ["11:00:00", 220, 1500, 1300],
      ["12:00:00", 400, 1120, 1300],
      ["01:00:00", 660, 1120, 1700],
      ["02:00:00", 660, 1720, 1300],
      ["03:00:00", 930, 1540, 1350],
      ["04:00:00", 660, 1120, 1300],
      ["05:00:00", 700, 1120, 1300],
   ];
   const optionsPromedios = {
      chart: {
         title: "Diagrama de promedios",
         subtitle: "Promedios, pedidos, ganancias y nuevos en cada hora del día",
      },
   };
   // DATA PARA DATOS DEL 2DO GRÁFICO DE BARRAS (VENTAS POR DÍA)
   const dataVentasDiarias = [
      [
         "Diagrama Estadístico",
         "Ventas($)",
      ],
      ["08:30:00", 37.8],
      ["09:30:00", 30.9],
      ["10:30:00", 25.4],
      ["11:30:00", 11.7],
      ["12:30:00", 11.9],
      ["13:30:00", 8.8],
      ["14:30:00", 7.6],
      ["15:30:00", 12.3],
      ["16:30:00", 16.9],
      ["17:30:00", 12.8],
      ["18:30:00", 5.3],
      ["19:30:00", 6.6],
      ["20:30:00", 4.8],
      ["21:30:00", 4.2],
      ["22:30:00", 4.2],
      ["23:30:00", 4.2],
      ["24:30:00", 4.2],
   ];
   // DATA PARA DATOS DEL GRÁFICO DE TORTA (TOP PRODUCTOS SIMPLES)
   const dataProductSimple = [
      ["Task", "Hours per Day"],
      ["Otros", 11],
      ["Cerveza", 8],
      ["Huevos", 15],
   ];
   const optionsProductSimple = {
      // title: "My Daily Activities",
      pieHole: 0.3,
      is3D: false,
   };
   // DATA PARA DATOS DEL GRÁFICO DE TORTA (TOP PRODUCTOS COMPUESTOS)
   const dataProductCompuesto = [
      ["Task", "Hours per Day"],
      ["Otros", 11],
      ["Cerveza + Ron", 8],
      ["Ron + Coca Cola", 15],
   ];
   const optionsProductCompuesto = {
      // title: "My Daily Activities",
      pieHole: 0.3,
      is3D: false,
   };


   const optionsVentasDiarias = {
      chart: {
         title: "Ventas Diarias",
         subtitle: "Ventas realizadas cada día en el mes",
      },
   };
   //DATA PARA TABLA AVG
   interface Column {
      id: 'nombre' | 'ventas' | 'ganancias';
      label: string;
      minWidth?: number;
      align?: 'right';
      format?: (value: number) => string;
   }
   const columns: Column[] = [
      { id: 'nombre', label: 'Nombre', minWidth: 10 },
      { id: 'ventas', label: 'Ventas', minWidth: 100 },
      { id: 'ganancias', label: 'Ganancias', minWidth: 100 },
   ];

   interface Data {
      nombre: string;
      ventas: string;
      ganancias: string;
   }

   function createData(
      nombre: string,
      ventas: string,
      ganancias: string,
   ): Data {
      return { nombre, ventas, ganancias };
   }
   const rows = [
      createData('Producto A-01', '1100', '$29900'),
      createData('Producto A-02', '820', '$2000'),
      createData('Producto A-03', '700', '$2000'),
      createData('Producto A-04', '200', '$1000'),
      createData('Producto A-05', '193', '$900'),
      createData('Producto A-06', '100', '$800'),
      createData('Producto A-07', '80', '$200'),
   ]



   const rules = useMemo(() => ({
      rut_proveedor: [
         VALIDATORS.REQUIRED,
      ],
      numero_contrato: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      titulo: [
         VALIDATORS.REQUIRED
      ],
      descripcion: [
         VALIDATORS.REQUIRED,
      ]

   }), []);
   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'rut_proveedot':
            props.setvalueContrato(prev => ({ ...prev, rut_proveedot: value }));
            break;
         case 'numero_contrato':
            props.setvalueContrato(prev => ({ ...prev, numero_contrato: value }))
            break;
         case 'titulo':
            props.setvalueContrato(prev => ({ ...prev, titulo: value }))
            break;
         case 'descripcion':
            props.setvalueContrato(prev => ({ ...prev, descripcion: value }));
            break;

         default:
            break;
      }
   };

   // const handleInputBlur = (event: any) => {
   //    const newVal = (event.target.value).trim();
   //    const name = event.target.name;
   //    props.setvalueContrato(prev => ({ ...prev, [name]: newVal }));
   // }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }
   return (
      <Protected>
         <Container maxWidth='xl'>
            <form style={{ margin: '8px' }}>
               <Typography variant="h6" gutterBottom mb={3} className="tittle">
                  PROMEDIO DE VENTAS
               </Typography>
               <Grid container mt={2} mb={4} sx={{ alignItems: 'center' }}>
                  <Grid item xs={12} sm={11}>
                     <Typography variant="subtitle2">
                        Acceda a los detalles del promedio de ventas
                     </Typography>
                  </Grid>
                  <Grid item xs={12} sm={1} textAlign="right" component={Link} to={ROUTE_HOME}>
                     <button className='btn_botones' style={{cursor:'pointer'}}>
                        Regresar
                     </button>
                  </Grid>

               </Grid>
            </form>

            <div className='bg-tittle'>
               <Typography
                  mt={3}
                  variant="h6"
                  align='center'
                  className='tittle-text'
               >	Listado de Línea de Productos Ingresadas
               </Typography>
            </div>
         </Container>

         <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer component={Paper}>
               <Table aria-label="simple table">
                  <TableHead>
                     <TableRow>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Ventas</TableCell>
                        <TableCell>Ganancias</TableCell>                       
                        <TableCell>Opciones</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        return (
                           <TableRow hover role="checkbox" tabIndex={-1} key={row.nombre}>
                              {columns.map((column) => {
                                 const value = row[column.id];
                                 return (
                                    <TableCell key={column.id} align={column.align}>
                                       {column.format && typeof value === 'number'
                                          ? column.format(value)
                                          : value}
                                    </TableCell>
                                 );
                              })}                              
                              <TableCell>

                                 <IconButton
                                    aria-label="delete"
                                    // onClick={() => handleDelete(row.id)}
                                    className='btn_delete'

                                 // disabled={loading}
                                 >
                                    <SearchIcon />
                                 </IconButton>                                

                              </TableCell>
                           </TableRow>
                        );
                     })}
                  </TableBody>
               </Table>
            </TableContainer>
            <TablePagination
               rowsPerPageOptions={[10, 25, 100]}
               component="div"
               count={rows.length}
               rowsPerPage={rowsPerPage}
               page={page}
               onPageChange={handleChangePage}
               onRowsPerPageChange={handleChangeRowsPerPage}
            />
         </Paper>
      </Protected>
   );
}