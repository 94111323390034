import { Protected } from "@/components/layout/Protected";
import {
   Box,
   FormControl,
   Grid,
   Container,
   Typography,
   IconButton,
   SelectChangeEvent,
   Autocomplete,
   Button,
} from "@mui/material";
import { useState } from "react";
import { Props } from "../EvMarginSell.type";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import "../EvMarginSell_styles.css";
import TextField from "@mui/material/TextField";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import LoadingButton from "@mui/lab/LoadingButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { AddCircle } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BookIcon from "@mui/icons-material/Book";
import WbShadeIcon from "@mui/icons-material/WbShade";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import { ROUTE_EV_DE_MARGIN_SELL } from "@/toolbox/constants/route-map";
import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface Column {
   id: "rut" | "razonSocial" | "fantasia" | "giro";
   label: string;
   minWidth?: number;
   align?: "right";
   format?: (value: number) => string;
}
const columns: readonly Column[] = [
   { id: "rut", label: "Barra", minWidth: 100 },
   { id: "razonSocial", label: "Razon Social", minWidth: 50 },
   { id: "fantasia", label: "Fantasia", minWidth: 50 },
   { id: "giro", label: "Giro", minWidth: 50 },
];

interface Data {
   rut: string;
   razonSocial: string;
   fantasia: string;
   giro: string;
}

function createData(
   rut: string,
   razonSocial: string,
   fantasia: string,
   giro: string
): Data {
   return { rut, razonSocial, fantasia, giro };
}

const rowsData = [
   createData("12345678-9", "Razon Social 1", "Fantasia 1", "$1.00"),
   createData("12345678-9", "Razon Social 2", "Fantasia 2", "$1.00"),
   createData("12345678-9", "Razon Social 3", "Fantasia 3", "$1.00"),
   createData("12345678-9", "Razon Social 4", "Fantasia 4", "$1.00"),
   createData("12345678-9", "Razon Social 5", "Fantasia 5", "$1.00"),
];

export const DataRut: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState("");
   const [loading, setLoading] = useState(false);
   const [ejecutivoComercial, setEjecutivoComercial] = useState("");
   const [estado, setEstado] = useState("");

   const [currency, setCurrency] = React.useState("EUR");

   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setCurrency(event.target.value);
   };

   const handleChangeEjecutivoComercial = (event: SelectChangeEvent) => {
      setEjecutivoComercial(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
   ) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const estados = [
      { label: "No Adjudicada", value: "Enviado" },
      { label: "Adjudicada", value: "Adjudicada" },
      { label: "Anulada", value: "No Adjudicada" },
   ];

   const monedaValue = [
      { label: "100%" },
      { label: "90%" },
      { label: "80%" },
      { label: "70%" },
      { label: "60%" },
      { label: "50%" },
      { label: "40%" },
      { label: "30%" },
      { label: "20%" },
      { label: "10%" },
   ];

   return (
      <Container maxWidth="xl">
         <Grid item xs={12} md={12} style={{ marginBottom: "10px" }}>
            <Box>
               <Typography
                  variant="h6"
                  component="h1"
                  gutterBottom
                  className="tittle"
               >
                  Indicadores de Gestión
               </Typography>
            </Box>
         </Grid>
         <Grid container spacing={1} item xs={12}>
            <Grid item xs={12} md={2}>
               <FormControl fullWidth>
                  <Autocomplete
                     disablePortal
                     size="small"
                     // id="combo-box-demo"
                     options={monedaValue}
                     renderInput={(params) => (
                        <TextField {...params} label="Muy Bueno" />
                     )}
                  />
               </FormControl>
            </Grid>

            <Grid item xs={12} md={2}>
               <FormControl fullWidth>
                  <Autocomplete
                     disablePortal
                     size="small"
                     // id="combo-box-demo"
                     options={monedaValue}
                     renderInput={(params) => (
                        <TextField {...params} label="Bueno" />
                     )}
                  />
               </FormControl>
            </Grid>

            <Grid item xs={12} md={2}>
               <FormControl fullWidth>
                  <Autocomplete
                     disablePortal
                     size="small"
                     // id="combo-box-demo"
                     options={monedaValue}
                     renderInput={(params) => (
                        <TextField {...params} label="Regular" />
                     )}
                  />
               </FormControl>
            </Grid>

            <Grid item xs={12} md={2}>
               <FormControl fullWidth>
                  <Autocomplete
                     disablePortal
                     size="small"
                     // id="combo-box-demo"
                     options={monedaValue}
                     renderInput={(params) => (
                        <TextField {...params} label="Malo" />
                     )}
                  />
               </FormControl>
            </Grid>

            <Grid item xs={12} md={2}>
               <FormControl fullWidth>
                  <Autocomplete
                     disablePortal
                     size="small"
                     // id="combo-box-demo"
                     options={monedaValue}
                     renderInput={(params) => (
                        <TextField {...params} label="Muy Malo" />
                     )}
                  />
               </FormControl>
            </Grid>

            <Grid item xs={12} sm={2} textAlign="end">
               <Button
                  variant="contained"
                  component={Link}
                  to={ROUTE_EV_DE_MARGIN_SELL}
                  startIcon={<KeyboardArrowUpIcon />}
               >
                  Actualizar
               </Button>
            </Grid>
         </Grid>
      </Container>
   );
};
