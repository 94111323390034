import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, } from '@mui/material';
import { ArrowBackIcon, ArrowDownIcon, ArrowRightIcon, MoreIcon } from "@toolbox/constants/icons";
import { Props } from './FormMainDirection.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import Entity from "@assets/img/entity.png";
import './style.sass'
import "./FormMainDirection_style.css";
import BadgeIcon from '@mui/icons-material/Badge';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import BusinessIcon from '@mui/icons-material/Business';
import FaxIcon from '@mui/icons-material/Fax';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import LanguageIcon from '@mui/icons-material/Language';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import MobileDateTimePicker from '@mui/lab/MobileDateTimePicker';
import DesktopDateTimePicker from '@mui/lab/DesktopDateTimePicker';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';

export const FormMainDirection: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false)
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
    );
   const [estado, setEstado] = useState<any>({ rut: false, nombre_rz: false })

   const [valueConsiderable, setValueConsiderable] = React.useState('Si');
   const [valueTipoAdquisicion, setValueTipoAvalueTipoAdquisicion] = React.useState('Activo Propio');


   const handleChangeConsiderable = (event: React.ChangeEvent<HTMLInputElement>) => {
     setValueConsiderable((event.target as HTMLInputElement).value);
   };

    const handleTipoAvalueTipoAdquisicion = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueTipoAvalueTipoAdquisicion((event.target as HTMLInputElement).value);
    };
    
   
   const unidadMedida = [
      { label: 'Unidad medida 1'},
      { label: 'Unidad medida 2'},
      { label: 'Unidad medida 3'},
      { label: 'Unidad medida 4'},
      { label: 'Unidad medida 5'},
   ]
   const pais = [
      { label: 'Chile'},
      { label: 'Japón'},
      { label: 'Mongolia'},
      { label: 'Rusia'},
      { label: 'Júpiter'},
   ]
   
   const rules = useMemo(() => ({
      unidadMedida: [
         VALIDATORS.REQUIRED,
      ],
      stockMinimo: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      stockMaximo: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      ubicacionFisica: [
         VALIDATORS.REQUIRED,
      ],

   }), []);


   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'unidadMedida':
            props.setvalueInventario(prev => ({ ...prev, unidadMedida: value }));
            break;
         case 'stockMinimo':
            props.setvalueInventario(prev => ({ ...prev, stockMinimo: value }))
            break;
         case 'compra_activo':
            props.setvalueInventario(prev => ({ ...prev, compra_activo: value }))
            break;
         case 'revalorizacion':
            props.setvalueInventario(prev => ({ ...prev, revalorizacion: value }));
            break;
         case 'depreciacion':
            props.setvalueInventario(prev => ({ ...prev, depreciacion: value }));
            break;
         case 'readecuaciones':
            props.setvalueInventario(prev => ({ ...prev, readecuaciones: value }));
            break;
         case 'monto_credito':
            props.setvalueInventario(prev => ({ ...prev, monto_credito: value }));
            break;
         case 'credito_adquisicion':
            props.setvalueInventario(prev => ({ ...prev, credito_adquisicion: value }));
            break;
         case 'revalorizable':
            props.setvalueInventario(prev => ({ ...prev, revalorizable: value }));
            break;
         default:
            break;
      }
   };
   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      props.setvalueInventario(prev => ({ ...prev, [name]: newVal }));
   }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      props.handleNext();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }

   return (
      <Container maxWidth="xl">
         <form >
            <Grid container spacing={3} className={`e-mb-lg`}>
               <Grid item xs={12} sm={3}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                     <BadgeIcon />
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="stockMinimo"
                     type='text'
                     placeholder="Ingresar Dirección"
                     value={props.valueInventario.stockMinimo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.stockMinimo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                     <BadgeIcon />
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="stockMinimo"
                     type='text'
                     placeholder="Ingresar Geo"
                     value={props.valueInventario.stockMinimo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.stockMinimo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                     <BadgeIcon />
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="stockMinimo"
                     type='text'
                     placeholder="Comuna"
                     value={props.valueInventario.stockMinimo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.stockMinimo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                     <BadgeIcon />
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="stockMinimo"
                     type='text'
                     placeholder="Ciudad"
                     value={props.valueInventario.stockMinimo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.stockMinimo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={pais}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Seleccione País" />}
                  />
               </Grid>
               <Grid item container
                  xs={12}
                 justifyContent='right'
                 >
                     <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                        className='btn_save'
                        endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>Siguiente</span>
                        }
                     </Button>

                     </Grid>
               </Grid>
               {/* <Grid item container
                  xs={12}
                 justifyContent='right'
                 >
                     <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                        className='btn_save2'
                        endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>Agregar</span>
                        }
                     </Button>

                     </Grid>
               </Grid> */}
            </Grid>
         </form>
      </Container>
   );
}
