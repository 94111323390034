import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, IconButton, } from '@mui/material';
import {  ArrowRightIcon, DeleteIcon } from "@toolbox/constants/icons";
import { Props } from './FormWeb.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import './style.sass'
import "./FormWeb_style.css";
import HomeIcon from '@mui/icons-material/Home';
import SellIcon from '@mui/icons-material/Sell';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

interface Column {
   id: 'numero' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

const columns:readonly Column[] = [
   { id: 'numero', label: 'Número', minWidth: 100 },   
   ];

interface Data {
   numero: number;
}

function createDataImagenProducts(
   numero: number,
): Data {
   return { numero };
}

const rowsDataImagenProducts = [
   createDataImagenProducts(1),
   createDataImagenProducts(2),
   createDataImagenProducts(3),
   createDataImagenProducts(4),
   createDataImagenProducts(5),
   createDataImagenProducts(6),
   createDataImagenProducts(7),
]


export const FormWeb: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
    );
    const [page , setPage]= useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);


   const estado_web = [
      { value: '1', label: 'No publicar' },
      { value: '2', label: 'Publicar en Catalogo Web' },
      { value: '3', label: 'Best Seller' },
   ]

   const destacarProducto = [
      { value: '1', label: 'Si' },
      { value: '2', label: 'No' },

   ]
   const marcarNuevo=[
      { value: '1', label: 'Si' },
      { value: '2', label: 'No' },
   ]

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };
   
   const rules = useMemo(() => ({
      ruta_video: [
         VALIDATORS.REQUIRED,
      ],
      file_adjunto: [
         VALIDATORS.REQUIRED,
      ],
      fecha_limite: [
         VALIDATORS.REQUIRED
      ],
      ficha_producto: [
         VALIDATORS.REQUIRED,
      ],

   }), []);


   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'ruta_video':
            props.setvalueEntry(prev => ({ ...prev, ruta_video: value }));
            break;
         case 'file_adjunto':
            props.setvalueEntry(prev => ({ ...prev, file_adjunto: value }))
            break;
         case 'fecha_limite':
            props.setvalueEntry(prev => ({ ...prev, fecha_limite: value }))
            break;
         case 'ficha_producto':
            props.setvalueEntry(prev => ({ ...prev, ficha_producto: value }));
            break;
         default:
            break;
      }
   };
   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      props.setvalueEntry(prev => ({ ...prev, [name]: newVal }));
   }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      props.handleNext();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }

   return (
      <Container maxWidth="xl">
         <form >
            <Grid container spacing={3} className={`e-mb-lg`}>
            
            <Grid item xs={12} sm={4}>
                  <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={estado_web}
                           renderInput={(params) => <TextField {...params} label="Estado Web" />}
                  />
            </Grid>

            <Grid item xs={12} sm={4}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <SellIcon/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     type='text'
                     rules={rules.ruta_video}
                     name='ruta_video'
                     placeholder="Ruta Video"
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>

               <Grid item xs={12} sm={4}>
                  <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={destacarProducto}
                           renderInput={(params) => <TextField {...params} label="Destacar Producto" />}
                  />
               </Grid>

               <Grid item xs={12} sm={4}>
                  <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={marcarNuevo}
                           renderInput={(params) => <TextField {...params} label="Marcar como nuevo" />}
                  />
               </Grid>

               <Grid item xs={12} sm={4}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                     <DatePicker
                        views={['year', 'month']}
                        label="Feha Limite"
                        minDate={new Date('2012-03-01')}
                        maxDate={new Date('2023-06-01')}
                        value={value}
                        onChange={(newValue) => {
                           setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} helperText={null} />}
                     />
                     </LocalizationProvider>
               </Grid>

               <Grid item xs={12} sm={4}>
                  <Input 
                   ref={ref => inputRefs.current[0] = ref}
                  type="file" />
               </Grid>
               <Grid item xs={12}>
               <TextField
                  id="outlined-multiline-static"
                  label="Ficha Producto"
                  multiline
                  rows={3}
                  fullWidth
               />
               </Grid>
               <Grid item xs={12}>
                  <Typography
                        className='bg-tittle' 
                        mt={3} 
                        variant="h6"
                        align='center'
                        >IMAGENES PRODUCTO
                  </Typography>
               </Grid>
            
            <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:440}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           <TableCell>N</TableCell>
                           <TableCell>Archivo</TableCell>
                           <TableCell align='right'>Borrar</TableCell>

                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsDataImagenProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.numero}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell
                                 >
                                    <Input 
                                       ref={ref => inputRefs.current[0] = ref}
                                       type="file"
                                       />  
                                 </TableCell>
                                 <TableCell align='right' >
                                       <IconButton>
                                                <DeleteIcon
                                             // onClick={handleOpenContact}
                                          />
                                       </IconButton>
                                 </TableCell>
                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsDataImagenProducts.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
         </Paper>

               <Grid item container
                  xs={12}
                 justifyContent='right'
                 >
                     <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                        className='btn_save'
                        
                        endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>Siguiente</span>
                        }
                     </Button>

                     </Grid>
               </Grid>
            </Grid>
         </form>
      </Container>
   );
}
