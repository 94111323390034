import React, { useState, useMemo, useRef, useEffect } from 'react';
import { FunctionComponent } from "react";
import { Grid, Typography } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import Link from '@mui/material/Link';
import { ROUTE_EV_DE_ELECTRONIC_BILLING_EXENTA
   ,ROUTE_EV_DE_MEANS_PAYMENT_VOUCHER
   ,ROUTE_EV_DE_ELECTRONIC_BILLING
   ,ROUTE_EV_DE_DISPASH_INVOICE
   ,ROUTE_EV_DE_NOTE_BILLING_EXPORT
   ,ROUTE_EV_DE_INVOICE_OFFICE_GUIDES } from '@/toolbox/constants/route-map';


export const Billing: FunctionComponent = () => {

   return (
      <>
         <Grid container spacing={2} pl={5} mt={1}>
            <Grid container item>
               <Link href={ROUTE_EV_DE_ELECTRONIC_BILLING} underline="none">
                  <Grid className={'enlacesDoc'}>
                     <ArticleIcon />
                     <Typography variant="subtitle1" ml={2}>
                        FACTURA ELECTRONICA
                     </Typography>
                  </Grid>
               </Link>
            </Grid>
            <Grid container item>
               <Link href={ROUTE_EV_DE_NOTE_BILLING_EXPORT} underline="none">
                  <Grid className={'enlacesDoc'}>
                     <ArticleIcon />
                     <Typography variant="subtitle1" ml={2}>
                        FACTURAR NOTAS DE VENTA DE EXPORTACIÓN
                     </Typography>
                  </Grid>
               </Link>
            </Grid>
            <Grid container item>
               <Link href={ROUTE_EV_DE_INVOICE_OFFICE_GUIDES} underline="none">
                  <Grid className={'enlacesDoc'}>
                     <ArticleIcon />
                     <Typography variant="subtitle1" ml={2}>
                        FACTURAR GUIAS DE DESPACHO
                     </Typography>
                  </Grid>
               </Link>
            </Grid>
            <Grid container item>
               <Link href={ROUTE_EV_DE_DISPASH_INVOICE} underline="none">
                  <Grid className={'enlacesDoc'}>
                     <ArticleIcon />
                     <Typography variant="subtitle1" ml={2}>
                        DESPACHAR FACTURAS
                     </Typography>
                  </Grid>
               </Link>
            </Grid>
            <Grid container item>
               <Link href={ROUTE_EV_DE_ELECTRONIC_BILLING_EXENTA} underline="none">
                  <Grid className={'enlacesDoc'}>
                     <ArticleIcon />
                     <Typography variant="subtitle1" ml={2}>
                        FACTURA EXENTA ELECTRONICA
                     </Typography>
                  </Grid>
               </Link>
            </Grid>
            <Grid container item>
               <Link href={ROUTE_EV_DE_MEANS_PAYMENT_VOUCHER} underline="none">
                  <Grid className={'enlacesDoc'}>
                     <ArticleIcon />
                     <Typography variant="subtitle1" ml={1}>
                        VALE DE COMPROBANTE MEDIO DE PAGO ELECTRONICO
                     </Typography>
                  </Grid>
               </Link>
            </Grid>

         </Grid>
      </>
   );
}

