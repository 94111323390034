import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { ArrowRightIcon } from "@/toolbox/constants/icons";
import { VALIDATORS } from "@/toolbox/helpers/validation-rules";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, TablePagination, TableFooter, } from '@mui/material';
import { Input } from '@components/common/Input';
import { Props } from "./QuotationEntity.type";
import BusinessIcon from '@mui/icons-material/Business';
import { InputRef } from '@/components/common/Input/InputInterfaces';
import './QuotationEntity_style.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from 'react-router-dom';
import { ROUTE_EV_SALES_DATES, ROUTE_GC_FAMILIES, ROUTE_GC_PRICE_LIST, ROUTE_RM_QUOTATION_SHEET } from '@/toolbox/constants/route-map';

export const QuotationEntity: React.FC<Props> = (props: Props): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);



   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 750,
      // height: 600,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const rules = useMemo(() => ({
      rut_proveedor: [
         VALIDATORS.REQUIRED,
      ],
      numero_contrato: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      titulo: [
         VALIDATORS.REQUIRED
      ],
      descripcion: [
         VALIDATORS.REQUIRED,
      ]

   }), []);
   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'rut_proveedot':
            props.setvalueContrato(prev => ({ ...prev, rut_proveedot: value }));
            break;
         case 'numero_contrato':
            props.setvalueContrato(prev => ({ ...prev, numero_contrato: value }))
            break;
         case 'titulo':
            props.setvalueContrato(prev => ({ ...prev, titulo: value }))
            break;
         case 'descripcion':
            props.setvalueContrato(prev => ({ ...prev, descripcion: value }));
            break;

         default:
            break;
      }
   };

   //TABLA 1 INSTITUCIONES DE AFP
   function createData(
      nombre: string,
      renta: string,
      afp: string,
      sis: string,
      cesantia: string,
      apv: string,
      trabajadores: string,
      total: string,
   ) {
      return { nombre, renta, afp, sis, cesantia, apv, trabajadores, total };
   }

   const rows = [
      createData('Capital', '$ 981.250', '$ 112.255', '$ 17.253', '$ 8.440', '$ 0', '2', '$ 137.948'),
      createData('Cuprum', '$ 3.160.427', '$ 3.160.427', '$ 3.160.427	', '$ 8.440', '$ 0', '2', '$ 137.948'),
      createData('PlanVital', '$ 981.250	', '$ 112.255', '$ 17.253', '$ 8.440', '$ 0', '2', '$ 137.948'),
   ];
   //TABLA 2 INSTITUCIONES DE ISAPRE
   function createData2(
      nombre: string,
      salud: string,
      trabajadores: string
   ) {
      return { nombre, salud, trabajadores };
   }

   const rows2 = [
      createData2('Banmedica S.A.', '$501.244', '9'),
      createData2('Colmena Golden Cross S.A.', '$501.244', '9'),
      createData2('Consalud S.A.', '$501.244', '1'),
   ];
   //TABLA 3 IPSFONASA IPSFONASA
   function createData3(
      fonasa: string,
      accidente: string,
      total: string,
   ) {
      return { fonasa, accidente, total };
   }

   const rows3 = [
      createData3('$ 31.500', '$ 0', '$ 31.500')
   ];

   // const handleInputBlur = (event: any) => {
   //    const newVal = (event.target.value).trim();
   //    const name = event.target.name;
   //    props.setvalueContrato(prev => ({ ...prev, [name]: newVal }));
   // }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }
   return (
      <Protected>
         <Container maxWidth='xl'>
            <Grid container mb={4}>
               <Grid item xs={12} sm={10}>
                  <Typography variant="h6" id="modal-modal-title" mb={2} className='tittle'>
                     PLANILLA DE COTIZACIONES POR ENTIDAD
                  </Typography>
                  <Typography variant="subtitle2">
                     Periodo 2022 mes 1
                  </Typography>
               </Grid>
               <Grid item xs={12} sm={2} textAlign="end">
                  <Button variant="outlined" component={Link} to={ROUTE_RM_QUOTATION_SHEET} startIcon={<ArrowBackIosIcon />}>
                     Volver
                  </Button>
               </Grid>
               <Grid item xs={12} mb={2}>
                  <Typography variant="subtitle2">
                     Rut	: 22222222-2
                  </Typography>
                  <Typography variant="subtitle2">
                     Razon Social	: DEMO ERP SOFTNET
                  </Typography>
                  <Typography variant="subtitle2">
                     Direccion	: YUNGAY 1942 - VALPARAISO
                  </Typography>
               </Grid>

            </Grid>
            {/* PRIMERA TABLA (INSTITUCIONES DE AFP) */}
            <Grid xs={12} mb={5}>
               <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  <TableContainer component={Paper}>
                     <Table aria-label="simple table">
                        <TableHead>
                           <TableRow>
                              <TableCell colSpan={8}>
                                 INSTITUCIONES DE AFP
                              </TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell>NOM. AFP</TableCell>
                              <TableCell>RENTA IMPONIBLE</TableCell>
                              <TableCell>COT. AFP</TableCell>
                              <TableCell>	S.I.S.</TableCell>
                              <TableCell>Seguro Cesantia</TableCell>
                              <TableCell>APV</TableCell>
                              <TableCell>TRABAJADORES</TableCell>
                              <TableCell>Total AFP</TableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {rows.map((row) => (
                              <TableRow
                                 key={row.nombre}
                                 sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                 <TableCell component="th" scope="row">
                                    {row.nombre}
                                 </TableCell>
                                 <TableCell >{row.renta}</TableCell>
                                 <TableCell >{row.afp}</TableCell>
                                 <TableCell >{row.sis}</TableCell>
                                 <TableCell >{row.cesantia}</TableCell>
                                 <TableCell >{row.apv}</TableCell>
                                 <TableCell >{row.trabajadores}</TableCell>
                                 <TableCell >{row.total}</TableCell>
                              </TableRow>
                           ))}
                        </TableBody>
                        <TableFooter>
                           <TableRow>
                              <TableCell align="right"> Totales</TableCell>
                              <TableCell > 27</TableCell>
                              <TableCell > $315.530.000</TableCell>
                              <TableCell > $0</TableCell>
                              <TableCell > $745677</TableCell>
                              <TableCell > $14.00876</TableCell>
                              <TableCell > $44.00876</TableCell>
                              <TableCell > $44.00876</TableCell>
                           </TableRow>
                        </TableFooter>
                     </Table>
                  </TableContainer>
               </Paper>
            </Grid>
            {/* SEGUNDA TABLA (INSTITUCIONES DE ISAPRE) */}
            <Grid xs={12} mb={5}>
               <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  <TableContainer component={Paper}>
                     <Table aria-label="simple table">
                        <TableHead>
                           <TableRow>
                              <TableCell colSpan={4}>
                                 INSTITUCIONES DE AFP
                              </TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell>NOM. ISAPRE</TableCell>
                              <TableCell>COT. SALUD</TableCell>
                              <TableCell></TableCell>
                              <TableCell>TRABAJADORES</TableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {rows2.map((row) => (
                              <TableRow
                                 key={row.nombre}
                                 sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                 <TableCell component="th" scope="row">
                                    {row.nombre}
                                 </TableCell>                                
                                 <TableCell >{row.salud}</TableCell>
                                 <TableCell ></TableCell>
                                 <TableCell >{row.trabajadores}</TableCell>
                              </TableRow>
                           ))}
                        </TableBody>
                        <TableFooter>
                           <TableRow>
                              <TableCell align="right"> Totales</TableCell>
                              <TableCell > $315.530.000</TableCell>
                              <TableCell > </TableCell>
                              <TableCell > 25</TableCell>
                           </TableRow>
                        </TableFooter>
                     </Table>
                  </TableContainer>
               </Paper>
            </Grid>
            {/* TERCERA TABLA (IPSFONASA IPSFONASA) */}
            <Grid xs={12} mb={5}>
               <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  <TableContainer component={Paper}>
                     <Table aria-label="simple table">
                        <TableHead>
                           <TableRow>
                              <TableCell colSpan={3}>
                                 IPSFONASA IPSFONASA
                              </TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell>FONASA</TableCell>
                              <TableCell>ACCIDENTE DE TRABAJO ( Porcentaje %)	</TableCell>
                              <TableCell>TOTAL IPSFONASA</TableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {rows3.map((row) => (
                              <TableRow
                                 key={row.fonasa}
                                 sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                 <TableCell component="th" scope="row">
                                    {row.fonasa}
                                 </TableCell>
                                 <TableCell >{row.accidente}</TableCell>
                                 <TableCell >{row.total}</TableCell>
                              </TableRow>
                           ))}
                        </TableBody>
                        <TableFooter>
                           <TableRow>
                              <TableCell align="right"> Totales</TableCell>
                              <TableCell > $ 0</TableCell>
                              <TableCell > $ 31.500</TableCell>
                           </TableRow>
                        </TableFooter>
                     </Table>
                  </TableContainer>
               </Paper>
            </Grid>
         </Container>
      </Protected>
   );
}