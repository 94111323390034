import React, { FunctionComponent, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import "./EvMarginSell_styles.css";
import TextField from "@mui/material/TextField";
import { Autocomplete, Box, Button, Grid } from "@mui/material";
import { Protected } from "@/components/layout/Protected";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Link } from "react-router-dom";
import {
   ROUTE_EV_MONTH_DAILY,
   ROUTE_EV_MONTH_YEARS,
   ROUTE_EV_PRODUCT_DETAIL,
} from "@/toolbox/constants/route-map";
import Tooltip from "@mui/material/Tooltip";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { styled } from "@mui/material/styles";
import SettingsIcon from "@mui/icons-material/Settings";
import { DataRut } from "./Modal/DataRut";
import Modal from "@mui/material/Modal";

const Input = styled("input")({
   display: "none",
});

const bases = [
   { label: "Venta", value: 1 },
   { label: "Costo", value: 2 },
];
const sucursales = [
   { label: "Sucursal 1", value: 1 },
   { label: "Sucursal 2", value: 2 },
   { label: "Sucursal 3", value: 3 },
   { label: "Sucursal 4", value: 4 },
];
interface EvMarginSell {}
interface Column {
   id:
      | "grc"
      | "codigo"
      | "descripcion"
      | "fisico"
      | "valorizado"
      | "fisico1"
      | "valorizado1"
      | "costoVenta"
      | "margen";
   label: string;
   minWidth?: number;
   align?: "right";
   format?: (value: number) => string;
}
interface Column1 {
   id:
      | "grc"
      | "codigo"
      | "descripcion"
      | "fisico"
      | "valorizado"
      | "fisico1"
      | "valorizado1"
      | "costoVenta"
      | "margen";
   label: string;
   minWidth?: number;
   align?: "right";
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: "grc", label: "GRC", minWidth: 10 },
   { id: "codigo", label: "Código", minWidth: 100 },
   { id: "descripcion", label: "Descripción", minWidth: 100 },
   { id: "fisico", label: "Fisico", minWidth: 100 },
   { id: "valorizado", label: "Valorizado", minWidth: 100 },
   { id: "fisico1", label: "Fisico1", minWidth: 100 },
   { id: "valorizado1", label: "Valorizado1", minWidth: 100 },
   { id: "costoVenta", label: "Costo Venta", minWidth: 100 },
   { id: "margen", label: "Margen", minWidth: 100 },
];

const columns1: readonly Column1[] = [
   { id: "grc", label: "GRC", minWidth: 10 },
   { id: "codigo", label: "Código", minWidth: 100 },
   { id: "descripcion", label: "Descripción", minWidth: 100 },
   { id: "fisico", label: "Fisico", minWidth: 100 },
   { id: "valorizado", label: "Valorizado", minWidth: 100 },
   { id: "fisico1", label: "Fisico1", minWidth: 100 },
   { id: "valorizado1", label: "Valorizado1", minWidth: 100 },
   { id: "costoVenta", label: "Costo Venta", minWidth: 100 },
   { id: "margen", label: "Margen", minWidth: 100 },
];

interface Data {
   grc: string;
   codigo: string;
   descripcion: string;
   fisico: string;
   valorizado: string;
   fisico1: string;
   valorizado1: string;
   costoVenta: string;
   margen: string;
}

interface Data1 {
   grc: string;
   codigo: string;
   descripcion: string;
   fisico: string;
   valorizado: string;
   fisico1: string;
   valorizado1: string;
   costoVenta: string;
   margen: string;
}

function createData(
   grc: string,
   codigo: string,
   descripcion: string,
   fisico: string,
   valorizado: string,
   fisico1: string,
   valorizado1: string,
   costoVenta: string,
   margen: string
): Data {
   return {
      grc,
      codigo,
      descripcion,
      fisico,
      valorizado,
      fisico1,
      valorizado1,
      costoVenta,
      margen,
   };
}

function createData1(
   grc: string,
   codigo: string,
   descripcion: string,
   fisico: string,
   valorizado: string,
   fisico1: string,
   valorizado1: string,
   costoVenta: string,
   margen: string
): Data {
   return {
      grc,
      codigo,
      descripcion,
      fisico,
      valorizado,
      fisico1,
      valorizado1,
      costoVenta,
      margen,
   };
}

const rowsData = [
   createData(
      "0000001",
      "Oferta JCO",
      "0",
      "0",
      "29",
      "$ 12.548",
      "$ 15.454",
      "45.1545",
      "$ 12.548"
   ),
   createData(
      "0000002",
      "Oferta JCO",
      "0",
      "0",
      "29",
      "$ 12.548",
      "$ 15.454",
      "45.1545",
      "$ 12.548"
   ),
   createData(
      "0000003",
      "Oferta JCO",
      "0",
      "0",
      "29",
      "$ 12.548",
      "$ 15.454",
      "45.1545",
      "$ 12.548"
   ),
   createData(
      "0000004",
      "Oferta JCO",
      "0",
      "0",
      "29",
      "$ 12.548",
      "$ 15.454",
      "45.1545",
      "$ 12.548"
   ),
   createData(
      "0000005",
      "Oferta JCO",
      "0",
      "0",
      "29",
      "$ 12.548",
      "$ 15.454",
      "45.1545",
      "$ 12.548"
   ),
   createData(
      "0000006",
      "Oferta JCO",
      "0",
      "0",
      "29",
      "$ 12.548",
      "$ 15.454",
      "45.1545",
      "$ 12.548"
   ),
];
const rowsData1 = [];

export const EvMarginSell: FunctionComponent<EvMarginSell> = () => {
   const [value, setValue] = React.useState<Date | null>(
      new Date("2018-01-01T00:00:00.000Z")
   );

   const [openRut, setOpenRut] = React.useState(false);
   const handleOpenRut = () => setOpenRut(true);
   const handleCloseRut = () => setOpenRut(false);

   const style = {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 870,
      bgcolor: "background.paper",
      // border: '2px solid #000',
      boxShadow: 24,
      p: 4,
      borderRadius: "20px",
   };

   return (
      <>
         <Protected>
            <div style={{ margin: "5px" }}>
               <Grid container>
                  <Typography
                     variant="h6"
                     component="h2"
                     gutterBottom
                     className="tittle"
                  >
                     Margen de Venta
                  </Typography>
               </Grid>

               <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                     <Grid container spacing={2}>
                        <Grid item xs={12} md={11}>
                           <Typography
                              variant="subtitle2"
                              p={1}
                              component="h2"
                              gutterBottom
                              style={{
                                 background: "#16556b",
                                 color: "#ffffff",
                              }}
                           >
                              Opcion 01 - Mes y Periodo
                           </Typography>
                        </Grid>
                        <Grid item xs={12} md={7}>
                           <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                 views={["year", "month"]}
                                 label="Periodo y Mes"
                                 minDate={new Date("2012-03-01")}
                                 maxDate={new Date("2023-06-01")}
                                 value={value}
                                 onChange={(newValue) => {
                                    setValue(newValue);
                                 }}
                                 renderInput={(params) => (
                                    <TextField
                                       {...params}
                                       fullWidth
                                       helperText={null}
                                    />
                                 )}
                              />
                           </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={7}>
                           <Autocomplete
                              id="combo-box-demo"
                              options={bases}
                              size="small"
                              disablePortal
                              renderInput={(params) => (
                                 <TextField {...params} label="Bases" />
                              )}
                           />
                        </Grid>
                        <Grid item xs={12} md={7}>
                           <Autocomplete
                              id="combo-box-demo"
                              options={sucursales}
                              size="small"
                              disablePortal
                              renderInput={(params) => (
                                 <TextField {...params} label="Sucursal" />
                              )}
                           />
                        </Grid>
                        <Grid item xs={12} md={4} textAlign="end">
                           <Button
                              type="submit"
                              variant="contained"
                              // onClick={handleSubmit}
                              // disabled={loading}
                              disableElevation
                              className="btn_consultar"
                              // style={{fontSize:'0.2rem'}}
                              component={Link}
                              to={ROUTE_EV_MONTH_YEARS}
                           >
                              Consultar
                           </Button>
                        </Grid>
                     </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                     <Grid container spacing={2}>
                        <Grid item xs={12} md={11}>
                           <Typography
                              variant="subtitle2"
                              p={1}
                              component="h2"
                              gutterBottom
                              style={{
                                 background: "#16556b",
                                 color: "#ffffff",
                              }}
                           >
                              Detalle Producto Pack
                           </Typography>
                        </Grid>
                        <Grid item xs={12} md={7}>
                           <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                 views={["year", "month"]}
                                 label="Periodo y Mes"
                                 minDate={new Date("2012-03-01")}
                                 maxDate={new Date("2023-06-01")}
                                 value={value}
                                 onChange={(newValue) => {
                                    setValue(newValue);
                                 }}
                                 renderInput={(params) => (
                                    <TextField
                                       {...params}
                                       fullWidth
                                       helperText={null}
                                    />
                                 )}
                              />
                           </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={7}>
                           <Autocomplete
                              id="combo-box-demo"
                              options={bases}
                              size="small"
                              disablePortal
                              renderInput={(params) => (
                                 <TextField {...params} label="Bases" />
                              )}
                           />
                        </Grid>
                        <Grid item xs={12} md={7}>
                           <Autocomplete
                              id="combo-box-demo"
                              options={sucursales}
                              size="small"
                              disablePortal
                              renderInput={(params) => (
                                 <TextField {...params} label="Sucursal" />
                              )}
                           />
                        </Grid>
                        <Grid item xs={12} md={4} textAlign="end">
                           <Button
                              type="submit"
                              variant="contained"
                              // onClick={handleSubmit}
                              // disabled={loading}
                              disableElevation
                              className="btn_consultar"
                              // style={{fontSize:'0.2rem'}}
                              component={Link}
                              to={ROUTE_EV_PRODUCT_DETAIL}
                           >
                              Consultar
                           </Button>
                        </Grid>
                     </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                     <Grid container spacing={2}>
                        <Grid item xs={12} md={11}>
                           <Typography
                              variant="subtitle2"
                              p={1}
                              component="h2"
                              gutterBottom
                              style={{
                                 background: "#16556b",
                                 color: "#ffffff",
                              }}
                           >
                              Opcion 02 - Diario
                           </Typography>
                        </Grid>
                        <Grid item xs={12} md={7}>
                           <Autocomplete
                              id="combo-box-demo"
                              options={bases}
                              size="small"
                              disablePortal
                              renderInput={(params) => (
                                 <TextField {...params} label="Bases" />
                              )}
                           />
                        </Grid>
                        <Grid item xs={12} md={7}>
                           <Autocomplete
                              id="combo-box-demo"
                              options={sucursales}
                              size="small"
                              disablePortal
                              renderInput={(params) => (
                                 <TextField {...params} label="Sucursal" />
                              )}
                           />
                        </Grid>
                        <Grid item xs={12} md={4} textAlign="end">
                           <Button
                              type="submit"
                              variant="contained"
                              // onClick={handleSubmit}
                              // disabled={loading}
                              disableElevation
                              className="btn_consultar"
                              // style={{fontSize:'0.2rem'}}
                              component={Link}
                              to={ROUTE_EV_MONTH_DAILY}
                           >
                              Consultar
                           </Button>
                        </Grid>
                     </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                     <Grid container spacing={2}>
                        <Grid item xs={12} md={11}>
                           <Typography
                              variant="subtitle2"
                              p={1}
                              component="h2"
                              gutterBottom
                              style={{
                                 background: "#16556b",
                                 color: "#ffffff",
                              }}
                           >
                              Indicadores de Margenes de Ventas (KPI)
                           </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                           <Grid item xs={3} md={2}>
                              <Typography
                                 variant="subtitle2"
                                 component="h2"
                                 gutterBottom
                              >
                                 Muy Bueno
                              </Typography>
                              <ArrowUpwardIcon
                                 style={{ fontSize: "2rem", color: "green" }}
                              />
                              <Typography
                                 variant="subtitle2"
                                 component="h2"
                                 gutterBottom
                              >
                                 50%
                              </Typography>
                           </Grid>
                           <Grid item xs={3} md={2}>
                              <Typography
                                 variant="subtitle2"
                                 component="h2"
                                 gutterBottom
                              >
                                 Bueno
                              </Typography>
                              <ArrowUpwardIcon
                                 style={{ fontSize: "2rem", color: "blue" }}
                              />
                              <Typography
                                 variant="subtitle2"
                                 component="h2"
                                 gutterBottom
                              >
                                 40%
                              </Typography>
                           </Grid>
                           <Grid item xs={6} md={2}>
                              <Typography
                                 variant="subtitle2"
                                 component="h2"
                                 gutterBottom
                              >
                                 Regular
                              </Typography>
                              <CallReceivedIcon
                                 style={{ fontSize: "2rem", color: "yellow" }}
                              />
                              <Typography
                                 variant="subtitle2"
                                 component="h2"
                                 gutterBottom
                              >
                                 30%
                              </Typography>
                           </Grid>
                           <Grid item xs={6} md={2}>
                              <Typography
                                 variant="subtitle2"
                                 component="h2"
                                 gutterBottom
                              >
                                 Malo
                              </Typography>
                              <ArrowDownwardIcon
                                 style={{ fontSize: "2rem", color: "trf" }}
                              />
                              <Typography
                                 variant="subtitle2"
                                 component="h2"
                                 gutterBottom
                              >
                                 20%
                              </Typography>
                           </Grid>
                           <Grid item xs={6} md={2}>
                              <Typography
                                 variant="subtitle2"
                                 component="h2"
                                 gutterBottom
                              >
                                 Muy Malo
                              </Typography>
                              <ArrowDownwardIcon
                                 style={{ fontSize: "2rem", color: "red" }}
                              />
                              <Typography
                                 variant="subtitle2"
                                 component="h2"
                                 gutterBottom
                              >
                                 10%
                              </Typography>
                           </Grid>
                           <Grid item xs={12} md={2}>
                              <Tooltip title="Configuración">
                                 <IconButton
                                    onClick={handleOpenRut}
                                    className="btn_consultar"
                                 >
                                    <SettingsIcon />
                                 </IconButton>
                              </Tooltip>
                           </Grid>
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>

               <Modal
                  open={openRut}
                  onClose={handleCloseRut}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style}>
                     <DataRut entity={undefined} />
                  </Box>
               </Modal>
            </div>
         </Protected>
      </>
   );
};
