import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "./BranchOffice.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './BranchOffice_styles.css'
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import Modal from '@mui/material/Modal';
import { DataModal } from "./DataModal";
import { DataRut } from "./DataRut";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import SearchIcon from '@mui/icons-material/Search';
import { LoadingButton } from "@mui/lab";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

interface Column {
   id: 'id' | 'nombre' | 'direccion'|'comuna' | 'ciudad'|'rolSucursal';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

const columns:readonly Column[] = [
   { id: 'id', label: 'ID', minWidth: 10 },
   { id: 'nombre', label: 'Nombre', minWidth: 50 },
   { id: 'direccion', label: 'Dirección', minWidth: 50 },
   { id: 'comuna', label: 'Comuna', minWidth: 100 },
   { id: 'ciudad', label: 'Ciudad', minWidth: 100 },
   { id: 'rolSucursal', label: 'Rol Sucursal', minWidth: 100 },
   ];

interface Data {
   id: number;
   nombre: string;
   direccion: string;
   comuna: string;
   ciudad: string;
   rolSucursal: string;
}

function createData(
   id: number,
   nombre: string,
   direccion: string,
   comuna: string,
   ciudad: string,
   rolSucursal: string,
   ):Data {
   return { id, nombre, direccion, comuna, ciudad, rolSucursal };
}

const rowsData = [
   createData(1, 'Sucursal 1', 'Calle 1', 'Comuna 1', 'Ciudad 1', 'Rol Sucursal 1'),
   createData(2, 'Sucursal 2', 'Calle 2', 'Comuna 2', 'Ciudad 2', 'Rol Sucursal 2'),
   createData(3, 'Sucursal 3', 'Calle 3', 'Comuna 3', 'Ciudad 3', 'Rol Sucursal 3'),
   createData(4, 'Sucursal 4', 'Calle 4', 'Comuna 4', 'Ciudad 4', 'Rol Sucursal 4'),
   createData(5, 'Sucursal 5', 'Calle 5', 'Comuna 5', 'Ciudad 5', 'Rol Sucursal 5'),
   createData(6, 'Sucursal 6', 'Calle 6', 'Comuna 6', 'Ciudad 6', 'Rol Sucursal 6'),
   createData(7, 'Sucursal 7', 'Calle 7', 'Comuna 7', 'Ciudad 7', 'Rol Sucursal 7'),
   createData(8, 'Sucursal 8', 'Calle 8', 'Comuna 8', 'Ciudad 8', 'Rol Sucursal 8'),
   createData(9, 'Sucursal 9', 'Calle 9', 'Comuna 9', 'Ciudad 9', 'Rol Sucursal 9'),
   createData(10, 'Sucursal 10', 'Calle 10', 'Comuna 10', 'Ciudad 10', 'Rol Sucursal 10'),
]


export const BranchOffice:React.FC<Props> = (props: Props): JSX.Element => {
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   // const [value, setValue] = useState('');
   const [value, setValue] = useState<Date | null>(new Date());
   const [loading, setLoading] = useState(false);
   const [pais, setPais] = useState('');
   const [estado, setEstado]= useState('');
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);
   const [open, setOpen] = useState(false);
   const [openRut, setOpenRut] = useState(false);
   const handleOpenRut = () => setOpenRut(true);
   const handleCloseRut = () => setOpenRut(false);

    const handleChangePais = (event: SelectChangeEvent) => {
      setPais(event.target.value);
    };
    const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
    };
   function handleClick() {
     setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const paises= [
      { value:1, label:'Chile'},
      { value:2, label:'Argentina'},
      { value:3, label:'Brasil'},
      { value:4, label:'Colombia'},
      { value:5, label:'Peru'},
      { value:6, label:'Venezuela'},
   ];
   const estados= [
      { value: '1', label: 'Activo' },
      { value: '2', label: 'Bloqueo Comercial' },
   ]

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 720,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

    const motivosBloqueos = [
      { value: '1', label: 'Cliente Activo' },
      { value: '2', label: 'Cliente con Deuda' },
      { value: '3', label: 'Cliente Inactivo' },
      { value: '4', label: 'Cliente Quiebra' },
      { value: '5', label: 'Morosos' },
      { value: '6', label: 'Problematicos' },
    ]
    return (
        <Protected>
        <Container maxWidth='xl'>
           <Grid container spacing={3}>
               <Grid item xs={12} md={12} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        MANTENEDOR DE SUCURSALES
                     </Typography>
                  </Box>
                  <Box>
                     <Typography variant='subtitle2'  gutterBottom >
                        Incorpóre Sucursales en el siguiente Formulario.
                     </Typography>
                  </Box>
                  
               </Grid>
                     
               {/* <Grid item xs={12} md={6} className="btn-download">
                  <Box pb={2}>
                  <LoadingButton
                     color="warning"
                     // onClick={handleOpenModal}
                     // loading={loading}
                     // loadingPosition="start"
                     // startIcon={<FileDownloadIcon />}
                     variant="contained"
                     className="btn-download2"
                     >
                     IMPRIMIR
                     </LoadingButton>
                  </Box>
               </Grid> */}
                     
               <Grid item xs={12} sm={3} >
                  <TextField
                     label="Nombre"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
                  {/* <IconButton>
                     <SearchIcon 
                        onClick={handleOpenRut}
                     />
                  </IconButton> */}
               </Grid>

               <Grid item xs={12} sm={3} >
                  <TextField
                     label="RUT Dueño"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={6} >
                  <TextField
                     label="Direccion"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={3} >
                  <TextField
                     label="Ciudad"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>


               <Grid item xs={12} sm={3} >
                  <TextField
                     label="Comuna"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={3} >
                  <TextField
                     label="Rol Sucursal"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={3} >
                  <TextField
                     label="Telefono"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
                  <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={motivosBloqueos}
                           renderInput={(params) => <TextField {...params} label="Motivo Bloqueo" />}
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
                  <FormControl>
                     <FormControlLabel value="0" control={<Radio />} label="Mostrar en impresion por api rest" />
                  </FormControl>
               </Grid>

               <Grid item xs={12} sm={3}>
                  <FormControl>
                     <FormControlLabel value="0" control={<Radio />} label="Casa Matriz" />
                  </FormControl>
               </Grid>

               <Grid item container
                     xs={12}
                     // mt={1}
                  justifyContent='right'
                  >
                        <Grid item>
                           <Button
                              type='submit'
                              variant='contained'
                              // onClick={handleSubmit}
                              // disabled={loading}
                              disableElevation
                              className='btn_add'
                           >
                                    <span>Agregar</span>
                           </Button>
                        </Grid>
               </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={2}>
               LISTADO DE SUCURSALES
            </Typography>

            <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:440}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {columns.map((column) => (
                              <TableCell
                              
                                 key={column.id}
                                 align={column.align}
                                 style={{ minWidth: column.minWidth }}
                              >
                                 {column.label}
                              </TableCell>
                           ))}
                           <TableCell
                           >
                              Opciones
                           </TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                       <IconButton 
                                          >
                                             <EditIcon 
                                          // onClick={handleOpen}
                                       />
                                       </IconButton>
                                       <IconButton>
                                          <DeleteIcon/>
                                       </IconButton>
                                 </TableCell>
                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
            </Paper>

         </Container>
            {/* <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <DataModal entity={undefined}/>
                  </Box>
            </Modal>

            <Modal
                  open={openRut}
                  onClose={handleCloseRut}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <DataRut entity={undefined}/>
                  </Box>
            </Modal> */}
     </Protected>
    );
   };

