import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, InputAdornment, FormControl, FormControlLabel, Checkbox, Tooltip, TableFooter, } from '@mui/material'
import { useState, useRef } from "react";
import { Props } from "../../CentralizeWorker.type";
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import NumbersIcon from '@mui/icons-material/Numbers';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import '../../CentralizeWorker_style.css';
import { makeStyles } from "@mui/styles";

interface Column {
   id: 'num' | 'cuenta' | 'nombre' | 'debe' | 'haber';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: 'num', label: 'num', minWidth: 100 },
   { id: 'cuenta', label: 'cuenta', minWidth: 100 },
   { id: 'nombre', label: 'nombre', minWidth: 100 },
   { id: 'debe', label: 'debe', minWidth: 100 },
   { id: 'haber', label: 'haber', minWidth: 100 }
];

interface Data {
   num: string;
   cuenta: string;
   nombre: string;
   debe: string;
   haber: string;
}

function createData(
   num: string,
   cuenta: string,
   nombre: string,
   debe: string,
   haber: string,
): Data {
   return { num, cuenta, nombre, debe, haber };
}

const rowsDataHistorico = [
   createData('1', '31501', 'SUELDO BASE', '1700000', '0'),
   createData('2', '31501', 'SUELDO BASE', '1700000', '0'),
   createData('3', '31501', 'SEGURO CESANTIA APORTE EMPLEADOR POR PAGAR', '1700000', '0'),
   createData('4', '31501', 'SUELDO BASE', '800000', '20282'),
   createData('5', '31501', 'ANTICIPO DE REMUNERACIONES', '1700000', '0'),
   createData('6', '31501', 'SUELDO BASE', '1700', '2282'),
   createData('7', '31501', 'SEG CESANTIA TRABAJADOR X PAGAR', '1700000', '0'),
   createData('8', '31501', 'SUELDO BASE', '1700000', '0'),
]

const useStyles = makeStyles({
   root: {
      width: '100%',
   },
   container: {
      maxHeight: 320
   },
});

export const DataModalCentralize: React.FC<Props> = (props: Props): JSX.Element => {
   const classes = useStyles();
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [ejecutivoComercial, setEjecutivoComercial] = useState('');
   const inputRefs = useRef<Array<InputRef | null>>([]);

   const estado = [
      { label: 'NO ADJUDICADA' },
      { label: 'ANULADA' },
      { label: 'ADJUDICADA' }
   ]
   const motivo = [
      { label: 'NO COMPRA' }
   ]
   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   return (
      <Container maxWidth='xl'>
         <Grid container>
            <Typography variant="h6" gutterBottom mb={1} className="tittle">
               CENTRALIZAR POR TRABAJADOR
            </Typography>

            <Grid item container xs={12} >
               <Typography variant="subtitle1" >
                  Generando asiento - FERNANDO - PERIODO: 2022 - MES: 1
               </Typography>
            </Grid>


         </Grid>

         <Grid container>
            <Grid xs={12}>
               <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={2}>
                  Informes
               </Typography>
            </Grid>

            <Paper sx={{ overflow: 'hidden' }} className={classes.root}>
               <TableContainer className={classes.container} component={Paper}>
                  <Table size="small" aria-label="simple table" stickyHeader>
                     <TableHead>
                        <TableRow>
                           <TableCell>N</TableCell>
                           <TableCell>Cuenta</TableCell>
                           <TableCell>Nombre Cuenta</TableCell>
                           <TableCell>DEBE</TableCell>
                           <TableCell>HABER</TableCell>                           
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsDataHistorico.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.num}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}                                
                              </TableRow>
                           );
                        })}
                     </TableBody>
                     <TableFooter>
                        <TableRow>
                           <TableCell align="right" colSpan={3}>TOTAL</TableCell>
                           <TableCell>2759408</TableCell>
                           <TableCell>2759408</TableCell>
                        </TableRow>

                     </TableFooter>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsDataHistorico.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>

         </Grid>
         <Grid item container
            xs={12}
            justifyContent='right'
            mt={2}
         >
            <Grid item>
               <Button
                  // type='submit'
                  variant='contained'
                  // onClick={handleSiguiente}
                  disabled={loading}
                  disableElevation
                  className='btn_save'
               >
                  <span>Actualizar</span>
               </Button>

            </Grid>
         </Grid>
      </Container >
   );
};

