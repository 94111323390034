import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "./AbsencesTardies.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './AbsencesTardies_styles.css'
import TextField from '@mui/material/TextField';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import { ModalAssistance } from "./Modal/ModalAssistance";
import Modal from '@mui/material/Modal';
import { ModalTardies } from "./Modal/ModalTardies";
import { ModalMovePersonal } from "./Modal/ModalMovePersonal";

interface Column {
   id: 'rut' | 'apellidoPaterno' | 'apellidoMaterno'|'nombre';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

const columns:readonly Column[] = [
   { id: 'rut', label: 'Rut', minWidth: 150 },
   { id: 'apellidoPaterno', label: 'Apellido Paterno', minWidth: 150 },
   { id: 'apellidoMaterno', label: 'Apellido Materno', minWidth: 150 },
   { id: 'nombre', label: 'Nombre', minWidth: 150 },
   ];

interface Data {
   rut: string;
   apellidoPaterno: string;
   apellidoMaterno: string;
   nombre: string;
}

function createDataAbsences(
   rut: string,
   apellidoPaterno: string,
   apellidoMaterno: string,
   nombre: string,
   ):Data {
   return {rut, apellidoPaterno, apellidoMaterno, nombre };
}

const rowsDataAbsences = [
   createDataAbsences('12.345.678-9', 'Perez', 'Perez', 'Juan'),
   createDataAbsences('12.345.678-9', 'Gomez', 'Disney', 'Pether'),
   createDataAbsences('12.345.678-9', 'Pancracio', 'Tijuane', 'Jhon'),
   createDataAbsences('12.345.678-9', 'Clavo', 'Olor', 'Quaquer'),
   createDataAbsences('12.345.678-9', 'Canela', 'Ajinomoto', 'Hector'),
   
]


export const AbsencesTardies:React.FC<Props> = (props: Props): JSX.Element => {
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [familyProduct, setFamilyProduct] = useState('');
   const [estado, setEstado]= useState('');

   const [openModal, setOpenModal] = useState(false);
   const [openModalTardies, setOpenModalTardies] = useState(false);
   const [openMove, setOpenMove] = useState(false);


   const handleOpenModal = () => setOpenModal(true);
   const handleCloseModal = () => setOpenModal(false);

   const handleOpenModalTardies = () => setOpenModalTardies(true);
   const handleCloseModalTardies = () => setOpenModalTardies(false);

   const handleOpenMove = () => setOpenMove(true);
   const handleCloseMove = () => setOpenMove(false);



   function handleClick() {
     setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const estados= [
      { value: '1', label: 'Activo' },
      { value: '2', label: 'Inactivo' },
   ]

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 920,
      height:650,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };
    return (
        <Protected>
        <Container maxWidth='xl'>
           <Grid container spacing={3} mb={3}>
               <Grid item xs={12} md={12} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        INASISTENCIAS Y ATRASOS
                     </Typography>
                  </Box>
                  <Typography  variant="caption" display="block" gutterBottom mt={1}>
                        Trabajadores Ingresados
                  </Typography>
               </Grid>

               <Grid item xs={12} sm={3}>
                  <TextField
                     label="Busqueda Avanzada ( Rut o Nombre):	"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                     value='22222222-2'
                  />
               </Grid>
             
               <Grid item xs={12} md={3}>
                    <Button
                        type='submit'
                        variant='contained'
                        // onClick={handleSubmit}
                        // disabled={loading}
                        disableElevation
                        className='btn_next'
                        style={{backgroundColor:'#ff9700', color:'#fff'}}
                        >
                        <span>BUSCAR</span>
                    </Button>
                </Grid>

            </Grid>
          
            <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:400}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                                 {column.label}
                              </TableCell>
                           ))}
                           <TableCell>
                              Acciones
                           </TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsDataAbsences.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.rut}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell style={{padding:0}}>
                                    <Button onClick={handleOpenModal} className="btn_ico" variant="outlined" startIcon={<AssignmentIcon />}>
                                    Inasistencia
                                    </Button>
                                 </TableCell>
                                  
                                  <TableCell>
                                    <Button  onClick={handleOpenModalTardies} className="btn_ico" variant="outlined" startIcon={<HourglassFullIcon />}>
                                    Atraso
                                    </Button>
                                 </TableCell>

                                 <TableCell>
                                    <Button onClick={handleOpenMove} className="btn_ico" variant="outlined" startIcon={<TimelapseIcon />}>
                                    Movimiento
                                    </Button>
                                 </TableCell>

                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsDataAbsences.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
            </Paper>
         </Container>
         <Modal
                  open={openModal}
                  onClose={handleCloseModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <ModalAssistance entity={undefined}/>
                  </Box>
      </Modal>
      <Modal
                  open={openModalTardies}
                  onClose={handleCloseModalTardies}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <ModalTardies entity={undefined}/>
                  </Box>
      </Modal>
      <Modal
                  open={openMove}
                  onClose={handleCloseMove}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <ModalMovePersonal entity={undefined}/>
                  </Box>
      </Modal>
     </Protected>
    );
   };

