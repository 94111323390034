import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, } from '@mui/material';
import { Props } from './ContactBusiness.type'
import { InputRef } from '@components/common/Input/InputInterfaces';
import './style.sass'
import "./ContactBusiness_style.css";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Protected } from '@/components/layout/Protected';
import Paper from '@mui/material/Paper';

export const ContactBusiness: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false)
   const [isDisabled, setIsDisabled] = useState<boolean>(false);


   return (
      <Container maxWidth="lg">
         <Container maxWidth="xl">
         {/* <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="left" mt={2}>
               LISTADO DE CONTACTOS INGRESADOS
         </Typography> */}
         <p  className="tittle3">
            LISTADO DE CONTACTOS INGRESADOS
         </p>
         <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:440}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           <TableCell>N</TableCell>
                           <TableCell>Nombre Contacto</TableCell>
                           <TableCell>Cargo</TableCell>
                           <TableCell>Email</TableCell>
                           <TableCell>Telefono</TableCell>
                           <TableCell>Pago</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {/* {rowsDataProviders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return ( */}
                              <TableRow hover role="checkbox" tabIndex={-1}>
                                 {/* {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })} */}
                                 
                              </TableRow>
                           {/* ); */}
                        {/* } */}
                        {/* )} */}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
              
            </Paper>
         </Container>
         <Grid item container
                  xs={12}
                 justifyContent='right' mt={3}
                 >
                     <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        // onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                        className='btn_save2'
                        
                        // endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>Agregar</span>
                        }
                     </Button>

                     </Grid>
         </Grid>
      </Container>
      
   );
}
