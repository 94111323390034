import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, InputAdornment, FormControl, FormControlLabel, Checkbox, Stack, Tooltip, } from '@mui/material'
import React, { useState, useRef } from "react";
import { Props } from "../Workers.type"
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TableBody from '@mui/material/TableBody';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TableRow from '@mui/material/TableRow';
import '../Workers.css'
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';

interface Column {
   id: 'estado' | 'fechaInicio' | 'fechaTermino' | 'causal';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: 'estado', label: 'Estado', minWidth: 100 },
   { id: 'fechaInicio', label: 'Inicio', minWidth: 100 },
   { id: 'fechaTermino', label: 'Termino', minWidth: 100 },
   { id: 'causal', label: 'Causal', minWidth: 100 }
];

interface Data {
   estado: string;
   fechaInicio: string;
   fechaTermino: string;
   causal: string;
}

function createDataContacto(
   estado: string,
   fechaInicio: string,
   fechaTermino: string,
   causal: string,
): Data {
   return { estado, fechaInicio, fechaTermino, causal };
}

const rowsListado = [
   createDataContacto('Vigente/Activo', '13-02-2022', '15-05-2022', 'Causa N 1 abandono'),
   createDataContacto('Vigente/Activo', '13-02-2022', '15-05-2022', 'Causa N 1 abandono'),
   createDataContacto('Vigente/Activo', '13-02-2022', '15-05-2022', 'Causa N 1 abandono'), 
   createDataContacto('Vigente/Activo', '13-02-2022', '15-05-2022', 'Causa N 1 abandono'),
   
]

export const DataModalWork: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [ejecutivoComercial, setEjecutivoComercial] = useState('');
   const [estado, setEstado] = useState('');
   // const [data, setData] = React.useState<Date | null>(new Date());
   const [data, setData] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const inputRefs = useRef<Array<InputRef | null>>([]);


   const handleChangeEjecutivoComercial = (event: SelectChangeEvent) => {
      setEjecutivoComercial(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   // const estadoValue = [
   //    { label: 'ACTIVADO', value: 'activado' },
   //    { label: 'DESACTIVADO', value: 'desactivado' }
   // ]
   const estadoValue = [
      { label: 'Vigente/activo' },
      { label: 'Finiquitado/Cesado' }
   ]
   const sexoValue = [
      { label: 'Masculino' },
      { label: 'Femenino' }
   ]
   const tipoValue = [
      { label: 'Indefinido' },
      { label: 'Plazo fijo' }
   ]
   const causalValue = [
      { label: 'ART. 160 N 2: Negociaciones prohibidas por escrito' },
      { label: 'ART. 160 N 3: No concurrencia a las labores' },
      { label: 'ART. 160 N 4: Negociaciones prohibidas por escrito' },
      { label: 'ART. 160 N 5: Abandono del trabajo' },
      { label: 'ART. 160 N 6: Negociaciones prohibidas por escrito' }
   ]

   return (
      <Container maxWidth='xl'>
         <Grid container spacing={3} mt={2} >
            <Grid container mb={2}>
               <Grid item xs={10}>
                  <Typography variant="h6" id="modal-modal-title" className='tittle'>
                     Mantenedor de Historial Laboral
                  </Typography>
                  <Typography variant="caption" id="modal-modal-title">
                  Incorpore Historial Laboral del trabajador
                  </Typography>
               </Grid>
               <Grid item xs={2} textAlign="end">
                  <AssignmentIcon style={{ color: '#FF9700' }} />
               </Grid>
            </Grid>
            <Grid container spacing={3} className={`e-mb-lg`}>
               <Grid item xs={12} sm={4}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} color="primary" focused>
                     <Stack>
                        <DatePicker
                           openTo="year"
                           // views={['year', 'month', 'day']}
                           label="Fecha de Inicio"
                           value={data}
                           onChange={(newValue) => {
                              setData(newValue);
                           }}
                           renderInput={(params) => <TextField {...params} helperText={null} />}
                        />
                     </Stack>
                  </LocalizationProvider>
               </Grid>
               <Grid item xs={12} sm={4}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} color="primary" focused>
                     <Stack>
                        <DatePicker
                           openTo="year"
                           // views={['year', 'month', 'day']}
                           label="Fecha de Término"
                           value={data}
                           onChange={(newValue) => {
                              setData(newValue);
                           }}
                           renderInput={(params) => <TextField {...params} helperText={null} />}
                        />
                     </Stack>
                  </LocalizationProvider>
               </Grid>
               <Grid item xs={12} sm={4}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={estadoValue}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Estado Contrato" />}
                  />
               </Grid>
               <Grid item xs={12} sm={4}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={tipoValue}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Tipo Contrato" />}
                  />
               </Grid>
               <Grid item xs={8}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={causalValue}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Causal Último Finiquito" />}
                  />
               </Grid>
            </Grid>
            <Grid container mt={2}>
               <Grid item xs={12} textAlign="center">
                  <button className='btn_add'>
                     Agregar
                  </button>
               </Grid>

            </Grid>
            <Grid xs={12}>
               <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={2}>
                  LISTADO DE HISTORIAL LABORAL
               </Typography>
            </Grid>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
               <TableContainer sx={{ maxHeight: 185 }}>
                  <Table stickyHeader aria-label="sticky table" size="small">
                     <TableHead>
                        <TableRow>
                           <TableCell>Estado</TableCell>
                           <TableCell>Fecha Inicio</TableCell>
                           <TableCell>Fecha Término</TableCell>
                           <TableCell>Causal</TableCell>
                           <TableCell>Opciones</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsListado.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.estado}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                    <Tooltip title={"Modificar"}>
                                       <IconButton
                                       >
                                          <EditIcon />
                                       </IconButton>
                                    </Tooltip>

                                 </TableCell>
                              </TableRow>
                           );
                        })}

                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsListado.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>
         </Grid>
      </Container >
   );
};

