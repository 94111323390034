import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { ArrowRightIcon } from "@/toolbox/constants/icons";
import { VALIDATORS } from "@/toolbox/helpers/validation-rules";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, TablePagination, TableFooter, } from '@mui/material';
import { Input } from '@components/common/Input';
import { Props } from "./QuotationWorkers.type";
import BusinessIcon from '@mui/icons-material/Business';
import { InputRef } from '@/components/common/Input/InputInterfaces';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import AssignmentIcon from '@mui/icons-material/Assignment';
import './QuotationWorkers_style.css';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SearchIcon from '@mui/icons-material/Search';

import AbcIcon from '@mui/icons-material/Abc';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EMobiledataIcon from '@mui/icons-material/EMobiledata';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { Link } from 'react-router-dom';
import { ROUTE_EV_SALES_DATES, ROUTE_GC_FAMILIES, ROUTE_GC_PRICE_LIST, ROUTE_RM_QUOTATION_SHEET } from '@/toolbox/constants/route-map';

// Para la tabla de la vista principal
interface Column {
   id: 'rut' | 'nombre' | 'hi' | 'hni' | 'afp' | 'salud' | 'familiar' | 'sis' | 'cesantia';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columnsPrincipal: readonly Column[] = [
   { id: 'rut', label: 'Rut', minWidth: 100 },
   { id: 'nombre', label: 'Nombre', minWidth: 100 },
   { id: 'hi', label: 'Hi', minWidth: 100 },
   { id: 'hni', label: 'Hni', minWidth: 100 },
   { id: 'afp', label: 'Afp', minWidth: 100 },
   { id: 'salud', label: 'Salud', minWidth: 100 },
   { id: 'familiar', label: 'Familiar', minWidth: 100 },
   { id: 'sis', label: 'Sis', minWidth: 100 },
   { id: 'cesantia', label: 'Cesantia', minWidth: 100 },
];

interface Data {
   rut: string;
   nombre: string;
   hi: string;
   hni: string;
   afp: string;
   salud: string;
   familiar: string;
   sis: string;
   cesantia: string;
}
function createData(
   rut: string,
   nombre: string,
   hi: string,
   hni: string,
   afp: string,
   salud: string,
   familiar: string,
   sis: string,
   cesantia: string,
) {
   return { rut, nombre, hi, hni, afp, salud, familiar, sis, cesantia };
}

const rowsListado = [
   createData('15956742-7', 'DEBORA ADRIANA ORMAZABAL QUEZADA', '$ 281.250', '$ 0', '$ 32.175', '$ 78.032', '$', '$$ 4.303', '$ 8.440'),
   createData('15956742-7', 'FERNANDO ALARCON GONZALEZ', '$ 281.250', '$ 0', '$ 32.175', '$ 78.032', '$', '$$ 4.303', '$ 8.440'),
   createData('15956742-7', '	LUIS ALFREDO ARGOMEDO GONZALEZ', '$ 281.250', '$ 0', '$ 32.175', '$ 78.032', '$', '$$ 4.303', '$ 8.440'),
   createData('15956742-7', '	PEDRO MUÑOZ C', '$ 281.250', '$ 0', '$ 32.175', '$ 78.032', '$', '$$ 4.303', '$ 8.440'),
]

export const QuotationWorkers: React.FC<Props> = (props: Props): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);


   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 750,
      // height: 600,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const rules = useMemo(() => ({
      rut_proveedor: [
         VALIDATORS.REQUIRED,
      ],
      numero_contrato: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      titulo: [
         VALIDATORS.REQUIRED
      ],
      descripcion: [
         VALIDATORS.REQUIRED,
      ]

   }), []);
   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'rut_proveedot':
            props.setvalueContrato(prev => ({ ...prev, rut_proveedot: value }));
            break;
         case 'numero_contrato':
            props.setvalueContrato(prev => ({ ...prev, numero_contrato: value }))
            break;
         case 'titulo':
            props.setvalueContrato(prev => ({ ...prev, titulo: value }))
            break;
         case 'descripcion':
            props.setvalueContrato(prev => ({ ...prev, descripcion: value }));
            break;

         default:
            break;
      }
   };


   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }
   return (
      <Protected>
         <Container maxWidth='xl'>

            <Grid container mb={4}>

               <Grid item xs={12} sm={10}>
                  <Typography variant="h6" id="modal-modal-title" mb={2} className='tittle'>
                     PLANILLA DE COTIZACIONES POR TRABAJADORES
                  </Typography>
                  <Typography variant="subtitle2">
                     Periodo 2022 mes 1
                  </Typography>
               </Grid>
               <Grid item xs={12} sm={2} textAlign="end">
                  <Button variant="outlined" component={Link} to={ROUTE_RM_QUOTATION_SHEET} startIcon={<ArrowBackIosIcon />}>
                     Volver
                  </Button>
               </Grid>
               <Grid item xs={12} mb={2}>
                  <Typography variant="subtitle2">
                     Rut	: 22222222-2
                  </Typography>
                  <Typography variant="subtitle2">
                     Razon Social	: DEMO ERP SOFTNET
                  </Typography>
                  <Typography variant="subtitle2">
                     Direccion	: YUNGAY 1942 - VALPARAISO
                  </Typography>
               </Grid>

            </Grid>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
               <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                     <TableHead>
                        <TableRow>
                           <TableCell>RUT</TableCell>
                           <TableCell>NOMBRE</TableCell>
                           <TableCell>TOTAL H.I</TableCell>
                           <TableCell>TOTAL H.N.I</TableCell>
                           <TableCell>COT. AFP</TableCell>
                           <TableCell>COT. SALUD</TableCell>
                           <TableCell>A. FAMILIAR</TableCell>
                           <TableCell>SIS</TableCell>
                           <TableCell>SEGURO SESANTIA</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsListado.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.rut}>
                                 {columnsPrincipal.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}

                              </TableRow>
                           );
                        })}
                     </TableBody>
                     <TableFooter>
                        <TableRow>
                           <TableCell align="right" colSpan={2}>TOTALES</TableCell>
                           <TableCell >$ 4.562.945</TableCell>
                           <TableCell >$ 28.732</TableCell>
                           <TableCell >$ 511.056</TableCell>
                           <TableCell >$ 486.271</TableCell>
                           <TableCell >$ 28.732</TableCell>
                           <TableCell >$ 31.790</TableCell>
                           <TableCell >$ 102.390</TableCell>
                        </TableRow>
                     </TableFooter>
                  </Table>
               </TableContainer>               
            </Paper>
         </Container>
      </Protected>
   );
}