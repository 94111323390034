import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { ArrowRightIcon } from "@/toolbox/constants/icons";
import { VALIDATORS } from "@/toolbox/helpers/validation-rules";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, TablePagination, TableFooter, } from '@mui/material';
import { Input } from '@components/common/Input';
import { Props } from "./MonthAndPeriodDay.type";
import BusinessIcon from '@mui/icons-material/Business';
import { InputRef } from '@/components/common/Input/InputInterfaces';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import AssignmentIcon from '@mui/icons-material/Assignment';
import './MonthAndPeriodDay_style.css';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SearchIcon from '@mui/icons-material/Search';

import AbcIcon from '@mui/icons-material/Abc';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EMobiledataIcon from '@mui/icons-material/EMobiledata';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { Link } from 'react-router-dom';
import { ROUTE_EV_SALES_DATES, ROUTE_GC_FAMILIES, ROUTE_GC_PRICE_LIST } from '@/toolbox/constants/route-map';

// Para la tabla de la vista principal
interface Column {
   id: 'dia' | 'diaMes' | 'ventas' | 'clientes' | 'montoNeto' | 'budget' | 'venta' | 'montoBruto';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: 'dia', label: 'Codigo', minWidth: 100 },
   { id: 'diaMes', label: 'Fecha', minWidth: 100 },
   { id: 'ventas', label: 'Tipo', minWidth: 100 },
   { id: 'clientes', label: 'Folio', minWidth: 100 },
   { id: 'montoNeto', label: 'Re', minWidth: 100 },
   { id: 'budget', label: 'Rut', minWidth: 100 },
   { id: 'venta', label: 'Razón Social', minWidth: 100 },
   { id: 'montoBruto', label: 'Total', minWidth: 100 },
];

interface Data {
   dia: string;
   diaMes: string;
   ventas: string;
   clientes: string;
   montoNeto: string;
   budget: string;
   venta: string;
   montoBruto: string;
}
function createData(
   dia: string,
   diaMes: string,
   ventas: string,
   clientes: string,
   montoNeto: string,
   budget: string,
   venta: string,
   montoBruto: string,
): Data {
   return { dia, diaMes, ventas, clientes, montoNeto, budget, venta, montoBruto };
}

const rowsListado = [
   createData('1', '2022-03-01', '26', '8', '$391.368.266', 'Sin Budget %', '391.368.266', '405.777.537'),
   createData('2', '2021-03-01', '1', '1', '$91.368.266', 'Sin Budget %', '391.368.266', '405.896.537'),
   createData('3', '2022-06-01', '4', '3', '$100.368', 'Sin Budget %', '391.368.266', '405.903.677'),
   createData('4', '2022-04-01', '39', '5', '$6.368.266', 'Sin Budget %', '391.368.266', '405.777.537'),
   createData('5', '2022-02-01', '1', '1', '$1.368.000', 'Sin Budget %', '391.368.266', '405.896.537'),
   createData('6', '2022-03-01', '26', '8', '$391.368.266', 'Sin Budget %', '391.368.266', '405.777.537'),
   createData('7', '2021-03-01', '1', '1', '$91.368.266', 'Sin Budget %', '391.368.266', '405.896.537'),
   createData('8', '2022-06-01', '4', '3', '$100.368', 'Sin Budget %', '391.368.266', '405.903.677'),
   createData('9', '2022-04-01', '39', '5', '$6.368.266', 'Sin Budget %', '391.368.266', '405.777.537'),
   createData('10', '2022-02-01', '1', '1', '$1.368.000', 'Sin Budget %', '391.368.266', '405.896.537'),
]

export const MonthAndPeriodDay: React.FC<Props> = (props: Props): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);


   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 750,
      // height: 600,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const rules = useMemo(() => ({
      rut_proveedor: [
         VALIDATORS.REQUIRED,
      ],
      numero_contrato: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      titulo: [
         VALIDATORS.REQUIRED
      ],
      descripcion: [
         VALIDATORS.REQUIRED,
      ]

   }), []);
   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'rut_proveedot':
            props.setvalueContrato(prev => ({ ...prev, rut_proveedot: value }));
            break;
         case 'numero_contrato':
            props.setvalueContrato(prev => ({ ...prev, numero_contrato: value }))
            break;
         case 'titulo':
            props.setvalueContrato(prev => ({ ...prev, titulo: value }))
            break;
         case 'descripcion':
            props.setvalueContrato(prev => ({ ...prev, descripcion: value }));
            break;

         default:
            break;
      }
   };

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }
   return (
      <Protected>
         <Container maxWidth='xl'>
            <Grid container mb={4}>
               <Grid item xs={12} sm={6}>
                  <Typography variant="h6" id="modal-modal-title" mb={2} className='tittle'>
                     Ventas Consolidadas por Mes-Periodo- Dia
                  </Typography>
                  <Typography variant="subtitle2">
                     Resumen de mes: 03 Periodo: 2022 Sucursal: Todas
                  </Typography>
               </Grid>
               <Grid item xs={12} sm={4} textAlign="end">
                  <Button>
                     Exportar
                  </Button>
               </Grid>
               <Grid item xs={12} sm={2} textAlign="end">
                  <Button variant="outlined" component={Link} to={ROUTE_EV_SALES_DATES} startIcon={<ArrowBackIosIcon />}>
                     Volver
                  </Button>
               </Grid>
            </Grid>
            <Grid container spacing={3} className={`e-mb-lg`} mt={2}>
               <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  <TableContainer component={Paper}>
                     <Table aria-label="simple table">
                        <TableHead>
                           <TableRow>
                              <TableCell>Día</TableCell>
                              <TableCell>Día del Mes</TableCell>
                              <TableCell>N° Ventas</TableCell>
                              <TableCell>N° Clientes</TableCell>
                              <TableCell>Monto Neto</TableCell>
                              <TableCell>Budget</TableCell>
                              <TableCell>Venta</TableCell>
                              <TableCell>Monto Bruto</TableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {rowsListado.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                              return (
                                 <TableRow hover role="checkbox" tabIndex={-1} key={row.dia}>
                                    {columns.map((column) => {
                                       const value = row[column.id];
                                       return (
                                          <TableCell key={column.id} align={column.align}>
                                             {column.format && typeof value === 'number'
                                                ? column.format(value)
                                                : value}
                                          </TableCell>
                                       );
                                    })}
                                 </TableRow>
                              );
                           })}
                        </TableBody>
                     </Table>
                  </TableContainer>
                  <TablePagination
                     rowsPerPageOptions={[10, 25, 100]}
                     component="div"
                     count={rowsListado.length}
                     rowsPerPage={rowsPerPage}
                     page={page}
                     onPageChange={handleChangePage}
                     onRowsPerPageChange={handleChangeRowsPerPage}
                  />
               </Paper>
            </Grid>
         </Container>
      </Protected>
   );
}