import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import proveedor from './restaurante.json';
import dulceria from './dulceria.json';
import heladeria from './heladeria.json';
import './Provider_style.css';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import { Grid, Typography} from '@mui/material';
import { Link } from "react-router-dom";
import { ROUTE_RAPPI_PRODUCTS } from "@/toolbox/constants/route-map";

export default class CarouselProvider extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToScroll: 5,
      slidesToShow: 5
    };
    return (
      <Grid container sx={{ overflow: 'hidden' }}>
         <Grid container sx={{ alignItems: 'center' }}>
            <Grid item xs={12} md={3} mt={3}>
               <Typography variant="h2"  gutterBottom className="textTitle">Restaurantes</Typography>
            </Grid>
            <Grid item xs={12} md={9} mt={3} sx={{ alignItems: 'center' }}>
               <Slider {...settings}>
                  {proveedor.map((item, index) => (
                     <Grid container >
                        <Grid item component={Link} to={ROUTE_RAPPI_PRODUCTS} >
                           <Card className={"card-container"} >
                                 <CardMedia
                                    component="img"
                                    image={item.imagen}
                                    alt="categorias"
                                    // className='card-media1'
                                    style={{backgroundColor:'#ffc800'}}
                                    // sx={{padding:'25px'}}
                                    sx={{width:'50' ,margin:'auto'}}
                                 />
                                 <CardContent >
                                    <Typography variant="subtitle2" textAlign={'center'} >
                                       {item.name}
                                    </Typography>
                                 </CardContent>

                              </Card>
                           </Grid>
                        </Grid>
                  ))}
               </Slider>
            </Grid>
         </Grid>

         <Grid container sx={{ alignItems: 'center' }}>
            <Grid item xs={12} md={3} mt={3}>
               <Typography variant="h2"  gutterBottom className="textTitle">Heladería</Typography>
            </Grid>
            <Grid item xs={12} md={9} mt={3} sx={{ alignItems: 'center' }}>
               <Slider {...settings}>
                  {heladeria.map((item, index) => (
                     <Grid container>
                        <Grid item component={Link} to={ROUTE_RAPPI_PRODUCTS} >
                           <Card className={"card-container"} >
                                 <CardMedia
                                    component="img"
                                    image={item.imagen}
                                    alt="categorias"
                                    // className='card-media1'
                                    style={{backgroundColor:'#ffc800'}}
                                    // sx={{padding:'25px'}}
                                    sx={{width:'50' ,margin:'auto'}}
                                 />
                                 <CardContent >
                                    <Typography variant="subtitle2" textAlign={'center'} >
                                       {item.name}
                                    </Typography>
                                 </CardContent>

                              </Card>
                           </Grid>
                        </Grid>
                  ))}
               </Slider>
            </Grid>
         </Grid>

         <Grid container sx={{ alignItems: 'center' }}>
            <Grid item xs={12} md={3} mt={3}>
               <Typography variant="h2"  gutterBottom className="textTitle">Licorería</Typography>
            </Grid>
            <Grid item xs={12} md={9} mt={3} sx={{ alignItems: 'center' }}>
               <Slider {...settings}>
                  {dulceria.map((item, index) => (
                     <Grid container >
                        <Grid item component={Link} to={ROUTE_RAPPI_PRODUCTS} >
                           <Card className={"card-container"} >
                                 <CardMedia
                                    component="img"
                                    image={item.imagen}
                                    alt="categorias"
                                    // className='card-media1'
                                    style={{backgroundColor:'#ffc800'}}
                                    // sx={{padding:'25px'}}
                                    sx={{width:'50' ,margin:'auto'}}
                                 />
                                 <CardContent >
                                    <Typography variant="subtitle2" textAlign={'center'} >
                                       {item.name}
                                    </Typography>
                                 </CardContent>

                              </Card>
                           </Grid>
                        </Grid>
                  ))}
               </Slider>
            </Grid>
         </Grid>

      </Grid>
    );
  }
}
