import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, } from '@mui/material';
import {ArrowRightIcon } from "@toolbox/constants/icons";
import { Props } from './FormEntity.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import { Loading } from "@components/common/Loading";
// import { entityService } from '@/service/services/Entity.service';
// import { ubigeoService } from '@/service/services/Ubigeo.service';
import Entity from "@assets/img/entity.png";
import './style.sass'
import BadgeIcon from '@mui/icons-material/Badge';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import BusinessIcon from '@mui/icons-material/Business';
import FaxIcon from '@mui/icons-material/Fax';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import LanguageIcon from '@mui/icons-material/Language';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';

export const FormEntity: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false)
   const [typeRubro, setTypeRubro] = useState<any>([]);
   const [idpais, setIdPais] = useState(null);
   const [idregion, setIdRegion] = useState(null);
   const [idprovincia, setIdProvincia] = useState(null);
   const [idcomuna, setIdComuna] = useState(null);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [estado, setEstado] = useState<any>({ rut: false, nombre_rz: false })
   const [ubigeo, setUbigeo] = useState<any>({ pais: [], region: [], provincia: [], comuna: [] });
   console.log(props.valueEntity)

   const topActivos = [
      { label: 'Activo 1'},
      { label: 'Bloqueo Comercial'}
   ]
   const topGiros = [
      { label: 'Desarrollo de Software'},
      { label: 'Otro tipo de Giro'},
      { label: 'Otro tipo de Giro 2'}
   ]

   const rules = useMemo(() => ({
      rutempresa: [
         VALIDATORS.REQUIRED,
         VALIDATORS.ONLY_LOWERCASE,
      ],
      razonsocial: [
         VALIDATORS.REQUIRED,

      ],
      email: [
         VALIDATORS.EMAIL.NAME_PONTS,
         VALIDATORS.EMAIL.POINTS_IN_A_ROW,
         VALIDATORS.EMAIL.NAME_SIZE,
         VALIDATORS.EMAIL.VALID,
      ],
      nombre: [
         VALIDATORS.REQUIRED,
      ],
      user_nick: [
         VALIDATORS.REQUIRED,
         VALIDATORS.ONLY_LOWERCASE,
      ],
      administrador: [
         VALIDATORS.REQUIRED,
      ],
      password: [
         VALIDATORS.REQUIRED,
         VALIDATORS.PASSWORD.SIZE,
         VALIDATORS.PASSWORD.ALLOWED_CHARACTERS,
         VALIDATORS.PASSWORD.VALID,
      ],
      credenciales: [
         VALIDATORS.REQUIRED,
         VALIDATORS.ONLY_LOWERCASE,
      ]
   }), []);


   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'rut_empresa':
            props.setValueEntity(prev => ({ ...prev, rut_empresa: value }));
            break;
         case 'idtiporubro':
            props.setValueEntity(prev => ({ ...prev, idtiporubro: value }))
            break;
         case 'telefono':
            props.setValueEntity(prev => ({ ...prev, telefono: value }))
            break;
         case 'propietario':
            props.setValueEntity(prev => ({ ...prev, propietario: value }));
            break;
         case 'nombre_rz':
            props.setValueEntity(prev => ({ ...prev, nombre_rz: value }));
            break;
         case 'nombre_fantasia':
            props.setValueEntity(prev => ({ ...prev, nombre_fantasia: value }));
            break;
         case 'giro':
            props.setValueEntity(prev => ({ ...prev, giro: value }));
            break;
         case 'fax':
            props.setValueEntity(prev => ({ ...prev, fax: value }));
            break;
         case 'prospecto':
            props.setValueEntity(prev => ({ ...prev, prospecto: value }));
            break;
         case 'observacion':
            props.setValueEntity(prev => ({ ...prev, observacion: value }));
            break;
         case 'extranjero':
            props.setValueEntity(prev => ({ ...prev, extranjero: value }));
            break;
         case 'web':
            props.setValueEntity(prev => ({ ...prev, web: value }));
            break;
         case 'direccion':
            props.setValueEntity(prev => ({ ...prev, direccion: value }));
            break;
         default:
            break;
      }
   };
   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      props.setValueEntity(prev => ({ ...prev, [name]: newVal }));
   }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      props.handleNext();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }

   return (
      <Container maxWidth="xl">
         {/* <form onSubmit={handleSubmit}> */}
         <form >
            <Grid container spacing={3} className={`e-mb-lg`}>
               <Grid item xs={12}></Grid>
               <Grid item xs={4}>
                  <Input
                    className='Input'
                    prependInnerAdornment={
                     <InputAdornment position="end">
                     <BadgeIcon />
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="rut_empresa"
                     type='text'
                     placeholder="Rut Empresa"
                     //value={data.rut_empresa}
                     value={props.valueEntity.rut_empresa}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.rutempresa}
                     disableElevation
                     validateOnBlur
                     dense
                     height={35}
                     disabled={isDisabled}
                  />
               </Grid>      
               <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[2] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <BusinessIcon />
                    </InputAdornment>
                    }
                     height={35}
                     name="nombre_rz"
                     placeholder="Razon Social"
                     //value={data.nombre_rz}
                     value={props.valueEntity.nombre_rz}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.razonsocial}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid>
               <Grid item xs={12} md={4} sx={{display:'flex',marginTop:'-5px' }}>
                  <FormControlLabel
                     value="top"
                     control={<Checkbox />}
                     label="Cliente"
                  />
                  <FormControlLabel
                     value="start"
                     control={<Checkbox />}
                     label="Proveedor"
                  />
                  <FormControlLabel
                     value="bottom"
                     control={<Checkbox />}
                     label="Cesionario"
                  />
               </Grid>
               <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[3] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <BusinessCenterIcon />
                    </InputAdornment>
                    }
                     className='Input'
                     height={35}
                     name="nombre_fantasia"
                     placeholder="Nombre Fantasia"
                     //value={data.nombre_fantasia}
                     value={props.valueEntity.nombre_fantasia}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.razonsocial}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid>
               {/* <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[4] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <CorporateFareIcon />
                    </InputAdornment>
                    }
                     height={35}
                     name="giro"
                     placeholder="Giro"
                     //value={data.giro}
                     value={props.valueEntity.giro}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.razonsocial}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid> */}
               <Grid item xs={4}>
                  <Autocomplete
                     id="free-solo-demo"
                     freeSolo
                     options={topGiros}
                     sx={{background:'#f3f3f3'}}
                     size='small'
                     renderInput={(params) => <TextField {...params} label="Giro" />}
                  />
               </Grid>
               <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[10] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <LocalPhoneIcon />
                    </InputAdornment>
                    }
                    className='input'
                     height={35}
                     name="telefono"
                     placeholder="Telefono Empresa"
                     //value={data.telefono}
                     value={props.valueEntity.telefono}
                     onChange={handleInput}
                     onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.nombre}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid>
               <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[7] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <SpeakerNotesIcon />
                    </InputAdornment>
                    }
                     height={35}
                     name="observacion"
                     placeholder="Observacion"
                     //value={data.observacion}
                     value={props.valueEntity.observacion}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.razonsocial}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid>
               <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[6] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <FaxIcon />
                    </InputAdornment>
                    }
                     height={35}
                     name="fax"
                     placeholder="Fax"
                     //value={data.fax}
                     value={props.valueEntity.fax}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.razonsocial}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid>
               <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[6] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">   
                     <EmailIcon />
                    </InputAdornment>
                    }
                     height={35}
                     name="email"
                     placeholder="Email"
                     //value={data.fax}
                     // value={props.valueEntity.email}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.email}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid>
               <Grid item xs={12} md={4}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={topActivos}
                     sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Activo" />}
                  />
               </Grid>
               <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[8] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <LanguageIcon />
                    </InputAdornment>
                    }
                     height={35}
                     name="web"
                     placeholder="Web"
                     //value={data.web}
                     value={props.valueEntity.web}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.razonsocial}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid>
               
              
               <Grid item container
                  xs={12}
                 justifyContent='right'
                 >
                     <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                        endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>Siguiente</span>
                        }
                     </Button>

                     </Grid>
               </Grid>
            </Grid>
         </form>
         <Modal
            open={openModal}
            //onClose={() => { setOpenModal(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={{
               position: 'absolute',
               top: '50%',
               left: '50%',
               transform: 'translate(-50%, -70%)',
               width: 300,
               bgcolor: '#ffffff',
               boxShadow: 24,
               p: 4,
            }}>
               <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
                  <Box
                     component="img"
                     textAlign="center"
                     sx={{
                        width: "100px",
                        height: "100px",
                     }}
                     src={Entity}
                  ></Box>
               </Grid>
               <Divider />
               <Typography id="modal-modal-description" textAlign='center' sx={{ mt: 2, mb: 2 }}>
                  {` ${estado.rut ? ' El Rut, ' : ''}${estado.nombre_rz ? 'Razon Social,' : ''} está registrado, Ingrese correctamente los datos de la empresa`}
               </Typography>
               <Divider />
               <Grid container item xs={12} sx={{ pt: 2 }}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center">
                  <Grid item>
                     <Button
                        fullWidth
                        variant='contained'
                        onClick={() => { setOpenModal(false) }}
                     >
                        Aceptar
                     </Button>
                  </Grid>
               </Grid>
            </Box>
         </Modal>
      </Container>
   );
}
