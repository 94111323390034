import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, } from '@mui/material';
import {  ArrowRightIcon } from "@toolbox/constants/icons";
import { Props } from './FormEspecial.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import './style.sass'
import "./FormEspecial_style.css";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import BusinessIcon from '@mui/icons-material/Business';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';


export const FormEspecial: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);

   const segmentaciones = [
     { value: '1', label: 'Segmentación 1' },
     { value: '2', label: 'Segmentación 2' },
     { value: '3', label: 'Segmentación 3' },
     { value: '4', label: 'Segmentación 4' },     
   ]
   const origenes = [
       { value: '1', label: 'Origen 1' },
         { value: '2', label: 'Origen 2' },
         { value: '3', label: 'Origen 3' },
         { value: '4', label: 'Origen 4' },
   ]

   const destinos=[
       { value: '1', label: 'Destino 1' },
         { value: '2', label: 'Destino 2' },
         { value: '3', label: 'Destino 3' },
         { value: '4', label: 'Destino 4' },
   ]

   

   const rules = useMemo(() => ({
      nombre_producto_ingles: [
         VALIDATORS.REQUIRED,
      ],
      markup: [
         VALIDATORS.REQUIRED,
      ],
      descripcion: [
         VALIDATORS.REQUIRED
      ],
      marca: [
         VALIDATORS.REQUIRED,
      ],

   }), []);


   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'nombre_producto':
            props.setvalueEntry(prev => ({ ...prev, nombre_producto: value }));
            break;
         case 'markup':
            props.setvalueEntry(prev => ({ ...prev, markup: value }))
            break;
         case 'marca':
            props.setvalueEntry(prev => ({ ...prev, marca: value }))
            break;
         default:
            break;
      }
   };
   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      props.setvalueEntry(prev => ({ ...prev, [name]: newVal }));
   }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      props.handleNext();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }

   return (
      <Container maxWidth="xl">
         <form >
            <Grid container spacing={3} className={`e-mb-lg`}>
            
            <Grid item xs={12} sm={4}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <BusinessCenterIcon/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="nombre_producto_ingles"
                     type='text'
                     placeholder="Nombre Producto en Ingles"
                     value={props.valueEntry.nombre_producto_ingles}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.nombre_producto_ingles}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />

               </Grid>

               <Grid item xs={12} sm={4}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <BusinessIcon/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="markup"
                     type='text'
                     placeholder="Markup"
                     value={props.valueEntry.markup}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.markup}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />

               </Grid>
              
               <Grid item xs={12} sm={4}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <DriveFileRenameOutlineIcon/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="marca"
                     type='text'
                     placeholder="Marca"
                     value={props.valueEntry.marca}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.marca}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>

             
               <Grid item xs={12} sm={4}>
                  <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={segmentaciones}
                           renderInput={(params) => <TextField {...params} label="Segmentación" />}
                  />
               </Grid>

               <Grid item xs={12} sm={4}>
                  <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={origenes}
                           renderInput={(params) => <TextField {...params} label="Seleccione origen" />}
                  />
               </Grid>
               <Grid item xs={12} sm={4}>
                  <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={destinos}
                           renderInput={(params) => <TextField {...params} label="Seleccione destino" />}
                  />
               </Grid>

               <Grid item container
                  xs={12}
                 justifyContent='right'
                 >
                     <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                        className='btn_save2'
                        
                        // endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>AGREGAR</span>
                        }
                     </Button>

                     </Grid>
               </Grid>
            </Grid>
         </form>
      </Container>
   );
}
