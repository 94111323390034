
import React,{ FunctionComponent, useState } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import '../EvMarginSell_styles.css';
import TextField from '@mui/material/TextField';
import { Autocomplete, Box, Button, Grid} from "@mui/material";
import { Protected } from "@/components/layout/Protected";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Container, } from '@mui/material'
import Typography from '@mui/material/Typography';
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from 'react-router-dom';
import {ROUTE_EV_DE_MARGIN_SELL}  from '@/toolbox/constants/route-map';


interface TableMOnthProductProps {
    
}
interface Column {
   id: 'grc' | 'codigo' | 'descripcion'|'ventas' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id:'grc', label: 'GRC', minWidth: 10 },
   { id: 'codigo', label: 'Código', minWidth: 100 },
   { id: 'descripcion', label: 'Descripción', minWidth: 100 },
   { id: 'ventas', label: 'Ventas', minWidth: 100 },
];


interface Data {
   grc: string;
   codigo: string;
   descripcion: string;
   ventas: string;
}


function createData(
   grc: string,
   codigo: string,
   descripcion: string,
   ventas: string,
): Data {
   return { grc, codigo, descripcion, ventas };
}

const rowsData = [
   createData('1','0000.1','CERVEZA','21'),
   createData('2','000025', 'CASA','21'),
   createData('3','00002546', 'ACEITE','45'),
   createData('4','0000-163', 'ARROZ','6'),
   createData('5','0000sada', 'AUTO','45'),
   createData('6','ASAD-153', 'CAMIONETA','18852'),
   createData('7','0000-ASE4', 'AGUA','1'),
   createData('8','0000-KAR56', 'LECHE','4'),
   createData('9','000055', 'ATUN','5'),
   createData('10','0000.1', 'AVENA','5'),
   createData('11','0000.1', 'HOTELES','45'),
   createData('12','0000.1', 'LICORES','52'),
   createData('13','0000.1', 'CARTERAS','145'),
   createData('14','0000.1', 'PANTALON','65'),
   createData('15','0000.1', 'CASACA','45'),
   createData('16','0000.1', 'CARTERA','0'),
   createData('18','0000.1', 'MOCHILA','0'),
   createData('19','0000.1', 'ESTAND','0'),
   createData('20','0000.1', 'MOSTRADOR','0'),
]

 
export const ProductDetail: FunctionComponent<TableMOnthProductProps> = () => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(20);

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };
   
   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   }; 

    return ( 
        <>
        <Protected >
        <Grid container mb={4}>
             <Grid item xs={12} sm={6}>
                <Typography variant="h6" id="modal-modal-title" mb={2} className='tittle'>
                     Detalle Venta Productos Pack Junio - 2022
                </Typography>
                <Typography variant="subtitle2">
                     Detalle según Ventas Realizadas
                </Typography>
             </Grid>
             <Grid item xs={12} sm={6} textAlign="end">
                <Button variant="outlined" component={Link} to={ROUTE_EV_DE_MARGIN_SELL} startIcon={<ArrowBackIosIcon />}>
                   Volver
                </Button>
             </Grid>
          </Grid>

          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
             <TableContainer component={Paper}>
                <Table aria-label="simple table">
                   <TableHead>
                      <TableRow>
                         {columns.map((column) => (
                            <TableCell
                            
                               key={column.id}
                               align={column.align}
                               style={{ minWidth: column.minWidth }}
                            >
                               {column.label}
                            </TableCell>
     
                         ))}
                  
                      </TableRow>
                   </TableHead>
                   <TableBody>
                      {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                         return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.codigo}>
                               {columns.map((column) => {
                                  const value = row[column.id];
                                  return (
                                     <TableCell key={column.id} align={column.align}>
                                        {column.format && typeof value === 'number'
                                           ? column.format(value)
                                           : value}
                                     </TableCell>
                                  );
                               })}
                                 
                            </TableRow>
                         );
                      })}
                   </TableBody>
                </Table>
             </TableContainer>
             <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rowsData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
             />
          </Paper>

        </Protected>
        </>
     );
}
 
