import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { ArrowRightIcon } from "@/toolbox/constants/icons";
import { VALIDATORS } from "@/toolbox/helpers/validation-rules";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, Card, CardActionArea, CardMedia, CardContent, } from '@mui/material';
import { Input } from '@components/common/Input';
import { Props } from "./Home.type";
import { InputRef } from '@/components/common/Input/InputInterfaces';
import './Home-style.css';
import imagen from '@assets/img/banner4.jpg';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import { Chart } from "react-google-charts";

import { Link } from 'react-router-dom';
import { ROUTE_HOME_SALES_AVG, ROUTE_HOME_SALES_MONTH, ROUTE_HOME_SALES_YEAR, ROUTE_HOME_TOTAL_PRODUCTS, ROUTE_HOME_TOTAL_SALES } from '@/toolbox/constants/route-map';

export const Home: React.FC<Props> = (props: Props): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [valueT, setValueT] = useState('');
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [openEnterprise, setOpenEnterprise] = useState(false);

   const [open, setOpen] = React.useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);
   // const [open, setOpen] = useState(false);
   // const handleOpen = () => setOpen(true);
   // const handleClose = () => setOpen(false);

   const handleChangeT = (event: any) => {
      setValueT(event.target.value);
   };

   const handleOpenEnterprise = () => setOpenEnterprise(true);
   const handleCloseEnterprise = () => setOpenEnterprise(false);

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };
   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };
   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 650,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const categoriaValue = [
      { label: 'Bebidas' },
      { label: 'Envasados' },
      { label: 'Lacteos' },

   ]

   // DATA PARA DATOS DEL 1ER GRÁFICO DE BARRAS (DIAGRAMA DE PROMEDIOS)
   const dataPromedios = [
      ["Promedios", "Pedidos(u)", "Ganancias($)", "Nuevo($)"],
      ["08:00:00", 800, 2000, 1200],
      ["09:00:00", 470, 3000, 1250],
      ["10:00:00", 530, 1120, 1300],
      ["11:00:00", 220, 1500, 1300],
      ["12:00:00", 400, 1120, 1300],
      ["01:00:00", 660, 1120, 1700],
      ["02:00:00", 660, 1720, 1300],
      ["03:00:00", 930, 1540, 1350],
      ["04:00:00", 660, 1120, 1300],
      ["05:00:00", 700, 1120, 1300],
   ];
   const optionsPromedios = {
      chart: {
         title: "Diagrama de promedios",
         subtitle: "Promedios, pedidos, ganancias y nuevos en cada hora del día",
      },
   };
   // DATA PARA DATOS DEL 2DO GRÁFICO DE BARRAS (VENTAS POR DÍA)
   const dataVentasDiarias = [
      [
         "Diagrama Estadístico",
         "Ventas($)",
      ],
      ["08:30:00", 37.8],
      ["09:30:00", 30.9],
      ["10:30:00", 25.4],
      ["11:30:00", 11.7],
      ["12:30:00", 11.9],
      ["13:30:00", 8.8],
      ["14:30:00", 7.6],
      ["15:30:00", 12.3],
      ["16:30:00", 16.9],
      ["17:30:00", 12.8],
      ["18:30:00", 5.3],
      ["19:30:00", 6.6],
      ["20:30:00", 4.8],
      ["21:30:00", 4.2],
      ["22:30:00", 4.2],
      ["23:30:00", 4.2],
      ["24:30:00", 4.2],
   ];
   // DATA PARA DATOS DEL GRÁFICO DE TORTA (TOP PRODUCTOS SIMPLES)
   const dataProductSimple = [
      ["Task", "Hours per Day"],
      ["Otros", 11],
      ["Cerveza", 8],
      ["Huevos", 15],
   ];
   const optionsProductSimple = {
      // title: "My Daily Activities",
      pieHole: 0.3,
      is3D: false,
   };
   // DATA PARA DATOS DEL GRÁFICO DE TORTA (TOP PRODUCTOS COMPUESTOS)
   const dataProductCompuesto = [
      ["Task", "Hours per Day"],
      ["Otros", 11],
      ["Cerveza + Ron", 8],
      ["Ron + Coca Cola", 15],
   ];
   const optionsProductCompuesto = {
      // title: "My Daily Activities",
      pieHole: 0.3,
      is3D: false,
   };


   const optionsVentasDiarias = {
      chart: {
         title: "Ventas Diarias",
         subtitle: "Ventas realizadas cada día en el mes",
      },
   };
   //DATA PARA TABLA AVG
   interface Column {
      id: 'Nombre' | 'Ventas' | 'Ganancias';
      label: string;
      minWidth?: number;
      align?: 'right';
      format?: (value: number) => string;
   }
   const columns: Column[] = [
      { id: 'Nombre', label: 'Nombre', minWidth: 10 },
      { id: 'Ventas', label: 'Ventas', minWidth: 100 },
      { id: 'Ganancias', label: 'Ganancias', minWidth: 100 },
   ];

   interface Data {
      Nombre: string;
      Ventas: string;
      Ganancias: string;
   }

   function createData(
      Nombre: string,
      Ventas: string,
      Ganancias: string,
   ): Data {
      return { Nombre, Ventas, Ganancias };
   }
   const rows = [
      createData('Producto A-01', '1100', '$29900'),
      createData('Producto A-02', '820', '$2000'),
      createData('Producto A-03', '700', '$2000'),
      createData('Producto A-04', '200', '$1000'),
      createData('Producto A-05', '193', '$900'),
      createData('Producto A-06', '100', '$800'),
      createData('Producto A-07', '80', '$200'),
   ]
   //GRAFICO ALEATORIOOO
   const dataAleatorio = [
      ["Year", "Sales", "Expenses"],
      ["2013", 1000, 400],
      ["2014", 1170, 460],
      ["2015", 660, 1120],
      ["2016", 1030, 540],
   ];
   const optionsAleatorio = {
      isStacked: true,
      height: 300,
      legend: { position: "top", maxLines: 3 },
      vAxis: { minValue: 0 },
   };
   //GRAFICO ALEATORIO 2
   const dataAleatorio2 = [
      ["City", "2010 Population", "2000 Population"],
      ["New York City, NY", 8175000, 8008000],
      ["Los Angeles, CA", 3792000, 3694000],
      ["Chicago, IL", 2695000, 2896000],
      ["Houston, TX", 2099000, 1953000],
      ["Philadelphia, PA", 1526000, 1517000],
    ];
   const optionsAleatorio2 = {
      title: "Population of Largest U.S. Cities",
      chartArea: { width: "50%" },
      hAxis: {
        title: "Total Population",
        minValue: 0,
      },
      vAxis: {
        title: "City",
      },
    };

   const rules = useMemo(() => ({
      rut_proveedor: [
         VALIDATORS.REQUIRED,
      ],
      numero_contrato: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      titulo: [
         VALIDATORS.REQUIRED
      ],
      descripcion: [
         VALIDATORS.REQUIRED,
      ]

   }), []);
   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'rut_proveedot':
            props.setvalueContrato(prev => ({ ...prev, rut_proveedot: value }));
            break;
         case 'numero_contrato':
            props.setvalueContrato(prev => ({ ...prev, numero_contrato: value }))
            break;
         case 'titulo':
            props.setvalueContrato(prev => ({ ...prev, titulo: value }))
            break;
         case 'descripcion':
            props.setvalueContrato(prev => ({ ...prev, descripcion: value }));
            break;

         default:
            break;
      }
   };

   // const handleInputBlur = (event: any) => {
   //    const newVal = (event.target.value).trim();
   //    const name = event.target.name;
   //    props.setvalueContrato(prev => ({ ...prev, [name]: newVal }));
   // }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }
   return (
      <Protected>
         <Container maxWidth='xl'>
            {/* <form style={{ margin: '8px' }}> */}

            <Grid container spacing={3} className={`e-mb-lg`} >

               <Grid item xs={12} sm={3} >
                  <Card >
                     <CardActionArea component={Link} to={ROUTE_HOME_TOTAL_SALES}>
                        <CardContent>
                           <Typography gutterBottom variant="subtitle2" component="div" color="text.secondary">
                              TOTAL VENTAS
                           </Typography>
                           <Typography variant="h6" className='color-number'>
                              $240, 000
                           </Typography>
                        </CardContent>
                     </CardActionArea>
                  </Card>
               </Grid>
               <Grid item xs={12} sm={3} >
                  <Card >
                     <CardActionArea component={Link} to={ROUTE_HOME_TOTAL_PRODUCTS}>
                        <CardContent>
                           <Typography gutterBottom variant="subtitle2" component="div" color="text.secondary">
                              TOTAL PRODUCTOS
                           </Typography>
                           <Typography variant="h6" className='color-number'>
                              90, 480
                           </Typography>
                        </CardContent>
                     </CardActionArea>
                  </Card>
               </Grid>
               <Grid item xs={12} sm={2} >
                  <Card >
                     <CardActionArea component={Link} to={ROUTE_HOME_SALES_YEAR}>
                        <CardContent>
                           <Typography gutterBottom variant="subtitle2" component="div" color="text.secondary">
                              VENTAS 2021
                           </Typography>
                           <Grid display={'flex'} alignItems={'center'}>
                              <Typography variant="h6" className='color-number'>
                                 $143, 540
                              </Typography>
                              <Typography variant="subtitle2" className='var' ml={'2px'}>
                                 + 14%
                              </Typography>
                           </Grid>
                        </CardContent>
                     </CardActionArea>
                  </Card>
               </Grid>
               <Grid item xs={12} sm={2} >
                  <Card >
                     <CardActionArea component={Link} to={ROUTE_HOME_SALES_MONTH}>
                        <CardContent>
                           <Typography gutterBottom variant="subtitle2" component="div" color="text.secondary">
                              VENTAS MARZO
                           </Typography>
                           <Grid display={'flex'} alignItems={'center'}>
                              <Typography variant="h6" className='color-number'>
                                 $240, 540
                              </Typography>
                              <Typography variant="subtitle2" className='var2' ml={'2px'}>
                                 + 24%
                              </Typography>
                           </Grid>
                        </CardContent>
                     </CardActionArea>
                  </Card>
               </Grid>
               <Grid item xs={12} sm={2} >
                  <Card>
                     <CardActionArea component={Link} to={ROUTE_HOME_SALES_AVG}>
                        <CardContent>
                           <Typography gutterBottom variant="subtitle2" component="div" color="text.secondary">
                              PROMEDIO VENTAS
                           </Typography>
                           <Grid display={'flex'} alignItems={'center'}>
                              <Typography variant="h6" className='color-number'>
                                 $3, 980
                              </Typography>
                              <Typography variant="subtitle2" className='var2' ml={'2px'}>
                                 + 30%
                              </Typography>
                           </Grid>
                        </CardContent>
                     </CardActionArea>
                  </Card>
               </Grid>

               {/* <CardActionArea> */}
               <Grid item xs={12}>
                  <Card>
                     <CardMedia
                        component="img"
                        height="100"
                        image={imagen}
                        alt="green iguana"
                     />
                     <CardContent>
                        <Typography gutterBottom variant="h4" color="text.secondary" component="div" >
                           Buenas Tardes, Softnet. 👋
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                           Esto es lo que está sucediendo con sus proyectos hoy
                        </Typography>
                     </CardContent>

                  </Card>
               </Grid>
               {/* OPCIONES DEBAJO DEL BANNER */}
               <Grid container item xs={12} alignItems={'center'}>
                  <Grid item xs={12} sm={4} textAlign="start" >
                     <Button color="secondary">
                        Opciones
                     </Button>
                  </Grid>
                  <Grid container item xs={12} sm={8} textAlign="end" alignItems={'center'}>
                     <Grid item xs={6}>
                        <IconButton aria-label="delete" size="large">
                           <FilterListIcon fontSize="large" />
                        </IconButton>
                     </Grid>
                     <Grid item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                           <DatePicker
                              // label="Fecha de Nacimiento"

                              value={value}
                              onChange={(newValue) => {
                                 setValue(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                           />
                        </LocalizationProvider>
                     </Grid>
                     <Grid item xs={2}>
                        {/* <Button color='warning'>
                              Otra opción
                           </Button>
                           {'|'} */}
                        <Button color='warning'>
                           + Add View
                        </Button>
                     </Grid>
                  </Grid>
               </Grid>
               {/* GRÁFICOS*/}
               <Grid item xs={12}>
                  <div className='bg-tittle'>
                     <Typography
                        mt={3}
                        variant="h6"
                        align='center'
                        className='tittle-text'
                     >Ventas promedio y ventas diarias
                     </Typography>
                  </div>
               </Grid>
               <Grid item xs={12}>
                  <Chart
                     chartType="Bar"
                     width="100%"
                     height="400px"
                     data={dataPromedios}
                     options={optionsPromedios}
                  />
               </Grid>
               <Grid item xs={12}>
                  <Chart
                     chartType="Line"
                     width="100%"
                     height="400px"
                     data={dataVentasDiarias}
                     options={optionsVentasDiarias}
                  />
               </Grid>
               {/* PIE CHART PRODUCTOS IMPLES */}
               <Grid item xs={12} sm={4}>
                  <div className='bg-tittle'>
                     <Typography
                        mt={3}
                        variant="h6"
                        align='center'
                        className='tittle-text'
                     >Top Productos Simples
                     </Typography>
                  </div>
                  <Grid container xs={12} alignItems='center' mt={1} justifyContent='space-around'>
                     <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                           <DatePicker
                              // label="Fecha de Nacimiento"

                              value={value}
                              onChange={(newValue) => {
                                 setValue(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                           />
                        </LocalizationProvider>
                     </Grid>
                     <Grid item xs={12} sm={4}>
                        <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={categoriaValue}
                           // sx={{ bgcolor: '#F3F3F3'}}
                           renderInput={(params) => <TextField {...params} label="Categorías" />}
                        />
                     </Grid>
                  </Grid>

                  <Chart
                     chartType="PieChart"
                     data={dataProductSimple}
                     options={optionsProductSimple}
                     width={"100%"}
                     height={"400px"}
                  />
               </Grid>
               {/* PIE CHARY PRODUCTOS COMPUESTOS */}
               <Grid item xs={12} sm={4}>
                  <div className='bg-tittle'>
                     <Typography
                        mt={3}
                        variant="h6"
                        align='center'
                        className='tittle-text'
                     >Top Productos Compuestos
                     </Typography>
                  </div>
                  <Chart
                     chartType="PieChart"
                     data={dataProductCompuesto}
                     options={optionsProductCompuesto}
                     width={"100%"}
                     height={"400px"}
                  />
               </Grid>
               {/* TABLA AVG Productos Variables */}
               <Grid item xs={12} sm={4}>
                  <div className='bg-tittle'>
                     <Typography
                        mt={3}
                        variant="h6"
                        align='center'
                        className='tittle-text'
                     >AVG Productos Variables
                     </Typography>
                  </div>
                  <Grid item xs={12} >
                     <Box mt={1} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <FormControl fullWidth >
                           <TextField
                              id="input-with-sx"
                              onChange={handleChangeT}
                              placeholder="Buscar"
                           />
                           <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                              <TableContainer sx={{ maxHeight: 280 }}>
                                 <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                       <TableRow>
                                          {columns.map((column) => (
                                             <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                             >
                                                {column.label}
                                             </TableCell>
                                          ))}
                                          <TableCell>Opciones</TableCell>
                                       </TableRow>
                                    </TableHead>

                                    <TableBody>
                                       {rows
                                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                          .map((rows) => {
                                             return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={rows.Nombre}>
                                                   {columns.map((column) => {
                                                      const value = rows[column.id];
                                                      return (
                                                         <TableCell key={column.id} align={column.align}>
                                                            {column.format && typeof value === 'number'
                                                               ? column.format(value)
                                                               : value}
                                                         </TableCell>
                                                      );
                                                   })}
                                                   <TableCell>
                                                      <Tooltip title="Ver Detalles">
                                                         <IconButton onClick={handleOpen} color="primary" aria-label="upload picture" component="span">
                                                            <SearchIcon />
                                                         </IconButton>
                                                      </Tooltip>

                                                   </TableCell>
                                                </TableRow>
                                             );
                                          })}
                                    </TableBody>
                                 </Table>
                              </TableContainer>

                           </Paper>
                        </FormControl>
                     </Box>
                  </Grid>
               </Grid>
               {/* CUADRO ALEATORIO */}
               <Grid item xs={12} sm={4}>
                  <div className='bg-tittle'>
                     <Typography
                        mt={3}
                        variant="h6"
                        align='center'
                        className='tittle-text'
                     >Cuadro Aleatorio
                     </Typography>
                  </div>
                  <Chart
                     chartType="AreaChart"
                     width="100%"
                     height="400px"
                     data={dataAleatorio}
                     options={optionsAleatorio}
                  />
               </Grid>
               {/* CUADRO ALEATORIO 2*/}
               <Grid item xs={12} sm={4}>
                  <div className='bg-tittle'>
                     <Typography
                        mt={3}
                        variant="h6"
                        align='center'
                        className='tittle-text'
                     >Cuadro Aleatorio 2
                     </Typography>
                  </div>
                  <Chart
                     chartType="BarChart"
                     width="100%"
                     height="400px"
                     data={dataAleatorio2}
                     options={optionsAleatorio2}
                  />
               </Grid>
               {/* CUADRO ALEATORIO 3*/}
               <Grid item xs={12} sm={4}>
                  <div className='bg-tittle'>
                     <Typography
                        mt={3}
                        variant="h6"
                        align='center'
                        className='tittle-text'
                     >Cuadro Aleatorio 3
                     </Typography>
                  </div>
                  <Chart
                     chartType="AreaChart"
                     width="100%"
                     height="400px"
                     data={dataAleatorio}
                     options={optionsAleatorio}
                  />
               </Grid>
            </Grid>
            {/* </form>*/}
         </Container>


         <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <Typography id="modal-modal-title" variant="h6" sx={{ fontWeight: 'bold' }} component="h2">
                  Detalle del Producto
               </Typography>
               <Typography id="modal-modal-description" variant='subtitle2' sx={{ mt: 1 }}>
                  Codigo: A-0021
               </Typography>
               <Typography id="modal-modal-description" variant='subtitle2' sx={{ mt: 1 }}>
                  Barra: |||||||
               </Typography>
               <Typography id="modal-modal-description" variant='subtitle2' sx={{ mt: 1 }}>
                  Codigo Proveedor: P-0021
               </Typography>
               <Typography id="modal-modal-description" variant='subtitle2' sx={{ mt: 1 }}>
                  Precio: $1.000
               </Typography>
               <Typography id="modal-modal-description" variant='subtitle2' sx={{ mt: 1 }}>
                  Stock: 50
               </Typography>

            </Box>
         </Modal>
      </Protected>
   );
}