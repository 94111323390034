import React, { useState, useEffect } from 'react';
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Protected } from "@/components/layout/Protected";
import { FormControl, Grid, Stack, Typography, Button, Input, Tooltip, Autocomplete } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { createTheme } from '@mui/material/styles';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import InputLabel from '@mui/material/InputLabel';
import "./InternalGuideOc.sass";
// import "./Home-styles.css";
import "./InternalGuideOc-styles.css"
import { TableData } from "@/components/common/Table/Table";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';

import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';
import { DateTimePicker } from '@mui/lab';


export const InternalGuideOc: FunctionComponent = (props: any) => {
   const [value, setValue] = useState('');
   const [selectRut, setSelectRut] = useState('');
   const [select, setSelect] = useState('');
   const [selectContact, setSelectContact] = useState('');
   const [selectConditionPay, setSelectConditionPay] = useState('');
   const [selectClasificationBuy, setSelectClasificationBuy] = useState('');
   const [selectCenterCost, setSelectCenterCost] = useState('');
   const [selectPerson, setSelectPerson] = useState('');
   const [selectTypeResume, setSelectTypeResume] = useState('');
   const [data, setData] = React.useState<Date | null>(new Date());
   const [open, setOpen] = React.useState(false);
   const [openRut, setOpenRut] = React.useState(false);
   const [valueCalendar, setValueCalendar] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);

   const handleOpenRut = () => setOpenRut(true);
   const handleCloseRut = () => setOpenRut(false);




   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 350,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 4,
      borderRadius: '20px'
   };

   const styleEnterprise = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '70%',
      // height:'500px',
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 4,
      borderRadius: '20px',

   };
   

   const handleChange = (event: any) => {
      setValue(event.target.value);
   };

   const handleChangeRut = (event: SelectChangeEvent) => {
      setSelectRut(event.target.value);
   };

   const handleChangeContact = (event: SelectChangeEvent) => {
      setSelectContact(event.target.value);
   };
   const handleChangeConditionPay = (event: SelectChangeEvent) => {
      setSelectConditionPay(event.target.value);
   };
   const handleChangeClasificationBuy = (event: SelectChangeEvent) => {
      setSelectClasificationBuy(event.target.value);
   };
   const handleChangeCenterCost = (event: SelectChangeEvent) => {
      setSelectCenterCost(event.target.value);
   };
   const handleChangePerson = (event: SelectChangeEvent) => {
      setSelectPerson(event.target.value);
   };
   const handleChangeTypeResume = (event: SelectChangeEvent) => {
      setSelectTypeResume(event.target.value);
   };



   interface Column {
      id: 'Codigo' | 'Detalle';
      label: string;
      minWidth?: number;
      align?: 'center';
      format?: (value: number) => string;
   }

   const columns: Column[] = [
      // { id: 'Codigo', label: 'Codigo', minWidth: 50 },
      { id: 'Detalle', label: 'Detalle', minWidth: 250 },
   ];

   interface Data {
      Codigo: string;
      Detalle: string;
   }

   function createData(
      Codigo: string,
      Detalle: string,
   ): Data {
      return { Codigo, Detalle };
   }
   const rows = [
      createData('A-0001', 'producto1'),
      createData('A-0002', 'producto2'),
      createData('A-0003', 'producto3'),
      createData('A-0004', 'producto4'),
      createData('A-0005', 'producto5'),
      createData('A-0006', 'producto6'),
      createData('A-0007', 'producto7'),
   ]
   const [page, setPage] = React.useState(0);
   const [rowsPerPage, setRowsPerPage] = React.useState(10);

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };


   //Para tabla de busqueda de ruts
   function createDataRut(
      rut: string,
      razonSocial: string,
      fantasia: string,
      giro: string,

   ) {
      return { rut, razonSocial, fantasia, giro };
   }
   const centroBeneficio=[
      { label: 'Seleccion CB' },
      { label: 'Despacho NV' },
      { label: 'Farmacia' }
   ]
   const bodega=[
      { label: 'Aduana' },
      { label: 'Jibia Congelada' },
      { label: 'Jibia Conserva' },
      { label: 'Mermas' }
   ]
   const equipo=[
      { label: 'Cortadora de Perfiles' },
      { label: 'Maquina 2' },
      { label: 'Principal' },
      { label: 'Mermas' },
      { label: 'Ejet ecosolventada' }
   ]
   const tipoDocumento=[
      { label: 'Boleta de Honorarios' },
      { label: 'Boleta de Honorarios Electrónica' },
      { label: 'Boleta de Honorarios de Terceros' },
      { label: 'Factura' },
      { label: 'Factura de Compra' },
      { label: 'Factura Extensa' }
   ]
   
   const rowsRut = [
      createDataRut('76888915-5', 'Empresa Pedrito Alcachofas', 'EPA', 'personas'),
      createDataRut('78059800-K', 'Empresa que soluciona tu vida', 'EQSTV', 'terceros'),
      createDataRut('92052000-6', 'Soy la tercera empresa', 'SL3', 'Giros'),
      createDataRut('78059800-q', 'Otro nombre más', 'ONM', ' terceros'),
      createDataRut('76888915-5', 'Empresa los empresarios', 'ELE', 'personas'),
      createDataRut('92052000-6', 'Soy la tercera empresa', 'SL3', 'Giros'),
      createDataRut('78059800-q', 'Otro nombre más', 'ONM', ' terceros'),
      createDataRut('76888915-5', 'Empresa los empresarios', 'ELE', 'personas'),
      createDataRut('92052000-6', 'Soy la tercera empresa', 'SL3', 'Giros'),
      createDataRut('78059800-q', 'Otro nombre más', 'ONM', ' terceros'),
      createDataRut('76888915-5', 'Empresa los empresarios', 'ELE', 'personas'),
      createDataRut('92052000-6', 'Soy la tercera empresa', 'SL3', 'Giros'),
      createDataRut('78059800-q', 'Otro nombre más', 'ONM', ' terceros'),
      createDataRut('76888915-5', 'Empresa los empresarios', 'ELE', 'personas'),
      createDataRut('92052000-6', 'Soy la tercera empresa', 'SL3', 'Giros'),
      createDataRut('78059800-q', 'Otro nombre más', 'ONM', ' terceros'),
      createDataRut('76888915-5', 'Empresa los empresarios', 'ELE', 'personas'),
      createDataRut('92052000-6', 'Soy la tercera empresa', 'SL3', 'Giros'),
      createDataRut('78059800-q', 'Otro nombre más', 'ONM', ' terceros'),
      createDataRut('76888915-5', 'Empresa los empresarios', 'ELE', 'personas')
   ];
   return (
      <Protected>
         <div className='Generator'>
            <Grid item xs={12} m={1}>
               <Typography
                  variant="h6"
                  className='tittle'
                  sx={{ textTransform: 'uppercase' }}
               >Emitiendo Guia Interna</Typography>

               <Grid className='Generator' container spacing={2}>

                  <Grid item xs={12} sm={12} mt={1}>
                     <div className='bg-tittle3'>
                        <Typography
                           mt={1}
                           variant="h6"
                           align='center'
                           className='tittle-text'
                        >Datos de Guia Interna
                        </Typography>
                     </div>

                     <Grid container spacing={2} mt={1} className='margin'>

                        <Grid item container xs={12} sm={3} sx={{ alignItems: 'center' }}>
                           <Grid item xs={10}>
                              <FormControl fullWidth sx={{ minWidth: 200 }}>
                                 <InputLabel id="demo-simple-select-helper-label">Rut de Proveedor</InputLabel>
                                 <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={selectRut}
                                    label="Rut de Proveedor "
                                    onChange={handleChangeRut}

                                 >
                                    <MenuItem value={1}>Rut de Proveedor 1</MenuItem>
                                    <MenuItem value={2}>Rut de Proveedor 2</MenuItem>
                                    <MenuItem value={3}>Rut de Proveedor 3</MenuItem>
                                 </Select>
                              </FormControl>
                           </Grid>
                           <Grid item  xs={12} container sm={2}>
                              <Tooltip title="Buscar Empresa">
                                 <Button onClick={handleOpenRut} className='btnIcoE'><SearchIcon /></Button>
                              </Tooltip>

                           </Grid>
                        </Grid>                        
                        <Grid item xs={12} container sm={2} sx={{ alignItems: 'center' }}>
                           <Grid item>
                           <LocalizationProvider dateAdapter={AdapterDateFns}>
                           <Stack spacing={3}>
                              <DatePicker
                                 views={['year', 'month','day']}
                                 label="Periodo y Mes"
                                 inputFormat="dd/MM/yyyy"
                                 minDate={new Date('2012-03-01')}
                                 maxDate={new Date('2023-06-01')}
                                 value={valueCalendar}
                                 
                                 onChange={(newValue) => {
                                    setValueCalendar(newValue);
                                 }}
                                 renderInput={(params) => <TextField {...params} helperText={null} />}
                              />
                              </Stack>
                           </LocalizationProvider>
                           </Grid>
                           
                        </Grid>
                        
                        <Grid item xs={12} sm={2}>
                           <FormControl fullWidth sx={{ minWidth: 200 }}>
                              <InputLabel id="demo-simple-select-helper-label">Operación</InputLabel>
                              <Select
                                 labelId="demo-simple-select-helper-label"
                                 id="demo-simple-select-helper"
                                 value={selectContact}
                                 label="Operación "
                                 onChange={handleChangeContact}
                              >
                                 <MenuItem value="Operacion">
                                    <em>None</em>
                                 </MenuItem>
                                 <MenuItem value={1}>Ingreso</MenuItem>
                                 <MenuItem value={2}>Egreso</MenuItem>               
                              </Select>
                           </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                        <FormControl fullWidth sx={{ minWidth: 200 }}>
                           <Autocomplete
                              disablePortal
                              // size='small'
                              id="combo-box-demo"
                              options={bodega}
                              renderInput={(params) => <TextField {...params} label="Bodega" />}
                           />
                           </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                        <FormControl fullWidth sx={{ minWidth: 200 }}>
                           <Autocomplete
                              disablePortal
                              // size='small'
                              id="combo-box-demo"
                              options={centroBeneficio}
                              renderInput={(params) => <TextField {...params} label="Moneda" />}
                           />
                           </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                        <FormControl fullWidth sx={{ minWidth: 200 }}>
                           <Autocomplete
                              disablePortal
                              // size='small'
                              id="combo-box-demo"
                              options={equipo}
                              renderInput={(params) => <TextField {...params} label="Equipo" />}
                           />
                           </FormControl>
                        </Grid>
                                    
                        <Grid item xs={12} sm={2}>
                           <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                           <FormControl fullWidth sx={{ minWidth: 200 }}> 
                                 <TextField
                                    id="input-with-sx"
                                    label="Tipo Cambio"
                                    onChange={handleChange}
                                    // placeholder="Cargando..."
                                 />
                             </FormControl>
                           </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                           <FormControl fullWidth sx={{ minWidth: 200 }}>
                              <Autocomplete
                                 disablePortal
                                 // size='small'
                                 id="combo-box-demo"
                                 options={tipoDocumento}
                                 renderInput={(params) => <TextField {...params} label="Tipo Documento" />}
                              />
                           </FormControl>
                        </Grid>               
                        <Grid item xs={12} sm={3}>
                           <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                           <FormControl fullWidth sx={{ minWidth: 200 }}>
                                 <TextField
                                    id="input-with-sx"
                                    label="Folio Doc"
                                    onChange={handleChange}
                                    // placeholder="Cargando..."
                                 />
                             </FormControl>
                           </Box>
                        </Grid>
                       
                     </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12} mt={1}>
                     <div className='bg-tittle3'>
                        <Typography
                           mt={1}
                           variant="h6"
                           align='center'
                           className='tittle-text'
                        >Insertar líneas de detalle
                        </Typography>
                     </div>
                     <Grid item xs={12} >
                        <Box mt={1} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                           <FormControl fullWidth >
                              <TextField
                                 id="input-with-sx"
                                 onChange={handleChange}
                                 defaultValue="Buscar"
                              />
                              <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                 <TableContainer sx={{ maxHeight: 280 }}>
                                    <Table stickyHeader aria-label="sticky table">
                                       <TableHead>
                                          <TableRow>
                                             {columns.map((column) => (
                                                <TableCell
                                                   key={column.id}
                                                   // align={column.align}
                                                   style={{ minWidth: column.minWidth }}
                                                   align='center'
                                                >
                                                   {column.label}
                                                </TableCell>
                                             ))}                                             
                                             <TableCell align='center'>Cantidad</TableCell>
                                             <TableCell align='center'>Pesos</TableCell>
                                             <TableCell align='center'>Moneda Ext</TableCell>
                                             <TableCell align='center'>Tipo</TableCell>
                                             <TableCell align='center'>Descuento</TableCell>
                                             <TableCell align='center'>Total</TableCell>
                                             <TableCell align='center'>Opciones</TableCell>
                                          </TableRow>
                                       </TableHead>

                                       <TableBody>
                                          {rows
                                             .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                             .map((rows) => {
                                                return (
                                                   <TableRow hover role="checkbox" tabIndex={-1} key={rows.Detalle}>
                                                      {columns.map((column) => {
                                                         const value = rows[column.id];
                                                         return (
                                                            <TableCell align='center' key={column.id}>
                                                               {column.format && typeof value === 'number'
                                                                  ? column.format(value)
                                                                  : value}
                                                            </TableCell>
                                                         );
                                                      })}
                                                      <TableCell>
                                                         <TextField type='number' defaultValue={1} size='small' />
                                                      </TableCell>
                                                      <TableCell>
                                                         <TextField type='text' size='small' />
                                                      </TableCell>
                                                      <TableCell>
                                                         <TextField type='text' size='small' />
                                                      </TableCell>
                                                      <TableCell>
                                                         <FormControl fullWidth sx={{ minWidth: 80 }}>
                                                            <InputLabel id="demo-simple-select-helper-label">Tipo</InputLabel>
                                                            <Select
                                                               labelId="demo-simple-select-helper-label"
                                                               id="demo-simple-select-helper"
                                                               value={selectTypeResume}
                                                               label="Tipo"
                                                               size='small'
                                                               onChange={handleChangeTypeResume}
                                                            >
                                                               <MenuItem value="Centro Costo">
                                                                  <em>None</em>
                                                               </MenuItem>
                                                               <MenuItem value={1}>%</MenuItem>
                                                               <MenuItem value={2}>$</MenuItem>
                                                            </Select>
                                                         </FormControl>

                                                      </TableCell>
                                                      <TableCell>
                                                         <TextField type='number' defaultValue={0} size='small' />
                                                      </TableCell>
                                                      <TableCell>
                                                         <TextField type='text' size='small' />
                                                      </TableCell>
                                                      <TableCell>
                                                         <div className='sepaEle'>                                                      
                                                            <button className='btnIcoD'><DownloadIcon /></button>
                                                         </div>
                                                      </TableCell>
                                                   </TableRow>
                                                );
                                             })}
                                       </TableBody>
                                    </Table>
                                 </TableContainer>

                              </Paper>
                           </FormControl>
                        </Box>
                     </Grid>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12}>
                     
                     <Grid item xs={12}>
                        <Box mt={1} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                           <FormControl fullWidth >
                              <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                 <TableContainer sx={{ maxHeight: 540 }}>
                                    <Table stickyHeader aria-label="sticky table">
                                       <TableHead>
                                          <TableRow>                                             
                                             <TableCell>Detalle / Descripción</TableCell>
                                             <TableCell>Cantidad</TableCell>
                                             <TableCell>Valor Neto</TableCell>
                                             <TableCell>Descuento</TableCell>
                                             <TableCell>SubTotal</TableCell>
                                             <TableCell>Selección</TableCell>
                                             <TableCell>Exento</TableCell>
                                             <TableCell align='center'>Opciones</TableCell>
                                          </TableRow>
                                       </TableHead>

                                       <TableBody>
                                          <TableRow >
                                             <TableCell>
                                                Lorem ipsum dolor sit
                                             </TableCell>

                                             <TableCell>
                                                14
                                             </TableCell>

                                             <TableCell>
                                                28
                                             </TableCell>

                                             <TableCell>
                                                0
                                             </TableCell>

                                             <TableCell>
                                                $600
                                             </TableCell>

                                             <TableCell>
                                                <FormControlLabel control={<Checkbox defaultChecked />} label="" />
                                             </TableCell>

                                             <TableCell>
                                                0
                                             </TableCell>

                                             <TableCell>
                                                <div className='sepaEle'>
                                                   <button className='btnIcoE'><EditIcon /></button>
                                                   <button className='btnIcoD'><DeleteIcon /></button>
                                                </div>
                                             </TableCell>
                                          </TableRow>

                                       </TableBody>
                                    </Table>
                                 </TableContainer>

                              </Paper>
                           </FormControl>
                        </Box>
                     </Grid>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12}>
                     <div className='bg-tittle2'>
                        <Typography
                           mt={2}
                           variant="h6"
                           align='center'
                           className='tittle-text'
                        >Resumen del Documento
                        </Typography>
                     </div>
                     <Grid container spacing={2} mt={1} className='margin' sx={{ justifyContent: 'space-between' }}>
                        {/* Columna 1 */}
                        <Grid item xs={12} sm={8}>
                           <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                              <FormControl fullWidth >
                                 <TextField
                                    id="outlined-multiline-static"
                                    label="Insertar Observación"
                                    multiline
                                    rows={5}
                                 />
                              </FormControl>
                           </Box>
                        </Grid>
                        {/* Columna 5 */}
                        <Grid item xs={12} sm={3}>                     
                           <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                              <FormControl fullWidth >
                                 <TextField
                                    id="input-with-sx"
                                    label="Total"
                                    onChange={handleChange}                                                                 
                                 />
                              </FormControl>
                           </Box>
                        </Grid>
                     </Grid>

                     <Grid item xs={12} textAlign="end">
                        <Button variant='contained' className='btnSend'>Generar Documento</Button>
                     </Grid>
                  </Grid>
               </Grid>

               <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <Typography id="modal-modal-title" variant="h6" sx={{ fontWeight: 'bold' }} component="h2">
                        Detalle del Producto
                     </Typography>
                     <Typography id="modal-modal-description" variant='subtitle2' sx={{ mt: 1 }}>
                        Codigo: A-0021
                     </Typography>
                     <Typography id="modal-modal-description" variant='subtitle2' sx={{ mt: 1 }}>
                        Barra: |||||||
                     </Typography>
                     <Typography id="modal-modal-description" variant='subtitle2' sx={{ mt: 1 }}>
                        Codigo Proveedor: P-0021
                     </Typography>
                     <Typography id="modal-modal-description" variant='subtitle2' sx={{ mt: 1 }}>
                        Precio: $1.000
                     </Typography>
                     <Typography id="modal-modal-description" variant='subtitle2' sx={{ mt: 1 }}>
                        Stock: 50
                     </Typography>

                  </Box>
               </Modal>

               <Modal
                  open={openRut}
                  onClose={handleCloseRut}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={styleEnterprise} >
                     <Typography>Seleccionar Proveedor</Typography><br />
                     <TableContainer component={Paper} sx={{ maxHeight: 430 }}>
                        <Table aria-label="simple table">
                           <TableHead>
                              <TableRow>
                                 <TableCell align="center">RUT</TableCell>
                                 <TableCell align="center">Razón Social</TableCell>
                                 <TableCell align="center">Nombre fantasía</TableCell>
                                 <TableCell align="center">Giro</TableCell>
                              </TableRow>
                           </TableHead>
                           <TableBody>
                              {rowsRut.map((row) => (
                                 <TableRow
                                    hover
                                    key={row.rut}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                 >
                                    <TableCell component="th" scope="row">
                                       {row.rut}
                                    </TableCell>
                                    <TableCell align="left">{row.razonSocial}</TableCell>
                                    <TableCell align="left">{row.fantasia}</TableCell>
                                    <TableCell align="left">{row.giro}</TableCell>
                                 </TableRow>
                              ))}
                           </TableBody>
                        </Table>
                     </TableContainer>
                  </Box>
               </Modal>
            </Grid>
         </div>
      </Protected>
   )
}

