import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, } from '@mui/material'
import { useState } from "react";
import { Props } from "./GcProductService.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './GcProductService_styles.css'
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { AddCircle } from "@mui/icons-material";
import AddCircleIcon from '@mui/icons-material/AddCircle';

interface Column {
   id: 'numero' | 'codigo' | 'color' | 'talla';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: 'numero', label: 'Número', minWidth: 100 },
   { id: 'codigo', label: 'Código', minWidth: 100 },
   { id: 'color', label: 'Color', minWidth: 100 },
   { id: 'talla', label: 'Talla', minWidth: 100 },

];

interface Data {
   numero: number;
   codigo: string;
   color: string;
   talla: string;

}

function createDataActives(
   numero: number,
   codigo: string,
   color: string,
   talla: string,
): Data {
return { numero, codigo, color, talla };
}

const rowsDataColorTalla = [
   createDataActives(1, 'COD001', 'BLANCO', 'S'),
   createDataActives(2, 'COD002', 'BLANCO', 'M'),
   createDataActives(3, 'COD003', 'BLANCO', 'L'),
   createDataActives(4, 'COD004', 'BLANCO', 'XL'),
]


export const DataModalColorTalla: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [ejecutivoComercial, setEjecutivoComercial] = useState('');
   const [estado, setEstado] = useState('');


   const handleChangeEjecutivoComercial = (event: SelectChangeEvent) => {
      setEjecutivoComercial(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const colores = [
      { value: '1', label: 'Blanco' },
      { value: '2', label: 'Azul' },
      { value: '3', label: 'Rojo' },
      { value: '4', label: 'Celeste' },
      { value: '5', label: 'Verde' },
      { value: '6', label: 'amarillo' },
   ]

   const tallas = [
      { value: '1', label: 'S' },
      { value: '2', label: 'M' },
      { value: '3', label: 'L' },
      { value: '4', label: 'XL' },
   ]

   return (
         <Container maxWidth='xl'>
            <Grid container spacing={3}>
            <Grid item xs={12} md={12} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        MANTENEDOR DE COLORES Y TALLAS
                     </Typography>
                  </Box>
               </Grid>

               <Grid item xs={12} sm={4}>
                  <TextField
                     value='COD001'
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={4}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={colores}
                     renderInput={(params) => <TextField {...params} label="Seleccione Color" />}
                  />
               </Grid>

               <Grid item xs={12} sm={4}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={tallas}
                     renderInput={(params) => <TextField {...params} label="Seleccione Talla" />}
                  />
               </Grid>

               <Grid item container
                  xs={12}
                  sm={4}  
                  justifyContent='center'
                  
               >
                  <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        // onClick={handleSubmit}
                        // disabled={loading}
                        disableElevation
                        className='btn_save'
                     >
                        <span>Agregar</span>
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={2}>
               LISTADO DE PROVEEDORES
            </Typography>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
               <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           <TableCell>N</TableCell>
                           <TableCell>Codigo</TableCell>
                           <TableCell>Color</TableCell>
                           <TableCell>Talla</TableCell>
                           <TableCell>Opcion</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsDataColorTalla.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.numero}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                    <IconButton
                                    >
                                       <EditIcon />
                                    </IconButton>
                                 </TableCell>
            
                              </TableRow>
                           );
                        })}

                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsDataColorTalla.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>

         </Container>
   );
};

