import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, InputAdornment, Modal, IconButton, SelectChangeEvent, Autocomplete, Button, } from '@mui/material'
import { useState, useRef } from "react";
import { Props } from "./CreditLine.type"
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import Paper from '@mui/material/Paper';

import './CreditLine_styles.css'

import SearchIcon from '@mui/icons-material/Search';

import { DataModalCustomer } from "./ModalListCustomer/DataModalCustomer";




export const CreditLine: React.FC<Props> = (props: Props): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [familyProduct, setFamilyProduct] = useState('');
   const [estado, setEstado] = useState('');
   const [openProvider, setOpenProvider] = useState(false);
   const [openCustomer, setOpenCustomer] = useState(false);


   const handleOpenProvider = () => setOpenProvider(true);
   const handleCloseProvider = () => setOpenProvider(false);

   const handleOpenCustomer = () => setOpenCustomer(true);

   const handleChangeFamilyProduct = (event: SelectChangeEvent) => {
      setFamilyProduct(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const estados = [
      { value: '1', label: 'Activo' },
      { value: '2', label: 'Inactivo' },
   ]

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 800,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

   return (
      <Protected>
         <Container maxWidth='xl'>
            <Grid container spacing={3}>
               <Grid item xs={12} md={12} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        LÍNEA DE CRÉDITO
                     </Typography>
                  </Box>
                  <Typography variant="subtitle2" display="block" gutterBottom mt={1}>
                     Seleccione al cliente
                  </Typography>

               </Grid>

               <Grid item xs={12} sm={3}>
                  <Input
                     ref={ref => inputRefs.current[2] = ref}
                     appendAdornment={
                        <InputAdornment position="end">
                           <IconButton onClick={handleOpenProvider}>
                              <SearchIcon />
                           </IconButton>

                        </InputAdornment>
                     }
                     name="valor_compra"
                     placeholder="Código"
                     //value={data.nombre_rz}
                     // value={props.valueContrato.valor_compra}
                     // onChange={handleInput}
                     //onBlur={handleInputBlur}
                     // backgroundColor="#F3F3F3"
                     // rules={rules.valor_compra}
                     disableElevation
                     validateOnBlur
                     dense
                     // disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>

               <Grid item xs={12} md={3}>
                  <Button
                     type='submit'
                     variant='contained'
                     onClick={handleOpenCustomer}
                     // disabled={loading}
                     disableElevation
                     className='btn_next'
                     style={{ backgroundColor: '#ff9700' }}
                  >
                     Seleccionar
                  </Button>
               </Grid>
               {/* CONTENEDOR DE CONTENIDO CONDICIONADO */}
               {/* SUBTITULOS */}
               {openCustomer && <Grid item container xs={12}>
                  <Grid item xs={12}>
                     <div className='bg-tittle'>
                        <Typography
                           mt={3}
                           variant="h6"
                           align='center'
                           className='tittle-text'
                        >Cliente Seleccionado
                        </Typography>
                     </div>
                  </Grid>
                  <Grid item xs={12}>
                     <Typography variant="button" display="block" gutterBottom mt={2}> 22222222-2
                        KAI DELIVERI - KAI DELIVERY
                     </Typography>
                  </Grid>
                  <Grid item container xs={12}>
                     <Grid item xs={12} md={5} ml={5}>
                        <Grid container spacing={2}>
                           <Grid item xs={12}>
                              <Typography variant="subtitle2" display="block" mt={3} p={1} className='subtitle_gray'>Información de Venta</Typography>
                           </Grid>
                           <Grid container item>
                              <Grid item xs={6}>
                                 <Typography variant="caption" display="block" mt={1} >Ventas de Ultimo Mes</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                 <Typography variant="caption" display="block" mr={1} mt={1} >: $ 315.530.000 (03-2022)</Typography>
                              </Grid>
                           </Grid>
                           <Grid container item>
                              <Grid item xs={6}>
                                 <Typography variant="caption" display="block" mt={1} >Ventas Total Periodo</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                 <Typography variant="caption" display="block" mr={1} mt={1} >: $ 315.600.960 (2022)</Typography>
                              </Grid>
                           </Grid>
                           <Grid container item >
                              <Grid item xs={6}>
                                 <Typography variant="caption" display="block" mt={1} >% Participacion Ventas Empresa</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                 <Typography variant="caption" display="block" mt={1} >: 77,17 % (03-2022)</Typography>
                              </Grid>
                           </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                           <Grid item xs={12}>
                              <Typography variant="subtitle2" display="block" mt={3} p={1} className='subtitle_gray'>Información de Credito y Cobranzas</Typography>
                           </Grid>
                           <Grid container item>
                              <Grid item xs={6}>
                                 <Typography variant="caption" display="block" mt={1} >Facturas Impagas</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                 <Typography variant="caption" display="block" mr={1} mt={1} >: 4</Typography>
                              </Grid>
                           </Grid>
                           <Grid container item>
                              <Grid item xs={6}>
                                 <Typography variant="caption" display="block" mt={1} >$ Facturas Impagas</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                 <Typography variant="caption" display="block" mr={1} mt={1} >: $ 315.662.364</Typography>
                              </Grid>
                           </Grid>
                           <Grid container item >
                              <Grid item xs={6}>
                                 <Typography variant="caption" display="block" mt={1} >Cheques en Cartera</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                 <Typography variant="caption" display="block" mt={1} >: 8</Typography>
                              </Grid>
                           </Grid>
                           <Grid container item >
                              <Grid item xs={6}>
                                 <Typography variant="caption" display="block" mt={1} >$ Cheques en Cartera</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                 <Typography variant="caption" display="block" mt={1} >: $ 107.980</Typography>
                              </Grid>
                           </Grid>
                           <Grid container item >
                              <Grid item xs={6} >
                                 <Typography variant="caption" align='right' display="block" mr={1} mt={1} >Total de Crédito </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                 <Typography variant="caption" display="block" mt={1} >: $ 107.980</Typography>
                              </Grid>
                           </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                           <Grid item xs={12}>
                              <Typography variant="subtitle2" display="block" mt={3} p={1} className='subtitle_gray'>Asignar Linea de Credito</Typography>
                           </Grid>
                           <Grid container item>
                              <Grid item xs={6}>
                                 <Typography variant="caption" display="block" mt={1} >Linea de Credito Actualizada</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                 <Typography variant="caption" display="block" mr={1} mt={1} >: 5800000000</Typography>
                              </Grid>
                           </Grid>
                           <Grid container item>
                              <Grid item xs={6}>
                                 <Typography variant="caption" display="block" mt={1} >Fecha de Vencimiento</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                 <Typography variant="caption" display="block" mr={1} mt={1} >: 0000-00-00</Typography>
                              </Grid>
                           </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>

                        </Grid>
                     </Grid>
                  </Grid>
                  <Grid item container xs={12} justifyContent='center' mt={3} mb={1}>
                     <Button
                        type='submit'
                        variant='contained'
                        // onClick={handleSubmit}
                        // disabled={loading}
                        disableElevation
                        className='btn_next'
                        style={{ backgroundColor: '#ff9700' }}
                     >
                        Actualizar Líne de Crédito
                     </Button>
                  </Grid>
               </Grid>}

            </Grid>
         </Container>
         <Modal
            open={openProvider}
            onClose={handleCloseProvider}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataModalCustomer entity={undefined} />
            </Box>
         </Modal>
      </Protected>
   );
};

