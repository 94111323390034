import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container } from "@mui/material";
import { useState } from "react";
import { Props } from "../ElectronicSaleDocuments.type";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

import { Link } from "react-router-dom";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { ROUTE_EV_DE_BE_TICKET_ELECTRONIC } from "@/toolbox/constants/route-map";

export const ElectronicTicket: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const style = {
      width: "100%",
      // maxWidth: 500,
      bgcolor: "background.paper",
      color: "#000",
      borderRadius: "10px",
   };
   const disable = {
      bgcolor: "#EEEEEB",
   };
   const StyleIcon = {
      color: "rgb(228, 152, 13)",
   };

   return (
      <>
         <List sx={style} component="nav" aria-label="mailbox folders">
            <ListItem
               button
               component={Link}
               to={ROUTE_EV_DE_BE_TICKET_ELECTRONIC}
            >
               <ListItemAvatar>
                  <AssignmentIcon sx={{ color: "rgb(228, 152, 13)" }} />
               </ListItemAvatar>
               <ListItemText primary="BOLETA ELECTRÓNICA" />
            </ListItem>
            <Divider />
            <ListItem button component={Link} to={"#"}>
               <ListItemAvatar>
                  <AssignmentIcon sx={{ color: "rgb(228, 152, 13)" }} />
               </ListItemAvatar>
               <ListItemText primary="BOLETA EXENTA ELECTRÓNICA" />
            </ListItem>
            <Divider />
            <ListItem button component={Link} to={"#"}>
               <ListItemAvatar>
                  <AssignmentIcon sx={{ color: "rgb(228, 152, 13)" }} />
               </ListItemAvatar>
               <ListItemText primary="BOLETA ELECTRÓNICA PV" />
            </ListItem>
         </List>
      </>
   );
};
