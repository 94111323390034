import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, InputAdornment, FormControl, FormControlLabel, Checkbox, } from '@mui/material'
import { useState, useRef } from "react";
import { Props } from "../MonthAndPeriod.type";
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import NumbersIcon from '@mui/icons-material/Numbers';
import TableContainer from '@mui/material/TableContainer';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TableRow from '@mui/material/TableRow';
import '../MonthAndPeriod_style.css'
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { AddCircle } from "@mui/icons-material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Link from '@mui/material/Link';

//Tabla 01
interface Column {
   id: 'fecha' | 'accion' | 'estado';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: 'fecha', label: 'Fecha', minWidth: 100 },
   { id: 'accion', label: 'Accion', minWidth: 100 },
   { id: 'estado', label: 'Estado', minWidth: 100 }
];

interface Data {
   fecha: string;
   accion: string;
   estado: string;
}

function createData(
   fecha: string,
   accion: string,
   estado: string
): Data {
   return { fecha, accion, estado };
}

const rowsData = [
   createData('-', 'Cantidad de veces notificado', '-'),
   createData('-', 'Fecha envío Sll', 'En proceso'),
   createData('-', 'DTE Despachado desde Email, casilla', 'En proceso')
]

//Segunda tabla
interface Column2 {
   id: 'registro' | 'validez' | 'respuesta' | 'estado';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns2: readonly Column2[] = [
   { id: 'registro', label: 'Registro', minWidth: 100 },
   { id: 'validez', label: 'Validex', minWidth: 100 },
   { id: 'respuesta', label: 'Respuesta', minWidth: 100 },
   { id: 'estado', label: 'Estado', minWidth: 100 }
];

interface Data2 {
   registro: string;
   validez: string;
   respuesta: string;
   estado: string;
}

function createData2(
   registro: string,
   validez: string,
   respuesta: string,
   estado: string
): Data2 {
   return { registro, validez, respuesta, estado };
}

const rowsData2 = [
   createData2('-', 'Validez tributaria del documento(debe decir ok) :', 'Boleta OK', 'OK'),
   createData2('Registro Compra y Venta', 'Fecha envío Sll', '-', 'Boleta OK')
]


export const DataModalStateSll: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [ejecutivoComercial, setEjecutivoComercial] = useState('');
   const [estado, setEstado] = useState('');
   const inputRefs = useRef<Array<InputRef | null>>([]);


   const handleChangeEjecutivoComercial = (event: SelectChangeEvent) => {
      setEjecutivoComercial(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   return (
      <Container maxWidth='xl'>
         <Grid container spacing={3} mt={1} mb={2}>
            <Grid container item xs={12}>
               <Grid item xs={10}>
                  <Typography variant="h6" id="modal-modal-title" className='tittle'>
                     Estado Tracking: Tipo: 39 - Folio: 284234
                  </Typography>
               </Grid>
               <Grid item xs={2} textAlign="end">
                  <AssignmentIcon style={{ color: '#FF9700' }} />
               </Grid>
            </Grid>
            <Grid container m={1} xs={12} display='block' textAlign='right'>
               <Typography variant='subtitle2'>
                  Envio SII
               </Typography>
               <Typography variant='subtitle2'>
                  Envio DTE receptor
               </Typography>
               <Typography variant='subtitle2'>
                  Descarga xml firmado
               </Typography>
               <Typography variant='subtitle2'>
                  Descarga xml Adicional
               </Typography>
               <Typography variant='subtitle2'>
                  Envio SII para Cesión
               </Typography>
            </Grid>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
               <TableContainer sx={{ maxHeight: 420 }}>
                  <Table size="small" stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           <TableCell>Fecha</TableCell>
                           <TableCell>Acción</TableCell>
                           <TableCell>Estado</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.fecha}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                              </TableRow>
                           );
                        })}
                     </TableBody>
                  </Table>
               </TableContainer>
            </Paper>
            <Grid item xs={10}>
               <Typography variant="h6" id="modal-modal-title" className='tittle'>
                  Sll
               </Typography>

            </Grid>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
               <TableContainer sx={{ maxHeight: 420 }}>
                  <Table size="small" stickyHeader aria-label="sticky table">
                     <TableBody>
                        {rowsData2.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.registro}>
                                 {columns2.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                              </TableRow>
                           );
                        })}
                     </TableBody>
                  </Table>
               </TableContainer>
            </Paper>
         </Grid>
      </Container >
   );
};

