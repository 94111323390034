import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "./PurchasesByDates.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './PurchasesByDates_styles.css'
import Modal from '@mui/material/Modal';
import { DataAbsence } from './TypeData/DataAbsence'
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import { DataTardie } from "./TypeData/DataTardie";
import { ModalInforms } from "./Modal/ModalInforms";


interface Column {
   id: 'numero' | 'rut' | 'apellidoPaterno' | 'apellidoMaterno'|'nombre';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

const columns:readonly Column[] = [
   { id: 'numero', label: 'N°', minWidth: 10 },
   { id: 'rut', label: 'Rut', minWidth: 10 },
   { id: 'apellidoPaterno', label: 'Apellido Paterno', minWidth: 10 },
   { id: 'apellidoMaterno', label: 'Apellido Materno', minWidth: 10 },
   { id: 'nombre', label: 'Nombre', minWidth: 10 },
   ];



export const PurchasesByDates:React.FC<Props> = (props: Props): JSX.Element => {
   const [value, setValue] = useState<Date | null>(new Date());
   const [openAbsense, setOpenAbsense] = useState(false);
   const [openTardie, setOpenTardie] = useState(false);
 

   const handleOpenAbsense = () => {
      setOpenAbsense(true);
      setOpenTardie(false);
   }
   const handleCloseAbsense = () => setOpenAbsense(false);

   const handleOpenTardie = () => {
      setOpenTardie(true);
      setOpenAbsense(false);
   }
   const handleCloseTardie = () => setOpenTardie(false);

  
    return (
        <Protected>
        <Container maxWidth='xl'>
           <Grid container spacing={3} mb={6}>
               <Grid item xs={12} md={12} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        COMPRAS ENTRE FECHAS
                     </Typography>
                  </Box>
                  
               </Grid>
               <Grid item xs={12} sm={6}>
                  <Grid container spacing={3}>
                     <Grid item xs={12}>
                        <Typography variant='subtitle2'  gutterBottom  align="center" style={{background:'#ff9700', color:'#ffffff', borderRadius:'5px'}}  p={1}>
                           OPCION 1 - MES Y PERIODO
                        </Typography>
                     </Grid>
                     
                     <Grid item xs={12} md={8}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                           <Stack spacing={3}>
                              <DatePicker
                                    views={['year', 'month']}
                                    label="Periodo y Mes"
                                    minDate={new Date('2012-03-01')}
                                    maxDate={new Date('2023-06-01')}
                                    value={value}
                                    onChange={(newValue) => {
                                       setValue(newValue);
                                    }}
                                    renderInput={(params) => <TextField  {...params} helperText={null} />}
                                    />
                           </Stack>
                        </LocalizationProvider>
                     </Grid>
                     
                     <Grid item xs={12} md={4}>
                        <Button
                           type='submit'
                           variant='contained'
                           onClick={handleOpenAbsense}
                           // disabled={loading}
                           disableElevation
                           className='btn_next'
                           style={{backgroundColor:'#16556b', color:'#fff'}}
                           >
                           <span>CONSULTAR</span>
                        </Button>
                     </Grid>
                  </Grid>
               </Grid>
               <Grid item xs={12} sm={6}>
                  <Grid container spacing={3}>
                     <Grid item xs={12}>
                        <Typography variant='subtitle2'  gutterBottom  align="center" style={{background:'#ff9700', color:'#ffffff', borderRadius:'5px'}}  p={1}>
                           OPCION 2 - SELECCION DE FECHAS
                        </Typography>
                     </Grid>
                     
                     <Grid item xs={12} md={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                           <DatePicker
                           label="Fecha Inicio"
                           value={value}
                           onChange={(newValue) => {
                              setValue(newValue);
                           }}
                           renderInput={(params) => <TextField {...params} />}
                           />
                        </LocalizationProvider>
                     </Grid>
                     <Grid item xs={12} md={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                           <DatePicker
                           label="Fecha Final"
                           value={value}
                           onChange={(newValue) => {
                              setValue(newValue);
                           }}
                           renderInput={(params) => <TextField {...params} />}
                           />
                        </LocalizationProvider>
                     </Grid>
                     
                     <Grid item xs={12} md={4}>
                        <Button
                           type='submit'
                           variant='contained'
                           onClick={handleOpenTardie}
                           // disabled={loading}
                           disableElevation
                           className='btn_next'
                           style={{backgroundColor:'#16556b', color:'#fff'}}
                           >
                           <span>CONTINUAR</span>
                        </Button>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>

            {openAbsense&&<DataAbsence entity={undefined} />||""}
               
            {openTardie && <DataTardie 
            entity={undefined}
            />||""}

         </Container>

         
     </Protected>
    );
   };

