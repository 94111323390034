import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, InputAdornment, FormControl, FormControlLabel, Checkbox, RadioGroup, FormLabel, Radio, } from '@mui/material'
import { useState, useRef } from "react";
import { Props } from "../MonthAndPeriod.type";
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import AssignmentIcon from '@mui/icons-material/Assignment';
import '../MonthAndPeriod_style.css'

interface Column {
   id: 'codigo' | 'fecha' | 'tipo' | 'folio' | 'rut' | 'razon' | 'valorTotal';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: 'codigo', label: 'Codigo', minWidth: 100 },
   { id: 'fecha', label: 'Fecha', minWidth: 100 },
   { id: 'tipo', label: 'Tipo', minWidth: 100 },
   { id: 'folio', label: 'Folio', minWidth: 100 },
   { id: 'rut', label: 'Rut', minWidth: 100 },
   { id: 'razon', label: 'Razon', minWidth: 100 },
   { id: 'valorTotal', label: 'Valor Total', minWidth: 100 }
];

interface Data {
   codigo: string;
   fecha: string;
   tipo: string;
   folio: string;
   rut: string;
   razon: string;
   valorTotal: string;
}

function createDataEnviador(
   codigo: string,
   fecha: string,
   tipo: string,
   folio: string,
   rut: string,
   razon: string,
   valorTotal: string
): Data {
   return { codigo, fecha, tipo, folio, rut, razon, valorTotal };
}

const rowsDataEnviador = [
   createDataEnviador('1', '2022-03-01', '33', '5042', '22222122-2', 'A.C. BADIACHILE', '$ 238.000'),
   createDataEnviador('2', '2022-03-01', '33', '5042', '222211122-2', 'A.C. BADIACHILE', '$ 238.000'),
   createDataEnviador('3', '2022-03-02', '33', '5042', '22222122-2', 'A.C. BADIACHILE', '$ 238.000'),
   createDataEnviador('4', '2022-02-26', '33', '5042', '22222122-2', 'A.C. BADIACHILE', '$ 111.000'),
   createDataEnviador('5', '2022-02-27', '33', '5042', '22222122-2', 'A.C. BADIACHILE', '$ 238.000'),
   createDataEnviador('6', '2022-02-25', '33', '5042', '22222122-2', 'A.C. BADIACHILE', '$ 768.000'),
]


export const DataModalEmail: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [ejecutivoComercial, setEjecutivoComercial] = useState('');
   const [estado, setEstado] = useState('');
   const inputRefs = useRef<Array<InputRef | null>>([]);


   const handleChangeEjecutivoComercial = (event: SelectChangeEvent) => {
      setEjecutivoComercial(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   return (
      <Container maxWidth='xl'>
         <form style={{ margin: '8px' }}>
            <Grid container spacing={3} className={`e-mb-lg`} >
               <Grid container item xs={12} mt={1} mb={1}>
                  <Grid item xs={10}>
                     <Typography variant="h6" id="modal-modal-title" className='tittle'>
                        Datos del receptor
                     </Typography>
                  </Grid>
                  <Grid item xs={2} textAlign="end">
                     <AssignmentIcon style={{ color: '#FF9700' }} />
                  </Grid>
               </Grid>
               <Grid item xs={12}>
                  <FormControl>
                     <FormLabel id="demo-radio-buttons-group-label">Tipo Documento</FormLabel>
                     <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                     >
                        <FormControlLabel value="female" control={<Radio />} label="Original" />
                        <FormControlLabel value="male" control={<Radio />} label="Cedible" />
                        <FormControlLabel control={<Checkbox defaultChecked />} label="XML Intercambio" />
                     </RadioGroup>
                  </FormControl>
               </Grid>

               <Grid item xs={12}>
                  <Typography variant="h6" id="modal-modal-title" mb={2} className='tittle'>
                     Contactos
                  </Typography>
               </Grid>
               <Grid item xs={12} sm={4}>
                  <Input
                     ref={ref => inputRefs.current[2] = ref}
                     name="valor_compra"
                     placeholder="CC 1"
                     disableElevation
                     validateOnBlur
                     dense
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={4}>
                  <Input
                     ref={ref => inputRefs.current[2] = ref}
                     name="valor_compra"
                     placeholder="CC 2"
                     disableElevation
                     validateOnBlur
                     dense
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={4}>
                  <Input
                     ref={ref => inputRefs.current[2] = ref}
                     name="valor_compra"
                     placeholder="CC 3"
                     disableElevation
                     validateOnBlur
                     dense
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={6}>
                  <Input
                     ref={ref => inputRefs.current[2] = ref}
                     name="valor_compra"
                     placeholder="CC Usuario(ejm. desarrollo@sofnet.cl)"
                     disableElevation
                     validateOnBlur
                     dense
                     className='borderInput'
                  />
               </Grid>


               <Grid item container
                  xs={12}
                  justifyContent='center'
                  m={2}
               >
                  <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        // onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                        className='btn_save'
                     >
                        <span>Enviar Email</span>
                     </Button>

                  </Grid>
               </Grid>
            </Grid>
         </form>
      </Container>
   );
};

