import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, SelectChangeEvent, } from '@mui/material';
import { Props } from './FormProvider.type'
import { InputRef } from '@components/common/Input/InputInterfaces';
import './style.sass'
import "./FormProvider_style.css";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
// import '../Advances_styles.css'
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import IconButton from '@mui/material/IconButton';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';


export const FormProvider: React.FC<Props> = (
   props: Props
): JSX.Element => {
   
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value2, setValue2] = useState('');
   const [loading, setLoading] = useState(false);
   const [familyProduct, setFamilyProduct] = useState('');
   const [estado, setEstado]= useState('');
   const [value, setValue] = useState<Date | null>(new Date());

    const handleChangeFamilyProduct = (event: SelectChangeEvent) => {
      setFamilyProduct(event.target.value);
    };
    const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
    };
   function handleClick() {
     setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      props.handleNext();
   }

   interface Column {
      id:  'rut' | 'razonSocial' | 'cantidadDoc'|'monto';
      label: string;
      minWidth?: number;
      align?: 'right';
      format?: (value: number) => string;
    }
   
   const columns:readonly Column[] = [
      { id: 'rut', label: 'Rut', minWidth: 100 },
      { id: 'razonSocial', label: 'Razon Social', minWidth: 100 },
      { id: 'cantidadDoc', label: 'Cantidad Doc', minWidth: 100 },
      { id: 'monto', label: 'Monto', minWidth: 100 },
      ];
   
   interface Data {
      rut: string;
      razonSocial: string;
      cantidadDoc: string;
      monto: string;
   }
   
   function createData(
      rut: string,
      razonSocial: string,
      cantidadDoc: string,
      monto: string,
      ):Data {
      return { rut, razonSocial, cantidadDoc, monto };
   }
   
   const rowsData = [
      createData('14656345-2', 'Razon Social 1', '1', '$1000041'),
      createData('14656345-2', 'Razon Social 2', '2', '$1000042'),
      createData('14656345-2', 'Razon Social 3', '3', '$1000043'),
      createData('14656345-2', 'Razon Social 4', '4', '$1000044'),
      createData('14656345-2', 'Razon Social 5', '5', '$1000045'),
      createData('14656345-2', 'Razon Social 6', '6', '$1000046'),
      createData('14656345-2', 'Razon Social 7', '7', '$1000047'),
      createData('14656345-2', 'Razon Social 8', '8', '$1000048')
   ]
   
   return (
      <Container maxWidth="xl">

            <Grid container spacing={3} className={`e-mb-lg`} mb={3} mt={3}>
            
               <Grid item xs={6} md={2} mb={-2}>
                  <Box>
                     <Typography variant='subtitle2'  gutterBottom>
                        Nº DE TRABAJADORES
                     </Typography>
                  </Box>
               </Grid>

               <Grid item xs={6} md={8} mb={-2}>
                  <Box>
                     <Typography variant='caption'  gutterBottom>
                           34
                     </Typography>
                  </Box> 
               </Grid>
               <Grid item xs={12}  md={2} mb={-2} textAlign="right">
                     <Button style={{backgroundColor:'#FF9700', color:'#ffffff'}} className="btn-download">
                        Exportar
                     </Button>
               </Grid>

               <Grid item xs={6} md={2} mb={-2}>
                  <Box>
                     <Typography variant='subtitle2'  gutterBottom>
                        TOTAL DE DOCUMENTOS
                     </Typography>
                  </Box>
               </Grid>

               <Grid item xs={6} md={9} mb={-2}>
                  <Box>
                     <Typography variant='caption'  gutterBottom>
                              140
                     </Typography>
                  </Box> 
               </Grid>

               <Grid item xs={6} md={2} mb={-2}>
                  <Box>
                     <Typography variant='subtitle2'  gutterBottom>
                        SUMA DE DEUDAS
                     </Typography>
                  </Box>
               </Grid>

               <Grid item xs={6} md={9}  mb={-2}>
                  <Box>
                     <Typography variant='caption'  gutterBottom>
                              $ 1.195.718.413
                     </Typography>
                  </Box> 
               </Grid>
               <Grid item xs={12}   mb={-1}>

               <div className='bg-tittle'>
                  <Typography
                     mt={3}
                     variant="h6"
                     align='center'
                     className='tittle-text'
                  >LISTADO DE TRABAJADORES CON DOCUMENTOS IMPAGOS
               </Typography>
               </div>
               </Grid>
               

            </Grid>

            <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:250}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                                 {column.label}
                              </TableCell>
                           ))}
                           <TableCell>
                              Accion
                           </TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.rut}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                    <IconButton aria-label="next" >
                                       <ArrowCircleRightIcon className='icon' onClick={handleSubmit}/>
                                    </IconButton>
                                 </TableCell>
                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
            </Paper>
      </Container>   

   );
}
