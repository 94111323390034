import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, Tooltip, TableFooter, } from '@mui/material'
import { useState } from "react";
import { Props } from "../BooksExportSalesNotes.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import TableContainer from '@mui/material/TableContainer';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import EventNoteIcon from '@mui/icons-material/EventNote';
import TableHead from '@mui/material/TableHead';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import HistoryIcon from '@mui/icons-material/History';
import EmailIcon from '@mui/icons-material/Email';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import WorkIcon from '@mui/icons-material/Work';
import '../BooksExportSalesNotes_styles.css'
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import Modal from '@mui/material/Modal';
import { DataModalSendEmail } from './Modals/DataModalSendEmail';
import { DataModalHistorySend } from './Modals/DataModalHistorySend';
import { DataModalChangeState } from './Modals/DataModalChangeState';


interface Column {
   id: 'tipoDocumento' | 'cantidad' | 'exento' | 'especifico' | 'valorNeto' | 'iva' | 'total';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: string) => string;
}

interface Column1 {
   id: 'tipo' | 'fecha' | 'folio' | 'rut' | 'razonSocial' | 'total' | 'vendedor' | 'estado';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value2: string) => string;
}


const columns: readonly Column[] = [
   { id: 'tipoDocumento', label: 'Tipo Documento', minWidth: 10 },
   { id: 'cantidad', label: 'Cantidad', minWidth: 10 },
   { id: 'exento', label: 'Exento', minWidth: 10 },
   { id: 'especifico', label: 'Valor Neto', minWidth: 10 },
   { id: 'valorNeto', label: 'IVA Retenido', minWidth: 10 },
   { id: 'iva', label: 'IVA', minWidth: 10 },
   { id: 'total', label: 'Total', minWidth: 10 },
];

const columns1: readonly Column1[] = [
   { id: 'tipo', label: 'Tipo', minWidth: 10 },
   { id: 'fecha', label: 'Fecha', minWidth: 10 },
   { id: 'folio', label: 'Folio', minWidth: 10 },
   { id: 'rut', label: 'Rut', minWidth: 10 },
   { id: 'razonSocial', label: 'Razón Social', minWidth: 10 },
   { id: 'total', label: 'Total', minWidth: 10 },
   { id: 'vendedor', label: 'Vendedor', minWidth: 10 },
   { id: 'estado', label: 'Estado', minWidth: 10 },
];

interface Data {
   tipoDocumento: string;
   cantidad: string;
   exento: string;
   especifico: string;
   valorNeto: string;
   iva: string;
   total: string;
}

interface Data1 {
   tipo: string;
   fecha: string;
   folio: string;
   rut: string;
   razonSocial: string;
   total: string;
   vendedor: string;
   estado: string;
}

function createData(
   tipoDocumento: string,
   cantidad: string,
   exento: string,
   especifico: string,
   valorNeto: string,
   iva: string,
   total: string,

): Data {
   return { tipoDocumento, cantidad, exento, especifico, valorNeto, iva, total };
}

function createData1(
   tipo: string,
   fecha: string,
   folio: string,
   rut: string,
   razonSocial: string,
   total: string,
   vendedor: string,
   estado: string,
): Data1 {
   return { tipo, fecha, folio, rut, razonSocial, total, vendedor, estado };
}

const rowsData = [
   createData('NOTA DE VENTA EXPORTACION', '0', '$ 0', '$ 0', '$ 0', '$ 0', '$ 0'),
]

const rowsData1 = [
   createData1('780', '03/12/2022', '11245', '1111111-1', 'Razon Social Anonima', '33.997', '162929272-1', 'GENERA FACTURA'),
   createData1('650', '03/12/2022', '11245', '1111111-1', 'Razon Social Anonima 2', '33.997', '162929272-1', 'GENERA BOLETA ELECTRONICA'),
]

export const DataBook: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);

   const [value, setValue] = useState<Date | null>(new Date());
   const [loading, setLoading] = useState(false);

   //State para modal Book
   const [openSendEmail, setOpenSendEmail] = useState(false);
   const handleOpenSendEmail = () => setOpenSendEmail(true);
   const handleCloseSendEmail = () => setOpenSendEmail(false);
   //State para modal HistorySend
   const [openHistorySend, setOpenHistorySend] = useState(false);
   const handleOpenHistorySend = () => setOpenHistorySend(true);
   const handleCloseHistorySend = () => setOpenHistorySend(false);
   //State para modal ChangeState
   const [openChangeState, setOpenChangeState] = useState(false);
   const handleOpenChangeState = () => setOpenChangeState(true);
   const handleCloseChangeState = () => setOpenChangeState(false);

   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 910,
      height: 480,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

   const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
         backgroundColor: '#c6ced1',
         fontSize: 14,
         color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
         fontSize: 14,
      },
   }));
   return (
      <Container maxWidth='xl'>

         <Grid container spacing={3}>
            <Grid item xs={12} md={6} >
               <Box>
                  <Typography variant='h6' component='h1' gutterBottom className="tittle">
                     LIBRO DE NOTAS DE VENTA DE EXPORTACIÓN
                  </Typography>
               </Box>
               <Box>
                  <Typography variant='subtitle2' gutterBottom >
                     Periodo: 2022 | Mes: 04 
                  </Typography>
               </Box>

            </Grid>
         </Grid>


         <div style={{ background: '#16556b', borderRadius: '5px', color: '#ffffff', }} >
            <Typography
               mt={2}
               mb={2}
               style={{ padding: '3px' }}
               variant="subtitle2"
               align='center'
            >Resumen de Documentación
            </Typography>
         </div>

         <Grid item xs={12}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
               <TableContainer sx={{ maxHeight: 220 }}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           {columns.map((column) => (
                              <StyledTableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{ minWidth: column.minWidth }}
                              >
                                 {column.label}
                              </StyledTableCell>
                           ))}
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.tipoDocumento}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'string'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}

                              </TableRow>
                           );
                        })}

                     </TableBody>
                     <TableFooter>
                        <TableCell align='right'>Totales</TableCell>
                        <TableCell>0</TableCell>
                        <TableCell>$0</TableCell>
                        <TableCell>$0</TableCell>
                        <TableCell>$0</TableCell>
                        <TableCell>$0</TableCell>
                        <TableCell>$0</TableCell>
                     </TableFooter>
                  </Table>
               </TableContainer>

            </Paper>
         </Grid>

         <Grid item xs={12} mt={4}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }} >
               <TableContainer sx={{ maxHeight: 700 }} >
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           {columns1.map((column) => (
                              <StyledTableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{ minWidth: column.minWidth }}
                              >
                                 {column.label}
                              </StyledTableCell>

                           ))}
                           <StyledTableCell>Opcion</StyledTableCell>

                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData1.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.tipo}>
                                 {columns1.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'string'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                    <Grid>
                                       <Tooltip title={"Enviar Email"} placement="bottom-start">
                                          <IconButton
                                             aria-label="Editar"
                                             onClick={() => handleOpenSendEmail()}
                                             className='btn_delete'
                                          // disabled={loading}
                                          >                                             
                                             <EmailIcon />
                                          </IconButton>
                                       </Tooltip>
                                       <Tooltip title={"Historial de envíos"} placement="bottom-start">
                                          <IconButton
                                             aria-label="Editar"
                                             onClick={() => handleOpenHistorySend()}
                                             className='btn_delete'
                                          // disabled={loading}
                                          >
                                             <HistoryIcon />
                                          </IconButton>
                                       </Tooltip>
                                       <Tooltip title={"Cambiar Estado"} placement="bottom-start">
                                          <IconButton
                                             aria-label="Editar"
                                             onClick={() => handleOpenChangeState()}
                                             className='btn_delete'
                                          // disabled={loading}
                                          >
                                             <ChangeCircleIcon />
                                          </IconButton>
                                       </Tooltip>


                                       
                                    </Grid>
                                 </TableCell>

                              </TableRow>
                           );
                        })}

                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>

         </Grid>
         <Modal
            open={openSendEmail}
            onClose={handleCloseSendEmail}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataModalSendEmail entity={undefined} />
            </Box>
         </Modal>
         <Modal
            open={openHistorySend}
            onClose={handleCloseHistorySend}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataModalHistorySend entity={undefined} />
            </Box>
         </Modal>
         <Modal
            open={openChangeState}
            onClose={handleCloseChangeState}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataModalChangeState entity={undefined} />
            </Box>
         </Modal>
         
        

      </Container>

   );
}