import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, InputAdornment, FormControl, FormControlLabel, Checkbox, Stack, FormGroup, RadioGroup, } from '@mui/material'
import React, { useState, useRef } from "react";
import { Props } from "../DataBooks.type"
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import EmailIcon from '@mui/icons-material/Email';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TableBody from '@mui/material/TableBody';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TableCell from '@mui/material/TableCell';
import NumbersIcon from '@mui/icons-material/Numbers';
import TableContainer from '@mui/material/TableContainer';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PhoneIcon from '@mui/icons-material/Phone';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import AssignmentIcon from '@mui/icons-material/Assignment';
import NextWeekIcon from '@mui/icons-material/NextWeek';
import TableRow from '@mui/material/TableRow';
import Radio from '@mui/material/Radio';
// import '../Workers.css'
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import { DeleteIcon } from "@/toolbox/constants/icons";

interface Column {
   id: 'folio' | 'documento' | 'fecha' | 'monto' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: 'folio', label: 'Folio', minWidth: 10 },
   { id: 'documento', label: 'Documento', minWidth: 50 },
   { id: 'fecha', label: 'Fecha', minWidth: 50 },
   { id: 'monto', label: 'Monto', minWidth: 100 },   
];

interface Data {
   folio: number;
   documento: string;
   fecha: string;
   monto: string;
}

function createDataHistorial(
   folio: number,
   documento: string,
   fecha: string,
   monto: string,
): Data {
   return { folio, documento, fecha, monto };
}

const rowsData = [
   createDataHistorial(897, 'COTIZACIONES', '2022-04-05', '33.506' ),
   createDataHistorial(7570, 'FACTURA ELECTRONICA', '2022-04-05', '33.506'),
]
export const DataModalSalesNote: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [ejecutivoComercial, setEjecutivoComercial] = useState('');
   const [estado, setEstado] = useState('');
   // const [data, setData] = React.useState<Date | null>(new Date());
   const [data, setData] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const inputRefs = useRef<Array<InputRef | null>>([]);

   //radio buttoms
   const [valueRadio, setValueRadio] = React.useState('female');

   const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueRadio((event.target as HTMLInputElement).value);
   };

   const handleChangeEjecutivoComercial = (event: SelectChangeEvent) => {
      setEjecutivoComercial(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const estadoValue = [
      { label: 'ACTIVADO', value: 'activado' },
      { label: 'DESACTIVADO', value: 'desactivado' }
   ]
   const parentescoValue = [
      { label: 'Esposa' },
      { label: 'Esposo' },
      { label: 'Hija' },
      { label: 'Hijo' },
      { label: 'Padre' },
      { label: 'Madre' },
      { label: 'Otro' }
   ]
   const contacto = [
      { label: 'Jorge Roberto' }
   ]
   const cargaValue = [
      { label: 'Simple' },
      { label: 'Duplo' },
      { label: 'Maternal' }
   ]

   return (
      <Container maxWidth='xl'>
         <Grid container spacing={3} mt={2} >
            <Grid item container mb={2}>
               <Grid item xs={10}>
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        NOTA DE VENTA Nº 6608
                     </Typography>
                  </Box>
                  <Box>
                     <Typography variant="caption" id="modal-modal-title" mb={2}>
                     Referencia de Nota de venta
                     </Typography>
                  </Box>

               </Grid>
               <Grid item xs={2} textAlign="end">
                  <AssignmentIcon style={{ color: '#FF9700' }} />
               </Grid>
            </Grid>
            <Grid item xs={12}>
               <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  <TableContainer sx={{ maxHeight: 440 }}>
                     <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                           <TableRow>
                              {columns.map((column) => (
                                 <TableCell

                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                 >
                                    {column.label}
                                 </TableCell>
                              ))}
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                              return (
                                 <TableRow hover role="checkbox" tabIndex={-1} key={row.folio}>
                                    {columns.map((column) => {
                                       const value = row[column.id];
                                       return (
                                          <TableCell key={column.id} align={column.align}>
                                             {column.format && typeof value === 'number'
                                                ? column.format(value)
                                                : value}
                                          </TableCell>
                                       );
                                    })}

                                 </TableRow>
                              );
                           })}

                        </TableBody>
                     </Table>
                  </TableContainer>
                  <TablePagination
                     rowsPerPageOptions={[10, 25, 100]}
                     component="div"
                     count={rowsData.length}
                     rowsPerPage={rowsPerPage}
                     page={page}
                     onPageChange={handleChangePage}
                     onRowsPerPageChange={handleChangeRowsPerPage}
                  />
               </Paper>
            </Grid>
         </Grid>
      </Container >
   );
};

