import React, { useState, useEffect } from 'react';
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Protected } from "@/components/layout/Protected";
import { FormControl, Grid, Stack, Typography, Button, Input, Tooltip, Autocomplete, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import InputLabel from '@mui/material/InputLabel';
import "./CuotesMedia.sass";
// import "./Home-styles.css";
import "./CuotesMedia-styles.css"
import { TableData } from "@/components/common/Table/Table";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';
import SearchIcon from '@mui/icons-material/Search';
import { DateTimePicker } from '@mui/lab';
// import { DataProductos } from './Modal/DataProductos';
// import {  DataRut } from './Modal/DataRut';
// import { DataInforme } from './Modal/DataInforme';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ROUTE_EV_LS_CONFIRM_EMITION, ROUTE_EV_LS_QUOTE_BOOK_RESUME } from "@/toolbox/constants/route-map";
import { DataRut } from './Modal/DataRut';
// import { DataAgregar } from './Modal/DataAgregar';
// import { DataEditarCotizacion } from './Modal/DataEditarCotizacion';


// interface Column {
//    id: 'numero' | 'codigo' | 'detalle'|'cantidad' | 'valor'|'unidad'| 'descuento'|'subtotal' ;
//    label: string;
//    minWidth?: number;
//    align?: 'right';
//    format?: (value: number) => string;
//  }
//  interface Column1 {
//    id: 'codigo' | 'detalle' | 'cantidad'|'valor' | 'tipo'| 'valorDescuento'|'total' ;
//    label: string;
//    minWidth?: number;
//    align?: 'right';
//    format?: (value: number) => string;
//  }

// const columns:readonly Column[] = [
//    { id: 'numero', label: 'N°', minWidth: 10 },
//    { id: 'codigo', label: 'Código', minWidth: 10 },
//    { id: 'detalle', label: 'Detalle', minWidth: 10 },
//    { id: 'cantidad', label: 'Cantidad', minWidth: 10 },
//    { id: 'valor', label: 'Valor Neto', minWidth: 10 },
//    { id: 'unidad', label: 'Unidad Medina', minWidth: 10 },
//    { id: 'descuento', label: 'Descuento', minWidth: 10 },
//    { id: 'subtotal', label: 'Subtotal', minWidth: 10 },
//    ];
// const columns1:readonly Column1[] = [
//    { id: 'codigo', label: 'Código', minWidth: 10 },
//    { id: 'detalle', label: 'Detalle', minWidth: 50 },
//    { id: 'cantidad', label: 'Cantidad', minWidth: 5 },
//    { id: 'valor', label: 'Valor Descuento', minWidth: 10 },
//    { id: 'tipo', label: 'Tipo', minWidth: 10 },
//    { id: 'valorDescuento', label: 'Valor Descuento', minWidth: 10 },
//    { id: 'total', label: 'Total', minWidth: 10 },
//    ];
// interface Data {
//    numero: number;
//    codigo: string;
//    detalle: string;
//    cantidad: number;
//    valor: number;
//    tipo: string;
//    valorDescuento: number;
//    total: number;
//    unidad: string;
//    descuento: number;
//    subtotal: number;
// }

// function createData(
//    numero: number,
//    codigo: string,
//    detalle: string,
//    cantidad: number,
//    valor: number,
//    tipo: string,
//    valorDescuento: number,
//    total: number,
//    ):Data {
//    return { numero, codigo, detalle, cantidad, valor, tipo, valorDescuento, total, unidad: '', descuento: 0, subtotal: 0 };
// }

// const rowsData = [
//    createData(1, '123', 'Prueba 1', 10, 5, 'C/U Serv', 0, 1000),
//    createData(2, '123', 'Prueba 2', 10, 5, 'C/U Serv', 0, 1000),
//    createData(3, '123', 'Prueba 3', 10, 5, 'C/U Serv', 0, 1000),
//    createData(4, '123', 'Prueba 4', 10, 5, 'C/U Serv', 0, 1000),
//    createData(5, '123', 'Prueba 5', 10, 5, 'C/U Serv', 0, 1000),
// ]

// const tipos =[
//    {label: '%', value: '%'},
//    {label: '$', value: '$'},
// ]

export const CuotesMedia: FunctionComponent = (props: any) => {
   const [value, setValue] = useState<Date | null>(new Date());
   const [selectTypeResume, setSelectTypeResume] = useState('');
   const [data, setData] = React.useState<Date | null>(new Date());
   const [valueCalendar, setValueCalendar] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

   //modal Rit
   const [openRut, setOpenRut] = React.useState(false);
   const handleOpenRut = () => setOpenRut(true);
   const handleCloseRut = () => setOpenRut(false);

   //modal articulos
   const [openArticulo, setOpenArticulo] = useState(false);
   const handleOpenArticulo = () => setOpenArticulo(true);
   const handleCloseArticulo = () => setOpenArticulo(false);

   //modal Informe
   const [openInforme, setOpenInforme] = React.useState(false);
   const handleOpenInforme = () => setOpenInforme(true);
   const handleCloseInforme = () => setOpenInforme(false);

   const [cambiarEstado, setCambiarEstado] = useState(false);
   const handleCambiarEstado = () => setCambiarEstado(true);

   const [openAgregar, setOpenAgregar] = useState(false);
   const handleOpenAgregar = () => setOpenAgregar(true);
   const handleCloseAgregar = () => setOpenAgregar(false);

   const [openEditar, setOpenEditar] = useState(false);
   const handleOpenEditar = () => setOpenEditar(true);
   const handleCloseEditar = () => setOpenEditar(false);

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 870,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 4,
      borderRadius: '20px'
   };

   const handleChange = (event: any) => {
      setValue(event.target.value);
   };
   const handleChangeTypeResume = (event: SelectChangeEvent) => {
      setSelectTypeResume(event.target.value);
   };

   const [page, setPage] = React.useState(0);
   const [rowsPerPage, setRowsPerPage] = React.useState(10);

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };


   const documentos = [
      { label: 'Documento 1' },
      { label: 'Documento 2' },
      { label: 'Documento 3' },
      { label: 'OTROS' }
   ]
   const areaNegocio = [
      { label: 'Categorizada' },
      { label: 'DESARROLLO' },
      { label: 'INSUMOS' },
      { label: 'OTROS' }
   ]
   const contactoValue = [
      { label: 'Santiago Ormeño' },
      { label: 'Gianluca Quispedula' },
      { label: 'Francisco Mamani' },
      { label: 'Pedrito Alcachofas' },
      { label: 'La tía Mey' }
   ]
   return (
      <Protected>
         <div className='Generator' >
            <Grid container spacing={2}>
               <Grid item xs={12} md={12} >
                  <Box>
                     <Typography variant='h6' component='h1' sx={{ textTransform: 'uppercase' }} gutterBottom className="tittle">
                        INGRESO DE NOTA DE VENTA EXENTA
                     </Typography>
                  </Box>
                  <Box>
                     <Typography variant='subtitle2' gutterBottom >
                        Para continuar debe seleccionar al cliente
                     </Typography>
                  </Box>

               </Grid>
               <Grid item container xs={12} mb={2} sx={{ alignItems: 'center' }}>
                  <Grid item xs={4}>
                     <FormControl fullWidth sx={{ minWidth: 200 }}>
                        <TextField
                           id="input-with-sx"
                           // label="Folio Doc"
                           onChange={handleChange}
                           placeholder="Cargando..."
                           size="small"
                        />
                     </FormControl>
                  </Grid>
                  <Grid item xs={1}>
                     <Tooltip title="Buscar Cliente">
                        <IconButton onClick={handleOpenRut} className='btnIcoE'>
                           <SearchIcon />
                        </IconButton>
                     </Tooltip>

                  </Grid>
                  <Grid item xs={4}>
                     <Autocomplete
                        id="combo-box-demo"
                        options={contactoValue}
                        getOptionLabel={(option) => option.label}
                        style={{ width: 300 }}
                        size="small"
                        fullWidth
                        renderInput={(params) => <TextField {...params} label="Seleccione Moneda" variant="outlined" />}
                     />
                  </Grid>

                     <Grid item>
                        <Button
                           type='submit'
                           variant='contained'
                           onClick={handleCambiarEstado}
                           // disabled={loading}
                           disableElevation
                           className='btn_save'
                        >
                           Seleccionar
                        </Button>

                     </Grid>
                  </Grid>
               </Grid>
               {cambiarEstado && 
               <Typography variant='subtitle2' gutterBottom >
                  Archivo no encontrado
               </Typography>
            //          <Grid container spacing={2}>
            //                <Grid item xs={12} md={12} >
            //                   <Box>
            //                      <Typography variant='subtitle2' gutterBottom >
            //                         CLIENTE SELECCIONADO
            //                      </Typography>
            //                   </Box>
            //                </Grid>
            //                <Grid item xs={12} md={3} >
            //                   <TextField
            //                      id="input-with-sx"
            //                      label="Rut Empresa"
            //                      defaultValue="16363600-K"
            //                      // onChange={handleChange}
            //                      // placeholder="Cargando..."
            //                      size="small"
            //                      fullWidth
            //                   />
            //                </Grid>

            //                <Grid item xs={12} md={3} >
            //                   <TextField
            //                      id="input-with-sx"
            //                      label="Razon Social"
            //                      defaultValue="Nombre Empresa"
            //                      // onChange={handleChange}
            //                      // placeholder="Cargando..."
            //                      size="small"
            //                      fullWidth
            //                   />
            //                </Grid>

            //                <Grid item xs={12} md={3} >
            //                   <LocalizationProvider dateAdapter={AdapterDateFns}>
            //                      <Stack spacing={3}>
            //                         <DatePicker
            //                               // views={['year', 'month']}
            //                               label="Fecha"
            //                               // minDate={new Date('2012-03-01')}
            //                               // maxDate={new Date('2023-06-01')}
            //                               value={value}
            //                               onChange={(newValue) => {
            //                                  setValue(newValue);
            //                               }}
            //                               renderInput={(params) => <TextField  {...params} helperText={null} />}
            //                               />
            //                         </Stack>
            //                   </LocalizationProvider>
            //                </Grid>

            //                <Grid item xs={12} md={3} >
            //                   <TextField
            //                      id="input-with-sx"
            //                      label="Telefono"
            //                      defaultValue="02-9878778"
            //                      // onChange={handleChange}
            //                      // placeholder="Cargando..."
            //                      size="small"
            //                      fullWidth
            //                   />
            //                </Grid>

            //                <Grid item xs={12} md={3} >
            //                   <TextField
            //                      id="input-with-sx"
            //                      label="Nombre de Fantasia"
            //                      defaultValue="Prueba"
            //                      // onChange={handleChange}
            //                      // placeholder="Cargando..."
            //                      size="small"
            //                      fullWidth
            //                   />
            //                </Grid>

            //                <Grid item xs={12} md={3} >
            //                   <TextField
            //                      id="input-with-sx"
            //                      label="Giro"
            //                      defaultValue="Personas"
            //                      // onChange={handleChange}
            //                      // placeholder="Cargando..."
            //                      size="small"
            //                      fullWidth
            //                   />
            //                </Grid>

            //                <Grid item xs={12} md={3} >
            //                   <TextField
            //                      id="input-with-sx"
            //                      label="Email"
            //                      defaultValue="email@mail.cl"
            //                      // onChange={handleChange}
            //                      // placeholder="Cargando..."
            //                      size="small"
            //                      fullWidth
            //                   />
            //                </Grid>

            //                <Grid item xs={12} md={3} >
            //                   <Autocomplete
            //                      id="combo-box-demo"
            //                      options={documentos}
            //                      // getOptionLabel={(option) => option.label}
            //                      // style={{ width: 300 }}
            //                      fullWidth
            //                      renderInput={(params) => <TextField {...params} label="Sin Referencia" variant="outlined" />}
            //                   />
            //                </Grid>

            //                <Grid item xs={12} md={6} >
            //                   <TextField
            //                      id="input-with-sx"
            //                      label="Dirección"
            //                      defaultValue="Sin Dirección - Sin Comuna - Sin Region"
            //                      // onChange={handleChange}
            //                      // placeholder="Cargando..."
            //                      size="small"
            //                      fullWidth
            //                   />
            //                </Grid>

            //                <Grid item xs={12} md={6} >
            //                   <TextField
            //                      id="input-with-sx"
            //                      label="Observacion"
            //                      defaultValue="Observaciones"
            //                      // onChange={handleChange}
            //                      // placeholder="Cargando..."
            //                      size="small"
            //                      fullWidth
            //                   />
            //                </Grid>
            //                <Grid item xs={12} md={12} >
            //                   <Box>
            //                      <Typography variant='subtitle2' gutterBottom >
            //                         CONDICIONES Y GUIAS 
            //                      </Typography>
            //                   </Box>
            //                </Grid>
            //                <Grid item xs={12} md={3} >
            //                   <Autocomplete
            //                      id="combo-box-demo"
            //                      options={documentos}
            //                      // getOptionLabel={(option) => option.label}
            //                      // style={{ width: 300 }}
            //                      size="small"
            //                      fullWidth
            //                      renderInput={(params) => <TextField {...params} label="Condicion de Pago" variant="outlined" />}
            //                   />
            //                </Grid>
            //                <Grid item xs={12} md={3} >
            //                   <Autocomplete
            //                      id="combo-box-demo"
            //                      options={documentos}
            //                      // getOptionLabel={(option) => option.label}
            //                      // style={{ width: 300 }}
            //                      size="small"
            //                      fullWidth
            //                      renderInput={(params) => <TextField {...params} label="Area de Negocio" variant="outlined" />}
            //                   />
            //                </Grid>
            //                <Grid item xs={12} md={3} >
            //                   <Autocomplete
            //                      id="combo-box-demo"
            //                      options={documentos}
            //                      // getOptionLabel={(option) => option.label}
            //                      // style={{ width: 300 }}
            //                      fullWidth
            //                      size="small"
            //                      renderInput={(params) => <TextField {...params} label="Vendedor" variant="outlined" />}
            //                   />
            //                </Grid>
            //                <Grid item xs={12} md={3} >
            //                   <TextField
            //                      id="input-with-sx"
            //                      label="Guia de Despacho"
            //                      // defaultValue="Observaciones"
            //                      // onChange={handleChange}
            //                      // placeholder="Cargando..."
            //                      size="small"
            //                      fullWidth
            //                   />
            //                </Grid>
            //                <Grid item xs={12} md={3} >
            //                   <TextField
            //                      id="input-with-sx"
            //                      label="Nota Venta Externa"
            //                      // defaultValue="Observaciones"
            //                      // onChange={handleChange}
            //                      // placeholder="Cargando..."
            //                      size="small"
            //                      fullWidth
            //                   />
            //                </Grid>
            //                <Grid item xs={12} md={3} >
            //                   <TextField
            //                      id="input-with-sx"
            //                      label="Orden de Compra"
            //                      // defaultValue="Observaciones"
            //                      // onChange={handleChange}
            //                      // placeholder="Cargando..."
            //                      size="small"
            //                      fullWidth
            //                   />
            //                </Grid>
            //                <Grid item xs={12} md={3} >
            //                   <TextField
            //                      id="input-with-sx"
            //                      label="Cotizacion"
            //                      // defaultValue="Observaciones"
            //                      // onChange={handleChange}
            //                      // placeholder="Cargando..."
            //                      size="small"
            //                      fullWidth
            //                   />
            //                </Grid>
            //                <Grid item xs={12} md={3} >
            //                   <TextField
            //                      id="input-with-sx"
            //                      label="Nº GUIA DE DESPACHO"
            //                      // defaultValue="Observaciones"
            //                      // onChange={handleChange}
            //                      // placeholder="Cargando..."
            //                      size="small"
            //                      fullWidth
            //                   />
            //                </Grid>
            //                <Grid item xs={12} md={3} >
            //                   <TextField
            //                      id="input-with-sx"
            //                      label="N/V"
            //                      // defaultValue="Observaciones"
            //                      // onChange={handleChange}
            //                      // placeholder="Cargando..."
            //                      size="small"
            //                      fullWidth
            //                   />
            //                </Grid>
            //                <Grid item xs={12} md={3} >
            //                   <TextField
            //                      id="input-with-sx"
            //                      label="ORDEN DE COMPRA"
            //                      // defaultValue="Observaciones"
            //                      // onChange={handleChange}
            //                      // placeholder="Cargando..."
            //                      size="small"
            //                      fullWidth
            //                   />
            //                </Grid>

            //                <Grid container spacing={2}>
            //    <Grid item xs={12} md={9} mt={3}>
            //       <Box>
            //          <Typography variant='subtitle2'  gutterBottom >
            //             INSERTAR LINES DE DETALLE
            //          </Typography>
            //       </Box>
            //       <Box>
            //          {/* <Typography variant='subtitle2'  gutterBottom >
            //          Periodo: 2022 Mes: 04                        
            //          </Typography> */}
            //       </Box>
         
            //    </Grid>

            //             <Paper sx={{ width: '100%', overflow: 'hidden'}} >
            
            //                <TableContainer sx={{maxHeight:440}}>
            //                   <Table stickyHeader aria-label="sticky table">
            //                      <TableHead>
            //                         <TableRow>
            //                         {columns1.map((column) => (
            //                            <TableCell
            //                               key={column.id}
            //                               align={column.align}
            //                               style={{ minWidth: column.minWidth }}
            //                            >
            //                               {column.label}
            //                            </TableCell>
            //                         ))}
            //                         </TableRow>
            //                      </TableHead>
            //                      <TableBody>
            //                      <TableRow>
            //                            <TableCell>
            //                               <div style={{display:'flex', justifyContent:'space-evenly'}}>
            //                                  <IconButton
            //                                     onClick={handleOpenArticulo}
            //                                  >
            //                                     <SearchIcon/>
            //                                  </IconButton>
            //                                  <TextField
            //                                     id="rut"
            //                                     // label="ORDEN DE COMPRA"
            //                                     type="text"
            //                                     fullWidth
            //                                     variant="outlined"
            //                                     size="small"
            //                                     // defaultValue="jmora@test.cl"
            //                                  />
            //                               </div>
                                          
            //                            </TableCell>
            //                            <TableCell>
            //                               <TextField
            //                                  id="rut"
            //                                  // label="ORDEN DE COMPRA"
            //                                  type="text"
            //                                  fullWidth
            //                                  variant="outlined"
            //                                  size="small"
            //                                  // defaultValue="jmora@test.cl"
            //                               />
            //                            </TableCell>
            //                            <TableCell>
            //                               <TextField
            //                                  id="rut"
            //                                  // label="ORDEN DE COMPRA"
            //                                  type="text"
            //                                  fullWidth
            //                                  variant="outlined"
            //                                  size="small"
            //                                  // defaultValue="jmora@test.cl"
            //                               />
            //                            </TableCell>
            //                            <TableCell>
            //                               <TextField
            //                                  id="rut"
            //                                  // label="ORDEN DE COMPRA"
            //                                  type="text"
            //                                  fullWidth
            //                                  variant="outlined"
            //                                  size="small"
            //                                  // defaultValue="jmora@test.cl"
            //                               />
            //                            </TableCell>
            //                            <TableCell>
            //                            <FormControl fullWidth>
            //                               <InputLabel id="demo-simple-select-label">%</InputLabel>
            //                               <Select
            //                                  labelId="demo-simple-select-label"
            //                                  id="demo-simple-select"
            //                                  // label="fdffd%"
            //                                  onChange={handleChange}
            //                                  size="small"
            //                               >
            //                                  <MenuItem value={10}>%</MenuItem>
            //                                  <MenuItem value={20}>$</MenuItem>
            //                               </Select>
            //                               </FormControl>
            //                            </TableCell>
            //                            <TableCell>
            //                               <TextField
            //                                  id="rut"
            //                                  // label="ORDEN DE COMPRA"
            //                                  type="text"
            //                                  fullWidth
            //                                  variant="outlined"
            //                                  size="small"
            //                                  defaultValue="0"
            //                               />
            //                            </TableCell>
            //                            <TableCell>
            //                               <TextField
            //                                  id="rut"
            //                                  // label="ORDEN DE COMPRA"
            //                                  type="text"
            //                                  fullWidth
            //                                  variant="outlined"
            //                                  size="small"
            //                                  // defaultValue="jmora@test.cl"
            //                               />
            //                            </TableCell>
            //                            <TableCell>
            //                                  <Button
            //                                     type='submit'
            //                                     variant='contained'
                                                
            //                                     // onClick={handleSubmit}
            //                                     // disabled={loading}
            //                                     disableElevation
            //                                     // component={Link} to={ROUTE_EV_QUOTE_BOOK_RESUME}
            //                                     className='btn_opcion'
            //                                  >
            //                                           <span>AGREGAR</span>
            //                                  </Button>
            //                                  <Button
            //                                     type='submit'
            //                                     variant='contained'
                                                
            //                                     // onClick={handleSubmit}
            //                                     // disabled={loading}
            //                                     disableElevation
            //                                     // component={Link} to={ROUTE_EV_QUOTE_BOOK_RESUME}
            //                                     className='btn_opcion1'
            //                                     style={{marginLeft:10}}
            //                                  >
            //                                           <span>LIMPIAR</span>
            //                                  </Button>
            //                            </TableCell>
                                 
            //                         </TableRow>
            //                      </TableBody>   
            //                   </Table>  
            //                </TableContainer>
            //                <TablePagination
            //                   rowsPerPageOptions={[10, 25, 100]}
            //                   component="div"
            //                   count={rowsData.length}
            //                   rowsPerPage={rowsPerPage}
            //                   page={page}
            //                   onPageChange={handleChangePage}
            //                   onRowsPerPageChange={handleChangeRowsPerPage}
            //                   />
            //             </Paper> 
            //             <Paper sx={{ width: '100%', overflow: 'hidden'}} >
                           
            //                <TableContainer sx={{maxHeight:440}}>
            //                   <Table stickyHeader aria-label="sticky table">
            //                      <TableHead>
            //                         <TableRow>
            //                            {columns.map((column) => (
            //                               <TableCell
                                          
            //                                  key={column.id}
            //                                  align={column.align}
            //                                  style={{ minWidth: column.minWidth }}
            //                               >
            //                                  {column.label}
            //                               </TableCell>
            //                            ))}
            //                            <TableCell>
            //                               Opciones
            //                            </TableCell>
            //                         </TableRow>
            //                      </TableHead>
            //                      <TableBody>
            //                         {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
            //                            return (
            //                               <TableRow hover role="checkbox" tabIndex={-1} key={row.numero}>
            //                                  {columns.map((column) => {
            //                                     const value = row[column.id];
            //                                     return (
            //                                        <TableCell key={column.id} align={column.align}>
            //                                           {column.format && typeof value === 'number'
            //                                              ? column.format(value)
            //                                              : value}
            //                                        </TableCell>
            //                                     );
            //                                  })}
            //                                  <TableCell>
                                                
            //                                        <Checkbox {...label}  />
                                             
            //                                     <IconButton
            //                                        >
            //                                           <EditIcon
            //                                           style={{ fontSize:'20px' }}
            //                                           onClick={handleOpenEditar}
            //                                        />
            //                                     </IconButton>
            //                                     <IconButton
            //                                        >
            //                                           <AddCircleIcon
            //                                           style={{ fontSize:'20px' }}
            //                                           onClick={handleOpenAgregar}
            //                                        />
            //                                     </IconButton>
            //                                  </TableCell>
            //                               </TableRow>
            //                            );
            //                         })}
                                 
            //                      </TableBody>   
            //                   </Table>  
            //                </TableContainer>
            //                <TablePagination
            //                   rowsPerPageOptions={[10, 25, 100]}
            //                   component="div"
            //                   count={rowsData.length}
            //                   rowsPerPage={rowsPerPage}
            //                   page={page}
            //                   onPageChange={handleChangePage}
            //                   onRowsPerPageChange={handleChangeRowsPerPage}
            //                   />
            //             </Paper> 
            //             <Grid item xs={12} md={12} mt={2}>
            //                <Typography variant='subtitle2'  gutterBottom >
            //                RESUMEN DOCUMENTO                       
            //                </Typography>
            //             </Grid>
            //             <Grid container spacing={2}>
            //                <Grid item xs={12} md={6}>
            //                         <TextField
            //                            id="rut"
            //                            label="Obserbacion"
            //                            type="text"
            //                            fullWidth
                                    
            //                            variant="outlined"
            //                            size="small"
            //                            // defaultValue="jmora@test.cl"
            //                         />
            //                </Grid>
            //                <Grid item xs={12} md={2}>
            //                         <TextField
            //                            id="rut"
            //                            label="Descuento Global"
            //                            type="text"
            //                            fullWidth
                                    
            //                            variant="outlined"
            //                            size="small"
            //                            // defaultValue="jmora@test.cl"
            //                         />
            //                </Grid>
            //                <Grid item xs={12} md={2}>
            //                         <TextField
            //                            id="rut"
            //                            label="Subtotal"
            //                            type="text"
            //                            fullWidth
                                    
            //                            variant="outlined"
            //                            size="small"
            //                            // defaultValue="jmora@test.cl"
            //                         />
            //                </Grid>
            //                <Grid item xs={12} md={2}>
            //                         <div style={{display:'flex', justifyContent:'space-evenly'}}>
            //                         <FormControl >
            //                               {/* <InputLabel id="demo-simple-select-label">%</InputLabel> */}
            //                                  <Select
            //                                     labelId="demo-simple-select-label"
            //                                     id="demo-simple-select"
            //                                     // label="fdffd%"
            //                                     onChange={handleChange}
            //                                     size="small"
            //                                  >
            //                                     <MenuItem value={10}>%</MenuItem>
            //                                     <MenuItem value={20}>$</MenuItem>
            //                                  </Select>
            //                         </FormControl>
            //                               <TextField
            //                                  id="rut"
            //                                  label="Descuento"
            //                                  type="text"
            //                                  fullWidth
            //                                  variant="outlined"
            //                                  size="small"
            //                                  // defaultValue="jmora@test.cl"
            //                                  />
            //                               </div>
                                          
            //                </Grid>
            //                <Grid item xs={12} md={3}>
            //                         <TextField
            //                            id="rut"
            //                            label="Total Afecto"
            //                            type="text"
            //                            fullWidth
                                    
            //                            variant="outlined"
            //                            size="small"
            //                            defaultValue="28156"
            //                         />
            //                </Grid>
            //                <Grid item xs={12} md={3}>
            //                         <TextField
            //                            id="rut"
            //                            label="19%"
            //                            type="text"
            //                            fullWidth
                                    
            //                            variant="outlined"
            //                            size="small"
            //                            defaultValue="5349.64"
            //                         />
            //                </Grid>
            //                <Grid item xs={12} md={3}>
            //                         <TextField
            //                            id="rut"
            //                            label="Exento"
            //                            type="text"
            //                            fullWidth
                                    
            //                            variant="outlined"
            //                            size="small"
            //                            defaultValue="0"
            //                         />
            //                </Grid>
            //                <Grid item xs={12} md={3}>
            //                         <TextField
            //                            id="rut"
            //                            label="Total"
            //                            type="text"
            //                            fullWidth
                                    
            //                            variant="outlined"
            //                            size="small"
            //                            defaultValue="33505.64"
            //                         />
            //                </Grid>
            //             </Grid>
            //             <Grid item container
            //                            xs={12}
            //                            mt={2}
            //                         justifyContent='center'
            //                         >
            //                               <Grid item>
            //                                  <Button
            //                                     type='submit'
            //                                     variant='contained'
                                                
            //                                     // onClick={handleSubmit}
            //                                     // disabled={loading}
            //                                        disableElevation
            //                                        component={Link} to={ROUTE_EV_LS_CONFIRM_EMITION}
            //                                        className='btn_next'
            //                                     >
            //                                              INGRESAR
            //                                     </Button>
            //                                  </Grid>
            //                </Grid>
            // </Grid>

            //          </Grid>
                  

               }
               <Modal
                  open={openRut}
                  onClose={handleCloseRut}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <DataRut entity={undefined}/>
                  </Box>
               </Modal>
               {/* 
                <Modal
                  open={openArticulo}
                  onClose={handleCloseArticulo}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <DataProductos entity={undefined} />
                  </Box>
               </Modal>
               <Modal
                  open={openAgregar}
                  onClose={handleCloseAgregar}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <DataAgregar entity={undefined} />
                  </Box>
               </Modal>
               <Modal
                  open={openEditar}
                  onClose={handleCloseEditar}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <DataEditarCotizacion entity={undefined} />
                  </Box>
               </Modal> */}
              {/* <Modal
                  open={openInforme}
                  onClose={handleCloseInforme}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <DataInforme />
                  </Box>
               </Modal> */}
         </div>
      </Protected >
   )
}

