import React, { useState, useEffect } from 'react';
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Protected } from "@/components/layout/Protected";
import { FormControl, Grid, Stack, Typography, Button, Input, Tooltip, Autocomplete, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import InputLabel from '@mui/material/InputLabel';
import "./ExportSaleNote.sass";
// import "./Home-styles.css";
import "./ExportSaleNote-styles.css"
import { TableData } from "@/components/common/Table/Table";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { DateTimePicker } from '@mui/lab';
import {  DataRut } from './Modal/DataRut';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ROUTE_EV_LS_CONFIRM_EMITION, ROUTE_EV_LS_QUOTE_BOOK_RESUME } from "@/toolbox/constants/route-map";
import { getDay } from 'date-fns';



export const ExportSaleNote: FunctionComponent = (props: any) => {
   const [value, setValue] = useState<Date | null>(new Date());
   const [selectTypeResume, setSelectTypeResume] = useState('');
   const [data, setData] = React.useState<Date | null>(new Date());
   const [valueCalendar, setValueCalendar] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

   //modal Rit
   const [openRut, setOpenRut] = React.useState(false);
   const handleOpenRut = () => setOpenRut(true);
   const handleCloseRut = () => setOpenRut(false);

   //modal articulos
   const [openArticulo, setOpenArticulo] = useState(false);
   const handleOpenArticulo = () => setOpenArticulo(true);
   const handleCloseArticulo = () => setOpenArticulo(false);

   //modal Informe
   const [openInforme, setOpenInforme] = React.useState(false);
   const handleOpenInforme = () => setOpenInforme(true);
   const handleCloseInforme = () => setOpenInforme(false);

   const [cambiarEstado, setCambiarEstado] = useState(false);
   const handleCambiarEstado = () => setCambiarEstado(true);

   const [openAgregar, setOpenAgregar] = useState(false);
   const handleOpenAgregar = () => setOpenAgregar(true);
   const handleCloseAgregar = () => setOpenAgregar(false);

   const [openEditar, setOpenEditar] = useState(false);
   const handleOpenEditar = () => setOpenEditar(true);
   const handleCloseEditar = () => setOpenEditar(false);

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 870,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 4,
      borderRadius: '20px'
   };

   const handleChange = (event: any) => {
      setValue(event.target.value);
   };
   const handleChangeTypeResume = (event: SelectChangeEvent) => {
      setSelectTypeResume(event.target.value);
   };

   const [page, setPage] = React.useState(0);
   const [rowsPerPage, setRowsPerPage] = React.useState(10);

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };
   const monedas=[
      {label:'Bolivar(Venezuela)',value:'Bolivar'},
      {label:'Boliviano(Bolivia)',value:'Boliviano'},
      {label:'Chelin(Austria)',value:'Chelin'},
      {label:'Corona Din(Dinamarca)',value:'Dinamarca'},
      {label:'Corona Nor(Noruega)',value:'Noruega'},
      {label:'Corona Sc(Suecia)',value:'Suecia'},
      {label:'Cruzeiro Real(Brasil)',value:'Brasil'},
      {label:'Dirham(Emiratos Arabes)',value:'Arabes'},
      {label:'Dolar Aust(Australia)',value:'Australia'},
      {label:'Dolar Can(Canada)',value:'Canada'},
      {label:'Dolar Hk(Hong-Kong)',value:'HongKong'},
      {label:'Dolar Nz(NuevaZelandia)',value:'NuevaZelandia'},
      {label:'Dolar Sin(Singapur)',value:'Singapur'},
      {label:'Dolar Tai(Taiwam)',value:'Taiwam'},
      {label:'Dolar USA(EstadosUnidos)',value:'EstadosUnidos'},
      {label:'Escudo(Portugal)',value:'Portugal'},
      {label:'Euro(Comunidad Economica Europea)',value:'ComunidadEconomicaEuropea'},
      {label:'Florin(Holanda)',value:'Holanda'},
      {label:'Franco Bel(Belgica)',value:'Belgica'},
      {label:'Franco FR (Francia)',value:'Francia'},
      {label:'Franco SZ(Suiza)',value:'Suiza'},
   ]
   return (
      <Protected>
         <div style={{marginLeft:'5px', marginRight:'5px'}}>
            <Grid container spacing={2} >
               
                  <Grid item xs={12} md={12} >
                     <Box>
                        <Typography variant='h6' component='h1' sx={{ textTransform: 'uppercase' }} gutterBottom className="tittle">
                             INGRESO DE NOTA DE VENTA DE EXPORTACIÓN ELECTRONICA (781)
                        </Typography>
                     </Box>
                     <Box>
                        <Typography variant='subtitle2' gutterBottom className="tittle2" align='center'>
                           DATOS PARA NUEVA NOTA DE VENTA
                        </Typography>
                     </Box>

                  </Grid>

                  <Grid item xs={12} md={2} >

                     <Grid container >

                        <Grid item xs={10}>
                                 <TextField 
                                    fullWidth
                                    label="Rut cliente"
                                    id="outlined-size-small"
                                    placeholder='76333322-1'
                                    size="small"
                                                                    />
                        </Grid>

                        <Grid item xs={1}>
                           <Tooltip title="Buscar Cliente">
                              <IconButton onClick={handleOpenRut} className='btnIcoE'>
                                 <SearchIcon />
                              </IconButton>
                           </Tooltip>

                        </Grid>

                     </Grid>
                  </Grid>

                  <Grid item xs={12} md={2}>
                     <Autocomplete
                        id="combo-box-demo"
                        options={monedas}
                        // getOptionLabel={(option) => option.label}
                        // style={{ width: 300 }}
                        // fullWidth
                        size='small'
                        renderInput={(params) => <TextField {...params} label="Monedas" variant="outlined" />}
                     />
                  </Grid>

                  <Grid item xs={12} md={2}>
                     <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                           <DatePicker
                                 // views={['year', 'month']}
                                 label="Fecha"
                                 // minDate={new Date('2012-03-01')}
                                 // maxDate={new Date('2023-06-01')}
                                 value={value}
                                 onChange={(newValue) => {
                                    setValue(newValue);
                                 }}
                                 renderInput={(params) => <TextField  {...params} helperText={null} />}
                                 />
                           </Stack>
                     </LocalizationProvider>                   
                  </Grid>

                  <Grid item container
                        xs={12}
                        justifyContent='left'
                     >
                           <Button
                              type='submit'
                              variant='contained'
                              onClick={handleCambiarEstado}
                              // disabled={loading}
                              disableElevation
                              className='btn_save'
                           >
                              Seleccionar
                           </Button>

                  </Grid>
            </Grid>
               {cambiarEstado &&
                  <Typography variant='subtitle2' gutterBottom mt={3}>
                     MODULO POR REVISAR
                  </Typography>

               }
               <Modal
                  open={openRut}
                  onClose={handleCloseRut}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <DataRut entity={undefined}/>
                  </Box>
               </Modal>
              {/* <Modal
                  open={openInforme}
                  onClose={handleCloseInforme}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <DataInforme />
                  </Box>
               </Modal> */}
         </div>
      </Protected >
   )
}

