import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, } from '@mui/material'
import { useState } from "react";
import { Props } from "./LsQuoteBook.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './LsQuoteBook_styles.css'
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import Modal from '@mui/material/Modal';
// import { DataMo } from "./Modal/DataEmail";
// import { DataRut } from "./Modal/DataRut";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import SearchIcon from '@mui/icons-material/Search';
import { LoadingButton } from "@mui/lab";
import { LsTableResumen } from "./LsTableResumen";
import { Link } from 'react-router-dom';
import { ROUTE_EV_LS_QUOTE_BOOK_RESUME } from "@/toolbox/constants/route-map";


export const LsQuoteBook: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   // const [value, setValue] = useState('');
   const [value, setValue] = useState<Date | null>(new Date());
   const [loading, setLoading] = useState(false);
   const [pais, setPais] = useState('');
   const [estado, setEstado] = useState('');
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);
   const [open, setOpen] = useState(false);
   const [openRut, setOpenRut] = useState(false);
   const handleOpenRut = () => setOpenRut(true);
   const handleCloseRut = () => setOpenRut(false);

   const handleChangePais = (event: SelectChangeEvent) => {
      setPais(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const estados = [
      { value: 1, label: 'Generada Abiertas' },
      { value: 2, label: 'Adjudicadas' },
      { value: 3, label: 'Anulada' },
      { value: 4, label: 'Adjudicada C/NV' },
      { value: 5, label: 'No adjudicada' },
   ];

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 720,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

   const sucursalValue = [
      { value: '1', label: 'Sucursal1' },
      { value: '2', label: 'Sucursal2' },
      { value: '3', label: 'Sucursal3' },
      { value: '4', label: 'Sucursal4' },
      { value: '5', label: 'Sucursal5' },
      { value: '6', label: 'Sucursal6' },
   ]
   return (
      <Protected>
         <Container maxWidth='xl' style={{height:'100vh'}}>
            <Grid container spacing={3}>
               <Grid item xs={12} md={12} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        COTIZACIONES EMPRESA EV-LS
                     </Typography>
                  </Box>
                  <Box>
                     <Typography variant='subtitle2' gutterBottom >
                        Para revisar sus libros de cotizaciones empresa, seleccione los siguientes datos
                     </Typography>
                  </Box>

               </Grid>

               <Grid justifyContent='center' item xs={12} md={5}>

                  <Grid item xs={12} md={12} >
                     <Box>
                        <Typography variant='caption' component='h1' gutterBottom >
                           LIBRO COTIZACIONES
                        </Typography>
                     </Box>

                  </Grid>

                  <Grid container spacing={2} >

                     <Grid item xs={8} >

                        <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={sucursalValue}
                           fullWidth
                           renderInput={(params) => <TextField {...params} label="Sucursal" />}
                        />
                     </Grid>

                     <Grid item xs={8} >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                           <Stack spacing={3}>
                              <DatePicker
                                 views={['year', 'month']}
                                 label="Periodo Y Mes"
                                 minDate={new Date('2012-03-01')}
                                 maxDate={new Date('2023-06-01')}
                                 value={value}
                                 onChange={(newValue) => {
                                    setValue(newValue);
                                 }}
                                 renderInput={(params) => <TextField  {...params} helperText={null} />}
                              />
                           </Stack>
                        </LocalizationProvider>
                     </Grid>

                     <Grid item container
                        xs={12}
                        mt={2}
                        justifyContent='left'
                     >
                        <Grid item>
                           <Button
                              type='submit'
                              variant='contained'

                              // onClick={handleSubmit}
                              // disabled={loading}
                              disableElevation
                              component={Link} to={ROUTE_EV_LS_QUOTE_BOOK_RESUME}
                              className='btn_next'
                           >
                              Siguiente
                           </Button>
                        </Grid>
                     </Grid>

                  </Grid>
               </Grid>
            </Grid>
         </Container>

      </Protected>
   );
};

