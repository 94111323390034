import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "../OtherDescounts.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import '../OtherDescounts_styles.css'
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { PictureAsPdf } from "@mui/icons-material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';

interface Column {
   id: 'dia' |'mes' | 'anio' | 'haberes' | 'obserbacion'|'monto';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

const columns:readonly Column[] = [
   { id: 'dia', label: 'Dia', minWidth: 20 },
   { id: 'mes', label: 'Mes', minWidth: 50 },
    { id: 'anio', label: 'Año', minWidth: 20 },
    { id: 'haberes', label: 'Haberes', minWidth: 50 },
    { id: 'obserbacion', label: 'Observacion', minWidth: 50 },
    { id: 'monto', label: 'Monto', minWidth: 50 },

   ];

interface Data {
    dia:number
    mes: string;
    anio: number;
    haberes: string;
    obserbacion: string;
    monto: string;

}

function createData(
   dia:number,
   mes: string,
   anio: number,
   haberes: string,
   obserbacion: string,
   monto: string
   ):Data {
   return { dia,mes, anio, haberes,obserbacion,monto };
}

const rowsData = [
    createData( 2,'Enero',2010, "Haber1", "Observacion1", "$ 100.000"),
    createData( 2,'Febrero',2011, "Haber1",  "Observacion2", "$ 100.000"),
    createData( 2,'Marzo',2012, "Haber1", "Observacion3", "$ 100.000"),
    createData( 2,'Abril',2010, "Haber1", "Observacion4", "$ 100.000"),
    createData( 2,'Junio',2013, "Haber1", "Observacion5", "$ 100.000"),
    createData( 2,'Julio',2010, "Haber1", "Observacion6", "$ 100.000"),
    createData( 2,'Agosto',2014, "Haber1", "Observacion7", "$ 100.000"),
    createData( 2,'Septiembre',2010, "Haber1", "Observacion8", "$ 100.000"),
    createData( 2,'Octubre',2015, "Haber1", "Observacion9", "$ 100.000"),
    createData( 2,'Noviembre',2010, "Haber1", "Observacion10", "$ 100.000"),
    createData( 2,'Diciembre',2017, "Haber1", "Observacion11", "$ 100.000"),
]

const nombresHaber=[
   {id:'HI Aguinaldo Imponible',label:'HI Aguinaldo Imponible'},
   {id:'HI Otros Bonos Imponibles',label:'HI Otros Bonos Imponibles'},
   {id:'HI Gratificacion Anticipada',label:'HI Gratificacion Anticipada'},
   {id:'HI Promedio Vacaciones',label:'HI Promedio Vacaciones'},
   {id:'HI Incentivos',label:'HI Incentivos'},
   {id:'HI Bono de Responsabilidad',label:'HI Bono de Responsabilidad'},
   {id:'HI Bono Produccion',label:'HI Bono Produccion'},
   {id:'HI Dif-sueldo mes ant',label:'HI Dif-sueldo mes ant'},
   {id:'HI Semana Corrida',label:'HI Semana Corrida'},
   {id:'HI Bono por Meta',label:'HI Bono por Meta'},
   {id:'HN Asignacion Caja',label:'HN Asignacion Caja'},
   {id:'HN Diferencia mes anterior',label:'HN Diferencia mes anterior'},
   {id:'HN Representaciones',label:'HI Representaciones'},
   {id:'HN Bono Movilizacion - Obra Faena',label:'HI Bono Movilizacion - Obra Faena'},
   {id:'HN VIATICO',label:'HI VIATICO'},
   {id:'HN ASIGANACION DE BIEN',label:'HI ASIGANACION DE BIEN'},
   {id:'HN Finiquito',label:'HI Finiquito'},

]
export const ModalOtherDescounts:React.FC<Props> = (props: Props): JSX.Element => {
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value2, setValue2] = useState('');
   const [loading, setLoading] = useState(false);
   const [familyProduct, setFamilyProduct] = useState('');
   const [estado, setEstado]= useState('');
   const [value, setValue] = useState<Date | null>(new Date());

    const handleChangeFamilyProduct = (event: SelectChangeEvent) => {
      setFamilyProduct(event.target.value);
    };
    const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
    };
   function handleClick() {
     setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };


    return (
        <Container maxWidth='xl'>
           <Grid container spacing={3}>
               <Grid item xs={12} md={12} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        INGRESOS DE OTROS DESCUENTOS
                     </Typography>
                  </Box>
                  <Typography  variant="caption" display="block" gutterBottom mt={1}>
                  Trabajador Seleccionado
                  </Typography>
               </Grid>
               <Grid item xs={6} md={3} mb={-2}>
               <Box>
                     <Typography variant='subtitle2'  gutterBottom>
                        RUT TRABAJADOR
                     </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={9} mb={-2}>
                    <Box>
                        <Typography variant='caption'  gutterBottom>
                        16976751-3
                        </Typography>
                    </Box> 
            </Grid>

            <Grid item xs={6} md={3} mb={2}>
               <Box>
                     <Typography variant='subtitle2'  gutterBottom>
                        APELLIDOS
                     </Typography>
                  </Box>
                </Grid>
            <Grid item xs={6} md={9} mb={2}>
                    <Box>
                        <Typography variant='caption'  gutterBottom>
                        ALARCON - GONZALEZ - FERNANDO
                        </Typography>
                    </Box> 
            </Grid>

            <Grid item xs={12} md={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={3}>
                    <DatePicker
                        // views={['year', 'month']}
                        label="Fecha "
                        minDate={new Date('2012-03-01')}
                        maxDate={new Date('2023-06-01')}
                        value={value}
                        onChange={(newValue) => {
                            setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} helperText={null} />}
                        />
                    </Stack>
                    </LocalizationProvider>
               </Grid>
               <Grid item xs={12} md={3}>
                     <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={nombresHaber}
                           // sx={{ bgcolor: '#F3F3F3'}}
                           renderInput={(params) => <TextField {...params} label="Nombre Haber" />}
                        />
               </Grid>
             <Grid item xs={12} md={3}>
                  <TextField
                     label="Monto ($)"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                     
                  />
             </Grid>
               

               <Grid item xs={12} md={3}>
                    <Button
                        type='submit'
                        variant='contained'
                        // onClick={handleSubmit}
                        // disabled={loading}
                        disableElevation
                        className='btn_next'
                        style={{backgroundColor:'#ff9700', color:'#fff'}}
                        >
                        <span>Agregar</span>
                    </Button>
                </Grid>

            </Grid>
            
            <div style={{background:'#16556b', borderRadius:'5px', color:'#ffffff',}} >
                        <Typography 
                        mt={2}
                        mb={2}
                        style={{padding:'3px'}}
                        variant="subtitle2"
                        align='center'
                        >HISTORICO DE ANTICIPO REGISTRADOS
                        </Typography>
         </div>

            <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:220}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                                 {column.label}
                              </TableCell>
                           ))}
                           <TableCell>Opcion</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.anio}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                       <IconButton aria-label="delete">
                                          <DeleteIcon style={{color:'red'}}/>
                                       </IconButton>
         
                                       {/* <IconButton aria-label="delete">
                                          <DeleteIcon style={{color:'#16556b'}}/>
                                       </IconButton>
                                       <IconButton aria-label="edit">
                                          <FactCheckIcon  style={{color:'#16556b'}}/>
                                       </IconButton>
                                       <IconButton aria-label="edit">
                                          <PrintIcon style={{color:'#16556b'}}/>
                                       </IconButton> */}
         
                                 </TableCell>

                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
            </Paper>

         </Container>
     
    );
   };

