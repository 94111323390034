import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, } from '@mui/material'
import { useState } from "react";
import { Props } from "./Custumer.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './Custumer_styles.css'
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { AddCircle } from "@mui/icons-material";
import AddCircleIcon from '@mui/icons-material/AddCircle';

interface Column {
   id: 'numero' | 'nombre' | 'cargo' | 'email';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: 'numero', label: 'N', minWidth: 100 },
   { id: 'nombre', label: 'Nombre', minWidth: 100 },
   { id: 'cargo', label: 'Cargo', minWidth: 100 },
   { id: 'email', label: 'Email', minWidth: 100 },
];

interface Data {
   numero: number;
   nombre: string;
   cargo: string;
   email: string;
}

function createDataProviders(
   numero: number,
   nombre: string,
   cargo: string,
   email: string,
): Data {
   return { numero, nombre, cargo, email };
}

const rowsDataContactosEmpresa = [
   createDataProviders(1, 'Juan Perez', 'Gerente', 'juan@gmail.com'),
   createDataProviders(2, 'Maria Perez', 'Gerente', 'maria@gmail.com'),
]


export const DataModalContacto: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [ejecutivoComercial, setEjecutivoComercial] = useState('');
   const [estado, setEstado] = useState('');


   const handleChangeEjecutivoComercial = (event: SelectChangeEvent) => {
      setEjecutivoComercial(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const comerciales = [
      { value: '1', label: 'Activo' },
      { value: '2', label: 'Desactivado' },
   ];
   const estados = [
      { value: '1', label: 'Activo' },
      { value: '2', label: 'Desactivado' },
   ]

   return (
         <Container maxWidth='xl'>
            <Grid container spacing={3}>
            <Grid item xs={12} md={6} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        CONTACTOS EMPRESA
                     </Typography>
                  </Box>
                  
               </Grid>
                     
               <Grid item xs={12} md={6} className="btn-download">
                  <Grid container spacing={3}>
                     <Grid item xs={12} md={6} className="btn-download" >
                        <Box>
                           <LoadingButton
                           // color="warning"
                           onClick={handleClick}
                           loading={loading}
                           loadingPosition="start"
                           startIcon={<FileDownloadIcon />}
                           variant="contained"
                           fullWidth
                           className="btn-download2"
                           >
                           Exportar
                           </LoadingButton>
                        </Box>
                     </Grid>
                     <Grid item xs={12} md={6} className="btn-download">
                        <Box>
                           <LoadingButton
                           // color="warning"
                           onClick={handleClick}
                           loading={loading}
                           loadingPosition="start"
                           startIcon={<AddCircleIcon />}
                           variant="contained"
                           fullWidth
                           className="btn-download2"
                           >
                           Nuevo
                           </LoadingButton>
                        </Box>
                     </Grid>
                  </Grid>
               </Grid>

               <Grid item xs={12} sm={4}>
                  <TextField
                     label="Nombre Contacto"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={4}>
                  <TextField
                     label="Cargo"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={4}>
                  <TextField
                     label="Email"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={4}>
                  <TextField
                     label="Telefono Fijo"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={4}>
                  <TextField
                     label="Celular"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={4}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={comerciales}
                     renderInput={(params) => <TextField {...params} label="Ejecutivo Comercial	" />}
                  />
               </Grid>

               <Grid item xs={12} sm={4}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={estados}
                     renderInput={(params) => <TextField {...params} label="Estado	" />}
                  />
               </Grid>

               <Grid item container
                  xs={12}
                  mt={1}
                  justifyContent='center'
               >
                  <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        // onClick={handleSubmit}
                        // disabled={loading}
                        disableElevation
                        className='btn_save'
                     >
                        <span>Agregar</span>
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={2}>
               LISTADO DE REGISTRO INGRESADO
            </Typography>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
               <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           <TableCell>N</TableCell>
                           <TableCell>Nombre</TableCell>
                           <TableCell>Cargo</TableCell>
                           <TableCell>Email</TableCell>
                           <TableCell>Estado</TableCell>
                           <TableCell>Cobrar</TableCell>
                           <TableCell>Opciones</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsDataContactosEmpresa.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.numero}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                    <IconButton
                                    >
                                       <CheckCircleIcon />
                                    </IconButton>
                                 </TableCell>
                                 <TableCell>
                                    <IconButton
                                    >
                                       <MonetizationOnIcon />
                                    </IconButton>
                                 </TableCell>
                                 <TableCell>
                                    <IconButton
                                    >
                                       <EditIcon />
                                    </IconButton>
                                 </TableCell>
                              </TableRow>
                           );
                        })}

                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsDataContactosEmpresa.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>

         </Container>
   );
};

