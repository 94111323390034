import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, } from '@mui/material'
import { useState } from "react";
import { Props } from "../../BooksSalesNotes.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import '../../BooksSalesNotes_styles.css'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import { TableResumen } from './TableResumen';
interface Column {
   id: 'num' | 'fecha' | 'detalle' | 'nombre' | 'cantidad' | 'pendiente' | 'estado' | 'porcentaje';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: string) => string;
}

interface Column1 {
   id: 'num' | 'fecha' | 'detalle' | 'empresa' | 'cantidad' | 'pendiente' | 'estado' | 'porcentaje';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value2: string) => string;
}


const columns: readonly Column[] = [
   { id: 'num', label: 'num', minWidth: 10 },
   { id: 'fecha', label: 'fecha', minWidth: 10 },
   { id: 'detalle', label: 'detalle', minWidth: 10 },
   { id: 'nombre', label: 'nombre', minWidth: 10 },
   { id: 'cantidad', label: 'cantidad', minWidth: 10 },
   { id: 'pendiente', label: 'cantidad', minWidth: 10 },
   { id: 'estado', label: 'estado', minWidth: 10 },
   { id: 'porcentaje', label: 'procentaje', minWidth: 10 },
];

const columns1: readonly Column1[] = [
   { id: 'num', label: 'num', minWidth: 10 },
   { id: 'fecha', label: 'fecha', minWidth: 10 },
   { id: 'detalle', label: 'detalle', minWidth: 10 },
   { id: 'empresa', label: 'empresa', minWidth: 10 },
   { id: 'cantidad', label: 'cantidad', minWidth: 10 },
   { id: 'pendiente', label: 'cantidad', minWidth: 10 },
   { id: 'estado', label: 'estado', minWidth: 10 },
   { id: 'porcentaje', label: 'procentaje', minWidth: 10 },
];

interface Data {
   num: string;
   fecha: string;
   detalle: string;
   nombre: string;
   cantidad: string;
   pendiente: string;
   estado: string;
   porcentaje: string;
}

interface Data1 {
   num: string;
   fecha: string;
   detalle: string;
   empresa: string;
   cantidad: string;
   pendiente: string;
   estado: string;
   porcentaje: string;
}

function createData(
   num: string,
   fecha: string,
   detalle: string,
   nombre: string,
   cantidad: string,
   pendiente: string,
   estado: string,
   porcentaje: string,
): Data {
   return { num, fecha, detalle, nombre, cantidad, pendiente, estado, porcentaje };
}

function createData1(
   num: string,
   fecha: string,
   detalle: string,
   empresa: string,
   cantidad: string,
   pendiente: string,
   estado: string,
   porcentaje: string,
): Data1 {
   return { num, fecha, detalle, empresa, cantidad, pendiente, estado, porcentaje };
}

const rowsData = [
   createData("194", "02-12-2021", "Nota de Venta Exportacion", "Alain monsalve", "100", "CANTIDAD PENDIENTE", "ESTADO", "PORCENTAJE FACTURADO (acumulable)")
]

const rowsData1 = [
   createData1("194", "02-12-2021", "Nota de Venta Exportacion", "Empresa Prueba", "100", "CANTIDAD PENDIENTE", "ESTADO", "PORCENTAJE FACTURADO (acumulable)")
]

export const DataReport: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value2, setValue2] = useState('');
   const [familyProduct, setFamilyProduct] = useState('');
   const [estado, setEstado] = useState('');
   const [value, setValue] = useState<Date | null>(new Date());
   const [loading, setLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false);
   const handleOpenModal = () => setOpenModal(true);
   const handleCloseModal = () => setOpenModal(false);

   // const [estadoContenido, setEstadoContenido] = useState(true);

   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };
   // const handleEstadoContenido=()=>setEstadoContenido(false);

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };
   const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
         backgroundColor: '#c6ced1',
         fontSize: 14,
         color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
         fontSize: 14,
      },
   }));

   return (
      <Container maxWidth='xl'>

         <Grid container spacing={3}>
            <Grid item xs={12} md={6} >
               <Box>
                  <Typography variant='h6' component='h1' gutterBottom className="tittle">
                     INFORME NOTAS DE VENTAS 12 - 2022
                  </Typography>
               </Box>
               <Box>
                  <Typography variant='subtitle2' gutterBottom >
                     Notas de Venta Facturadas v/s Montos de facturación
                  </Typography>
               </Box>

            </Grid>

            <Grid item xs={12} mb={2}>
               <TableResumen  entity={undefined} />
            </Grid>
         </Grid>


         <div style={{ background: '#16556b', borderRadius: '5px', color: '#ffffff', }} >
            <Typography
               mt={2}
               mb={2}
               style={{ padding: '3px' }}
               variant="subtitle2"
               align='center'
            >Detalle
            </Typography>
         </div>

         <Grid item xs={12}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
               <TableContainer sx={{ maxHeight: 220 }}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {columns.map((column) => (
                              <StyledTableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{ minWidth: column.minWidth }}
                              >
                                 {column.label}
                              </StyledTableCell>
                           ))}
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.num}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'string'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}

                              </TableRow>
                           );
                        })}

                     </TableBody>
                  </Table>
               </TableContainer>

            </Paper>
         </Grid>

         <Grid item xs={12} mt={5}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }} >
               <TableContainer sx={{ maxHeight: 220 }} >
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {columns1.map((column) => (
                              <StyledTableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{ minWidth: column.minWidth }}
                              >
                                 {column.label}
                              </StyledTableCell>

                           ))}
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData1.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.num}>
                                 {columns1.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'string'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                              </TableRow>
                           );
                        })}

                     </TableBody>
                  </Table>
               </TableContainer>
               {/* <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rowsData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
            </Paper>
         </Grid>


      </Container>

   );
}