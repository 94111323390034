import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, FormControl, TextField, InputAdornment, FormControlLabel,  } from '@mui/material';
import { ArrowRightIcon } from "@toolbox/constants/icons";
import { Props } from './FormSituationInitial.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import Entity from "@assets/img/entity.png";
import './style.sass';
import "./FormSituationInitial_style.css";
import BusinessIcon from '@mui/icons-material/Business';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';

export const FormSituationInitial: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false)
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
    );
   const [estado, setEstado] = useState<any>({ rut: false, nombre_rz: false })

   const [valueInitialSituation, setValueInitialSituation] = React.useState('Informacion de Compra');

    const handleInitialSituation = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueInitialSituation((event.target as HTMLInputElement).value);
    };
    
   
   const proveedores = [
      { label: 'Proveedor 1'},
      { label: 'Proveedor 2'},
      { label: 'Proveedor 3'},
      { label: 'Proveedor 4'},
      { label: 'Proveedor 5'},
   ]
  
   const rules = useMemo(() => ({
      situacion_inicial: [
         VALIDATORS.REQUIRED,
      ],
      fecha_compra: [
         VALIDATORS.REQUIRED,
      ],
      valor_compra: [
         VALIDATORS.REQUIRED
      ],
      numero_factura: [
         VALIDATORS.REQUIRED,
      ],
      orden_compra: [
         VALIDATORS.REQUIRED,
      ],
      proveedor: [
         VALIDATORS.REQUIRED,
      ],
      vida_financiera:[
         VALIDATORS.REQUIRED,
      ],
      vida_tributaria:[
         VALIDATORS.REQUIRED,
      ],
      inicio_valorizacion: [
         VALIDATORS.REQUIRED,
      ],
      inicio_depreciacion: [
         VALIDATORS.REQUIRED,
      ],
      descripcion: [
         VALIDATORS.REQUIRED,
      ]

   }), []);


   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'situacion_inicial':
            props.setvalueEntry(prev => ({ ...prev, situacion_inicial: value }));
            break;
         case 'fecha_compra':
            props.setvalueEntry(prev => ({ ...prev, fecha_compra: value }))
            break;
         case 'valor_compra':
            props.setvalueEntry(prev => ({ ...prev, valor_compra: value }))
            break;
         case 'numero_factura':
            props.setvalueEntry(prev => ({ ...prev, numero_factura: value }));
            break;
         case 'orden_compra':
            props.setvalueEntry(prev => ({ ...prev, orden_compra: value }));
            break;
         case 'proveedor':
            props.setvalueEntry(prev => ({ ...prev, proveedor: value }));
            break;
         case 'vida_financiera':
            props.setvalueEntry(prev => ({ ...prev, vida_financiera: value }));
            break;
         case 'inicio_valorizacion':
            props.setvalueEntry(prev => ({ ...prev, inicio_valorizacion: value }));
            break;
         case 'inicio_depreciacion':
            props.setvalueEntry(prev => ({ ...prev, inicio_depreciacion: value }));
            break;
         case 'vida_tributaria':
            props.setvalueEntry(prev => ({ ...prev, vida_tributaria: value }));
            break;
         case 'descripcion':
            props.setvalueEntry(prev => ({ ...prev, descripcion: value }));
            break;
         default:
            break;
      }
   };
   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      props.setvalueEntry(prev => ({ ...prev, [name]: newVal }));
   }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      props.handleNext();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }

   return (
      <Container maxWidth="xl">
         <form >
            <Grid container spacing={3} className={`e-mb-lg`}>
   
               <Grid item xs={12} textAlign="center">
               <FormControl>
                  <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={valueInitialSituation}
                  onChange={handleInitialSituation}
                  >
                  <FormControlLabel value="Informacion de Compra" control={<Radio />} label="Informacion de Compra" />
                  <FormControlLabel value="Situacion Contable a la fecha" control={<Radio />} label="Situacion Contable a la fecha" />
                  </RadioGroup>
               </FormControl>
               </Grid>

               <Grid item xs={12} sm={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                     <DatePicker
                     label="Fecha de Compra"
                     value={value}
                     onChange={(newValue) => {
                        setValue(newValue);
                     }}
                     renderInput={(params) => <TextField {...params} />}
                     />
                  </LocalizationProvider>
               </Grid>

               <Grid item xs={12} sm={3}>
               <Input
                     ref={ref => inputRefs.current[2] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <BusinessIcon />
                     </InputAdornment>
                    }
                     name="valor_compra"
                     placeholder="Valor de Compra"
                     //value={data.nombre_rz}
                     value={props.valueEntry.valor_compra}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     // backgroundColor="#F3F3F3"
                     rules={rules.valor_compra}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
               <Input
                     ref={ref => inputRefs.current[2] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <BusinessIcon />
                     </InputAdornment>
                    }
                     name="numero_factura"
                     placeholder="Numero factura"
                     //value={data.nombre_rz}
                     value={props.valueEntry.numero_factura}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     // backgroundColor="#F3F3F3"
                     rules={rules.numero_factura}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
               <Input
                     ref={ref => inputRefs.current[2] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <BusinessIcon />
                     </InputAdornment>
                    }
                     name="orden_compra"
                     placeholder="Orden Compra"
                     //value={data.nombre_rz}
                     value={props.valueEntry.orden_compra}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     // backgroundColor="#F3F3F3"
                     rules={rules.orden_compra}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
               <Input
                     ref={ref => inputRefs.current[2] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <BusinessIcon />
                     </InputAdornment>
                    }
                     name="vida_tributaria"
                     placeholder="VIda Util Tributaria"
                     //value={data.nombre_rz}
                     value={props.valueEntry.vida_tributaria}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     // backgroundColor="#F3F3F3"
                     rules={rules.vida_tributaria}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
               <Input
                     ref={ref => inputRefs.current[2] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <BusinessIcon />
                     </InputAdornment>
                    }
                     name="vida_financiera"
                     placeholder="Vida Util Financiera"
                     //value={data.nombre_rz}
                     value={props.valueEntry.vida_financiera}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     // backgroundColor="#F3F3F3"
                     rules={rules.vida_financiera}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
               <LocalizationProvider dateAdapter={AdapterDateFns}>
                     <DatePicker
                     label="Mes/Año de Revalorización"
                     value={value}
                     openTo="year"
                     onChange={(newValue) => {
                        setValue(newValue);
                     }}
                     renderInput={(params) => <TextField {...params} />}
                     />
                  </LocalizationProvider>
               </Grid>
               <Grid item xs={12} sm={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                     <DatePicker
                     label="Mes/Año de Depreciación"
                     value={value}
                     openTo="year"
                     onChange={(newValue) => {
                        setValue(newValue);
                     }}
                     renderInput={(params) => <TextField {...params} />}
                     />
                  </LocalizationProvider>
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={proveedores}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Proveedor" />}
                     />
               </Grid>

               <Grid item xs={12} sm={9}>
                  <Input
                     ref={ref => inputRefs.current[2] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <BusinessIcon />
                     </InputAdornment>
                    }
                     name="descripcion"
                     placeholder="Descripcion"
                     //value={data.nombre_rz}
                     value={props.valueEntry.descripcion}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     // backgroundColor="#F3F3F3"
                     rules={rules.descripcion}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>



               <Grid item container
                  xs={12}
                 justifyContent='right'
                 >
                     <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                        className='btn_save2'
                        endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>Agregar</span>
                        }
                     </Button>

                     </Grid>
               </Grid>
            </Grid>
         </form>
      </Container>
   );
}
