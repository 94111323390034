import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { ArrowRightIcon } from "@/toolbox/constants/icons";
import { VALIDATORS } from "@/toolbox/helpers/validation-rules";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, TablePagination, TableFooter, } from '@mui/material';
import { Input } from '@components/common/Input';
import { Props } from "./QuotasState.type";
import BusinessIcon from '@mui/icons-material/Business';
import { InputRef } from '@/components/common/Input/InputInterfaces';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import AssignmentIcon from '@mui/icons-material/Assignment';
import './QuotasState_style.css';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SearchIcon from '@mui/icons-material/Search';

import AbcIcon from '@mui/icons-material/Abc';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EMobiledataIcon from '@mui/icons-material/EMobiledata';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { Link } from 'react-router-dom';
import { ROUTE_EV_SALES_DATES, ROUTE_GC_FAMILIES, ROUTE_GC_PRICE_LIST, ROUTE_RM_RESUME_WORKER } from '@/toolbox/constants/route-map';
// Para la tabla de la vista principal
interface Column {
   id: 'codigo' | 'fechaVencimiento' | 'monto' | 'estado' | 'fechaLiquidacion';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columnsPrincipal: readonly Column[] = [
   { id: 'codigo', label: 'Codigo', minWidth: 100 },
   { id: 'fechaVencimiento', label: 'Fecha', minWidth: 100 },
   { id: 'monto', label: 'Tipo', minWidth: 100 },
   { id: 'estado', label: 'Folio', minWidth: 100 },
   { id: 'fechaLiquidacion', label: 'Re', minWidth: 100 }   
];

interface Data {
   codigo: string;
   fechaVencimiento: string;
   monto: string;
   estado: string;
   fechaLiquidacion: string;
}
function createData(
   codigo: string,
   fechaVencimiento: string,
   monto: string,
   estado: string,
   fechaLiquidacion: string,
) {
   return { codigo, fechaVencimiento, monto, estado, fechaLiquidacion};
}

const rowsListado = [
]

export const QuotasState: React.FC<Props> = (props: Props): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [openEnviador, setOpenEnviador] = useState(false);
   const [openEmail, setOpenEmail] = useState(false);
   const [openStateSll, setOpenStateSll] = useState(false);
   const [openVoucher, setOpenVoucher] = useState(false);

   //Modal Enviador masivo
   const handleOpenEnviador = () => setOpenEnviador(true);
   const handleCloseEnviador = () => setOpenEnviador(false);
   //Modal Email
   const handleOpenEmail = () => setOpenEmail(true);
   const handleCloseEmail = () => setOpenEmail(false);
   //Modal Enviar Sll
   const handleOpenStateSll = () => setOpenStateSll(true);
   const handleCloseStateSll = () => setOpenStateSll(false);
   //Modal Voucher
   const handleOpenVoucher = () => setOpenVoucher(true);
   const handleCloseVoucher = () => setOpenVoucher(false);

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 750,
      // height: 600,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const rules = useMemo(() => ({
      rut_proveedor: [
         VALIDATORS.REQUIRED,
      ],
      numero_contrato: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      titulo: [
         VALIDATORS.REQUIRED
      ],
      descripcion: [
         VALIDATORS.REQUIRED,
      ]

   }), []);
   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'rut_proveedot':
            props.setvalueContrato(prev => ({ ...prev, rut_proveedot: value }));
            break;
         case 'numero_contrato':
            props.setvalueContrato(prev => ({ ...prev, numero_contrato: value }))
            break;
         case 'titulo':
            props.setvalueContrato(prev => ({ ...prev, titulo: value }))
            break;
         case 'descripcion':
            props.setvalueContrato(prev => ({ ...prev, descripcion: value }));
            break;

         default:
            break;
      }
   };

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }
   return (
      <Protected>
         <Container maxWidth='xl' sx={{height:'100vh'}}>

            <Grid container mb={6}>
               <Grid item xs={12} sm={8}>
                  <Typography variant="h6" id="modal-modal-title" mb={2} className='tittle'>
                     INFORME ESTADO DE CUOTAS
                  </Typography>
                  <Typography variant="subtitle2">
                  Trabajador: 9168943-K - ADOLFO FREDES ARACENA
                  </Typography>
                  <Typography variant="subtitle2">
                  Resumen:
                  </Typography>
               </Grid>               
               <Grid item xs={12} sm={4} textAlign="end">
                  <Button variant="outlined" component={Link} to={ROUTE_RM_RESUME_WORKER} startIcon={<ArrowBackIosIcon />}>
                     Volver
                  </Button>
               </Grid>
            </Grid>            

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
               <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                     <TableHead>
                        <TableRow>
                           <TableCell>Codigo</TableCell>
                           <TableCell>Fecha Vencimiento</TableCell>
                           <TableCell>Monto</TableCell>
                           <TableCell>Estado</TableCell>
                           <TableCell>Fecha Liquidación</TableCell>                                                   
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsListado.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.codigo}>
                                 {columnsPrincipal.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}                                
                              </TableRow>
                           );
                        })}
                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsListado.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>
         </Container>     
      </Protected>
   );
}