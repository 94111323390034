import React, { useState, useEffect } from "react";
import { FunctionComponent } from "react";
import { Protected } from "@/components/layout/Protected";
import "./ElectronicExportDebitNote.sass";
import "./ElectronicExportDebitNote_styles.css";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { LoadingButton } from "@mui/lab";
import { FormControl } from "@mui/material";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { IconButton, Autocomplete } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Stack from "@mui/material/Stack";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { DataRut } from "./Modal/DataRut";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import TablePagination from "@mui/material/TablePagination";
import { Link } from "react-router-dom";
import { ROUTE_F_DE_EX_EMIT_ELECTRONIC_EXPORT_DEBIT_NOTE } from "@/toolbox/constants/route-map";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

interface Column {
   id: "ejecutivo" | "razonsocial" | "fecha" | "asunto" | "contacto" | "email";
   label: string;
   minWidth?: number;
   align?: "right";
   format?: (value: number) => string;
}

export const ElectronicExportDebitNote: FunctionComponent = (props: any) => {
   const [value, setValue] = useState("");

   const [selectTypeResume, setSelectTypeResume] = useState("");
   const [data, setData] = React.useState<Date | null>(new Date());

   const [valueCalendar, setValueCalendar] = React.useState<Date | null>(
      new Date("2018-01-01T00:00:00.000Z")
   );
   const label = { inputProps: { "aria-label": "Checkbox demo" } };

   const handleChangeShow = () => {
      setCambiarEstado(!cambiarEstado);
   };

   //modal Rit
   const [openRut, setOpenRut] = React.useState(false);
   const handleOpenRut = () => setOpenRut(true);
   const handleCloseRut = () => setOpenRut(false);

   //modal articulos
   const [openArticulo, setOpenArticulo] = useState(false);
   const handleOpenArticulo = () => setOpenArticulo(true);
   const handleCloseArticulo = () => setOpenArticulo(false);

   //modal Informe
   const [openInforme, setOpenInforme] = React.useState(false);
   const handleOpenInforme = () => setOpenInforme(true);
   const handleCloseInforme = () => setOpenInforme(false);

   const [cambiarEstado, setCambiarEstado] = useState(false);
   const handleCambiarEstado = () => setCambiarEstado(true);

   const [openAgregar, setOpenAgregar] = useState(false);
   const handleOpenAgregar = () => setOpenAgregar(true);
   const handleCloseAgregar = () => setOpenAgregar(false);

   const [openEditar, setOpenEditar] = useState(false);
   const handleOpenEditar = () => setOpenEditar(true);
   const handleCloseEditar = () => setOpenEditar(false);

   const style = {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 870,
      bgcolor: "background.paper",
      // border: '2px solid #000',
      boxShadow: 24,
      p: 4,
      borderRadius: "20px",
   };

   const handleChange = (event: any) => {
      setValue(event.target.value);
   };
   const handleChangeTypeResume = (event: SelectChangeEvent) => {
      setSelectTypeResume(event.target.value);
   };

   const [page, setPage] = React.useState(0);
   const [rowsPerPage, setRowsPerPage] = React.useState(10);

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
   ) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };
   const condicionPago = [
      { label: "EFECTIVO" },
      { label: "15 DÍAS" },
      { label: "30 DÍAS STE" },
      { label: "OTROS" },
   ];
   const areaNegocio = [
      { label: "Categorizada" },
      { label: "DESARROLLO" },
      { label: "INSUMOS" },
      { label: "OTROS" },
   ];

   const contactoValue = [
      { label: "Santiago Ormeño" },
      { label: "Gianluca Quispedula" },
      { label: "Francisco Mamani" },
      { label: "Pedrito Alcachofas" },
      { label: "La tía Mey" },
   ];
   const centroCosto = [
      { label: "Sin CC" },
      { label: "Desarrollo" },
      { label: "Insumos" },
      { label: "OPERACIONES" },
      { label: "VENTAS" },
   ];
   const transladoValue = [
      { label: "Sin CC" },
      { label: "Desarrollo" },
      { label: "Insumos" },
      { label: "OPERACIONES" },
      { label: "VENTAS" },
   ];
   const vendedorValue = [
      { label: "demo" },
      { label: "Felipe" },
      { label: "Francisco" },
      { label: "Lapadula" },
   ];
   const despachoValue = [
      { label: "Por cuenta del receptor" },
      { label: "Felipe" },
      { label: "Francisco" },
      { label: "Lapadula" },
   ];
   const bodegaOrigen = [
      { label: "VINOS" },
      { label: "ADUANA" },
      { label: "PRINCIPAL" },
      { label: "MERMAS" },
      { label: "TRANSITO" },
      { label: "FARMACIA" },
   ];
   const operadorValue = [
      { label: "Gianluca Quispedula" },
      { label: "Mamani Mamalberto" },
      { label: "Nikki Sixx" },
      { label: "Pedro Castillo" },
      { label: "Harry Popoter" },
   ];
   const direccion = [
      {
         label: "CASA MATRIZ: Dirección Cliente - Comuna Cliente - Ciudad Cliente",
      },
   ];
   const nombreDocumento = [
      {
         label: "Sin Referencia",
      },
   ];

   const tipoDocumento = [
      { label: "Guia de despacho" },
      { label: "Orden de Compra" },
      { label: "Nota de Pedido" },
      { label: "Resolución" },
      { label: "Conformidad" },
   ];

   return (
      <Protected>
         <div className="Generator">
            <Grid container spacing={2}>
               <Grid item xs={12}>
                  <Typography className="tittle" variant="h6" gutterBottom>
                     Ingresando Nota de Debito Exportación Electrónica
                  </Typography>

                  <Typography variant="subtitle2" gutterBottom>
                     Seleccione Cliente
                  </Typography>
               </Grid>

               <Grid item xs={12}>
                  <Grid item container spacing={1}>
                     <Grid item xs={3}>
                        <FormControl fullWidth>
                           <TextField
                              id="input-with-sx"
                              // label="Folio Doc"
                              placeholder="Rut de Cliente..."
                              size="small"
                           />
                        </FormControl>
                     </Grid>

                     <Grid item xs={1}>
                        <Tooltip title="Buscar Cliente">
                           <IconButton
                              onClick={handleOpenRut}
                              className="btnIcoE"
                           >
                              <SearchIcon />
                           </IconButton>
                        </Tooltip>
                     </Grid>

                     <Grid item container xs={8} justifyContent="flex-end">
                        <Button
                           size="small"
                           variant="contained"
                           className="btnSelec"
                           component={Link}
                           to={ROUTE_F_DE_EX_EMIT_ELECTRONIC_EXPORT_DEBIT_NOTE}
                        >
                           Seleccionar
                        </Button>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>

            <Modal
               open={openRut}
               onClose={handleCloseRut}
               aria-labelledby="modal-modal-title"
               aria-describedby="modal-modal-description"
            >
               <Box sx={style}>
                  <DataRut entity={undefined} />
               </Box>
            </Modal>
            {/* <Modal
                  open={openInforme}
                  onClose={handleCloseInforme}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <DataInforme />
                  </Box>
               </Modal> */}
         </div>
      </Protected>
   );
};
