import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { ArrowRightIcon } from "@/toolbox/constants/icons";
import { VALIDATORS } from "@/toolbox/helpers/validation-rules";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, TablePagination, TableFooter, } from '@mui/material';
import { Input } from '@components/common/Input';
import { Props } from "./EvCheckRoyalty.type";
import BusinessIcon from '@mui/icons-material/Business';
import RefreshIcon from '@mui/icons-material/Refresh';
import { InputRef } from '@/components/common/Input/InputInterfaces';
import './EvCheckRoyalty_style.css';


//PRODUCTOS SIN FAMILIA ----- codigo 1
interface Columns {
   id: 'num' | 'tipo' | 'folio' | 'codigo';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}
const columns1: readonly Columns[] = [
   { id: 'num', label: 'Num', minWidth: 100 },
   { id: 'tipo', label: 'Tipo', minWidth: 100 },
   { id: 'folio', label: 'Folio', minWidth: 100 },
   { id: 'codigo', label: 'Codigo', minWidth: 100 }
];
function createData1(
   num: string,
   tipo: string,
   folio: string,
   codigo: string
) {
   return { num, tipo, folio, codigo };
}
const rowsListado1 = [
   createData1('1', '33', '5048', 'SOF80'),
   createData1('2', '28', '1048', 'SOF20'),
   createData1('3', '33', '528', 'SOF80'),
]
//ITEMS SIN CODIGO DE PRODUCTO ----- codigo 2
interface Column2 {
   id: 'num' | 'tipo' | 'folio';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}
const columns2: readonly Column2[] = [
   { id: 'num', label: 'Num', minWidth: 100 },
   { id: 'tipo', label: 'Tipo', minWidth: 100 },
   { id: 'folio', label: 'Folio', minWidth: 100 },
];
function createData2(
   num: string,
   tipo: string,
   folio: string,
) {
   return { num, tipo, folio };
}
const rowsListado2 = [
   createData2('1', '33', '5048'),
   createData2('2', '28', '1048'),
   createData2('3', '33', '528'),
]
//Códigos de productos sin crear en maestro ----- codigo 3
interface Column3 {
   id: 'num' | 'tipo' | 'folio' | 'codigo';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}
const columns3: readonly Column3[] = [
   { id: 'num', label: 'Num', minWidth: 100 },
   { id: 'tipo', label: 'Tipo', minWidth: 100 },
   { id: 'folio', label: 'Folio', minWidth: 100 },
   { id: 'codigo', label: 'Codigo', minWidth: 100 }
];
function createData3(
   num: string,
   tipo: string,
   folio: string,
   codigo: string
) {
   return { num, tipo, folio, codigo };
}
const rowsListado3 = [
   createData1('1', '33', '5048', 'PLA-P'),
   createData1('2', '28', '1048', 'PL4'),
   createData1('3', '33', '528', 'PL4'),
   createData1('4', '33', '5048', 'PLA-P'),
   createData1('5', '33', '5048', 'PLA-P'),
   createData1('6', '28', '1048', 'PL4'),
   createData1('7', '33', '528', 'PL4'),
   createData1('8', '33', '5048', 'PLA-P'),
]


export const EvCheckRoyalty: React.FC<Props> = (props: Props): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);

   const [siguiente, setSiguiente] = useState(false);

   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [open, setOpen] = useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);

   const handleSiguiente = () => setSiguiente(true);

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };


   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const [valueContrato, setvalueContrato] = useState({
      rut_proveedor: '',
      titulo: '',
      numero_contrato: '',
      descripcion: '',
   });


   const periodo = [
      { label: '2022' },
      { label: '2021' },
      { label: '2020' },
      { label: '2019' },
      { label: '2018' },
      { label: '2017' },
      { label: '2016' },
      { label: '2015' },
   ]
   const mes = [
      { label: 'Enero' },
      { label: 'Febrero' },
      { label: 'Marzo' },
      { label: 'Abril' },
      { label: 'Mayo' },
      { label: 'Junio' },
      { label: 'Agosto' },
      { label: 'Setiembre' },
      { label: 'Octubre' },
      { label: 'Noviembre sin ti' },
      { label: 'Diciembre' },
      { label: 'TODOS' }
   ]
   const seleccionOpcion = [
      { label: 'Petroleo' },
      { label: 'Agua de' },
      { label: 'Huevos' },
      { label: 'XLR8' },
      { label: 'Jiji' },
      { label: 'Otro mas' }
   ]

   const rules = useMemo(() => ({
      rut_proveedor: [
         VALIDATORS.REQUIRED,
      ],
      numero_contrato: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      titulo: [
         VALIDATORS.REQUIRED
      ],
      descripcion: [
         VALIDATORS.REQUIRED,
      ]

   }), []);
   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'rut_proveedot':
            props.setvalueContrato(prev => ({ ...prev, rut_proveedot: value }));
            break;
         case 'numero_contrato':
            props.setvalueContrato(prev => ({ ...prev, numero_contrato: value }))
            break;
         case 'titulo':
            props.setvalueContrato(prev => ({ ...prev, titulo: value }))
            break;
         case 'descripcion':
            props.setvalueContrato(prev => ({ ...prev, descripcion: value }));
            break;

         default:
            break;
      }
   };

   // const handleInputBlur = (event: any) => {
   //    const newVal = (event.target.value).trim();
   //    const name = event.target.name;
   //    props.setvalueContrato(prev => ({ ...prev, [name]: newVal }));
   // }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }

   function createData(
      tipo: string,
      total: string,
      generadas: string,
      vigentes: string,
      nulas: string,
      adjudicadas: string,
      noAdjudicadas: string,
   ) {
      return { tipo, total, generadas, vigentes, nulas, adjudicadas, noAdjudicadas };
   }

   const rows = [
      createData('Cotizaciones Electrónicas', '16', '', '12', '', '4', '')
   ];

   return (
      <Protected >

         <Container maxWidth='xl' style={{ minHeight: '100vh' }}>
            <form style={{ marginBottom: '25px' }}>
               <Typography variant="h6" gutterBottom mb={3} className="tittle">
                  CARGA DE DATOS ROYALTY
               </Typography>

               <Grid item container m={2} xs={12} >
                  <Typography variant="subtitle1" >
                     Seleccione Informe Segun Corresponda
                  </Typography>
               </Grid>


               <Grid container spacing={3} className={`e-mb-lg`} >
                  <Grid item xs={12} sm={3}>
                     <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={periodo}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Periodo" />}
                     />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                     <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={mes}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Mes" />}
                     />
                  </Grid>
                  <Grid item container
                     xs={12}
                     justifyContent='right'
                  >
                     <Grid item>
                        <Button
                           // type='submit'
                           variant='contained'
                           onClick={handleSiguiente}
                           disabled={loading}
                           disableElevation
                           className='btn_save'
                        >
                           <span>Cargar y Verificar Datos</span>
                        </Button>

                     </Grid>
                  </Grid>
               </Grid>
            </form>

            {siguiente && (
               <Grid container m={2}>                  
                  {/* PRIMERA TABLA */}
                  <Grid item xs={12} mb={5}>
                     <div className='bg-tittle'>
                        <Typography
                           mt={3}
                           variant="h6"
                           align='center'
                           className='tittle-text'
                        >1. Códigos de productos sin familia asignada
                        </Typography>
                     </div>
                  </Grid>
                  <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                     <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                           <TableHead>
                              <TableRow>
                                 <TableCell>Num</TableCell>
                                 <TableCell>Tipo Documento</TableCell>
                                 <TableCell>Folio</TableCell>
                                 <TableCell>Codigo Producto</TableCell>
                              </TableRow>
                           </TableHead>
                           <TableBody>
                              {rowsListado1.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                 return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.num}>
                                       {columns1.map((column) => {
                                          const value = row[column.id];
                                          return (
                                             <TableCell key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number'
                                                   ? column.format(value)
                                                   : value}
                                             </TableCell>
                                          );
                                       })}
                                    </TableRow>
                                 );
                              })}
                           </TableBody>
                        </Table>
                     </TableContainer>
                     <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rowsListado1.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                     />
                  </Paper>
                  {/* SEGUNDA TABLA */}
                  <Grid item xs={12} mb={5}>
                     <div className='bg-tittle'>
                        <Typography
                           mt={3}
                           variant="h6"
                           align='center'
                           className='tittle-text'
                        >2. Item(s) sin código producto
                        </Typography>
                     </div>
                  </Grid>
                  <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                     <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                           <TableHead>
                              <TableRow>
                                 <TableCell>Num</TableCell>
                                 <TableCell>Tipo Documento</TableCell>
                                 <TableCell>Folio</TableCell>
                                 <TableCell>Opción</TableCell>
                              </TableRow>
                           </TableHead>
                           <TableBody>
                              {rowsListado2.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                 return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.num}>
                                       {columns2.map((column) => {
                                          const value = row[column.id];
                                          return (
                                             <TableCell key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number'
                                                   ? column.format(value)
                                                   : value}
                                             </TableCell>
                                          );
                                       })}
                                       <TableCell sx={{ maxWidth: 80 }}>
                                          <Grid container xs={12}>
                                             <Grid item xs={8}>
                                                <Autocomplete
                                                   disablePortal
                                                   size='small'
                                                   id="combo-box-demo"
                                                   options={seleccionOpcion}
                                                   // sx={{ bgcolor: '#F3F3F3'}}
                                                   renderInput={(params) => <TextField {...params} label="Seleccione" />}
                                                />
                                             </Grid>
                                             <Grid item xs={3} ml={1}>
                                                <Tooltip title="Actualizar">
                                                   <IconButton>
                                                      <RefreshIcon />
                                                   </IconButton>
                                                </Tooltip>
                                             </Grid>
                                          </Grid>
                                       </TableCell>
                                    </TableRow>
                                 );
                              })}
                           </TableBody>
                        </Table>
                     </TableContainer>
                     <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rowsListado2.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                     />
                  </Paper>
                  {/* TERCERA TABLA */}
                  <Grid item xs={12} mb={5}>
                     <div className='bg-tittle'>
                        <Typography
                           mt={3}
                           variant="h6"
                           align='center'
                           className='tittle-text'
                        >3. Códigos de productos sin crear en maestro
                        </Typography>
                     </div>
                  </Grid>
                  <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                     <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                           <TableHead>
                              <TableRow>
                                 <TableCell>Num</TableCell>
                                 <TableCell>Tipo Documento</TableCell>
                                 <TableCell>Folio</TableCell>
                                 <TableCell>Código Producto</TableCell>
                                 <TableCell>Opción</TableCell>
                              </TableRow>
                           </TableHead>
                           <TableBody>
                              {rowsListado3.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                 return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.num}>
                                       {columns3.map((column) => {
                                          const value = row[column.id];
                                          return (
                                             <TableCell key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number'
                                                   ? column.format(value)
                                                   : value}
                                             </TableCell>
                                          );
                                       })}
                                       <TableCell sx={{ maxWidth: 80 }}>
                                          <Grid container xs={12}>
                                             <Grid item xs={8}>
                                                <Autocomplete
                                                   disablePortal
                                                   size='small'
                                                   id="combo-box-demo"
                                                   options={seleccionOpcion}
                                                   // sx={{ bgcolor: '#F3F3F3'}}
                                                   renderInput={(params) => <TextField {...params} label="Seleccione" />}
                                                />
                                             </Grid>
                                             <Grid item xs={3} ml={1}>
                                                <Tooltip title="Actualizar">
                                                   <IconButton>
                                                      <RefreshIcon />
                                                   </IconButton>
                                                </Tooltip>
                                             </Grid>
                                          </Grid>
                                       </TableCell>
                                    </TableRow>
                                 );
                              })}
                           </TableBody>
                        </Table>
                     </TableContainer>
                     <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rowsListado3.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                     />
                  </Paper>
               </Grid>

            )}
         </Container>

      </Protected>
   );
}