import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField,Modal ,Box, Divider, InputAdornment } from '@mui/material';
import { ArrowBackIcon, ArrowDownIcon, ArrowRightIcon, MoreIcon } from "@toolbox/constants/icons";
import Entity  from "@assets/img/entity.png";
import { Link } from 'react-router-dom';
import { indigo } from '@mui/material/colors';
import { useHistory } from 'react-router-dom'
import { Props } from './FormContactEntity.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
// import { entityService } from '@/service/services/Entity.service';
import BadgeIcon from '@mui/icons-material/Badge';
import PersonIcon from '@mui/icons-material/Person';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import PaidIcon from '@mui/icons-material/Paid';

export const FormContactEntity: React.FC<Props> = (
   props: Props
): JSX.Element => {

   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [openModal,setOpenModal] = useState(false)
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [estado, setEstado] = useState<any>({email_cto:false})
   console.log(props.valueContactEntity)

   const topFormaPago = [
      { label: 'Forma De Pago 1'},
      { label: 'Forma De Pago 2'},
      { label: 'Forma De Pago 3'},
      { label: 'Forma De Pago 4'}
   ]
   const topVendedor = [
      { label: 'Vendedor 1'},
      { label: 'Vendedor 2'},
      { label: 'Vendedor 3'},
      { label: 'Vendedor 4'}
   ]
   const topRubro = [
      { label: 'Rubro 1'},
      { label: 'Rubro 2'},
      { label: 'Rubro 3'},
      { label: 'Rubro 4'}
   ]
   const topTransporte = [
      { label: 'Auto'},
      { label: 'Bus'}
   ]

   useEffect(() => {
      if(props.valueContactEntity.action)
      {
         if (props.valueContactEntity.action == 'view') {
            setIsDisabled(true);
         }
      }
   }, [])
   const rules = useMemo(() => ({
      string_lowecase: [
         VALIDATORS.REQUIRED,
         VALIDATORS.ONLY_LOWERCASE,
      ],
      string_required: [
         VALIDATORS.REQUIRED,
      ],
      email: [
         VALIDATORS.EMAIL.NAME_PONTS,
         VALIDATORS.EMAIL.POINTS_IN_A_ROW,
         VALIDATORS.EMAIL.NAME_SIZE,
         VALIDATORS.EMAIL.VALID,
      ],
   }), []);

   const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'nombre_cto':
            props.setValueContactEntity(prev => ({ ...prev, nombre_cto: value, textError: '' }))
            break;
         case 'cargo_cto':
            props.setValueContactEntity(prev => ({ ...prev, cargo_cto: value, textError: '' }))
            break;
         case 'email_cto':
            if ((/^[\w@.-]{0,86}$/).test(value)) {
               props.setValueContactEntity(prev => ({ ...prev, email_cto: value, textError: '' }));
            }
            props.setValueContactEntity(prev => ({ ...prev, email_cto: value, textError: '' }));
            break;
         case 'telefono_cto':
            props.setValueContactEntity(prev => ({ ...prev, telefono_cto: value, textError: '' }));
            break;
         case 'celular_cto':
            props.setValueContactEntity(prev => ({ ...prev, celular_cto: value, textError: '' }));
            break;
         case 'cobranza_cto':
            props.setValueContactEntity(prev => ({ ...prev, cobranza_cto: value, textError: '' }));
            break;
         default:
            break;
      }
   };
   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      props.setValueContactEntity(prev => ({ ...prev, [name]: newVal }));
   }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      props.handleNext();
   }


   const validate = () => {
      const refs = inputRefs.current;
      console.log(refs)
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }
   return (
      <Container maxWidth="xl">
         {/* <form onSubmit={handleSubmit} > */}
         <form >
            <Grid container spacing={3} className={`e-mb-lg`}>
               <Grid item xs={12}></Grid>
               <Grid item xs={4}>
                  <Typography variant='h6'>Forma de Pago</Typography>
               </Grid>
               <Grid item xs={12} md={5}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={topFormaPago}
                     sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Seleccionar" />}
                  />
               </Grid>
               <Grid item xs={4}>
                  <Typography variant='h6'>Vendedor</Typography>
               </Grid>
               <Grid item xs={12} md={5}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={topVendedor}
                     sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Seleccionar" />}
                  />
               </Grid>
               <Grid item xs={4}>
                  <Typography variant='h6'>Rubro</Typography>
               </Grid>
               <Grid item xs={12} md={5}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={topRubro}
                     sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Seleccionar" />}
                  />
               </Grid>
               <Grid item xs={4}>
                  <Typography variant='h6'>Transporrte / Entrega</Typography>
               </Grid>
               <Grid item xs={12} md={5}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={topTransporte}
                     sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Seleccionar" />}
                  />
               </Grid>
               <Grid item xs={12}></Grid>
               <Grid container item xs={12}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center">
                  <Grid item >
                     <Button
                        fullWidth={true}
                        variant='contained'
                        onClick={props.handleBack}
                        disabled={loading}
                        disableElevation
                        startIcon={<ArrowBackIosIcon/>}
                     >
                        <span>Volver</span>
                     </Button>
                  </Grid>

                  <Grid item >
                     <Button
                        fullWidth={true}
                        type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                        endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>Aceptar y Continuar</span>
                        }
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
         </form>
         <Modal
            open={openModal}
            //onClose={() => { setOpenModal(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={{
               position: 'absolute',
               top: '50%',
               left: '50%',
               transform: 'translate(-50%, -80%)',
               width: 300,
               bgcolor: '#ffffff',
               boxShadow: 24,
               p: 4,
            }}>
               <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
                  <Box
                     component="img"
                     textAlign="center"
                     sx={{
                        width: "100px",
                        height: "100px",
                     }}
                     src={Entity}
                  ></Box>
               </Grid>
               <Divider />
               <Typography id="modal-modal-description" textAlign='center' sx={{ mt: 2, mb: 2 }}>
                  {`El ${estado.email_cto ? 'Email' : ''} está registrado, Ingrese correctamente los datos de la empresa`}
               </Typography>
               <Divider />
               <Grid container item xs={12} sx={{ pt: 2 }}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center">
                  <Grid item>
                     <Button
                        fullWidth
                        variant='contained'
                        onClick={() => { setOpenModal(false) }}
                     >
                        Aceptar
                     </Button>
                  </Grid>
               </Grid>
            </Box>
         </Modal>
      </Container>
   );
}
