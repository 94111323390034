import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, } from '@mui/material'
import {  useState } from "react";
import {Props} from "./GcProductsList.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './GcProductsList_styles.css'
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';


interface Column {
   id: 'codigoProducto' | 'descripcion' | 'precio' | 'inventario' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

const columns:readonly Column[] = [
      { id: 'codigoProducto', label: 'Codigo Producto', minWidth: 100 },
      { id: 'descripcion', label: 'Descripcion', minWidth: 150 },
      { id: 'precio', label: 'Precio', minWidth: 100, format: (value: number) => value.toFixed(2),},
      { id: 'inventario', label: 'Inventario', minWidth: 100, format: (value: number) => value.toFixed(2),},
   ];

interface Data {
   codigoProducto: string;
   descripcion: string;
   precio: number;
   inventario: number;
}

function createDataProducts(
codigoProducto: string,
descripcion: string,
precio: number,
inventario: number,
):Data {
return { codigoProducto, descripcion, precio, inventario };
}

const rowsDataProducts = [
createDataProducts('1', 'Producto 1', 100, 100),
createDataProducts('2', 'Producto 2', 200, 200),
createDataProducts('3', 'Producto 3', 300, 300),
createDataProducts('4', 'Producto 4', 400, 400),
createDataProducts('5', 'Producto 5', 500, 500),
createDataProducts('6', 'Producto 6', 600, 600),
createDataProducts('7', 'Producto 7', 700, 700),
createDataProducts('8', 'Producto 8', 800, 800),
createDataProducts('9', 'Producto 9', 900, 900),
createDataProducts('10', 'Producto 10', 1000, 1000),
]

export const GcProductsList:React.FC<Props> = (props: Props): JSX.Element => {

   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);

   const [loading, setLoading] = useState(false);
   function handleClick() {
     setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

    return (
        <Protected>
        <Container maxWidth='xl'>
           <Grid container spacing={3}>
               <Grid item xs={6} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        LISTA DE PRODUCTOS
                     </Typography>
                  </Box>
                  
               </Grid>
                     
               <Grid item xs={6} className="btn-download">
                  <Box pb={2}>
                  <LoadingButton
                     color="warning"
                     onClick={handleClick}
                     loading={loading}
                     loadingPosition="start"
                     startIcon={<FileDownloadIcon />}
                     variant="contained"
                     className="btn-download"
                     >
                     Exportar
                     </LoadingButton>
                  </Box>
               </Grid>
            </Grid>       

            <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center">
               LISTA DE PRODUCTOS INGRESADOS
            </Typography>

            <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:440}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           <TableCell>Codigo Producto</TableCell>
                           <TableCell>Descripcion</TableCell>
                           <TableCell>Precio</TableCell>
                           <TableCell>Inventario</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsDataProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.codigoProducto}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                              </TableRow>
                           );
                        })}
                     </TableBody>   
                  </Table>  
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsDataProducts.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
            </Paper>


            
         </Container>
     </Protected>

    );
};

