import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, } from '@mui/material'
import { useState } from "react";
import { Props } from "../LsBooksSalesBook.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import '../LsBooksSalesBook_styles.css'
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { AddCircle } from "@mui/icons-material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BookIcon from '@mui/icons-material/Book';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

interface Column {
   id: 'bodega' | 'detalle' | 'cantidad'|'valor' | 'tipo'| 'valorDescuento' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }
 const columns:readonly Column[] = [
   { id: 'bodega', label: 'Bodega', minWidth: 10 },
   { id: 'detalle', label: 'Detalle', minWidth: 50 },
   { id: 'cantidad', label: 'Cantidad', minWidth: 5 },
   { id: 'valor', label: 'Valor Descuento', minWidth: 10 },
   { id: 'tipo', label: 'Tipo', minWidth: 10 },
   { id: 'valorDescuento', label: 'Valor Descuento', minWidth: 10 },

   ];


export const DataEditarCotizacion: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [ejecutivoComercial, setEjecutivoComercial] = useState('');
   const [estado, setEstado] = useState('');


   const handleChangeEjecutivoComercial = (event: SelectChangeEvent) => {
      setEjecutivoComercial(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const estados=[
      {label:'No Adjudicada',value:'Enviado'},
      {label:'Adjudicada',value:'Adjudicada'},
      {label:'Anulada',value:'No Adjudicada'},
   ]
   return (
         <Container maxWidth='xl'>   
           <Grid item xs={12} md={12} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        EDITAR DETALLE COTIZACION
                     </Typography>
                  </Box>
                  <Box>
                     <Typography variant='subtitle2'  gutterBottom >
                     Actualizar Datos segun Corresponda                        
                      </Typography>
                  </Box>
            </Grid>   

            <Paper sx={{ width: '100%', overflow: 'hidden'}} >
               
               <TableContainer sx={{maxHeight:440}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                        {columns.map((column) => (
                           <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                           >
                              {column.label}
                           </TableCell>
                        ))}
                        </TableRow>
                     </TableHead>
                     <TableBody>
                      <TableRow>
                           <TableCell>
                              <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Seleccione</InputLabel>
                              <Select
                                 labelId="demo-simple-select-label"
                                 id="demo-simple-select"
                                 // label="fdffd%"
                                 // onChange={handleChange}
                                 size="small"
                              >
                                 <MenuItem value={10}>Bodega 1</MenuItem>
                                 <MenuItem value={20}>Bodega 2</MenuItem>
                                 <MenuItem value={30}>Bodega 3</MenuItem>

                              </Select>
                              </FormControl>

                           </TableCell>
                           <TableCell>
                              <TextField
                                 id="rut"
                                 // label="ORDEN DE COMPRA"
                                 type="text"
                                 fullWidth
                                 variant="outlined"
                                 size="small"
                                 // defaultValue="jmora@test.cl"
                              />
                           </TableCell>
                           <TableCell>
                              <TextField
                                 id="rut"
                                 // label="ORDEN DE COMPRA"
                                 type="text"
                                 fullWidth
                                 variant="outlined"
                                 size="small"
                                 // defaultValue="jmora@test.cl"
                              />
                           </TableCell>
                           <TableCell>
                              <TextField
                                 id="rut"
                                 // label="ORDEN DE COMPRA"
                                 type="text"
                                 fullWidth
                                 variant="outlined"
                                 size="small"
                                 // defaultValue="jmora@test.cl"
                              />
                           </TableCell>
                           <TableCell>
                           <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">%</InputLabel>
                              <Select
                                 labelId="demo-simple-select-label"
                                 id="demo-simple-select"
                                 // label="fdffd%"
                                 // onChange={handleChange}
                                 size="small"
                              >
                                 <MenuItem value={10}>%</MenuItem>
                                 <MenuItem value={20}>$</MenuItem>

                              </Select>
                              </FormControl>
                           </TableCell>
                           <TableCell>
                              <TextField
                                 id="rut"
                                 // label="ORDEN DE COMPRA"
                                 type="text"
                                 fullWidth
                                 variant="outlined"
                                 size="small"
                                 defaultValue="0"
                              />
                           </TableCell>
                          
                     
                        </TableRow>
                     </TableBody>   
                  </Table>  
               </TableContainer>
               
            </Paper> 
         </Container>
   );
};

