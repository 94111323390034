import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "./Monthly.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './Monthly_style.css'
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import { MonthAndPeriod } from "@/views/CommercialGesture/SelesStatistics/EvSaleDates/Create/MonthAndPeriod/MonthAndPeriod";
import { MonthAndYear } from "./TypeData/MonthAndYear";
import { SheetsRouteSeller } from "./TypeData/SheetsRouteSeller";

export const Monthly:React.FC<Props> = (props: Props): JSX.Element => {
   const [value, setValue] = useState<Date | null>(new Date());
   const [openAbsense, setOpenAbsense] = useState(false);
   const [openTardie, setOpenTardie] = useState(false);

   const handleOpenAbsense = () => {
      setOpenAbsense(true);
      setOpenTardie(false);
   }
   const handleCloseAbsense = () => setOpenAbsense(false);

   const handleOpenTardie = () => {
      setOpenTardie(true);
      setOpenAbsense(false);
   }
   const handleCloseTardie = () => setOpenTardie(false);

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 920,
      height:650,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };
   const vendedores=[
      {label:'Juan Perez', id:1},
      {label:'Juan Perez', id:2},
      {label:'Juan Perez', id:3},
      {label:'Juan Perez', id:4},
   ]
    return (
        <Protected>
        <Container maxWidth='xl'>
           <Grid container spacing={3} mb={6}>
               <Grid item xs={12} md={12} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        RESUMEN DE EQUIPO DE VENTA
                     </Typography>
                  </Box>
                  <Typography  variant="caption" display="block" gutterBottom mt={1}>
                     Resumen de EQuipo de Venta
                  </Typography>
                  
               </Grid>
               <Grid item xs={12} sm={6}>
                  <Grid container spacing={3}>
                     <Grid item xs={12}>
                        <Typography variant='subtitle2'  gutterBottom  align="center" style={{background:'#ff9700', color:'#ffffff', borderRadius:'5px'}}  p={1}>
                           MES Y PERIODO
                        </Typography>
                     </Grid>
                     
                     <Grid item xs={12} md={8}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                           <Stack spacing={3}>
                              <DatePicker
                                    views={['year', 'month']}
                                    label="Año y Mes"
                                    minDate={new Date('2012-03-01')}
                                    maxDate={new Date('2023-06-01')}
                                    value={value}
                                    onChange={(newValue) => {
                                       setValue(newValue);
                                    }}
                                    renderInput={(params) => <TextField  {...params} helperText={null} />}
                                    />
                           </Stack>
                        </LocalizationProvider>
                     </Grid>
                     
                     <Grid item xs={12} md={4}>
                        <Button
                           type='submit'
                           variant='contained'
                           onClick={handleOpenAbsense}
                           // disabled={loading}
                           disableElevation
                           className='btn_next'
                           style={{backgroundColor:'#16556b', color:'#fff'}}
                           >
                           <span>SIGUIENTE</span>
                        </Button>
                     </Grid>
                  </Grid>
               </Grid>

               <Grid item xs={12} md={6}>
                  <Grid container spacing={3}>
                     <Grid item xs={12}>
                        <Typography variant='subtitle2'  gutterBottom  align="center" style={{background:'#ff9700', color:'#ffffff', borderRadius:'5px'}}  p={1}>
                           HOJAS DE RUTAS POR VENDEDOR
                        </Typography>
                     </Grid>
                     
                     <Grid item xs={12} md={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                           <Stack spacing={3}>
                              <DatePicker
                                    views={['year', 'month']}
                                    label="Año y Mes"
                                    minDate={new Date('2012-03-01')}
                                    maxDate={new Date('2023-06-01')}
                                    value={value}
                                    onChange={(newValue) => {
                                       setValue(newValue);
                                    }}
                                    renderInput={(params) => <TextField  {...params} helperText={null} />}
                                    />
                           </Stack>
                        </LocalizationProvider>
                     </Grid>

                     <Grid item xs={12} md={4}>
                        <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={vendedores}
                        renderInput={(params) => <TextField {...params} label="Centro Beneficio" />}
                        />
                     </Grid>
                     
                     <Grid item xs={12} md={4} alignItems="end">
                        <Button
                           type='submit'
                           variant='contained'
                           onClick={handleOpenTardie}
                           size='small'
                           // disabled={loading}
                           // disableElevation
                           className='btn_next'
                           style={{backgroundColor:'#16556b', color:'#fff'}}
                           >
                           <span>SIGUIENTE</span>
                        </Button>
                     </Grid>

                  </Grid>
               </Grid>

            </Grid>

            {openAbsense&&<MonthAndYear entity={undefined} />||""}
               
            {openTardie && <SheetsRouteSeller
            entity={undefined}
            />||""}
            
         </Container>
     </Protected>
    );
   };

