import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { ArrowRightIcon } from "@/toolbox/constants/icons";
import { VALIDATORS } from "@/toolbox/helpers/validation-rules";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Grid, Container, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, } from '@mui/material';
import { Input } from '@components/common/Input';
import { Props } from "./GcVariousConfigurations.type";
import BusinessIcon from '@mui/icons-material/Business';
import { InputRef } from '@/components/common/Input/InputInterfaces';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';      

import './GcVariousConfigurations_style.css';
import PersonIcon from '@mui/icons-material/Person';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import List from '@mui/material/List';
import CategoryIcon from '@mui/icons-material/Category';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

export const GcVariousConfigurations: React.FC<Props> = (props: Props): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);

   const style = {
      width: '100%',
      maxWidth: 360,
      bgcolor: 'background.paper',
   };

   const [valueContrato, setvalueContrato] = useState({
      rut_proveedor: '',
      titulo: '',
      numero_contrato: '',
      descripcion: '',
   });


   const rules = useMemo(() => ({
      rut_proveedor: [
         VALIDATORS.REQUIRED,
      ],
      numero_contrato: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      titulo: [
         VALIDATORS.REQUIRED
      ],
      descripcion: [
         VALIDATORS.REQUIRED,
      ]

   }), []);
   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'rut_proveedot':
            props.setvalueContrato(prev => ({ ...prev, rut_proveedot: value }));
            break;
         case 'numero_contrato':
            props.setvalueContrato(prev => ({ ...prev, numero_contrato: value }))
            break;
         case 'titulo':
            props.setvalueContrato(prev => ({ ...prev, titulo: value }))
            break;
         case 'descripcion':
            props.setvalueContrato(prev => ({ ...prev, descripcion: value }));
            break;

         default:
            break;
      }
   };

   // const handleInputBlur = (event: any) => {
   //    const newVal = (event.target.value).trim();
   //    const name = event.target.name;
   //    props.setvalueContrato(prev => ({ ...prev, [name]: newVal }));
   // }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }
   return (
      <Protected>
         <Container maxWidth='xl' sx={{height:'100vh'}}>
            <form style={{ margin: '8px' }}>
               <Typography variant="h6" gutterBottom mb={3} className="tittle">
                  CONFIGURACIONES VARIAS
               </Typography>

               <Grid container spacing={3} className={`e-mb-lg`} >
                  <Grid item xs={12} sm={3}>
                     <Grid item container className='bg-tittle' sx={{ display: 'flex'}} p={1}>
                        <Grid item xs={2} >
                           <IconButton aria-label="delete">
                              <FindInPageIcon sx={{color:'white'}}/>                           
                           </IconButton>
                        </Grid>
                        <Grid item xs={8}>
                           <Typography gutterBottom variant="h5" component="div">
                              Cotizaciones
                           </Typography>
                        </Grid>
                     </Grid>
                     <List sx={style} component="nav" aria-label="mailbox folders">
                        <ListItem button>
                           <ListItemText primary="Razón de no Adjuntación" />
                        </ListItem>
                        <Divider />
                        <ListItem button divider>
                           <ListItemText primary="Razón de Anulación" />
                        </ListItem>
                        <ListItem button>
                           <ListItemText primary="Estado de Oportunidad" />
                        </ListItem>
                        <Divider light />
                        <ListItem button>
                           <ListItemText primary="Estado de Cotización" />
                        </ListItem>
                     </List>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                     <Grid item container className='bg-tittle' sx={{ display: 'flex'}} p={1}>
                        <Grid item xs={2} >
                           <IconButton aria-label="delete">
                              <ShoppingCartIcon sx={{color:'white'}}/>                                                  
                           </IconButton>
                        </Grid>
                        <Grid item xs={8}>
                           <Typography gutterBottom variant="h5" component="div">
                              Proveedores
                           </Typography>
                        </Grid>
                     </Grid>
                     <List sx={style} component="nav" aria-label="mailbox folders">
                        <ListItem button>
                           <ListItemText primary="Rubros" />
                        </ListItem>                        
                     </List>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                     <Grid item container className='bg-tittle' sx={{ display: 'flex'}} p={1}>
                        <Grid item xs={2} >
                           <IconButton aria-label="delete">
                              <PersonIcon sx={{color:'white'}}/>                           
                           </IconButton>
                        </Grid>
                        <Grid item xs={8}>
                           <Typography gutterBottom variant="h5" component="div">
                              Clientes
                           </Typography>
                        </Grid>
                     </Grid>
                     <List sx={style} component="nav" aria-label="mailbox folders">
                        <ListItem button>
                           <ListItemText primary="Segmentación de Clientes" />
                        </ListItem>
                        <Divider />
                        <ListItem button divider>
                           <ListItemText primary="Motivo de Bloqueo Comercial" />
                        </ListItem>
                        <ListItem button>
                           <ListItemText primary="Transportes" />
                        </ListItem>                        
                     </List>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                     <Grid item container className='bg-tittle' sx={{ display: 'flex'}} p={1}>
                        <Grid item xs={2} >
                           <IconButton aria-label="delete">
                              <CategoryIcon sx={{color:'white'}}/>                                                        
                           </IconButton>
                        </Grid>
                        <Grid item xs={8}>
                           <Typography gutterBottom variant="h5" component="div">
                              Productos
                           </Typography>
                        </Grid>
                     </Grid>
                     <List sx={style} component="nav" aria-label="mailbox folders">
                        <ListItem button>
                           <ListItemText primary="Origen" />
                        </ListItem>
                        <Divider />
                        <ListItem button divider>
                           <ListItemText primary="Destino" />
                        </ListItem>
                        <ListItem button>
                           <ListItemText primary="Canal de Ventas" />
                        </ListItem>
                        <Divider light />
                        <ListItem button>
                           <ListItemText primary="Marca Producto" />
                        </ListItem>
                        <Divider light />
                        <ListItem button>
                           <ListItemText primary="Colores" />
                        </ListItem>
                        <Divider light />
                        <ListItem button>
                           <ListItemText primary="Tallas" />
                        </ListItem>                        
                     </List>
                  </Grid>
               </Grid>
            </form>
         </Container>
      </Protected>
   );
}