import { Protected } from "@/components/layout/Protected";
import {
   Box,
   Grid,
   Container,
   Typography,
   IconButton,
   SelectChangeEvent,
   Autocomplete,
   Button,
} from "@mui/material";
import { useState } from "react";
import { Props } from "../EmitElectronicDispatchGuide.type";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import "../ElectronicDispatchGuide_styles.css";
import TextField from "@mui/material/TextField";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import LoadingButton from "@mui/lab/LoadingButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { AddCircle, PictureAsPdf } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BookIcon from "@mui/icons-material/Book";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

interface Column {
   id:
      | "numero"
      | "fecha"
      | "folio"
      | "tipo"
      | "codigo"
      | "detalle"
      | "cantidad"
      | "precio";
   label: string;
   minWidth?: number;
   align?: "right";
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: "numero", label: "Número", minWidth: 10 },
   { id: "fecha", label: "Fecha", minWidth: 10 },
   { id: "folio", label: "Folio", minWidth: 10 },
   { id: "tipo", label: "Tipo Doc", minWidth: 10 },
   { id: "codigo", label: "Código", minWidth: 10 },
   { id: "detalle", label: "Detalle Producto", minWidth: 100 },
   { id: "cantidad", label: "Cantidad", minWidth: 10 },
   { id: "precio", label: "Precio", minWidth: 10 },
];

interface Data {
   numero: string;
   fecha: string;
   folio: string;
   tipo: string;
   codigo: string;
   detalle: string;
   cantidad: string;
   precio: string;
}

function createData(
   numero: string,
   fecha: string,
   folio: string,
   tipo: string,
   codigo: string,
   detalle: string,
   cantidad: string,
   precio: string
): Data {
   return { numero, fecha, folio, tipo, codigo, detalle, cantidad, precio };
}

const rowsData = [
   createData("1", "01/01/2020", "1", "Factura", "1", "Producto 1", "1", "$1"),
   createData("2", "01/01/2020", "1", "Factura", "1", "Producto 1", "1", "$1"),
   createData("3", "01/01/2020", "1", "Factura", "1", "Producto 1", "1", "$1"),
   createData("4", "01/01/2020", "1", "Factura", "1", "Producto 1", "1", "$1"),
   createData("5", "01/01/2020", "1", "Factura", "1", "Producto 1", "1", "$1"),
   createData("6", "01/01/2020", "1", "Factura", "1", "Producto 1", "1", "$1"),
   createData("7", "01/01/2020", "1", "Factura", "1", "Producto 1", "1", "$1"),
];

export const DataInformsFinal: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState("");
   const [loading, setLoading] = useState(false);
   const [ejecutivoComercial, setEjecutivoComercial] = useState("");
   const [estado, setEstado] = useState("");

   const handleChangeEjecutivoComercial = (event: SelectChangeEvent) => {
      setEjecutivoComercial(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
   ) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const estados = [
      { label: "No Adjudicada", value: "Enviado" },
      { label: "Adjudicada", value: "Adjudicada" },
      { label: "Anulada", value: "No Adjudicada" },
   ];
   return (
      <Container maxWidth="xl">
         <Grid container spacing={3}>
            <Grid item xs={12} md={9}>
               <Box>
                  <Typography
                     variant="h6"
                     component="h1"
                     gutterBottom
                     className="tittle"
                  >
                     INFORME DE VENTAS DE PRODUCTOS POR CLIENTES
                  </Typography>
               </Box>
               <Box>
                  <Typography variant="subtitle2" gutterBottom>
                     Detalle de productos por Clientes
                  </Typography>
               </Box>
            </Grid>
            <Grid item xs={12} md={3}>
               <LoadingButton
                  color="warning"
                  // onClick={handleClick}
                  // loading={loading}
                  // loadingPosition="start"
                  // startIcon={<FileDownloadIcon />}
                  variant="contained"
                  className="btn-download2"
               >
                  Exportar en Excel
               </LoadingButton>
            </Grid>
         </Grid>

         <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
               <TextField
                  label="Rut"
                  id="outlined-size-small"
                  size="small"
                  defaultValue="760015476-k"
                  fullWidth
               />
            </Grid>
            <Grid item xs={12} md={6}>
               <TextField
                  label="Nombre"
                  id="outlined-size-small"
                  size="small"
                  defaultValue="SOFTNET SPA"
                  fullWidth
               />
            </Grid>
         </Grid>
         <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
               <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                     <TableRow>
                        {columns.map((column) => (
                           <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                           >
                              {column.label}
                           </TableCell>
                        ))}
                        <TableCell>Opciones</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {rowsData
                        .slice(
                           page * rowsPerPage,
                           page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                           return (
                              <TableRow
                                 hover
                                 role="checkbox"
                                 tabIndex={-1}
                                 key={row.numero}
                              >
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell
                                          key={column.id}
                                          align={column.align}
                                       >
                                          {column.format &&
                                          typeof value === "number"
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                    <IconButton>
                                       <BookIcon
                                          style={{ fontSize: "20px" }}
                                          // onClick={handleOpen}
                                       />
                                    </IconButton>
                                    <IconButton>
                                       <PictureAsPdfIcon
                                          style={{ fontSize: "20px" }}
                                          // onClick={handleOpen}
                                       />
                                    </IconButton>
                                 </TableCell>
                              </TableRow>
                           );
                        })}
                  </TableBody>
               </Table>
            </TableContainer>
            <TablePagination
               rowsPerPageOptions={[10, 25, 100]}
               component="div"
               count={rowsData.length}
               rowsPerPage={rowsPerPage}
               page={page}
               onPageChange={handleChangePage}
               onRowsPerPageChange={handleChangeRowsPerPage}
            />
         </Paper>
      </Container>
   );
};
