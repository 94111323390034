import { FunctionComponent, useState } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import '../EcSellConsidate_styles.css';
import TextField from '@mui/material/TextField';
import { Box, Button, Grid,Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { fontSize } from "@mui/system";

interface TableMOnthProductProps {
    
}

interface Column {
    id: 'rut' | 'direccion' | 'documento'|'suma' | 'venta' ;
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
  }
 
 const columns:readonly Column[] = [
   { id: 'rut', label: 'Rut', minWidth: 120 },
   { id: 'direccion', label: 'Dirección', minWidth: 170 },
   { id: 'documento', label: 'Documento', minWidth: 120 },
   { id: 'suma', label: 'Suma', minWidth: 100 },
   { id: 'venta', label: 'Venta', minWidth: 120 },
    ];
 
 interface Data {
   rut: string;
   direccion: string;
   documento: Number;
   suma: Number;
   venta: Number;
    // detalle: string;
 }
 
 function createDataClient(
    rut: string,
    direccion: string,
    documento: Number,
    suma: Number,
    venta: Number,
    // detalle: string,

 ): Data {
    return { rut, direccion, documento, suma, venta };
 }
 

 const rowsDataClient = [
    createDataClient('7656576-7', 'Alain monsalve', 2 , 106.000, 1000000 ),
 ]

 
export const TableMOnth: FunctionComponent<TableMOnthProductProps> = () => {
    const [error, setError] = useState(null);
    const [page , setPage]= useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
     };
  
     const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
     };
    return ( 
        <>
        <Grid container spacing={2}>
            <Grid item xs={12} md={10} textAlign="start" mb={2}>
                  <Typography  style={{color:'#ffffff'}} mt={1} variant='h5' component='h1' gutterBottom className="tittle4">
                  INFORME CONSOLIDADO POR CLIENTE
                  </Typography>
               </Grid>
               <Grid item xs={12} md={2} className="btn-download" mb={2}>
                        <Box>
                           <LoadingButton
                           // color="warning"
                        //    onClick={handleClick}
                        //    loading={loading}
                           loadingPosition="start"
                           startIcon={<FileDownloadIcon />}
                           variant="contained"
                           fullWidth
                           style={{backgroundColor:'#ffffff',color:'#000000', fontSize:'14px !important'}}
                           className="btn-download"
                           >
                           Exportar
                           </LoadingButton>
                        </Box>
                     </Grid>
            <Grid item xs={12} md={4} textAlign="end">
                  <Typography  style={{color:'#ffffff'}} mt={1} variant='h6' component='h1' gutterBottom className="tittle4">
                  Cantidad de Clientes :
                  </Typography>
               </Grid>

               <Grid item xs={10} md={6} mb={1}>
                  <TextField
                  style={{backgroundColor:'#ffffff'}}
                  id="outlined-select-currency"
                  value="1"
                  fullWidth
                  size='small'
                  />
               </Grid>

               <Grid item xs={12} md={4} textAlign="end">
                  <Typography  style={{color:'#ffffff'}} mt={1} variant='h6' component='h1' gutterBottom className="tittle4">
                  Total de Documentos :
                  </Typography>
               </Grid>

               <Grid item xs={10} md={6} mb={1}>
                  <TextField
                  style={{backgroundColor:'#ffffff'}}
                  id="outlined-select-currency"
                  value="2"
                  fullWidth
                  size='small'
                  />
               </Grid>

               <Grid item xs={12} md={4} textAlign="end">
                  <Typography  style={{color:'#ffffff'}} mt={1} variant='h6' component='h1' gutterBottom className="tittle4">
                  Suma Ventas Netas :
                  </Typography>
               </Grid>

               <Grid item xs={10} md={6} mb={2}>
                  <TextField
                  style={{backgroundColor:'#ffffff'}}
                  id="outlined-select-currency"
                  value="$ 106.000"
                  fullWidth
                  size='small'
                  />
               </Grid>
            </Grid>
             <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:440}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead >
                        <TableRow >
                            {columns.map((column) => (
                                 <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                    >
                                    {column.label}
                                    </TableCell>
                            ))}
                            <TableCell>Opcion</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsDataClient.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.rut}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell >
                           
                                    <Button variant="contained"  size="small" style={{color:'#ffffff', background:"#FF9700"}} className='btn-download'>
                                        Detalle
                                    </Button>
                                 </TableCell>
                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsDataClient.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
            </Paper>
        </>
     );
}
 
