import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { VALIDATORS } from "@/toolbox/helpers/validation-rules";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Grid, Container, Button, Autocomplete,  InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, } from '@mui/material';
import { Input } from '@components/common/Input';
import { Props } from "./Contrato.type";
import BusinessIcon from '@mui/icons-material/Business';
import { InputRef } from '@/components/common/Input/InputInterfaces';
import NumbersIcon from '@mui/icons-material/Numbers';
import ArticleIcon from '@mui/icons-material/Article';
import Stack from '@mui/material/Stack';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import './Contrato_style.css';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
export const Contrato: React.FC<Props> = (props:Props):JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);

   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const [valueContrato,setvalueContrato]=useState({
      rut_proveedor:'',
      titulo:'',
      numero_contrato:'',
      descripcion:'',
  });

  const tipoContrato =[
    {label:'Publico'},
    {label:'Privado'},
  ]

  const tipoFacturacion=[
  { label: 'Mensual' },
  { label: 'Trimestral' },
  { label: 'Semestral' },
  { label: 'Anual' },
  ]

  const centroCosto=[
  { label: 'Centro de Costo 1' },
  { label: 'Centro de Costo 2' },
  { label: 'Centro de Costo 3' },
  { label: 'Centro de Costo 4' }
  ]

  const prioridad=[
  { label: 'Alta' },
  { label: 'Media' },
  { label: 'Baja' },
  { label: 'Urgente' }
  ]

  const modeda=[
  { label: 'UF' }
  ]

  
  const condicionPago=[
  { label: 'Contado' },
  ]    

  const aprobacion=[
  { label: 'Aprobacion 1' },
  { label: 'Aprobacion 2' },
  { label: 'Aprobacion 3' },
  { label: 'Aprobacion 4' },

  ]

  function createDataContrato(
     numero:number,
     titulo:string,
     inicio:string,
     termino:string,
     Facturación:string,
     monto:string,
     moneda:string,
     prioridad:string,
     estado:string,
  ){
   return {numero,titulo,inicio,termino,Facturación,monto,moneda,prioridad,estado};
}

  const rowsContrato = [
       createDataContrato(1, 'Contrato 1', '01/01/2018', '01/01/2019', '01/01/2018', '$1.000.000', 'UF', 'Alta', 'Activo'),
         createDataContrato(2, 'Contrato 2', '01/01/2018', '01/01/2019', '01/01/2018', '$1.000.000', 'UF', 'Alta', 'Activo'),
         createDataContrato(3, 'Contrato 3', '01/01/2018', '01/01/2019', '01/01/2018', '$1.000.000', 'UF', 'Alta', 'Activo'),
         createDataContrato(4, 'Contrato 4', '01/01/2018', '01/01/2019', '01/01/2018', '$1.000.000', 'UF', 'Alta', 'Activo'),
         
      ]

  const rules = useMemo(() => ({
    rut_proveedor: [
       VALIDATORS.REQUIRED,
    ],
    numero_contrato: [
       VALIDATORS.REQUIRED,
       VALIDATORS.CODE.ONLY_NUMBER
    ],
    titulo: [
       VALIDATORS.REQUIRED
    ],
    descripcion: [
       VALIDATORS.REQUIRED,
    ]

 }), []);
    const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'rut_proveedot':
            props.setvalueContrato(prev => ({ ...prev, rut_proveedot: value }));
            break;
         case 'numero_contrato':
            props.setvalueContrato(prev => ({ ...prev, numero_contrato: value }))
            break;
         case 'titulo':
            props.setvalueContrato(prev => ({ ...prev, titulo: value }))
            break;
         case 'descripcion':
            props.setvalueContrato(prev => ({ ...prev, descripcion: value }));
            break;
         
         default:
            break;
      }
   };

   // const handleInputBlur = (event: any) => {
   //    const newVal = (event.target.value).trim();
   //    const name = event.target.name;
   //    props.setvalueContrato(prev => ({ ...prev, [name]: newVal }));
   // }

const handleSubmit = async (event: React.SyntheticEvent) => {
   event.preventDefault();
}
const validate = () => {
   const refs = inputRefs.current;
   const valid = refs.reduce((prev, ref) => {
      const isValid = ref && ref.validate(true) || false
      return prev && isValid;
   }, true);
   return valid;
}
  return (
    <Protected>
      <form style={{margin:'8px'}}>
            <Typography variant="h6" gutterBottom mb={3} className="tittle">
               MANTENEDOR DE CONTRATOS
             </Typography>

             
               
            <Grid container spacing={3} className={`e-mb-lg`} >

               <Grid item xs={12} sm={2}>
                  <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                        <InputAdornment position="end">
                        <BusinessIcon />
                        </InputAdornment>
                     }
                        name="rut_proveedor"
                        placeholder="Rut Proveedor"
                        type="text"
                        // value={valueContrato.rut_proveedor}
                        onChange={handleInput}
                        // onBlur={handleInputBlur}
                        // rules={rules.rut_proveedor}
                        disableElevation
                        // validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
               </Grid>

               <Grid item xs={12} sm={2}>
                  <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                        <InputAdornment position="end">
                        <NumbersIcon />
                        </InputAdornment>
                     }
                        name="valor_compra"
                        placeholder="N de Contrato"
                        //value={data.nombre_rz}
                        // value={props.valueContrato.valor_compra}
                        onChange={handleInput}
                        //onBlur={handleInputBlur}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.valor_compra}
                        disableElevation
                        validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
               </Grid>

               <Grid item xs={12} sm={4}>
                  <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                        <InputAdornment position="end">
                        <BusinessIcon />
                        </InputAdornment>
                     }
                        name="valor_compra"
                        placeholder="Título"
                        //value={data.nombre_rz}
                        // value={props.valueContrato.valor_compra}
                        onChange={handleInput}
                        //onBlur={handleInputBlur}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.valor_compra}
                        disableElevation
                        validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
               </Grid>

               <Grid item xs={12} sm={2}>
                     <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                        label="Fecha Termino"
                        value={value}
                        onChange={(newValue) => {
                           setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        />
                     </LocalizationProvider>
               </Grid>

               <Grid item xs={12} sm={2}>
                     <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                        label="Fecha Inicio"
                        
                        value={value}
                        onChange={(newValue) => {
                           setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        />
                     </LocalizationProvider>
               </Grid>

               <Grid item xs={12} sm={2}>
                  <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                        <InputAdornment position="end">
                        <AttachMoneyIcon />
                        </InputAdornment>
                     }
                        name="monto"
                        placeholder="Monto"
                        //value={data.nombre_rz}
                        // value={props.valueContrato.vida_financiera}
                        onChange={handleInput}
                        //onBlur={handleInputBlur}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.vida_financiera}
                        disableElevation
                        validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
               </Grid>

               <Grid item xs={12} sm={2}>
               <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={modeda}
                           // sx={{ bgcolor: '#F3F3F3'}}
                           renderInput={(params) => <TextField {...params} label="Modena" />}
                        />
               </Grid>

               <Grid item xs={12} sm={2}>
                     <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={tipoContrato}
                           // sx={{ bgcolor: '#F3F3F3'}}
                           renderInput={(params) => <TextField {...params} label="Tipo" />}
                        />
               </Grid>

               <Grid item xs={12} sm={2}>
               <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={prioridad}
                           // sx={{ bgcolor: '#F3F3F3'}}
                           renderInput={(params) => <TextField {...params} label="Prioridad" />}
                        />
               </Grid>

               <Grid item xs={12} sm={2}>
               <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={centroCosto}
                           // sx={{ bgcolor: '#F3F3F3'}}
                           renderInput={(params) => <TextField {...params} label="Centro de Costo" />}
                        />
               </Grid>

               <Grid item xs={12} sm={2}>
               <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={tipoFacturacion}
                           // sx={{ bgcolor: '#F3F3F3'}}
                           renderInput={(params) => <TextField {...params} label="Tipo de Facturación" />}
                        />
               </Grid>

               <Grid item xs={12} sm={2}>
               <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={condicionPago}
                           // sx={{ bgcolor: '#F3F3F3'}}
                           renderInput={(params) => <TextField {...params} label="Condición de Pago" />}
                        />
               </Grid>

               <Grid item xs={12} sm={2}>
               <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={aprobacion}
                           // sx={{ bgcolor: '#F3F3F3'}}
                           renderInput={(params) => <TextField {...params} label="Aprobado por..." />}
                        />
               </Grid>

               <Grid item xs={12} sm={2}>
               <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                        label="Fecha de Facturación"
                        
                        value={value}
                        onChange={(newValue) => {
                           setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        />
                     </LocalizationProvider>
               </Grid>

               <Grid item xs={12} sm={3}>
                  <Stack direction="row" alignItems="center">
                     <label htmlFor="contained-button-file">
                     <Input id="contained-button-file" type="file" ref={undefined} />

                     </label>
                  </Stack>
               </Grid>

               <Grid item xs={12} sm={6}>
                  <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                        <InputAdornment position="end">
                        <ArticleIcon />
                        </InputAdornment>
                     }
                        name="valor_compra"
                        placeholder="Descripción"
                        //value={data.nombre_rz}
                        // value={props.valueContrato.valor_compra}
                        onChange={handleInput}
                        //onBlur={handleInputBlur}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.valor_compra}
                        disableElevation
                        validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
               </Grid>

               <Grid item container
                     xs={12}
                  justifyContent='right'
                  >
                        <Grid item>
                        <Button
                           type='submit'
                           variant='contained'
                           onClick={handleSubmit}
                           disabled={loading}
                           disableElevation
                           className='btn_save'
                        >
                                 <span>Agregar</span>
                        </Button>

                        </Grid>
               </Grid>
            </Grid>
         </form>

         <div className='bg-tittle'>
               <Typography 
               mt={3} 
               variant="h6"
               align='center'
               className='tittle-text'
               >Listado de Contratos
               </Typography>
         </div>

         <TableContainer component={Paper}>
            <Table aria-label="simple table">
               <TableHead>
                  <TableRow>
                     <TableCell>N°</TableCell>
                     <TableCell align="center">Título</TableCell>
                     <TableCell align="center">Inicio</TableCell>
                     <TableCell align="center">Termino</TableCell>
                     <TableCell align="center">Facturacion</TableCell>
                     <TableCell align="center">Monto</TableCell>
                     <TableCell align="center">Moneda</TableCell>
                     <TableCell align="center">Prioridad</TableCell>
                     <TableCell align="center">Estado</TableCell>
                     <TableCell align="center">Opciones</TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                     {rowsContrato.map((row, index) => (
                        <TableRow key={index} >
                           <TableCell component="th" scope="row">
                              {index + 1}
                           </TableCell>
                           <TableCell align="center">{row.titulo}</TableCell>
                           <TableCell align="center">{row.inicio}</TableCell>
                           <TableCell align="center">{row.termino}</TableCell>
                           <TableCell align="center">{row.Facturación}</TableCell>
                           <TableCell align="center">{row.monto}</TableCell>
                           <TableCell align="center">{row.moneda}</TableCell>
                           <TableCell align="center">{row.prioridad}</TableCell>
                           <TableCell align="center">{row.estado}</TableCell>
                           <TableCell align="center">
                              <IconButton
                                 aria-label="delete"
                                 // onClick={() => handleDelete(row.id)}
                                 className='btn_delete'
                                 // disabled={loading}
                              >
                                 <DeleteIcon />
                              </IconButton>

                              <IconButton
                                 aria-label="delete"
                                 // onClick={() => handleDelete(row.id)}
                                 className='btn_delete'
                                 // disabled={loading}
                              >
                                 <EditIcon />
                              </IconButton>

                           </TableCell>
                        </TableRow>
                     ))}
               </TableBody> 
            </Table>
         </TableContainer> 
    </Protected>
  );
}