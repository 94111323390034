import React, { useState } from "react";
// import { Protected } from "@/components/layout/Protected";
import {
   Button,
   Grid,
   IconButton,
   InputBase,
   Modal,
   Paper,
   Typography,
} from "@mui/material";
import { Props } from "../AllProducts.type";
import "../AllProducts_style.css";
import CloseIcon from "@mui/icons-material/Close";
import { FormControl } from "@mui/material";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Stack from "@mui/material/Stack";
import SearchIcon from "@mui/icons-material/Search";
import { ModalPruebados } from "./ModalPruebados";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import Box from "@mui/material/Box";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import LocationOnIcon from "@mui/icons-material/LocationOn";

export const ModalGuiaDespacho: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const [open, setOpen] = useState(false);
   const handleClose = () => setOpen(false);
   const [openModalPruebados, setOpenModalPruebados] = React.useState(false);
   const [value, setValue] = useState<Date | null>(new Date());
   const handleOpenPruebados = () => setOpenModalPruebados(true);
   const handleClosePruebados = () => setOpenModalPruebados(false);

   const style = {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      height: "600px",
      transform: "translate(-50%, -50%)",
      width: "900px",
      borderRadius: "10px",
      bgcolor: "background.paper",
      boxShadow: "rgba(100,100,111,0.2) 0px 7px 29px 0px",
      p: 3,
      background: "#F5F5F5",
   };

   const direcciones = [
      { label: "Santiago", value: "Santiago" },
      { label: "Providencia", value: "Providencia" },
      { label: "Concepcion", value: "Concepcion" },
      { label: "Talca", value: "Talca" },
   ];

   const traslado = [
      {
         label: "Operación Constituye Venta",
         value: "Operación Constituye Venta",
      },
      { label: "Venta por Efectuar", value: "Venta por Efectuar" },
      { label: "Consignación", value: "Consignación" },
      { label: "Entrega Gratuitas", value: "Entrega Gratuitas" },
      { label: "Traslados Internos", value: "Traslados Internos" },
      { label: "Otros Traslado no Venta", value: "Otros Traslado no Venta" },
      { label: "Guia Devolución", value: "Guia Devolución" },
      {
         label: "Traslado para Exportación",
         value: "Traslado para Exportación",
      },
   ];

   const proyecto = [
      { label: "Seleccione Proyecto", value: "Seleccione Proyecto" },
   ];

   const bodega = [{ label: "Seleccione Bodega", value: "Seleccione Bodega" }];

   const operador = [
      { label: "Seleccione Operador", value: "Seleccione Operador" },
   ];

   const despacho = [
      { label: "Por Cuenta del Receptor", value: "Por Cuenta del Receptor" },
      {
         label: "Por Cuenta del Emisor a Inst. del Receptor",
         value: "Por Cuenta del Emisor a Inst. del Receptor",
      },
      { label: "Sin Despacho", value: "Sin Despacho" },
      {
         label: "Por Cuenta del Emisor a otras Inst.",
         value: "Por Cuenta del Emisor a otras Inst.",
      },
   ];

   return (
      <Grid container p={1} sx={style}>
         <Grid container maxWidth="xl" spacing={1}>
            <Grid item xs={12} md={9}>
               <Box>
                  {/*<Typography
                     variant="h6"
                     component="h1"
                     gutterBottom
                     className="tittle"
                  >
                     Guía de Despacho Electrónica
                  </Typography>*/}
               </Box>
               <Box>
                  {/* <Typography variant='subtitle2'  gutterBottom >
                       Periodo: 2022 Mes: 04                        
                       </Typography> */}
               </Box>
            </Grid>
            <Grid item xs={12} md={3}>
               <Grid container>
                  <Grid item xs={12} md={8} justifyContent="flex-end">
                     <IconButton>
                        <ContentPasteSearchIcon
                           // style={{ fontSize:'20px' }}
                           style={{
                              justifyContent: "flex-end",
                              fontSize: "30px",
                              color: "#000000",
                              marginLeft: "60px",
                           }}
                           //onClick={handleOpenInform}
                        />
                     </IconButton>
                  </Grid>
                  <Grid item xs={12} md={4}>
                     <Button
                        type="submit"
                        variant="contained"
                        // onClick={handleSubmit}
                        // disabled={loading}
                        disableElevation
                        // component={Link}
                        // to={ROUTE_F_ED_ELECTRONIC_DISPATCH_GUIDE}
                        className="btn_opcion"
                     >
                        <span>VOLVER</span>
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
               <Typography
                  variant="h6"
                  gutterBottom
                  mb={3}
                  className="tittle2"
                  align="center"
                  mt={3}
               >
                  GUIA DE DESPACHO ELECTRONICA
               </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
               <TextField
                  id="rut"
                  label="Rut Empresa"
                  type="text"
                  fullWidth
                  variant="outlined"
                  defaultValue="7656576-7"
                  size="small"
               />
            </Grid>
            <Grid item xs={12} md={2}>
               <TextField
                  id="rut"
                  label="Razon Social"
                  type="text"
                  fullWidth
                  variant="outlined"
                  defaultValue="Alan Monsalve"
                  size="small"
               />
            </Grid>
            <Grid item xs={12} md={3}>
               <TextField
                  id="rut"
                  label="Giro"
                  type="text"
                  fullWidth
                  variant="outlined"
                  defaultValue="giro cliente 3"
                  size="small"
               />
            </Grid>
            <Grid item xs={12} md={3}>
               <TextField
                  id="rut"
                  label="Email"
                  type="text"
                  fullWidth
                  variant="outlined"
                  defaultValue="jmora@test.cl"
                  size="small"
               />
            </Grid>
            <Grid item xs={12} sm={2}>
               <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack spacing={3}>
                     <DatePicker
                        // views={['year', 'month']}
                        label="Fecha"
                        minDate={new Date("2012-03-01")}
                        maxDate={new Date("2023-06-01")}
                        value={value}
                        onChange={(newValue) => {
                           setValue(newValue);
                        }}
                        renderInput={(params) => (
                           <TextField {...params} helperText={null} />
                        )}
                     />
                  </Stack>
               </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={3}>
               <Autocomplete
                  disablePortal
                  size="small"
                  id="combo-box-demo"
                  options={direcciones}
                  // sx={{ bgcolor: '#F3F3F3'}}
                  renderInput={(params) => (
                     <TextField {...params} label="Condicion de Pago" />
                  )}
               />
            </Grid>
            <Grid item xs={12} md={3}>
               <Autocomplete
                  disablePortal
                  size="small"
                  id="combo-box-demo"
                  options={direcciones}
                  // sx={{ bgcolor: '#F3F3F3'}}
                  renderInput={(params) => (
                     <TextField {...params} label="Area de Negocio" />
                  )}
               />
            </Grid>
            <Grid item xs={12} md={2}>
               <TextField
                  id="rut"
                  label="Vendedor"
                  type="text"
                  fullWidth
                  // margin="normal"
                  variant="outlined"
                  size="small"
                  // defaultValue="jmora@test.cl"
               />
            </Grid>
            <Grid item xs={12} md={2}>
               <TextField
                  id="rut"
                  label="Orden de Compra"
                  type="text"
                  fullWidth
                  // margin="normal"
                  variant="outlined"
                  size="small"
                  // defaultValue="jmora@test.cl"
               />
            </Grid>
            <Grid item xs={12} md={2}>
               <TextField
                  id="rut"
                  label="Patente"
                  type="text"
                  fullWidth
                  // margin="normal"
                  variant="outlined"
                  size="small"
                  // defaultValue="jmora@test.cl"
               />
            </Grid>
            <Grid item xs={12} md={4}>
               <Autocomplete
                  disablePortal
                  size="small"
                  id="combo-box-demo"
                  options={traslado}
                  // sx={{ bgcolor: '#F3F3F3'}}
                  renderInput={(params) => (
                     <TextField {...params} label=" Traslado" />
                  )}
               />
            </Grid>
            <Grid item xs={12} md={4}>
               <Autocomplete
                  disablePortal
                  size="small"
                  id="combo-box-demo"
                  options={traslado}
                  // sx={{ bgcolor: '#F3F3F3'}}
                  renderInput={(params) => (
                     <TextField {...params} label=" Bodega Destino" />
                  )}
               />
            </Grid>
            <Grid item xs={12} md={4}>
               <TextField
                  id="rut"
                  label="Nombre Bodega"
                  type="text"
                  fullWidth
                  variant="outlined"
                  size="small"
                  // defaultValue="jmora@test.cl"
               />
            </Grid>

            <Grid item xs={12} md={12} mt={2}>
               <Typography variant="subtitle2" gutterBottom>
                  GUIA
               </Typography>
            </Grid>

            <Grid item xs={12} md={3}>
               <TextField
                  id="rut"
                  label="Nº Guia de Despacho"
                  type="text"
                  fullWidth
                  variant="outlined"
                  size="small"
                  // defaultValue="jmora@test.cl"
               />
            </Grid>
            <Grid item xs={12} md={3}>
               <TextField
                  id="rut"
                  label="N/V"
                  type="text"
                  fullWidth
                  variant="outlined"
                  size="small"
                  // defaultValue="jmora@test.cl"
               />
            </Grid>
            <Grid item xs={12} md={3}>
               <TextField
                  id="rut"
                  label="Orden de Compra"
                  type="text"
                  fullWidth
                  variant="outlined"
                  size="small"
                  // defaultValue="jmora@test.cl"
               />
            </Grid>
            <Grid item xs={12} md={3}>
               <Autocomplete
                  disablePortal
                  size="small"
                  id="combo-box-demo"
                  options={proyecto}
                  // sx={{ bgcolor: '#F3F3F3'}}
                  renderInput={(params) => (
                     <TextField {...params} label=" Proyecto" />
                  )}
               />
            </Grid>

            <Grid item xs={12} md={12} mt={2}>
               <Typography variant="subtitle2" gutterBottom>
                  DESPACHO
               </Typography>
            </Grid>

            <Grid item xs={12} md={3}>
               <Autocomplete
                  disablePortal
                  size="small"
                  id="combo-box-demo"
                  options={despacho}
                  // sx={{ bgcolor: '#F3F3F3'}}
                  renderInput={(params) => (
                     <TextField {...params} label=" Despacho" />
                  )}
               />
            </Grid>
            <Grid item xs={12} md={3}>
               <Autocomplete
                  disablePortal
                  size="small"
                  id="combo-box-demo"
                  options={direcciones}
                  // sx={{ bgcolor: '#F3F3F3'}}
                  renderInput={(params) => (
                     <TextField {...params} label="Dirección" />
                  )}
               />
            </Grid>
            <Grid item xs={12} md={3}>
               <Autocomplete
                  disablePortal
                  size="small"
                  id="combo-box-demo"
                  options={bodega}
                  // sx={{ bgcolor: '#F3F3F3'}}
                  renderInput={(params) => (
                     <TextField {...params} label=" Bodega Origen" />
                  )}
               />
            </Grid>
            <Grid item xs={12} md={3}>
               <Autocomplete
                  disablePortal
                  size="small"
                  id="combo-box-demo"
                  options={operador}
                  // sx={{ bgcolor: '#F3F3F3'}}
                  renderInput={(params) => (
                     <TextField {...params} label=" Operador" />
                  )}
               />
            </Grid>
            <Grid item xs={12} md={4}>
               <Autocomplete
                  disablePortal
                  size="small"
                  id="combo-box-demo"
                  options={direcciones}
                  // sx={{ bgcolor: '#F3F3F3'}}
                  renderInput={(params) => (
                     <TextField {...params} label="Dirección de Despacho" />
                  )}
               />
            </Grid>
            <Grid item xs={12} md={2}>
               <TextField
                  id="rut"
                  label="FCL"
                  type="text"
                  fullWidth
                  variant="outlined"
                  size="small"
                  // defaultValue="jmora@test.cl"
               />
            </Grid>
            <Grid item xs={12} md={2}>
               <TextField
                  id="rut"
                  label="Marcas"
                  type="text"
                  fullWidth
                  variant="outlined"
                  size="small"
                  // defaultValue="jmora@test.cl"
               />
            </Grid>
            <Grid item xs={12} md={2}>
               <TextField
                  id="rut"
                  label="Anticipo"
                  type="text"
                  fullWidth
                  variant="outlined"
                  size="small"
                  // defaultValue="jmora@test.cl"
               />
            </Grid>
            <Grid item xs={12} md={2}>
               <TextField
                  id="rut"
                  label="Sellos"
                  type="text"
                  fullWidth
                  variant="outlined"
                  size="small"
                  // defaultValue="jmora@test.cl"
               />
            </Grid>

            <Grid item xs={12} md={2}>
               <TextField
                  id="rut"
                  label="Folio_Interno"
                  type="text"
                  fullWidth
                  variant="outlined"
                  size="small"
                  // defaultValue="jmora@test.cl"
               />
            </Grid>
            <Grid item xs={12} md={2}>
               <TextField
                  id="rut"
                  label="Folio_Interno2"
                  type="text"
                  fullWidth
                  variant="outlined"
                  size="small"
                  // defaultValue="jmora@test.cl"
               />
            </Grid>
            <Grid item xs={12} md={2}>
               <TextField
                  id="rut"
                  label="Fecha de Embarque"
                  type="text"
                  fullWidth
                  variant="outlined"
                  size="small"
                  // defaultValue="jmora@test.cl"
               />
            </Grid>
            <Grid item xs={12} md={2}>
               <TextField
                  id="rut"
                  label="Saldo"
                  type="text"
                  fullWidth
                  variant="outlined"
                  size="small"
                  // defaultValue="jmora@test.cl"
               />
            </Grid>
            <Grid item xs={12} md={4}>
               <TextField
                  id="rut"
                  label="Monto Cuenta tercero"
                  type="text"
                  fullWidth
                  variant="outlined"
                  size="small"
                  // defaultValue="jmora@test.cl"
               />
            </Grid>
         </Grid>
      </Grid>
   );
};
