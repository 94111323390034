import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, } from '@mui/material';
import { ArrowBackIcon, ArrowDownIcon, ArrowRightIcon, MoreIcon } from "@toolbox/constants/icons";
import { Props } from './FormDetalleInsumo.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import Entity from "@assets/img/entity.png";
import './style.sass'
import "./FormDetalleInsumo_style.css";
import BadgeIcon from '@mui/icons-material/Badge';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import BusinessIcon from '@mui/icons-material/Business';
import FaxIcon from '@mui/icons-material/Fax';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import LanguageIcon from '@mui/icons-material/Language';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import MobileDateTimePicker from '@mui/lab/MobileDateTimePicker';
import DesktopDateTimePicker from '@mui/lab/DesktopDateTimePicker';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';

export const FormDetalleInsumo: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false)
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
    );
   const [estado, setEstado] = useState<any>({ rut: false, nombre_rz: false })

   const [valueRevalorizable, setValueRevalorizable] = React.useState('Si');
   const [valueDepreciable, setValueDepreciable] = React.useState('Si');
   const [valueTipoDepreciacion, setValueTipoDepreciacion] = React.useState('Lineal');
   const [valueUnidadMedina, setValueUnidadMedina] = React.useState('Mensual');


   const handleChangeRevalorizable = (event: React.ChangeEvent<HTMLInputElement>) => {
     setValueRevalorizable((event.target as HTMLInputElement).value);
   };

   const handleChangeDepreciable = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueDepreciable((event.target as HTMLInputElement).value);
    };
    const handleTipoDepreciacion = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueTipoDepreciacion((event.target as HTMLInputElement).value);
    };
    const handleUnidadMedina = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueUnidadMedina((event.target as HTMLInputElement).value);
    };
   
   const grupos = [
      { label: 'Grupo 1'},
      { label: 'Grupo 2'},
      { label: 'Grupo 3'},
      { label: 'Grupo 4'}
   ]
   const estados = [
      { label: 'Activado'},
      { label: 'Desactivado'},
   ]
   const clasificaciones = [
      { label: 'Clasificacion 1'},
      { label: 'Clasificacion 2'},
      { label: 'Clasificacion 3'},
      { label: 'Clasificacion 4'}
   ]

   const rules = useMemo(() => ({
      codigoInsumo: [
         VALIDATORS.REQUIRED,
      ],
      nombreInsumo: [
         VALIDATORS.REQUIRED,
      ],
      detalleLargo: [
         VALIDATORS.REQUIRED
      ],
      grupo: [
         VALIDATORS.REQUIRED,
      ],
      estadoProducto: [
         VALIDATORS.REQUIRED,
      ],
      clasificacionCompra: [
         VALIDATORS.REQUIRED,
      ],

   }), []);


   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'codigoInsumo':
            props.setvalueDetall(prev => ({ ...prev, codigoInsumo: value }));
            break;
         case 'nombreInsumo':
            props.setvalueDetall(prev => ({ ...prev, nombreInsumo: value }))
            break;
         case 'detalleLargo':
            props.setvalueDetall(prev => ({ ...prev, detalleLargo: value }))
            break;
         case 'grupo':
            props.setvalueDetall(prev => ({ ...prev, grupo: value }));
            break;
         case 'estadoProducto':
            props.setvalueDetall(prev => ({ ...prev, estadoProducto: value }));
            break;
         case 'clasificacionCompra':
            props.setvalueDetall(prev => ({ ...prev, clasificacionCompra: value }));
            break;
         case 'ubicacion':
            props.setvalueDetall(prev => ({ ...prev, ubicacion: value }));
            break;
         case 'nombreInsumo_vencimiento':
            props.setvalueDetall(prev => ({ ...prev, nombreInsumo_vencimiento: value }));
            break;
         case 'revalorizable':
            props.setvalueDetall(prev => ({ ...prev, revalorizable: value }));
            break;
         case 'tipo_depreciacion':
            props.setvalueDetall(prev => ({ ...prev, tipo_depreciacion: value }));
            break;
         case 'depreiable':
            props.setvalueDetall(prev => ({ ...prev, depreiable: value }));
            break;
         case 'unidad_medida':
            props.setvalueDetall(prev => ({ ...prev, unidad_medida: value }));
            break;
         default:
            break;
      }
   };
   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      props.setvalueDetall(prev => ({ ...prev, [name]: newVal }));
   }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      props.handleNext();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }

   return (
      <Container maxWidth="xl">
         <form >
            <Grid container spacing={3} className={`e-mb-lg`}>
         
               <Grid item xs={12} sm={3}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                     <BadgeIcon />
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="codigoInsumo"
                     type='text'
                     placeholder="Codigo Insumo"
                     value={props.valueDetall.codigoInsumo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.codigoInsumo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />

               </Grid>

               <Grid item xs={12} sm={3}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                     <BadgeIcon />
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="nombreInsumo"
                     type='text'
                     placeholder="Nombre Insumo"
                     value={props.valueDetall.nombreInsumo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.nombreInsumo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />

               </Grid>

               <Grid item xs={12} sm={3}>
                  <Input
                     ref={ref => inputRefs.current[1] = ref}
                      prependInnerAdornment={
                     <InputAdornment position="end">
                     <BusinessIcon />
                    </InputAdornment>
                    }
                     name="detalleLargo"
                     placeholder="Detalle Largo"
                     value={props.valueDetall.detalleLargo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.detalleLargo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>

               
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={grupos}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Grupo" />}
                  />
               </Grid>
   
               <Grid item xs={12} sm={3}>
               <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={estados}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Estado Producto" />}
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
               <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={clasificaciones}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Clasificación de Compra" />}
                  />
               </Grid>
               

              
               <Grid item container
                  xs={12}
                 justifyContent='right'
                 >
                     <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                        className='btn_save'
                        endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>Siguiente</span>
                        }
                     </Button>

                     </Grid>
               </Grid>
            </Grid>
         </form>
      </Container>
   );
}
