import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "../HoursExtraordinaries.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import '../HoursExtraordinaries_styles.css'
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { PictureAsPdf } from "@mui/icons-material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';

interface Column {
   id: 'anio' | 'mes' | 'horas1' | 'horas2'|'horas3';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

const columns:readonly Column[] = [
    { id: 'anio', label: 'Año', minWidth: 20 },
    { id: 'mes', label: 'Mes', minWidth: 50 },
    { id: 'horas1', label: 'Anticipo', minWidth: 50 },
    { id: 'horas2', label: 'Estado', minWidth: 50 },
    { id: 'horas3', label: 'Estado', minWidth: 50 },

   ];

interface Data {
    anio: number;
    mes: string;
    horas1:number;
    horas2:number;
    horas3:number;
}

function createData(
    anio: number,
    mes: string,
    horas1:number,
    horas2:number,
    horas3:number
   ):Data {
   return { anio, mes, horas1,horas2,horas3 };
}

const rowsData = [
    createData(2010, 'Enero', 2, 4,7),
    createData(2011, 'Febrero', 2, 4,7),
    createData(2012, 'Marzo', 2, 4,7),
    createData(2010, 'Abril', 2, 4,7),
    createData(2012, 'Mayo', 2, 4,7),
    createData(2013, 'Junio', 2, 4,7),
    createData(2010, 'Julio', 2, 4,7),
    createData(2014, 'Agosto', 2, 4,7),
    createData(2010, 'Septiembre', 2, 4,7),
    createData(2015, 'Octubre', 2, 4,7),
    createData(2010, 'Noviembre', 2, 4,7),
    createData(2017, 'Diciembre', 2, 4,7),
]


export const ModalAdvance:React.FC<Props> = (props: Props): JSX.Element => {
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value2, setValue2] = useState('');
   const [loading, setLoading] = useState(false);
   const [familyProduct, setFamilyProduct] = useState('');
   const [estado, setEstado]= useState('');
   const [value, setValue] = useState<Date | null>(new Date());

    const handleChangeFamilyProduct = (event: SelectChangeEvent) => {
      setFamilyProduct(event.target.value);
    };
    const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
    };
   function handleClick() {
     setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };


    return (
        <Container maxWidth='xl'>
           <Grid container spacing={3}>
               <Grid item xs={12} md={12} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        INGRESAR HORAS EXTRAORDINARIAS
                     </Typography>
                  </Box>
                  <Typography  variant="caption" display="block" gutterBottom mt={1}>
                  Trabajador Seleccionado
                  </Typography>
               </Grid>
               <Grid item xs={6} md={3} mb={-2}>
               <Box>
                     <Typography variant='subtitle2'  gutterBottom>
                        RUT TRABAJADOR
                     </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={9} mb={-2}>
                    <Box>
                        <Typography variant='caption'  gutterBottom>
                        16976751-3
                        </Typography>
                    </Box> 
            </Grid>

            <Grid item xs={6} md={3} mb={2}>
               <Box>
                     <Typography variant='subtitle2'  gutterBottom>
                        APELLIDOS
                     </Typography>
                  </Box>
                </Grid>
            <Grid item xs={6} md={9} mb={2}>
                    <Box>
                        <Typography variant='caption'  gutterBottom>
                        ALARCON - GONZALEZ - FERNANDO
                        </Typography>
                    </Box> 
            </Grid>

            <Grid item xs={12} md={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={3}>
                    <DatePicker
                        views={['year', 'month']}
                        label="Año y Mes"
                        minDate={new Date('2012-03-01')}
                        maxDate={new Date('2023-06-01')}
                        value={value}
                        onChange={(newValue) => {
                            setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} helperText={null} />}
                        />
                    </Stack>
                    </LocalizationProvider>
               </Grid>
               <Grid item xs={12} sm={4}>
                  <TextField
                     label="Horas extras fact: 0.0077777777"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                     
                  />
             </Grid>
             <Grid item xs={12} sm={4}>
                  <TextField
                     label="Horas extras fact: 0.015909"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                     
                  />
             </Grid>
             <Grid item xs={12} sm={4}>
                  <TextField
                     label="Horas extras fact: 0.0103707"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                     
                  />
             </Grid>
               

               <Grid item xs={12} md={4}>
                    <Button
                        type='submit'
                        variant='contained'
                        // onClick={handleSubmit}
                        // disabled={loading}
                        disableElevation
                        className='btn_next'
                        style={{backgroundColor:'#ff9700', color:'#fff'}}
                        >
                        <span>Agregar</span>
                    </Button>
                </Grid>

            </Grid>
            
            <div style={{background:'#16556b', borderRadius:'5px', color:'#ffffff',}} >
                        <Typography 
                        mt={2}
                        mb={2}
                        style={{padding:'3px'}}
                        variant="subtitle2"
                        align='center'
                        >HISTORICO DE ANTICIPO REGISTRADOS
                        </Typography>
         </div>

            <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:220}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                                 {column.label}
                              </TableCell>
                           ))}
                           <TableCell>Opcion</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.anio}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                       <IconButton aria-label="delete">
                                          <DeleteIcon style={{color:'red'}}/>
                                       </IconButton>
         
                                       {/* <IconButton aria-label="delete">
                                          <DeleteIcon style={{color:'#16556b'}}/>
                                       </IconButton>
                                       <IconButton aria-label="edit">
                                          <FactCheckIcon  style={{color:'#16556b'}}/>
                                       </IconButton>
                                       <IconButton aria-label="edit">
                                          <PrintIcon style={{color:'#16556b'}}/>
                                       </IconButton> */}
         
                                 </TableCell>

                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
            </Paper>

         </Container>
     
    );
   };

