import React, { useState, useRef } from 'react';
import { Protected } from "@/components/layout/Protected";
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { Input } from '@components/common/Input';
// import { InputRef } from '@/components/common/Input/InputInterfaces';
import Box from '@mui/material/Box';
import { InputRef } from '@/components/common/Input/InputInterfaces';
import BusinessIcon from '@mui/icons-material/Business';
import NumbersIcon from '@mui/icons-material/Numbers';
import ArticleIcon from '@mui/icons-material/Article';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Grid, Autocomplete, TextField, Button, InputAdornment } from '@mui/material';
import './Users_styles.css';
import { ActiveUsers } from './ActiveUsers/ActiveUsers';
import Checkbox from '@mui/material/Checkbox';
import { NoActiveUsers } from './NoActiveUsers/NoActiveUsers';
import FormControlLabel from '@mui/material/FormControlLabel';
// import { TabMonthProducts } from './InformsMonthProduct/TabMonthProducts'
// import { TableMOnth } from './InformsMonth/TableMonth';

interface EcSellConsolidateProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}
const estados = [
  { value: '1', label: 'Activo' },
  { value: '2', label: 'Inactivo' },
]

function TabPanel(props: EcSellConsolidateProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export const Users: React.FunctionComponent<EcSellConsolidateProps> = () => {
  const inputRefs = useRef<Array<InputRef | null>>([]);
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);//

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
  }
  const sucursal = [
    { label: 'Sucursal 1' },
    { label: 'Los Condes' },
    { label: 'Con con' }
  ]

  const tipoMenu = [
    { label: 'STANDART' },
    { label: 'PUNTO VENTA' },
    { label: 'SIN MENU' },
    { label: 'Aprobacion 4' },

  ]
  const paginaInicio = [
    { label: 'Punto de venta' },
    { label: 'PUNTO VENTA RESUMEN' },
    { label: 'RR HH' },
    { label: 'POS' },

  ]
  const estadoValue = [
    { label: 'ACTIVADO' },
    { label: 'DESACTIVADO' },
  ]
  const horarioRes = [
    { label: 'regla 1' }
  ]


  return (
    <Protected>
      {/* <Grid container spacing={3}> */}
      <form style={{ margin: '8px' }}>
        <Grid item xs={12} md={12} textAlign="start">
          <Typography mt={1} variant='h6' component='h1' gutterBottom className="tittle">
            MANTENEDOR DE USUARIOS
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" display="block" gutterBottom >
            Incorpore sus Usuarios en el siguiente Formulario.
          </Typography>
        </Grid>
        <Grid item container spacing={3} mt={1} alignItems='center' className={`e-mb-lg`} >

          <Grid item xs={12} sm={2}>
            <Input
              ref={ref => inputRefs.current[2] = ref}
              prependInnerAdornment={
                <InputAdornment position="end">
                  <BusinessIcon />
                </InputAdornment>
              }
              name="rut_proveedor"
              placeholder="Nombre de usuario"
              type="text"
              // value={valueContrato.rut_proveedor}
              // onChange={handleInput}
              // onBlur={handleInputBlur}
              // rules={rules.rut_proveedor}
              disableElevation
              // validateOnBlur
              dense
              disabled={isDisabled}
              className='borderInput'
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Input
              ref={ref => inputRefs.current[2] = ref}
              prependInnerAdornment={
                <InputAdornment position="end">
                  <NumbersIcon />
                </InputAdornment>
              }
              name="valor_compra"
              placeholder="Rut de usuario"
              //value={data.nombre_rz}
              // value={props.valueContrato.valor_compra}
              // onChange={handleInput}
              //onBlur={handleInputBlur}
              // backgroundColor="#F3F3F3"
              // rules={rules.valor_compra}
              disableElevation
              validateOnBlur
              dense
              disabled={isDisabled}
              className='borderInput'
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Input
              ref={ref => inputRefs.current[2] = ref}
              prependInnerAdornment={
                <InputAdornment position="end">
                  <BusinessIcon />
                </InputAdornment>
              }
              name="valor_compra"
              placeholder="Email"
              //value={data.nombre_rz}
              // value={props.valueContrato.valor_compra}
              // onChange={handleInput}
              //onBlur={handleInputBlur}
              // backgroundColor="#F3F3F3"
              // rules={rules.valor_compra}
              disableElevation
              validateOnBlur
              dense
              disabled={isDisabled}
              className='borderInput'
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              ref={ref => inputRefs.current[2] = ref}
              prependInnerAdornment={
                <InputAdornment position="end">
                  <BusinessIcon />
                </InputAdornment>
              }
              name="valor_compra"
              placeholder="Usuario"
              //value={data.nombre_rz}
              // value={props.valueContrato.valor_compra}
              // onChange={handleInput}
              //onBlur={handleInputBlur}
              // backgroundColor="#F3F3F3"
              // rules={rules.valor_compra}
              disableElevation
              validateOnBlur
              dense
              disabled={isDisabled}
              className='borderInput'
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              ref={ref => inputRefs.current[2] = ref}
              prependInnerAdornment={
                <InputAdornment position="end">
                  <BusinessIcon />
                </InputAdornment>
              }
              name="valor_compra"
              placeholder="Clave"
              //value={data.nombre_rz}
              // value={props.valueContrato.valor_compra}
              // onChange={handleInput}
              //onBlur={handleInputBlur}
              // backgroundColor="#F3F3F3"
              // rules={rules.valor_compra}
              disableElevation
              validateOnBlur
              dense
              disabled={isDisabled}
              className='borderInput'
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Autocomplete
              disablePortal
              size='small'
              id="combo-box-demo"
              options={sucursal}
              // sx={{ bgcolor: '#F3F3F3'}}
              renderInput={(params) => <TextField {...params} label="Sucursal" />}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Input
              ref={ref => inputRefs.current[2] = ref}
              prependInnerAdornment={
                <InputAdornment position="end">
                  <AttachMoneyIcon />
                </InputAdornment>
              }
              name="monto"
              placeholder="Repetir Clave"
              //value={data.nombre_rz}
              // value={props.valueContrato.vida_financiera}
              // onChange={handleInput}
              //onBlur={handleInputBlur}
              // backgroundColor="#F3F3F3"
              // rules={rules.vida_financiera}
              disableElevation
              validateOnBlur
              dense
              disabled={isDisabled}
              className='borderInput'
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Autocomplete
              disablePortal
              size='small'
              id="combo-box-demo"
              options={tipoMenu}
              // sx={{ bgcolor: '#F3F3F3'}}
              renderInput={(params) => <TextField {...params} label="Tipo Menu" />}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Input
              ref={ref => inputRefs.current[2] = ref}
              prependInnerAdornment={
                <InputAdornment position="end">
                  <AttachMoneyIcon />
                </InputAdornment>
              }
              name="monto"
              placeholder="Firma Correo"
              //value={data.nombre_rz}
              // value={props.valueContrato.vida_financiera}
              // onChange={handleInput}
              //onBlur={handleInputBlur}
              // backgroundColor="#F3F3F3"
              // rules={rules.vida_financiera}
              disableElevation
              validateOnBlur
              dense
              disabled={isDisabled}
              className='borderInput'
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Autocomplete
              disablePortal
              size='small'
              id="combo-box-demo"
              options={paginaInicio}
              // sx={{ bgcolor: '#F3F3F3'}}
              renderInput={(params) => <TextField {...params} label="Página de Inicio" />}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Autocomplete
              disablePortal
              size='small'
              id="combo-box-demo"
              options={estadoValue}
              // sx={{ bgcolor: '#F3F3F3'}}
              renderInput={(params) => <TextField {...params} label="Estado" />}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Input
              ref={ref => inputRefs.current[2] = ref}
              prependInnerAdornment={
                <InputAdornment position="end">
                  <AttachMoneyIcon />
                </InputAdornment>
              }
              name="monto"
              placeholder="Anexo"
              //value={data.nombre_rz}
              // value={props.valueContrato.vida_financiera}
              // onChange={handleInput}
              //onBlur={handleInputBlur}
              // backgroundColor="#F3F3F3"
              // rules={rules.vida_financiera}
              disableElevation
              validateOnBlur
              dense
              disabled={isDisabled}
              className='borderInput'
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Autocomplete
              disablePortal
              size='small'
              id="combo-box-demo"
              options={horarioRes}
              // sx={{ bgcolor: '#F3F3F3'}}
              renderInput={(params) => <TextField {...params} label="Horario Rextricción" />}
            />
          </Grid>
          <Grid item xs={4} container mt={1} textAlign="center">
            <FormControlLabel control={<Checkbox defaultChecked />} label="Virtual" />
          </Grid>

          <Grid item container
            xs={12}
            justifyContent='right'
          >
            <Grid item>
              <Button
                type='submit'
                variant='contained'
                onClick={handleSubmit}
                disabled={loading}
                disableElevation
                className='btn_save'
              >
                <span>Agregar</span>
              </Button>

            </Grid>
          </Grid>
        </Grid>
      </form>

      <Grid item xs={12} mt={3}>
        <Box  >
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
            // className='tabMain'
            >
              <Tab className='tab' label="Usuarios Activos" {...a11yProps(0)} />
              <Tab className='tab' label="Inactivos" {...a11yProps(1)} />
              {/* <Tab className='tab' label=" Informes Mensuales por Producto" {...a11yProps(2)} /> */}
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <ActiveUsers />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <NoActiveUsers />
            </TabPanel>
            {/* <TabPanel value={value} index={2} dir={theme.direction}>
                <TabMonthProducts />
              </TabPanel> */}
          </SwipeableViews>
        </Box>
      </Grid>
      {/* <Grid item xs={12} md={7} >
          <SwipeableViews
            className='bgTable'
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <TableMOnth />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              2
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              3
            </TabPanel>
          </SwipeableViews>
        </Grid> */}
      {/* <Grid item xs={12} md={12} >
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={2} dir={theme.direction}>
              
            </TabPanel>
          </SwipeableViews>
        </Grid> */}
      {/* </Grid> */}

    </Protected>
  );
}
