import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, } from '@mui/material'
import { useState } from "react";
import { Props } from "./ContratoWorker.type"

import './ContratoWorker_styles.css'


export const ContratoWorker: React.FC<Props> = (props: Props): JSX.Element => {

   return (
      <Protected>
         <Container maxWidth='xl' style={{ height: '100vh' }}>
            <Grid container spacing={3} mb={3}>

               <Grid item xs={12}>
                  <Box mb={3} mx={3}>
                     <Typography variant="h4" component="h1" gutterBottom>
                        Modulo en Mantencion !
                     </Typography>
                  </Box>
                  <Box mb={3} mx={3}>
                     <Typography variant="h6" component="h1" gutterBottom>
                        Acceso denegado , por mantencion del sistema de su empresa DEMO ERP SOFTNET.

                        Equipo SOFTNET
                     </Typography>
                  </Box>
               </Grid>


            </Grid>

         </Container>
      </Protected>
   );
};

