import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { ArrowRightIcon } from "@/toolbox/constants/icons";
import { VALIDATORS } from "@/toolbox/helpers/validation-rules";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, TablePagination, TableFooter, } from '@mui/material';
import { Input } from '@components/common/Input';
import { Props } from "./EvFillRate.type";
import BusinessIcon from '@mui/icons-material/Business';
import { InputRef } from '@/components/common/Input/InputInterfaces';
import './EvFillRate_style.css';
import EditIcon from '@mui/icons-material/Edit';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import EMobiledataIcon from '@mui/icons-material/EMobiledata';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AltRouteIcon from '@mui/icons-material/AltRoute';

// Para la tabla de la vista principal
interface Column {
   id: 'cliente' | 'codigo' | 'producto' | 'pedido' | 'facturado' | 'pendiente'| 'porcentaje';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columnsPrincipal: readonly Column[] = [
   { id: 'cliente', label: 'Fecha', minWidth: 100 },
   { id: 'codigo', label: 'Tipo', minWidth: 100 },
   { id: 'producto', label: 'Folio', minWidth: 100 },
   { id: 'pedido', label: 'Rut', minWidth: 100 },
   { id: 'facturado', label: 'Razón Social', minWidth: 100 },
   { id: 'pendiente', label: 'Total', minWidth: 100 },
   { id: 'porcentaje', label: 'Total', minWidth: 100 }
];

interface Data {
   cliente: string;
   codigo: string;
   producto: string;
   pedido: string;
   facturado: string;
   pendiente: string;
   porcentaje: string;
}
function createData(
   cliente: string,
   codigo: string,
   producto: string,
   pedido: string,
   facturado: string,
   pendiente: string,
   porcentaje: string
) {
   return { cliente, codigo, producto, pedido, facturado, pendiente, porcentaje };
}

const rowsListado = [
   createData('COMPAÑIA DISEÑO CARIÑO MAÑANA', '000001', 'escobas de madera', '132', '0', '132','0'),
   createData('COMPAÑIA PATITO JUAN', '000002', 'escobas para barrer', '131', '0', '131','0')
]
export const EvFillRate: React.FC<Props> = (props: Props): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);

   const [siguiente, setSiguiente] = useState(false);

   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [openEmail, setOpenEmail] = useState(false);
   const [openRecord, setOpenRecord] = useState(false);
   const [openState, setOpenState] = useState(false);
   const [openDetail, setOpenDetail] = useState(false);
   const [open, setOpen] = useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);

   //Modal Email
   const handleOpenEmail = () => setOpenEmail(true);
   const handleCloseEmail = () => setOpenEmail(false);
   //Modal Historial de envíos
   const handleOpenRecord = () => setOpenRecord(true);
   const handleCloseRecord = () => setOpenRecord(false);
   //Modal Estado de Cotizacion
   const handleOpenState = () => setOpenState(true);
   const handleCloseState = () => setOpenState(false);
   //Modal Detalle de Cotizacion
   const handleOpenDetail = () => setOpenDetail(true);
   const handleCloseDetail = () => setOpenDetail(false);

   const handleSiguiente = () => setSiguiente(true);

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };


   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const [valueContrato, setvalueContrato] = useState({
      rut_proveedor: '',
      titulo: '',
      numero_contrato: '',
      descripcion: '',
   });

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 750,
      minHeight: 350,
      maxHeight: 600,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

   const periodo = [
      { label: '2022' },
      { label: '2021' },
      { label: '2020' },
      { label: '2019' },
      { label: '2018' },
      { label: '2017' },
      { label: '2016' },
      { label: '2015' },
   ]
   const mes = [
      { label: 'Enero' },
      { label: 'Febrero' },
      { label: 'Marzo' },
      { label: 'Abril' },
      { label: 'Mayo' },
      { label: 'Junio' },
      { label: 'Agosto' },
      { label: 'Setiembre' },
      { label: 'Octubre' },
      { label: 'Noviembre sin ti' },
      { label: 'Diciembre' },
      { label: 'TODOS' }
   ]
   const filtro = [
      { label: 'Por Cliente' }
   ]
   const informe = [
      { label: 'Por Factura' }
   ]

   const rules = useMemo(() => ({
      rut_proveedor: [
         VALIDATORS.REQUIRED,
      ],
      numero_contrato: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      titulo: [
         VALIDATORS.REQUIRED
      ],
      descripcion: [
         VALIDATORS.REQUIRED,
      ]

   }), []);
   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'rut_proveedot':
            props.setvalueContrato(prev => ({ ...prev, rut_proveedot: value }));
            break;
         case 'numero_contrato':
            props.setvalueContrato(prev => ({ ...prev, numero_contrato: value }))
            break;
         case 'titulo':
            props.setvalueContrato(prev => ({ ...prev, titulo: value }))
            break;
         case 'descripcion':
            props.setvalueContrato(prev => ({ ...prev, descripcion: value }));
            break;

         default:
            break;
      }
   };

   // const handleInputBlur = (event: any) => {
   //    const newVal = (event.target.value).trim();
   //    const name = event.target.name;
   //    props.setvalueContrato(prev => ({ ...prev, [name]: newVal }));
   // }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }

   function createData(
      tipo: string,
      total: string,
      generadas: string,
      vigentes: string,
      nulas: string,
      adjudicadas: string,
      noAdjudicadas: string,
   ) {
      return { tipo, total, generadas, vigentes, nulas, adjudicadas, noAdjudicadas };
   }

   const rows = [
      createData('Cotizaciones Electrónicas', '16', '', '12', '', '4', '')
   ];

   return (
      <Protected >

         <Container maxWidth='xl' style={{ minHeight: '100vh' }}>
            <form style={{ marginBottom: '25px' }}>
               <Typography variant="h6" gutterBottom mb={3} className="tittle">
                  FILL RATE
               </Typography>

               <Grid item container m={2} xs={12} >
                  <Typography variant="subtitle1" >
                     Seleccione Informe Segun Corresponda
                  </Typography>
               </Grid>


               <Grid container spacing={3} className={`e-mb-lg`} >
                  <Grid item xs={12} sm={3}>
                     <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={periodo}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Periodo" />}
                     />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                     <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={mes}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Mes" />}
                     />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                     <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={filtro}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Filtro" />}
                     />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                     <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={informe}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Informe por" />}
                     />
                  </Grid>

                  <Grid item container
                     xs={12}
                     justifyContent='right'
                  >
                     <Grid item>
                        <Button
                           // type='submit'
                           variant='contained'
                           onClick={handleSiguiente}
                           disabled={loading}
                           disableElevation
                           className='btn_save'
                        >
                           <span>Siguiente</span>
                        </Button>

                     </Grid>
                  </Grid>
               </Grid>
            </form>

            {siguiente && (
               <Grid container m={2}>
                  <Grid item xs={12} mb={5}>
                     <Typography variant="h6" id="modal-modal-title" mb={2} className='tittle'>
                     Fill Rate Clientes
                        </Typography>
                     <Typography variant="subtitle1">
                     Informe por Clientes {'02'} - {'2022'}
                     </Typography>
                  </Grid>
                  <Grid item xs={12} mb={5}>
                     <div className='bg-tittle'>
                        <Typography
                           mt={3}
                           variant="h6"
                           align='center'
                           className='tittle-text'
                        >Listado por Clientes
                        </Typography>
                     </div>
                  </Grid>                 
                  <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                     <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                           <TableHead>
                              <TableRow>
                                 <TableCell>Cliente</TableCell>
                                 <TableCell>Codigo</TableCell>
                                 <TableCell>Producto</TableCell>
                                 <TableCell>Pedido</TableCell>
                                 <TableCell>Facturado</TableCell>
                                 <TableCell>Pendiente</TableCell>
                                 <TableCell>%</TableCell>
                              </TableRow>
                           </TableHead>
                           <TableBody>
                              {rowsListado.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                 return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.codigo}>
                                       {columnsPrincipal.map((column) => {
                                          const value = row[column.id];
                                          return (
                                             <TableCell key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number'
                                                   ? column.format(value)
                                                   : value}
                                             </TableCell>
                                          );
                                       })}
                                    </TableRow>
                                 );
                              })}
                           </TableBody>
                        </Table>
                     </TableContainer>
                     <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rowsListado.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                     />
                  </Paper>
               </Grid>

            )}
         </Container>

      </Protected>
   );
}