import React, { useState, useMemo, useRef, useEffect } from 'react';
import { FunctionComponent } from "react";
import { Grid, Typography } from '@mui/material'

import ArticleIcon from '@mui/icons-material/Article';
import Link from '@mui/material/Link';
import { ROUTE_EV_DE_ELECTRONIC_TICKET } from '@/toolbox/constants/route-map';


export const Tickets: FunctionComponent = () => {

   return (
      <>
         <Grid container spacing={2} pl={5} mt={1}>
            <Grid container item>
               <Link href={ROUTE_EV_DE_ELECTRONIC_TICKET} underline="none">
                  <Grid className={'enlacesDoc'}>
                     <ArticleIcon />
                     <Typography variant="subtitle1" ml={2}>
                        BOLETA ELECTRONICA
                     </Typography>
                  </Grid>
               </Link>
            </Grid>

         </Grid>
      </>
   );
}

