import React, { useState, useMemo, useRef, useEffect } from 'react';
import { FunctionComponent } from "react";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateRangePicker, { DateRange } from '@mui/lab/DateRangePicker';
import { Grid, Container, Typography,IconButton, SelectChangeEvent, Autocomplete, Button, Tooltip,} from '@mui/material'
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Box from '@mui/material/Box';

interface TabMonthInformProps {
    
}

const informsConsolidate=[
   {label:"Consolidados X Cliente",value:"all"},
   {label:"Consolidados X Vendedor",value:"all"},
   {label:"Consolidados X Producto",value:"all"},
   {label:"Consolidados X Sucursal",value:"all"},
   {label:"Consolidados X Segmentacion",value:"all"},

]
const internSells=[
   {label:"SI",value:"all"},
   {label:"NO",value:"all"},
]

const sucursales=[
   {label:"Sucursal 1",value:"all"},
   {label:"Sucursal 2",value:"all"},
   {label:"Sucursal 3",value:"all"},
   {label:"Sucursal 4",value:"all"},
   {label:"Sucursal 5",value:"all"},
]
 
export const TabMonthProducts: FunctionComponent<TabMonthInformProps> = () => {
    const [value, setValue] = React.useState<DateRange<Date>>([null, null]);

    return ( 
        <>
        <Grid container spacing={3}>
                <Grid item xs={12} md={3} textAlign="start" mt={2}>
                  <Typography mt={1} variant='h6'  gutterBottom className="tittle4">
                  Fecha desde:
                  </Typography>
               </Grid>
               <Grid item xs={12} md={9} mt={2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                     <DateRangePicker
                     startText="Inicio"
                     endText="Termino"
                     value={value}
                     onChange={(newValue) => {
                        setValue(newValue);
                     }}
                     renderInput={(startProps, endProps) => (
                        <>
                              <TextField {...startProps}  size="small" />
                              <Typography  sx={{ mx: 2 }} variant='h6' component='h1' gutterBottom className="tittle4">
                                hasta
                                </Typography>
                              <TextField {...endProps}  size="small"/>
                        </>
                     )}
                     />
                  </LocalizationProvider>
               </Grid>
               <Grid item xs={12} md={3} textAlign="start">
                  <Typography mt={1} variant='h6' component='h1' gutterBottom className="tittle4">
                  Producto :
                  </Typography>
               </Grid>

               <Grid item xs={10} md={9}>
                  <TextField
                  id="outlined-select-currency"
                  // value="Diciembre"
                  fullWidth
                  size='small'
                  />
               </Grid>
               <Grid item container
                  xs={12}
                  mt={2}
                 justifyContent='right'
                 >
                     <Grid item>
                        <Button
                           type='submit'
                           variant='contained'
                           // onClick={() => {
                           // }}
                           // disabled={loading}
                           disableElevation
                           className='btn_save3'
                        >
                                 <span>Generar</span>
                        </Button>
                     </Grid>
               </Grid> 
            </Grid>
        </>
     );
}
 
