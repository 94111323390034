import React,{ FunctionComponent, useState } from "react"
import { Alert, Button, FormControl, Grid, MenuItem, Snackbar, TableContainer, Typography,useTheme ,useMediaQuery, IconButton, Backdrop, CircularProgress, TableBody, Table, } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import InputLabel from '@mui/material/InputLabel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import PeopleIcon from '@mui/icons-material/People';
import { ROUTE_PROVIDER_CREATE } from '@/toolbox/constants/route-map';
import { Link } from 'react-router-dom';
import InputBase from '@mui/material/InputBase';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Protected } from "@/components/layout/Protected";

export const StateOC: FunctionComponent=(props:any)=>{

   const [data, setData] = useState('');
   const [open, setOpen] = useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);
   const [select, setSelect] =useState('');

   const handleChange = (event: any) => {
   setData(event.target.value as string);
   };

   const style = {
   position: 'absolute' as 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   width: 450,
   bgcolor: 'background.paper',
   // border: '2px solid #000',
   boxShadow: 24,
   p: 2,
   borderRadius: '20px'
};
    return(
        <Protected>
            <div className='spacing' style={{height:'100vh'}}>
            <Grid container>
               <Grid item xs={12} className='end'>
                  <Grid container>
                     <Grid item xs={10} sm={4}>
                        <Typography
                        mt={1}
                        variant="h3"
                        className='tittle'
                        sx={{ textTransform: 'uppercase'}}
                        >Estado Orden de Compra</Typography>
                     </Grid>

                     <Grid item xs={2} sm={1} className=''>
                        <Button  className='btnData'variant="contained"  onClick={handleOpen}>
                           {<AddIcon/>}
                        </Button>
                     </Grid>
                  </Grid>
               </Grid>
               
               <Grid container mt={2}>
                  <Grid item xs={12}>
                     <Box mt={1} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <FormControl fullWidth >
                                       <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                             <TableContainer sx={{ maxHeight: 540 }}>
                                             <Table stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                   <TableRow>
                                                      <TableCell align="center">Num.</TableCell>
                                                      <TableCell align="center">Estado de Compra</TableCell>
                                                      
                                                      <TableCell align="center">Orden</TableCell>
                                                      <TableCell align="center">Acciones</TableCell>
                                                   </TableRow>
                                                </TableHead>
                     
                                                <TableBody>
                                                   <TableRow >  
                                                      <TableCell>
                                                         1
                                                      </TableCell>

                                                      <TableCell>
                                                         Nula  
                                                      </TableCell>
                                                    
                                                      <TableCell>
                                                         1
                                                      </TableCell>

                                                      <TableCell align="left">
                                                         <div className='sepaEle'>                                                            
                                                            <button className='btnIcoD'><EditIcon /></button> 
                                                            <button className='btnIcoD'><DeleteIcon /></button>                                                           
                                                         </div>
                                                      </TableCell>
                                                   </TableRow>
                                                   
                                                </TableBody>
                                             </Table>
                                             </TableContainer>
                                             
                                       </Paper>
                                    </FormControl>
                     </Box>
                  </Grid>                 
               </Grid>
            </Grid>
         </div>
         <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <Grid container>
                        <Grid item xs={10}>
                           <Typography variant="h6" id="modal-modal-title" mb={2} className='tittle'>
                              Mantenedor Orden de Compra
                           </Typography>
                        </Grid>
                        <Grid item xs={2} textAlign="end">
                           <AssignmentIcon style={{color:'#FF9700'}}/>
                        </Grid>
                     </Grid>

                        <Grid item xs={12} mt={2}>
                           <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                              <FormControl fullWidth >
                                 <TextField
                                    id="input-with-sx"
                                    label="Estado de Compra"
                                    onChange={handleChange}
                                    placeholder="Ingrese el Estado Compra"
                                    rows={2}
                                 />
                              </FormControl>
                           </Box>
                        </Grid>
                        <Grid item xs={12} mt={2}>
                           <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                              <FormControl fullWidth >
                                 <TextField
                                    id="input-with-sx"
                                    label="Orden"
                                    onChange={handleChange}
                                    placeholder="Ingrese el Orden"
                                    rows={2}
                                 />
                              </FormControl>
                           </Box>
                        </Grid>
                        <Grid container mt={1} textAlign="center">
                           <Grid item xs={12} >
                              <FormControlLabel control={<Checkbox defaultChecked />} label="Estado indicador(Solo un estado estará activo)" />
                           </Grid>
                        </Grid>

                        <Grid container mt={2}>
                           <Grid item xs={6} textAlign="center">
                              <Button variant="contained" className='btnColorCanceled' onClick={handleClose}>
                              Cancelar
                              </Button>
                           </Grid>
                           <Grid item xs={6} textAlign="center">
                              <Button variant="contained" className='btnColorAccepeted' onClick={handleClose}>
                                 Guardar
                              </Button>
                           </Grid>
                        </Grid>
                  </Box>
                     
            </Modal>

        </Protected>
    )
}