import React, { useState, useMemo, useRef, useEffect } from 'react';
import { FunctionComponent } from "react";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateRangePicker, { DateRange } from '@mui/lab/DateRangePicker';
import { Grid, Container, Typography,IconButton, SelectChangeEvent, Autocomplete, Button, Tooltip,} from '@mui/material'
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Box from '@mui/material/Box';
import DatePicker from '@mui/lab/DatePicker';

interface TabMonthInformProps {
    
}

const informsConsolidate=[
   {label:"Consolidados X Cliente",value:"all"},
   {label:"Consolidados X Vendedor",value:"all"},
   {label:"Consolidados X Producto",value:"all"},
   {label:"Consolidados X Sucursal",value:"all"},
   {label:"Consolidados X Segmentacion",value:"all"},

]
const internSells=[
   {label:"SI",value:"all"},
   {label:"NO",value:"all"},
]

export const TabYearInform: FunctionComponent<TabMonthInformProps> = () => {
    const [value, setValue] = React.useState<DateRange<Date>>([null, null]);
    const [value2, setValue2] = React.useState<Date | null>(new Date());

    return ( 
        <>
        <Grid container spacing={3}>

               <Grid item xs={12} md={5} textAlign="start">
                  <Typography mt={1} variant='h6' component='h1' gutterBottom className="tittle4">
                  Informe a:
                  </Typography>
               </Grid>

               <Grid item xs={10} md={7}>
                  <TextField
                  id="outlined-select-currency"
                  value="Diciembre"
                  fullWidth
                  size='small'
                  />
               </Grid>

                <Grid item xs={12} md={5} textAlign="start">
                  <Typography mt={1} variant='h6'  gutterBottom className="tittle4">
                  Periodo:
                  </Typography>
               </Grid>
               <Grid item xs={12} md={7}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                     <DatePicker
                        views={['year']}
                        value={value2}
                        onChange={(newValue:Date) => {
                           setValue2(newValue);
                        }}
                        renderInput={(params) => <TextField fullWidth {...params} helperText={null} />}
                     />
                  </LocalizationProvider>
               </Grid>

               <Grid item xs={12} md={5} textAlign="start">
                  <Typography mt={1} variant='h6' component='h1' gutterBottom className="tittle4">
                  Informe de Consolidacion:
                  </Typography>
               </Grid>

               <Grid item xs={10} md={7}>
                        <Autocomplete
                           
                           disablePortal
                           id="combo-Grid-demo"
                           options={informsConsolidate}
                           fullWidth
                           // sx={{ width: 300 }}
                           size='small'
                           renderInput={(params) => <TextField {...params} label="Informe de Consolidacion" />}
                        />
               </Grid>
               <Grid item xs={12} md={5} textAlign="start">
                  <Typography mt={1} variant='h6' component='h1' gutterBottom className="tittle4">
                  Interno de Ventas :
                  </Typography>
               </Grid>

               <Grid item xs={10} md={7}>
                        <Autocomplete
                           
                           disablePortal
                           id="combo-Grid-demo"
                           options={internSells}
                           fullWidth
                           // sx={{ width: 300 }}
                           size='small'
                           renderInput={(params) => <TextField {...params} label="Informe de Consolidacion" />}
                        />
               </Grid>

               <Grid item container
                  xs={12}
                  mt={2}
                 justifyContent='right'
                 >
                     <Grid item>
                        <Button
                           type='submit'
                           variant='contained'
                           // onClick={() => {
                           // }}
                           // disabled={loading}
                           disableElevation
                           className='btn_save3'
                        >
                                 <span>Generar</span>
                        </Button>
                     </Grid>
               </Grid> 
            </Grid>
        </>
     );
}
 
