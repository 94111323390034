import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { ArrowRightIcon, DeleteIcon } from "@/toolbox/constants/icons";
import { VALIDATORS } from "@/toolbox/helpers/validation-rules";
import { Grid, Container, Button, Autocomplete, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, TablePagination, Modal, Box, } from '@mui/material';
import { Input } from '@components/common/Input';
import { Props } from "./AddLoan.type";
import BusinessIcon from '@mui/icons-material/Business';
import { InputRef } from '@/components/common/Input/InputInterfaces';
import './AddLoan_style.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { SelectChangeEvent } from '@mui/material/Select';
import { Link } from 'react-router-dom';
import { ROUTE_RM_LOAN_WORKER, ROUTE_RM_SALARY_LIQUIDATION } from '@/toolbox/constants/route-map';
import { DataModalViewQuotas } from './ModalViewQuotas/DataModalViewQuotas';
import { DatePicker, LocalizationProvider } from '@mui/lab';

// Para la tabla de la vista principal
interface Column {
   id: 'codigo' | 'estado' | 'monto' | 'cuotas' | 'desde' | 'solicitado' | 'aprobado';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columnsPrincipal: readonly Column[] = [
   { id: 'codigo', label: 'Codigo', minWidth: 100 },
   { id: 'estado', label: 'estado', minWidth: 100 },
   { id: 'monto', label: 'monto', minWidth: 100 },
   { id: 'cuotas', label: 'cuotas', minWidth: 100 },
   { id: 'desde', label: 'desde', minWidth: 100 },
   { id: 'solicitado', label: 'solicitado', minWidth: 100 },
   { id: 'aprobado', label: 'aprobado', minWidth: 100 },
];

interface Data {
   codigo: string;
   estado: string;
   monto: string;
   cuotas: string;
   desde: string;
   solicitado: string;
   aprobado: string;
}
function createData(
   codigo: string,
   estado: string,
   monto: string,
   cuotas: string,
   desde: string,
   solicitado: string,
   aprobado: string,
) {
   return { codigo, estado, monto, cuotas, desde, solicitado, aprobado };
}

const rowsListado = [
   createData('1', 'AUTORIZADO', '20000', '3', '13-09-2019', '2022-03-01', '15134573-5'),
   createData('2', 'AUTORIZADO', '1000', '3', '13-09-2019', '2022-03-01', '15134573-5'),
   createData('3', 'AUTORIZADO', '100000', '9', '13-09-2019', '2022-03-01', '15134573-5'),
   createData('4', 'AUTORIZADO', '2999', '2', '13-09-2019', '2022-03-01', '15134573-5'),
   createData('5', 'AUTORIZADO', '2000', '1', '13-09-2019', '2022-03-01', '15134573-5'),
]

export const AddLoan: React.FC<Props> = (props: Props): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   // const [openProducts, setOpenProducts] = useState(false);
   const [loading, setLoading] = useState(false);
   const [selectTypeDesc, setSelectTypeDesc] = useState('');
   const [isDisabled, setIsDisabled] = useState<boolean>(false);

   const [open, setOpen] = useState(false);

   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   // const handleOpenProducts = () => setOpenProducts(true);
   // const handleCloseProducts = () => setOpenProducts(false);
   const [openAddLoan, setOpenAddLoan] = useState(false); //para modal

   //Modal mantenedor de prestamos
   const handleOpenAddLoan = () => setOpenAddLoan(true);
   const handleCloseAddLoan = () => setOpenAddLoan(false);

   const handleChangeTypeDesc = (event: SelectChangeEvent) => {
      setSelectTypeDesc(event.target.value);
   };
   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const coutas = [
      { label: '1' },
      { label: '2' },
      { label: '3' },
      { label: '4' },
      { label: '5' },
      { label: '6' },
      { label: '7' },
      { label: '8' },
      { label: '9' },
      { label: '10' },
      { label: '11' },
      { label: '12' },
   ]
   const periodo = [
      { label: '2022' },
      { label: '2021' },
      { label: '2020' },
      { label: '2019' },
      { label: '2018' },
      { label: '2017' },
      { label: '2016' },
      { label: '2015' },
   ]
   const mes = [
      { label: 'Enero' },
      { label: 'Febrero' },
      { label: 'Marzo' },
      { label: 'Abril' },
      { label: 'Mayo' },
      { label: 'Junio' },
      { label: 'Agosto' },
      { label: 'Setiembre' },
      { label: 'Octubre' },
      { label: 'Noviembre' },
      { label: 'Diciembre' },
      { label: 'TODOS' }
   ]

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 720,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };



   const rules = useMemo(() => ({
      rut_proveedor: [
         VALIDATORS.REQUIRED,
      ],
      numero_contrato: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      titulo: [
         VALIDATORS.REQUIRED
      ],
      descripcion: [
         VALIDATORS.REQUIRED,
      ]

   }), []);
   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'rut_proveedot':
            props.setvalueContrato(prev => ({ ...prev, rut_proveedot: value }));
            break;
         case 'numero_contrato':
            props.setvalueContrato(prev => ({ ...prev, numero_contrato: value }))
            break;
         case 'titulo':
            props.setvalueContrato(prev => ({ ...prev, titulo: value }))
            break;
         case 'descripcion':
            props.setvalueContrato(prev => ({ ...prev, descripcion: value }));
            break;

         default:
            break;
      }
   };

   // const handleInputBlur = (event: any) => {
   //    const newVal = (event.target.value).trim();
   //    const name = event.target.name;
   //    props.setvalueContrato(prev => ({ ...prev, [name]: newVal }));
   // }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }
   return (
      <Protected>
         <Container maxWidth='xl'>
            <form style={{ margin: '8px' }}>
               <Grid container>
                  <Grid item xs={12} sm={8}>
                     <Typography variant="h6" id="modal-modal-title" mb={2} className='tittle'>
                        MANTENEDOR DE PRÉSTAMOS
                     </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} textAlign="end">
                     <Button variant="outlined" component={Link} to={ROUTE_RM_LOAN_WORKER} startIcon={<ArrowBackIosIcon />}>
                        Volver
                     </Button>
                  </Grid>
               </Grid>
               <Grid container mb={4} alignItems={"center"}>
                  <Typography variant="subtitle1" >
                     Incorpore la información de Préstamos a sus Trabajadores.
                  </Typography>
               </Grid>

               <Grid container spacing={3} mt={2} className={`e-mb-lg`} xs={12} sm={6}>
                  <Grid item xs={12}>
                     <div className='bg-tittle3'>
                        <Typography
                           mt={1}
                           variant="h6"
                           align='center'
                           className='tittle-text'
                        >Datos de Préstamo
                        </Typography>
                     </div>                    
                  </Grid>
                  <Grid item xs={12} >
                     <Typography variant="subtitle1" >
                        TRABAJADOR: 16976751-3 - FERNANDO ALARCON GONZALEZ
                     </Typography>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                     <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <BusinessIcon />
                           </InputAdornment>
                        }
                        name="rut_proveedor"
                        placeholder="Monto"
                        type="text"
                        // value={valueContrato.rut_proveedor}
                        onChange={handleInput}
                        // onBlur={handleInputBlur}
                        // rules={rules.rut_proveedor}
                        disableElevation
                        // validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                     <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={coutas}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Cuotas" />}
                     />
                  </Grid>
                  <Grid item xs={12}>
                     <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                           label="Fecha Solicitud"
                           value={value}
                           onChange={(newValue) => {
                              setValue(newValue);
                           }}
                           renderInput={(params) => <TextField {...params} />}
                        />
                     </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6} >
                     <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                           label="Fecha Descuento"

                           value={value}
                           onChange={(newValue) => {
                              setValue(newValue);
                           }}
                           renderInput={(params) => <TextField {...params} />}
                        />
                     </LocalizationProvider>
                  </Grid>
               </Grid>
               <Grid item container
                  xs={12}
                  justifyContent='center'
                  sm={6}
                  mt={5}
                  mb={5}
               >
                  <Grid item>
                     <Button
                        // type='submit'
                        variant='contained'
                        // onClick={handleSiguiente}
                        disabled={loading}
                        disableElevation
                        className='btn_save'
                     >
                        Agregar
                     </Button>

                  </Grid>
               </Grid>
            </form>
            <Grid item xs={12}>
               <div className='bg-tittle3'>
                  <Typography
                     mt={1}
                     variant="h6"
                     align='center'
                     className='tittle-text'
                  >Detalles de Préstamo
                  </Typography>
               </div>
            </Grid>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
               <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                     <TableHead>
                        <TableRow>
                           <TableCell>Codigo</TableCell>
                           <TableCell>Estado</TableCell>
                           <TableCell>Monto</TableCell>
                           <TableCell>Cuotas</TableCell>
                           <TableCell>Desde</TableCell>
                           <TableCell>Solicitado</TableCell>
                           <TableCell>Aprobado</TableCell>
                           <TableCell align="center">Opciones</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsListado.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.codigo}>
                                 {columnsPrincipal.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}


                                 <TableCell>
                                    {/* <div className='sepaEle' style={{ display: "flex", justifyContent: "space-evenly" }}> */}
                                    <Tooltip title='Detalles Cuotas'>
                                       <IconButton onClick={handleOpenAddLoan}>
                                          <SearchIcon />
                                       </IconButton>
                                    </Tooltip>
                                    <Tooltip title='Modificar'>
                                       <IconButton>
                                          <EditIcon />
                                       </IconButton>
                                    </Tooltip>
                                    <Tooltip title='Eliminar'>
                                       <IconButton>
                                          <DeleteIcon />
                                       </IconButton>
                                    </Tooltip>
                                    <Tooltip title='Autorizar'>
                                       <IconButton >
                                          <BorderColorIcon />
                                       </IconButton>
                                    </Tooltip>
                                    <Tooltip title='Imprimir'>
                                       <IconButton >
                                          <LocalPrintshopIcon />
                                       </IconButton>
                                    </Tooltip>
                                    {/* </div> */}
                                 </TableCell>
                              </TableRow>
                           );
                        })}
                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsListado.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>

         </Container>
         <Modal
            open={openAddLoan}
            onClose={handleCloseAddLoan}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataModalViewQuotas entity={undefined} />
            </Box>
         </Modal>
      </Protected>
   );
}