import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, } from '@mui/material';
import { ArrowBackIcon, ArrowDownIcon, ArrowRightIcon, MoreIcon } from "@toolbox/constants/icons";
import { Props } from './FormLocation.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import { Loading } from "@components/common/Loading";
// import { entityService } from '@/service/services/Entity.service';
// import { ubigeoService } from '@/service/services/Ubigeo.service';
import Entity from "@assets/img/entity.png";
import './style.sass'
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export const FormLocation: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false)
   const [typeRubro, setTypeRubro] = useState<any>([]);
   const [idpais, setIdPais] = useState(null);
   const [idregion, setIdRegion] = useState(null);
   const [idprovincia, setIdProvincia] = useState(null);
   const [idcomuna, setIdComuna] = useState(null);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [estado, setEstado] = useState<any>({ rut: false, nombre_rz: false })
   const [ubigeo, setUbigeo] = useState<any>({ pais: [], region: [], provincia: [], comuna: [] });
   console.log(props.valueEntity)

   const topUbigeos = [
      { label: 'Ubigeo 1'},
      { label: 'Ubigeo 2'},
      { label: 'Ubigeo 3'},
      { label: 'Ubigeo 4'}
   ]

   const rules = useMemo(() => ({
      rutempresa: [
         VALIDATORS.REQUIRED,
         VALIDATORS.ONLY_LOWERCASE,
      ],
      razonsocial: [
         VALIDATORS.REQUIRED,

      ],
      email: [
         VALIDATORS.EMAIL.NAME_PONTS,
         VALIDATORS.EMAIL.POINTS_IN_A_ROW,
         VALIDATORS.EMAIL.NAME_SIZE,
         VALIDATORS.EMAIL.VALID,
      ],
      nombre: [
         VALIDATORS.REQUIRED,
      ],
      user_nick: [
         VALIDATORS.REQUIRED,
         VALIDATORS.ONLY_LOWERCASE,
      ],
      administrador: [
         VALIDATORS.REQUIRED,
      ],
      password: [
         VALIDATORS.REQUIRED,
         VALIDATORS.PASSWORD.SIZE,
         VALIDATORS.PASSWORD.ALLOWED_CHARACTERS,
         VALIDATORS.PASSWORD.VALID,
      ],
      credenciales: [
         VALIDATORS.REQUIRED,
         VALIDATORS.ONLY_LOWERCASE,
      ]
   }), []);


   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         
         case 'direccion':
            props.setValueLocation(prev => ({ ...prev, direccion: value }));
            break;
         default:
            break;
      }
   };
   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      props.setValueLocation(prev => ({ ...prev, [name]: newVal }));
   }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      props.handleNext();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }

   return (
      <Container maxWidth="xl">
         {/* <form onSubmit={handleSubmit}> */}
         <form >
            <Grid container spacing={3} className={`e-mb-lg`}>
            <Grid item xs={12}></Grid>
               
               <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[9] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <HomeWorkIcon />
                    </InputAdornment>
                    }
                     height={35}
                     name="direccion"
                     placeholder="Direccion"
                     //value={data.direccion}
                     value={props.valueLocation.direccion}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.razonsocial}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid>
               <Grid item xs={12} md={4}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={topUbigeos}
                     sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Pais" />}
                  />
               </Grid>
               <Grid item xs={12} md={4}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={topUbigeos}
                     sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Región" />}
                  />
               </Grid>
               <Grid item xs={12} md={4}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={topUbigeos}
                     sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Provincia" />}
                  />
               </Grid>
               <Grid item xs={12} md={4}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={topUbigeos}
                     sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Comuna" />}
                  />
               </Grid>
               <Grid container item xs={12}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center">
                  <Grid item >
                     <Button
                        fullWidth={true}
                        variant='contained'
                        onClick={props.handleBack}
                        disabled={loading}
                        disableElevation
                        startIcon={<ArrowBackIosIcon/>}
                     >
                        <span>Volver</span>
                     </Button>
                  </Grid>

                  <Grid item >
                     <Button
                        fullWidth={true}
                        type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                        endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>Aceptar y Continuar</span>
                        }
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
         </form>
         <Modal
            open={openModal}
            //onClose={() => { setOpenModal(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={{
               position: 'absolute',
               top: '50%',
               left: '50%',
               transform: 'translate(-50%, -70%)',
               width: 300,
               bgcolor: '#ffffff',
               boxShadow: 24,
               p: 4,
            }}>
               <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
                  <Box
                     component="img"
                     textAlign="center"
                     sx={{
                        width: "100px",
                        height: "100px",
                     }}
                     src={Entity}
                  ></Box>
               </Grid>
               <Divider />
               <Typography id="modal-modal-description" textAlign='center' sx={{ mt: 2, mb: 2 }}>
                  {` ${estado.rut ? ' El Rut, ' : ''}${estado.nombre_rz ? 'Razon Social,' : ''} está registrado, Ingrese correctamente los datos de la empresa`}
               </Typography>
               <Divider />
               <Grid container item xs={12} sx={{ pt: 2 }}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center">
                  <Grid item>
                     <Button
                        fullWidth
                        variant='contained'
                        onClick={() => { setOpenModal(false) }}
                     >
                        Aceptar
                     </Button>
                  </Grid>
               </Grid>
            </Box>
         </Modal>
      </Container>
   );
}
