import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { ArrowRightIcon } from "@/toolbox/constants/icons";
import { VALIDATORS } from "@/toolbox/helpers/validation-rules";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, TablePagination, } from '@mui/material';
import { Input } from '@components/common/Input';
import { Props } from "./NoSegementation.type";
import BusinessIcon from '@mui/icons-material/Business';
import { InputRef } from '@/components/common/Input/InputInterfaces';
import AssignmentIcon from '@mui/icons-material/Assignment';
import './NoSegmentation_style.css';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SearchIcon from '@mui/icons-material/Search';

import AbcIcon from '@mui/icons-material/Abc';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Link } from 'react-router-dom';
import { ROUTE_GC_FAMILIES, ROUTE_GC_PRICE_LIST } from '@/toolbox/constants/route-map';
import { DataModalProducts } from '../ModalListProducts/DataModalProducts';

// Para la tabla de la vista principal
interface Column {
   id: 'codigo' | 'detalle' | 'valor' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

const columnsPrincipal:readonly Column[] = [
   { id: 'codigo', label: 'Rut', minWidth: 100 },
   { id: 'detalle', label: 'Razon Social', minWidth: 100 },
   { id: 'valor', label: 'Fantasia', minWidth: 100 }
   ];

interface Data {
   codigo: string;
   detalle: string;
   valor: string;
}
function createData(
   codigo: string,
   detalle: string,
   valor: string,
) {
   return { codigo, detalle, valor };
}

const rowsListado = [
   createData('proc-1', 'prodcuto prueba', '1.0'),
   createData('pois-2', 'otro producto prueba', '2.0'),
   createData('robs-ñ', 'cerveza', '24.5'),
   createData('code-1', 'jarabe de palo', '23.7')
]

export const NoSegementation: React.FC<Props> = (props: Props): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [openProducts, setOpenProducts] = useState(false);


   const handleOpenProducts = () => setOpenProducts(true);
   const handleCloseProducts = () => setOpenProducts(false);

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 720,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const rules = useMemo(() => ({
      rut_proveedor: [
         VALIDATORS.REQUIRED,
      ],
      numero_contrato: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      titulo: [
         VALIDATORS.REQUIRED
      ],
      descripcion: [
         VALIDATORS.REQUIRED,
      ]

   }), []);
   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'rut_proveedot':
            props.setvalueContrato(prev => ({ ...prev, rut_proveedot: value }));
            break;
         case 'numero_contrato':
            props.setvalueContrato(prev => ({ ...prev, numero_contrato: value }))
            break;
         case 'titulo':
            props.setvalueContrato(prev => ({ ...prev, titulo: value }))
            break;
         case 'descripcion':
            props.setvalueContrato(prev => ({ ...prev, descripcion: value }));
            break;

         default:
            break;
      }
   };

   // const handleInputBlur = (event: any) => {
   //    const newVal = (event.target.value).trim();
   //    const name = event.target.name;
   //    props.setvalueContrato(prev => ({ ...prev, [name]: newVal }));
   // }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }
   return (
      <Protected>
         <Container maxWidth='xl'>
            <form style={{ margin: '8px' }}>               
               <Grid container mb={4}>
                  <Grid item xs={12} sm={8}>
                     <Typography variant="h6" id="modal-modal-title" mb={2} className='tittle'>
                        AGREGAR PRODUCTOS
                     </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} textAlign="end">
                  <Button variant="outlined" component={Link} to={ROUTE_GC_PRICE_LIST} startIcon={<ArrowBackIosIcon />}>                 
                     Volver (Lista de Precios)
                  </Button>
                  </Grid>
               </Grid>             

               <Grid container spacing={3} className={`e-mb-lg`} >
                  <Grid item xs={12} sm={3}>
                     <Input
                        ref={ref => inputRefs.current[2] = ref}
                        appendAdornment={
                           <InputAdornment position="end">
                              <IconButton onClick={handleOpenProducts}>
                                 <SearchIcon />                                 
                              </IconButton>

                           </InputAdornment>
                        }
                        name="valor_compra"
                        placeholder="Código"
                        //value={data.nombre_rz}
                        // value={props.valueContrato.valor_compra}
                        onChange={handleInput}
                        //onBlur={handleInputBlur}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.valor_compra}
                        disableElevation
                        validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'                        
                     />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                     <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <AbcIcon />

                           </InputAdornment>
                        }
                        name="valor_compra"
                        placeholder="Detalle"
                        //value={data.nombre_rz}
                        // value={props.valueContrato.valor_compra}
                        onChange={handleInput}
                        //onBlur={handleInputBlur}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.valor_compra}
                        disableElevation
                        validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                     <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <AttachMoneyIcon />
                           </InputAdornment>
                        }
                        name="valor_compra"
                        placeholder="Valor"
                        //value={data.nombre_rz}
                        // value={props.valueContrato.valor_compra}
                        onChange={handleInput}
                        //onBlur={handleInputBlur}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.valor_compra}
                        disableElevation
                        validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>                               

                  <Grid item container
                     xs={12}
                     justifyContent='right'
                  >
                     <Grid item>
                        <Button
                           type='submit'
                           variant='contained'
                           onClick={handleSubmit}
                           disabled={loading}
                           disableElevation
                           className='btn_save'
                        >
                           <span>Agregar</span>
                        </Button>

                     </Grid>
                  </Grid>
               </Grid>
            </form>

            <div className='bg-tittle'>
               <Typography
                  mt={3}
                  variant="h6"
                  align='center'
                  className='tittle-text'
               >Listados de Productos Ingresados
               </Typography>
            </div>

            <Paper sx={{ width: '100%', overflow: 'hidden'}}>
            <TableContainer component={Paper}>
               <Table aria-label="simple table">
                  <TableHead>
                     <TableRow>
                        <TableCell>Codigo</TableCell>
                        <TableCell align="center">Detalle / Descripcion</TableCell>
                        <TableCell align="center">Valor</TableCell>
                        <TableCell align="center">Opciones</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                  {rowsListado.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.codigo}>
                                 {columnsPrincipal.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                           

                           <TableCell align="center">

                              <IconButton
                                 aria-label="delete"
                                 // onClick={() => handleDelete(row.id)}
                                 className='btn_delete'
                                 // onClick={() => handleOpen()}
                              // disabled={loading}
                              >
                                 <EditIcon />                                 
                              </IconButton>
                              <IconButton
                                 aria-label="delete"
                                 // onClick={() => handleDelete(row.id)}
                                 className='btn_delete'
                                 // onClick={() => handleOpen()}
                              // disabled={loading}
                              >                                 
                                 <DeleteIcon/>
                              </IconButton>

                           </TableCell>                      
                        </TableRow>
                    );
                  })}
                  </TableBody>
               </Table>
            </TableContainer>
            <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsListado.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
            </Paper>

         </Container>
         <Modal
            open={openProducts}
            onClose={handleCloseProducts}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataModalProducts entity={undefined} />
            </Box>
         </Modal>
         {/* <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <Grid container>
                  <Grid item xs={10}>
                     <Typography variant="h6" id="modal-modal-title" mb={2} className='tittle'>
                       Seleccionar Producto
                     </Typography>
                  </Grid>
                  <Grid item xs={2} textAlign="end">
                     <AssignmentIcon style={{ color: '#FF9700' }} />
                  </Grid>
               </Grid>
               
               <TableContainer component={Paper}>
               <Table aria-label="simple table">
                  <TableHead>
                     <TableRow>
                        <TableCell>N°</TableCell>
                        <TableCell align="center">Código</TableCell>
                        <TableCell align="center">Barra</TableCell>
                        <TableCell align="center">CódigopP</TableCell>
                        <TableCell align="center">Descripción</TableCell>
                        <TableCell align="center">Precio</TableCell>
                        <TableCell align="center">Stock</TableCell>                        
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {rowsListadoProductos.map((row, index) => (
                        <TableRow hover key={index} >
                           <TableCell component="th" scope="row">
                              {row.numero}
                           </TableCell>
                           <TableCell align="center">{row.codigo}</TableCell>
                           <TableCell align="center">{row.barra}</TableCell>
                           <TableCell align="center">{row.codigoP}</TableCell>
                           <TableCell align="center">{row.descripcion}</TableCell>
                           <TableCell align="center">{row.precio}</TableCell>
                           <TableCell align="center">{row.stock}</TableCell>                           
                        </TableRow>
                     ))}
                  </TableBody>
               </Table>
            </TableContainer>                       
            </Box>

         </Modal> */}
      </Protected>
   );
}