import React, { useState, useEffect } from 'react';
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Protected } from "@/components/layout/Protected";
import { FormControl, Grid, Stack, Typography, Button, Input, Tooltip, Autocomplete, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import "./ElectronicCreditNote.sass";
// import "./Home-styles.css";
import "./ElectronicCreditNote-styles.css"
import { TableData } from "@/components/common/Table/Table";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Modal from '@mui/material/Modal';
import SearchIcon from '@mui/icons-material/Search';
import { DataProductosQ } from './Modal/DataProductos';
import { DataProvider } from './Modal/DataProvider';
import { DataInforme } from './Modal/DataInforme';



interface Column2 {
   id: 'documento' | 'accion' | 'folio' | 'fecha' | 'monto';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}
const columns2: readonly Column2[] = [
   { id: 'documento', label: 'Documento', minWidth: 10 },
   { id: 'accion', label: 'Accion', minWidth: 50 },
   { id: 'folio', label: 'Folio', minWidth: 5 },
   { id: 'fecha', label: 'Fecha', minWidth: 10 },
   { id: 'monto', label: 'Monto', minWidth: 10 }
];

interface Data {
   documento: string;
   accion: string;
   folio: string;
   fecha: string;
   monto: string;
}

function createData(
   documento: string,
   accion: string,
   folio: string,
   fecha: string,
   monto: string,
): Data {
   return { documento, accion, folio, fecha, monto };
}

const rowsData = [
   createData('1122', 'accion', '98djslsa-k', '21-02-2022', '8000'),
   createData('2122', 'accion', '98djslsa-k', '21-02-2022', '8000'),
   createData('3122', 'accion', '98djslsa-k', '21-02-2022', '8000'),
   createData('4122', 'accion', '98djslsa-k', '21-02-2022', '8000'),
]
export const ElectronicCreditNote: FunctionComponent = (props: any) => {
   const [value, setValue] = useState('');

   const [selectTypeResume, setSelectTypeResume] = useState('');
   const [data, setData] = React.useState<Date | null>(new Date());


   const [valueCalendar, setValueCalendar] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   //modal Rit
   const [openRut, setOpenRut] = React.useState(false);
   const handleOpenRut = () => setOpenRut(true);
   const handleCloseRut = () => setOpenRut(false);

   const [cambiarEstado, setCambiarEstado] = useState(false);
   const handleCambiarEstado = () => setCambiarEstado(true);

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 870,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 4,
      borderRadius: '20px'
   };

   const handleChange = (event: any) => {
      setValue(event.target.value);
   };
   const handleChangeTypeResume = (event: SelectChangeEvent) => {
      setSelectTypeResume(event.target.value);
   };

   const [page, setPage] = React.useState(0);
   const [rowsPerPage, setRowsPerPage] = React.useState(10);

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const direccion = [
      { label: 'CASA MATRIZ: Dirección Cliente - Comuna Cliente - Ciudad Cliente' }
   ]


   return (
      <Protected>
         <div className='Generator' >
            <Grid item xs={12} m={1} style={{ minHeight: '100vh' }}>
               <Grid item xs={12} md={12} >
                  <Box>
                     <Typography variant='h6' component='h1' sx={{ textTransform: 'uppercase' }} gutterBottom className="tittle">
                        NOTA DE CRÉDITO ELECTRÓNICA
                     </Typography>
                  </Box>
                  <Box>
                     <Typography variant='subtitle2' gutterBottom >
                        Para continuar con la factura debe seleccionar al cliente
                     </Typography>
                  </Box>

               </Grid>
               <Grid item container xs={12} mb={2} sx={{ alignItems: 'center' }}>
                  <Grid item xs={4}>
                     <FormControl fullWidth sx={{ minWidth: 200 }}>
                        <TextField
                           id="input-with-sx"
                           // label="Folio Doc"
                           onChange={handleChange}
                           placeholder="Cargando..."
                           size="small"
                        />
                     </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                     <Tooltip title="Buscar Empresa">
                        <IconButton onClick={handleOpenRut} className='btnIcoE'><SearchIcon /></IconButton>
                     </Tooltip>

                  </Grid>
                  <Grid item container
                     xs={4}
                     justifyContent='right'
                  >
                     <Grid item>
                        <Button
                           type='submit'
                           variant='contained'
                           onClick={handleCambiarEstado}
                           // disabled={loading}
                           disableElevation
                           className='btn_save'
                        >
                           Seleccionar
                        </Button>

                     </Grid>
                  </Grid>
               </Grid>
               {cambiarEstado &&
                  <Grid className='Generator' container mt={5} spacing={2}>
                     <Grid item xs={12} mt={1}>
                        <Grid>
                           <Grid item xs={12} md={9} >
                              <Box>
                                 <Typography variant='h6' component='h1' gutterBottom className="tittle">
                                    Referenciar Documento Nota de Crédito
                                 </Typography>
                              </Box>
                           </Grid>
                        </Grid>

                        <Grid container spacing={2} mt={1} className='margin'>
                           <Grid item xs={12} md={12} >
                              <Box>
                                 <Typography variant='subtitle2' gutterBottom >
                                    DATOS DEL RECEPTOR
                                 </Typography>
                              </Box>
                           </Grid>
                           <Grid item xs={12} sm={3}>
                              <FormControl fullWidth sx={{ minWidth: 200 }}>
                                 <TextField
                                    id="input-with-sx"
                                    label="Rut CLiente"
                                    onChange={handleChange}
                                    value="10366537-K"
                                    // placeholder="Cargando..."
                                    size="small"
                                 />
                              </FormControl>
                           </Grid>
                           <Grid item xs={12} sm={3}>
                              <FormControl fullWidth sx={{ minWidth: 200 }}>
                                 <TextField
                                    id="input-with-sx"
                                    label="Razón Social"
                                    onChange={handleChange}
                                    value="Angelo Mauricio Cabello Saez"
                                    size="small"
                                 />
                              </FormControl>
                           </Grid>
                           <Grid item xs={12} sm={3}>
                              <FormControl fullWidth sx={{ minWidth: 200 }}>
                                 <TextField
                                    id="input-with-sx"
                                    label="Giros de Actividad"
                                    onChange={handleChange}
                                    value="Persona Natural"
                                    size="small"
                                 />
                              </FormControl>
                           </Grid>
                           <Grid item xs={12} sm={3}>
                              <FormControl fullWidth sx={{ minWidth: 200 }}>
                                 <TextField
                                    id="input-with-sx"
                                    label="Telefono"
                                    size="small"
                                    onChange={handleChange}
                                    // placeholder="Cargando..."
                                    value="99999986"
                                 />
                              </FormControl>
                           </Grid>
                           <Grid item xs={12} sm={4}>
                              <FormControl fullWidth sx={{ minWidth: 200 }}>
                                 <TextField
                                    id="input-with-sx"
                                    label="Direccion"
                                    size="small"
                                    onChange={handleChange}
                                    // placeholder="Cargando..."
                                    value="Carmen Sylva 2 - Providencia - Santiago, ahí"
                                 />
                              </FormControl>
                           </Grid>

                           <Grid item xs={12} md={12} >
                              <Box>
                                 <Typography variant='subtitle2' gutterBottom >
                                    REFERENCIAR DOCUMENTO
                                 </Typography>
                              </Box>
                           </Grid>
                           <Grid item xs={12} sm={4}>
                              <FormControl fullWidth sx={{ minWidth: 200 }}>
                                 <Autocomplete
                                    disablePortal
                                    size="small"
                                    // id="combo-box-demo"
                                    options={direccion}
                                    renderInput={(params) => <TextField {...params} label="Documento Referencia" />}
                                 />
                              </FormControl>
                           </Grid>
                           <Grid item xs={12} sm={4}>
                              <FormControl fullWidth sx={{ minWidth: 200 }}>
                                 <TextField
                                    id="input-with-sx"
                                    label="Razón Referencia"
                                    size="small"
                                    onChange={handleChange}
                                 // placeholder="Cargando..."                                 
                                 />
                              </FormControl>
                           </Grid>
                           <Grid item xs={1}>
                              <Tooltip title="Buscar Razón">
                                 <IconButton className='btnIcoE'><SearchIcon /></IconButton>
                              </Tooltip>

                           </Grid>
                           <Grid item xs={12} sm={4}>
                              <FormControl fullWidth sx={{ minWidth: 200 }}>
                                 <Autocomplete
                                    disablePortal
                                    size="small"
                                    // id="combo-box-demo"
                                    options={direccion}
                                    renderInput={(params) => <TextField {...params} label="Razón de Referencia" />}
                                 />
                              </FormControl>
                           </Grid>
                           <Grid item xs={12} sm={3}>
                              <FormControl fullWidth sx={{ minWidth: 200 }}>
                                 <TextField
                                    id="input-with-sx"
                                    label="Folio(opcional)"
                                    size="small"
                                    onChange={handleChange}
                                 // placeholder="Cargando..."                          
                                 />
                              </FormControl>
                           </Grid>
                        </Grid>
                     </Grid>
                     <Grid item xs={12} sm={12} mt={1}>
                        <div className='bg-tittle3'>
                           <Typography
                              mt={1}
                              variant="h6"
                              align='center'
                              className='tittle-text'
                           >	Resultado de Búsqueda
                           </Typography>
                        </div>
                        <Grid item xs={12} >
                           <Box mt={1} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                              <FormControl fullWidth >
                                 <Paper sx={{ width: '100%', overflow: 'hidden' }} >

                                    <TableContainer sx={{ maxHeight: 440 }}>
                                       <Table stickyHeader aria-label="sticky table">
                                          <TableHead>
                                             <TableRow>
                                                {columns2.map((column) => (
                                                   <TableCell
                                                      key={column.id}
                                                      align={column.align}
                                                      style={{ minWidth: column.minWidth }}
                                                   >
                                                      {column.label}
                                                   </TableCell>
                                                ))}
                                             </TableRow>
                                          </TableHead>
                                          <TableBody>
                                             {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((rows) => {
                                                   return (
                                                      <TableRow hover role="checkbox" tabIndex={-1} key={rows.documento}>
                                                         {columns2.map((column) => {
                                                            const value = rows[column.id];
                                                            return (
                                                               <TableCell key={column.id} align={column.align}>
                                                                  {column.format && typeof value === 'number'
                                                                     ? column.format(value)
                                                                     : value}
                                                               </TableCell>

                                                            );
                                                         })}
                                                      </TableRow>
                                                   );
                                                })}
                                          </TableBody>
                                       </Table>
                                    </TableContainer>
                                    <TablePagination
                                       rowsPerPageOptions={[10, 25, 100]}
                                       component="div"
                                       count={rowsData.length}
                                       rowsPerPage={rowsPerPage}
                                       page={page}
                                       onPageChange={handleChangePage}
                                       onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                 </Paper>
                              </FormControl>
                           </Box>
                        </Grid>
                     </Grid>
                  </Grid>
               }
               <Modal
                  open={openRut}
                  onClose={handleCloseRut}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <DataProvider />
                  </Box>
               </Modal>              
            </Grid>
         </div>
      </Protected >
   )
}

