import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, } from '@mui/material';
import { ArrowBackIcon, ArrowDownIcon, ArrowRightIcon, MoreIcon } from "@toolbox/constants/icons";
import { Props } from './FormInformation.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import './style.sass'
import "./FormInformation_style.css";
import BadgeIcon from '@mui/icons-material/Badge';

export const FormInformation: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false)
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
    );
   const [estado, setEstado] = useState<any>({ rut: false, nombre_rz: false })

   const [valueInitialSituation, setValueInitialSituation] = React.useState('Informacion de Compra');

    const handleInitialSituation = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueInitialSituation((event.target as HTMLInputElement).value);
    };
    
   const afpValue = [
      { label: 'Capital'},
      { label: 'Cuprum'},
      { label: 'Empart'},
      { label: 'Modelo'},
      { label: 'Habitat'},
   ]
   const saludValue = [
      { label: 'Alemana Salud S.A.'},
      { label: 'Banmedica S.A.'},
      { label: 'Chuchikamata S.A.'},
      { label: 'Colmena S.A.'},
   ]
   const institucionApv = [
      { label: 'ABN AMRO (CHILE) SEGUROS DE VIDA S.A.'},
      { label: 'ACE SEGUROS S.A.'},
      { label: 'ADMINISTRADORA GENERAL DE FONDOS SECURITY S.A.'},
      { label: 'AGENCIA DE VALORES SURA S.A.'}
   ]
   const rebajaImpuesto = [
      { label: 'NO' },
      { label: 'SI' },
   ]
   const tipoValue = [
      { label: 'Pesos' },
      { label: 'Porcentaje' },
      { label: 'UF' },
   ]
   const tipoContratoSalud = [
      { label: 'Pesos Fijo' },
      { label: 'Porcentaje FIjo' },
      { label: 'U.F. Variable' },
   ]
   const pagoApvi = [
      { label: 'Directa' },
      { label: 'Indirecta' }
   ]   
   const rules = useMemo(() => ({
      unidadMedida: [
         VALIDATORS.REQUIRED,
      ],
      stockMinimo: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      stockMaximo: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      ubicacionFisica: [
         VALIDATORS.REQUIRED,
      ],

   }), []);
   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'unidadMedida':
            props.setvalueInformation(prev => ({ ...prev, unidadMedida: value }));
            break;
         case 'stockMinimo':
            props.setvalueInformation(prev => ({ ...prev, stockMinimo: value }))
            break;
         case 'compra_activo':
            props.setvalueInformation(prev => ({ ...prev, compra_activo: value }))
            break;
         case 'revalorizacion':
            props.setvalueInformation(prev => ({ ...prev, revalorizacion: value }));
            break;
         case 'depreciacion':
            props.setvalueInformation(prev => ({ ...prev, depreciacion: value }));
            break;
         case 'readecuaciones':
            props.setvalueInformation(prev => ({ ...prev, readecuaciones: value }));
            break;
         case 'monto_credito':
            props.setvalueInformation(prev => ({ ...prev, monto_credito: value }));
            break;
         case 'credito_adquisicion':
            props.setvalueInformation(prev => ({ ...prev, credito_adquisicion: value }));
            break;
         case 'revalorizable':
            props.setvalueInformation(prev => ({ ...prev, revalorizable: value }));
            break;
         default:
            break;
      }
   };

   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      props.setvalueInformation(prev => ({ ...prev, [name]: newVal }));
   }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      props.handleNext();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }

   return (
      <Container maxWidth="xl">
         <form >
            <Grid container spacing={3} className={`e-mb-lg`} mt={2}>
                  
               <Grid item xs={12} sm={3} >
                  <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={afpValue}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="A.F.P." />}
                     />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={saludValue}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Salud" />}
                     />
               </Grid>
               <Grid item xs={12} sm={6}>
                  <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={institucionApv}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Intitución APV" />}
                     />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={rebajaImpuesto}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Rebaja Impuesto" />}
                     />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={tipoValue}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Tipo" />}
                     />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="stockMinimo"
                     type='text'
                     placeholder="A.P.V. A.F.P."
                     value={props.valueInformation.stockMinimo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.stockMinimo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <FormControl >
                     <FormControlLabel control={<Checkbox />} label=" Libro Rem. Elect." />                     
                  </FormControl>
               </Grid> 
               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="stockMinimo"
                     type='text'
                     placeholder="N° Contrato"
                     value={props.valueInformation.stockMinimo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.stockMinimo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={tipoContratoSalud}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Tipo Contrato Salud" />}
                     />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="stockMinimo"
                     type='text'
                     placeholder="Pactado % Cot. Salud"
                     value={props.valueInformation.stockMinimo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.stockMinimo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="stockMinimo"
                     type='text'
                     placeholder="Pactado $ Cot. Salud"
                     value={props.valueInformation.stockMinimo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.stockMinimo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="stockMinimo"
                     type='text'
                     placeholder="Pactado Uf Cot. Salud"
                     value={props.valueInformation.stockMinimo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.stockMinimo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={pagoApvi}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Forma de Pago APVI" />}
                     />
               </Grid>

             

               <Grid item container
                  xs={12}
                  justifyContent='right'
               >
                  <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                        className='btn_save'
                        endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>Siguiente</span>
                        }
                     </Button>

                  </Grid>
               </Grid>
            </Grid>
         </form>
      </Container>
   );
}
