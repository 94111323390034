import React, { useState, useEffect } from 'react';
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Protected } from "@/components/layout/Protected";
import { FormControl, Grid, Stack, Typography,Button, Input } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { createTheme } from '@mui/material/styles';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import InputLabel from '@mui/material/InputLabel';
// import "./Home.sass";
import "./BuyRequest-styles.css"
import {TableData} from "@/components/common/Table/Table";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';

import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';


export const BuyRequest: FunctionComponent = (props: any) => {
   const [value, setValue] = useState('');
   const [select, setSelect] = useState('');
   const [data, setData] = React.useState<Date | null>(new Date());
   const [open, setOpen] = React.useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);
   

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 350,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 4,
      borderRadius:'20px'
    };

   const handleChange = (event: any) => {
      setValue(event.target.value);
   };

   const handleChangeSelec = (event: SelectChangeEvent) => {
      setSelect(event.target.value);
   };
 
   interface Column {
      id: 'Codigo' | 'Descripcion';
      label: string;
      minWidth?: number;
      align?: 'right';
      format?: (value: number) => string;
    }

    const columns: Column[] = [
      { id: 'Codigo', label: 'Codigo', minWidth: 10 },
      { id: 'Descripcion', label: 'Descripcion', minWidth: 100 },
    ];
    
    interface Data {
      Codigo: string;
      Descripcion: string;
    }
    
    function createData(
      Codigo: string,
      Descripcion: string,
    ): Data {
      return { Codigo, Descripcion};
    }
    const rows =[
         createData('A-0001', 'producto1'),
         createData('A-0001', 'producto2'),
         createData('A-0001', 'producto3'),
         createData('A-0001', 'producto4'),
         createData('A-0001', 'producto5'),
         createData('A-0001', 'producto6'),
         createData('A-0001', 'producto7'),
      ]
        const [page, setPage] = React.useState(0);
        const [rowsPerPage, setRowsPerPage] = React.useState(10);
      
        const handleChangePage = (event: unknown, newPage: number) => {
          setPage(newPage);
        };
      
        const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
          setRowsPerPage(+event.target.value);
          setPage(0);
        };
   return (
      <Protected>
         <div className='Generator'>
         <Grid item xs={12} m={1}>
            <Typography
            variant="h6"
            className='tittle'
            sx={{ textTransform: 'uppercase'}}
            >Crear Solicitud de Compra</Typography>

            <Grid className='Generator'  container spacing={2}>
               
               <Grid item xs={12} sm={8} mt={1}>
                     <div className='bg-tittle'>
                        <Typography 
                        mt={1} 
                        variant="h6"
                        align='center'
                        className='tittle-text'
                        >Información General de la Compra
                        </Typography>
                     </div>

                     <Grid container spacing={2} mt={1} className='margin'>

                        <Grid item xs={12} sm={3}>
                           <FormControl fullWidth sx={{ minWidth: 200 }}>
                              <InputLabel id="demo-simple-select-helper-label">Solicitado Por</InputLabel>
                              <Select
                                 labelId="demo-simple-select-helper-label"
                                 id="demo-simple-select-helper"
                                 value={select}
                                 label="Solicitado Por"
                                 onChange={handleChange}

      >                           <MenuItem value={1}>Data1</MenuItem>
                                 <MenuItem value={2}>Data2</MenuItem>
                                 <MenuItem value={3}>Data3</MenuItem>
                              </Select>
                           </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                           <FormControl fullWidth sx={{minWidth: 200 }}>
                              <InputLabel id="demo-simple-select-helper-label">Centro Beneficio</InputLabel>
                                 <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={select}
                                    label="Age"
                                    onChange={handleChange}
                                 >
                                    <MenuItem value="Centro Beneficio">
                                       <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={1}>Data1</MenuItem>
                                    <MenuItem value={2}>Data2</MenuItem>
                                    <MenuItem value={3}>Data3</MenuItem>
                                 </Select>
                           </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                           <FormControl fullWidth sx={{ minWidth: 200 }}>
                              <InputLabel id="demo-simple-select-helper-label">Centro Costo</InputLabel>
                                 <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={select}
                                    label="Age"
                                    onChange={handleChange}
                                 >
                                    <MenuItem value="Centro Costo">
                                       <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={1}>Data1</MenuItem>
                                    <MenuItem value={2}>Data2</MenuItem>
                                    <MenuItem value={3}>Data3</MenuItem>
                                 </Select>
                           </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                           <FormControl fullWidth sx={{  minWidth: 200 }}>
                              <InputLabel id="demo-simple-select-helper-label">Prioridad</InputLabel>
                                 <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={select}
                                    label="Age"
                                    onChange={handleChange}
                                 >
                                    <MenuItem value="Prioridad">
                                       <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={1}>Data1</MenuItem>
                                    <MenuItem value={2}>Data2</MenuItem>
                                    <MenuItem value={3}>Data3</MenuItem>
                                 </Select>
                           </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                           <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                              <FormControl fullWidth >
                                 <TextField 
                                 id="input-wit" 
                                 label="Usuario Creación"
                                 onChange={handleChange}
                                 defaultValue="Demo ERP"
                                 />
                              </FormControl>
                           </Box>
                        </Grid>

                        <Grid item xs={12} sm={8}>
                           <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                              <FormControl fullWidth >
                                 <TextField 
                                 id="input-with-sx" 
                                 label="Descripción Corta"
                                 onChange={handleChange}
                                 defaultValue="..."
                                 />
                              </FormControl>
                           </Box>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                           <LocalizationProvider dateAdapter={AdapterDateFns} color="primary" focused>
                              <Stack>
                                 <DatePicker
                                    openTo="year"
                                    views={['year', 'month', 'day']}
                                    label="Fecha de Solicitud"
                                    value={data}
                                    onChange={(newValue) => {
                                       setData(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} helperText={null} />}
                                 />
                              </Stack>
                           </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                           <LocalizationProvider dateAdapter={AdapterDateFns} color="primary" focused>
                              <Stack spacing={3}>
                                 <DatePicker
                                    openTo="year"
                                    views={['year', 'month', 'day']}
                                    label="Fecha de Entrega"
                                    value={data}
                                    onChange={(newValue) => {
                                       setData(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} helperText={null} />}
                                 />
                              </Stack>
                           </LocalizationProvider>
                        </Grid>

                        <Grid item xs={6} sm={3}>
                           <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                              <TextField 
                              id="input-with-sx" 
                              label="Status"
                              onChange={handleChange}
                              defaultValue="Activo"
                              />
                           </Box>
                        </Grid>

                        <Grid item xs={6} sm={3}>
                           <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                              <FormControlLabel 
                              control={<Checkbox defaultChecked onChange={handleChange} />} 
                              label="Generico" />
                           </Box>
                        </Grid>


                        <Grid item xs={12} sm={12}>
                           <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                              <FormControl fullWidth >
                                 <TextField 
                                 id="filled-multiline-static"
                                 label="Nota"
                                 multiline
                                 rows={2}
                                    />
                              </FormControl>
                           </Box>
                        </Grid>
                     </Grid>
               </Grid>
            
               <Grid item xs={12} sm={4} mt={1}>
                  <div className='bg-tittle2'>
                        <Typography 
                        mt={1} 
                        variant="h6"
                        align='center'
                        className='tittle-text2'
                        >Insertar Item
                        </Typography>
                  </div>
                  <Grid item xs={12} >
                     <Box mt={1} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <FormControl fullWidth >
                           <TextField
                              id="input-with-sx" 
                              onChange={handleChange}
                              defaultValue="Buscar"
                           />
                           <Paper  sx={{ width: '100%', overflow: 'hidden' }}>
                                 <TableContainer  sx={{ maxHeight: 280 }}>
                                 <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                       <TableRow>
                                       {columns.map((column) => (
                                          <TableCell
                                             key={column.id}
                                             align={column.align}
                                             style={{ minWidth: column.minWidth }}
                                          >
                                             {column.label}
                                          </TableCell>
                                       ))}
                                       <TableCell>Cantidad</TableCell>
                                       <TableCell>Opciones</TableCell>
                                       </TableRow>
                                    </TableHead>
         
                                    <TableBody>
                                       {rows
                                       .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                       .map((rows) => {
                                          return (
                                             <TableRow hover role="checkbox" tabIndex={-1} key={rows.Descripcion}>
                                             {columns.map((column) => {
                                                const value = rows[column.id];
                                                return (
                                                   <TableCell  key={column.id} align={column.align}>
                                                   {column.format && typeof value === 'number'
                                                      ? column.format(value)
                                                      : value}
                                                   </TableCell>
                                                );
                                             })}
                                                   <TableCell>
                                                      <TextField type='number' defaultValue={1}  size='small'/>
                                                   </TableCell>
                                                   <TableCell>
                                                   <div className='sepaEle'>
                                                      <button onClick={handleOpen} className='btnIcoE'><SearchIcon /></button>
                                                      <button className='btnIcoD'><DownloadIcon /></button>
                                                   </div>
                                                   </TableCell>
                                             </TableRow>
                                          );
                                       })}
                                    </TableBody>
                                 </Table>
                                 </TableContainer>
                                 
                           </Paper>
                        </FormControl>
                     </Box>
                  </Grid>
               </Grid>
               
               <Grid item xs={12}>
               <div className='bg-tittle3'>
                        <Typography 
                        mt={2} 
                        variant="h6"
                        align='center'
                        className='tittle-text'
                        >Productos Solicitados
                        </Typography>
                     </div>
                  <Grid item xs={12}>
                     <Box mt={1} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                              <FormControl fullWidth >
                                 <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                       <TableContainer sx={{ maxHeight: 540 }}>
                                       <Table stickyHeader aria-label="sticky table">
                                          <TableHead>
                                             <TableRow>
                                                <TableCell>N</TableCell>
                                                <TableCell>Codigo</TableCell>
                                                <TableCell>Detalle/Descripcion</TableCell>
                                                <TableCell>Cantidad</TableCell>
                                                <TableCell>Observacion</TableCell>
                                                <TableCell>Opciones</TableCell>
                                             </TableRow>
                                          </TableHead>
               
                                          <TableBody>
                                             <TableRow >  
                                                <TableCell>
                                                   1
                                                </TableCell>

                                                <TableCell>
                                                   A-0021
                                                </TableCell>
                                                         
                                                <TableCell>
                                                   Lorem ipsum dolor sit amet consectetur adipisicing elit
                                                </TableCell>

                                                <TableCell>
                                                   14
                                                </TableCell>

                                                <TableCell>
                                                   <Input/>
                                                </TableCell>
  
                                                <TableCell>
                                                   <div className='sepaEle'>
                                                      <button className='btnIcoE'><EditIcon/></button>
                                                      <button className='btnIcoD'><DeleteIcon /></button>
                                                   </div>
                                                </TableCell>
                                             </TableRow>
                                              
                                          </TableBody>
                                       </Table>
                                       </TableContainer>
                                       
                                 </Paper>
                              </FormControl>
                     </Box>
                  </Grid>

                  <Grid item xs={12} className='start'>
                     <Button variant='contained' className='btnSend'>Crear Solicitud de Pedido</Button>
                  </Grid>
               </Grid>
            </Grid>
            <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style} >
               <Typography id="modal-modal-title" variant="h6" sx={{fontWeight:'bold'}} component="h2">
                  Detalle del Producto
               </Typography>
               <Typography id="modal-modal-description" variant='subtitle2' sx={{ mt: 1 }}>
                  Codigo: A-0021
               </Typography>
               <Typography id="modal-modal-description" variant='subtitle2' sx={{ mt: 1 }}>
                  Barra: |||||||
               </Typography>
               <Typography id="modal-modal-description" variant='subtitle2' sx={{ mt: 1 }}>
                  Codigo Proveedor: P-0021
               </Typography>
               <Typography id="modal-modal-description" variant='subtitle2' sx={{ mt: 1 }}>
                  Precio: $1.000
               </Typography>
               <Typography id="modal-modal-description" variant='subtitle2' sx={{ mt: 1 }}>
                  Stock: 50
               </Typography>
                  
            </Box>
            </Modal>
         </Grid>
         </div>
      </Protected>
   )
}

