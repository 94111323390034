import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "../ConsolidatedPurchases"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import '../ConsolidatedPurchases_styles.css'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import { ModalInforms } from '../Modal/ModalInforms';
import Modal from '@mui/material/Modal';


interface Column {
   id: 'centro' | 'compraNeta' | 'porcentaje';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: string) => string;
 }

const columns:readonly Column[] = [
   { id: 'centro', label: 'Centro de Costo', minWidth: 100 },
   { id: 'compraNeta', label: 'Compra Neta', minWidth: 50 },
   { id: 'porcentaje', label: '%', minWidth: 50 },
];


interface Data {
   centro: string;
   compraNeta: string;
   porcentaje: string;
}


function createData(
   centro: string,
   compraNeta: string,
   porcentaje: string,
   ):Data {
   return { centro, compraNeta, porcentaje };
}



const rowsData = [
   createData('Operaciones', '$1.000.000', '10%'),
   createData('Ventas', '$2.000.000', '20%'),
   createData('Insumos', '$3.000.000', '30%'),
   createData('Desarrollo', '$4.000.000', '40%'),
   createData('Sin asignar', '$5.000.000', '50%'),
   createData('Totales', '$5.000.000', '100%'),
]



export const DataAbsence:React.FC<Props> = (props: Props): JSX.Element => {
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value2, setValue2] = useState('');
   const [familyProduct, setFamilyProduct] = useState('');
   const [estado, setEstado]= useState('');
   const [value, setValue] = useState<Date | null>(new Date());
   const [loading, setLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false);

   const handleOpenModal = () => {
      setOpenModal(true);
   };
   const handleCloseModal = () => {
      setOpenModal(false);
   };

   function handleClick() {
     setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 1020,
      height:680,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };
    return (
        <Container maxWidth='xl'>

            <Grid container spacing={3}>
               <Grid item xs={12} md={6} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        CONSOLIDADOS POR CENTRO DE COSTO MENSUAL
                     </Typography>
                  </Box>
                  <Box>
                     <Typography variant='subtitle2'  gutterBottom >
                     Resumen de mes de 1 - 2022
                     </Typography>
                  </Box>
                  
               </Grid>
                     
               <Grid item xs={12} md={6} className="btn-download">
                  <Box pb={2}>
                  <LoadingButton
                     color="warning"
                     // onClick={handleOpenModal}
                     // loading={loading}
                     // loadingPosition="start"
                     // startIcon={<FileDownloadIcon />}
                     variant="contained"
                     className="btn-download2"
                     >
                     EXPORTAR
                     </LoadingButton>
                  </Box>
               </Grid>
            </Grid>

           <Grid container spacing={3}>
               <Grid item xs={12} md={8} >
                  <Paper sx={{ width: '100%', overflow: 'hidden'}}>
                     <TableContainer sx={{maxHeight:220}}>
                        <Table stickyHeader aria-label="sticky table">
                           <TableHead>
                              <TableRow>
                                 {/* {columns.map((column) => (
                                    <TableCell
                                       key={column.id}
                                       align={column.align}
                                       style={{minWidth: column.minWidth}}
                                       >
                                    </TableCell>
                                 ))} */}
                                 {columns.map((column) => (
                                    <TableCell
                                       key={column.id}
                                       align={column.align}
                                       style={{minWidth: column.minWidth}}
                                       >
                                       {column.label}
                                    </TableCell>
                                 ))}
                              </TableRow>
                           </TableHead>
                           <TableBody>
                              {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                 return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.centro}>
                                       {columns.map((column) => {
                                          const value = row[column.id];
                                          return (
                                             <TableCell key={column.id} align={column.align}>
                                                {column.format && typeof value === 'string'
                                                   ? column.format(value)
                                                   : value}
                                             </TableCell>
                                          );
                                       })}

                                    </TableRow>
                                 );
                              })}
                           
                           </TableBody>   
                        </Table>  
                     </TableContainer>
               
                  </Paper>
               </Grid>
            </Grid>
         </Container>
     
    );
}