import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, InputAdornment, FormControl, FormControlLabel, Checkbox, } from '@mui/material'
import { useState, useRef } from "react";
import { Props } from "../MonthAndPeriod.type";
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import NumbersIcon from '@mui/icons-material/Numbers';
import TableContainer from '@mui/material/TableContainer';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TableRow from '@mui/material/TableRow';
import '../MonthAndPeriod_style.css'
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { AddCircle } from "@mui/icons-material";
import AddCircleIcon from '@mui/icons-material/AddCircle';

interface Column {
   id: 'codigo' | 'fecha' | 'tipo' | 'folio' | 'rut' | 'razon' | 'valorTotal';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: 'codigo', label: 'Codigo', minWidth: 100 },
   { id: 'fecha', label: 'Fecha', minWidth: 100 },
   { id: 'tipo', label: 'Tipo', minWidth: 100 },
   { id: 'folio', label: 'Folio', minWidth: 100 },
   { id: 'rut', label: 'Rut', minWidth: 100 },
   { id: 'razon', label: 'Razon', minWidth: 100 },
   { id: 'valorTotal', label: 'Valor Total', minWidth: 100 }
];

interface Data {
   codigo: string;
   fecha: string;
   tipo: string;
   folio: string;
   rut: string;
   razon: string;
   valorTotal: string;
}

function createDataEnviador(
   codigo: string,
   fecha: string,
   tipo: string,
   folio: string,
   rut: string,
   razon: string,
   valorTotal: string
): Data {
   return { codigo, fecha, tipo, folio, rut, razon, valorTotal };
}

const rowsDataEnviador = [
   createDataEnviador('1', '2022-03-01', '33', '5042', '22222122-2', 'A.C. BADIACHILE', '$ 238.000'),
   createDataEnviador('2', '2022-03-01', '33', '5042', '222211122-2', 'A.C. BADIACHILE', '$ 238.000'),
   createDataEnviador('3', '2022-03-02', '33', '5042', '22222122-2', 'A.C. BADIACHILE', '$ 238.000'),
   createDataEnviador('4', '2022-02-26', '33', '5042', '22222122-2', 'A.C. BADIACHILE', '$ 111.000'),
   createDataEnviador('5', '2022-02-27', '33', '5042', '22222122-2', 'A.C. BADIACHILE', '$ 238.000'),
   createDataEnviador('6', '2022-02-25', '33', '5042', '22222122-2', 'A.C. BADIACHILE', '$ 768.000'),
]


export const DataModalSendMassive: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [ejecutivoComercial, setEjecutivoComercial] = useState('');
   const [estado, setEstado] = useState('');
   const inputRefs = useRef<Array<InputRef | null>>([]);


   const handleChangeEjecutivoComercial = (event: SelectChangeEvent) => {
      setEjecutivoComercial(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const comerciales = [
      { value: '1', label: 'Activo' },
      { value: '2', label: 'Desactivado' },
   ];
   const estados = [
      { value: '1', label: 'Activo' },
      { value: '2', label: 'Desactivado' },
   ]
   const bancos = [
      { label: 'Banco 1', value: 'Banco 1' },
      { label: 'Banco 2', value: 'Banco 2' },
      { label: 'Banco 3', value: 'Banco 3' },
      { label: 'Banco 4', value: 'Banco 4' },
   ]
   const tipoCuenta = [
      { label: 'Tipo Cuenta 1', value: 'tipoCuenta 1' },
      { label: 'Tipo Cuenta 2', value: 'tipoCuenta 2' },
      { label: 'Tipo Cuenta 3', value: 'tipoCuenta 3' },
      { label: 'Tipo Cuenta 4', value: 'tipoCuenta 4' },
   ]

   return (
      <Container maxWidth='xl'>
         <Grid container spacing={3} mt={2}>
            <Grid container mb={2}>
               <Grid item xs={10}>
                  <Typography variant="h6" id="modal-modal-title" className='tittle'>
                     Enviador Masivo
                  </Typography>
               </Grid>
               <Grid item xs={2} textAlign="end">
                  <AssignmentIcon style={{ color: '#FF9700' }} />
               </Grid>
            </Grid>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
               <TableContainer sx={{ maxHeight: 420 }}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>                     
                           <TableCell>N</TableCell>
                           <TableCell>Fecha</TableCell>
                           <TableCell>Tipo</TableCell>
                           <TableCell>Folio</TableCell>
                           <TableCell>Rut Cliente</TableCell>
                           <TableCell>Razón</TableCell>
                           <TableCell>Valor Total</TableCell>
                           <TableCell>
                              <FormControlLabel control={<Checkbox defaultChecked />} label="Todos" />
                           </TableCell>

                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsDataEnviador.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.codigo}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Todos" />
                                 </TableCell>
                              </TableRow>
                           );
                        })}
                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsDataEnviador.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>
            <Grid item container
               xs={12}
               justifyContent='center'
            >
               <Grid item>
                  <Button
                     type='submit'                  
                     variant='contained'
                     // onClick={handleSubmit}
                     disabled={loading}
                     disableElevation
                     className='btn_save'
                  >
                     Enviar
                  </Button>
               </Grid>
            </Grid>
         </Grid>




      </Container >
   );
};

