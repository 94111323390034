import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, } from '@mui/material'
import { useState } from "react";
import { Props } from "./SalesNoteBook.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './SalesNoteBook_styles.css'
import Modal from '@mui/material/Modal';
import { DataBook } from './TypeData/DataBook/DataBook'
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
// import { DataReport } from "./TypeData/DataReport/DataReport";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import { ModalInforms } from "./Modal/ModalInforms";
// import { DataExport } from "./TypeData/DataExport/DataExport";


interface Column {
   id: 'numero' | 'rut' | 'apellidoPaterno' | 'apellidoMaterno' | 'nombre';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: 'numero', label: 'N°', minWidth: 10 },
   { id: 'rut', label: 'Rut', minWidth: 10 },
   { id: 'apellidoPaterno', label: 'Apellido Paterno', minWidth: 10 },
   { id: 'apellidoMaterno', label: 'Apellido Materno', minWidth: 10 },
   { id: 'nombre', label: 'Nombre', minWidth: 10 },
];



export const SalesNoteBook: React.FC<Props> = (props: Props): JSX.Element => {
   const [value, setValue] = useState<Date | null>(new Date());
   const [openBook, setOpenBook] = useState(false);
   // const [openReport, setOpenReport] = useState(false);
   // const [openReportExport, setOpenReportExport] = useState(false);


   const handleOpenBook = () => {
      setOpenBook(true);
      // setOpenReport(false);
      // setOpenReportExport(false);
   }
   const handleCloseBook = () => setOpenBook(false);

   // const handleOpenReport = () => {
   //    setOpenReport(true);
   //    setOpenBook(false);
   //    setOpenReportExport(false);
   // }
   // const handleCloseReport = () => setOpenReport(false);

   // const handleOpenReportExport = () => {
   //    setOpenReportExport(true);
   //    setOpenBook(false);
   //    setOpenReport(false);
   // }
   // const handleCloseReportExport = () => setOpenReportExport(false);

   const periodo = [
      { value: 1, label: '2022' },
      { value: 2, label: '2021' },
      { value: 3, label: '2020' },
      { value: 4, label: '2019' },
      { value: 5, label: '2018' },
      { value: 6, label: '2017' },
      { value: 7, label: '2016' },
      { value: 8, label: '2015' }
   ]
   const mes = [
      { label: 'Enero' },
      { label: 'Febrero' },
      { label: 'Marzo' },
      { label: 'Abril' },
      { label: 'Mayo' },
      { label: 'Junio' },
      { label: 'Agosto' },
      { label: 'Setiembre' },
      { label: 'Octubre' },
      { label: 'Noviembre' },
      { label: 'Diciembre' },
      { label: 'TODOS' }
   ]
   const vendedor = [
      { value: 1, label: 'Vendedor 1' },
      { value: 2, label: 'Vendedor 2' },
      { value: 3, label: 'Vendedor 3' }
   ];
   const sucursal = [
      { value: 1, label: 'Sucursal 1' },
      { value: 2, label: 'Sucursal 2' },
      { value: 3, label: 'Sucursal 3' }
   ];
   const estado = [
      { value: 1, label: 'GENERADA' },
      { value: 2, label: 'GENERADA OT' },
      { value: 3, label: 'ANULADA' }
   ];
   const filtro = [
      { value: 1, label: 'Facturadas y Saldos' }
   ];

   return (
      <Protected>
         <Container maxWidth='xl'>
            <Grid container spacing={3} mb={6}>
               <Grid item xs={12} md={12} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        LIBROS NOTAS DE VENTA
                     </Typography>
                  </Box>
                  <Box>
                     <Typography variant="caption" id="modal-modal-title" mb={2}>
                        Para revisar sus libros Notas de venta, seleccione los siguientes datos
                     </Typography>
                  </Box>

               </Grid>
               <Grid item xs={12} sm={6}>
                  <Grid container spacing={3}>
                     <Grid item xs={12}>
                        <Typography variant='subtitle2' gutterBottom align="center" style={{ background: '#ff9700', color: '#ffffff', borderRadius: '5px' }} p={1}>
                           Libro de Notas de Venta
                        </Typography>
                     </Grid>

                     <Grid item xs={6} >
                        <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={periodo}
                           fullWidth
                           renderInput={(params) => <TextField {...params} label="Periodo" />}
                        />
                     </Grid>
                     <Grid item xs={6} >
                        <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={mes}
                           fullWidth
                           renderInput={(params) => <TextField {...params} label="Mes" />}
                        />
                     </Grid>                     

                     <Grid item textAlign={'center'} xs={12}>
                        <Button
                           type='submit'
                           variant='contained'
                           onClick={handleOpenBook}
                           // disabled={loading}
                           disableElevation
                           className='btn_save2'
                           style={{ backgroundColor: '#16556b', color: '#fff' }}
                        >
                           CONSULTAR
                        </Button>
                     </Grid>
                  </Grid>
               </Grid>               
            </Grid>

            {openBook && <DataBook entity={undefined} /> || ""}

            {/* {openReport && <DataReport entity={undefined} /> || ""}

            {openReportExport && <DataExport entity={undefined} />||""} */}

         </Container>


      </Protected>
   );
};

