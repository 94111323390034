import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, } from '@mui/material'
import { useState } from "react";
import { Props } from "../NoteSellExenta.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import '../NoteSellExenta-styles.css'
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { AddCircle } from "@mui/icons-material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BookIcon from '@mui/icons-material/Book';
import { DeleteIcon } from "@/toolbox/constants/icons";

interface Column {
   id: 'codigo' | 'detalle' | 'detalleLargo' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [

   { id: 'codigo', label: 'Código', minWidth: 10 },
   { id: 'detalle', label: 'Detalle Producto', minWidth: 100 },
   { id: 'detalleLargo', label: 'Detalle Largo', minWidth: 50 },
];

interface Data {
   codigo: string;
   detalle: string;
   detalleLargo: string;
}

function createData(
   codigo: string,
   detalle: string,
   detalleLargo: string,
): Data {
   return { codigo, detalle, detalleLargo };
}

const rowsData = [
   createData('1', 'Producto 1', 'Detalle Largo 1'),
   createData('2', 'Producto 2', 'Detalle Largo 2'),
   createData('3', 'Producto 3', 'Detalle Largo 3'),
   createData('4', 'Producto 4', 'Detalle Largo 4'),
]


export const DataAgregar: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [ejecutivoComercial, setEjecutivoComercial] = useState('');
   const [estado, setEstado] = useState('');


   const handleChangeEjecutivoComercial = (event: SelectChangeEvent) => {
      setEjecutivoComercial(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const estados=[
      {label:'No Adjudicada',value:'Enviado'},
      {label:'Adjudicada',value:'Adjudicada'},
      {label:'Anulada',value:'No Adjudicada'},
   ]
   return (
         <Container maxWidth='xl'>   
           <Grid item xs={12} md={12} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        AGREGAR DETALLE LARGO
                     </Typography>
                  </Box>

            </Grid>   

            <Grid container spacing={2} >
               <Grid item xs={12} md={6}>
                  <TextField
                     label="Codigo Producto"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>
               <Grid item xs={12} md={6}>
                  <TextField
                     label="Detalle Producto"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>
               <Grid item xs={12} md={12}>
                  <TextField
                     label="Descripcion Producto"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>
            </Grid>
            <Grid item container
                           xs={12}
                           mt={2}
                        justifyContent='left'
                        >
                              <Grid item>
                                 <Button
                                    type='submit'
                                    variant='contained'
                                    
                                    // onClick={handleSubmit}
                                    // disabled={loading}
                                    disableElevation
                                    // component={Link} to={ROUTE_EV_QUOTE_BOOK_RESUME}
                                    className='btn_next'
                                 >
                                          <span>Agregar</span>
                                 </Button>
                              </Grid>
               </Grid>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
               <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {columns.map((column) => (
                              <TableCell
                              
                                 key={column.id}
                                 align={column.align}
                                 style={{ minWidth: column.minWidth }}
                              >
                                 {column.label}
                              </TableCell>
                           ))}
                           <TableCell>
                              Opciones
                           </TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.codigo}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                    <IconButton>
                                       
                                          <EditIcon
                                          style={{ fontSize:'20px' }}
                                          // onClick={handleOpen}
                                       />
                                    </IconButton>
                                    <IconButton>
                                       
                                       <DeleteIcon
                                       style={{ fontSize:'20px' }}
                                       // onClick={handleOpen}
                                    />
                                 </IconButton>
                                 </TableCell>
                              </TableRow>
                           );
                        })}

                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>

         </Container>
   );
};

