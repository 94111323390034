import React, { useState } from "react";
// import { Protected } from "@/components/layout/Protected";
import {
   Button,
   Grid,
   IconButton,
   InputBase,
   Modal,
   Paper,
   Typography,
} from "@mui/material";
import { Props } from "../AllProducts.type";
import "../AllProducts_style.css";
import CloseIcon from "@mui/icons-material/Close";
import { FormControl } from "@mui/material";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import SearchIcon from "@mui/icons-material/Search";
import { ModalPruebados } from "./ModalPruebados";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import Box from "@mui/material/Box";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import LocationOnIcon from "@mui/icons-material/LocationOn";

export const ModalPruebatres: React.FC<Props> = (props: Props): JSX.Element => {
   const [open, setOpen] = useState(false);
   const handleClose = () => setOpen(false);
   const [openModalPruebados, setOpenModalPruebados] = React.useState(false);

   const handleOpenPruebados = () => setOpenModalPruebados(true);
   const handleClosePruebados = () => setOpenModalPruebados(false);

   const style = {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      height: "300px",
      transform: "translate(-50%, -50%)",
      width: "600px",
      borderRadius: "10px",
      bgcolor: "background.paper",
      boxShadow: "rgba(100,100,111,0.2) 0px 7px 29px 0px",
      p: 3,
      background: "#F5F5F5",
   };

   const direcciones = [
      { label: "Santiago", value: "Santiago" },
      { label: "Providencia", value: "Providencia" },
      { label: "Concepcion", value: "Concepcion" },
      { label: "Talca", value: "Talca" },
   ];

   return (
      <Grid container p={1} sx={style}>
         <Grid container maxWidth="xl" spacing={1}>
            <Grid item xs={12} className="registrar" mb={3}>
               <Typography
                  className="tittle-text3"
                  variant="body2"
                  gutterBottom
               >
                  REGISTRE DATOS DE CLIENTE
               </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
               <TextField
                  id="rut"
                  label="Rut Empresa"
                  type="text"
                  fullWidth
                  variant="outlined"
                  placeholder="72222252-2"
                  size="small"
               />
            </Grid>
            <Grid item xs={12} md={4}>
               <TextField
                  id="rut"
                  label="Razon Social"
                  type="text"
                  fullWidth
                  variant="outlined"
                  placeholder="Castillo Jara"
                  size="small"
               />
            </Grid>
            <Grid item xs={12} md={4}>
               <TextField
                  id="rut"
                  label="Fantasia"
                  type="text"
                  fullWidth
                  variant="outlined"
                  placeholder="Nombre Fantasia"
                  size="small"
               />
            </Grid>
            <Grid item xs={12} md={4}>
               <TextField
                  id="rut"
                  label="Telefono"
                  type="text"
                  fullWidth
                  variant="outlined"
                  placeholder="+1 1573256"
                  size="small"
               />
            </Grid>
            <Grid item xs={12} md={4}>
               <TextField
                  id="rut"
                  label="Giro"
                  type="text"
                  fullWidth
                  variant="outlined"
                  placeholder="giro cliente"
                  size="small"
               />
            </Grid>

            <Grid item xs={12} md={4}>
               <TextField
                  id="rut"
                  label="Email"
                  type="text"
                  fullWidth
                  variant="outlined"
                  defaultValue="jmora@test.cl"
                  size="small"
               />
            </Grid>

            <Grid item xs={12} md={8}>
               <Autocomplete
                  disablePortal
                  size="small"
                  id="combo-box-demo"
                  options={direcciones}
                  // sx={{ bgcolor: '#F3F3F3'}}
                  renderInput={(params) => (
                     <TextField {...params} label="Dirección" />
                  )}
               />
            </Grid>

            <Grid item xs={12} md={4} mb={5}>
               <TextField
                  id="rut"
                  label="Observación"
                  type="text"
                  fullWidth
                  multiline
                  //rows={4}
                  // margin="normal"
                  variant="outlined"
                  size="small"
                  // defaultValue="jmora@test.cl"
               />
            </Grid>
         </Grid>
      </Grid>
   );
};
