import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "../ConsolidatedPurchases"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import '../ConsolidatedPurchases_styles.css'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';

interface Column {
   id: 'clasificacion' | 'enero' | 'febrero' | 'marzo'|'abril'| 'mayo'|'junio'|'julio'|'agosto'|'septiembre'|'octubre'|'noviembre'|'diciembre'|'total';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: string) => string;
 }

interface Column1 {
   id: 'clasificacion' | 'enero' | 'febrero' | 'marzo'|'abril'| 'mayo'|'junio'|'julio'|'agosto'|'septiembre'|'octubre'|'noviembre'|'diciembre'|'total';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: string) => string;
}


const columns:readonly Column[] = [
   { id: 'clasificacion', label: 'Clasificación', minWidth: 50 },
   { id: 'enero', label: 'Enero', minWidth: 10 },
   { id: 'febrero', label: 'Febrero', minWidth: 10 },
   { id: 'marzo', label: 'Marzo', minWidth: 10 },
   { id: 'abril', label: 'Abril', minWidth: 10 },
   { id: 'mayo', label: 'Mayo', minWidth: 10 },
   { id: 'junio', label: 'Junio', minWidth: 10 },
   { id: 'julio', label: 'Julio', minWidth: 10 },
   { id: 'agosto', label: 'Agosto', minWidth: 10 },
   { id: 'septiembre', label: 'Septiembre', minWidth: 10 },
   { id: 'octubre', label: 'Octubre', minWidth: 10 },
   { id: 'noviembre', label: 'Noviembre', minWidth: 10 },
   { id: 'diciembre', label: 'Diciembre', minWidth: 10 },
   { id: 'total', label: 'Total', minWidth: 10 },
   ];

interface Data {
   clasificacion: string;
   enero: string;
   febrero: string;
   marzo: string;
   abril: string;
   mayo: string;
   junio: string;
   julio: string;
   agosto: string;
   septiembre: string;
   octubre: string;
   noviembre: string;
   diciembre: string;
   total: string;
}


function createData(
   clasificacion: string,
   enero: string,
   febrero: string,
   marzo: string,
   abril: string,
   mayo: string,
   junio: string,
   julio: string,
   agosto: string,
   septiembre: string,
   octubre: string,
   noviembre: string,
   diciembre: string,
   total: string,
   ):Data {
   return { clasificacion, enero, febrero, marzo, abril, mayo, junio, julio, agosto, septiembre, octubre, noviembre, diciembre, total };
}



const rowsData = [
   createData('Gastos de Luz', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0'),
   createData('Gastos de Agua', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0'),
   createData('Gastos de Alimentos', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0'),
   createData('Gastos de Transporte', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0'),
   createData('Gastos de Servicios', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0'),
   createData('Gastos de Comunicaciones', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0'),
   createData('Gastos de Otros', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0', '$0'),
]


export const DataTardie:React.FC<Props> = (props: Props): JSX.Element => {
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value2, setValue2] = useState('');
   const [familyProduct, setFamilyProduct] = useState('');
   const [estado, setEstado]= useState('');
   const [value, setValue] = useState<Date | null>(new Date());
   const [loading, setLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false);
   const handleOpenModal = () => setOpenModal(true);
   const handleCloseModal = () => setOpenModal(false);

   function handleClick() {
     setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };


    return (
        <Container maxWidth='xl'>

            <Grid container spacing={3}>
               <Grid item xs={12} md={6} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        COMPRAS CONSOLIDADAS POR CLASIFICACION 
                     </Typography>
                  </Box>
                  <Box>
                     <Typography variant='subtitle2'  gutterBottom >
                     Resumen de Enero a Diciembre 2022
                     </Typography>
                  </Box>
                  
               </Grid>
                     
               <Grid item xs={12} md={6} className="btn-download">
                  <Box pb={2}>
                  <LoadingButton
                     color="warning"
                     // onClick={handleClick}
                     // loading={loading}
                     // loadingPosition="start"
                     // startIcon={<FileDownloadIcon />}
                     variant="contained"
                     className="btn-download2"
                     >
                     Exportar en Excel
                     </LoadingButton>
                  </Box>
               </Grid>
            </Grid>

            
           

            <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:220}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                                 {column.label}
                              </TableCell>
                           ))}
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.clasificacion}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'string'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}

                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
              
            </Paper>

           
         
         </Container>
     
    );
}