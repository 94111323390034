import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, SelectChangeEvent, } from '@mui/material';
import { Props } from './FormDetall.type'
import { InputRef } from '@components/common/Input/InputInterfaces';
import './style.sass'
import "./FormDetall_style.css";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
// import '../Advances_styles.css'
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import IconButton from '@mui/material/IconButton';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export const FormDetall: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value2, setValue2] = useState('');
   const [loading, setLoading] = useState(false);
   const [familyProduct, setFamilyProduct] = useState('');
   const [estado, setEstado]= useState('');
   const [value, setValue] = useState<Date | null>(new Date());

    const handleChangeFamilyProduct = (event: SelectChangeEvent) => {
      setFamilyProduct(event.target.value);
    };
    const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
    };
   function handleClick() {
     setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      props.handleNext();
   }

   interface Column {
      id:  'tipo' | 'folio' | 'fecha'|'vence' | 'total' | 'abono' | 'monto' | 'gestion' | 'RI' ;
      label: string;
      minWidth?: number;
      align?: 'right';
      format?: (value: number) => string;
    }
   
    interface Column1 {
      id:  'numero' | 'tipo' | 'monto' ;
      label: string;
      minWidth?: number;
      align?: 'right';
      format?: (value: number) => string;
    }

   const columns:readonly Column[] = [
      { id: 'tipo', label: 'Tipo', minWidth: 100 },
      { id: 'folio', label: 'Folio', minWidth: 50 },
      { id: 'fecha', label: 'Fecha', minWidth: 100 },
      { id: 'vence', label: 'Vence', minWidth: 100 },
      { id: 'total', label: 'Total', minWidth: 100 },
      { id: 'abono', label: 'Abono', minWidth: 50 },
      { id: 'monto', label: 'Monto', minWidth: 50 },
      { id: 'gestion', label: 'Gestion', minWidth: 30 },
      { id: 'RI', label: 'R.I', minWidth: 30 },
      ];

   const columns1:readonly Column1[] = [
      { id: 'numero', label: 'Numero', minWidth: 50 },
      { id: 'tipo', label: 'Tipo', minWidth: 50 },
      { id: 'monto', label: 'Monto', minWidth: 60 },
      ];
   
   interface Data {
      tipo: string;
      folio: string;
      fecha: string;
      vence: string;
      total: string;
      abono: string;
      monto: string;
      gestion: string;
      RI: string;
   }
   
   interface Data1 {
      numero: string;
      tipo: string;
      monto: string;
   }

   function createData(
      tipo: string,
      folio: string,
      fecha: string,
      vence: string,
      total: string,
      abono: string,
      monto: string,
      gestion: string,
      RI: string,
      ):Data {
      return { tipo, folio, fecha, vence, total, abono, monto, gestion, RI };
   }
   
   function createData1(
      numero: string,
      tipo: string,
      monto: string,
      ):Data1 {
      return { numero, tipo, monto };
   }


   const rowsData = [
      createData('LIQUIDACION DE SUELDO', '10188', '12/12/2020', '12/12/2020', '$1,000.00', '$0.00', '$1,000.00', '', ''),
      createData('LIQUIDACION DE SUELDO', '10188', '12/12/2020', '12/12/2020', '$1,000.00', '$0.00', '$1,000.00', '', ''),
      createData('LIQUIDACION DE SUELDO', '10188', '12/12/2020', '12/12/2020', '$1,000.00', '$0.00', '$1,000.00', '', ''),
      createData('LIQUIDACION DE SUELDO', '10188', '12/12/2020', '12/12/2020', '$1,000.00', '$0.00', '$1,000.00', '', ''),
      createData('LIQUIDACION DE SUELDO', '10188', '12/12/2020', '12/12/2020', '$1,000.00', '$0.00', '$1,000.00', '', ''),
      createData('LIQUIDACION DE SUELDO', '10188', '12/12/2020', '12/12/2020', '$1,000.00', '$0.00', '$1,000.00', '', ''),
      createData('LIQUIDACION DE SUELDO', '10188', '12/12/2020', '12/12/2020', '$1,000.00', '$0.00', '$1,000.00', '', ''),
      createData('LIQUIDACION DE SUELDO', '10188', '12/12/2020', '12/12/2020', '$1,000.00', '$0.00', '$1,000.00', '', ''),
      createData('LIQUIDACION DE SUELDO', '10188', '12/12/2020', '12/12/2020', '$1,000.00', '$0.00', '$1,000.00', '', ''),
      createData('LIQUIDACION DE SUELDO', '10188', '12/12/2020', '12/12/2020', '$1,000.00', '$0.00', '$1,000.00', '', ''),
   ]

   const rowsData1 = [
      createData1('20188', '890', '$1,000.00'),
      createData1('20183', '890', '$5,000.00'),
   ]

   const formasPago=[
      { label:'Efctivo desde caja', value:'Efectivo'},
      { label:'Efectivo a deposito', value:'Transferencia'},
      { label:'Cheque', value:'Cheque'},
      { label:'Trasferencia Bancaria', value:'Trasferencia Bancaria'},
      { label:'Vale Vista', value:'Vale Vista'},
      { label:'Red Compra', value:'Red Compra'},
      { label:'Rendicion', value:'Rendicion'},
      { label:'Notas de Credito u otro', value:'Notas de Credito u otro'},
      { label:'======================='},
      { label:'Gestion de Pago', value:'Gestion de Pago'},

   ]
   
   return (
      <Container maxWidth="xl">

            <Grid container spacing={3} className={`e-mb-lg`} mb={3} mt={3}>

               <Grid item sm={12} md={6} >
                  <Grid container spacing={3}>
            
                     <Grid item xs={6} md={3} mb={-2}>
                        <Box>
                           <Typography variant='subtitle2'  gutterBottom>
                              PROVEEDOR
                           </Typography>
                        </Box>
                     </Grid>

                     <Grid item xs={6} md={9} mb={-2}>
                        <Box>
                           <Typography variant='caption'  gutterBottom>
                              (10121844-9)
                           </Typography>
                        </Box> 
                     </Grid>

                     <Grid item xs={6} md={3} mb={-2}>
                        <Box>
                           <Typography variant='subtitle2'  gutterBottom>
                           N º DOCUMENTOS IMPAGOS
                           </Typography>
                        </Box>
                     </Grid>

                     <Grid item xs={6} md={9} mb={-2}>
                        <Box>
                           <Typography variant='caption'  gutterBottom>
                                    14
                           </Typography>
                        </Box> 
                     </Grid>

                     <Grid item xs={6} md={3} mb={-2}>
                        <Box>
                           <Typography variant='subtitle2'  gutterBottom>
                              TOTAL
                           </Typography>
                        </Box>
                     </Grid>

                     <Grid item xs={6} md={9}  mb={-2}>
                        <Box>
                           <Typography variant='caption'  gutterBottom>
                           $ 7.801.573
                           </Typography>
                        </Box> 
                     </Grid>
                    </Grid> 
                  </Grid>


                  <Grid item xs={12}  md={6} mb={2} alignItems="right" >
                        <Button style={{backgroundColor:'#16556b', color:'#ffffff', justifyContent:"right"}} className="btn-download">
                           Exportar
                        </Button>

                        <Grid container spacing={3} mt={1}>
                           <Grid item xs={6} md={6} mb={-2}>
                              <Autocomplete
                              disablePortal
                              size='small'
                              id="combo-box-demo"
                              options={formasPago}
                              // sx={{ bgcolor: '#F3F3F3'}}
                              renderInput={(params) => <TextField {...params} label="Seleccione Forma de Pago" />}
                              />
                           </Grid>
                           <Grid item xs={6} md={6} mb={-2}>
                              <Button style={{backgroundColor:'#ff9700', color:'#ffffff'}} className="btn-download" onClick={handleSubmit}>
                              Cancelar Documentos
                              </Button>
                           </Grid>

                        </Grid>
                     
                  </Grid>

               <Grid item xs={12} md={3}   mb={-1}>

                     <div className='bg-tittle'>
                        <Typography
                           mt={3}
                           variant="h6"
                           align='center'
                           className='tittle-text'
                        >DOCUMENTOS
                        </Typography>
                     </div>

                     <Paper sx={{ width: '100%', overflow: 'hidden'}}>
                        <TableContainer sx={{maxHeight:450}}>
                           <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                 <TableRow>
                                    <TableCell></TableCell>
                                    {columns1.map((column) => (
                                       <TableCell
                                          key={column.id}
                                          align={column.align}
                                          style={{minWidth: column.minWidth}}
                                          >
                                          {column.label}
                                       </TableCell>
                                    ))}
                                 </TableRow>
                              </TableHead>
                              <TableBody>
                                 {rowsData1.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    return (
                                       <TableRow hover role="checkbox" tabIndex={-1} key={row.numero}>
                                            <TableCell>
                                             <IconButton aria-label="delete" >
                                                <DoDisturbOnIcon style={{color:'red'}} />
                                             </IconButton>
                                          </TableCell>
                                          {columns1.map((column) => {
                                             const value = row[column.id];
                                             return (
                                                <TableCell key={column.id} align={column.align}>
                                                   {column.format && typeof value === 'number'
                                                      ? column.format(value)
                                                      : value}
                                                </TableCell>
                                             );
                                          })}
                                        
                                       </TableRow>
                                    );
                                 })}
                              
                              </TableBody>   
                           </Table>  
                        </TableContainer>
                     </Paper>
                     <Grid item xs={12}  md={12} mt={1}>
                        <Button style={{backgroundColor:'red', color:'white'}} className="btn_deleted"   fullWidth>
                        Borrar todo
                        </Button>
                     </Grid>
                     <Grid item xs={6} md={9}  mb={1}>
                        <Box>
                           <Typography variant='subtitle2'  gutterBottom>
                              Total: $1.069.131
                           </Typography>
                        </Box> 
                     </Grid>
                  </Grid>

               <Grid item xs={12} md={9}   mb={-1}>

                  <div className='bg-tittle'>
                     <Typography
                        mt={3}
                        variant="h6"
                        align='center'
                        className='tittle-text'
                     >LISTADO DE DOCUMENTOS IMPAGOS
                     </Typography>
                  </div>
            
                  <Paper sx={{ width: '100%', overflow: 'hidden'}}>
                     <TableContainer sx={{maxHeight:550}}>
                        <Table stickyHeader aria-label="sticky table">
                           <TableHead>
                              <TableRow>
                                 {columns.map((column) => (
                                    <TableCell
                                       key={column.id}
                                       align={column.align}
                                       style={{minWidth: column.minWidth}}
                                       >
                                       {column.label}
                                    </TableCell>
                                 ))}
                                 <TableCell>
                                    Accion
                                 </TableCell>
                              </TableRow>
                           </TableHead>
                           <TableBody>
                              {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                 return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.tipo}>
                                       {columns.map((column) => {
                                          const value = row[column.id];
                                          return (
                                             <TableCell key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number'
                                                   ? column.format(value)
                                                   : value}
                                             </TableCell>
                                          );
                                       })}
                                       <TableCell>
                                          <IconButton aria-label="next" >
                                             <AddCircleIcon style={{color:'green'}} />
                                          </IconButton>
                                       </TableCell>
                                    </TableRow>
                                 );
                              })}
                           
                           </TableBody>   
                        </Table>  
                     </TableContainer>
                     <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rowsData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                  </Paper>
               </Grid>
            </Grid>
      </Container>
   );
}
