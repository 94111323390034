import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "./QuoteBook.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './QuoteBook_styles.css'
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import Modal from '@mui/material/Modal';
// import { DataModal } from "./Modal/DataEmail";
import { DataRut } from "./Modal/DataRut";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import SearchIcon from '@mui/icons-material/Search';
import { LoadingButton } from "@mui/lab";

interface Column {
   id: 'nombre' | 'mensual' | 'hoy'|'monto'|'numero'| 'montoMes' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

const columns:readonly Column[] = [
   { id: 'nombre', label: 'Nombre', minWidth: 100 },
   { id: 'mensual', label: 'Mensual', minWidth: 100 },
   { id: 'hoy', label: 'Hoy', minWidth: 100 },
   { id: 'monto', label: 'Monto/Mes', minWidth: 100 },
   { id: 'numero', label: 'N°', minWidth: 50 },
   { id: 'montoMes', label: 'Monto Mes', minWidth: 100 },
   ];

interface Data {
   nombre: string;
   mensual: string;
   hoy: string;
   monto: string;
   numero: string;
   montoMes: string;
}

function createData(
   nombre: string,
   mensual: string,
   hoy: string,
   monto: string,
   numero: string,
   montoMes: string,
   ):Data {
   return { nombre, mensual, hoy, monto, numero, montoMes };
}

const rowsData = [
   createData('DEMO ERP', '3', '0', '$ 28.157','1', '$ 28.157'),
];



export const TableResumen:React.FC<Props> = (props: Props): JSX.Element => {
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   // const [value, setValue] = useState('');

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };


    return (
        <Container maxWidth='xl'>
            <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:440}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>

                        <TableRow>
                           <TableCell rowSpan={2}>Nombre Ejecutivo</TableCell>
                           <TableCell align="center" colSpan={3}>Cotizaciones</TableCell>
                           <TableCell align="center" colSpan={2}>Notas de Venta</TableCell>
                        </TableRow >
                        <TableRow>
                           <TableCell>Mensual</TableCell>
                           <TableCell>Hoy</TableCell>
                           <TableCell>Monto/Mes</TableCell>
                           <TableCell>N°</TableCell>
                           <TableCell>Monto Mes</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.numero}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                
                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
               
            </Paper>

         </Container>
      
    );
   };

