import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, } from '@mui/material'
import { useState } from "react";
import { Props } from "./ElectronicSaleDocuments.type"
import Paper from '@mui/material/Paper';
import './ElectronicSaleDocuments_styles.css'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ElectronicDocument } from "./ElectronicDocument/ElectronicDocument";
import { ElectronicTicket } from "./ElectronicTicket/ElectronicTicket";
import { DocumentExport } from "./DocumentExport/DocumentExport";

export const ElectronicSaleDocuments: React.FC<Props> = (props: Props): JSX.Element => {

   const [expanded, setExpanded] = useState<string | false>('panel1');

   const handleChange =
      (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
         setExpanded(isExpanded ? panel : false);
      };

   const styleAcordion = {
      bgcolor: '#D2EDF8',
   };
   return (
      <Protected>
         <Container maxWidth='xl' sx={{ minHeight: '100vh' }}>
            <Grid container spacing={3}>
               <Grid item xs={12} md={12} >
                  {/* <Box> */}
                  <Typography variant='h6' gutterBottom className="tittle">
                     DOCUMENTOS DE VENTAS ELECTRÓNICOS
                  </Typography>
                  {/* </Box> */}
                  <Typography variant="subtitle1" display="block" gutterBottom mt={1}>
                     Seleccione el items a consultar
                  </Typography>
               </Grid>
            </Grid>

            <Grid mt={2} xs={12} sm={6} pl={2}>
               <Accordion sx={styleAcordion} defaultExpanded expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                  <AccordionSummary
                     expandIcon={<ExpandMoreIcon />}
                     aria-controls="panel1a-content"
                     id="panel1a-header"
                  >
                     <Typography>Documentos Electrónicos</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                     {/* ITEM DOCUMENTOS ELECTRONICOS */}
                     <ElectronicDocument entity />
                  </AccordionDetails>
               </Accordion>
               <Accordion sx={styleAcordion} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                  <AccordionSummary
                     expandIcon={<ExpandMoreIcon />}
                     aria-controls="panel2a-content"
                     id="panel2a-header"
                  >
                     <Typography>Documentos Exportación</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                     {/* ITEM DOCUMENTOS EXPORTACION */}
                     <DocumentExport entity />
                  </AccordionDetails>
               </Accordion>
               <Accordion sx={styleAcordion} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                  <AccordionSummary
                     expandIcon={<ExpandMoreIcon />}
                     aria-controls="panel2a-content"
                     id="panel2a-header"
                  >
                     <Typography>Boleta Electrónica</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                     {/* ITEM BOLETA ELECTRONICA */}
                     <ElectronicTicket entity />
                  </AccordionDetails>
               </Accordion>
            </Grid>
         </Container>
      </Protected>
   );
};

