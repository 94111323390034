import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";

import { VALIDATORS } from "@/toolbox/helpers/validation-rules";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Grid, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, } from '@mui/material';

import { Props } from "./BookPurchaseOrder.type";

import { InputRef } from '@/components/common/Input/InputInterfaces';

import './BookPurchaseOrder-styles.css';
import FormGroup from '@mui/material/FormGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

export const BookPurchaseOrder: React.FC<Props> = (props: Props): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);

   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );

   const centroCosto = [
      { label: 'Seleccion CC' },
      { label: 'Desarrollo' },
      { label: 'Insumos' },
      { label: 'OPERACIONES' },
      { label: 'Ventas' },
   ]

   const centroBeneficio = [
      { label: 'Seleccion CB' },
      { label: 'Jibia Congelada' },
      { label: 'Jibia Conserva' }
   ]
   const rowsOrdenCompra = [
   ]

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
   }
   
   return (
      <Protected>
         <div style={{height:'100vh'}}>
         <form style={{ margin: '8px' }}>
            <Typography variant="h6" gutterBottom mb={3} className="tittle">
               Libro de Orden de Compra
            </Typography>
            <Grid container spacing={3} className={`e-mb-lg`} >

               <Grid item xs={12} sm={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                     <DatePicker
                        views={['year', 'month']}
                        label="Periodo y Mes"
                        minDate={new Date('2012-03-01')}
                        maxDate={new Date('2023-06-01')}
                        value={value}
                        onChange={(newValue) => {
                           setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} helperText={null} />}
                     />
                  </LocalizationProvider>
               </Grid>

               <Grid item xs={12} sm={2}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={centroCosto}
                     renderInput={(params) => <TextField {...params} label="Centro Costo" />}
                  />
               </Grid>

               <Grid item xs={12} sm={2}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={centroBeneficio}
                     renderInput={(params) => <TextField {...params} label="Centro Beneficio" />}
                  />
               </Grid>

               <Grid item xs={12} sm={5} alignItems="center" >

                  <Button
                     type='submit'
                     variant='contained'
                     onClick={handleSubmit}
                     disabled={loading}
                     disableElevation
                     className='btn_save'
                  >
                     Visualizar
                  </Button>
               </Grid>

            </Grid>
         </form>

         <div className='bg-tittle'>
            <Typography
               mt={3}
               variant="h6"
               align='center'
               className='tittle-text'
            >Listado Solicitudes de Oferta
            </Typography>
         </div>


         <div>
            <Typography
               mt={1}
               mb={2}
               align='left'
               className='tittle-text2'
            >Periodo 20XX | Mes Junio
            </Typography>
         </div>

         <TableContainer component={Paper}>
            <Table aria-label="simple table">
               <TableHead>
                  <TableRow>
                     <TableCell>N°</TableCell>           
                     <TableCell align="center">Fecha</TableCell>
                     <TableCell align="center">Tipo</TableCell>
                     <TableCell align="center">Folio</TableCell>
                     <TableCell align="center">Re</TableCell>
                     <TableCell align="center">Rut</TableCell>
                     <TableCell align="center">Razón Social</TableCell>
                     <TableCell align="center">Total</TableCell>
                     <TableCell align="center">Solicitado por</TableCell>
                     <TableCell align="center">Estado</TableCell>
                     <TableCell align="center">Cta</TableCell>
                     <TableCell align="center">Opciones</TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {rowsOrdenCompra.map((row, index) => (
                     <TableRow key={index} >
                        <TableCell component="th" scope="row">
                           {index + 1}
                        </TableCell>
                        <TableCell align="center">{row.numero}</TableCell>
                        <TableCell align="center">{row.fecha}</TableCell>
                        <TableCell align="center">{row.tipo}</TableCell>
                        <TableCell align="center">{row.folio}</TableCell>
                        <TableCell align="center">{row.re}</TableCell>
                        <TableCell align="center">{row.rut}</TableCell>
                        <TableCell align="center">{row.razonSocial}</TableCell>
                        <TableCell align="center">{row.total}</TableCell>
                        <TableCell align="center">{row.solicitadoPor}</TableCell>
                        <TableCell align="center">{row.estado}</TableCell>
                        <TableCell align="center">{row.cuenta}</TableCell>
                        <TableCell align="center">
                           <IconButton
                              aria-label="delete"
                              className='btn_delete'
                           >
                              <DeleteIcon />
                           </IconButton>

                           <IconButton
                              aria-label="delete"
                              className='btn_delete'
                           >
                              <EditIcon />
                           </IconButton>

                        </TableCell>
                     </TableRow>
                  ))}
               </TableBody>
            </Table>
         </TableContainer>
         </div>
      </Protected>
   );
}