import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, } from '@mui/material'
import { useState } from "react";
import { Props } from "../PurchaseOrder.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import '../PurchaseOrder-styles.css'
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { AddCircle } from "@mui/icons-material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BookIcon from '@mui/icons-material/Book';
import WbShadeIcon from '@mui/icons-material/WbShade';
import { ListadoBodegas } from "./ListadoBodegas";
import Modal from '@mui/material/Modal';

interface Column {
   id: 'codigo' | 'barra' | 'proveedor'|'descripcion'|'precio'|'stock' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}
const columns: readonly Column[] = [
   { id: 'codigo', label: 'Código', minWidth: 10 },
   { id: 'barra', label: 'Barra', minWidth: 100 },
   { id: 'proveedor', label: 'CODIGO Proveedor', minWidth: 50 },
   { id: 'descripcion', label: 'Descripción', minWidth: 100 },
   { id: 'precio', label: 'Precio', minWidth: 50 },
   { id: 'stock', label: 'Stock', minWidth: 50 },

];
interface Data {
   codigo: string;
   barra: string;
   proveedor: string;
   descripcion: string;
   precio: string;
   stock: string;
}
function createData(
   codigo: string,
   barra: string,
   proveedor: string,
   descripcion: string,
   precio: string,
   stock: string,
   ): Data {
      return { codigo, barra, proveedor, descripcion, precio, stock };
   }

const rowsData = [
   createData('1', '123456789', 'Proveedor 1', 'Producto 1', '$1.00', '10'),
   createData('2', '123456789', 'Proveedor 1', 'Producto 1', '$1.00', '10'),
   createData('3', '123456789', 'Proveedor 1', 'Producto 1', '$1.00', '10'),
   createData('4', '123456789', 'Proveedor 1', 'Producto 1', '$1.00', '10'),
   createData('5', '123456789', 'Proveedor 1', 'Producto 1', '$1.00', '10'),
]

const style = {
   position: 'absolute' as 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   width: 500,
   bgcolor: 'background.paper',
   // border: '2px solid #000',
   boxShadow: 24,
   p: 2,
   borderRadius: '20px'
};

export const DataProductos: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [ejecutivoComercial, setEjecutivoComercial] = useState('');
   const [estado, setEstado] = useState('');
   const [bodega, setBodega] = useState(false);
   const handleOPenBodega = () => setBodega(true);
   const handleCloseBodega = () => setBodega(false);

   const handleChangeEjecutivoComercial = (event: SelectChangeEvent) => {
      setEjecutivoComercial(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const estados=[
      {label:'No Adjudicada',value:'Enviado'},
      {label:'Adjudicada',value:'Adjudicada'},
      {label:'Anulada',value:'No Adjudicada'},
   ]
   return (
         <Container maxWidth='xl'>   
           <Grid item xs={12} md={12} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        ARTICULOS
                     </Typography>
                  </Box>
                  <Box>
                     <Typography variant='subtitle2'  gutterBottom >
                     Seleccione un articulo 0 un estado para filtrar
                      </Typography>
                  </Box>
            </Grid>   

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
               <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                        <TableCell>
                                          <TextField 
                                             id="outlined-basic"
                                             label="Codigo"
                                             size="small"
                                             fullWidth
                                          >
                                          </TextField>
                                       </TableCell>

                                       <TableCell>
                                          <TextField
                                             id="outlined-basic"
                                             label="Barra"
                                             size="small"
                                             fullWidth
                                          >
                                          </TextField>
                                       </TableCell>

                                       <TableCell>
                                          <TextField
                                             id="outlined-basic"
                                             label="Proveedor"
                                             size="small"
                                             fullWidth
                                          >
                                          </TextField>
                                       </TableCell>

                                       <TableCell>
                                          <TextField
                                             id="outlined-basic"
                                             label="Descripcion"
                                             size="small"
                                             fullWidth
                                          >
                                          </TextField>
                                       </TableCell>

                                       <TableCell>
                                          <TextField
                                             id="outlined-basic"
                                             label="Precio"
                                             size="small"
                                             fullWidth
                                          >
                                          </TextField>
                                       </TableCell>

                                       <TableCell>
                                          <TextField
                                             id="outlined-basic"
                                             label="Stock"
                                             size="small"
                                             fullWidth
                                          >
                                          </TextField>
                                       </TableCell>

                                       <TableCell>
                                          Otras Bodegas
                                       </TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.codigo}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                    <IconButton>
                                       
                                          <WbShadeIcon
                                          style={{ fontSize:'20px' }}
                                          onClick={handleOPenBodega}
                                       />
                                    </IconButton>
                                 </TableCell>
                              </TableRow>
                           );
                        })}

                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>
            <Modal
               open={bodega}
               onClose={handleCloseBodega}
               aria-labelledby="modal-modal-title"
               aria-describedby="modal-modal-description"
               >
            <Box sx={style} >
               <ListadoBodegas entity={undefined} />
            </Box>
         </Modal> 
        
         </Container>
   );
};
