import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { ArrowRightIcon } from "@/toolbox/constants/icons";
import { VALIDATORS } from "@/toolbox/helpers/validation-rules";
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, TablePagination, } from '@mui/material';
import { Input } from '@components/common/Input';
import { Props } from "./MassiveLiquidation.type";
import { InputRef } from '@/components/common/Input/InputInterfaces';
import './MassiveLiquidation_style.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { SelectChangeEvent } from '@mui/material/Select';
import { Link } from 'react-router-dom';
import { ROUTE_GC_FAMILIES, ROUTE_GC_PRICE_LIST, ROUTE_RM_SALARY_LIQUIDATION } from '@/toolbox/constants/route-map';

export const MassiveLiquidation: React.FC<Props> = (props: Props): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   // const [openProducts, setOpenProducts] = useState(false);
   const [loading, setLoading] = useState(false);
   const [selectTypeDesc, setSelectTypeDesc] = useState('');
   const [isDisabled, setIsDisabled] = useState<boolean>(false);

   const [open, setOpen] = useState(false);
   // const handleOpenProducts = () => setOpenProducts(true);
   // const handleCloseProducts = () => setOpenProducts(false);

   const handleChangeTypeDesc = (event: SelectChangeEvent) => {
      setSelectTypeDesc(event.target.value);
   };
   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };
   const periodo = [
      { label: '2022' },
      { label: '2021' },
      { label: '2020' },
      { label: '2019' },
      { label: '2018' },
      { label: '2017' },
      { label: '2016' },
      { label: '2015' },
   ]
   const mes = [
      { label: 'Enero' },
      { label: 'Febrero' },
      { label: 'Marzo' },
      { label: 'Abril' },
      { label: 'Mayo' },
      { label: 'Junio' },
      { label: 'Agosto' },
      { label: 'Setiembre' },
      { label: 'Octubre' },
      { label: 'Noviembre' },
      { label: 'Diciembre' },
      { label: 'TODOS' }
   ]

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 720,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };


   function listadoProducts(
      numero: number,
      codigo: string,
      barra: string,
      codigoP: string,
      descripcion: string,
      precio: string,
      stock: string
   ) {
      return { numero, codigo, barra, codigoP, descripcion, precio, stock };
   }

   const rowsListadoProductos = [
      listadoProducts(1, '12', '', '', 'Descripción 1', '0', '-1'),
      listadoProducts(2, '1256', '', '', 'Descripción 2', '0', '2'),
      listadoProducts(3, '003', '', '', 'Descripción 3', '1000', '12'),
      listadoProducts(4, '0002', '', '', 'Descripción 4', '12', '13'),

   ]

   function createData(
      descripcion: string,
      cantidad: string,
      valor: string,
      monto: string,
      total: string
   ) {
      return { descripcion, cantidad, valor, monto, total };
   }

   const rowsListado = [
      createData('Cerveza', '0', '$0', '$0', '$23'),
      createData('Huevos', '1', '$0', '$0', '$200'),
      createData('Producto prueba importacion', '0', '$0', '$0', '$20')
   ]

   const rules = useMemo(() => ({
      rut_proveedor: [
         VALIDATORS.REQUIRED,
      ],
      numero_contrato: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      titulo: [
         VALIDATORS.REQUIRED
      ],
      descripcion: [
         VALIDATORS.REQUIRED,
      ]

   }), []);
   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'rut_proveedot':
            props.setvalueContrato(prev => ({ ...prev, rut_proveedot: value }));
            break;
         case 'numero_contrato':
            props.setvalueContrato(prev => ({ ...prev, numero_contrato: value }))
            break;
         case 'titulo':
            props.setvalueContrato(prev => ({ ...prev, titulo: value }))
            break;
         case 'descripcion':
            props.setvalueContrato(prev => ({ ...prev, descripcion: value }));
            break;

         default:
            break;
      }
   };

   // const handleInputBlur = (event: any) => {
   //    const newVal = (event.target.value).trim();
   //    const name = event.target.name;
   //    props.setvalueContrato(prev => ({ ...prev, [name]: newVal }));
   // }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }
   return (
      <Protected>
         <Container maxWidth='xl' style={{height:'100vh'}}>
            <form style={{ margin: '8px' }}>
               <Grid container mb={4}>
                  <Grid item xs={12} sm={8}>
                     <Typography variant="h6" id="modal-modal-title" mb={2} className='tittle'>
                        GENERACIÓN LIQUIDACIÓN MASIVA
                     </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} textAlign="end">
                     <Button variant="outlined" component={Link} to={ROUTE_RM_SALARY_LIQUIDATION} startIcon={<ArrowBackIosIcon />}>
                        Volver
                     </Button>
                  </Grid>
               </Grid>             

               <Grid xs={12} mb={4}>
                  <Typography variant="h6" id="modal-modal-title" mb={2} className='tittle'>
                     Seleccionar Periodo y mes de Liquidación de Sueldo
                  </Typography>
               </Grid>

               <Grid container spacing={3} className={`e-mb-lg`} >
                  <Grid item xs={12} sm={3}>
                     <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={periodo}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Periodo" />}
                     />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                     <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={mes}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Mes" />}
                     />
                  </Grid>
               </Grid>
               <Grid item container
                  xs={12}
                  justifyContent='center'
                  mt={5}
               >
                  <Grid item>
                     <Button
                        // type='submit'
                        variant='contained'
                        // onClick={handleSiguiente}
                        disabled={loading}
                        disableElevation
                        className='btn_save'
                     >
                        generar
                     </Button>

                  </Grid>
               </Grid>
            </form>
         </Container>
      </Protected>
   );
}