
import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "./IndicatorsMonth.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './IndicatorsMonth_styles.css'
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { PictureAsPdf } from "@mui/icons-material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
   [`&.${tableCellClasses.head}`]: {
   //   backgroundColor: 'rgb(216, 214, 214)',
     borderButton: '2px solid rgb(216, 214, 214)',
   //   color: theme.palette.common.black,
     fontSize: 11,
   },
   [`&.${tableCellClasses.body}`]: {
     fontSize: 12,
   },
 }));
 
 const StyledTableRow = styled(TableRow)(({ theme }) => ({
   '&:nth-of-type(odd)': {
     backgroundColor: theme.palette.action.hover,
   },
   // hide last border
   '&:last-child td, &:last-child th': {
   },
 }));
 

export const IndicatorMonth:React.FC<Props> = (props: Props): JSX.Element => {
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState<Date | null>(new Date());



   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };



    return (
        <Protected>
        <Container maxWidth='xl'>
           <Grid container spacing={3}>
               <Grid item xs={12} md={12} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        INDICADORES PREVISIONALES
                     </Typography>
                  </Box>
                  
               </Grid>
                     
               <Grid item xs={12} md={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={3}>
                    <DatePicker
                        views={['year', 'month']}
                        label="Periodo y Mes"
                        minDate={new Date('2012-03-01')}
                        maxDate={new Date('2023-06-01')}
                        value={value}
                        onChange={(newValue) => {
                            setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} helperText={null} />}
                        />
                    </Stack>
                    </LocalizationProvider>
               </Grid>
                <Grid item xs={12} md={3}>
                    <Button
                        type='submit'
                        variant='contained'
                        // onClick={handleSubmit}
                        // disabled={loading}
                        disableElevation
                        className='btn_next'
                        style={{backgroundColor:'#ff9700', color:'#fff'}}
                        >
                        <span>Siguiente</span>
                    </Button>
                </Grid>
            </Grid>


            <Typography variant="subtitle1" gutterBottom mb={3} style={{background:"#16556b",color:'#ffffff' , borderRadius:"5px"}} align="center" mt={2}>
                Indicadores Mensuales 03/2022
            </Typography>
            <Typography  variant="subtitle2" display="block" gutterBottom mt={-2}> Base para el calculo en <span>4/2022</span> (Remuneraciones 03/2022)</Typography>
            

            <Grid container spacing={3}>
                <Grid item xs={12} md={5} >
                  <Typography  variant="subtitle2" display="block" gutterBottom mt={3} mx={6} p={1} style={{background:'rgb(86, 92, 97)', color:'#ffffff', borderRadius:'5px'}}>  Rentas Imponibles</Typography>
                  
                  <Grid container spacing={3}>
                     <Grid item xs={6} md={8}>
                        <Typography  variant="caption" display="block" mt={1} marginLeft={6}>Trab. Dependientes e Independientes</Typography>
                     </Grid>
                     <Grid item xs={6} md={4}>
                        <Typography  variant="caption" display="block" mt={1} marginLeft={6}>: <span>0</span></Typography>
                     </Grid>
                     <Grid item xs={6} md={8}>
                        <Typography  variant="caption" display="block" mt={-1} marginLeft={6}>Menores de 18 y Mayores de 65</Typography>
                     </Grid>
                     <Grid item xs={6} md={4}>
                        <Typography  variant="caption" display="block" mt={-1} marginLeft={6}>: <span>0</span></Typography>
                     </Grid>
                     <Grid item xs={6} md={8}>
                        <Typography  variant="caption" display="block" mt={-1} marginLeft={6}>Trabajadores de Casa Particular </Typography>
                     </Grid>
                     <Grid item xs={6} md={4}>
                        <Typography  variant="caption" display="block" mt={-1} marginLeft={6}>: <span>0</span></Typography>
                     </Grid>
                  </Grid>

                  <Typography  variant="subtitle2" display="block" gutterBottom mt={3} mx={6} p={1} style={{background:'rgb(86, 92, 97)', color:'#ffffff', borderRadius:'5px'}}> Rentas Topes Imponibles</Typography>
                  
                  <Grid container spacing={3}>
                     <Grid item xs={6} md={8}>
                        <Typography  variant="caption" display="block" mt={1} marginLeft={6}>Para afiliados a una AFP</Typography>
                     </Grid>
                     <Grid item xs={6} md={4}>
                        <Typography  variant="caption" display="block" mt={1} marginLeft={6}>: $<span>0</span></Typography>
                     </Grid>
                     <Grid item xs={6} md={8}>
                        <Typography  variant="caption" display="block" mt={-1} marginLeft={6}>Para afiliados al IPS (ex INP)</Typography>
                     </Grid>
                     <Grid item xs={6} md={4}>
                        <Typography  variant="caption" display="block" mt={-1} marginLeft={6}>: $<span>0</span></Typography>
                     </Grid>
                  </Grid>

                  <Typography  variant="subtitle2" display="block" gutterBottom mt={3} mx={6} p={1} style={{background:'rgb(86, 92, 97)', color:'#ffffff', borderRadius:'5px'}}>Valor UF</Typography>
                  
                  <Grid container spacing={3}>
                     <Grid item xs={6} md={6}>
                        <Typography  variant="caption" display="block" mt={1} marginLeft={6}>Al 31/10/2022</Typography>
                     </Grid>
                  </Grid>

                  <Typography  variant="subtitle2" display="block" gutterBottom mt={3} mx={6} p={1} style={{background:'rgb(86, 92, 97)', color:'#ffffff', borderRadius:'5px'}}> Valor UTM </Typography>
                  
                  <Grid container spacing={3}>
                     <Grid item xs={6} md={6}>
                        <Typography  variant="caption" display="block" mt={1} marginLeft={6}>31/10/2022</Typography>
                     </Grid>
                  </Grid>
                  
                  
                  <Grid container spacing={3}>
                     <Grid item xs={6} md={2}>
                        <Typography style={{color:'gray',fontWeight:'700'}} variant="caption" display="block" mt={2} >Fuente: </Typography>
                     </Grid>
                     <Grid item xs={6} md={6}>
                        <Typography  variant="caption" display="block" mt={2} >Previred</Typography>
                     </Grid>
                     <Grid item xs={6} md={3}>
                     </Grid>
                     <Grid item xs={6} md={2} style={{color:'gray',fontWeight:'700'}}>
                        <Typography  variant="caption" display="block" >Diferencias:</Typography>
                     </Grid>
                     <Grid item xs={6} md={6}>
                        <Typography  variant="caption" display="block" >soporte@softnet.cl</Typography>
                     </Grid>
                  </Grid>

                </Grid>
               <Grid item xs={12} md={6} >
               <Typography  variant="subtitle2" display="block" gutterBottom mt={3} mx={6} p={1} style={{background:'rgb(86, 92, 97)', color:'#ffffff', borderRadius:'5px'}}>AFP Cotización Activos (*)</Typography>
                  <Paper >
                     <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                           <TableHead>
                              <StyledTableRow>
                                 <StyledTableCell align="center" style={{fontWeight:'700'}}>AFP</StyledTableCell>
                                 <StyledTableCell align="center" style={{fontWeight:'700'}} colSpan={2}>DEPENDIENTES</StyledTableCell>
                                 <StyledTableCell align="center" style={{fontWeight:'700'}} >INDEPENDIENTES</StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow>
                                 <StyledTableCell align="center" style={{fontWeight:'700'}}></StyledTableCell>
                                 <StyledTableCell align="center" style={{fontWeight:'700'}}>TASA AFP</StyledTableCell>
                                 <StyledTableCell align="center" style={{fontWeight:'700'}}>SIS (1) (2)</StyledTableCell>
                                 <StyledTableCell align="center" style={{fontWeight:'700'}}>TASA AFP (3)</StyledTableCell>
                              </StyledTableRow>
                           </TableHead>
                           <TableBody>
                              <StyledTableRow>
                                 <StyledTableCell align="center">Capital</StyledTableCell>
                                 <StyledTableCell align="center">11.44 %</StyledTableCell>
                                 <StyledTableCell align="center">2.3 %</StyledTableCell>
                                 <StyledTableCell align="center">13.74 %</StyledTableCell>
                              </StyledTableRow>
                              <StyledTableRow>
                                 <StyledTableCell align="center">Cuprum</StyledTableCell>
                                 <StyledTableCell align="center">11.44</StyledTableCell>
                                 <StyledTableCell align="center">2.3 %</StyledTableCell>
                                 <StyledTableCell align="center">13.74</StyledTableCell>
                              </StyledTableRow>
                        </TableBody>
                        </Table>
                     </TableContainer>
                  </Paper>
                  <Typography  variant="caption" display="block" mt={1} >
                  (*) 1) Esta cotización es de cargo del Empleador. 2) SIS no aplica para trabajador dependiente pensionado. 3) Esta tasa incluye el SIS, que es de cargo del trabajador.
                  </Typography>

                  <Typography  variant="subtitle2" display="block" gutterBottom mt={3} mx={6} p={1} style={{background:'rgb(86, 92, 97)', color:'#ffffff', borderRadius:'5px'}} >  Asignación Familiar</Typography>

                  <Grid container spacing={3}>
                     <Grid item xs={6} >
                     <Typography  align="center" variant="subtitle2" display="block" gutterBottom mt={3} >  Tramo</Typography>

                     </Grid>
                     <Grid item xs={6} >
                     <Typography align="center" variant="subtitle2" display="block" gutterBottom mt={3} >  Monto</Typography>

                     </Grid> 
                     <Grid item xs={6} >
                     <Typography  align="center" variant="caption" display="block" gutterBottom >  A</Typography>

                     </Grid>

                     <Grid item xs={6} >
                     <Typography align="center" variant="caption" display="block" gutterBottom  > $ 13.401</Typography>

                     </Grid> 
                     <Grid item xs={6} >
                     <Typography  align="center" variant="caption" display="block" gutterBottom  >  B</Typography>

                     </Grid>
                     <Grid item xs={6} >
                     <Typography align="center" variant="caption" display="block" gutterBottom  >  $ 8.224</Typography>

                     </Grid> 
                     <Grid item xs={6} >
                     <Typography  align="center" variant="caption" display="block" gutterBottom  >  C</Typography>

                     </Grid>
                     <Grid item xs={6} >
                     <Typography align="center" variant="caption" display="block" gutterBottom  >  $ 2.599</Typography>

                     </Grid> 
                     <Grid item xs={6} >
                     <Typography  align="center" variant="caption" display="block" gutterBottom  >  D</Typography>

                     </Grid>
                     <Grid item xs={6} >
                     <Typography  align="center" variant="caption" display="block" gutterBottom  >  $</Typography>

                     </Grid>

                  </Grid>
               </Grid>
            </Grid>

         </Container>
     </Protected>
    );
   };

