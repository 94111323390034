import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, InputAdornment, FormControl, FormControlLabel, Checkbox, Stack, FormGroup, RadioGroup, } from '@mui/material'
import React, { useState, useRef } from "react";
import { Props } from "../DataBooks.type"
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import EmailIcon from '@mui/icons-material/Email';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TableBody from '@mui/material/TableBody';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TableCell from '@mui/material/TableCell';
import NumbersIcon from '@mui/icons-material/Numbers';
import TableContainer from '@mui/material/TableContainer';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PhoneIcon from '@mui/icons-material/Phone';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import AssignmentIcon from '@mui/icons-material/Assignment';
import NextWeekIcon from '@mui/icons-material/NextWeek';
import TableRow from '@mui/material/TableRow';
import Radio from '@mui/material/Radio';
// import '../Workers.css'
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import { DeleteIcon } from "@/toolbox/constants/icons";

export const DataModalAssignSurrender: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [ejecutivoComercial, setEjecutivoComercial] = useState('');
   const [estado, setEstado] = useState('');
   // const [data, setData] = React.useState<Date | null>(new Date());
   const [data, setData] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const inputRefs = useRef<Array<InputRef | null>>([]);

   //radio buttoms
   const [valueRadio, setValueRadio] = React.useState('female');

   const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueRadio((event.target as HTMLInputElement).value);
   };

   const handleChangeEjecutivoComercial = (event: SelectChangeEvent) => {
      setEjecutivoComercial(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const tipoRendicion = [
      { label: 'Caja' },
      { label: 'Transferencia' },
      { label: 'Cheque' }
   ]

   return (
      <Container maxWidth='xl'>
         <Grid container spacing={3} mt={2} >
            <Grid item container>
               <Grid item xs={10}>
                  <Typography variant="h6" id="modal-modal-title" className='tittle'>
                     ASIGNAR RENDICIÓN
                  </Typography>
                  <Grid xs={12} mt={1}>
                     <Typography variant='subtitle2' gutterBottom >
                        Datos del Receptor:
                     </Typography>
                  </Grid>
               </Grid>
               <Grid item xs={2} textAlign="end">
                  <AssignmentIcon style={{ color: '#FF9700' }} />
               </Grid>
            </Grid>
            <Grid item container className={`e-mb-lg`}>
               <Grid item xs={12} sm={4}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={tipoRendicion}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Tipo Rendición" />}
                  />
               </Grid>            
            </Grid>         
            <Grid container mt={2}>
               <Grid item xs={12} textAlign="center">
                  <button className='btn_add'>
                     Asignar
                  </button>
               </Grid>

            </Grid>
            {/* <Grid xs={12}>
               <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={2}>
                  LISTADO DE CARGAS FAMILIARES
               </Typography>
            </Grid> */}
         </Grid>




      </Container >
   );
};

