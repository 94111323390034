import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography } from '@mui/material'
import { useState, useRef } from "react";
import { Props } from "../DataBooks.type";
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TableRow from '@mui/material/TableRow';

//Tabla 01
interface Column {
   id: 'numero' | 'tipo' | 'fecha' | 'monto';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: 'numero', label: 'Numero', minWidth: 100 },
   { id: 'tipo', label: 'Tipo', minWidth: 100 },
   { id: 'fecha', label: 'Fecha', minWidth: 100 },
   { id: 'monto', label: 'Monto', minWidth: 100 }
];

interface Data {
   numero: string;
   tipo: string;
   fecha: string;
   monto: string;
}

function createData(
   numero: string,
   tipo: string,
   fecha: string,
   monto: string
): Data {
   return { numero, tipo, fecha, monto };
}

const rowsData = [
   createData('Número', 'Tipo', 'Fecha Ingreso', 'Monto')
]

//Segunda tabla
interface Column2 {
   id: 'tipo' | 'folio' | 'razon' | 'texto';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns2: readonly Column2[] = [
   { id: 'tipo', label: 'Tipo', minWidth: 100 },
   { id: 'folio', label: 'Folio', minWidth: 100 },
   { id: 'razon', label: 'Razon', minWidth: 100 },
   { id: 'texto', label: 'Texto', minWidth: 100 }
];

interface Data2 {
   tipo: string;
   folio: string;
   razon: string;
   texto: string;
}

function createData2(
   tipo: string,
   folio: string,
   razon: string,
   texto: string
): Data2 {
   return { tipo, folio, razon, texto };
}

const rowsData2 = [
   createData2('Tipo de Documento', 'Folio', 'Razón de Referencia', 'Texto Referencia')
]


export const DataModalVoucher: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);  
   const inputRefs = useRef<Array<InputRef | null>>([]);

   return (
      <Container maxWidth='xl'>
         <Grid container spacing={3} mt={1} mb={2}>
            <Grid container item xs={12}>
               <Grid item xs={10}>
                  <Typography variant="h6" id="modal-modal-title" className='tittle'>
                     Comprobantes de Pago y Anulaciones
                  </Typography>
               </Grid>
               <Grid item xs={2} textAlign="end">
                  <AssignmentIcon style={{ color: '#FF9700' }} />
               </Grid>
            </Grid>
            <Grid item xs={12}>
               <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  <TableContainer sx={{ maxHeight: 420 }}>
                     <Table size="small" stickyHeader aria-label="sticky table">
                        <TableHead>
                           <TableRow>
                              <TableCell colSpan={4}>Comprobante de Pago Asociado</TableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                              return (
                                 <TableRow hover role="checkbox" tabIndex={-1} key={row.numero}>
                                    {columns.map((column) => {
                                       const value = row[column.id];
                                       return (
                                          <TableCell key={column.id} align={column.align}>
                                             {column.format && typeof value === 'number'
                                                ? column.format(value)
                                                : value}
                                          </TableCell>
                                       );
                                    })}
                                 </TableRow>
                              );
                           })}
                        </TableBody>
                     </Table>
                  </TableContainer>
               </Paper>
            </Grid>

            <Grid mt={2} item xs={12}>
               <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  <TableContainer sx={{ maxHeight: 420 }}>
                     <Table size="small" stickyHeader aria-label="sticky table">
                        <TableHead>
                           <TableRow>
                              <TableCell colSpan={4}> Documentos con Anulación</TableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {rowsData2.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                              return (
                                 <TableRow hover role="checkbox" tabIndex={-1} key={row.tipo}>
                                    {columns2.map((column) => {
                                       const value = row[column.id];
                                       return (
                                          <TableCell key={column.id} align={column.align}>
                                             {column.format && typeof value === 'number'
                                                ? column.format(value)
                                                : value}
                                          </TableCell>
                                       );
                                    })}
                                 </TableRow>
                              );
                           })}
                        </TableBody>
                     </Table>
                  </TableContainer>
               </Paper>
            </Grid>

         </Grid>
      </Container >
   );
};

