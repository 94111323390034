import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "../Monthly.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import '../Monthly_style.css'
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { PictureAsPdf } from "@mui/icons-material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import { ROUTE_RM_STEPPER } from '@/toolbox/constants/route-map';

interface Column {
   id: 'ejecutivo' | 'razonSocial' | 'fecha' | 'asunto'|'contacto'| 'email';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

const columns:readonly Column[] = [
   { id: 'ejecutivo', label: 'Ejecutivo', minWidth: 10 },
   { id: 'razonSocial', label: 'Razón Social', minWidth: 10 },
   { id: 'fecha', label: 'Fecha', minWidth: 10 },
   { id: 'asunto', label: 'Asunto', minWidth: 10 },
   { id: 'contacto', label: 'Contacto', minWidth: 10 },
   { id: 'email', label: 'Email', minWidth: 10 },
   ];

interface Data {
   ejecutivo: string;
   razonSocial: string;
   fecha: string;
   asunto: string;
   contacto: string;
   email: string;
}

function createData(
   ejecutivo: string,
   razonSocial: string,
   fecha: string,
   asunto: string,
   contacto: string,
   email: string,
   ):Data {
   return { ejecutivo, razonSocial, fecha, asunto, contacto, email };
}

const rowsData = [
   createData('Ejecutivo 1', 'Razón Social 1', 'Fecha 1', 'Asunto 1', 'Contacto 1', 'Email 1'),
   createData('Ejecutivo 2', 'Razón Social 2', 'Fecha 2', 'Asunto 2', 'Contacto 2', 'Email 2'),
   createData('Ejecutivo 3', 'Razón Social 3', 'Fecha 3', 'Asunto 3', 'Contacto 3', 'Email 3'),
   createData('Ejecutivo 4', 'Razón Social 4', 'Fecha 4', 'Asunto 4', 'Contacto 4', 'Email 4'),
   createData('Ejecutivo 5', 'Razón Social 5', 'Fecha 5', 'Asunto 5', 'Contacto 5', 'Email 5'),
]


const bancos = [
   { label: 'Banco Chile 123456789', value: 'banco1' },
   { label: 'Banco de x 123456789', value: 'banco2' },
   { label: 'Banco de y 123456789', value: 'banco3' },
   { label: 'Banco de z 123456789', value: 'banco4' },
]


export const SheetsRouteSeller:React.FC<Props> = (props: Props): JSX.Element => {
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value2, setValue2] = useState('');
   const [loading, setLoading] = useState(false);
   const [familyProduct, setFamilyProduct] = useState('');
   const [estado, setEstado]= useState('');
   const [value, setValue] = useState<Date | null>(new Date());
   const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const handleChangeFamilyProduct = (event: SelectChangeEvent) => {
      setFamilyProduct(event.target.value);
    };
    const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
    };
   function handleClick() {
     setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const handleStepper = (props: Props) => {

   }
   
    return (
        <Container maxWidth='xl'>
           <Grid container spacing={3}>
               <Grid item xs={12} md={6} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        HOJA DE RUTA DIARIA EQUIPO DE VENTA
                     </Typography>
                  </Box>
                  <Box>
                     <Typography variant='caption'  gutterBottom >
                        HOJA DE RUTA DIARIA EQUIPO DE VENTA
                     </Typography>
                  </Box>
               </Grid>
               <Grid item xs={12} md={6} className="btn-download">
                  <Box pb={2} display="flex" justifyContent="space-evenly">

                  <LoadingButton
                     // color="warning"
                     onClick={handleClick}
                     loading={loading}
                     loadingPosition="start"
                     startIcon={<FileDownloadIcon />}
                     variant="contained"
                     className="btn-download2"
                     style={{marginRight: '10px'  }}
                     
                     >
                     Excel
                     </LoadingButton>
                  </Box>
               </Grid>

               <Grid item xs={12} md={2}>
                     <TextField
                                 id="outlined-basic"
                                 // label="Presupuesto"
                                 variant="outlined"
                                 defaultValue="100 %"
                                 size='small'
                                 // fullWidth
                                 value='Ejecutivo'
                                 aria-readonly
                                 // onChange={(e) => setValue2(e.target.value)}
                           />
               </Grid>               
               <Grid item xs={12} md={2}>
                     <TextField
                                 id="outlined-basic"
                                 // label="Presupuesto"
                                 variant="outlined"
                                 defaultValue="Hoy"
                                 size='small'
                                 // fullWidth
                                 // value='Ejecutivo'
                                 aria-readonly
                                 // onChange={(e) => setValue2(e.target.value)}
                           />
               </Grid>
               <Grid item xs={12} md={2}>
                     <TextField
                                 id="outlined-basic"
                                 // label="Presupuesto"
                                 variant="outlined"
                                 defaultValue="Mes"
                                 size='small'
                                 // fullWidth
                                 // value='Ejecutivo'
                                 aria-readonly
                                 // onChange={(e) => setValue2(e.target.value)}
                           />
               </Grid>            

               <Grid item xs={12} md={2}>
                     <TextField
                                 id="outlined-basic"
                                 // label="Presupuesto"
                                 variant="outlined"
                                 defaultValue="% Mes"
                                 size='small'
                                 // fullWidth
                                 // value='Ejecutivo'
                                 aria-readonly
                                 // onChange={(e) => setValue2(e.target.value)}
                           />
               </Grid>            

               <Grid item xs={12} md={2}>
                     <TextField
                                 id="outlined-basic"
                                 // label="Presupuesto"
                                 variant="outlined"
                                 defaultValue="Año"
                                 size='small'
                                 // fullWidth
                                 value='Ejecutivo'
                                 aria-readonly
                                 // onChange={(e) => setValue2(e.target.value)}
                           />
               </Grid>            

               <Grid item xs={12} md={2}>
                     <TextField
                                 id="outlined-basic"
                                 // label="Presupuesto"
                                 variant="outlined"
                                 defaultValue="% Año"
                                 size='small'
                                 // fullWidth
                                 value='Ejecutivo'
                                 aria-readonly
                                 // onChange={(e) => setValue2(e.target.value)}
                           />
               </Grid>            


               <Grid item xs={12} md={3}>
                       
               </Grid>

               
             
             

            </Grid>
            
            <div style={{background:'#16556b', borderRadius:'5px', color:'#ffffff',}} >
                        <Typography 
                        mt={2}
                        mb={2}
                        style={{padding:'3px'}}
                        variant="subtitle2"
                        align='center'
                        >Listado Clientes y Proveedores Ingresados
                        </Typography>
         </div>

            <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:220}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                                 {column.label}
                              </TableCell>
                           ))}
                           <TableCell>Opcion</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.ejecutivo}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                       <IconButton aria-label="delete" component={Link} to={ROUTE_RM_STEPPER}>
                                          <CheckCircleIcon style={{color:'green'}} />
                                       </IconButton>
                                       {/* <IconButton aria-label="delete">
                                          <DeleteIcon style={{color:'#16556b'}}/>
                                       </IconButton>
                                       <IconButton aria-label="edit">
                                          <FactCheckIcon  style={{color:'#16556b'}}/>
                                       </IconButton>
                                       <IconButton aria-label="edit">
                                          <PrintIcon style={{color:'#16556b'}}/>
                                       </IconButton> */}
         
                                 </TableCell>

                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
            </Paper>

         </Container>
     
    );
   };

