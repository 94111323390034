import React, { FunctionComponent, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import { SelectChangeEvent } from "@mui/material";
import Stack from "@mui/material/Stack";
import TableRow from "@mui/material/TableRow";
import { Modal, IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "../ElectronicDispatchGuide_styles.css";
import TextField from "@mui/material/TextField";
import { Autocomplete, Box, Button, Grid } from "@mui/material";
import { Protected } from "@/components/layout/Protected";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DataInformsFinal } from "./Modal/DataInformsFinal";
import { DataProductos } from "./Modal/DataProductos";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowSquareIcon from "@mui/icons-material/Square";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SearchIcon from "@mui/icons-material/Search";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { Link } from "react-router-dom";
import { ROUTE_F_ED_ELECTRONIC_DISPATCH_GUIDE } from "@/toolbox/constants/route-map";

interface Column {
   id:
      | "numero"
      | "codigo"
      | "detalle"
      | "cantidad"
      | "valor"
      | "descuento"
      | "subtotal";
   label: string;
   minWidth?: number;
   align?: "right";
   format?: (value: number) => string;
}
interface Column1 {
   id:
      | "bodega"
      | "detalle"
      | "cantidad"
      | "valor"
      | "tipo"
      | "valorDescuento"
      | "total";
   label: string;
   minWidth?: number;
   align?: "right";
   format?: (value: number) => string;
}

interface Column2 {
   id:
      | "tipoDocumento"
      | "folioDocumento"
      | "fechaDocumento"
      | "razonReferencia";

   label: string;
   minWidth?: number;
   align?: "right";
   format?: (value: number) => string;
}

interface Column3 {
   id: "numero" | "tipoDocumento" | "numeroDocumento" | "fechaDocumento";

   label: string;
   minWidth?: number;
   align?: "right";
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: "numero", label: "N°", minWidth: 10 },
   { id: "codigo", label: "Código", minWidth: 10 },
   { id: "detalle", label: "Detalle", minWidth: 10 },
   { id: "cantidad", label: "Cantidad", minWidth: 10 },
   { id: "valor", label: "Valor Neto", minWidth: 10 },
   { id: "descuento", label: "Descuento", minWidth: 10 },
   { id: "subtotal", label: "Subtotal", minWidth: 10 },
];
const columns1: readonly Column1[] = [
   { id: "bodega", label: "Bodega", minWidth: 10 },
   { id: "detalle", label: "Detalle", minWidth: 50 },
   { id: "cantidad", label: "Cantidad", minWidth: 5 },
   { id: "valor", label: "Valor", minWidth: 10 },
   { id: "tipo", label: "Tipo", minWidth: 10 },
   { id: "valorDescuento", label: "Valor Descuento", minWidth: 10 },
   { id: "total", label: "Total", minWidth: 10 },
];

const columns2: readonly Column2[] = [
   { id: "tipoDocumento", label: "Tipo Documento", minWidth: 10 },
   { id: "folioDocumento", label: "Folio Documento", minWidth: 50 },
   { id: "fechaDocumento", label: "Fecha Documento", minWidth: 5 },
   { id: "razonReferencia", label: "Razon Referencia", minWidth: 10 },
];

const columns3: readonly Column3[] = [
   { id: "numero", label: "N", minWidth: 10 },
   { id: "tipoDocumento", label: "Tipo Documento", minWidth: 50 },
   { id: "numeroDocumento", label: "Numero Documento", minWidth: 5 },
   { id: "fechaDocumento", label: "Fecha Documento", minWidth: 10 },
];

interface Data {
   numero: number;
   codigo: string;
   detalle: string;
   cantidad: number;
   valor: number;
   tipo: string;
   valorDescuento: number;
   total: number;
   descuento: number;
   subtotal: number;
}

function createData(
   numero: number,
   codigo: string,
   detalle: string,
   cantidad: number,
   valor: number,
   tipo: string,
   valorDescuento: number,
   total: number
): Data {
   return {
      numero,
      codigo,
      detalle,
      cantidad,
      valor,
      tipo,
      valorDescuento,
      total,
      descuento: 0,
      subtotal: 0,
   };
}

interface Data2 {
   numero: number;
   tipoDocumento: string;
   numeroDocumento: string;
   fechaDocumento: string;
}

function createData2(
   numero: number,
   tipoDocumento: string,
   numeroDocumento: string,
   fechaDocumento: string
): Data2 {
   return { numero, tipoDocumento, numeroDocumento, fechaDocumento };
}

const rowsData = [
   createData(1, "123", "Prueba 1", 10, 5, "C/U Serv", 0, 1000),
   createData(2, "123", "Prueba 2", 10, 5, "C/U Serv", 0, 1000),
   createData(3, "123", "Prueba 3", 10, 5, "C/U Serv", 0, 1000),
   createData(4, "123", "Prueba 4", 10, 5, "C/U Serv", 0, 1000),
   createData(5, "123", "Prueba 5", 10, 5, "C/U Serv", 0, 1000),
];
const rowsData2 = [createData2(1, "123", "Prueba 1", "Prueba 2")];

const tipos = [
   { label: "%", value: "%" },
   { label: "$", value: "$" },
];

interface TableEmitElectronicDispatchGuideProps {}

export const EmitElectronicDispatchGuide: FunctionComponent<
   TableEmitElectronicDispatchGuideProps
> = () => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   // const [value, setValue] = useState('');
   const [value, setValue] = useState<Date | null>(new Date());
   const [loading, setLoading] = useState(false);
   const [pais, setPais] = useState("");
   const [estado, setEstado] = useState("");
   const [tipo, seTipo] = useState("");

   const [openArticulo, setOpenArticulo] = useState(false);
   const handleOpenArticulo = () => {
      setOpenArticulo(true);
   };
   const handleCloseArticulo = () => setOpenArticulo(false);

   const [selectTypeResume, setSelectTypeResume] = useState("");
   const [data, setData] = React.useState<Date | null>(new Date());

   const [openAdd, setOpenAdd] = useState(false);
   const handleOpenAdd = () => setOpenAdd(true);
   const handleCloseAdd = () => setOpenAdd(false);

   const [openEdit, setOpenEdit] = useState(false);
   const handleOpenEdit = () => setOpenEdit(true);
   const handleCloseEdit = () => setOpenEdit(false);

   const [openInform, setOpenInform] = useState(false);
   const handleOpenInform = () => setOpenInform(true);
   const handleCloseInform = () => setOpenInform(false);

   const handleChange = (event: SelectChangeEvent) => {
      seTipo(event.target.value as string);
   };

   const handleChangeto = (event: any) => {
      setValue(event.target.value);
   };
   const handleChangeTypeResume = (event: SelectChangeEvent) => {
      setSelectTypeResume(event.target.value);
   };

   const label = { inputProps: { "aria-label": "Checkbox demo" } };

   const handleChangePais = (event: SelectChangeEvent) => {
      setPais(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
   ) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const direcciones = [
      { label: "Santiago", value: "Santiago" },
      { label: "Providencia", value: "Providencia" },
      { label: "Concepcion", value: "Concepcion" },
      { label: "Talca", value: "Talca" },
   ];

   const traslado = [
      {
         label: "Operación Constituye Venta",
         value: "Operación Constituye Venta",
      },
      { label: "Venta por Efectuar", value: "Venta por Efectuar" },
      { label: "Consignación", value: "Consignación" },
      { label: "Entrega Gratuitas", value: "Entrega Gratuitas" },
      { label: "Traslados Internos", value: "Traslados Internos" },
      { label: "Otros Traslado no Venta", value: "Otros Traslado no Venta" },
      { label: "Guia Devolución", value: "Guia Devolución" },
      {
         label: "Traslado para Exportación",
         value: "Traslado para Exportación",
      },
   ];

   const proyecto = [
      { label: "Seleccione Proyecto", value: "Seleccione Proyecto" },
   ];

   const bodega = [{ label: "Seleccione Bodega", value: "Seleccione Bodega" }];

   const operador = [
      { label: "Seleccione Operador", value: "Seleccione Operador" },
   ];

   const despacho = [
      { label: "Por Cuenta del Receptor", value: "Por Cuenta del Receptor" },
      {
         label: "Por Cuenta del Emisor a Inst. del Receptor",
         value: "Por Cuenta del Emisor a Inst. del Receptor",
      },
      { label: "Sin Despacho", value: "Sin Despacho" },
      {
         label: "Por Cuenta del Emisor a otras Inst.",
         value: "Por Cuenta del Emisor a otras Inst.",
      },
   ];

   const style = {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 1100,
      bgcolor: "background.paper",
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: "20px",
   };

   return (
      <Protected>
         <Container maxWidth="xl">
            <Grid container spacing={1}>
               <Grid item xs={12} md={10}>
                  <Box>
                     <Typography
                        variant="h6"
                        component="h1"
                        gutterBottom
                        className="tittle"
                     >
                        Emitiendo Guía de Despacho Electrónica (52)
                     </Typography>
                  </Box>
                  <Box>
                     {/* <Typography variant='subtitle2'  gutterBottom >
                       Periodo: 2022 Mes: 04                        
                       </Typography> */}
                  </Box>
               </Grid>
               <Grid item xs={12} md={2}>
                  <Grid container>
                     <Grid item xs={12} md={8}>
                        <IconButton>
                           <ContentPasteSearchIcon
                              // style={{ fontSize:'20px' }}
                              style={{
                                 fontSize: "30px",
                                 color: "#000000",
                                 marginLeft: "25px",
                              }}
                              onClick={handleOpenInform}
                           />
                        </IconButton>
                     </Grid>
                     <Grid item xs={12} md={4}>
                        <Button
                           type="submit"
                           variant="contained"
                           // onClick={handleSubmit}
                           // disabled={loading}
                           disableElevation
                           component={Link}
                           to={ROUTE_F_ED_ELECTRONIC_DISPATCH_GUIDE}
                           className="btn_opcion"
                        >
                           <span>VOLVER</span>
                        </Button>
                     </Grid>
                  </Grid>
               </Grid>
               <Grid item xs={12} md={12}>
                  <Typography
                     variant="h6"
                     gutterBottom
                     mb={3}
                     className="tittle2"
                     align="center"
                     mt={3}
                  >
                     GUIA DE DESPACHO ELECTRONICA (52)
                  </Typography>
               </Grid>
               <Grid item xs={12} md={2}>
                  <TextField
                     id="rut"
                     label="Rut Empresa"
                     type="text"
                     fullWidth
                     variant="outlined"
                     defaultValue="7656576-7"
                     size="small"
                  />
               </Grid>
               <Grid item xs={12} md={2}>
                  <TextField
                     id="rut"
                     label="Razon Social"
                     type="text"
                     fullWidth
                     variant="outlined"
                     defaultValue="Alan Monsalve"
                     size="small"
                  />
               </Grid>
               <Grid item xs={12} md={3}>
                  <TextField
                     id="rut"
                     label="Giro"
                     type="text"
                     fullWidth
                     variant="outlined"
                     defaultValue="giro cliente 3"
                     size="small"
                  />
               </Grid>
               <Grid item xs={12} md={3}>
                  <TextField
                     id="rut"
                     label="Email"
                     type="text"
                     fullWidth
                     variant="outlined"
                     defaultValue="jmora@test.cl"
                     size="small"
                  />
               </Grid>
               <Grid item xs={12} sm={2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                     <Stack spacing={3}>
                        <DatePicker
                           // views={['year', 'month']}
                           label="Fecha"
                           minDate={new Date("2012-03-01")}
                           maxDate={new Date("2023-06-01")}
                           value={value}
                           onChange={(newValue) => {
                              setValue(newValue);
                           }}
                           renderInput={(params) => (
                              <TextField {...params} helperText={null} />
                           )}
                        />
                     </Stack>
                  </LocalizationProvider>
               </Grid>
               <Grid item xs={12} md={3}>
                  <Autocomplete
                     disablePortal
                     size="small"
                     id="combo-box-demo"
                     options={direcciones}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => (
                        <TextField {...params} label="Condicion de Pago" />
                     )}
                  />
               </Grid>
               <Grid item xs={12} md={3}>
                  <Autocomplete
                     disablePortal
                     size="small"
                     id="combo-box-demo"
                     options={direcciones}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => (
                        <TextField {...params} label="Area de Negocio" />
                     )}
                  />
               </Grid>
               <Grid item xs={12} md={2}>
                  <TextField
                     id="rut"
                     label="Vendedor"
                     type="text"
                     fullWidth
                     // margin="normal"
                     variant="outlined"
                     size="small"
                     // defaultValue="jmora@test.cl"
                  />
               </Grid>
               <Grid item xs={12} md={2}>
                  <TextField
                     id="rut"
                     label="Orden de Compra"
                     type="text"
                     fullWidth
                     // margin="normal"
                     variant="outlined"
                     size="small"
                     // defaultValue="jmora@test.cl"
                  />
               </Grid>
               <Grid item xs={12} md={2}>
                  <TextField
                     id="rut"
                     label="Patente"
                     type="text"
                     fullWidth
                     // margin="normal"
                     variant="outlined"
                     size="small"
                     // defaultValue="jmora@test.cl"
                  />
               </Grid>
               <Grid item xs={12} md={4}>
                  <Autocomplete
                     disablePortal
                     size="small"
                     id="combo-box-demo"
                     options={traslado}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => (
                        <TextField {...params} label=" Traslado" />
                     )}
                  />
               </Grid>
               <Grid item xs={12} md={4}>
                  <Autocomplete
                     disablePortal
                     size="small"
                     id="combo-box-demo"
                     options={traslado}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => (
                        <TextField {...params} label=" Bodega Destino" />
                     )}
                  />
               </Grid>
               <Grid item xs={12} md={4}>
                  <TextField
                     id="rut"
                     label="Nombre Bodega"
                     type="text"
                     fullWidth
                     variant="outlined"
                     size="small"
                     // defaultValue="jmora@test.cl"
                  />
               </Grid>

               <Grid item xs={12} md={12} mt={2}>
                  <Typography variant="subtitle2" gutterBottom>
                     GUIA
                  </Typography>
               </Grid>

               <Grid item xs={12} md={3}>
                  <TextField
                     id="rut"
                     label="Nº Guia de Despacho"
                     type="text"
                     fullWidth
                     variant="outlined"
                     size="small"
                     // defaultValue="jmora@test.cl"
                  />
               </Grid>
               <Grid item xs={12} md={3}>
                  <TextField
                     id="rut"
                     label="N/V"
                     type="text"
                     fullWidth
                     variant="outlined"
                     size="small"
                     // defaultValue="jmora@test.cl"
                  />
               </Grid>
               <Grid item xs={12} md={3}>
                  <TextField
                     id="rut"
                     label="Orden de Compra"
                     type="text"
                     fullWidth
                     variant="outlined"
                     size="small"
                     // defaultValue="jmora@test.cl"
                  />
               </Grid>
               <Grid item xs={12} md={3}>
                  <Autocomplete
                     disablePortal
                     size="small"
                     id="combo-box-demo"
                     options={proyecto}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => (
                        <TextField {...params} label=" Proyecto" />
                     )}
                  />
               </Grid>

               <Grid item xs={12} md={12} mt={2}>
                  <Typography variant="subtitle2" gutterBottom>
                     DESPACHO
                  </Typography>
               </Grid>

               <Grid item xs={12} md={3}>
                  <Autocomplete
                     disablePortal
                     size="small"
                     id="combo-box-demo"
                     options={despacho}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => (
                        <TextField {...params} label=" Despacho" />
                     )}
                  />
               </Grid>
               <Grid item xs={12} md={3}>
                  <Autocomplete
                     disablePortal
                     size="small"
                     id="combo-box-demo"
                     options={direcciones}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => (
                        <TextField {...params} label="Dirección" />
                     )}
                  />
               </Grid>
               <Grid item xs={12} md={3}>
                  <Autocomplete
                     disablePortal
                     size="small"
                     id="combo-box-demo"
                     options={bodega}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => (
                        <TextField {...params} label=" Bodega Origen" />
                     )}
                  />
               </Grid>
               <Grid item xs={12} md={3}>
                  <Autocomplete
                     disablePortal
                     size="small"
                     id="combo-box-demo"
                     options={operador}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => (
                        <TextField {...params} label=" Operador" />
                     )}
                  />
               </Grid>
               <Grid item xs={12} md={4}>
                  <Autocomplete
                     disablePortal
                     size="small"
                     id="combo-box-demo"
                     options={direcciones}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => (
                        <TextField {...params} label="Dirección de Despacho" />
                     )}
                  />
               </Grid>
               <Grid item xs={12} md={2}>
                  <TextField
                     id="rut"
                     label="FCL"
                     type="text"
                     fullWidth
                     variant="outlined"
                     size="small"
                     // defaultValue="jmora@test.cl"
                  />
               </Grid>
               <Grid item xs={12} md={2}>
                  <TextField
                     id="rut"
                     label="Marcas"
                     type="text"
                     fullWidth
                     variant="outlined"
                     size="small"
                     // defaultValue="jmora@test.cl"
                  />
               </Grid>
               <Grid item xs={12} md={2}>
                  <TextField
                     id="rut"
                     label="Anticipo"
                     type="text"
                     fullWidth
                     variant="outlined"
                     size="small"
                     // defaultValue="jmora@test.cl"
                  />
               </Grid>
               <Grid item xs={12} md={2}>
                  <TextField
                     id="rut"
                     label="Sellos"
                     type="text"
                     fullWidth
                     variant="outlined"
                     size="small"
                     // defaultValue="jmora@test.cl"
                  />
               </Grid>

               <Grid item xs={12} md={2}>
                  <TextField
                     id="rut"
                     label="Folio_Interno"
                     type="text"
                     fullWidth
                     variant="outlined"
                     size="small"
                     // defaultValue="jmora@test.cl"
                  />
               </Grid>
               <Grid item xs={12} md={2}>
                  <TextField
                     id="rut"
                     label="Folio_Interno2"
                     type="text"
                     fullWidth
                     variant="outlined"
                     size="small"
                     // defaultValue="jmora@test.cl"
                  />
               </Grid>
               <Grid item xs={12} md={2}>
                  <TextField
                     id="rut"
                     label="Fecha de Embarque"
                     type="text"
                     fullWidth
                     variant="outlined"
                     size="small"
                     // defaultValue="jmora@test.cl"
                  />
               </Grid>
               <Grid item xs={12} md={2}>
                  <TextField
                     id="rut"
                     label="Saldo"
                     type="text"
                     fullWidth
                     variant="outlined"
                     size="small"
                     // defaultValue="jmora@test.cl"
                  />
               </Grid>
               <Grid item xs={12} md={4}>
                  <TextField
                     id="rut"
                     label="Monto Cuenta tercero"
                     type="text"
                     fullWidth
                     variant="outlined"
                     size="small"
                     // defaultValue="jmora@test.cl"
                  />
               </Grid>
            </Grid>

            <Grid item xs={12} md={12} mt={3}>
               <Box>
                  <Typography
                     variant="h6"
                     gutterBottom
                     className="tittle2"
                     align="center"
                  >
                     INSERTAR LINEAS DE REFERENCIAS
                  </Typography>
               </Box>
            </Grid>

            <Paper sx={{ width: "100%", overflow: "hidden" }}>
               <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {columns2.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{ minWidth: column.minWidth }}
                              >
                                 {column.label}
                              </TableCell>
                           ))}
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        <TableRow>
                           <TableCell>
                              <FormControl fullWidth>
                                 <Autocomplete
                                    disablePortal
                                    size="small"
                                    id="combo-box-demo"
                                    options={traslado}
                                    // sx={{ bgcolor: '#F3F3F3'}}
                                    renderInput={(params) => (
                                       <TextField
                                          {...params}
                                          label=" Traslado"
                                       />
                                    )}
                                 />
                              </FormControl>
                           </TableCell>

                           <TableCell>
                              <TextField
                                 id="rut"
                                 // label="ORDEN DE COMPRA"
                                 type="text"
                                 fullWidth
                                 variant="outlined"
                                 size="small"
                                 // defaultValue="jmora@test.cl"
                              />
                           </TableCell>

                           <TableCell>
                              <LocalizationProvider
                                 dateAdapter={AdapterDateFns}
                              >
                                 <Stack spacing={3}>
                                    <DatePicker
                                       // views={['year', 'month']}
                                       label="Fecha"
                                       minDate={new Date("2012-03-01")}
                                       maxDate={new Date("2023-06-01")}
                                       value={value}
                                       onChange={(newValue) => {
                                          setValue(newValue);
                                       }}
                                       renderInput={(params) => (
                                          <TextField
                                             {...params}
                                             helperText={null}
                                          />
                                       )}
                                    />
                                 </Stack>
                              </LocalizationProvider>
                           </TableCell>

                           <TableCell>
                              <TextField
                                 id="rut"
                                 // label="ORDEN DE COMPRA"
                                 type="text"
                                 fullWidth
                                 variant="outlined"
                                 size="small"
                                 // defaultValue="jmora@test.cl"
                              />
                           </TableCell>

                           <TableCell>
                              <Button
                                 type="submit"
                                 variant="contained"
                                 // onClick={handleSubmit}
                                 // disabled={loading}
                                 disableElevation
                                 // component={Link} to={ROUTE_EV_QUOTE_BOOK_RESUME}
                                 className="btn_opcion"
                              >
                                 <span>AGREGAR</span>
                              </Button>
                              <Button
                                 type="submit"
                                 variant="contained"
                                 // onClick={handleSubmit}
                                 // disabled={loading}
                                 disableElevation
                                 // component={Link} to={ROUTE_EV_QUOTE_BOOK_RESUME}
                                 className="btn_opcion1"
                                 style={{ marginLeft: 10 }}
                              >
                                 <span>ELIMINAR</span>
                              </Button>
                           </TableCell>
                        </TableRow>
                     </TableBody>
                  </Table>
               </TableContainer>
            </Paper>

            <Paper sx={{ width: "100%", overflow: "hidden" }}>
               <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {columns3.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{ minWidth: column.minWidth }}
                              >
                                 {column.label}
                              </TableCell>
                           ))}
                           <TableCell>Opciones</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData2
                           .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                           )
                           .map((row) => {
                              return (
                                 <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.numero}
                                 >
                                    {columns3.map((column) => {
                                       const value = row[column.id];
                                       return (
                                          <TableCell
                                             key={column.id}
                                             align={column.align}
                                          >
                                             {column.format &&
                                             typeof value === "number"
                                                ? column.format(value)
                                                : value}
                                          </TableCell>
                                       );
                                    })}
                                    <TableCell>
                                       <Checkbox {...label} />

                                       <IconButton>
                                          <EditIcon
                                             style={{ fontSize: "20px" }}
                                             onClick={handleOpenEdit}
                                          />
                                       </IconButton>

                                       <IconButton>
                                          <AddCircleIcon
                                             style={{ fontSize: "20px" }}
                                             onClick={handleOpenAdd}
                                          />
                                       </IconButton>
                                    </TableCell>
                                 </TableRow>
                              );
                           })}
                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsData2.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>

            <Typography
               variant="h6"
               gutterBottom
               mb={3}
               className="tittle2"
               align="center"
               mt={3}
            >
               INSERTAR LINEAS DE DETALLE
            </Typography>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
               <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {columns1.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{ minWidth: column.minWidth }}
                              >
                                 {column.label}
                              </TableCell>
                           ))}
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        <TableRow>
                           <TableCell>
                              <div
                                 style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                 }}
                              >
                                 <IconButton onClick={handleOpenArticulo}>
                                    <SearchIcon />
                                 </IconButton>
                                 <TextField
                                    id="rut"
                                    // label="ORDEN DE COMPRA"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    // defaultValue="jmora@test.cl"
                                 />
                              </div>
                           </TableCell>
                           <TableCell>
                              <TextField
                                 id="rut"
                                 // label="ORDEN DE COMPRA"
                                 type="text"
                                 fullWidth
                                 variant="outlined"
                                 size="small"
                                 // defaultValue="jmora@test.cl"
                              />
                           </TableCell>
                           <TableCell>
                              <TextField
                                 id="rut"
                                 // label="ORDEN DE COMPRA"
                                 type="text"
                                 fullWidth
                                 variant="outlined"
                                 size="small"
                                 // defaultValue="jmora@test.cl"
                              />
                           </TableCell>
                           <TableCell>
                              <TextField
                                 id="rut"
                                 // label="ORDEN DE COMPRA"
                                 type="text"
                                 fullWidth
                                 variant="outlined"
                                 size="small"
                                 // defaultValue="jmora@test.cl"
                              />
                           </TableCell>
                           <TableCell>
                              <FormControl fullWidth>
                                 <InputLabel id="demo-simple-select-label">
                                    %
                                 </InputLabel>
                                 <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    // label="fdffd%"
                                    onChange={handleChangeto}
                                    size="small"
                                 >
                                    <MenuItem value={10}>%</MenuItem>
                                    <MenuItem value={20}>$</MenuItem>
                                 </Select>
                              </FormControl>
                           </TableCell>
                           <TableCell>
                              <TextField
                                 id="rut"
                                 // label="ORDEN DE COMPRA"
                                 type="text"
                                 fullWidth
                                 variant="outlined"
                                 size="small"
                                 defaultValue="0"
                              />
                           </TableCell>
                           <TableCell>
                              <TextField
                                 id="rut"
                                 // label="ORDEN DE COMPRA"
                                 type="text"
                                 fullWidth
                                 variant="outlined"
                                 size="small"
                                 // defaultValue="jmora@test.cl"
                              />
                           </TableCell>
                           <TableCell>
                              <Button
                                 type="submit"
                                 variant="contained"
                                 // onClick={handleSubmit}
                                 // disabled={loading}
                                 disableElevation
                                 // component={Link} to={ROUTE_EV_QUOTE_BOOK_RESUME}
                                 className="btn_opcion"
                              >
                                 <span>AGREGAR</span>
                              </Button>
                              <Button
                                 type="submit"
                                 variant="contained"
                                 // onClick={handleSubmit}
                                 // disabled={loading}
                                 disableElevation
                                 // component={Link} to={ROUTE_EV_QUOTE_BOOK_RESUME}
                                 className="btn_opcion1"
                                 style={{ marginLeft: 10 }}
                              >
                                 <span>LIMPIAR</span>
                              </Button>
                           </TableCell>
                        </TableRow>
                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>

            <Paper sx={{ width: "100%", overflow: "hidden" }}>
               <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{ minWidth: column.minWidth }}
                              >
                                 {column.label}
                              </TableCell>
                           ))}
                           <TableCell>Opciones</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData
                           .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                           )
                           .map((row) => {
                              return (
                                 <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.numero}
                                 >
                                    {columns.map((column) => {
                                       const value = row[column.id];
                                       return (
                                          <TableCell
                                             key={column.id}
                                             align={column.align}
                                          >
                                             {column.format &&
                                             typeof value === "number"
                                                ? column.format(value)
                                                : value}
                                          </TableCell>
                                       );
                                    })}
                                    <TableCell>
                                       <Checkbox {...label} />

                                       <IconButton>
                                          <EditIcon
                                             style={{ fontSize: "20px" }}
                                             onClick={handleOpenEdit}
                                          />
                                       </IconButton>

                                       <IconButton>
                                          <AddCircleIcon
                                             style={{ fontSize: "20px" }}
                                             onClick={handleOpenAdd}
                                          />
                                       </IconButton>
                                    </TableCell>
                                 </TableRow>
                              );
                           })}
                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>

            <Grid item xs={12} mt={2}>
               <div className="bg-tittle2">
                  <Typography
                     mt={2}
                     variant="h6"
                     align="center"
                     className="tittle-text"
                  >
                     Resumen del Documento
                  </Typography>
               </div>
               <Grid container spacing={2} mt={1} className="margin">
                  {/* Columna 1 */}
                  <Grid item xs={12} sm={3}>
                     <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <FormControl fullWidth>
                           <TextField
                              id="outlined-multiline-static"
                              label="Insertar Observación"
                              multiline
                              rows={5}
                              size="small"
                           />
                        </FormControl>
                     </Box>
                  </Grid>
                  {/* Columna 2 */}
                  <Grid item xs={12} sm={1}>
                     <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <FormControl fullWidth>
                           <InputLabel id="demo-simple-select-helper-label">
                              Tipo
                           </InputLabel>
                           <Select
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              value={selectTypeResume}
                              label="Tipo"
                              size="small"
                              onChange={handleChangeTypeResume}
                           >
                              <MenuItem value="Centro Costo">
                                 <em>None</em>
                              </MenuItem>
                              <MenuItem value={1}>%</MenuItem>
                              <MenuItem value={2}>$</MenuItem>
                           </Select>
                        </FormControl>
                     </Box>
                  </Grid>
                  {/* Columna 3 */}
                  <Grid item xs={12} sm={2}>
                     <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <FormControl fullWidth>
                           <TextField
                              id="input-with-sx"
                              label="Descuento"
                              onChange={handleChangeto}
                              size="small"
                              placeholder="Cargando..."
                           />
                        </FormControl>
                     </Box>
                  </Grid>
                  {/* Columna 4 */}
                  <Grid item xs={12} sm={3}>
                     <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <FormControl fullWidth>
                           <TextField
                              id="input-with-sx"
                              size="small"
                              label="Subtotal"
                              onChange={handleChangeto}
                              placeholder="Cargando..."
                           />
                        </FormControl>
                     </Box>
                     <br />
                     <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <FormControl fullWidth>
                           <TextField
                              id="input-with-sx"
                              label="Descuento"
                              onChange={handleChangeto}
                              placeholder="Cargando..."
                           />
                        </FormControl>
                     </Box>
                  </Grid>
                  {/* Columna 5 */}
                  <Grid item xs={12} sm={3}>
                     <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <FormControl fullWidth>
                           <TextField
                              id="input-with-sx"
                              size="small"
                              label="Total Afecto"
                              onChange={handleChangeto}
                              placeholder="Cargando..."
                           />
                        </FormControl>
                     </Box>
                     <br />
                     <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <FormControl fullWidth>
                           <TextField
                              id="input-with-sx"
                              size="small"
                              label="19%"
                              onChange={handleChangeto}
                              placeholder="Cargando..."
                           />
                        </FormControl>
                     </Box>
                     <br />
                     <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <FormControl fullWidth>
                           <TextField
                              id="input-with-sx"
                              label="Exento"
                              size="small"
                              onChange={handleChangeto}
                              placeholder="Cargando..."
                           />
                        </FormControl>
                     </Box>
                     <br />
                     <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                        <FormControl fullWidth>
                           <TextField
                              id="input-with-sx"
                              label="Total"
                              size="small"
                              onChange={handleChangeto}
                              placeholder="Cargando..."
                           />
                        </FormControl>
                     </Box>
                  </Grid>
               </Grid>

               <Grid item xs={12} textAlign="end">
                  <Button variant="contained" className="btnSend">
                     Generar Descuento
                  </Button>
               </Grid>
            </Grid>
         </Container>

         <Modal
            open={openArticulo}
            onClose={handleCloseArticulo}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style}>
               <DataProductos entity={undefined} />
            </Box>
         </Modal>

         {/*<Modal
            open={openEdit}
            onClose={handleCloseEdit}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style}>
               <DataEditarCotizacion entity={undefined} />
            </Box>
         </Modal>*/}

         {/*<Modal
            open={openAdd}
            onClose={handleCloseAdd}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style}>
               <DataEditarCotizacion entity={undefined} />
            </Box>
         </Modal>*/}

         <Modal
            open={openInform}
            onClose={handleCloseInform}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style}>
               <DataInformsFinal entity={undefined} />
            </Box>
         </Modal>
      </Protected>
   );
};
