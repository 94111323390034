import { Protected } from "@/components/layout/Protected";
import { Box, Stepper, InputAdornment, IconButton, Step, StepLabel, Button, CircularProgress, Grid, CssBaseline, Container, StepIconProps, Typography, Tooltip, FormControl } from '@mui/material'
import { FunctionComponent, useEffect, useState } from "react";
import {Props} from "./GcCustomerProspect.type"
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import InfoIcon from '@mui/icons-material/Info';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { ContactBusiness } from "./ContactBusiness";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { FormComercial } from "./FormComercial/FormComercial";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import ArticleIcon from '@mui/icons-material/Article';
import Modal from '@mui/material/Modal';
import { DataModalActiveBienes } from "./DataModalActiveBienes";
import { DataModalContacto } from "./DataModalContacto";
import { DataModalCustumerProducts } from "./DataModalCustumerProducts";
import { FormGeneral } from "./FormGeneral/FormGeneral";


const steps= [{name:'Información General', icon:null},
{name:'Información Comercial', icon:null},
{name:'Contactos Empresa', icon:null}]

interface Column {
   id: 'rut' | 'razonSocial' |'giro' | 'producto' | 'fecha'| 'cliente'|'telefono'| 'vendedor' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

const columns:readonly Column[] = [
   { id: 'rut', label: 'Rut', minWidth: 100 },
   { id: 'razonSocial', label: 'Razón Social', minWidth: 100 },
   { id: 'giro', label: 'Giro', minWidth: 100 },
   { id: 'producto', label: 'Producto', minWidth: 100 },
   { id: 'fecha', label: 'Fecha', minWidth: 100 },
   { id: 'cliente', label: 'Cliente', minWidth: 100 },
   { id: 'telefono', label: 'Telefono', minWidth: 100 },
   { id: 'vendedor', label: 'Vendedor', minWidth: 100 },
   ];

interface Data {
   rut: string;
   razonSocial: string;
   giro: string;
   producto: string;
   fecha: string;
   cliente: string;
   telefono: string;
   vendedor: string;
}

function createDataCustomersProspect(
   rut: string,
   razonSocial: string,
   giro: string,
   producto: string,
   fecha: string,
   cliente: string,
   telefono: string,
   vendedor: string,
): Data {
   return { rut, razonSocial, giro, producto,fecha, cliente, telefono, vendedor };
}

const rowsDataCustomerProspect = [
   createDataCustomersProspect('112', 'Empresa 1', 'Giro 1', 'Producto 1', '02/12/2020', 'Cliente 1', '984857891', 'Juan Perez'),
   createDataCustomersProspect('214', 'Empresa 2', 'Giro 2', 'Producto 2', '02/12/2020', 'Cliente 2', '984857892', 'Maria Perez'),
   createDataCustomersProspect('354', 'Empresa 3', 'Giro 3', 'Producto 3', '02/12/2020', 'Cliente 3', '984857893', 'Pedro Perez'),
   createDataCustomersProspect('445', 'Empresa 4', 'Giro 4', 'Producto 4', '02/12/2020', 'Cliente 4', '984857894', 'Juan Perez'),
   createDataCustomersProspect('554', 'Empresa 5', 'Giro 5', 'Producto 5', '02/12/2020', 'Cliente 5', '984857895', 'Maria Perez'),
   createDataCustomersProspect('645', 'Empresa 6', 'Giro 6', 'Producto 6', '02/12/2020', 'Cliente 6', '984857896', 'Pedro Perez'),
]


export const GcCustomerProspect:React.FC<Props> = (props: Props): JSX.Element => {
    
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [error, setError] = useState(null);
    const [page , setPage]= useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openContact, setOpenContact] = useState(false);
    const [openProduct, setOpenProduct] = useState(false);
    const [openBienes, setOpenBienes] = useState(false);

    const handleOpenContact = () => setOpenContact(true);
    const handleCloseContact = () => setOpenContact(false);

    const handleOpenProduct = () => setOpenProduct(true);
    const handleCloseProduct = () => setOpenProduct(false);

    const handleOpenBienes = () => setOpenBienes(true);
    const handleCloseBienes = () => setOpenBienes(false);
    
    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };
    const [valueGeneral,setValueGeneral]=useState({
        rut:'',
        razon_social:'',
        giro_empresa:'',
        email:'',
        pagina_web:'',
        obserbacion:'',
        tipo:'',
        fantasia:'',
        telefono_fijo:'',
        fax:'',
        activo:'',
        direccion:'',
        ciudad:'',
        geocolizacion:'',
        comuna:'',
        pais_:'',
    });
    
    const [valueComercial,setValueComercial]=useState({
        forma_pago:'',
        vendedor:'',
        segmentacion:'',
        lista_precio:'',
        codigo_super_intencia:'',
        ruta_distribucion:'',
        trasporte_entrega:'',
        canal_venta:'',
        obserbacion_venta:'',
        obserbacion_cobranza:'',
    });

    const [valueContactBusiness,setValueContactBusiness]=useState({
         nombre_contacto:'',

    });
    const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 720,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

    useEffect(() => {
        if(props.entity !== undefined){
            setValueGeneral(props.entity);
            setValueComercial(props.entity);
            setValueContactBusiness(props.entity);
        }
    }, []);

    const isStepSkipped = (step) => {
        return skipped.has(step);
     };
  
     const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
           newSkipped = new Set(newSkipped.values());
           newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
     };
  
     const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
     };

     const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
          top: 22,
        },
        [`&.${stepConnectorClasses.active}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
              'linear-gradient( 95deg,#19c1d1 0%,#1976D2 50%,#1996d1 100%)',
          },
        },
        [`&.${stepConnectorClasses.completed}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
              'linear-gradient( 95deg,#19c1d1 0%,#1976D2 50%,#1996d1 100%)',
          },
        },
        [`& .${stepConnectorClasses.line}`]: {
          height: 3,
          border: 0,
          backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
          borderRadius: 1,
        },
      }));
  
      const ColorlibStepIconRoot = styled('div')<{
        ownerState: { completed?: boolean; active?: boolean };
      }>(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
          backgroundImage:
            'linear-gradient( 136deg, #1976D2 0%, #1996d1 50%, #19c1d1 100%)',
          boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
          backgroundImage:
            'linear-gradient( 136deg, #1976D2 0%, #1996d1 50%, #19c1d1 100%)',
        }),
      }));


      function ColorlibStepIcon(props: StepIconProps) {
        const { active, completed, className } = props;
  
        const icons: { [index: string]: React.ReactElement } = {
          1: <AssignmentIcon />,
          2:<AnalyticsIcon/>,
          3: <InfoIcon />          
        };
  
        return (
          <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
          </ColorlibStepIconRoot>
        );
      }

      const mostrarFormulario = () => {
        if (activeStep === 0) {
           return (
              <FormGeneral
                 handleNext={handleNext}
                 valueEntry={valueGeneral}
                 setvalueEntry={setValueGeneral}
              />
           )
        }
        else if (activeStep === 1) {
           return (
            <FormComercial
            handleNext={handleNext}
            valueEntry={valueComercial}
            setvalueEntry={setValueComercial}
            />
           )
        }
        else if (activeStep === 2) {
           return (
            <ContactBusiness
            handleNext={handleNext}
            valueEntry={valueContactBusiness}
            setvalueEntry={setValueContactBusiness}
            />
           )
        }
     }
    return (
        <Protected>
        <Container maxWidth='xl'>
           <Typography variant="h6" gutterBottom mb={3} className="tittle">
               MANTENEDOR DE PROSPECTOS CLIENTES
             </Typography>
           <Grid container flexDirection='row' spacing={2}>
                 <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                       <Box>
                          <Box sx={{ mb: 2 }}>
                             <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
                                {steps.map((label, index) => {
                                   return (
                                      <Step key={index}>
                                         <StepLabel StepIconComponent={ColorlibStepIcon} error={error === `Usuario${index}noencontrado` ? true : false}>{label.name}</StepLabel>
                                      </Step>
                                   );
                                })}
                             </Stepper>
                          </Box>
                          {activeStep === steps.length ? (
                             <div>AGREGADO</div>
                             //VALIDAR QUE EL ENVIO DE DATOS
                             // history.push(ROUTE_ACCOUNT)
                          ) : (
                             mostrarFormulario()
                          )}
                       </Box>
                    </Grid>
                 </Grid>
           </Grid>
           
           <div className='bg-tittle'>
                        <Typography 
                        mt={3} 
                        variant="h6"
                        align='center'
                        className='tittle-text'
                        >LISTADO DE PROSPECTOS
                        </Typography>
         </div>
         </Container>

         <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:440}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           <TableCell>Rut</TableCell>
                           <TableCell>Razon Social</TableCell>
                           <TableCell>Giro</TableCell>
                           <TableCell>Producto</TableCell>
                           <TableCell>Fecha</TableCell>
                           <TableCell>Cliente</TableCell>
                           <TableCell>Telefono</TableCell>
                           <TableCell>Vendedor</TableCell>
                           <TableCell>Opciones</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsDataCustomerProspect.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.rut}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell >
                                    <div>
                                       <IconButton 
                                             >
                                                <RemoveRedEyeIcon
                                             onClick={handleOpenContact}
                                          />
                                          </IconButton>

                                          <IconButton 
                                             >
                                                <AddCircleIcon
                                             onClick={handleOpenProduct}
                                          />
                                       </IconButton>
                                    </div>
                                      
                                    <div>
                                       <IconButton 
                                          >
                                             <PriceCheckIcon
                                          onClick={handleOpenBienes}
                                       />
                                       </IconButton>

                                       <IconButton 
                                          >
                                             <ArticleIcon
                                          // onClick={handleOpen}
                                       />
                                       </IconButton>
                                    </div>
                                 </TableCell>
                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsDataCustomerProspect.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
         </Paper>
            <Modal
                  open={openBienes}
                  onClose={handleCloseBienes}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <DataModalActiveBienes entity={undefined}/>
                  </Box>
            </Modal>
            <Modal
                  open={openContact}
                  onClose={handleCloseContact}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <DataModalContacto entity={undefined}/>
                  </Box>
            </Modal>
            <Modal
                  open={openProduct}
                  onClose={handleCloseProduct}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <DataModalCustumerProducts entity={undefined}/>
                  </Box>
            </Modal>
     </Protected>

    );
};

