import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "../Monthly.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import '../Monthly_style.css'
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { PictureAsPdf } from "@mui/icons-material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';

interface Column {
   id: 'nombre' | 'cantidad' | 'precio' | 'porcentaje';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

const columns:readonly Column[] = [
   { id: 'nombre', label: 'Nombre', minWidth: 100 },
   { id: 'cantidad', label: 'Cantidad', minWidth: 100 },
   { id: 'precio', label: 'Precio', minWidth: 100 },
   { id: 'porcentaje', label: 'Porcentaje', minWidth: 100 },
   ];

interface Data {
   nombre: string;
   cantidad: string;
   precio: string;
   porcentaje: string;

}

function createData(
   nombre: string,
   cantidad: string,
   precio: string,
   porcentaje: string,
   ):Data {
   return { nombre, cantidad, precio, porcentaje };
}

const rowsData = [
   createData('Facturada', '1', '$1.000', '10%'),
   createData('Enviada Email', '1', '$1.000', '80%'),
   createData('Generada / Abiertas', '1', '$1.000', '10%'),
]


export const MonthAndYear:React.FC<Props> = (props: Props): JSX.Element => {
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value2, setValue2] = useState('');
   const [loading, setLoading] = useState(false);
   const [familyProduct, setFamilyProduct] = useState('');
   const [estado, setEstado]= useState('');
   const [value, setValue] = useState<Date | null>(new Date());

    const handleChangeFamilyProduct = (event: SelectChangeEvent) => {
      setFamilyProduct(event.target.value);
    };
    const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
    };
   function handleClick() {
     setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };


    return (
        <Container maxWidth='xl'>
           <Grid container spacing={3}>
           <Grid item xs={12} md={12} >
                     <Box>
                        <Typography variant='h6' component='h1' gutterBottom className="tittle">
                           RESUMEN DE EQUIPO DE VENTA
                        </Typography>
                     </Box>
                     <Box>
                        <Typography variant='caption'  gutterBottom >
                        Informe de gestion de Ventas Mensual (04 - 2022)	                     </Typography>
                     </Box>
            </Grid>
            <Grid item xs={12} md={5}>
               <Grid container spacing={3}>

                  <Grid item xs={12} md={12} >
                     <Grid container spacing={3}>
                        <Grid item xs={12} md={4} ml={3} mt={1}>
                           <Box>
                              <Typography variant='caption'  gutterBottom >
                              PRESUPUESTO DEL MES (BUDGET)
                              </Typography>
                           </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                              <TextField
                                 id="outlined-basic"
                                 // label="Presupuesto"
                                 variant="outlined"
                                 defaultValue="$ 0"
                                 size='small'
                                 // fullWidth
                                 // value={value2}
                                 // onChange={(e) => setValue2(e.target.value)}
                              />
                        </Grid>
                     </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} >
                     <Grid container spacing={3}>
                        <Grid item xs={12} md={4} ml={3} mt={1}>
                           <Box>
                              <Typography variant='caption'  gutterBottom >
                              DIAS HABILES DEL MES
                              </Typography>
                           </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                              <TextField
                                 id="outlined-basic"
                                 // label="Presupuesto"
                                 variant="outlined"
                                 defaultValue="19"
                                 size='small'
                                 // fullWidth
                                 // value={value2}
                                 // onChange={(e) => setValue2(e.target.value)}
                              />
                        </Grid>
                     </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} >
                     <Grid container spacing={3}>
                        <Grid item xs={12} md={4} ml={3} mt={1}>
                           <Box>
                              <Typography variant='caption'  gutterBottom >
                              FACTURACION A LA FECHA
                              </Typography>
                           </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                              <TextField
                                 id="outlined-basic"
                                 // label="Presupuesto"
                                 variant="outlined"
                                 defaultValue="$ 334.292"
                                 size='small'
                                 // fullWidth
                                 // value={value2}
                                 // onChange={(e) => setValue2(e.target.value)}
                              />
                        </Grid>
                     </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} >
                     <Grid container spacing={3}>
                        <Grid item xs={12} md={4} ml={3} mt={1}>
                           <Box>
                              <Typography variant='caption'  gutterBottom >
                              META (BUDGET) DE FACTURACION A LA FECHA
                              </Typography>
                           </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                              <TextField
                                 id="outlined-basic"
                                 // label="Presupuesto"
                                 variant="outlined"
                                 defaultValue="$ 0"
                                 size='small'
                                 // fullWidth
                                 // value={value2}
                                 // onChange={(e) => setValue2(e.target.value)}
                              />
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>

            <Grid item xs={12} md={7}>
               <Grid container spacing={3}>
                  <Grid item xs={12} md={12} mt={1}>
                           <Box>
                              <Typography variant='subtitle2' style={{color:'#16556b'}} gutterBottom >
                              RESUMEN DE NOTA DE VENTA
                              </Typography>
                           </Box>
                  </Grid>
                  <Grid item xs={12} md={12} >
                     <Grid container spacing={3}>
                        <Grid item xs={12} md={3} mt={1}>
                           <Box>
                              <Typography variant='caption'  gutterBottom >
                              NOTA DE VENTAS EMITIDAS EL 04-2022
                              </Typography>
                           </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                              <TextField
                                 id="outlined-basic"
                                 // label="Presupuesto"
                                 variant="outlined"
                                 defaultValue="$ 28.156"
                                 size='small'
                                 // fullWidth
                                 // value={value2}
                                 // onChange={(e) => setValue2(e.target.value)}
                              />
                        </Grid>
                     </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} >
                     <Grid container spacing={3}>
                        <Grid item xs={12} md={3} mt={1}>
                           <Box>
                              <Typography variant='caption'  gutterBottom >
                              (11) EN PRODUCCION
                              </Typography>
                           </Box>
                        </Grid>
                        <Grid item xs={12} md={3}>
                              <TextField
                                 id="outlined-basic"
                                 // label="Presupuesto"
                                 variant="outlined"
                                 defaultValue="1"
                                 size='small'
                                 fullWidth
                                 // value={value2}
                                 // onChange={(e) => setValue2(e.target.value)}
                              />
                        </Grid>
                        <Grid item xs={12} md={3}>
                              <TextField
                                 id="outlined-basic"
                                 // label="Presupuesto"
                                 variant="outlined"
                                 defaultValue="$ 28.156"
                                 size='small'
                                 fullWidth
                                 // value={value2}
                                 // onChange={(e) => setValue2(e.target.value)}
                              />
                        </Grid>
                        <Grid item xs={12} md={3}>
                              <TextField
                                 id="outlined-basic"
                                 // label="Presupuesto"
                                 variant="outlined"
                                 defaultValue="100 %"
                                 size='small'
                                 // fullWidth
                                 // value={value2}
                                 // onChange={(e) => setValue2(e.target.value)}
                              />
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
               <Grid item xs={12} md={3} mt={1}>
                        <Box>
                           <Typography variant='subtitle2' style={{color:'#16556b'}} mb={2} gutterBottom >
                           RESUMEN DE COTIZACIONES
                           </Typography>
                        </Box>
               </Grid>

            </Grid>
            
   
            <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:220}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                              <TableCell
                                 >
                                    COTIZACIONES EMITIDAS EL 04-2022
                              </TableCell>
                              <TableCell colSpan={3}
                                 >
                                 $ 28.959 (6)
                              </TableCell>
                           
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.nombre}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                              

                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
            </Paper>

         </Container>
     
    );
   };

