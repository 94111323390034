import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "../PaymentWorker/PaymentWorker.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './PaymentWorker_styles.css'
import Modal from '@mui/material/Modal';
// import { GestionPayment } from './TypeData/GestionPayment'
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import { ROUTE_RM_PAYMENT_WORKER } from '@/toolbox/constants/route-map';

const clasificacionesCompras = [
   { label: 'Banco Chile 123456789', value: 'banco1' },
   { label: 'Banco de x 123456789', value: 'banco2' },
   { label: 'Banco de y 123456789', value: 'banco3' },
   { label: 'Banco de z 123456789', value: 'banco4' },
]
const sucursales=[
   { label: 'Sucursal 1', value: 'sucursal1' },
   { label: 'Sucursal 2', value: 'sucursal2' },
   { label: 'Sucursal 3', value: 'sucursal3' },
   { label: 'Sucursal 4', value: 'sucursal4' },
]
export const FormInitialize:React.FC<Props> = (props: Props): JSX.Element => {
   const [value, setValue] = useState<Date | null>(new Date());
   const [openAbsense, setOpenAbsense] = useState(false);
   const [openTardie, setOpenTardie] = useState(false);

   const handleOpenAbsense = () => {
      setOpenAbsense(true);
      setOpenTardie(false);
   }
   const handleCloseAbsense = () => setOpenAbsense(false);

   const handleOpenTardie = () => {
      setOpenTardie(true);
      setOpenAbsense(false);
   }
   const handleCloseTardie = () => setOpenTardie(false);

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 920,
      height:650,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };
    return (
        <Protected>
        <Container maxWidth='xl'>
           <Grid container spacing={3} mb={6}>
               <Grid item xs={12} md={6} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        GESTION DE PAGO
                     </Typography>
                  </Box>
                  <Typography  variant="caption" display="block" gutterBottom mt={1}>
                  Para gestionar los pagos de liquidaciones, seleccione los siguientes datos	
                  </Typography>
                  
               </Grid>
               <Grid item xs={12}>
                  <Grid container spacing={3}>
                     <Grid item xs={12} md={8}>
                        <Typography variant='subtitle2'  gutterBottom  align="center" style={{background:'#ff9700', color:'#ffffff', borderRadius:'5px'}}  p={1}>
                           MES Y PERIODO
                        </Typography>
                     </Grid>
                     
                     <Grid item xs={12} md={8}>
                        <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={clasificacionesCompras}
                        renderInput={(params) => <TextField {...params} label="Clasificacion de Compra" />}
                        />
                     </Grid>

                     <Grid item xs={12} md={8}>
                        <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={sucursales}
                        renderInput={(params) => <TextField {...params} label="Sucursal" />}
                        />
                     </Grid>
                     
                     <Grid item xs={12} md={5}>
                        <Button
                           type='submit'
                           variant='contained'
                           component={Link} to={ROUTE_RM_PAYMENT_WORKER}
                           // disabled={loading}
                           disableElevation
                           // fullWidth
                           className='btn_next'
                           style={{backgroundColor:'#16556b', color:'#fff'}}
                           >
                           <span>SIGUIENTE</span>
                        </Button>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>

         </Container>
     </Protected>
    );
   };

