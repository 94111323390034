import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, FormControl, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, } from '@mui/material';
import {  ArrowRightIcon } from "@toolbox/constants/icons";
import { Props } from './FormDataEntry.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import './style.sass'
import "./FormDataEntry_style.css";
import BadgeIcon from '@mui/icons-material/Badge';
import BusinessIcon from '@mui/icons-material/Business';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';

export const FormDataEntry: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false)
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
    );
   const [estado, setEstado] = useState<any>({ rut: false, nombre_rz: false })

   const [valueRevalorizable, setValueRevalorizable] = React.useState('Si');
   const [valueDepreciable, setValueDepreciable] = React.useState('Si');
   const [valueTipoDepreciacion, setValueTipoDepreciacion] = React.useState('Lineal');
   const [valueUnidadMedina, setValueUnidadMedina] = React.useState('Mensual');


   const handleChangeRevalorizable = (event: React.ChangeEvent<HTMLInputElement>) => {
     setValueRevalorizable((event.target as HTMLInputElement).value);
   };

   const handleChangeDepreciable = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueDepreciable((event.target as HTMLInputElement).value);
    };
    const handleTipoDepreciacion = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueTipoDepreciacion((event.target as HTMLInputElement).value);
    };
    const handleUnidadMedina = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueUnidadMedina((event.target as HTMLInputElement).value);
    };
   
   const areaNegocios = [
      { label: 'Negocio 1'},
      { label: 'Negocio 2'},
      { label: 'Negocio 3'},
      { label: 'Negocio 4'}
   ]
   const centroCosto = [
      { label: 'Centro de Costo 1'},
      { label: 'Centro de Costo 2'},
      { label: 'Centro de Costo 3'}
   ]
  
   const rules = useMemo(() => ({
      resumen: [
         VALIDATORS.REQUIRED,
      ],
      fecha: [
         VALIDATORS.REQUIRED,
      ],
      detalle: [
         VALIDATORS.REQUIRED
      ],
      concepto_ingreso: [
         VALIDATORS.REQUIRED,
      ],
      area_negocio: [
         VALIDATORS.REQUIRED,
      ],
      centro_costo: [
         VALIDATORS.REQUIRED,
      ],
      ubicacion: [
         VALIDATORS.REQUIRED,
      ],
      fecha_vencimiento: [
         VALIDATORS.REQUIRED,
      ],
      revalorizable: [
         VALIDATORS.REQUIRED,
      ],
      tipo_depreciacion: [
         VALIDATORS.REQUIRED,
      ],
      depreiable: [
         VALIDATORS.REQUIRED,
      ],
      unidad_medida: [
         VALIDATORS.REQUIRED,
      ]

   }), []);


   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'resumen':
            props.setvalueEntry(prev => ({ ...prev, resumen: value }));
            break;
         case 'fecha':
            props.setvalueEntry(prev => ({ ...prev, fecha: value }))
            break;
         case 'detalle':
            props.setvalueEntry(prev => ({ ...prev, detalle: value }))
            break;
         case 'concepto_ingreso':
            props.setvalueEntry(prev => ({ ...prev, concepto_ingreso: value }));
            break;
         case 'area_negocio':
            props.setvalueEntry(prev => ({ ...prev, area_negocio: value }));
            break;
         case 'centro_costo':
            props.setvalueEntry(prev => ({ ...prev, centro_costo: value }));
            break;
         case 'ubicacion':
            props.setvalueEntry(prev => ({ ...prev, ubicacion: value }));
            break;
         case 'fecha_vencimiento':
            props.setvalueEntry(prev => ({ ...prev, fecha_vencimiento: value }));
            break;
         case 'revalorizable':
            props.setvalueEntry(prev => ({ ...prev, revalorizable: value }));
            break;
         case 'tipo_depreciacion':
            props.setvalueEntry(prev => ({ ...prev, tipo_depreciacion: value }));
            break;
         case 'depreiable':
            props.setvalueEntry(prev => ({ ...prev, depreiable: value }));
            break;
         case 'unidad_medida':
            props.setvalueEntry(prev => ({ ...prev, unidad_medida: value }));
            break;
         default:
            break;
      }
   };
   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      props.setvalueEntry(prev => ({ ...prev, [name]: newVal }));
   }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      props.handleNext();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }

   return (
      <Container maxWidth="xl">
         <form >
            <Grid container spacing={3} className={`e-mb-lg`}>
         
               <Grid item xs={12} sm={3}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                     <BadgeIcon />
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="resumen"
                     type='text'
                     placeholder="Resumida"
                     value={props.valueEntry.resumen}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.resumen}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />

               </Grid>

               <Grid item xs={12} sm={3}>
                  <Input
                     ref={ref => inputRefs.current[1] = ref}
                      prependInnerAdornment={
                     <InputAdornment position="end">
                     <BusinessIcon />
                    </InputAdornment>
                    }
                     name="detalle"
                     placeholder="Detallada"
                     value={props.valueEntry.detalle}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.detalle}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
                  <Input
                     ref={ref => inputRefs.current[2] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <BusinessIcon />
                     </InputAdornment>
                    }
                     name="concepto_ingreso"
                     placeholder="Concepto Ingreso"
                     //value={data.nombre_rz}
                     value={props.valueEntry.concepto_ingreso}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     // backgroundColor="#F3F3F3"
                     rules={rules.concepto_ingreso}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
                  <Input
                     ref={ref => inputRefs.current[5] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">
                     <BusinessIcon />
                     </InputAdornment>
                    }
                     name="ubicacion"
                     placeholder="Ubicacion"
                     //value={data.nombre_rz}
                     value={props.valueEntry.ubicacion}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     // backgroundColor="#F3F3F3"
                     rules={rules.ubicacion}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={areaNegocios}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Area de Negocios" />}
                  />
               </Grid>
   
               <Grid item xs={12} sm={3}>
               <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={centroCosto}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Centro Costo" />}
                  />
               </Grid>

               <Grid item xs={12} sm={3} >
               <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                  label="Fecha"
                  value={value}
                  onChange={(newValue) => {
                     setValue(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  />
               </LocalizationProvider>
               </Grid>

               <Grid item xs={12} sm={3} >
               <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                  label="Fecha de Vencimiento"
                  value={value}
                  onChange={(newValue) => {
                     setValue(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  />
               </LocalizationProvider>
               </Grid>

               <Grid item xs={12} sm={3} >
                  <FormControl>
                     <FormLabel id="demo-controlled-radio-buttons-group">Revalorizable</FormLabel>
                     <RadioGroup
                     aria-labelledby="demo-controlled-radio-buttons-group"
                     name="controlled-radio-buttons-group"
                     value={valueRevalorizable}
                     onChange={handleChangeRevalorizable}
                     >
                     <FormControlLabel value="Si" control={<Radio />} label="Si" />
                     <FormControlLabel value="No" control={<Radio />} label="No" />
                     </RadioGroup>
                  </FormControl>
               </Grid>

               <Grid item xs={12} sm={3} >
                  <FormControl>
                     <FormLabel id="demo-controlled-radio-buttons-group">Depreciable</FormLabel>
                     <RadioGroup
                     aria-labelledby="demo-controlled-radio-buttons-group"
                     name="controlled-radio-buttons-group"
                     value={valueDepreciable}
                     onChange={handleChangeDepreciable}
                     >
                     <FormControlLabel value="Si" control={<Radio />} label="Si" />
                     <FormControlLabel value="No" control={<Radio />} label="No" />
                     </RadioGroup>
                  </FormControl>
               </Grid>

               <Grid item xs={12} sm={3} >
                  <FormControl>
                     <FormLabel id="demo-controlled-radio-buttons-group">Tipo de Depreciacion</FormLabel>
                     <RadioGroup
                     aria-labelledby="demo-controlled-radio-buttons-group"
                     name="controlled-radio-buttons-group"
                     value={valueTipoDepreciacion}
                     onChange={handleTipoDepreciacion}
                     >
                     <FormControlLabel value="Lineal" control={<Radio />} label="Lineal" />
                     <FormControlLabel value="Acelerada" control={<Radio />} label="Acelerada" />
                     </RadioGroup>
                  </FormControl>
               </Grid>

               <Grid item xs={12} sm={3} >
                  <FormControl>
                     <FormLabel id="demo-controlled-radio-buttons-group">Unidad de Medida</FormLabel>
                     <RadioGroup
                     aria-labelledby="demo-controlled-radio-buttons-group"
                     name="controlled-radio-buttons-group"
                     value={valueUnidadMedina}
                     onChange={handleUnidadMedina}
                     >
                     <FormControlLabel value="Mensual" control={<Radio />} label="Mensual" />
                     <FormControlLabel value="Unidad de Produccion" control={<Radio />} label="Unidad de Produccion" />
                     </RadioGroup>
                  </FormControl>
               </Grid>
              
               <Grid item container
                  xs={12}
                 justifyContent='right'
                 >
                     <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                        className='btn_save'
                        endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>Siguiente</span>
                        }
                     </Button>

                     </Grid>
               </Grid>
            </Grid>
         </form>
      </Container>
   );
}
