import React,{ FunctionComponent, useState } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './GcRutaDepacho_styles.css';
import TextField from '@mui/material/TextField';
import { Box, Button, Grid,Typography,Autocomplete, IconButton, Modal } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { fontSize } from "@mui/system";
import { Protected } from "@/components/layout/Protected";
import DateRangePicker, { DateRange } from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Container, } from '@mui/material'
import { DoNotDisturb, LocalPrintshop } from "@mui/icons-material";
import DownloadIcon from '@mui/icons-material/Download';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Stack from '@mui/material/Stack';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import { ModalButton } from "./ModalButton";

const optionsDocuments = [
   { label:'FACTURA ELECTRONICA', value:'FACTURA ELECTRONICA' },
   { label:'NOTA DE VENTA', value:'NOTA DE VENTA' },
]
const optionsRuta = [
   { label:'Huechuraba', value:'Huechuraba' },
   { label:'Linares', value:'Linares' },
   { label:'Rancagua', value:'Rancagua' },

]

const style = {
   position: 'absolute' as 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   width: 250,
   bgcolor: 'background.paper',
   // border: '2px solid #000',
   boxShadow: 24,
   p: 2,
   borderRadius: '20px'
};
interface TableMOnthProductProps {
    
}

interface Column {
    id: 'razonSocial' | 'vendedor' | 'formaPago'|'numeroDescuento'|'monto' | 'kilo'|'piezas'|'obs' ;
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
  }

interface Column2{
      id: 'codigo' | 'articulo' | 'kilo'|'piezas'|'obs' ;
      label: string;
      minWidth?: number;
      align?: 'right';
      format?: (value: number) => string;
   }
 const columns:readonly Column[] = [
      { id: 'razonSocial', label: 'RAZON SOCIAL', minWidth: 100 },
      { id: 'vendedor', label: 'VENDEDOR', minWidth: 100 },
      { id: 'formaPago', label: 'FORMA DE PAGO', minWidth: 100 },
      { id: 'numeroDescuento', label: 'N DCTO', minWidth: 50 },
      { id: 'monto', label: 'MONTO $', minWidth: 50 },
      { id: 'kilo', label: 'KILOS', minWidth: 50 },
      { id: 'piezas', label: 'PIEZAS', minWidth: 80 },
      { id: 'obs', label: 'OBS', minWidth: 50 },
    ];
 
const columns2:readonly Column2[] = [
      { id: 'codigo', label: 'CODIGO', minWidth: 100 },
      { id: 'articulo', label: 'ARTICULO', minWidth: 100 },
      { id: 'kilo', label: 'KILOS', minWidth: 100 },
      { id: 'piezas', label: 'PIEZAS', minWidth: 100 },
      { id: 'obs', label: 'OBS', minWidth: 100 },
      ];
 interface Data {
      razonSocial: string;
      vendedor: string;
      formaPago: string;
      numeroDescuento: string;
      monto: string;
      kilo: string;
      piezas: string;
      obs: string;
 }

   interface Data2 {
      codigo: number;
      articulo: string;
      kilo: number;
      piezas: number;
      obs: string;
   }
 
 function createData1(
      razonSocial: string,
      vendedor: string,
      formaPago: string,
      numeroDescuento: string,
      monto: string,
      kilo: string,
      piezas: string,
      obs: string,
 ): Data {
    return { razonSocial, vendedor, formaPago, numeroDescuento, monto, kilo, piezas, obs };
 }

   function createData2(
      codigo: number,
      articulo: string,
      kilo: number,
      piezas: number,
      obs: string,
   ): Data2 {
      return { codigo, articulo, kilo, piezas, obs };
   }

 const rowsData1 = [
      createData1('2222222-2', 'Pancracio Canela', 'Debito', '125', '154', '5', '78', 'Obs'),
 ]

   const rowsData2 = [
      createData2(1, 'Pancracio Canela', 5, 78, 'Obs'),
   ]

 const StyledTableCell = styled(TableCell)(({ theme }) => ({
   [`&.${tableCellClasses.head}`]: {
     backgroundColor: '#c6ced1',
     fontSize: 14,
     color: theme.palette.common.black,
   },
   [`&.${tableCellClasses.body}`]: {
     fontSize: 14,
   },
 }));

 const StyledTableRow = styled(TableRow)(({ theme }) => ({
   '&:nth-of-type(odd)': {
     backgroundColor: theme.palette.action.hover,
   },
   // hide last border
   '&:last-child td, &:last-child th': {
     border: 0,
   },
 }));
 
export const GcRutaDepacho: FunctionComponent<TableMOnthProductProps> = () => {
    const [error, setError] = useState(null);
    const [page , setPage]= useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [show, setShow] = useState(false);
    const [showIcon, setShowIcon]= useState(false);
    const [value, setValue] = React.useState<Date | null>(
      new Date('2014-08-18T21:11:54'),
    );
   const [showModal, setShowModal] = useState(false);


   const showModalButton=()=>{
      setShowModal(true);
   }
   const closeModalButton=()=>{
      setShowModal(false);
   }

    const handleChangeShowIco=()=>{
      setShowIcon(!showIcon);
      }
  
    const handleChange = (newValue: Date | null) => {
      setValue(newValue);
    };
    
    const handleChangeShow = () => {
       setShow(!show);
      }
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
     };
  
     const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
     };
    return ( 
        <>
        <Protected >
        <Container maxWidth='xl'>
         <Grid container spacing={2}>

               <Grid item xs={8} md={10} textAlign="start" mb={1}>
                     <Typography mt={1} variant='h5' component='h1' gutterBottom className="tittle">
                     RUTA DE ENTREGA MERCADERIA
                     </Typography>
               </Grid>

               <Grid item xs={4} md={2} className="btn-download" mb={1}>
                     <Grid container spacing={2} alignContent="end">
                     
                        <Grid item xs={12} md={6}>
                           <Box>
                              Exportar
                              <LoadingButton
                              // color="warning"
                           //    onClick={handleClick}
                           //    loading={loading}
                              loadingPosition="center"
                              startIcon={<DownloadIcon />}
                              variant="contained"
                              style={{backgroundColor:'#16556b',color:'#ffffff', fontSize:'14px !important'}}
                              className="btn-download"
                              onClick={showModalButton}
                              >
                              </LoadingButton>
                           </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                           <Box>
                              Imprimir
                              <LoadingButton
                              // color="warning"
                           //    onClick={handleClick}
                           //    loading={loading}
                              loadingPosition="center"
                              startIcon={<LocalPrintshop />}
                              variant="contained"
                              style={{backgroundColor:'#16556b',color:'#ffffff', fontSize:'14px !important'}}
                              className="btn-download"
                              >
                              </LoadingButton>
                           </Box>
                        </Grid>

                     </Grid>
               </Grid>

               <Grid item xs={12} md={12}>
               Informes Mensuales
               </Grid>

               <Grid  item xs={12} md={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                     <Stack spacing={3}>
                        <DesktopDatePicker
                           label="Fecha"
                           inputFormat="MM/dd/yyyy"
                           value={value}
                           onChange={handleChange}
                           renderInput={(params) => <TextField {...params} />}
                        />
                     </Stack>
                  </LocalizationProvider>
               </Grid>

               <Grid item xs={12} md={3}>
                     <Autocomplete
                              disablePortal
                              size='small'
                              id="combo-box-demo"
                              options={optionsDocuments}
                              // sx={{ bgcolor: '#F3F3F3'}}
                              renderInput={(params) => <TextField {...params} label="Documentos Base" />}
                           />
               </Grid>
               <Grid item xs={12} md={3}>
                     <Autocomplete
                              disablePortal
                              size='small'
                              id="combo-box-demo"
                              options={optionsRuta}
                              // sx={{ bgcolor: '#F3F3F3'}}
                              renderInput={(params) => <TextField {...params} label="Documentos Base" />}
                           />
               </Grid>
               <Grid item container
                     xs={12}
                     mt={2}
                     mb={3}
                  justifyContent='right'
                  mr={3}
   >
                        <Grid item>
                           <Button
                              type='submit'
                              variant='contained'
                              onClick={handleChangeShow}
                              // disabled={loading}
                              disableElevation
                              className='btn_save3'
                              
                           >
                                    <span>GENERAR</span>
                           </Button>
                        </Grid>
                  </Grid> 
            </Grid>
            <Grid item xs={12} md={12}>
            {/* <Typography variant="h6" gutterBottom mb={3} className="tittleIn" align="center" mt={2}>
                  LISTADO
               </Typography> */}

              {show && <Paper sx={{ width: '100%', overflow: 'hidden'}}>


                  <TableContainer sx={{maxHeight:440}} component={Paper}>
                     <Table stickyHeader aria-label="customized table" >
                        <TableHead >
                           <TableRow >
                              {columns.map((column) => (
                                    <StyledTableCell
                                       key={column.id}
                                       align={column.align}
                                       style={{ minWidth: column.minWidth }}
                                       >
                                       {column.label}
                                       </StyledTableCell>
                              ))}
                              <StyledTableCell align="center">
                                 QUITAR IMPRESION
                              </StyledTableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {rowsData1.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                              return (
                                 <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.razonSocial}>
                                    {columns.map((column) => {
                                       const value = row[column.id];
                                       return (
                                          <StyledTableCell key={column.id} align={column.align}>
                                             {column.format && typeof value === 'number'
                                                ? column.format(value)
                                                : value}
                                          </StyledTableCell>
                                       );
                                    })}
                                    <StyledTableCell align="center">
                                       <IconButton aria-label="delete" onClick={handleChangeShowIco}>
                                          {!showIcon &&<AddCircleIcon />||<DoNotDisturbOnIcon />}
                                       </IconButton>
                                    </StyledTableCell>                                   
                                    
                                 </StyledTableRow>
                              );
                           })}
                        
                        </TableBody>   
                     </Table>  
                  </TableContainer>
                  <Grid item xs={12} md={12} style={{fontWeight:'500'}} mt={3} mb={2}>
                  Resumen de Mercaderia
                  </Grid>

                  <TableContainer sx={{maxHeight:440}} component={Paper}>
                     <Table stickyHeader aria-label="customized table" >
                        <TableHead >
                           <TableRow >
                              {columns2.map((column) => (
                                    <StyledTableCell
                                       key={column.id}
                                       align={column.align}
                                       style={{ minWidth: column.minWidth }}
                                       >
                                       {column.label}
                                       </StyledTableCell>
                              ))}
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {rowsData2.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                              return (
                                 <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.codigo}>
                                    {columns2.map((column) => {
                                       const value = row[column.id];
                                       return (
                                          <StyledTableCell key={column.id} align={column.align}>
                                             {column.format && typeof value === 'number'
                                                ? column.format(value)
                                                : value}
                                          </StyledTableCell>
                                       );
                                    })}
                                    
                                 </StyledTableRow>
                              );
                           })}
                        
                        </TableBody>   
                     </Table>  
                  </TableContainer>
               
               </Paper>}
            </Grid>
         </Container>
            <Modal
                  open={showModal}
                  onClose={closeModalButton}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <ModalButton/>
                  </Box>
            </Modal>
            </Protected>
        </>
     );
}
 
