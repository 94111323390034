import React, { useState, useEffect } from 'react';
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Protected } from "@/components/layout/Protected";
import { FormControl, Grid, Stack, Typography, Button, Input, Tooltip, Autocomplete, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import InputLabel from '@mui/material/InputLabel';
import "./SaleNoteExportOption.sass";
// import "./Home-styles.css";
import "./SaleNoteExportOption-styles.css"
import { TableData } from "@/components/common/Table/Table";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';
import SearchIcon from '@mui/icons-material/Search';
import { DateTimePicker } from '@mui/lab';
import { DataProvider } from './Modal/DataProvider';

export const SaleNoteExportOption: FunctionComponent = (props: any) => {
   const [value, setValue] = useState('');

   const [selectTypeResume, setSelectTypeResume] = useState('');
   const [data, setData] = React.useState<Date | null>(new Date());


   const [valueCalendar, setValueCalendar] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   //modal Rit
   const [openRut, setOpenRut] = React.useState(false);
   const handleOpenRut = () => setOpenRut(true);
   const handleCloseRut = () => setOpenRut(false);


   const [cambiarEstado, setCambiarEstado] = useState(false);
   const handleCambiarEstado = () => setCambiarEstado(true);

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 870,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 4,
      borderRadius: '20px'
   };

   const handleChange = (event: any) => {
      setValue(event.target.value);
   };
   const handleChangeTypeResume = (event: SelectChangeEvent) => {
      setSelectTypeResume(event.target.value);
   };

   const [page, setPage] = React.useState(0);
   const [rowsPerPage, setRowsPerPage] = React.useState(10);

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const monedaValue = [
      { label: 'BOLIVAR (VENEZUELA)' },
      { label: 'BOLIVIANO (BOLIVIA)' },
      { label: 'CHELIN (AUSTRIA)' },
      { label: 'DOLAR (ESTADOS UNIDOS)' },
      { label: 'SOL (PERUANO)' }
   ]

   return (
      <Protected>
         <div className='Generator' >
            <Grid item xs={12} m={1} style={{ minHeight: '100vh' }}>
               <Grid item xs={12} md={12} >
                  <Box>
                     <Typography variant='h6' component='h1' sx={{ textTransform: 'uppercase' }} gutterBottom className="tittle">
                        Ingreso de Nota de Venta de Exportación Electrónica (781)
                     </Typography>
                  </Box>
                  <Box>
                     <Typography variant='subtitle2' gutterBottom >
                        Para continuar debe seleccionar al cliente, moneda y fecha
                     </Typography>
                  </Box>

               </Grid>
               <Grid item container xs={12} mb={2} spacing={2} sx={{ flexDirection: 'column' }}>
                  <Grid item xs={12} container>
                     <Grid item xs={4}>
                        <FormControl fullWidth sx={{ minWidth: 200 }}>
                           <TextField
                              id="input-with-sx"
                              // label="Folio Doc"
                              onChange={handleChange}
                              placeholder="Cargando..."
                           />
                        </FormControl>
                     </Grid>

                     <Grid  item xs={4}>
                        <Tooltip title="Buscar Empresa">
                           <IconButton onClick={handleOpenRut} className='btnIcoE'><SearchIcon /></IconButton>
                        </Tooltip>
                     </Grid>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                     <FormControl fullWidth sx={{ minWidth: 200 }}>
                        <Autocomplete
                           disablePortal
                           // size='small'
                           // id="combo-box-demo"
                           options={monedaValue}
                           renderInput={(params) => <TextField {...params} label="Moneda" />}
                        />
                     </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={3} sx={{ alignItems: 'center' }}>
                     {/* <Grid item xs={12} container sm={4} sx={{ alignItems: 'center' }}> */}
                     <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                           <DatePicker
                              views={['year', 'month', 'day']}
                              label="Periodo y Mes"
                              inputFormat="dd/MM/yyyy"
                              minDate={new Date('2012-03-01')}
                              maxDate={new Date('2023-06-01')}
                              value={valueCalendar}

                              onChange={(newValue) => {
                                 setValueCalendar(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} helperText={null} />}
                           />
                        </Stack>
                     </LocalizationProvider>
                  </Grid>
                  <Grid item container
                     xs={4}
                     justifyContent='right'
                  >
                     <Grid item>
                        <Button
                           type='submit'
                           variant='contained'
                           onClick={handleCambiarEstado}
                           // disabled={loading}
                           disableElevation
                           className='btn_save'
                        >
                           Seleccionar
                        </Button>

                     </Grid>
                  </Grid>
               </Grid>
               {cambiarEstado &&
                  <Grid item mt={5}>
                     <Typography variant="h2">Vista aún no establecida</Typography>
                  </Grid>

               }
               <Modal
                  open={openRut}
                  onClose={handleCloseRut}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <DataProvider />
                  </Box>
               </Modal>
            </Grid>
         </div>
      </Protected >
   )
}

