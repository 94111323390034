import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, InputAdornment, FormControl, FormControlLabel, Checkbox, } from '@mui/material'
import { useState, useRef } from "react";
import { Props } from "../AddLoan.type";
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import NumbersIcon from '@mui/icons-material/Numbers';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TableRow from '@mui/material/TableRow';
import '../AddLoan_style.css'

interface Column {
   id: 'num' | 'fecha' | 'monto' | 'estado' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: 'num', label: 'num', minWidth: 100 },
   { id: 'fecha', label: 'fecha', minWidth: 100 },
   { id: 'monto', label: 'monto', minWidth: 100 },
   { id: 'estado', label: 'estado', minWidth: 100 },
];

interface Data {
   num: string;
   fecha: string;
   monto: string;
   estado: string;
}

function createDataEnviador(
   num: string,
   fecha: string,
   monto: string,
   estado: string,
): Data {
   return { num, fecha, monto, estado };
}

const rowsData = [
   createDataEnviador('1', '2022-03-01', '633', 'Generada'),
   createDataEnviador('2', '2022-03-01', '633', 'Generada'),
   createDataEnviador('3', '2022-03-02', '633', 'Generada'),
]


export const DataModalViewQuotas: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [loading, setLoading] = useState(false);
   const [ejecutivoComercial, setEjecutivoComercial] = useState('');
   const [estado, setEstado] = useState('');
   const inputRefs = useRef<Array<InputRef | null>>([]);


   const handleChangeEjecutivoComercial = (event: SelectChangeEvent) => {
      setEjecutivoComercial(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   return (
      <Container maxWidth='xl'>
         <Grid container spacing={3} mt={2}>
            <Grid container mb={2}>
               <Grid item xs={10}>
                  <Typography variant="h6" id="modal-modal-title" className='tittle'>
                     Listado de Cuotas
                  </Typography>
               </Grid>
               <Grid item xs={2} textAlign="end">
                  <AssignmentIcon style={{ color: '#FF9700' }} />
               </Grid>
               <Grid item xs={12} >
                  <Typography variant="subtitle1" >
                     Detalle: 
                  </Typography>
               </Grid>
            </Grid>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
               <TableContainer sx={{ maxHeight: 420 }}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           <TableCell>N° Cuota</TableCell>
                           <TableCell>Fecha</TableCell>
                           <TableCell>Monto($)</TableCell>
                           <TableCell>Cobro Liquidación</TableCell>
                           <TableCell>Estado</TableCell>                                            
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.num}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                    <FormControlLabel control={<Checkbox defaultChecked />} label="" />
                                 </TableCell>
                              </TableRow>
                           );
                        })}
                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>
            <Grid item container
               xs={12}
               justifyContent='center'
            >
               <Grid item>
                  <Button
                     type='submit'
                     variant='contained'
                     // onClick={handleSubmit}
                     disabled={loading}
                     disableElevation
                     className='btn_save'
                  >
                     actualizar
                  </Button>
               </Grid>
            </Grid>
         </Grid>




      </Container >
   );
};

