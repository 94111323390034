import React, { FunctionComponent, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import { SelectChangeEvent } from "@mui/material";
import Stack from "@mui/material/Stack";
import TableRow from "@mui/material/TableRow";
import { Modal, IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "../ElectronicExportCreditNote_styles.css";
import TextField from "@mui/material/TextField";
import { Autocomplete, Box, Button, Grid } from "@mui/material";
import { Protected } from "@/components/layout/Protected";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { Container } from "@mui/material";
import { ResultBusque } from "./Modal/ResultBusque";
import Typography from "@mui/material/Typography";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
//import { DataInformsFinal } from "./Modal/DataInformsFinal";
//import { DataProductos } from "./Modal/DataProductos";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowSquareIcon from "@mui/icons-material/Square";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SearchIcon from "@mui/icons-material/Search";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { Link } from "react-router-dom";
import {
   ROUTE_F_DE_EX_ELECTRONIC_EXPORT_CREDIT_NOTE,
   ROUTE_F_ED_EMIT_QUOTES,
} from "@/toolbox/constants/route-map";

interface Column {
   id:
      | "numero"
      | "codigo"
      | "detalle"
      | "cantidad"
      | "valor"
      | "unidad"
      | "descuento"
      | "subtotal";
   label: string;
   minWidth?: number;
   align?: "right";
   format?: (value: number) => string;
}
interface Column1 {
   id:
      | "codigo"
      | "detalle"
      | "cantidad"
      | "valor"
      | "tipo"
      | "valorDescuento"
      | "total";
   label: string;
   minWidth?: number;
   align?: "right";
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: "numero", label: "N°", minWidth: 10 },
   { id: "codigo", label: "Código", minWidth: 10 },
   { id: "detalle", label: "Detalle", minWidth: 10 },
   { id: "cantidad", label: "Cantidad", minWidth: 10 },
   { id: "valor", label: "Valor Neto", minWidth: 10 },
   { id: "unidad", label: "Unidad Medida", minWidth: 10 },
   { id: "descuento", label: "Descuento", minWidth: 10 },
   { id: "subtotal", label: "Subtotal", minWidth: 10 },
];
const columns1: readonly Column1[] = [
   { id: "codigo", label: "Codigo", minWidth: 10 },
   { id: "detalle", label: "Detalle", minWidth: 50 },
   { id: "cantidad", label: "Cantidad", minWidth: 5 },
   { id: "valor", label: "Valor", minWidth: 10 },
   { id: "tipo", label: "Tipo", minWidth: 10 },
   { id: "valorDescuento", label: "Valor Descuento", minWidth: 10 },
   { id: "total", label: "Total", minWidth: 10 },
];
interface Data {
   numero: number;
   codigo: string;
   detalle: string;
   cantidad: number;
   valor: number;
   tipo: string;
   valorDescuento: number;
   total: number;
   unidad: string;
   descuento: number;
   subtotal: number;
}

function createData(
   numero: number,
   codigo: string,
   detalle: string,
   cantidad: number,
   valor: number,
   tipo: string,
   valorDescuento: number,
   total: number
): Data {
   return {
      numero,
      codigo,
      detalle,
      cantidad,
      valor,
      tipo,
      valorDescuento,
      total,
      unidad: "",
      descuento: 0,
      subtotal: 0,
   };
}

const rowsData = [
   createData(1, "123", "Prueba 1", 10, 5, "C/U Serv", 0, 1000),
   createData(2, "123", "Prueba 2", 10, 5, "C/U Serv", 0, 1000),
   createData(3, "123", "Prueba 3", 10, 5, "C/U Serv", 0, 1000),
   createData(4, "123", "Prueba 4", 10, 5, "C/U Serv", 0, 1000),
   createData(5, "123", "Prueba 5", 10, 5, "C/U Serv", 0, 1000),
];

const tipos = [
   { label: "%", value: "%" },
   { label: "$", value: "$" },
];

interface TableEmitReferesDocumentNoteCreditProps {}

export const EmitElectronicExportCreditNote: FunctionComponent<
   TableEmitReferesDocumentNoteCreditProps
> = () => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   // const [value, setValue] = useState('');
   const [value, setValue] = useState<Date | null>(new Date());
   const [loading, setLoading] = useState(false);
   const [pais, setPais] = useState("");
   const [estado, setEstado] = useState("");
   const [tipo, seTipo] = useState("");

   const [openArticulo, setOpenArticulo] = useState(false);
   const handleOpenArticulo = () => {
      setOpenArticulo(true);
   };
   const handleCloseArticulo = () => setOpenArticulo(false);

   const [selectTypeResume, setSelectTypeResume] = useState("");
   const [data, setData] = React.useState<Date | null>(new Date());

   const [openAdd, setOpenAdd] = useState(false);
   const handleOpenAdd = () => setOpenAdd(true);
   const handleCloseAdd = () => setOpenAdd(false);

   const [openRut, setOpenRut] = React.useState(false);
   const handleOpenRut = () => setOpenRut(true);
   const handleCloseRut = () => setOpenRut(false);

   const [openEdit, setOpenEdit] = useState(false);
   const handleOpenEdit = () => setOpenEdit(true);
   const handleCloseEdit = () => setOpenEdit(false);

   const [openInform, setOpenInform] = useState(false);
   const handleOpenInform = () => setOpenInform(true);
   const handleCloseInform = () => setOpenInform(false);

   const handleChange = (event: SelectChangeEvent) => {
      seTipo(event.target.value as string);
   };

   const handleChangeto = (event: any) => {
      setValue(event.target.value);
   };
   const handleChangeTypeResume = (event: SelectChangeEvent) => {
      setSelectTypeResume(event.target.value);
   };

   const label = { inputProps: { "aria-label": "Checkbox demo" } };

   const handleChangePais = (event: SelectChangeEvent) => {
      setPais(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
   ) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const direcciones = [
      { label: "Santiago", value: "Santiago" },
      { label: "Providencia", value: "Providencia" },
      { label: "Concepcion", value: "Concepcion" },
      { label: "Talca", value: "Talca" },
   ];

   const refdocumentos = [
      { label: "FACTURA DE COMPRA", value: "FACTURA DE COMPRA" },
      {
         label: "GUIA DE DESPACHO ELECTRONICA",
         value: "GUIA DE DESPACHO ELECTRONICA",
      },
      { label: "NOTA DE DEBITO", value: "NOTA DE DEBITO" },
      {
         label: "NOTA DE DEBITO ELECTRONICA",
         value: "NOTA DE DEBITO ELECTRONICA",
      },

      { label: "FACTURA", value: "FACTURA" },
      { label: "FACTURA EXENTA", value: "FACTURA EXENTA" },
      {
         label: "FACTURA EXENTA ELECTRONICA",
         value: "FACTURA EXENTA ELECTRONICA",
      },
      { label: "BOLETA", value: "BOLETA" },
      { label: "BOLETA EXENTA", value: "BOLETA EXENTA" },
      {
         label: "BOLETA ELECTRONICA",
         value: "BOLETA ELECTRONICA",
      },
      {
         label: "FACTURA DE COMPRA ELECTRONICA",
         value: "FACTURA DE COMPRA ELECTRONICA",
      },
      {
         label: "GASTO TRIBUTARIO SIN COMP",
         value: "GASTO TRIBUTARIO SIN COMP",
      },
      {
         label: "BOLETA EXENTA ELECTRONICA",
         value: "BOLETA EXENTA ELECTRONICA",
      },
   ];

   const anuladocumentoref = [
      {
         label: "Anula Documento de Referencia",
         value: "Anula Documento de Referencia",
      },
      {
         label: "Corrige Texto Documento de Referencia",
         value: "Corrige Texto Documento de Referencia",
      },
      { label: "Corrige Montos", value: "Corrige Montos" },
   ];

   const style = {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 1100,
      bgcolor: "background.paper",
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: "20px",
   };

   return (
      <Protected>
         <Container maxWidth="xl">
            <Grid container spacing={1}>
               <Grid item xs={12} md={12}>
                  <Grid container justifyContent="flex-end">
                     <Grid item xs={12} md={11}>
                        <Typography variant="subtitle2" gutterBottom>
                           REFERENCIAR NOTAS DE CREDITO EXPORTACION ELECTRONICA
                        </Typography>
                     </Grid>
                     <Grid item xs={12} md={1}>
                        <Button
                           type="submit"
                           variant="contained"
                           size="small"
                           // onClick={handleSubmit}
                           // disabled={loading}
                           disableElevation
                           component={Link}
                           to={ROUTE_F_DE_EX_ELECTRONIC_EXPORT_CREDIT_NOTE}
                           className="btnData"
                        >
                           <span>VOLVER</span>
                        </Button>
                     </Grid>
                  </Grid>
               </Grid>
               <Grid item xs={12} md={12}>
                  <Box>
                     <Typography
                        variant="h6"
                        gutterBottom
                        mb={3}
                        className="tittle2"
                        align="center"
                        mt={3}
                     >
                        DATOS DE RECEPTOR
                     </Typography>
                  </Box>
               </Grid>

               <Grid item xs={12} md={4}>
                  <TextField
                     id="rut"
                     label="Rut Cliente"
                     type="text"
                     fullWidth
                     variant="outlined"
                     defaultValue="7656576-7"
                     size="small"
                  />
               </Grid>
               <Grid item xs={12} md={4}>
                  <TextField
                     id="rut"
                     label="Razon Social"
                     type="text"
                     fullWidth
                     variant="outlined"
                     defaultValue="Alan Monsalve"
                     size="small"
                  />
               </Grid>

               <Grid item xs={12} md={4}>
                  <TextField
                     id="rut"
                     label="Giro de Actividad"
                     type="text"
                     fullWidth
                     variant="outlined"
                     defaultValue="giro cliente 3"
                     size="small"
                  />
               </Grid>
               <Grid item xs={12} md={7}>
                  <Autocomplete
                     disablePortal
                     size="small"
                     id="combo-box-demo"
                     options={direcciones}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => (
                        <TextField {...params} label="Seleccione Direccion" />
                     )}
                  />
               </Grid>
               <Grid item xs={12} md={5}>
                  <TextField
                     id="rut"
                     label="Telefono"
                     type="text"
                     fullWidth
                     variant="outlined"
                     size="small"
                     // defaultValue="jmora@test.cl"
                  />
               </Grid>
            </Grid>

            <Typography
               variant="h6"
               gutterBottom
               mb={3}
               className="tittle2"
               align="center"
               mt={3}
            >
               REFERENCIAR DOCUMENTO
            </Typography>

            <Grid container spacing={1}>
               <Grid item xs={12} md={4}>
                  <Autocomplete
                     disablePortal
                     size="small"
                     id="combo-box-demo"
                     options={refdocumentos}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => (
                        <TextField
                           {...params}
                           label="Seleccione Tipo Documento"
                        />
                     )}
                  />
               </Grid>
               <Grid item xs={12} md={4}>
                  <TextField
                     id="rut"
                     label="Razon Referencia"
                     type="text"
                     fullWidth
                     variant="outlined"
                     placeholder="Inserte Razon..."
                     size="small"
                  />
               </Grid>

               <Grid item xs={12} md={4}>
                  <Autocomplete
                     disablePortal
                     size="small"
                     id="combo-box-demo"
                     options={anuladocumentoref}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => (
                        <TextField
                           {...params}
                           label="Seleccione Razón de Referencia"
                        />
                     )}
                  />
               </Grid>

               <Grid item xs={12} md={12}>
                  <Grid container justifyContent="flex-end">
                     <Grid item xs={12}>
                        <Button
                           type="submit"
                           variant="contained"
                           size="small"
                           // onClick={handleSubmit}
                           onClick={handleOpenRut}
                           // disabled={loading}
                           disableElevation
                           className="btnData"
                        >
                           <span>Buscar</span>
                        </Button>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
         </Container>

         <Modal
            open={openRut}
            onClose={handleCloseRut}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style}>
               <ResultBusque entity={undefined} />
            </Box>
         </Modal>
         {/*<Modal
            open={openEdit}
            onClose={handleCloseEdit}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style}>
               <DataEditarCotizacion entity={undefined} />
            </Box>
         </Modal>*/}

         {/*<Modal
            open={openAdd}
            onClose={handleCloseAdd}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style}>
               <DataEditarCotizacion entity={undefined} />
            </Box>
         </Modal>*/}

         {/*<Modal
            open={openInform}
            onClose={handleCloseInform}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style}>
               <DataInformsFinal entity={undefined} />
            </Box>
         </Modal>*/}
      </Protected>
   );
};
