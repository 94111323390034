import { Box, Grid, Container, Typography, IconButton, Button, Tooltip, TableFooter, } from '@mui/material'
import { useState } from "react";
import { Props } from "../SalesBook.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import HistoryIcon from '@mui/icons-material/History';
import ReceiptIcon from '@mui/icons-material/Receipt';
import EmailIcon from '@mui/icons-material/Email';
import { LoadingButton } from '@mui/lab';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import '../SalesBook_styles.css'
import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import { DataModalSendEmail } from './Modals/DataModalSendEmail';
import { DataModalStateSll } from './Modals/DataModalStateSll';
import { DataModalVoucher } from './Modals/DataModalVoucher';


interface Column {
   id: 'tipoDocumento' | 'cantidad' | 'exento' | 'especifico' | 'valorNeto' | 'iva' | 'total';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: string) => string;
}

interface Column1 {
   id: 'tipo' | 'fecha' | 'folio' | 're' | 'rut' | 'razonSocial' | 'total' | 'ref' | 'cta';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value2: string) => string;
}


const columns: readonly Column[] = [
   { id: 'tipoDocumento', label: 'Tipo Documento', minWidth: 10 },
   { id: 'cantidad', label: 'Cantidad', minWidth: 10 },
   { id: 'exento', label: 'Exento', minWidth: 10 },
   { id: 'especifico', label: 'Específico', minWidth: 10 },
   { id: 'valorNeto', label: 'Valor Neto', minWidth: 10 },
   { id: 'iva', label: 'IVA', minWidth: 10 },
   { id: 'total', label: 'Total', minWidth: 10 },
];

const columns1: readonly Column1[] = [
   { id: 'tipo', label: 'Tipo', minWidth: 10 },
   { id: 'fecha', label: 'Fecha', minWidth: 10 },
   { id: 'folio', label: 'Folio', minWidth: 10 },
   { id: 're', label: 'Re', minWidth: 10 },
   { id: 'rut', label: 'Rut', minWidth: 10 },
   { id: 'razonSocial', label: 'Razón Social', minWidth: 10 },
   { id: 'total', label: 'Total', minWidth: 10 },
   { id: 'ref', label: 'Ref', minWidth: 10 },
   { id: 'cta', label: 'Cuenta', minWidth: 10 },
];

interface Data {
   tipoDocumento: string;
   cantidad: string;
   exento: string;
   especifico: string;
   valorNeto: string;
   iva: string;
   total: string;
}

interface Data1 {
   tipo: string;
   fecha: string;
   folio: string;
   re: string;
   rut: string;
   razonSocial: string;
   total: string;
   ref: string;
   cta: string;
}

function createData(
   tipoDocumento: string,
   cantidad: string,
   exento: string,
   especifico: string,
   valorNeto: string,
   iva: string,
   total: string,

): Data {
   return { tipoDocumento, cantidad, exento, especifico, valorNeto, iva, total };
}

function createData1(
   tipo: string,
   fecha: string,
   folio: string,
   re: string,
   rut: string,
   razonSocial: string,
   total: string,
   ref: string,
   cta:string
): Data1 {
   return { tipo, fecha, folio, re, rut, razonSocial, total, ref,cta };
}

const rowsData = [
   createData('NOTA DE VENTA EXPORTACION', '0', '$ 0', '$ 0', '$ 0', '$ 0', '$ 0'),
]

const rowsData1 = [
   createData1('780', '03/12/2022', '11245', '', '6666111-1', 'Razon Social Anonima', '33.997', '', 'USUARIO'),
   createData1('650', '03/12/2022', '11245', '' ,'1111111-1', 'Razon Social Anonima 2', '33.997', '', 'USUARIO'),
   createData1('650', '03/12/2022', '11245', '' ,'1111111-1', 'Razon Social Anonima 2', '33.997', '', 'USUARIO'),
   createData1('650', '03/12/2022', '11245', '' ,'1111111-1', 'Razon Social Anonima 2', '33.997', '', 'USUARIO'),
]

export const DataBook: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);

   const [loading, setLoading] = useState(false);

   //State para modal Book
   const [openSendEmail, setOpenSendEmail] = useState(false);
   const handleOpenSendEmail = () => setOpenSendEmail(true);
   const handleCloseSendEmail = () => setOpenSendEmail(false);
   //State para modal HistorySend
   const [openStateSll, setOpenStateSll] = useState(false);
   const handleOpenStateSll = () => setOpenStateSll(true);
   const handleCloseStateSll = () => setOpenStateSll(false);
   //State para modal ChangeState
   const [openVoucher, setOpenVoucher] = useState(false);
   const handleOpenVoucher = () => setOpenVoucher(true);
   const handleCloseVoucher = () => setOpenVoucher(false);

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   function handleClick() {
      setLoading(true);
   }

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 910,
      height: 500,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

   const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
         backgroundColor: '#c6ced1',
         fontSize: 14,
         color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
         fontSize: 14,
      },
   }));
   return (
      <Container maxWidth='xl'>
         <Grid container spacing={3}>
            <Grid item xs={12} md={6} >
               <Box>
                  <Typography variant='h6' component='h1' gutterBottom className="tittle">
                     Registro Libro de Ventas
                  </Typography>
               </Box>
               <Box>
                  <Typography variant='subtitle2' gutterBottom >
                     Periodo: 2022 | Mes: 04 
                  </Typography>
               </Box>

            </Grid>
            <Grid item xs={12} md={6} className="btn-download">
               <Box pb={2} display="flex" justifyContent="space-evenly">

                  <LoadingButton
                     // color="warning"
                     onClick={handleClick}
                     loading={loading}
                     loadingPosition="start"
                     startIcon={<FileDownloadIcon />}
                     variant="contained"
                     className="btn-download2"
                     style={{ marginRight: '10px' }}

                  >
                     Excel
                  </LoadingButton>
                  <LoadingButton
                     // color="warning"
                     onClick={handleClick}
                     loading={loading}
                     loadingPosition="start"
                     startIcon={<FileDownloadIcon />}
                     variant="contained"
                     className="btn-download2"
                     style={{ marginRight: '10px' }}

                  >
                     PDF
                  </LoadingButton>
               </Box>
            </Grid>
         </Grid>


         <div style={{ background: '#16556b', borderRadius: '5px', color: '#ffffff', }} >
            <Typography
               mt={2}
               mb={2}
               style={{ padding: '3px' }}
               variant="subtitle2"
               align='center'
            >Resumen de Documentación
            </Typography>
         </div>

         <Grid item xs={12}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
               <TableContainer sx={{ maxHeight: 220 }}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           {columns.map((column) => (
                              <StyledTableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{ minWidth: column.minWidth }}
                              >
                                 {column.label}
                              </StyledTableCell>
                           ))}
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.tipoDocumento}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'string'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}

                              </TableRow>
                           );
                        })}

                     </TableBody>
                     <TableFooter>
                        <TableCell align='right'>Totales</TableCell>
                        <TableCell>0</TableCell>
                        <TableCell>$0</TableCell>
                        <TableCell>$0</TableCell>
                        <TableCell>$0</TableCell>
                        <TableCell>$0</TableCell>
                        <TableCell>$0</TableCell>
                     </TableFooter>
                  </Table>
               </TableContainer>

            </Paper>
         </Grid>

         <Grid item xs={12} mt={4}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }} >
               <TableContainer sx={{ maxHeight: 700 }} >
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           {columns1.map((column) => (
                              <StyledTableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{ minWidth: column.minWidth }}
                              >
                                 {column.label}
                              </StyledTableCell>

                           ))}
                           <StyledTableCell>Opcion</StyledTableCell>

                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData1.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.tipo}>
                                 {columns1.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'string'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                    <Grid>
                                       <Tooltip title={"Enviar Email"} placement="bottom-start">
                                          <IconButton
                                             aria-label="Editar"
                                             onClick={() => handleOpenSendEmail()}
                                             className='btn_delete'
                                          // disabled={loading}
                                          >                                             
                                             <EmailIcon />
                                          </IconButton>
                                       </Tooltip>
                                       
                                       <Tooltip title={"Estado Sll"} placement="bottom-start">
                                          <IconButton
                                             aria-label="Editar"
                                             onClick={() => handleOpenStateSll()}
                                             className='btn_delete'
                                          // disabled={loading}
                                          >
                                             <HistoryIcon />
                                          </IconButton>
                                       </Tooltip>
                                       <Tooltip title={"Notificar Electrónicamente"} placement="bottom-start">
                                          <IconButton
                                             aria-label="Editar"
                                             // onClick={() =>()}
                                             className='btn_delete'
                                          // disabled={loading}
                                          >
                                             <NotificationsActiveIcon />
                                          </IconButton>
                                       </Tooltip>
                                       <Tooltip title={"Comprobante de Pago y Anulaciones"} placement="bottom-start">
                                          <IconButton
                                             aria-label="Editar"
                                             onClick={() => handleOpenVoucher()}
                                             className='btn_delete'
                                          // disabled={loading}
                                          >
                                             <ReceiptIcon />
                                          </IconButton>
                                       </Tooltip>                                    
                                    </Grid>
                                 </TableCell>

                              </TableRow>
                           );
                        })}

                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>

         </Grid>
         <Modal
            open={openSendEmail}
            onClose={handleCloseSendEmail}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataModalSendEmail entity={undefined} />
            </Box>
         </Modal>
         <Modal
            open={openStateSll}
            onClose={handleCloseStateSll}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataModalStateSll entity={undefined} />
            </Box>
         </Modal>
         <Modal
            open={openVoucher}
            onClose={handleCloseVoucher}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataModalVoucher entity={undefined} />
            </Box>
         </Modal>
         
        

      </Container>

   );
}