import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl,  TextField, Divider, InputAdornment, FormControlLabel } from '@mui/material';
import { ArrowRightIcon } from "@toolbox/constants/icons";
import { Props } from './FormContable.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import Entity from "@assets/img/entity.png";
import './style.sass'
import "./FormContable_style.css";
import BadgeIcon from '@mui/icons-material/Badge';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';

export const FormContable: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false)
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
    );
   const [estado, setEstado] = useState<any>({ rut: false, nombre_rz: false })

   const [valueConsiderable, setValueConsiderable] = React.useState('Si');
   const [valueTipoAdquisicion, setValueTipoAvalueTipoAdquisicion] = React.useState('Activo Propio');


   const handleChangeConsiderable = (event: React.ChangeEvent<HTMLInputElement>) => {
     setValueConsiderable((event.target as HTMLInputElement).value);
   };

    const handleTipoAvalueTipoAdquisicion = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueTipoAvalueTipoAdquisicion((event.target as HTMLInputElement).value);
    };
    
   
   const compraActivo = [
      { label: 'Cuenta contable 1'},
      { label: 'Cuenta contable 1'},
      { label: 'Cuenta contable 1'},
      { label: 'Cuenta contable 1'},
   ]
   const revalorizacion = [
      { label: 'Cuenta contable 1'},
      { label: 'Cuenta contable 1'},
      { label: 'Cuenta contable 1'},
      { label: 'Cuenta contable 1'},
   ]
   const depreciacion = [
      { label: 'Cuenta contable 1'},
      { label: 'Cuenta contable 1'},
      { label: 'Cuenta contable 1'},
      { label: 'Cuenta contable 1'},
   ]

   const readecuaciones = [
      { label: 'Cuenta contable 1'},
      { label: 'Cuenta contable 1'},
      { label: 'Cuenta contable 1'},
      { label: 'Cuenta contable 1'},
   ]
  
   const rules = useMemo(() => ({
      valor_residual: [
         VALIDATORS.REQUIRED,
      ],
      tipo_adquisicion: [
         VALIDATORS.REQUIRED,
      ],
      compra_activo: [
         VALIDATORS.REQUIRED
      ],
      revalorizacion: [
         VALIDATORS.REQUIRED,
      ],
      depreciacion: [
         VALIDATORS.REQUIRED,
      ],
      readecuaciones: [
         VALIDATORS.REQUIRED,
      ],
      monto_credito:[
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      credito_adquisicion: [
         VALIDATORS.REQUIRED,
      ]

   }), []);


   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'valor_residual':
            props.setvalueEntry(prev => ({ ...prev, valor_residual: value }));
            break;
         case 'tipo_adquisicion':
            props.setvalueEntry(prev => ({ ...prev, tipo_adquisicion: value }))
            break;
         case 'compra_activo':
            props.setvalueEntry(prev => ({ ...prev, compra_activo: value }))
            break;
         case 'revalorizacion':
            props.setvalueEntry(prev => ({ ...prev, revalorizacion: value }));
            break;
         case 'depreciacion':
            props.setvalueEntry(prev => ({ ...prev, depreciacion: value }));
            break;
         case 'readecuaciones':
            props.setvalueEntry(prev => ({ ...prev, readecuaciones: value }));
            break;
         case 'monto_credito':
            props.setvalueEntry(prev => ({ ...prev, monto_credito: value }));
            break;
         case 'credito_adquisicion':
            props.setvalueEntry(prev => ({ ...prev, credito_adquisicion: value }));
            break;
         case 'revalorizable':
            props.setvalueEntry(prev => ({ ...prev, revalorizable: value }));
            break;
         default:
            break;
      }
   };
   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      props.setvalueEntry(prev => ({ ...prev, [name]: newVal }));
   }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      props.handleNext();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }

   return (
      <Container maxWidth="xl">
         <form >
            <Grid container spacing={3} className={`e-mb-lg`}>
            
            <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={compraActivo}
                     renderInput={(params) => <TextField {...params} label="Compra de Activo" />}
                  />
               </Grid>
   
               <Grid item xs={12} sm={3}>
               <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={revalorizacion}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Revalorizacion" />}
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
               <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={depreciacion}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Depreciacion" />}
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
               <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={readecuaciones}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Readecuaciones" />}
                  />
               </Grid>

               <Grid item xs={12} sm={6}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                     <BadgeIcon />
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="valor_residual"
                     type='text'
                     placeholder="Valor Residual"
                     value={props.valueEntry.valor_residual}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.valor_residual}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />

               </Grid>

               <Grid item xs={12} sm={6}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                     <BadgeIcon />
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[3] = ref}
                     name="monto_credito"
                     type='text'
                     placeholder="Monto Credito"
                     value={props.valueEntry.monto_credito}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.monto_credito}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />

               </Grid>

               <Grid item xs={12} sm={6}>
               <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">Tipo de Adquisicion</FormLabel>
                  <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={valueTipoAdquisicion}
                  onChange={handleTipoAvalueTipoAdquisicion}
                  >
                  <FormControlLabel value="Activo Propio" control={<Radio />} label="Activo Propio" />
                  <FormControlLabel value="Activo en Leasing" control={<Radio />} label="Activo en Leasing" />
                  </RadioGroup>
               </FormControl>
               </Grid>
               
               <Grid item xs={12} sm={6}>
               <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">Se considera en el credito de adquisicion?</FormLabel>
                  <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={valueConsiderable}
                  onChange={handleChangeConsiderable}
                  >
                  <FormControlLabel value="Si" control={<Radio />} label="Si" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                  </RadioGroup>
               </FormControl>
               </Grid>

               <Grid item container
                  xs={12}
                 justifyContent='right'
                 >
                     <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                        className='btn_save'
                        endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>Siguiente</span>
                        }
                     </Button>

                     </Grid>
               </Grid>
            </Grid>
         </form>
      </Container>
   );
}
