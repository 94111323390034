import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, InputAdornment, FormControl, FormControlLabel, Checkbox, Stack, FormGroup, RadioGroup, } from '@mui/material'
import React, { useState, useRef } from "react";
import { Props } from "../DataBooks.type"
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import AssignmentIcon from '@mui/icons-material/Assignment';
// import '../Workers.css'
import TextField from '@mui/material/TextField';

export const DataModalChangeState: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [ejecutivoComercial, setEjecutivoComercial] = useState('');
   const [estado, setEstado] = useState('');
   // const [data, setData] = React.useState<Date | null>(new Date());
   const [data, setData] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const inputRefs = useRef<Array<InputRef | null>>([]);

   //radio buttoms
   const [valueRadio, setValueRadio] = React.useState('female');

   const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueRadio((event.target as HTMLInputElement).value);
   };

   const handleChangeEjecutivoComercial = (event: SelectChangeEvent) => {
      setEjecutivoComercial(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const estadoValue = [
      { label: 'ACTIVADO', value: 'activado' },
      { label: 'DESACTIVADO', value: 'desactivado' }
   ]
   const parentescoValue = [
      { label: 'Esposa' },
      { label: 'Esposo' },
      { label: 'Hija' },
      { label: 'Hijo' },
      { label: 'Padre' },
      { label: 'Madre' },
      { label: 'Otro' }
   ]
   const contacto = [
      { label: 'Jorge Roberto' }
   ]
   const cargaValue = [
      { label: 'Simple' },
      { label: 'Duplo' },
      { label: 'Maternal' }
   ]

   return (
      <Container maxWidth='xl'>
         <Grid container spacing={3} mt={2} >
            <Grid item container>
               <Grid item xs={10}>
                  <Typography variant="h6" id="modal-modal-title" className='tittle'>
                     ESTADO NOTA DE VENTA
                  </Typography>
                  <Grid xs={12} mt={1}>
                     <Typography variant='subtitle2' gutterBottom >
                        Nota Venta Nº 6608
                     </Typography>
                  </Grid>
               </Grid>
               <Grid item xs={2} textAlign="end">
                  <AssignmentIcon style={{ color: '#FF9700' }} />
               </Grid>
            </Grid>
            <Grid item container className={`e-mb-lg`} spacing={3}>
               <Grid item xs={12} sm={4}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={contacto}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Estado Actual" />}
                  />
               </Grid>
               <Grid item xs={1}></Grid>
               <Grid item xs={12} sm={8}>
                  <TextField
                     id="outlined-multiline-flexible"
                     label="Observaciones"
                     multiline
                     fullWidth={true}
                     rows={4}
                     value={value}
                  // onChange={handleChange}
                  />
               </Grid>

            </Grid>
            <Grid container mt={2}>
               <Grid item xs={12} textAlign="center">
                  <button className='btn_add'>
                     Actualizar
                  </button>
               </Grid>

            </Grid>
            {/* <Grid xs={12}>
               <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={2}>
                  LISTADO DE CARGAS FAMILIARES
               </Typography>
            </Grid> */}
         </Grid>




      </Container >
   );
};

