import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { ArrowRightIcon } from "@/toolbox/constants/icons";
import { VALIDATORS } from "@/toolbox/helpers/validation-rules";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, } from '@mui/material';
import { Input } from '@components/common/Input';
import { Props } from "./GcClientPerson.type";
import BusinessIcon from '@mui/icons-material/Business';
import { InputRef } from '@/components/common/Input/InputInterfaces';
import AssignmentIcon from '@mui/icons-material/Assignment';
import NumbersIcon from '@mui/icons-material/Numbers';
import TablePagination from '@mui/material/TablePagination';
import ArticleIcon from '@mui/icons-material/Article';
import Stack from '@mui/material/Stack';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import './GcClientPerson_style.css';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import WorkIcon from '@mui/icons-material/Work';
import AbcIcon from '@mui/icons-material/Abc';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { DataModalContactEnterprise } from './ModalContactEnterprise/DataModalContactEnterprise';

interface Column {
   id: 'rut' | 'razonSocial' | 'fantasia' | 'giro' | 'telefono' | 'vendedor';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: 'rut', label: 'Rut', minWidth: 100 },
   { id: 'razonSocial', label: 'Razón Social', minWidth: 100 },
   { id: 'fantasia', label: 'Fantasia', minWidth: 100 },
   { id: 'giro', label: 'Giro', minWidth: 100 },
   { id: 'telefono', label: 'Telefono', minWidth: 100 },
   { id: 'vendedor', label: 'Vendedor', minWidth: 100 },
];

interface Data {
   rut: string;
   razonSocial: string;
   fantasia: string;
   giro: string;
   telefono: string;
   vendedor: string;
}
function createDataContrato(
   rut: string,
   razonSocial: string,
   fantasia: string,
   giro: string,
   telefono: string,
   vendedor: string,
): Data {
   return { rut, razonSocial, fantasia, giro, telefono, vendedor };
}

const rowsListado = [
   createDataContrato('98171618-K', 'Razón Social 1', 'Pruebas', 'Personas', '1392660', 'Vendedor 1'),
   createDataContrato('1111111-1', 'Razón Social 2', 'Probando', 'Personas', '992028354', 'Vendedor 2'),
   createDataContrato('22222222-2', 'Razón Social 3', 'Fantasía 1', 'Personas', '955164705', 'Vendedor 3'),
   createDataContrato('8753258-O', 'Razón Social 4', 'Fantasía 2', 'Personas', '23917108', 'Vendedor 4'),

]


export const GcClientPerson: React.FC<Props> = (props: Props): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [openEnterprise, setOpenEnterprise] = useState(false);

   // const [open, setOpen] = useState(false);
   // const handleOpen = () => setOpen(true);
   // const handleClose = () => setOpen(false);

   const handleOpenEnterprise = () => setOpenEnterprise(true);
   const handleCloseEnterprise = () => setOpenEnterprise(false);

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };
   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };
   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 720,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const [valueContrato, setvalueContrato] = useState({
      rut_proveedor: '',
      titulo: '',
      numero_contrato: '',
      descripcion: '',
   });

   const estado = [
      { label: 'ACTIVADO' },
      { label: 'DESACTIVADO' },
   ]

   const ejecutivoComercial = [
      { label: 'ACTIVADO' },
      { label: 'DESACTIVADO' }
   ]

   
   const vendedor = [
      { label: 'El chavo' },
      { label: 'Vendedor 2' },
      { label: 'Vendedor 3' },
      { label: 'Vendedor 4' }
   ]

   const estadoComercial = [
      { label: 'ACTIVO' },
      { label: 'BLOQUEO COMERCIAL' }
   ]



   const rules = useMemo(() => ({
      rut_proveedor: [
         VALIDATORS.REQUIRED,
      ],
      numero_contrato: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      titulo: [
         VALIDATORS.REQUIRED
      ],
      descripcion: [
         VALIDATORS.REQUIRED,
      ]

   }), []);
   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'rut_proveedot':
            props.setvalueContrato(prev => ({ ...prev, rut_proveedot: value }));
            break;
         case 'numero_contrato':
            props.setvalueContrato(prev => ({ ...prev, numero_contrato: value }))
            break;
         case 'titulo':
            props.setvalueContrato(prev => ({ ...prev, titulo: value }))
            break;
         case 'descripcion':
            props.setvalueContrato(prev => ({ ...prev, descripcion: value }));
            break;

         default:
            break;
      }
   };

   // const handleInputBlur = (event: any) => {
   //    const newVal = (event.target.value).trim();
   //    const name = event.target.name;
   //    props.setvalueContrato(prev => ({ ...prev, [name]: newVal }));
   // }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }
   return (
      <Protected>
         <Container maxWidth='xl'>
            <form style={{ margin: '8px' }}>
               <Typography variant="h6" gutterBottom mb={3} className="tittle">
                  MANTENEDOR DE CLIENTES PERSONAS
               </Typography>
               <Grid container mt={2} mb={2}>
                  <Grid item xs={12} textAlign="right">
                     <button className='btn_botones'>
                        Exportar Productos
                     </button>
                  </Grid>

               </Grid>



               <Grid container spacing={3} className={`e-mb-lg`} >

                  <Grid item xs={12} sm={2}>
                     <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <BusinessIcon />
                           </InputAdornment>
                        }
                        name="rut_proveedor"
                        placeholder="Rut Proveedor"
                        type="text"
                        // value={valueContrato.rut_proveedor}
                        onChange={handleInput}
                        // onBlur={handleInputBlur}
                        // rules={rules.rut_proveedor}
                        disableElevation
                        // validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                     <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <AbcIcon />

                           </InputAdornment>
                        }
                        name="valor_compra"
                        placeholder="Nombre Completo"
                        //value={data.nombre_rz}
                        // value={props.valueContrato.valor_compra}
                        onChange={handleInput}
                        //onBlur={handleInputBlur}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.valor_compra}
                        disableElevation
                        validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                     <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <AlternateEmailIcon />
                           </InputAdornment>
                        }
                        name="valor_compra"
                        placeholder="Email"
                        //value={data.nombre_rz}
                        // value={props.valueContrato.valor_compra}
                        onChange={handleInput}
                        //onBlur={handleInputBlur}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.valor_compra}
                        disableElevation
                        validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                     <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <AnnouncementIcon />
                           </InputAdornment>
                        }
                        name="valor_compra"
                        placeholder="Observación"
                        //value={data.nombre_rz}
                        // value={props.valueContrato.valor_compra}
                        onChange={handleInput}
                        //onBlur={handleInputBlur}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.valor_compra}
                        disableElevation
                        validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                     <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={vendedor}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Vendedor" />}
                     />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                     <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                           label="Fecha de Nacimiento"

                           value={value}
                           onChange={(newValue) => {
                              setValue(newValue);
                           }}
                           renderInput={(params) => <TextField {...params} />}
                        />
                     </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                     <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <PhoneIcon />
                           </InputAdornment>
                        }
                        name="valor_compra"
                        placeholder="Teléfono"
                        //value={data.nombre_rz}
                        // value={props.valueContrato.valor_compra}
                        onChange={handleInput}
                        //onBlur={handleInputBlur}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.valor_compra}
                        disableElevation
                        validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                     <FormControl sx={{ display: 'inline' }}>
                        <FormControlLabel control={<Checkbox defaultChecked />} label="Cliente" />
                        <FormControlLabel control={<Checkbox defaultChecked />} label="Proveedor" />
                        <FormControlLabel control={<Checkbox />} label="Extranjero" />
                     </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                     <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={estadoComercial}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Estado Comercial" />}
                     />
                  </Grid>

                  <Grid item container
                     xs={12}
                     justifyContent='right'
                  >
                     <Grid item>
                        <Button
                           type='submit'
                           variant='contained'
                           onClick={handleSubmit}
                           disabled={loading}
                           disableElevation
                           className='btn_save'
                        >
                           <span>Agregar</span>
                        </Button>

                     </Grid>
                  </Grid>
               </Grid>
            </form>

            <div className='bg-tittle'>
               <Typography
                  mt={3}
                  variant="h6"
                  align='center'
                  className='tittle-text'
               >Listado Clientes Personas Ingresados
               </Typography>
            </div>
         </Container>

         <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer component={Paper}>
               <Table aria-label="simple table">
                  <TableHead>
                     <TableRow>                        
                        <TableCell>Rut</TableCell>
                        <TableCell>Razón Social</TableCell>
                        <TableCell>Fantasía</TableCell>
                        <TableCell>Giro</TableCell>
                        <TableCell>Teléfono</TableCell>
                        <TableCell>Vendedor</TableCell>
                        <TableCell>Opciones</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {rowsListado.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        return (
                           <TableRow hover role="checkbox" tabIndex={-1} key={row.rut}>
                              {columns.map((column) => {
                                 const value = row[column.id];
                                 return (
                                    <TableCell key={column.id} align={column.align}>
                                       {column.format && typeof value === 'number'
                                          ? column.format(value)
                                          : value}
                                    </TableCell>
                                 );
                              })}
                              <TableCell>

                                 <IconButton
                                    aria-label="delete"
                                    // onClick={() => handleDelete(row.id)}
                                    className='btn_delete'
                                    onClick={() => handleOpenEnterprise()}
                                 // disabled={loading}
                                 >
                                    <PersonIcon />
                                 </IconButton>

                              </TableCell>
                           </TableRow>
                        );
                     })}
                  </TableBody>
               </Table>
            </TableContainer>
            <TablePagination
               rowsPerPageOptions={[10, 25, 100]}
               component="div"
               count={rowsListado.length}
               rowsPerPage={rowsPerPage}
               page={page}
               onPageChange={handleChangePage}
               onRowsPerPageChange={handleChangeRowsPerPage}
            />
         </Paper>
         <Modal
            open={openEnterprise}
            onClose={handleCloseEnterprise}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataModalContactEnterprise entity={undefined} />
            </Box>
         </Modal>
         {/* <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style}>
               <Grid container>
                  <Grid item xs={10}>
                     <Typography variant="h6" id="modal-modal-title" mb={2} className='tittle'>
                        Contactos Empresa
                     </Typography>
                  </Grid>
                  <Grid item xs={2} textAlign="end">
                     <AssignmentIcon style={{ color: '#FF9700' }} />
                  </Grid>
               </Grid>
               <Grid container mb={2}>
                  <Typography variant="caption" id="modal-modal-title" mb={2}>
                     Incorpore sus Contactos y Datos necesarios.
                  </Typography>
               </Grid>

               <Grid container spacing={3} className={`e-mb-lg`}>

                  <Grid item xs={12} sm={4}>
                     <Input
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <NumbersIcon />
                           </InputAdornment>
                        }
                        ref={ref => inputRefs.current[0] = ref}
                        name="resumen"
                        type='text'
                        placeholder="Nombre Contacto"
                        // onChange={handleInput}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.resumen}
                        disableElevation
                        validateOnBlur
                        dense
                        // disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                     <Input
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <WorkIcon />
                           </InputAdornment>
                        }
                        ref={ref => inputRefs.current[0] = ref}
                        name="resumen"
                        type='text'
                        placeholder="Cargo"
                        // onChange={handleInput}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.resumen}
                        disableElevation
                        validateOnBlur
                        dense
                        // disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                     <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={ejecutivoComercial}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Ejecutico Comercial" />}
                     />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                     <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={estado}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Estado" />}
                     />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                     <Input
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <AlternateEmailIcon />
                           </InputAdornment>
                        }
                        ref={ref => inputRefs.current[0] = ref}
                        name="resumen"
                        type='text'
                        placeholder="Email"
                        // onChange={handleInput}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.resumen}
                        disableElevation
                        validateOnBlur
                        dense
                        // disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                     <Input
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <PhoneIcon />
                           </InputAdornment>
                        }
                        ref={ref => inputRefs.current[0] = ref}
                        name="resumen"
                        type='text'
                        placeholder="Teléfono FIjo"
                        // onChange={handleInput}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.resumen}
                        disableElevation
                        validateOnBlur
                        dense
                        // disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                     <Input
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <PhoneAndroidIcon />
                           </InputAdornment>
                        }
                        ref={ref => inputRefs.current[0] = ref}
                        name="resumen"
                        type='text'
                        placeholder="Celular"
                        // onChange={handleInput}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.resumen}
                        disableElevation
                        validateOnBlur
                        dense
                        // disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>



               </Grid>


               <Grid container mt={2}>
                  <Grid item xs={12} textAlign="center">
                     <button className='btn_add'>
                        Agregar
                     </button>
                  </Grid>

               </Grid>

               <Grid container mt={2}>
                  <Grid item xs={12}>
                     <Box mt={1} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <FormControl fullWidth >
                           <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                              <TableContainer sx={{ maxHeight: 540 }}>
                                 <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                       <TableRow>
                                          <TableCell>N°</TableCell>
                                          <TableCell>Nombre</TableCell>
                                          <TableCell>Cargo</TableCell>
                                          <TableCell>Email</TableCell>
                                          <TableCell>Estado</TableCell>
                                          <TableCell>Cobrar</TableCell>
                                          <TableCell>Opciones</TableCell>

                                       </TableRow>
                                    </TableHead>

                                    <TableBody>
                                       <TableRow >
                                          <TableCell>
                                             1
                                          </TableCell>

                                          <TableCell>
                                             nombre
                                          </TableCell>

                                          <TableCell>
                                             cargo
                                          </TableCell>

                                          <TableCell>
                                             email.softnet@gmail.com
                                          </TableCell>

                                          <TableCell>
                                             <Checkbox defaultChecked />
                                          </TableCell>
                                          <TableCell>
                                             <Checkbox defaultChecked />
                                          </TableCell>

                                          <TableCell>
                                             <div className='sepaEle'>
                                                <button className='btnIcoD'><EditIcon /></button>
                                             </div>
                                          </TableCell>
                                       </TableRow>

                                    </TableBody>
                                 </Table>
                              </TableContainer>

                           </Paper>
                        </FormControl>
                     </Box>
                  </Grid>
               </Grid>
            </Box>

         </Modal> */}
      </Protected>
   );
}