import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "./LuQuoteBook.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './LuQuoteBook_styles.css'
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import { DataEmail } from "./Modal/DataEmail";
// import { DataRut } from "./Modal/DataRut";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';
import { ROUTE_EV_LU_CONFIRM_EMITION, ROUTE_EV_LU_QUOTE_BOOK_RESUME } from "@/toolbox/constants/route-map";

import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Checkbox from '@mui/material/Checkbox';
import './LuQuoteBook_styles.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DataProductos } from "./Modal/DataProductos";
import { DataEditarCotizacion } from "./Modal/DataEditarCotizacion";
import { DataAgregar } from "./Modal/DataAgregar";

import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import { DataInformsFinal } from "./Modal/DataInformsFinal";
interface Column {
   id: 'numero' | 'codigo' | 'detalle'|'cantidad' | 'valor'|'unidad'| 'descuento'|'subtotal' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }
 interface Column1 {
   id: 'codigo' | 'detalle' | 'cantidad'|'valor' | 'tipo'| 'valorDescuento'|'total' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

const columns:readonly Column[] = [
   { id: 'numero', label: 'N°', minWidth: 10 },
   { id: 'codigo', label: 'Código', minWidth: 10 },
   { id: 'detalle', label: 'Detalle', minWidth: 10 },
   { id: 'cantidad', label: 'Cantidad', minWidth: 10 },
   { id: 'valor', label: 'Valor Neto', minWidth: 10 },
   { id: 'unidad', label: 'Unidad Medina', minWidth: 10 },
   { id: 'descuento', label: 'Descuento', minWidth: 10 },
   { id: 'subtotal', label: 'Subtotal', minWidth: 10 },
   ];
const columns1:readonly Column1[] = [
   { id: 'codigo', label: 'Código', minWidth: 10 },
   { id: 'detalle', label: 'Detalle', minWidth: 50 },
   { id: 'cantidad', label: 'Cantidad', minWidth: 5 },
   { id: 'valor', label: 'Valor Descuento', minWidth: 10 },
   { id: 'tipo', label: 'Tipo', minWidth: 10 },
   { id: 'valorDescuento', label: 'Valor Descuento', minWidth: 10 },
   { id: 'total', label: 'Total', minWidth: 10 },
   ];
interface Data {
   numero: number;
   codigo: string;
   detalle: string;
   cantidad: number;
   valor: number;
   tipo: string;
   valorDescuento: number;
   total: number;
   unidad: string;
   descuento: number;
   subtotal: number;
}

   function createData(
      numero: number,
      codigo: string,
      detalle: string,
      cantidad: number,
      valor: number,
      tipo: string,
      valorDescuento: number,
      total: number,
      ):Data {
      return { numero, codigo, detalle, cantidad, valor, tipo, valorDescuento, total, unidad: '', descuento: 0, subtotal: 0 };
   }

   const rowsData = [
      createData(1, '123', 'Prueba 1', 10, 5, 'C/U Serv', 0, 1000),
      createData(2, '123', 'Prueba 2', 10, 5, 'C/U Serv', 0, 1000),
      createData(3, '123', 'Prueba 3', 10, 5, 'C/U Serv', 0, 1000),
      createData(4, '123', 'Prueba 4', 10, 5, 'C/U Serv', 0, 1000),
      createData(5, '123', 'Prueba 5', 10, 5, 'C/U Serv', 0, 1000),
   ]

const tipos =[
   {label: '%', value: '%'},
   {label: '$', value: '$'},
]

export const LuQuoteFolio:React.FC<Props> = (props: Props): JSX.Element => {
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   // const [value, setValue] = useState('');
   const [value, setValue] = useState<Date | null>(new Date());
   const [loading, setLoading] = useState(false);
   const [pais, setPais] = useState('');
   const [estado, setEstado]= useState('');
   const [tipo, seTipo] =useState('');

   const [openArticulo, setOpenArticulo] = useState(false);
   const handleOpenArticulo= () => { 
      setOpenArticulo(true);
   }
   const handleCloseArticulo=()=> setOpenArticulo(false);

   const [openAdd, setOpenAdd] = useState(false);
   const handleOpenAdd= () => setOpenAdd(true);
   const handleCloseAdd=()=> setOpenAdd(false);

   const [openEdit, setOpenEdit] = useState(false);
   const handleOpenEdit= () => setOpenEdit(true);
   const handleCloseEdit=()=> setOpenEdit(false);

   const [openInform, setOpenInform] = useState(false);
   const handleOpenInform= () => setOpenInform(true);
   const handleCloseInform=()=> setOpenInform(false);

   const handleChange = (event: SelectChangeEvent) => {
      seTipo(event.target.value as string);
   };



   const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


    const handleChangePais = (event: SelectChangeEvent) => {
      setPais(event.target.value);
    };
    const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
    };
   function handleClick() {
     setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const direcciones=[
      {label:'Santiago', value:'Santiago'},
      {label:'Providencia', value:'Providencia'},
      {label:'Concepcion', value:'Concepcion'},
      {label:'Talca', value:'Talca'},
   ]


   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 1100,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

    return (
        <Protected>
        <Container maxWidth='xl'>
           <Grid container spacing={1}>
                  <Grid item xs={12} md={10} >
                     <Box>
                        <Typography variant='h6' component='h1' gutterBottom className="tittle">
                           EMITIENDO COTIZACION EV-LU
                        </Typography>
                     </Box>
                     <Box>
                        {/* <Typography variant='subtitle2'  gutterBottom >
                         Periodo: 2022 Mes: 04                        
                         </Typography> */}
                     </Box>
                     
                  </Grid>
                  <Grid item xs={12} md={2}>
                       <Grid container>

                           <Grid item xs={12} md={9}>
                              <IconButton>
                                 <ContentPasteSearchIcon onClick={handleOpenInform} />
                              </IconButton>
                           </Grid>
                           <Grid item xs={12} md={3}>
                                 <Button
                                    type='submit'
                                    variant='contained'
                                    
                                    // onClick={handleSubmit}
                                    // disabled={loading}
                                    disableElevation
                                    component={Link} to={ROUTE_EV_LU_QUOTE_BOOK_RESUME}
                                    className='btn_next'
                                 >
                                          VOLVER
                                 </Button>
                           </Grid>
                       </Grid>
                        
                  </Grid>
                  <Grid item xs={12} md={12}>
                        <Typography variant='subtitle2'  gutterBottom >
                         INFORMACION GENERAL                        
                         </Typography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                     <TextField
                        id="rut"
                        label="Rut Empresa"
                        type="text"
                        fullWidth
                     
                        variant="outlined"
                        defaultValue="7656576-7"
                        size="small"
                     />
                  </Grid>

                  <Grid item xs={12} md={2}>
                     <TextField
                        id="rut"
                        label="Razon Social"
                        type="text"
                        fullWidth
                     
                        variant="outlined"
                        defaultValue="Alan Monsalve"
                        size="small"
                     />
                  </Grid>
                  <Grid item xs={12} md={3}>
                     <TextField
                        id="rut"
                        label="Giro"
                        type="text"
                        fullWidth
                     
                        variant="outlined"
                        defaultValue="giro cliente 3"
                        size="small"
                     />
                  </Grid>
                  <Grid item xs={12} md={3}>
                     <TextField
                        id="rut"
                        label="Email"
                        type="text"
                        fullWidth
                     
                        variant="outlined"
                        defaultValue="jmora@test.cl"
                        size="small"
                     />
                  </Grid>

                  <Grid item xs={12} sm={2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Stack spacing={3}>
                                 <DatePicker
                                       // views={['year', 'month']}
                                       label="Fecha"
                                       minDate={new Date('2012-03-01')}
                                       maxDate={new Date('2023-06-01')}
                                       value={value}
                                       onChange={(newValue) => {
                                          setValue(newValue);
                                       }}
                                       renderInput={(params) => <TextField  {...params} helperText={null} />}
                                       />
                              </Stack>
                     </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12} md={6}>
                     <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={direcciones}
                           // sx={{ bgcolor: '#F3F3F3'}}
                           renderInput={(params) => <TextField {...params} label="Seleccione Direccion" />}
                        />
                  </Grid>

                  <Grid item xs={12} md={12} mt={2}>
                        <Typography variant='subtitle2'  gutterBottom >
                         COTIZACION                        
                         </Typography>
                  </Grid>

                  <Grid item xs={12} md={3}>
                     <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={direcciones}
                           // sx={{ bgcolor: '#F3F3F3'}}
                           renderInput={(params) => <TextField {...params} label="Condicion de Pago" />}
                        />
                  </Grid>
                  <Grid item xs={12} md={3}>
                     <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={direcciones}
                           // sx={{ bgcolor: '#F3F3F3'}}
                           renderInput={(params) => <TextField {...params} label="Area de Negocio" />}
                        />
                  </Grid>
                  <Grid item xs={12} md={3}>
                     <TextField
                        id="rut"
                        label="Vendedor"
                        type="text"
                        fullWidth
                        // margin="normal"
                        variant="outlined"
                        size="small"
                        // defaultValue="jmora@test.cl"
                     />
                  </Grid>
                  <Grid item xs={12} md={3}>
                     <TextField
                        id="rut"
                        label="Validez"
                        type="text"
                        fullWidth
                        // margin="normal"
                        variant="outlined"
                        size="small"
                        // defaultValue="jmora@test.cl"
                     />
                  </Grid>
                  <Grid item xs={12} md={4}>
                     <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={direcciones}
                           // sx={{ bgcolor: '#F3F3F3'}}
                           renderInput={(params) => <TextField {...params} label=" Seleccione Contacto" />}
                        />
                  </Grid>
                 
                  <Grid item xs={12} md={3}>
                     <TextField
                        id="rut"
                        label="Folio interno"
                        type="text"
                        fullWidth
                        // margin="normal"
                        variant="outlined"
                        size="small"
                        // defaultValue="jmora@test.cl"
                     />
                  </Grid>
                  <Grid item xs={12} md={3}>
                     <TextField
                        id="rut"
                        label="Folio Interno 2"
                        type="text"
                        fullWidth
                     
                        variant="outlined"
                        size="small"
                        // defaultValue="jmora@test.cl"
                     />
                  </Grid>
                  <Grid item xs={12} md={12} mt={2}>
                        <Typography variant='subtitle2'  gutterBottom >
                         GUIA                  
                         </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                     <TextField
                        id="rut"
                        label="Nº GUIA DE DESPACHO"
                        type="text"
                        fullWidth
                     
                        variant="outlined"
                        size="small"
                        // defaultValue="jmora@test.cl"
                     />
                  </Grid>
                  <Grid item xs={12} md={3}>
                     <TextField
                        id="rut"
                        label="N/V"
                        type="text"
                        fullWidth
                     
                        variant="outlined"
                        size="small"
                        // defaultValue="jmora@test.cl"
                     />
                  </Grid>
                  <Grid item xs={12} md={3}>
                     <TextField
                        id="rut"
                        label="ORDEN DE COMPRA"
                        type="text"
                        fullWidth
                     
                        variant="outlined"
                        size="small"
                        // defaultValue="jmora@test.cl"
                     />
                  </Grid>
            </Grid>

                  <Grid item xs={12} md={9} mt={3}>
                     <Box>
                        <Typography variant='subtitle2'  gutterBottom >
                           INSERTAR LINES DE DETALLE
                        </Typography>
                     </Box>
                     <Box>
                        {/* <Typography variant='subtitle2'  gutterBottom >
                         Periodo: 2022 Mes: 04                        
                         </Typography> */}
                     </Box>
                     
                  </Grid>


            <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={3}>
               LISTADO DE REGISTROS
            </Typography>
            <Paper sx={{ width: '100%', overflow: 'hidden'}} >
               
               <TableContainer sx={{maxHeight:440}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                        {columns1.map((column) => (
                           <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                           >
                              {column.label}
                           </TableCell>
                        ))}
                        </TableRow>
                     </TableHead>
                     <TableBody>
                      <TableRow>
                           <TableCell>
                              <div style={{display:'flex', justifyContent:'space-evenly'}}>
                                 <IconButton
                                    onClick={handleOpenArticulo}
                                 >
                                    <SearchIcon/>
                                 </IconButton>
                                 <TextField
                                    id="rut"
                                    // label="ORDEN DE COMPRA"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    // defaultValue="jmora@test.cl"
                                 />
                              </div>
                              
                           </TableCell>
                           <TableCell>
                              <TextField
                                 id="rut"
                                 // label="ORDEN DE COMPRA"
                                 type="text"
                                 fullWidth
                                 variant="outlined"
                                 size="small"
                                 // defaultValue="jmora@test.cl"
                              />
                           </TableCell>
                           <TableCell>
                              <TextField
                                 id="rut"
                                 // label="ORDEN DE COMPRA"
                                 type="text"
                                 fullWidth
                                 variant="outlined"
                                 size="small"
                                 // defaultValue="jmora@test.cl"
                              />
                           </TableCell>
                           <TableCell>
                              <TextField
                                 id="rut"
                                 // label="ORDEN DE COMPRA"
                                 type="text"
                                 fullWidth
                                 variant="outlined"
                                 size="small"
                                 // defaultValue="jmora@test.cl"
                              />
                           </TableCell>
                           <TableCell>
                           <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">%</InputLabel>
                              <Select
                                 labelId="demo-simple-select-label"
                                 id="demo-simple-select"
                                 // label="fdffd%"
                                 onChange={handleChange}
                                 size="small"
                              >
                                 <MenuItem value={10}>%</MenuItem>
                                 <MenuItem value={20}>$</MenuItem>

                              </Select>
                              </FormControl>
                           </TableCell>
                           <TableCell>
                              <TextField
                                 id="rut"
                                 // label="ORDEN DE COMPRA"
                                 type="text"
                                 fullWidth
                                 variant="outlined"
                                 size="small"
                                 defaultValue="0"
                              />
                           </TableCell>
                           <TableCell>
                              <TextField
                                 id="rut"
                                 // label="ORDEN DE COMPRA"
                                 type="text"
                                 fullWidth
                                 variant="outlined"
                                 size="small"
                                 // defaultValue="jmora@test.cl"
                              />
                           </TableCell>
                           <TableCell>
                                 <Button
                                    type='submit'
                                    variant='contained'
                                    
                                    // onClick={handleSubmit}
                                    // disabled={loading}
                                    disableElevation
                                    // component={Link} to={ROUTE_EV_QUOTE_BOOK_RESUME}
                                    className='btn_opcion'
                                 >
                                          <span>AGREGAR</span>
                                 </Button>
                                 <Button
                                    type='submit'
                                    variant='contained'
                                    
                                    // onClick={handleSubmit}
                                    // disabled={loading}
                                    disableElevation
                                    // component={Link} to={ROUTE_EV_QUOTE_BOOK_RESUME}
                                    className='btn_opcion1'
                                    style={{marginLeft:10}}
                                 >
                                          <span>LIMPIAR</span>
                                 </Button>
                           </TableCell>
                     
                        </TableRow>
                     </TableBody>   
                  </Table>  
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
            </Paper> 

            <Paper sx={{ width: '100%', overflow: 'hidden'}} >
               
               <TableContainer sx={{maxHeight:440}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {columns.map((column) => (
                              <TableCell
                              
                                 key={column.id}
                                 align={column.align}
                                 style={{ minWidth: column.minWidth }}
                              >
                                 {column.label}
                              </TableCell>
                           ))}
                           <TableCell>
                              Opciones
                           </TableCell>

                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.numero}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                    
                                       <Checkbox {...label}  />

                                   
                                    <IconButton
                                       >
                                          <EditIcon
                                          style={{ fontSize:'20px' }}
                                          onClick={handleOpenEdit}
                                       />
                                    </IconButton>

                                    <IconButton
                                       >
                                          <AddCircleIcon
                                          style={{ fontSize:'20px' }}
                                          onClick={handleOpenAdd}
                                       />
                                    </IconButton>


                                 </TableCell>

                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
            </Paper> 
            <Grid item xs={12} md={12} mt={2}>
               <Typography variant='subtitle2'  gutterBottom >
                RESUMEN DOCUMENTO                       
                </Typography>
            </Grid>
            <Grid container spacing={2}>
               <Grid item xs={12} md={6}>
                        <TextField
                           id="rut"
                           label="Obserbacion"
                           type="text"
                           fullWidth
                        
                           variant="outlined"
                           size="small"
                           // defaultValue="jmora@test.cl"
                        />
               </Grid>

               <Grid item xs={12} md={2}>
                        <TextField
                           id="rut"
                           label="Descuento Global"
                           type="text"
                           fullWidth
                        
                           variant="outlined"
                           size="small"
                           // defaultValue="jmora@test.cl"
                        />
               </Grid>

               <Grid item xs={12} md={2}>
                        <TextField
                           id="rut"
                           label="Subtotal"
                           type="text"
                           fullWidth
                        
                           variant="outlined"
                           size="small"
                           // defaultValue="jmora@test.cl"
                        />
               </Grid>

               <Grid item xs={12} md={2}>
                        <div style={{display:'flex', justifyContent:'space-evenly'}}>
                        <FormControl >
                              {/* <InputLabel id="demo-simple-select-label">%</InputLabel> */}
                                 <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    // label="fdffd%"
                                    onChange={handleChange}
                                    size="small"
                                 >
                                    <MenuItem value={10}>%</MenuItem>
                                    <MenuItem value={20}>$</MenuItem>

                                 </Select>
                        </FormControl>

                              <TextField
                                 id="rut"
                                 label="Descuento"
                                 type="text"
                                 fullWidth
                                 variant="outlined"
                                 size="small"
                                 // defaultValue="jmora@test.cl"
                                 />
                              </div>
                              
               </Grid>

               <Grid item xs={12} md={3}>
                        <TextField
                           id="rut"
                           label="Total Afecto"
                           type="text"
                           fullWidth
                        
                           variant="outlined"
                           size="small"
                           defaultValue="28156"
                        />
               </Grid>

               <Grid item xs={12} md={3}>
                        <TextField
                           id="rut"
                           label="19%"
                           type="text"
                           fullWidth
                        
                           variant="outlined"
                           size="small"
                           defaultValue="5349.64"
                        />
               </Grid>

               <Grid item xs={12} md={3}>
                        <TextField
                           id="rut"
                           label="Exento"
                           type="text"
                           fullWidth
                        
                           variant="outlined"
                           size="small"
                           defaultValue="0"
                        />
               </Grid>

               <Grid item xs={12} md={3}>
                        <TextField
                           id="rut"
                           label="Total"
                           type="text"
                           fullWidth
                        
                           variant="outlined"
                           size="small"
                           defaultValue="33505.64"
                        />
               </Grid>
            </Grid>
            <Grid item container
                           xs={12}
                           mt={2}
                        justifyContent='center'
                        >
                              <Grid item>
                                 <Button
                                    type='submit'
                                    variant='contained'
                                    
                                    // onClick={handleSubmit}
                                    // disabled={loading}
                                    disableElevation
                                    component={Link} to={ROUTE_EV_LU_CONFIRM_EMITION}
                                    className='btn_next'
                                 >
                                          INGRESAR
                                 </Button>
                              </Grid>
            </Grid>
         </Container>

          <Modal
            open={openArticulo}
            onClose={handleCloseArticulo}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataProductos entity={undefined} />
            </Box>
         </Modal>
         <Modal
            open={openEdit}
            onClose={handleCloseEdit}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataEditarCotizacion entity={undefined} />
            </Box>
         </Modal> 
         <Modal
            open={openAdd}
            onClose={handleCloseAdd}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataAgregar entity={undefined} />
            </Box>
         </Modal>
         <Modal
            open={openInform}
            onClose={handleCloseInform}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataInformsFinal entity={undefined} />
            </Box>
         </Modal>


        
        
     </Protected>
    );
   };

