import React, { useState, useMemo, useRef, useEffect } from 'react';
import { InputAdornment, IconButton, Step, StepLabel, Button, CircularProgress, Grid, CssBaseline, Container, Modal, Box, Divider, Typography, FormControl, Select, MenuItem, TextField } from '@mui/material'
import { ArrowBackIcon, ArrowDownIcon, ArrowRightIcon, VisibilityIcon, VisibilityOffIcon } from "@toolbox/constants/icons";
import { Icon } from '@components/common/Icon';
import User from "@assets/img/user.png";
import { Link } from 'react-router-dom';
import { indigo } from '@mui/material/colors';
import { useHistory } from 'react-router-dom'
import { Props } from './FormAdminUser.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import { userService } from '@/service/services/User.service';
// import { entityService } from '@/service/services/Entity.service';
// import { ROUTE_ENTITY } from '@toolbox/constants/route-map';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import BadgeIcon from '@mui/icons-material/Badge';
import { ROUTE_PROVIDER_CREATE } from '@/toolbox/constants/route-map';
import WorkIcon from '@mui/icons-material/Work';
import PhoneIcon from '@mui/icons-material/Phone';
import PaymentIcon from '@mui/icons-material/Payment';

const getPassword = () => {
   var chars = "$+=?@_0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
   var password = '';
   for (let i = 0; i < 8; i++) {
      password += chars.trim().charAt(Math.floor(Math.random() * chars.length));
   }
   return password;
}

export const FormAdminUser: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const history = useHistory();
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false)
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [estado, setEstado] = useState<any>({ user_nick: false, email: false })
   const [showPassword, setShowPassword] = useState<boolean>(false)
   console.log(props.valueEntity)
   console.log(props.valueContactEntity)
   console.log(props.valueAdminUser)
   useEffect(() => {
      if (props.valueAdminUser.action) {
         if (props.valueAdminUser.action == 'view') {
            setIsDisabled(true);
         }
      } else {
         props.setValueAdminUser(prev => ({ ...prev, idtipousuario: 2, password: getPassword(), textError: '' }));
      }

   }, [])

   const rules = useMemo(() => ({
      string_lowecase: [
         VALIDATORS.REQUIRED,
         VALIDATORS.ONLY_LOWERCASE,
      ],
      string_required: [
         VALIDATORS.REQUIRED,
      ],
      razonsocial: [
         VALIDATORS.REQUIRED,

      ],
      email: [
         VALIDATORS.EMAIL.NAME_PONTS,
         VALIDATORS.EMAIL.POINTS_IN_A_ROW,
         VALIDATORS.EMAIL.NAME_SIZE,
         VALIDATORS.EMAIL.VALID,
      ],
      password: [
         VALIDATORS.REQUIRED,
         VALIDATORS.PASSWORD.SIZE,
         VALIDATORS.PASSWORD.ALLOWED_CHARACTERS,
         VALIDATORS.PASSWORD.VALID,
      ],
   }), []);


   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'nombre':
            props.setValueAdminUser(prev => ({ ...prev, nombre: value }));
            break;
         case 'user_nick':
            props.setValueAdminUser(prev => ({ ...prev, user_nick: value }));
            break;
         case 'email':
            if ((/^[\w@.-]{0,86}$/).test(value)) {
               props.setValueAdminUser(prev => ({ ...prev, email: value }));
            }
            props.setValueAdminUser(prev => ({ ...prev, email: value }));
            break;
         case 'idtipousuario':
            props.setValueAdminUser(prev => ({ ...prev, idtipousuario: value }))
            break;
         case 'telefono':
            props.setValueAdminUser(prev => ({ ...prev, telefono: value }))
            break;
         case 'password':
            if (/^.{0,15}$/.test(value)) {
               props.setValueAdminUser(prev => ({ ...prev, password: value }));
            }
            break;
         default:
            break;
      }
   };


   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      props.setValueAdminUser(prev => ({ ...prev, [name]: newVal }));
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }
   const handleClickShowPassword = () => {
      setShowPassword(!showPassword)
      // setData({ ...data, showPassword: !data.showPassword });
   };

   const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
   };

   return (
      <Container maxWidth="xl">
         {/* <form onSubmit={handleSubmit}> */}
         <form>
            <Grid container spacing={2} className={`e-mb-lg`}>
               <Grid item xs={12}></Grid>
               <Grid item xs={4}>
                  <Input
                    className='Input'
                    prependInnerAdornment={
                     <InputAdornment position="end">
                     <BadgeIcon />
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="rut_empresa"
                     type='text'
                     placeholder="Nombre de Contacto"
                     //value={data.rut_empresa}
                     value={props.valueEntity.rut_empresa}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     // rules={rules.rutempresa}
                     disableElevation
                     validateOnBlur
                     rules={rules.razonsocial}
                     dense
                     height={35}
                     disabled={isDisabled}
                  />
               </Grid>
               
               <Grid item xs={4}>
                  <Input
                    className='Input'
                    prependInnerAdornment={
                     <InputAdornment position="end">
                     <WorkIcon />
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="rut_empresa"
                     type='text'
                     placeholder="Cargo"
                     //value={data.rut_empresa}
                     value={props.valueEntity.rut_empresa}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     // rules={rules.rutempresa}
                     disableElevation
                     validateOnBlur
                     rules={rules.razonsocial}
                     dense
                     height={35}
                     disabled={isDisabled}
                  />
               </Grid>
               <Grid item xs={4}>
                  <Input
                     ref={ref => inputRefs.current[6] = ref}
                     prependInnerAdornment={
                     <InputAdornment position="end">   
                     <EmailIcon />
                    </InputAdornment>
                    }
                     height={35}
                     name="email"
                     placeholder="Email"
                     //value={data.fax}
                     // value={props.valueEntity.email}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.email}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                  />
               </Grid>
               <Grid item xs={4}>
                  <Input
                    className='Input'
                    prependInnerAdornment={
                     <InputAdornment position="end">
                     <PhoneIcon />
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="rut_empresa"
                     type='text'
                     
                     placeholder="Teléfono"
                     //value={data.rut_empresa}
                     value={props.valueEntity.rut_empresa}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     rules={rules.razonsocial}
                     disableElevation
                     validateOnBlur
                     dense
                     height={35}
                     disabled={isDisabled}
                  />
               </Grid>
               <Grid item xs={4}>
                  <Input
                    className='Input'
                    prependInnerAdornment={
                     <InputAdornment position="end">
                     <PaymentIcon />
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="rut_empresa"
                     type='text'
                     placeholder="Pago"
                     //value={data.rut_empresa}
                     value={props.valueEntity.rut_empresa}
                     onChange={handleInput}
                     //onBlur={handleInputBlur}
                     backgroundColor="#F3F3F3"
                     // rules={rules.rutempresa}
                     disableElevation
                     validateOnBlur
                     rules={rules.razonsocial}
                     dense
                     height={35}
                     disabled={isDisabled}
                  />
               </Grid>

               <Grid container item xs={12}
                  direction="row"
                  justifyContent="space-between"
               >
                  <Grid item >
                     <Button
                        variant='contained'
                        onClick={props.handleBack}
                        disabled={loading}
                        disableElevation
                        startIcon={<ArrowBackIosIcon />}
                     >
                        <span>Volver</span>
                     </Button>
                  </Grid>

                  <Grid item >
                     <Button component={Link} to={ROUTE_PROVIDER_CREATE} variant="contained" endIcon={<ArrowRightIcon />}>
                        Guardar
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
         </form>
         <Modal
            open={openModal}
            //onClose={() => { setOpenModal(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={{
               position: 'absolute',
               top: '50%',
               left: '50%',
               transform: 'translate(-50%, -80%)',
               width: 300,
               bgcolor: '#ffffff',
               boxShadow: 24,
               p: 4,
            }}>
               <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ mb: 2 }}>
                  <Box
                     component="img"
                     textAlign="center"
                     sx={{
                        width: "100px",
                        height: "100px",
                     }}
                     src={User}
                  ></Box>
               </Grid>
               <Divider />
               <Typography id="modal-modal-description" textAlign='center' sx={{ mt: 2, mb: 2 }}>
                  {`El ${estado.user_nick ? 'User Nick, ' : ''}${estado.email ? 'Email,' : ''} ya esta tomado por otro usuario`}
               </Typography>
               <Divider />
               <Grid container item xs={12} sx={{ pt: 2 }}
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center">
                  <Grid item>
                     <Button
                        fullWidth
                        variant='contained'
                        onClick={() => { setOpenModal(false) }}
                     >
                        Aceptar
                     </Button>
                  </Grid>
               </Grid>
            </Box>
         </Modal>
      </Container>
   );
}
