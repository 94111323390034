import React,{ FunctionComponent, useState } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './GcInformRoyalty_styles.css';
import TextField from '@mui/material/TextField';
import { Box, Button, Grid,Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { fontSize } from "@mui/system";
import { Protected } from "@/components/layout/Protected";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateRangePicker, { DateRange } from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Container, } from '@mui/material'

interface TableMOnthProductProps {
    
}

interface Column {
    id: 'numero' | 'familia' | 'cantidad'|'totalVenta' | 'totalventa2'|'royalty'|'totalRoyalty' ;
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
  }
 
 const columns:readonly Column[] = [
      { id: 'numero', label: 'N', minWidth: 10 },
      { id: 'familia', label: 'Familia', minWidth: 100 },
      { id: 'cantidad', label: 'Cantidad', minWidth: 50 },
      { id: 'totalVenta', label: 'Total Venta ($)', minWidth: 100 },
      { id: 'totalventa2', label: 'Total Venta U.S.', minWidth: 100 },
      { id: 'royalty', label: ' % Royalty', minWidth: 100 },
      { id: 'totalRoyalty', label: 'Total Royalty', minWidth: 80 },
    ];
 
 interface Data {
      numero: number;
      familia: string;
      cantidad: number;
      totalVenta: number;
      totalventa2: number;
      royalty: number;
      totalRoyalty: number;
 }
 
 function createDataRotalty(
      numero: number,
      familia: string,
      cantidad: number,
      totalVenta: number,
      totalventa2: number,
      royalty: number,
      totalRoyalty: number,
 ): Data {
    return { numero, familia, cantidad, totalVenta, totalventa2, royalty, totalRoyalty };
 }
 

 const rowsDataRotalty = [
    createDataRotalty(1, 'Familia 1', 1, 100, 100, 10, 1451.54),
 ]

 const StyledTableCell = styled(TableCell)(({ theme }) => ({
   [`&.${tableCellClasses.head}`]: {
     backgroundColor: '#eff2f0',
     fontSize: 14,
     color: theme.palette.common.black,
   },
   [`&.${tableCellClasses.body}`]: {
     fontSize: 14,
   },
 }));

 const StyledTableRow = styled(TableRow)(({ theme }) => ({
   '&:nth-of-type(odd)': {
     backgroundColor: theme.palette.action.hover,
   },
   // hide last border
   '&:last-child td, &:last-child th': {
     border: 0,
   },
 }));
 
export const GcInformRoyalty: FunctionComponent<TableMOnthProductProps> = () => {
    const [error, setError] = useState(null);
    const [page , setPage]= useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [value, setValue] = React.useState<DateRange<Date>>([null, null]);
    const [show, setShow] = useState(false);
    
    const handleChangeShow = () => {
       setShow(!show);
      }
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
     };
  
     const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
     };
    return ( 
        <>
        <Protected >
        <Container maxWidth='xl'>
         <Grid container spacing={2}>
               <Grid item xs={8} md={10} textAlign="start" mb={2}>
                     <Typography mt={1} variant='h5' component='h1' gutterBottom className="tittle">
                     INFORME ROYALTY
                     </Typography>
                  </Grid>
                  <Grid item xs={4} md={2} className="btn-download" mb={2}>
                           <Box>
                              <LoadingButton
                              // color="warning"
                           //    onClick={handleClick}
                           //    loading={loading}
                              loadingPosition="center"
                              startIcon={<FileDownloadIcon />}
                              variant="contained"
                              style={{backgroundColor:'#16556b',color:'#ffffff', fontSize:'14px !important'}}
                              className="btn-download"
                              >
                              Exportar
                              </LoadingButton>
                           </Box>
                  </Grid>

               <Grid item xs={12} md={12} >

                     <Typography  mt={1} variant='h6' component='h1' gutterBottom className="tittle4">
                     Fecha : <span style={{color:'#000000', marginLeft:'27px', fontWeight:'400'}}>07-03-2022</span>
                     </Typography>

                     <Typography   mt={1} variant='h6' component='h1' gutterBottom className="tittle4">
                     Cambio : <span style={{color:'#000000',marginLeft:'15px', fontWeight:'400'}}>806.89</span>
                     </Typography>
               </Grid>
               <Grid item xs={12} md={12} mt={-1} textAlign="start" >
                     <Typography  variant='h6'  gutterBottom className="tittle4">
                     Fecha inicio: <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateRangePicker
                        startText="Inicio"
                        endText="Termino"
                        value={value}
                        onChange={(newValue) => {
                           setValue(newValue);
                        }}
                        renderInput={(startProps, endProps) => (
                           <>
                                 <TextField {...startProps}  size="small" />
                                 <Typography  sx={{ mx: 2 }} variant='h6' component='h1' gutterBottom className="tittle4">
                                 hasta
                                 </Typography>
                                 <TextField {...endProps}  size="small" />
                           </>
                        )}
                        />
                     </LocalizationProvider>
                     </Typography>
                  </Grid>
                 
               <Grid item container
                     xs={12}
                     mt={2}
                  justifyContent='right'
                  mr={3}
   >
                        <Grid item>
                           <Button
                              type='submit'
                              variant='contained'
                              onClick={handleChangeShow}
                              // disabled={loading}
                              disableElevation
                              className='btn_save3'
                              
                           >
                                    <span>Consultar</span>
                           </Button>
                        </Grid>
                  </Grid> 
            </Grid>
            <Grid item xs={12} md={12}>
            {/* <Typography variant="h6" gutterBottom mb={3} className="tittleIn" align="center" mt={2}>
                  LISTADO
               </Typography> */}

              {show && <Paper sx={{ width: '100%', overflow: 'hidden'}}>
                  <TableContainer sx={{maxHeight:440}} component={Paper}>
                     <Table stickyHeader aria-label="customized table" >
                        <TableHead >
                           <TableRow >
                              {columns.map((column) => (
                                    <StyledTableCell
                                       key={column.id}
                                       align={column.align}
                                       style={{ minWidth: column.minWidth }}
                                       >
                                       {column.label}
                                       </StyledTableCell>
                              ))}
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {rowsDataRotalty.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                              return (
                                 <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.numero}>
                                    {columns.map((column) => {
                                       const value = row[column.id];
                                       return (
                                          <StyledTableCell key={column.id} align={column.align}>
                                             {column.format && typeof value === 'number'
                                                ? column.format(value)
                                                : value}
                                          </StyledTableCell>
                                       );
                                    })}
                                    
                                 </StyledTableRow>
                              );
                           })}
                        
                        </TableBody>   
                     </Table>  
                  </TableContainer>
                  <TablePagination
                     rowsPerPageOptions={[10, 25, 100]}
                     component="div"
                     count={rowsDataRotalty.length}
                     rowsPerPage={rowsPerPage}
                     page={page}
                     onPageChange={handleChangePage}
                     onRowsPerPageChange={handleChangeRowsPerPage}
                     />
               </Paper>}
            </Grid>
         </Container>
            </Protected>
        </>
     );
}
 
