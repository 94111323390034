import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Badge, Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import Fade from '@mui/material/Fade';
import { Link } from 'react-router-dom';
import { ROUTE_HOME, ROUTE_GENERATOR_TICKET, ROUTE_GENERATOR_DETAIL, ROUTE_LOGIN, ROUTE_GENERATOR_CANCELED, ROUTE_GENERATOR_NOT_ISSUED, ROUTE_PROVIDER } from '@/toolbox/constants/route-map';
import { DrawerComponent } from './Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import Avatar from '@mui/material/Avatar';
import { readLocalStorage, removeLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_PARMS, KEY_TOOGLE_MENU, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { authenticationService } from '@/service/services/Authentication.service';

import Logo from '@assets/img/nupy-logo.png';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import LogoutIcon from '@mui/icons-material/Logout';
import PreviewIcon from '@mui/icons-material/Preview';
import { MenuResponsive } from './MenuResposive';
import './MenuResposive/estilos.css';
import Container from '@mui/material/Container';

type Props = {
}
const Search = styled('div')(({ theme }) => ({
   position: 'relative',
   borderRadius: theme.shape.borderRadius,
   backgroundColor: alpha(theme.palette.common.white, 0.15),
   '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
   },
   marginRight: theme.spacing(2),
   marginLeft: 0,
   width: '100%',
   [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
   },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
   padding: theme.spacing(0, 2),
   height: '100%',
   position: 'absolute',
   pointerEvents: 'none',
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
   color: 'inherit',
   '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
         width: '20ch',
      },
   },
}));

export const Header: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const history= useHistory();

   // Método para salir de sesión
   const handleLogout = () => {
      removeLocalStorage(KEY_PARMS);
      removeLocalStorage(KEY_TOOGLE_MENU)
      removeLocalStorage(KEY_USER_DATA);
      localStorage.removeItem('dataUser');
      window.location.replace('/');
   }

   const [anchorEl, setAnchorEl] = React.useState(null);

   const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
      React.useState<null | HTMLElement>(null);

   const [mobileMoreAnchorElButton, setMobileMoreAnchorElButton] =
      React.useState<null | HTMLElement>(null);


   const isMenuOpen = Boolean(anchorEl);
   const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
   //button
   const isMobileButtonOpen = Boolean(mobileMoreAnchorElButton);

   const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };

   const handleMobileMenuClose = () => {
      setMobileMoreAnchorEl(null);
   };
   //nutton
   const handleMobileButtonClose = () => {
      setMobileMoreAnchorElButton(null);
   };

   const handleMenuClose = () => {
      setAnchorEl(null);
      handleMobileMenuClose();
   };

   const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
      setMobileMoreAnchorEl(event.currentTarget);
   };
   const handleMobileButtonOpen = (event: React.MouseEvent<HTMLElement>) => {
      setMobileMoreAnchorElButton(event.currentTarget);
   };

   //button

   const menuId = 'primary-search-account-menu';
   

   const renderMenu = (
      <Menu
         anchorEl={anchorEl}
         anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
         }}
         id={menuId}
         keepMounted
         transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
         }}
         open={isMenuOpen}
         onClose={handleMenuClose}
      >
         <MenuItem onClick={handleMenuClose}><PreviewIcon sx={{marginRight:'10px'}}/> Ver Perfil</MenuItem>
         <MenuItem onClick={()=>handleLogout()}><LogoutIcon sx={{marginRight:'10px'}}/>Salir</MenuItem>
      </Menu>
   );

   const mobileMenuId = 'primary-search-account-menu-mobile';
    const mobileButtonId = 'primary-search-account-button-mobile';
   const renderMobileMenu = (
      <Menu
         anchorEl={mobileMoreAnchorEl}
         anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
         }}
         id={mobileMenuId}
         keepMounted
         transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
         }}
         open={isMobileMenuOpen}
         onClose={handleMobileMenuClose}
      >
         <MenuItem>
            <IconButton size="small" aria-label="show 4 new mails" color="inherit">
               <Badge badgeContent={4} color="error">
                  <MailIcon />
               </Badge>
            </IconButton>
            <p className="ico">Messages</p>
         </MenuItem>
         <MenuItem>
            <IconButton
               size="small"
               aria-label="show 17 new notifications"
               color="inherit"
            >
               <Badge badgeContent={17} color="error">
                  <NotificationsIcon />
               </Badge>
            </IconButton>
            <p className="ico">Notifications</p>
         </MenuItem>
         <MenuItem onClick={handleProfileMenuOpen}>
            <IconButton
               // className="icon"
               size="small"
               aria-label="account of current user"
               aria-controls="primary-search-account-menu"
               aria-haspopup="true"
               color="inherit"
            >
               <AccountCircle />
            </IconButton>
            <p className="ico">Profile</p>
         </MenuItem>
      </Menu>
   );
   const renderMobileButton = (
      <Menu
         anchorEl={mobileMoreAnchorElButton}
         anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
         }}
         id={mobileButtonId}
         keepMounted
         transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
         }}
         open={isMobileButtonOpen}
         onClose={handleMobileButtonClose}
      >
         <MenuItem>
            <Button style={{ borderRadius: '20px', marginLeft: '5px', marginRight: '5px' }} variant='contained' color='primary'>ZENDY</Button>
         </MenuItem>
         <MenuItem>
            <Button style={{ borderRadius: '20px', marginLeft: '5px', marginRight: '5px' }} variant='contained' color='secondary'>CRM</Button>
         </MenuItem>
         <MenuItem>
            <Button style={{ borderRadius: '20px', marginLeft: '5px', marginRight: '5px' }} variant='contained' color='warning'>DOCSET</Button>
         </MenuItem>
         <MenuItem>
            <Button style={{ borderRadius: '20px', marginLeft: '5px', marginRight: '5px' }} variant='contained' color='success'>NUPY</Button>
         </MenuItem>
      </Menu>
   );
   // const theme = useTheme();
   // const isMobile = useMediaQuery(theme.breakpoints.down("md"));

   return (
      <>
            <Box>
               <Container >

                  <AppBar position="static"
                     sx={{ background: '#ffffff', boxShadow:'none' }}
                  >
                  <Toolbar>
                              <Box sx={{marginLeft:'10px',marginRight:'30px',marginTop:'5px'}}>
                              <img style={{ width: "150px" }} src={Logo} />
                              </Box>

                              <Box sx={{ display: {xs:'none', md: 'flex' } }}>
                                 <Button className='btn'>ZENDY</Button>
                                 <p className='separate'>|</p>
                                 <Button className='btn'>CMR</Button>
                                 <p className='separate'>|</p>
                                 <Button className='btn'>DOCSET</Button>
                                 <p className='separate'>|</p>
                                 <Button className='btn'>NUPY</Button>
                              </Box>

                              <Box sx={{ flexGrow: 12 }} />
                              <Search className='styleShearch'>
                                 <SearchIconWrapper>
                                    <SearchIcon />
                                 </SearchIconWrapper>
                                 <StyledInputBase
                                    placeholder="Search…"
                                    inputProps={{ 'aria-label': 'search' }}
                                 />
                              </Search>

                              <Box sx={{ flexGrow: 1 }} />
                              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                                 <IconButton size="small" aria-label="show 4 new mails" color="inherit">
                                    <Badge badgeContent={4} color="error">
                                          <MailIcon className='color'/>
                                    </Badge>
                                 </IconButton>
                                 <IconButton
                                    size="small"
                                    aria-label="show 17 new notifications"
                                    color="inherit"
                                 >
                                    <Badge badgeContent={17} color="error">
                                          <NotificationsIcon className='color'/>
                                    </Badge>
                                 </IconButton>
                                 <IconButton
                                    size="small"
                                    edge="end"
                                    aria-label="account of current user"
                                    aria-controls={menuId}
                                    aria-haspopup="true"
                                    onClick={handleProfileMenuOpen}
                                 >
                                    <AccountCircle  className='color'/>
                                 </IconButton>
                              </Box>
                              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                                 <IconButton
                                    size="small"
                                    aria-label="show more"
                                    aria-controls={mobileButtonId}
                                    aria-haspopup="true"
                                    onClick={handleMobileButtonOpen}
                                 >
                                    <PersonPinIcon />
                                 </IconButton>
                              </Box>
                              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                                 <IconButton
                                    size="small"
                                    aria-label="show more"
                                    aria-controls={mobileButtonId}
                                    aria-haspopup="true"
                                    onClick={handleMobileMenuOpen}
                                 >
                                    <MoreIcon/>
                                 </IconButton>
                              </Box>
                  </Toolbar>
                  </AppBar>
               </Container>

               {renderMobileButton}
               {renderMobileMenu}

               {renderMenu}
            </Box>
         <MenuResponsive/>
      </>
   );
}
