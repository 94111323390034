import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { Grid, Container, Typography,IconButton, SelectChangeEvent, Autocomplete, Button, Tooltip,} from '@mui/material'
import './EcClientSell_styles.css'
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateRangePicker, { DateRange } from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Informe } from './Informe';
import { DataModalRuts } from './DataModalRuts';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

interface EcClientSellProps {
    
}
interface ColumnRegistroIngresado {
   id: 'numero' | 'fecha' | 'folio' | 'tipoDocumento' | 'rut' | 'razonSocial' | 'total';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}
interface ColumnRegistroLibroVentas {
   id: 'tipo' | 'cantidad' | 'exento' | 'especifico' | 'valor neto' | 'iva' | 'total';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly ColumnRegistroIngresado[] = [
   { id: 'numero', label: 'Número', minWidth: 100 },
   { id: 'fecha', label: 'Fecha', minWidth: 100 },
   { id: 'folio', label: 'Folio', minWidth: 100 },
   { id: 'tipoDocumento', label: 'Tipo Documento', minWidth: 100 },
   { id: 'rut', label: 'Rut', minWidth: 100 },
   { id: 'razonSocial', label: 'RazonSocial', minWidth: 100 },
   { id: 'total', label: 'Total', minWidth: 100 },
];
const ColumnRegistroLibroVentas: readonly ColumnRegistroLibroVentas[] = [
   { id: 'tipo', label: 'Tipo', minWidth: 100 },
   { id: 'cantidad', label: 'Cantidad', minWidth: 100 },
   { id: 'exento', label: 'Exento', minWidth: 100 },
   { id: 'especifico', label: 'Especifico', minWidth: 100 },
   { id: 'valor neto', label: 'Valor Neto', minWidth: 100 },
   { id: 'iva', label: 'Iva', minWidth: 100 },
   { id: 'total', label: 'Total', minWidth: 100 },

];


interface DataIgreso {
   numero: string;
   fecha: string;
   folio: string;
   tipoDocumento: string;
   rut: string;
   razonSocial: string;
   total: string;
}
interface DataLibroVentas {
   tipo: string;
   cantidad: string;
   exento: string;
   especifico: string;
   valorNeto: string;
   iva: string;
   total: string;
}


function createDataIngreso(
   numero: string,
   fecha: string,
   folio: string,
   tipoDocumento: string,
   rut: string,
   razonSocial: string,
   total: string,
):DataIgreso{
return { numero, fecha, folio, tipoDocumento, rut, razonSocial, total };
}


function createDataLibroVenta(
   tipo: string,
   cantidad: string,
   exento: string,
   especifico: string,
   valorNeto: string,
   iva: string,
   total: string,
):DataLibroVentas{
return { tipo, cantidad, exento, especifico, valorNeto, iva, total };
}


const rowsDataLibroVentas = [
   createDataLibroVenta('1', '1', '1', '1', '1', '1', '1'),
]

const rowsDataIgreso = [
   createDataIngreso('1', '1', '1', '1', '1', '1', '1'),
]

 const ruts=[
      {label:'223214567-3',value:'223214567-3'},
      {label:'223214567-4',value:'223214567-4'},
      {label:'223214567-5',value:'223214567-5'},
      {label:'223214567-6',value:'223214567-6'},
      {label:'223214567-7',value:'223214567-7'},
      {label:'223214567-8',value:'223214567-8'},
      {label:'223214567-9',value:'223214567-9'},
      {label:'223214567-10',value:'223214567-10'},
 ]
 const options=[
      {label:'x Factura',value:'factura'},
      {label:'x Producto',value:'producto'},
 ]
export const EcClientSell: React.FunctionComponent<EcClientSellProps> = () => {
   const [value, setValue] = React.useState<DateRange<Date>>([null, null]);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   // const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [ejecutivoComercial, setEjecutivoComercial] = useState('');
   const [estado, setEstado] = useState('');
   const [openRut, setOpenRut]=useState(false);
   const handleOpenRut=()=> setOpenRut(true);
   const handleCloseRut=()=> setOpenRut(false);
   
   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const estados = [
      { value: '1', label: 'Activo' },
      { value: '2', label: 'Desactivado' },
   ]

   const handleSubmit=(e:React.FormEvent<HTMLFormElement>)=>{
      e.preventDefault();
      window.location.href='/ec-client-sell/informe';
   }

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 720,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };
    return ( 
        <Protected>
        <Container maxWidth='xl'>
           
           <Grid container spacing={3}>

                  <Grid item xs={12}>
                     <Grid>
                        <Typography variant='h6' component='h1' gutterBottom className="tittle" >
                           VENTAS POR CLIENTE
                        </Typography>
                     </Grid>
                     
                  </Grid>
                  
                  <Grid item xs={12} md={6} textAlign="center" >
                        <Typography className='bg' variant='h6' component='h1' gutterBottom  mt={1}>
                        Angelo Mauricio Cabello Saez - (10366537-K)
                        </Typography>                     
                  </Grid>

                  <Grid item xs={12} md={5} alignItems="center" display="flex"  >

                     <Grid item xs={10} md={6}>
                        <Autocomplete
                           
                           disablePortal
                           id="combo-Grid-demo"
                           options={ruts}
                           fullWidth
                           // sx={{ width: 300 }}
                           size='small'
                           renderInput={(params) => <TextField {...params} label="Rut de Empresa" />}
                        />
                     </Grid>

                     <Grid item xs={2} md={2} >
                           <Tooltip title="Buscar Empresa">
                              <Button  className='btnIcoE' onClick={handleOpenRut}><SearchIcon /></Button>
                           </Tooltip>
                     </Grid>

                  </Grid>
                  
                  

               <Grid item xs={12} md={3}>
                  <Typography mt={1} variant='h6' component='h1' gutterBottom className="tittle">
                     Ventas Ultimo Mes :
                  </Typography>
               </Grid>
               <Grid  item xs={12} md={3}>
                  <TextField
                     value='$ 14.399.000 (03-2022)'
                     fullWidth
                     size='small'
                  />
               </Grid>
                     

               <Grid item xs={12} md={3} >
                  <Typography mt={1} variant='h6' component='h1' gutterBottom className="tittle">
                     Ventas Periodo :
                  </Typography>
               </Grid>
               <Grid item xs={12} md={3} textAlign="start">
                  <TextField
                     value='$ 14.470.000 (2022)'
                     fullWidth
                     size='small'
                  />
               </Grid>

               <Grid item xs={12} md={3} >
                  <Typography mt={1} variant='h6' component='h1' gutterBottom className="tittle">
                     Facturas Impagas :
                  </Typography>
               </Grid>
               <Grid item xs={12} md={3} >
                  <TextField
                     value='13'
                     fullWidth
                     size='small'
                  />
               </Grid>
                     

               <Grid item xs={12} md={3}>
                  <Typography mt={1} variant='h6' component='h1' gutterBottom className="tittle">
                     $ Facturas Impagas :
                  </Typography>
               </Grid>

               <Grid item xs={12} md={3} textAlign="start">
                  <TextField
                     value='$ 15.814.204'
                     fullWidth
                     size='small'
                  />
               </Grid>
                     

               <Grid item xs={12} md={3}>
                  <Typography mt={1} variant='h6' component='h1' gutterBottom className="tittle">
                     Cheques en Cartera :
                  </Typography>
               </Grid>
               <Grid item xs={12} md={3} >
                  <TextField
                     value='0'
                     fullWidth
                     size='small'
                  />
               </Grid>
                     

               <Grid item xs={12} md={3}>
                  <Typography mt={1} variant='h6' component='h1' gutterBottom className="tittle">
                  $ Cheques en Cartera :
                  </Typography>
               </Grid>
               <Grid item xs={12} md={3} >
                  <TextField
                     value='$ 0'
                     fullWidth
                     size='small'
                  />
               </Grid>
               <Grid item xs={12} md={3}>
                  <Typography mt={2} variant='h6' component='h1' gutterBottom className="tittle">
                  Resumen de Ventas :
                  </Typography>
               </Grid>
               <Grid item xs={12} md={3}>
                     <Autocomplete
                              disablePortal
                              size='small'
                              id="combo-box-demo"
                              options={options}
                              // sx={{ bgcolor: '#F3F3F3'}}
                              renderInput={(params) => <TextField {...params} label="Solicitado Por" />}
                           />
               </Grid>

               
               <Grid item xs={12} md={12}>
               </Grid>
             
               <Grid item xs={12} md={3} textAlign="end" mt={-3}>
                  <Typography mt={1} variant='h6' component='h1' gutterBottom className="tittle">
                  Fecha:
                  </Typography>
               </Grid>
               <Grid item xs={12} md={9} mt={-3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                     <DateRangePicker
                     startText="Inicio"
                     endText="Termino"
                     value={value}
                     onChange={(newValue) => {
                        setValue(newValue);
                     }}
                     renderInput={(startProps, endProps) => (
                        <>
                              <TextField {...startProps}  size="small" />
                              <Box sx={{ mx: 2 }}> hasta </Box>

                              <TextField {...endProps}  size="small"/>
                        </>
                     )}
                     />
                  </LocalizationProvider>
               </Grid>
                        
           </Grid>

            <Grid item container
                  xs={12}
                  mt={2}
                 justifyContent='center'
                 >
                     <Grid item>
                        <Button
                           type='submit'
                           variant='contained'
                           // onClick={() => {
                           // }}
                           // disabled={loading}
                           disableElevation
                           className='btn_save'
                        >
                                 <span>Consultar</span>
                        </Button>
                     </Grid>
               </Grid> 

         </Container>
         <Modal
                  open={openRut}
                  onClose={handleCloseRut}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                        <DataModalRuts entity={undefined} />
                  </Box>
            </Modal>

            {/* <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={2}>
               CLIENTE SELECCIONADO
            </Typography>

            <Grid item xs={12} md={5}>
                  <Typography mt={1} variant='h6' component='h1' gutterBottom className="tittle">
                     Rut de Cliente :
                  </Typography>
               </Grid>
               <Grid  item xs={12} md={5}>
                  <TextField
                     value=' 22222222-2'
                     fullWidth
                     size='small'
                  />
               </Grid>
                     

               <Grid item xs={12} md={5} >
                  <Typography mt={1} variant='h6' component='h1' gutterBottom className="tittle">
                     Razon Social :
                  </Typography>
               </Grid>
               <Grid item xs={12} md={5} textAlign="start">
                  <TextField
                     value='KAI DELIVERY'
                     fullWidth
                     size='small'
                  />
               </Grid>

               <Grid item xs={12} md={5} >
                  <Typography mt={1} variant='h6' component='h1' gutterBottom className="tittle">
                     Fechas Seleccionadas :
                  </Typography>
               </Grid>
               <Grid item xs={12} md={5} >
                  <TextField
                     value='DESDE 2022-12-12 HASTA 2022-12-12'
                     fullWidth
                     size='small'
                  />
               </Grid> */}

            <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={2}>
               LIBRO DE VENTAS
            </Typography>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
               <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           <TableCell>Tipo</TableCell>
                           <TableCell>Cantidad</TableCell>
                           <TableCell>Exento</TableCell>
                           <TableCell>Especifico</TableCell>
                           <TableCell>Valor Neto</TableCell>
                           <TableCell>IVA</TableCell>
                           <TableCell>Total</TableCell>

                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsDataLibroVentas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.tipo}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
            
                              </TableRow>
                           );
                        })}

                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsDataLibroVentas.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>

            <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={2}>
               LISTADO DE REGISTRO INGRESADO
            </Typography>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
               <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           <TableCell>Numero</TableCell>
                           <TableCell>Fecha</TableCell>
                           <TableCell>Folio</TableCell>
                           <TableCell>Tipo Doc.</TableCell>
                           <TableCell>Rut</TableCell>
                           <TableCell>Razon Social</TableCell>
                           <TableCell>Total</TableCell>

                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsDataIgreso.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.numero}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
            
                              </TableRow>
                           );
                        })}

                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsDataIgreso.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>
         </Protected>  
     );
}
