import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { VALIDATORS } from "@/toolbox/helpers/validation-rules";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Grid, Container, Button, Autocomplete, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, TablePagination, TableFooter, } from '@mui/material';
import { Input } from '@components/common/Input';
import { Props } from "./CentralizeWorker.type";
import BusinessIcon from '@mui/icons-material/Business';
import { InputRef } from '@/components/common/Input/InputInterfaces';
import './CentralizeWorker_style.css';
import NextPlanIcon from '@mui/icons-material/NextPlan';
import { DataModalCentralize } from './Create/ModaCentralize/DataModalCentralize';

// Para la tabla de la vista principal
interface Column {
   id: 'num' | 'rut' | 'apellidoPaterno' | 'apellidoMaterno' | 'nombres' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}
const columnsPrincipal: readonly Column[] = [
   { id: 'num', label: 'Num', minWidth: 100 },
   { id: 'rut', label: 'Rut', minWidth: 100 },
   { id: 'apellidoPaterno', label: 'Apellido Paterno', minWidth: 100 },
   { id: 'apellidoMaterno', label: 'Apellido Materno', minWidth: 100 },
   { id: 'nombres', label: 'Nombres', minWidth: 100 }
];

interface Data {
   num: string;
   rut: string;
   apellidoPaterno: string;
   apellidoMaterno: string;
   nombres: string;
}
function createData(
   num: string,
   rut: string,
   apellidoPaterno: string,
   apellidoMaterno: string,
   nombres: string,
) {
   return { num, rut, apellidoPaterno, apellidoMaterno, nombres };
}

const rowsListado = [
   createData('1', '15956742-7', 'ORMAZABAL', 'QUEZADA', 'DEBORA ADRIANA'),
   createData('2', '15956742-7', 'ALARCON', 'GONZALEZ', 'FERNANDO'),
   createData('3', '15956742-7', 'ORMAZABAL', 'QUEZADA', 'DEBORA ADRIANA'),
   createData('4', '15956742-7', 'ORMAZABAL', 'QUEZADA', 'DEBORA ADRIANA'),
]

export const CentralizeWorker: React.FC<Props> = (props: Props): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);

   const [siguiente, setSiguiente] = useState(false);

   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [openCentralize, setOpenCentralize] = useState(false);
   const [open, setOpen] = useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);

   const handleOpenCentralize = () => setOpenCentralize(true);
   const handleCloseCentralize = () => setOpenCentralize(false);  

   const handleSiguiente = () => setSiguiente(true);

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 750,
      maxHeight: 600,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

   const periodo = [
      { label: '2022' },
      { label: '2021' },
      { label: '2020' },
      { label: '2019' },
      { label: '2018' },
      { label: '2017' },
      { label: '2016' },
      { label: '2015' },
   ]
   const mes = [
      { label: 'Enero' },
      { label: 'Febrero' },
      { label: 'Marzo' },
      { label: 'Abril' },
      { label: 'Mayo' },
      { label: 'Junio' },
      { label: 'Agosto' },
      { label: 'Setiembre' },
      { label: 'Octubre' },
      { label: 'Noviembre sin ti' },
      { label: 'Diciembre' },
      { label: 'TODOS' }
   ]

   const rules = useMemo(() => ({
      rut_proveedor: [
         VALIDATORS.REQUIRED,
      ],
      numero_contrato: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      titulo: [
         VALIDATORS.REQUIRED
      ],
      descripcion: [
         VALIDATORS.REQUIRED,
      ]

   }), []);
   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'rut_proveedot':
            props.setvalueContrato(prev => ({ ...prev, rut_proveedot: value }));
            break;
         case 'numero_contrato':
            props.setvalueContrato(prev => ({ ...prev, numero_contrato: value }))
            break;
         case 'titulo':
            props.setvalueContrato(prev => ({ ...prev, titulo: value }))
            break;
         case 'descripcion':
            props.setvalueContrato(prev => ({ ...prev, descripcion: value }));
            break;

         default:
            break;
      }
   };
   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }

   return (
      <Protected >

         <Container maxWidth='xl' style={{ minHeight: '100vh' }}>
            <form style={{ marginBottom: '25px' }}>
               <Typography variant="h6" gutterBottom mb={3} className="tittle">
                  CENTRALIZACIÓN POR TRABAJADOR
               </Typography>

               <Grid item container m={2} xs={12} >
                  <Typography variant="subtitle1" >
                     Para revisar sus libros de cotizaciones, seleccione los siguientes datos
                     </Typography>
               </Grid>


               <Grid container spacing={3} className={`e-mb-lg`} >
                  <Grid item xs={12} sm={3}>
                     <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={periodo}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Periodo" />}
                     />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                     <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={mes}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Mes" />}
                     />
                  </Grid>

                  <Grid item container
                     xs={12}
                     justifyContent='right'
                  >
                     <Grid item>
                        <Button
                           // type='submit'
                           variant='contained'
                           onClick={handleSiguiente}
                           disabled={loading}
                           disableElevation
                           className='btn_save'
                        >
                           continuar
                        </Button>

                     </Grid>
                  </Grid>
               </Grid>
            </form>

            {siguiente && (
               <Grid container m={2}>                                    
                  <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                     <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                           <TableHead>
                              <TableRow>
                                 <TableCell>N</TableCell>
                                 <TableCell>RUT</TableCell>
                                 <TableCell>Apellido Paterno</TableCell>
                                 <TableCell>Apellido Materno</TableCell>
                                 <TableCell>Nombres</TableCell>
                                 <TableCell>Centralizar</TableCell>
                              </TableRow>
                           </TableHead>
                           <TableBody>
                              {rowsListado.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                 return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.num}>
                                       {columnsPrincipal.map((column) => {
                                          const value = row[column.id];
                                          return (
                                             <TableCell key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number'
                                                   ? column.format(value)
                                                   : value}
                                             </TableCell>
                                          );
                                       })}

                                       <TableCell>
                                          {/* <div className='sepaEle' style={{ display: "flex", justifyContent: "space-evenly" }}> */}
                                             
                                             <Tooltip title='Centralizar a ALARCON  '>
                                                <IconButton onClick={handleOpenCentralize}>                                                
                                                   <NextPlanIcon />                                                   
                                                </IconButton>
                                             </Tooltip>                                             
                                          {/* </div> */}
                                       </TableCell>
                                    </TableRow>
                                 );
                              })}
                           </TableBody>
                        </Table>
                     </TableContainer>
                     <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rowsListado.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                     />
                  </Paper>
               </Grid>

            )}
         </Container>         
         <Modal
            open={openCentralize}
            onClose={handleCloseCentralize}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataModalCentralize entity={undefined} />
            </Box>
         </Modal>        
      </Protected>
   );
}