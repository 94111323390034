import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { ArrowRightIcon } from "@/toolbox/constants/icons";
import { VALIDATORS } from "@/toolbox/helpers/validation-rules";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, TablePagination, } from '@mui/material';
import { Input } from '@components/common/Input';
import { Props } from "./GcDispatchRoutes.type";
import BusinessIcon from '@mui/icons-material/Business';
import { InputRef } from '@/components/common/Input/InputInterfaces';
import './GcDispatchRoutes_style.css';
import EditIcon from '@mui/icons-material/Edit';
import AltRouteIcon from '@mui/icons-material/AltRoute';

interface Column {
   id: 'numero' | 'nombre';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: 'numero', label: 'Rut', minWidth: 100 },
   { id: 'nombre', label: 'Razón Social', minWidth: 100 }
];

interface Data {
   numero: string;
   nombre: string;
}

function createDataListado(
   numero: number,
   nombre: string
) {
   return { numero, nombre };
}

const rowsListado = [
   createDataListado(1, 'Curico'),
   createDataListado(2, 'Linares'),
   createDataListado(3, 'Rancagua'),
   createDataListado(4, 'Huejupa')
]

export const GcDispatchRoutes: React.FC<Props> = (props: Props): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);

   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [open, setOpen] = useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const [valueContrato, setvalueContrato] = useState({
      rut_proveedor: '',
      titulo: '',
      numero_contrato: '',
      descripcion: '',
   });



   const estado = [
      { label: 'ACTIVADO' },
      { label: 'DESACTIVADO' },
   ]

   const rules = useMemo(() => ({
      rut_proveedor: [
         VALIDATORS.REQUIRED,
      ],
      numero_contrato: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      titulo: [
         VALIDATORS.REQUIRED
      ],
      descripcion: [
         VALIDATORS.REQUIRED,
      ]

   }), []);
   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'rut_proveedot':
            props.setvalueContrato(prev => ({ ...prev, rut_proveedot: value }));
            break;
         case 'numero_contrato':
            props.setvalueContrato(prev => ({ ...prev, numero_contrato: value }))
            break;
         case 'titulo':
            props.setvalueContrato(prev => ({ ...prev, titulo: value }))
            break;
         case 'descripcion':
            props.setvalueContrato(prev => ({ ...prev, descripcion: value }));
            break;

         default:
            break;
      }
   };

   // const handleInputBlur = (event: any) => {
   //    const newVal = (event.target.value).trim();
   //    const name = event.target.name;
   //    props.setvalueContrato(prev => ({ ...prev, [name]: newVal }));
   // }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }
   return (
      <Protected>
         <Container maxWidth='xl'>
            <form style={{ margin: '8px' }}>
               <Typography variant="h6" gutterBottom mb={3} className="tittle">
                  RUTA DE DESPACHO
               </Typography>

               <Grid container m={2} xs={12}>
                  <Typography variant="caption">
                     Ingresar Datos
                  </Typography>
               </Grid>


               <Grid container spacing={3} className={`e-mb-lg`} >

                  <Grid item xs={12} sm={4}>
                     <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <AltRouteIcon />
                           </InputAdornment>
                        }
                        name="valor_compra"
                        placeholder="Ruta"
                        //value={data.nombre_rz}
                        // value={props.valueContrato.valor_compra}
                        onChange={handleInput}
                        //onBlur={handleInputBlur}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.valor_compra}
                        disableElevation
                        validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                     <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={estado}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Estado" />}
                     />
                  </Grid>

                  <Grid item container
                     xs={12}
                     justifyContent='right'
                  >
                     <Grid item>
                        <Button
                           type='submit'
                           variant='contained'
                           onClick={handleSubmit}
                           disabled={loading}
                           disableElevation
                           className='btn_save'
                        >
                           <span>Agregar</span>
                        </Button>

                     </Grid>
                  </Grid>
               </Grid>
            </form>

            <div className='bg-tittle'>
               <Typography
                  mt={3}
                  variant="h6"
                  align='center'
                  className='tittle-text'
               >	Items Agregados
               </Typography>
            </div>
         </Container>

         <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer component={Paper}>
               <Table aria-label="simple table">
                  <TableHead>
                     <TableRow>
                        <TableCell>N°</TableCell>
                        <TableCell >Nombre</TableCell>
                        <TableCell >Estado</TableCell>
                        <TableCell >Opciones</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {rowsListado.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        return (
                           <TableRow hover role="checkbox" tabIndex={-1} key={row.numero}>
                              {columns.map((column) => {
                                 const value = row[column.id];
                                 return (
                                    <TableCell key={column.id} align={column.align}>
                                       {column.format && typeof value === 'number'
                                          ? column.format(value)
                                          : value}
                                    </TableCell>
                                 );
                              })}
                              <TableCell>
                                 <FormControlLabel control={<Checkbox defaultChecked />} label="" />
                              </TableCell>
                              <TableCell>

                                 <IconButton
                                    aria-label="delete"
                                    // onClick={() => handleDelete(row.id)}
                                    className='btn_delete'

                                 // disabled={loading}
                                 >
                                    <EditIcon />
                                 </IconButton>
                              </TableCell>
                           </TableRow>
                        );
                     })}
                  </TableBody>
               </Table>
            </TableContainer>
            <TablePagination
               rowsPerPageOptions={[10, 25, 100]}
               component="div"
               count={rowsListado.length}
               rowsPerPage={rowsPerPage}
               page={page}
               onPageChange={handleChangePage}
               onRowsPerPageChange={handleChangeRowsPerPage}
            />
         </Paper>

      </Protected>
   );
}