import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "./GcVendors.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './GcVendors_styles.css'
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import ArticleIcon from '@mui/icons-material/Article';
import PublicIcon from '@mui/icons-material/Public';
import TextField from '@mui/material/TextField';
import { ArrowRightIcon } from "@toolbox/constants/icons";



interface Column {
   id: 'numero' | 'rut' | 'nombre' | 'estado' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

const columns:readonly Column[] = [
      { id: 'numero', label: 'Codigo Producto', minWidth: 100 },
      { id: 'rut', label: 'Rut', minWidth: 170 },
      { id: 'nombre', label: 'Nombre', minWidth: 170, },
      { id: 'estado', label: 'Estado', minWidth: 100, },
      
   ];

interface Data {
   numero: string;
   rut: string;
   nombre: string;
   estado: string;
}

function createDataVendors(
numero: string,
rut: string,
nombre: string,
estado: string,
):Data {
return { numero, rut, nombre, estado };
}

const rowsDataVendors = [
createDataVendors('1', '12124548', 'Vendedor 1', 'Activo'), 
createDataVendors('2', '25456456', 'Vendedor 2', 'Activo'),
createDataVendors('3', '35456456', 'Vendedor 3', 'Activo'),
createDataVendors('4', '48761524', 'Vendedor 4', 'Activo'),
createDataVendors('5', '55748641', 'Vendedor 5', 'Activo'),
createDataVendors('6', '66548746', 'Vendedor 6', 'Activo'),
createDataVendors('7', '75464655', 'Vendedor 7', 'Activo'),
createDataVendors('8', '85464554', 'Vendedor 8', 'Activo'),
createDataVendors('9', '96545646', 'Vendedor 9', 'Activo'),
]


export const GcVendors:React.FC<Props> = (props: Props): JSX.Element => {
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [canal, setCanal] = useState('');
   const [estado, setEstado]= useState('');
   const [dataWoppy, setWoopy]= useState('');
   const [sucursal, setSucursal]= useState('');


   const handleChangeCanal = (event: SelectChangeEvent) => {
      setCanal(event.target.value);
    };

    const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
    };
    const handleChangeWoopy = (event: SelectChangeEvent) => {
      setWoopy(event.target.value);
    };
    const handleChangeSucursal = (event: SelectChangeEvent) => {
      setSucursal(event.target.value);
    };
   function handleClick() {
     setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const canales= [
      { value: '', label: 'Todos' },
      { value: '1', label: 'Canal 1' },
      { value: '2', label: 'Canal 2' },
      { value: '3', label: 'Canal 3' },
      { value: '4', label: 'Canal 4' },
   ];
   const estados= [
      { value: '', label: 'Todos' },
      { value: '1', label: 'Activo' },
      { value: '2', label: 'Inactivo' },
   ]

   const woopys= [
      { value: '', label: 'Todos' },
      { value: '1', label: 'Activo' },
      { value: '2', label: 'Inactivo' },
   ];
   const sucursales= [
      { value: '', label: 'Todos' },
      { value: '1', label: 'Sucursal 1' },
      { value: '2', label: 'Sucursal 2' },
      { value: '3', label: 'Sucursal 3' },
   ];
   const tipos=[
      { value: '', label: '%' },
      { value: '1', label: '$' },
      { value: '2', label: 'PEN' },
   ]
    return (
        <Protected>
        <Container maxWidth='xl'>
           <Grid container spacing={3}>
               <Grid item xs={12} md={6} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        MANTENEDOR DE VENDEDORES 
                     </Typography>
                  </Box>
                  
               </Grid>
                     
               <Grid item xs={12} md={6} className="btn-download">
                  <Box pb={2}>
                  <LoadingButton
                     // color="warning"
                     onClick={handleClick}
                     loading={loading}
                     loadingPosition="start"
                     startIcon={<FileDownloadIcon />}
                     variant="contained"
                     className="btn-download2"
                     >
                     Exportar productos Ventas x Vendedor
                     </LoadingButton>
                  </Box>
               </Grid>

               <Grid item xs={12} sm={2}>
                  <TextField
                     label="Rut"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>
               <Grid item xs={12} sm={2}>
                  <TextField
                     label="Nombre"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>
               <Grid item xs={12} sm={2}>
                  <TextField
                     label="Comision"
                     id="outlined-size-small"
                     size="small"
                     fullWidth

                  />
               </Grid>
               <Grid item xs={12} sm={2}>
                  <TextField
                     label="Max. Descuento Item"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                     />
               </Grid>
               <Grid item xs={12} sm={2}>
                  <TextField
                     label="Max. Descuento GLobal"
                     id="outlined-size-small"
                     size="small"
                     fullWidth

                  />
               </Grid>
               <Grid item xs={12} sm={2}>
               <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={tipos}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Tipo Descuento" />}
                     />
               </Grid>

               <Grid item xs={12} sm={3}>
               <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={canales}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Canal de Venta" />}
                     />
               </Grid>
               
               <Grid item xs={12} sm={3}>
               <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={estados}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Estado" />}
                     />
               </Grid>

               <Grid item xs={12} sm={3}>
               <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={woopys}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Woppy" />}
                     />
               </Grid>

               <Grid item xs={12} sm={3}>
               <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={sucursales}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Sucursal" />}
                     />
               </Grid>


            </Grid>
            <Grid item container
                  xs={12}
                  mt={1}
                 justifyContent='right'
                 >
                     <Grid item>
                        <Button
                           type='submit'
                           variant='contained'
                           // onClick={handleSubmit}
                           // disabled={loading}
                           disableElevation
                           className='btn_save'
                        >
                                 <span>Agregar</span>
                        </Button>
                     </Grid>
               </Grid>      

            <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={3}>
               LISTA DE VENDEDORES INGRESADOS
            </Typography>

            <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:440}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           <TableCell>N</TableCell>
                           <TableCell>Rut</TableCell>
                           <TableCell>Nombre</TableCell>
                           <TableCell>Estado</TableCell>
                           <TableCell>Opciones</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsDataVendors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.numero}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                       <IconButton
                                          >
                                             <EditIcon />
                                       </IconButton>
                                       <IconButton
                                          >
                                             <SearchIcon />
                                       </IconButton>
                                       <IconButton
                                          >
                                             <ArticleIcon />
                                       </IconButton>
                                       <IconButton
                                          >
                                             <PublicIcon />
                                       </IconButton>
                                 </TableCell>
                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsDataVendors.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
            </Paper>

         </Container>
     </Protected>
    );
   };

