import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, InputAdornment, FormControl, FormControlLabel, Checkbox, Stack, FormGroup, RadioGroup, Tooltip, } from '@mui/material'
import React, { useState, useRef } from "react";
import { Props } from "../DataBooks.type"
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EditIcon from '@mui/icons-material/Edit';
// import '../Workers.css'
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';

interface Column {
   id: 'usuario' | 'estado' | 'fecha' | 'observacion';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: string) => string;
}
const columns: readonly Column[] = [
   { id: 'usuario', label: 'Usuario', minWidth: 10 },
   { id: 'estado', label: 'Estado', minWidth: 10 },
   { id: 'fecha', label: 'Fecha', minWidth: 10 },
   { id: 'observacion', label: 'Observacion', minWidth: 10 }
];
interface Data {
   usuario: string;
   estado: string;
   fecha: string;
   observacion: string
}
function createData(
   usuario: string,
   estado: string,
   fecha: string,
   observacion: string

): Data {
   return { usuario, estado, fecha, observacion };
}
const rowsData = [
   createData('DEMOERP', 'Autorizada', '2021-12-30', 'Ninguna'),
   createData('DEMOERP', 'Autorizada', '2021-12-30', 'Ninguna'),
   createData('DEMOERP', 'Autorizada', '2021-12-30', 'Ninguna'),
]

export const DataModalHistoryOC: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [ejecutivoComercial, setEjecutivoComercial] = useState('');
   const [estado, setEstado] = useState('');
   // const [data, setData] = React.useState<Date | null>(new Date());
   const [data, setData] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const inputRefs = useRef<Array<InputRef | null>>([]);

   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
   };

   const handleChangeEjecutivoComercial = (event: SelectChangeEvent) => {
      setEjecutivoComercial(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const estadoOrden = [
      { label: 'NUL' },
      { label: 'Aprobada' },
      { label: 'Estado 3' },
      { label: 'Estado 4' }
   ]
   const contacto = [
      { label: 'Jorge Roberto' }
   ]
   const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
         backgroundColor: '#c6ced1',
         fontSize: 14,
         color: theme.palette.common.black,
      },
      [`&.${tableCellClasses.body}`]: {
         fontSize: 14,
      },
   }));
   return (
      <Container maxWidth='xl'>
         <Grid container spacing={3} mt={2} >
            <Grid item container>
               <Grid item xs={10}>
                  <Typography variant="h6" id="modal-modal-title" className='tittle'>
                     HISTORIAL DE ORDEN DE COMPRA
                  </Typography>
                  <Grid xs={12} mt={1}>
                     <Typography variant='subtitle2' gutterBottom >
                        Orden de Compra: Nº 407
                     </Typography>
                  </Grid>
               </Grid>
               <Grid item xs={2} textAlign="end">
                  <AssignmentIcon style={{ color: '#FF9700' }} />
               </Grid>
            </Grid>
            <Grid item xs={12} sm={5} container className={`e-mb-lg`} spacing={3}>
               <Grid item xs={12} sm={8}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={estadoOrden}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Estado Actual" />}
                  />
               </Grid>

               <Grid item xs={12} sm={11}>
                  <TextField
                     id="outlined-multiline-flexible"
                     label="Observaciones"
                     multiline
                     fullWidth={true}
                     rows={4}
                     value={value}
                     onChange={handleChange}
                  />
               </Grid>
            </Grid>
            <Grid item xs={12} sm={7} container className={`e-mb-lg`} spacing={3}>
               <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  <TableContainer sx={{ maxHeight: 220 }}>
                     <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                           <TableRow>
                              {columns.map((column) => (
                                 <StyledTableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                 >
                                    {column.label}
                                 </StyledTableCell>
                              ))}
                              <StyledTableCell>Opc</StyledTableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                              return (
                                 <TableRow hover role="checkbox" tabIndex={-1} key={row.usuario}>
                                    {columns.map((column) => {
                                       const value = row[column.id];
                                       return (
                                          <TableCell key={column.id} align={column.align}>
                                             {column.format && typeof value === 'string'
                                                ? column.format(value)
                                                : value}
                                          </TableCell>
                                       );
                                    })}
                                    <TableCell>
                                       <Tooltip title={"Editar"} placement="bottom-start">
                                          <IconButton
                                             aria-label="Editar"
                                             // onClick={}
                                             className='btn_delete'
                                          // disabled={loading}
                                          >

                                             <EditIcon />
                                          </IconButton>
                                       </Tooltip>
                                    </TableCell>
                                 </TableRow>
                              );
                           })}
                        </TableBody>
                     </Table>
                  </TableContainer>
               </Paper>
            </Grid>
            <Grid item xs={12} textAlign='center' mt={2}>
               <button className='btn_add'>
                  Actualizar
               </button>

            </Grid>
            {/* <Grid xs={12}>
               <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={2}>
                  LISTADO DE CARGAS FAMILIARES
               </Typography>
            </Grid> */}
         </Grid>




      </Container >
   );
};

