import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, Tooltip, } from '@mui/material'
import { useState } from "react";
import { Props } from "../SaleNoteOption.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import '../SaleNoteOption-styles.css'
import TextField from '@mui/material/TextField';

interface Column {
   id: 'num' | 'fecha' | 'folio' | 'tipoDoc' | 'codigo' | 'detalle' | 'cantidad' | 'precio';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}
const columns: readonly Column[] = [
   { id: 'num', label: 'Num', minWidth: 10 },
   { id: 'fecha', label: 'Fecha', minWidth: 100 },
   { id: 'folio', label: 'Folio', minWidth: 50 },
   { id: 'tipoDoc', label: 'Tipo Documento', minWidth: 100 },
   { id: 'codigo', label: 'Codigo', minWidth: 50 },
   { id: 'detalle', label: 'Detalle', minWidth: 50 },
   { id: 'cantidad', label: 'Cantidad', minWidth: 50 },
   { id: 'precio', label: 'Precio', minWidth: 50 },

];
interface Data {
   num: string;
   fecha: string;
   folio: string;
   tipoDoc: string;
   codigo: string;
   detalle: string;
   cantidad: string;
   precio: string;
}
function createData(
   num: string,
   fecha: string,
   folio: string,
   tipoDoc: string,
   codigo: string,
   detalle: string,
   cantidad: string,
   precio: string,
): Data {
   return { num, fecha, folio, tipoDoc, codigo, detalle, cantidad, precio };
}

const rowsData = [
   createData('1', '01-02-2022', '28191', '31', 'CMZXU', 'Detalle 1', '1', '12000'),
   createData('2', '01-02-2022', '28191', '31', 'CMZXU', 'Detalle 1', '1', '8000'),
   createData('3', '01-02-2022', '28191', '31', 'CMZXU', 'Detalle 1', '1', '1000'),
   createData('4', '01-02-2022', '28191', '31', 'CMZXU', 'Detalle 1', '1', '12000'),
]

const style = {
   position: 'absolute' as 'absolute',
   top: '50%',
   left: '50%',
   transform: 'translate(-50%, -50%)',
   width: 500,
   bgcolor: 'background.paper',
   // border: '2px solid #000',
   boxShadow: 24,
   p: 2,
   borderRadius: '20px'
};

export const DataInforme: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [ejecutivoComercial, setEjecutivoComercial] = useState('');
   const [estado, setEstado] = useState('');

   const handleChangeEjecutivoComercial = (event: SelectChangeEvent) => {
      setEjecutivoComercial(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const estados = [
      { label: 'No Adjudicada', value: 'Enviado' },
      { label: 'Adjudicada', value: 'Adjudicada' },
      { label: 'Anulada', value: 'No Adjudicada' },
   ]
   return (
      <Container maxWidth='xl'>
         <Grid item xs={12} md={12} mb={1}>
            <Box>
               <Typography variant='h6' component='h1' gutterBottom className="tittle">
                  INFORME DE VENTAS DE PRODUCTOS POR CLIENTES
               </Typography>
            </Box>
            <Box>
               <Typography variant='subtitle2' gutterBottom >
                  Detalle de productos por Clientes
               </Typography>
               <Typography variant='subtitle2' gutterBottom >
                  RUT: 10291011-k, Nombre: Pedrito Alcachofas
               </Typography>
            </Box>
         </Grid>

         <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
               <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                     <TableRow>
                        <TableCell>Num</TableCell>
                        <TableCell>Fecha</TableCell>
                        <TableCell>Folio</TableCell>
                        <TableCell>Tipo Doc</TableCell>
                        <TableCell>Codigo</TableCell>
                        <TableCell>Detalle</TableCell>
                        <TableCell>Cantidad</TableCell>
                        <TableCell>Precio</TableCell>
                        <TableCell>Opciones</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        return (
                           <TableRow hover role="checkbox" tabIndex={-1} key={row.num}>
                              {columns.map((column) => {
                                 const value = row[column.id];
                                 return (
                                    <TableCell key={column.id} align={column.align}>
                                       {column.format && typeof value === 'number'
                                          ? column.format(value)
                                          : value}
                                    </TableCell>
                                 );
                              })}
                              <TableCell>
                                 <Tooltip title={'Exportar'}>
                                    <PictureAsPdfIcon />
                                 </Tooltip>
                              </TableCell>
                           </TableRow>

                        );
                     })}
                  </TableBody>
               </Table>
            </TableContainer>
            <TablePagination
               rowsPerPageOptions={[10, 25, 100]}
               component="div"
               count={rowsData.length}
               rowsPerPage={rowsPerPage}
               page={page}
               onPageChange={handleChangePage}
               onRowsPerPageChange={handleChangeRowsPerPage}
            />
         </Paper>
      </Container>
   );
};

