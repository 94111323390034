import React, { useState, useRef } from "react";
import { Protected } from "@/components/layout/Protected";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
// import { InputRef } from '@/components/common/Input/InputInterfaces';
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import "./NoElectronicSalesDocuments_styles.css";
import Checkbox from "@mui/material/Checkbox";
import { DocumentsNoElectronic } from "./DocumentsNoElectronic/DocumentsNoElectronic";
import { DocumentsCancel } from "./DocumentsCancel/DocumentsCancel";
import { VtaCommodityNoNac } from "./VtaCommodityNoNac/VtaCommodityNoNac";
// import { TabMonthProducts } from './InformsMonthProduct/TabMonthProducts'
// import { TableMOnth } from './InformsMonth/TableMonth';

interface ChargesProps {
   children?: React.ReactNode;
   dir?: string;
   index: number;
   value: number;
}

function TabPanel(props: ChargesProps) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`full-width-tabpanel-${index}`}
         aria-labelledby={`full-width-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box sx={{ p: 3 }}>
               <Typography>{children}</Typography>
            </Box>
         )}
      </div>
   );
}

function a11yProps(index: number) {
   return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
   };
}

export const NoElectronicSalesDocuments: React.FunctionComponent<
   ChargesProps
> = () => {
   const theme = useTheme();
   const [value, setValue] = React.useState(0);

   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
   };

   const handleChangeIndex = (index: number) => {
      setValue(index);
   };

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
   };

   return (
      <Protected>
         <Grid style={{ margin: "8px", height: "100vh" }}>
            {/* <form style={{ margin: '8px' }}> */}
            <Grid item xs={12} md={12} textAlign="start">
               <Typography
                  mt={1}
                  variant="h6"
                  component="h1"
                  gutterBottom
                  className="tittle"
               >
                  DOCUMENTOS DE VENTA NO ELECTRONICOS
               </Typography>
            </Grid>
            <Grid item xs={12}>
               <Typography variant="subtitle1" display="block" gutterBottom>
                  Seleccione el Item a consultar
               </Typography>
            </Grid>
            {/* </form> */}

            <Grid item xs={12} mt={3}>
               <Box>
                  <AppBar position="static">
                     <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="inherit"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                        // className='tabMain'
                     >
                        <Tab
                           className="tab"
                           label="Documentos No Electronicos"
                           {...a11yProps(0)}
                        />
                        <Tab
                           className="tab"
                           label="Anular Documentos"
                           {...a11yProps(1)}
                        />
                        <Tab
                           className="tab"
                           label="Vta de Mercaderia no Nac c/ Destino Final Fuera de Chile"
                           {...a11yProps(2)}
                        />
                     </Tabs>
                  </AppBar>
                  <SwipeableViews
                     axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                     index={value}
                     onChangeIndex={handleChangeIndex}
                  >
                     <TabPanel value={value} index={0} dir={theme.direction}>
                        <DocumentsNoElectronic />
                     </TabPanel>
                     <TabPanel value={value} index={1} dir={theme.direction}>
                        <DocumentsCancel />
                     </TabPanel>
                     <TabPanel value={value} index={2} dir={theme.direction}>
                        <VtaCommodityNoNac />
                     </TabPanel>
                  </SwipeableViews>
               </Box>
            </Grid>
         </Grid>
      </Protected>
   );
};
