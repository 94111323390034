import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "./LsBooksSalesBookGuide.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './LsBooksSalesBook_styles.css'
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import Modal from '@mui/material/Modal';
import { DataEmail } from "./Modal/DataEmail";
import BookIcon from '@mui/icons-material/Book';
import { Book, Equalizer, ListAlt } from "@mui/icons-material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Link } from 'react-router-dom';
import {  ROUTE_EV_LS_BOOK_NOTE_SELL_GUIDE, ROUTE_EV_LS_BOOK_NOTE_SELL_FOLIO_GUIDE} from "@/toolbox/constants/route-map";
import EqualizerIcon from '@mui/icons-material/Equalizer';
import ListAltIcon from '@mui/icons-material/ListAlt';
import EmailIcon from '@mui/icons-material/Email';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import ArticleIcon from '@mui/icons-material/Article';
import SearchIcon from '@mui/icons-material/Search';
import { DataHistorico } from "./Modal/DataHistorico";
import { DataCotizacion } from "./Modal/DataCotizacion";
import { DataCotizacionState } from "./Modal/DataCotizacionState";
import { DataCotizacionResumen } from "./Modal/DataCotizacionResumen";
import { DataCotizacionGrafico } from "./Modal/DataCotizacionGrafico";
import { DataInformeCotizacion } from "./Modal/DataInformeCotizacion";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
interface Column {
   id: 'fecha' | 'tipo' | 'rut'|'razonSocial' | 'total'|'estado'| 'vendedor' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }
 interface Column1 {
   id: 'tipo' | 'cantidad' | 'exento'|'especifico'|'valorNeto'|'iva'|'total' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

 interface Column2 {
   id: 'facturada' | 'enviada' | 'generada'|'total' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }


const columns:readonly Column[] = [
   { id: 'fecha', label: 'Fecha', minWidth: 50 },
   { id: 'tipo', label: 'Tipo', minWidth: 50 },
   { id: 'rut', label: 'Rut', minWidth: 50 },
   { id: 'razonSocial', label: 'Razon Social', minWidth: 50 },
   { id: 'total', label: 'Total', minWidth: 50 },
   { id: 'estado', label: 'Estado', minWidth: 50 },
   { id: 'vendedor', label: 'Vendedor', minWidth: 50 },
   ];

const columns1:readonly Column1[] = [
   { id: 'tipo', label: 'Tipo', minWidth: 50 },
   { id: 'cantidad', label: 'Cantidad', minWidth: 50 },
   { id: 'exento', label: 'Exento', minWidth: 50 },
   { id: 'especifico', label: 'Especifico', minWidth: 50 },
   { id: 'valorNeto', label: 'Valor Neto', minWidth: 50 },
   { id: 'iva', label: 'Iva', minWidth: 50 },
   { id: 'total', label: 'Total', minWidth: 50 },
   ];



interface Data {
   fecha: string;
   tipo: string;
   rut: string;
   razonSocial: string;
   total: string;
   estado: string;
   vendedor: string;
}

interface Data1 {
   tipo: string;
   cantidad: string;
   exento: string;
   especifico: string;
   valorNeto: string;
   iva: string;
   total: string;
}

function createData(
   fecha: string,
   tipo: string,
   rut: string,
   razonSocial: string,
   total: string,
   estado: string,
   vendedor: string,
   ):Data {
   return { fecha, tipo, rut, razonSocial, total, estado, vendedor };
}

function createData1(
   tipo: string,
   cantidad: string,
   exento: string,
   especifico: string,
   valorNeto: string,
   iva: string,
   total: string,
):Data1 {
   return { tipo, cantidad, exento, especifico, valorNeto, iva, total };
}



const rowsData = [
   createData('12/12/2020', 'Cotizacion', '123456789', 'Empresa 1', '$1.000.000', 'Pendiente', 'Vendedor 1'),
   createData('12/12/2020', 'Cotizacion', '123456789', 'Empresa 1', '$1.000.000', 'Pendiente', 'Vendedor 1'),
   createData('12/12/2020', 'Cotizacion', '123456789', 'Empresa 1', '$1.000.000', 'Pendiente', 'Vendedor 1'),
   createData('12/12/2020', 'Cotizacion', '123456789', 'Empresa 1', '$1.000.000', 'Pendiente', 'Vendedor 1'),
   createData('12/12/2020', 'Cotizacion', '123456789', 'Empresa 1', '$1.000.000', 'Pendiente', 'Vendedor 1'),
   createData('12/12/2020', 'Cotizacion', '123456789', 'Empresa 1', '$1.000.000', 'Pendiente', 'Vendedor 1'),
]

const rowsData1 = [
   createData1('Nota de VEnta', '1', '$0', '$0', '$451.000', '$547.000', '$541.000'),
   createData1('Totales', '1', '$0', '$0', '$451.000', '$547.000', '$541.000'),
]

export const LsQuoteBookResumenNoteGuide:React.FC<Props> = (props: Props): JSX.Element => {
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   // const [value, setValue] = useState('');
   const [value, setValue] = useState<Date | null>(new Date());
   const [loading, setLoading] = useState(false);
   const [pais, setPais] = useState('');
   const [estado, setEstado]= useState('');

   const [openEmail, setOpenEmail] = useState(false);
   const handleOpenEmail = () => setOpenEmail(true);
   const handleCloseEmail = () => setOpenEmail(false);

   const [openHistorico, setOpenHistorico]= useState(false);
   const handleOpenHistorico = () => setOpenHistorico(true);
   const handleCloseHistorico = () => setOpenHistorico(false);

   const [openCotizacion, setOpenCotizacion] = useState(false);
   const handleOpenCotizacion = () => setOpenCotizacion(true);
   const handleCloseCotizacion = () => setOpenCotizacion(false);

   const [openCotizacionState, setOpenCotizacionState] = useState(false);
   const handleOpenCotizacionState = () => setOpenCotizacionState(true);
   const handleCloseCotizacionState = () => setOpenCotizacionState(false);

   const [openCotizacionResumen, setOpenCotizacionResumen] = useState(false);
   const handleOpenCotizacionResumen = () => setOpenCotizacionResumen(true);
   const handleCloseCotizacionResumen = () => setOpenCotizacionResumen(false);

   const [openGrafico, setOpenGrafico] = useState(false);
   const handleOpenGrafico = () => setOpenGrafico(true);
   const handleCloseGrafico = () => setOpenGrafico(false);

   const [openInformeCotizacion, setOpenInformeCotizacion] = useState(false);
   const handleOpenInformeCotizacion = () => setOpenInformeCotizacion(true);
   const handleCloseInformeCotizacion = () => setOpenInformeCotizacion(false);

   const [openRut, setOpenRut] = useState(false);
   const handleOpenRut = () => setOpenRut(true);
   const handleCloseRut = () => setOpenRut(false);

    const handleChangePais = (event: SelectChangeEvent) => {
      setPais(event.target.value);
    };
    const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
    };
   function handleClick() {
     setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };


   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 720,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

    return (
        <Protected>
        <Container maxWidth='xl'>
           <Grid container spacing={1}>
                  <Grid item xs={12} md={9} >
                     <Box>
                        <Typography variant='h6' component='h1' gutterBottom className="tittle">
                           LIBRO DE GUIA DE DESPACHO
                        </Typography>
                     </Box>
                     <Box>
                        <Typography variant='subtitle2'  gutterBottom >
                         Periodo: 2022 Mes: 04                        
                         </Typography>
                     </Box>

                  </Grid>
                  <Grid item xs={12} md={3}>
                       <Grid container display="flex" justifyContent="space-evenly">
                              <IconButton>
                                 <FileDownloadIcon/>
                              </IconButton>
                                 <Button
                                    type='submit'
                                    variant='contained'
                                    
                                    // onClick={handleSubmit}
                                    // disabled={loading}
                                    disableElevation
                                    component={Link} to={ROUTE_EV_LS_BOOK_NOTE_SELL_GUIDE}
                                    className='btn_next'
                                 >
                                          VOLVER
                                 </Button>
                       </Grid>
                        
                     </Grid>
                  <Grid justifyContent='center' item xs={12} md={12}>
                     <Grid container spacing={1}>
                        <Grid item xs={12} md={12} >
                           <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={2}>
                              Resumen por Tipo de Documentos
                           </Typography>
                           <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                 <TableRow>
                                    {columns.map(column => (
                                       <TableCell
                                          key={column.id}
                                          align={column.align}
                                          style={{ minWidth: column.minWidth }}
                                       >
                                          {column.label}
                                       </TableCell>
                                    ))}
                                 </TableRow>
                              </TableHead>
                              <TableBody>
                                 {rowsData1.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    return (
                                       <TableRow hover role="checkbox" tabIndex={-1} key={row.tipo}>
                                          {columns1.map((column) => {
                                             const value = row[column.id];
                                             return (
                                                <TableCell key={column.id} align={column.align}>
                                                   {column.format && typeof value === 'number'
                                                      ? column.format(value)
                                                      : value}
                                                </TableCell>
                                             );
                                          })}
                                       
                                       </TableRow>
                                    );
                                 })}
                              
                              </TableBody>   
                           </Table>  
                        </Grid>
                     </Grid>
                  </Grid>
            </Grid>
                       
            <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={3}>
               LISTADO DE DOCUMENTOS INGRESADOS
            </Typography>

            <Paper sx={{ width: '100%', overflow: 'hidden'}} >
               
               <TableContainer sx={{maxHeight:440}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {columns.map((column) => (
                              <TableCell
                              
                                 key={column.id}
                                 align={column.align}
                                 style={{ minWidth: column.minWidth }}
                              >
                                 {column.label}
                              </TableCell>
                           ))}
                           <TableCell>
                              Folio
                           </TableCell>
                           <TableCell>
                              PDF
                           </TableCell>
                           <TableCell>
                              Opciones
                           </TableCell>

                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.fecha}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                       <IconButton 
                                          component={Link} to={ROUTE_EV_LS_BOOK_NOTE_SELL_FOLIO_GUIDE}
                                          >
                                             <BookIcon
                                          style={{ fontSize:'20px' }}                                          
                                       />
                                       </IconButton>
                                 </TableCell>
                                 <TableCell>
                                       <IconButton 
                                          // component={Link} to={ROUTE_EV_LS_EMITION_QUOTE}
                                          >
                                             <PictureAsPdfIcon
                                          style={{ fontSize:'20px' }}                                          
                                       />
                                       </IconButton>
                                 </TableCell>
                                 <TableCell>
                                    <IconButton
                                       >
                                          <EmailIcon
                                          style={{ fontSize:'20px' }}
                                          onClick={handleOpenEmail}
                                       />
                                    </IconButton>
                                    <IconButton
                                       >
                                          <HistoryEduIcon
                                          style={{ fontSize:'20px' }}
                                          onClick={handleOpenHistorico}
                                       />
                                    </IconButton>

                                    <IconButton
                                       >
                                          <DocumentScannerIcon
                                          style={{ fontSize:'20px' }}
                                          onClick={handleOpenCotizacion}
                                       />
                                    </IconButton>

                                    <IconButton
                                       >
                                          <ArticleIcon
                                          style={{ fontSize:'20px' }}
                                          onClick={handleOpenCotizacionState}
                                       />
                                    </IconButton>

                                    <IconButton
                                       >
                                          <SearchIcon
                                          style={{ fontSize:'20px' }}
                                          onClick={handleOpenCotizacionResumen}
                                       />
                                    </IconButton>


                                 </TableCell>

                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
            </Paper> 

         </Container>
         <Modal
            open={openEmail}
            onClose={handleCloseEmail}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataEmail entity={undefined} />
            </Box>
         </Modal>

         <Modal
            open={openHistorico}
            onClose={handleCloseHistorico}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataHistorico entity={undefined} />
            </Box>
         </Modal>
         <Modal
            open={openCotizacion}
            onClose={handleCloseCotizacion}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataCotizacion entity={undefined} />
            </Box>
         </Modal>
         <Modal
            open={openCotizacionState}
            onClose={handleCloseCotizacionState}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataCotizacionState entity={undefined} />
            </Box>
         </Modal>
         <Modal
            open={openCotizacionResumen}
            onClose={handleCloseCotizacionResumen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataCotizacionResumen entity={undefined} />
            </Box>
         </Modal>
         <Modal
            open={openGrafico}
            onClose={handleCloseGrafico}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataCotizacionGrafico entity={undefined} />
            </Box>
         </Modal>
         <Modal
            open={openInformeCotizacion}
            onClose={handleCloseInformeCotizacion}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataInformeCotizacion entity={undefined} />
            </Box>
         </Modal>
     </Protected>
    );
   };

