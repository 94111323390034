import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "../PaymentWorkers.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import '../PaymentWorkers_styles.css'
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { PictureAsPdf } from "@mui/icons-material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';

interface Column {
   id: 'numero' | 'rut' | 'nombre' | 'apellidoPaterno'|'apellidoMaterno'| 'Monto';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

const columns:readonly Column[] = [
   { id: 'numero', label: 'N°', minWidth: 10 },
   { id: 'rut', label: 'Rut', minWidth: 10 },
   { id: 'nombre', label: 'Nombre', minWidth: 10 },
   { id: 'apellidoPaterno', label: 'Apellido Paterno', minWidth: 10 },
   { id: 'apellidoMaterno', label: 'Apellido Materno', minWidth: 10 },
   { id: 'Monto', label: 'Monto', minWidth: 10 },
   ];

interface Data {
   numero: number;
   rut: string;
   nombre: string;
   apellidoPaterno: string;
   apellidoMaterno: string;
   Monto: number;
}

function createData(
   numero: number,
   rut: string,
   nombre: string,
   apellidoPaterno: string,
   apellidoMaterno: string,
   Monto: number,
   ):Data {
   return { numero, rut, nombre, apellidoPaterno, apellidoMaterno, Monto };
}

const rowsData = [
   createData(1, '123456789', 'Juan', 'Perez', 'Perez', 100000),
   createData(2, '123456789', 'Juan', 'Perez', 'Perez', 100000),
   createData(3, '123456789', 'Juan', 'Perez', 'Perez', 100000),
   createData(4, '123456789', 'Juan', 'Perez', 'Perez', 100000),
   createData(5, '123456789', 'Juan', 'Perez', 'Perez', 100000),
   createData(6, '123456789', 'Juan', 'Perez', 'Perez', 100000),
   createData(7, '123456789', 'Juan', 'Perez', 'Perez', 100000),
   createData(8, '123456789', 'Juan', 'Perez', 'Perez', 100000),
]


export const FiledPayment:React.FC<Props> = (props: Props): JSX.Element => {
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value2, setValue2] = useState('');
   const [loading, setLoading] = useState(false);
   const [familyProduct, setFamilyProduct] = useState('');
   const [estado, setEstado]= useState('');
   const [value, setValue] = useState<Date | null>(new Date());

    const handleChangeFamilyProduct = (event: SelectChangeEvent) => {
      setFamilyProduct(event.target.value);
    };
    const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
    };
   function handleClick() {
     setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };


    return (
        <Container maxWidth='xl'>
           <Grid container spacing={3}>

               <Grid item xs={12} md={6} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                     ARCHIVO DE PAGO TRABAJADORES 2022-1 
                     </Typography>
                  </Box>
                  
               </Grid>
                     
               <Grid item xs={12} md={6} className="btn-download">
                  <Box pb={2} display="flex" justifyContent="space-evenly">

                  <LoadingButton
                     // color="warning"
                     onClick={handleClick}
                     loading={loading}
                     loadingPosition="start"
                     startIcon={<FileDownloadIcon />}
                     variant="contained"
                     className="btn-download2"
                     style={{marginRight: '10px'  }}
                     
                     >
                     Excel
                     </LoadingButton>

                     <LoadingButton
                     // color="warning"
                     onClick={handleClick}
                     loading={loading}
                     loadingPosition="start"
                     startIcon={<FileDownloadIcon />}
                     variant="contained"
                     className="btn-download2"
                     >
                     Txt
                     </LoadingButton>

                  </Box>
               </Grid>
            </Grid>
            
            <div style={{background:'#16556b', borderRadius:'5px', color:'#ffffff',}} >
                        <Typography 
                        mt={2}
                        mb={2}
                        style={{padding:'3px'}}
                        variant="subtitle2"
                        align='center'
                        >HISTORICO DE ARCHIVO DE PAGO
                        </Typography>
         </div>

            <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:220}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                                 {column.label}
                              </TableCell>
                           ))}
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.numero}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                              

                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
            </Paper>

         </Container>
     
    );
   };

