import React, { useState, useMemo, useRef, useEffect } from "react";
import { FunctionComponent } from "react";
import { Grid, Typography } from "@mui/material";

import ArticleIcon from "@mui/icons-material/Article";
import Link from "@mui/material/Link";

import {} from "@/toolbox/constants/route-map";

export const DocumentsCancel: FunctionComponent = () => {
   return (
      <>
         <Grid container spacing={2} pl={5} mt={1}>
            <Grid container item>
               <Link href={""} underline="none">
                  <Grid className={"enlacesDoc"}>
                     <ArticleIcon />
                     <Typography variant="subtitle1" ml={2}>
                        DOCUMENTOS NULOS
                     </Typography>
                  </Grid>
               </Link>
            </Grid>
         </Grid>
      </>
   );
};
