import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container,Stack, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, } from '@mui/material'
import { useState } from "react";
import { Props } from "./GcProductService.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './GcProductService_styles.css'
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

interface Column {
   id: 'numero' | 'codigo' | 'proveedor' | 'tipo'|'fecha'|'precio';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: 'numero', label: 'Número', minWidth: 100 },
   { id: 'codigo', label: 'Código', minWidth: 100 },
   { id: 'proveedor', label: 'Proveedor', minWidth: 100 },
   { id: 'tipo', label: 'Tipo', minWidth: 100 },
   { id: 'fecha', label: 'Fecha', minWidth: 100 },
   { id: 'precio', label: 'Precio', minWidth: 100 },
];

interface Data {
   numero: number;
   codigo: string;
   proveedor: string;
   tipo: string;
   fecha: string;
   precio: number;
}

function createDataProviders(
   numero: number,
   codigo: string,
   proveedor: string,
   tipo: string,
   fecha: string,
   precio: number
): Data {
   return { numero, codigo, proveedor, tipo, fecha, precio };
}

const rowsDataProviders = [
   createDataProviders(1, 'codigo1', 'proveedor1', 'tipo1', '20/10/2022', 1),
   createDataProviders(2, 'codigo2', 'proveedor2', 'tipo2', '20/10/2022', 2),
   createDataProviders(3, 'codigo3', 'proveedor3', 'tipo3', '20/10/2022', 3),
   createDataProviders(4, 'codigo4', 'proveedor4', 'tipo4', '20/10/2022', 4),
   createDataProviders(5, 'codigo5', 'proveedor5', 'tipo5', '20/10/2022', 5),
]


export const DataModalProvider: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [ejecutivoComercial, setEjecutivoComercial] = useState('');
   const [estado, setEstado] = useState('');
   const [data, setData] = useState<Date | null>(new Date());


   const handleChangeEjecutivoComercial = (event: SelectChangeEvent) => {
      setEjecutivoComercial(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const proveedores = [
      { value: 'proveedor1', label: 'Proveedor 1' },
      { value: 'proveedor2', label: 'Proveedor 2' },
      { value: 'proveedor3', label: 'Proveedor 3' },
      { value: 'proveedor4', label: 'Proveedor 4' },
   ];
   const tipos = [
      { value: 'tipo1', label: 'Tipo 1' },
      { value: 'tipo2', label: 'Tipo 2' },
      { value: 'tipo3', label: 'Tipo 3' },
      { value: 'tipo4', label: 'Tipo 4' },
   ];

   return (
         <Container maxWidth='xl'>
            <Grid container spacing={3}>
            <Grid item xs={12}>
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        MANTENEDOR DE PRODUCTOS
                     </Typography>
                  </Box>
                  
               </Grid>
                     
              
               <Grid item xs={12} sm={4}>
                  <TextField
                     value="000000-Sum"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={4}>
                  <TextField
                     label="Codigo Producto"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={4}>
                  <TextField
                     label="Precio"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={4}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={proveedores}
                     renderInput={(params) => <TextField {...params} label="Seleccione Proveedor" />}
                  />
               </Grid>

               <Grid item xs={12} sm={4}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={tipos}
                     renderInput={(params) => <TextField {...params} label="Tipo" />}
                  />
               </Grid>

               <Grid item xs={12} sm={4}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} color="primary" focused>
                              <Stack spacing={3}>
                                 <DatePicker
                                    openTo="year"
                                    views={['year', 'month', 'day']}
                                    label="Fecha"
                                    value={data}
                                    onChange={(newValue) => {
                                       setData(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} helperText={null} />}
                                 />
                              </Stack>
                     </LocalizationProvider>
               </Grid>

               <Grid item container
                  xs={12}
                  mt={1}
                  justifyContent='center'
               >
                  <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        // onClick={handleSubmit}
                        // disabled={loading}
                        disableElevation
                        className='btn_save'
                     >
                        <span>Agregar</span>
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={2}>
            </Typography>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
               <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           <TableCell>N</TableCell>
                           <TableCell>Codigo</TableCell>
                           <TableCell>Proveedor</TableCell>
                           <TableCell>Tipo</TableCell>
                           <TableCell>Fecha</TableCell>
                           <TableCell>Precio</TableCell>
                           <TableCell>Opciones</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsDataProviders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.numero}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                    <IconButton
                                    >
                                       <EditIcon />
                                    </IconButton>
                                 </TableCell>
                                 
                              </TableRow>
                           );
                        })}

                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsDataProviders.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>

         </Container>
   );
};

