import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, } from '@mui/material'
import { useState } from "react";
import { Props } from "./Charges.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './Charges_styles.css'
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Delete, PictureAsPdf } from "@mui/icons-material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import { DeleteIcon } from "@/toolbox/constants/icons";
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

interface Column {
   id: 'numero' | 'cargo' | 'trabajadores' | 'descripcion';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: 'numero', label: 'Número', minWidth: 100 },
   { id: 'cargo', label: 'Cargo', minWidth: 100 },
   { id: 'trabajadores', label: 'Trabajadores', minWidth: 100 },
   { id: 'descripcion', label: 'Descripción', minWidth: 100 },
];

interface Data {
   numero: number;
   cargo: string;
   trabajadores: number;
   descripcion: string;
}

function createDataCharges(
   numero: number,
   cargo: string,
   trabajadores: number,
   descripcion: string,
): Data {
   return { numero, cargo, trabajadores, descripcion };
}

const rowsDataCharges = [
   createDataCharges(1, 'JEFE DE DEPARTAMENTO', 1, 'Jefe de departamento'),
   createDataCharges(2, 'ASISTENTE DE DEPARTAMENTO', 2, 'Asistente de departamento'),
   createDataCharges(3, 'ENCARGADA GESTION DE PERSONAS	', 3, 'Asistente de departamento'),
   createDataCharges(4, ' CONTADOR GENERAL	', 4, 'Asistente de departamento'),
]


export const Charges: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [familyProduct, setFamilyProduct] = useState('');
   const [estado, setEstado] = useState('');

   const handleChangeFamilyProduct = (event: SelectChangeEvent) => {
      setFamilyProduct(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };


   return (
      <Protected>
         <Container maxWidth='xl'>
            <Grid container spacing={3}>
               <Grid item xs={12} md={12} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        MANTENEDOR DE CARGOS
                     </Typography>
                  </Box>
                  <Typography variant="caption" display="block" gutterBottom mt={1}>
                     Incorpóre la información de los Cargos en el siguiente formulario.
                  </Typography>

               </Grid>

               <Grid item xs={12} sm={3}>
                  <TextField
                     label="Nombre"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
                  <TextField
                     label="Descripción de Cargo"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>


               <Grid item xs={12} sm={3}>
                  <FormControl component="fieldset">
                     <FormGroup aria-label="position" row>
                        <FormControlLabel
                           value="start"
                           control={<Switch color="primary" />}
                           label="Producción"
                           labelPlacement="start"
                        />

                     </FormGroup>
                  </FormControl>
               </Grid>

               <Grid item xs={12} md={3}>
                  <Button
                     type='submit'
                     variant='contained'
                     // onClick={handleSubmit}
                     // disabled={loading}
                     disableElevation
                     className='btn_next'
                     style={{ backgroundColor: '#ff9700' }}
                  >
                     <span>Agregar</span>
                  </Button>
               </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={2}>
               LISTADO DE CARGOS INGRESADOS
            </Typography>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
               <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{ minWidth: column.minWidth }}
                              >
                                 {column.label}
                              </TableCell>
                           ))}
                           <TableCell>Opcion</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsDataCharges.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.numero}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                    <IconButton
                                    >
                                       <EditIcon />
                                    </IconButton>
                                    <IconButton
                                    >
                                       <DeleteIcon />
                                    </IconButton>

                                 </TableCell>

                              </TableRow>
                           );
                        })}

                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsDataCharges.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>

         </Container>
      </Protected>
   );
};

