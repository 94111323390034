import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, InputAdornment, FormControl, FormControlLabel, Checkbox, } from '@mui/material'
import { useState, useRef } from "react";
import { Props } from "../AddCart/AddCart.type";
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import NumbersIcon from '@mui/icons-material/Numbers';
import TableContainer from '@mui/material/TableContainer';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TableRow from '@mui/material/TableRow';
import '../../GcPriceList_style.css'
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { AddCircle } from "@mui/icons-material";
import AddCircleIcon from '@mui/icons-material/AddCircle';

interface Column {
   id: 'codigo' | 'barra' | 'codigoP' | 'descripcion' | 'precio' | 'stock';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: 'codigo', label: 'N', minWidth: 100 },
   { id: 'barra', label: 'Cuenta', minWidth: 100 },
   { id: 'codigoP', label: 'Banco', minWidth: 100 },
   { id: 'descripcion', label: 'Tipo', minWidth: 100 },
   { id: 'precio', label: 'Tipo', minWidth: 100 },
   { id: 'stock', label: 'Tipo', minWidth: 100 },
];

interface Data {
   codigo: string;
   barra: string;
   codigoP: string;
   descripcion: string;
   precio: string;
   stock: string;
}

function createDataContacto(
   codigo: string,
   barra: string,
   codigoP: string,
   descripcion: string,
   precio: string,
   stock: string
): Data {
   return { codigo, barra, codigoP, descripcion, precio, stock };
}

const rowsDataContacto = [
   createDataContacto('123', 'codigoBarra 1', 'codigoP', 'descripcion 1', '$34', '0'),
   createDataContacto('456', 'codigoBarra 2', 'otroCodigoP', 'descripcion 2', '$23', '12'),
]


export const DataModalProducts: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [ejecutivoComercial, setEjecutivoComercial] = useState('');
   const [estado, setEstado] = useState('');
   const inputRefs = useRef<Array<InputRef | null>>([]);


   const handleChangeEjecutivoComercial = (event: SelectChangeEvent) => {
      setEjecutivoComercial(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const comerciales = [
      { value: '1', label: 'Activo' },
      { value: '2', label: 'Desactivado' },
   ];
   const estados = [
      { value: '1', label: 'Activo' },
      { value: '2', label: 'Desactivado' },
   ]
   const bancos = [
      { label: 'Banco 1', value: 'Banco 1' },
      { label: 'Banco 2', value: 'Banco 2' },
      { label: 'Banco 3', value: 'Banco 3' },
      { label: 'Banco 4', value: 'Banco 4' },
   ]
   const tipoCuenta = [
      { label: 'Tipo Cuenta 1', value: 'tipoCuenta 1' },
      { label: 'Tipo Cuenta 2', value: 'tipoCuenta 2' },
      { label: 'Tipo Cuenta 3', value: 'tipoCuenta 3' },
      { label: 'Tipo Cuenta 4', value: 'tipoCuenta 4' },
   ]

   return (
      <Container maxWidth='xl'>
         <Grid container spacing={3} mt={2} >
            <Grid container mb={2}>
               <Grid item xs={10}>
                  <Typography variant="h6" id="modal-modal-title" className='tittle'>
                     Listado de Productos
                  </Typography>
               </Grid>
               <Grid item xs={2} textAlign="end">
                  <AssignmentIcon style={{ color: '#FF9700' }} />
               </Grid>
            </Grid>

            <Grid xs={12}>
               <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={2}>
                  LISTADO DE REGISTRO INGRESADO
               </Typography>
            </Grid>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
               <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           <TableCell>N</TableCell>
                           <TableCell>Códigoa</TableCell>
                           <TableCell>Barra</TableCell>
                           <TableCell>CódigoP</TableCell>
                           <TableCell>Descripción</TableCell>
                           <TableCell>Precio</TableCell>
                           <TableCell>Stock</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsDataContacto.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.codigo}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}

                                 <TableCell align="center">

                                    <IconButton
                                       aria-label="delete"
                                       // onClick={() => handleDelete(row.id)}
                                       className='btn_delete'
                                    // onClick={() => handleOpen()}
                                    // disabled={loading}
                                    >
                                       <EditIcon />
                                    </IconButton>
                                    <IconButton
                                       aria-label="delete"
                                       // onClick={() => handleDelete(row.id)}
                                       className='btn_delete'
                                    // onClick={() => handleOpen()}
                                    // disabled={loading}
                                    >
                                       <LocalPrintshopIcon />
                                    </IconButton>
                                 </TableCell>
                              </TableRow>
                           );
                        })}
                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsDataContacto.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>
         </Grid>




      </Container >
   );
};

