import React, { useState } from "react";
// import { Protected } from "@/components/layout/Protected";
import {
   Grid,
   Box,
   Typography,
   Divider,
   FormControlLabel,
   Checkbox,
} from "@mui/material";
import { Props } from "./AllProducts.type";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./AllProducts_style.css";
import FormGroup from "@mui/material/FormGroup";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import Modal from "@mui/material/Modal";

import { ModalPrueba } from "./Modals/ModalPrueba";
import Slider from "@mui/material/Slider";
import { Protected } from "@/components/layout/Protected";
import { ModalGuiaDespacho } from "./Modals/ModalGuiaDespacho";

function valuetext(value: number) {
   return `${value}°C`;
}

export const AllProducsSidebar: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const style = {
      width: "100%",
      // maxWidth: 360,
      bgcolor: "background.paper",
   };

   // material ui SLIDER
   const [value, setValue] = React.useState<number[]>([20, 120]);

   const [openModalPrueba, setOpenModalPrueba] = React.useState(false);
   const [openModalGuiaDespacho, setOpenModalGuiaDespacho] =
      React.useState(false);

   const handleChange = (event: Event, newValue: number | number[]) => {
      setValue(newValue as number[]);
   };

   const handleOpenPrueba = () => setOpenModalPrueba(true);
   const handleClosePrueba = () => setOpenModalPrueba(false);

   const handleOPenGuiaDespacho = () => setOpenModalGuiaDespacho(true);
   const handleCloseGuiaDespacho = () => setOpenModalGuiaDespacho(false);

   const [openRut, setOpenRut] = React.useState(false);
   const handleOpenRut = () => setOpenRut(true);
   const handleCloseRut = () => setOpenRut(false);

   return (
      // <Grid item container className={'grid-slider'} sx={{ border: '2px solid #000', alignContent: 'flex-start' }}>
      <Grid item container className={"grid-slider"}>
         <Grid item xs={12} textAlign="center">
            <img
               alt="Proveedor"
               className="grid-slider__img"
               src="/images/productos/superMercado.png"
            />
         </Grid>
         <List sx={style} className="list-sidebar">
            <Divider light />
            <ListItem button>
               <Typography variant="h6">Nombre Farmacia</Typography>
            </ListItem>
            <Divider light />
            <ListItem button>
               <Button variant="text">Referencia</Button>
            </ListItem>
            <Divider light />
            <ListItem button>
               <HowToRegIcon />
               <Typography
                  variant="subtitle1"
                  onClick={handleOpenPrueba}
                  className="grid-slider__subtitle"
               >
                  Datos del Cliente
               </Typography>
               <NavigateNextIcon />
            </ListItem>
            <Divider light />
            <ListItem button>
               <MonetizationOnIcon />
               <Typography
                  variant="subtitle1"
                  onClick={handleOPenGuiaDespacho}
                  className="grid-slider__subtitle"
               >
                  Condiciones del Documento
               </Typography>
               <NavigateNextIcon />
            </ListItem>
            <Divider light />
            <Accordion>
               <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
               >
                  <Typography variant="subtitle1">Marcas</Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <FormGroup>
                     <FormControlLabel
                        control={<Checkbox defaultChecked name="gilad" />}
                        label="Marca 1 (207)"
                     />
                     <FormControlLabel
                        control={<Checkbox defaultChecked name="jason" />}
                        label="Marca 2 (17)"
                     />
                     <FormControlLabel
                        control={<Checkbox name="antoine" />}
                        label="Marca 3 (30)"
                     />
                  </FormGroup>
               </AccordionDetails>
            </Accordion>
            <Accordion>
               <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
               >
                  <Typography variant="subtitle1">Descuento</Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <Typography variant="subtitle2">
                     %{value[0]} - %{value[1]}. {"(206)"}
                  </Typography>
                  <Box sx={{ width: "100%" }}>
                     <Slider
                        getAriaLabel={() => "Temperature range"}
                        value={value}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetext}
                     />
                  </Box>
               </AccordionDetails>
            </Accordion>
            <Accordion>
               <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
               >
                  <Typography variant="subtitle1">Precio</Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <FormGroup>
                     <FormControlLabel
                        control={<Checkbox defaultChecked name="jason" />}
                        label="De S/ 0 a S/ 10 (506)"
                     />
                     <FormControlLabel
                        control={<Checkbox defaultChecked name="gilad" />}
                        label="De S/ 10 a S/ 25 (280)"
                     />
                     <FormControlLabel
                        control={<Checkbox defaultChecked name="jason" />}
                        label="De S/ 25 a S/ 50 (70)"
                     />
                     <FormControlLabel
                        control={<Checkbox name="antoine" />}
                        label="De S/ 50 a S/ 100 (3)"
                     />
                  </FormGroup>
               </AccordionDetails>
            </Accordion>
            <Grid mt={3}>
               <button className="list-siderbar__button">Aplicar</button>
            </Grid>
         </List>
         <Modal
            open={openModalPrueba}
            onClose={handleClosePrueba}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            className="modalCard"
         >
            <ModalPrueba />
         </Modal>

         <Modal
            open={openModalGuiaDespacho}
            onClose={handleCloseGuiaDespacho}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            className="modalCard"
         >
            <ModalGuiaDespacho />
         </Modal>
      </Grid>
   );
};
