import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography} from '@mui/material'
import {  useState } from "react";
import {Props} from "./LsQuoteBook.type"
import './LsQuoteBook_styles.css'


import Link from '@mui/material/Link';

export const LsQuoteConfirmEmition:React.FC<Props> = (props: Props): JSX.Element => {
 
    return (
        <Protected>
        <Container maxWidth='xl'>
           <Grid container spacing={1}>
                  <Grid item xs={12} md={12} >
                     <Box>
                        <Typography variant='h6' component='h1' gutterBottom className="tittle">
                           CONFIRMACION DE EMISION EV-LU
                        </Typography>
                     </Box>
                  </Grid>

                  <Grid item xs={12} md={12} >
                     <Box>
                        <Typography variant='h6' style={{background:'#FF9700', color:'#ffffff'}} component='h1' gutterBottom align="center">
                        Documento Ingresado Correctamente: Folio nº 866
                        </Typography>
                     </Box>
                  </Grid>

                  <Grid item xs={12} md={4} >
                     <Typography variant='subtitle2' gutterBottom >
                        Opciones de Visualizacion
                     </Typography>
                     <Link className="link" href="#"> Visualizar Documento N°866</Link>
                     <br></br>
                     <Link className="link" href="#"> Documento Libro</Link>
                  </Grid>
                  <Grid item xs={12} md={4} >
                     <Typography variant='subtitle2' gutterBottom >
                     Opciones emisión nuevo docuento
                     </Typography>
                     <Link className="link" href="#"> Cotizacion</Link>
                     <br></br>
                     <Link className="link" href="#"> Documento Electrónico</Link>
                  </Grid>
                  <Grid item xs={12} md={4} >
                     <Typography variant='subtitle2' gutterBottom >
                     Opcion imprimir
                     </Typography>
                     <Link className="link" href="#">Imprimir Formato Térmico</Link>
                     {/* <Link href="#"> Documento Libro</Link> */}
                  </Grid>
                
            </Grid>
         </Container>
     </Protected>
    );
   };

