import React, { useState, useMemo, useRef, useEffect } from 'react';
import { FunctionComponent } from "react";
import { Grid, Typography } from '@mui/material'

import ArticleIcon from '@mui/icons-material/Article';
import Link from '@mui/material/Link';

import { ROUTE_EV_DE_OFFICE_GUIDE
   ,ROUTE_EV_DE_QUOTES, ROUTE_EV_DE_SALE_NOTE
   ,ROUTE_EV_DE_SALE_NOTE_EXPORT
   ,ROUTE_EV_DE_NOTE_SELL_EXENTA
   ,ROUTE_EV_DE_CUOTE_MEDIA } from '@/toolbox/constants/route-map';


export const Sale: FunctionComponent = () => {

   return (
      <>
         <Grid container spacing={2} pl={5} mt={1}>
            <Grid container item>
               <Link href={ROUTE_EV_DE_QUOTES} underline="none">
                  <Grid className={'enlacesDoc'}>
                     <ArticleIcon />
                     <Typography variant="subtitle1" ml={2}>
                        COTIZACIONES
                     </Typography>
                  </Grid>
               </Link>
            </Grid>
            <Grid container item>
               <Link href={ROUTE_EV_DE_CUOTE_MEDIA} underline="none">
                  <Grid className={'enlacesDoc'}>
                     <ArticleIcon />
                     <Typography variant="subtitle1" ml={2}>
                        COTIZACIONES MULTIMONEDA
                     </Typography>
                  </Grid>
               </Link>
            </Grid>
            <Grid container item>
               <Link href={ROUTE_EV_DE_SALE_NOTE} underline="none">
                  <Grid className={'enlacesDoc'}>
                     <ArticleIcon />
                     <Typography variant="subtitle1" ml={2}>
                        NOTA DE VENTA
                     </Typography>
                  </Grid>
               </Link>
            </Grid>
            <Grid container item>
               <Link href={ ROUTE_EV_DE_NOTE_SELL_EXENTA} underline="none">
                  <Grid className={'enlacesDoc'}>
                     <ArticleIcon />
                     <Typography variant="subtitle1" ml={2}>
                        NOTA DE VENTA EXENTA
                     </Typography>
                  </Grid>
               </Link>
            </Grid>
            <Grid container item>
               <Link href={ROUTE_EV_DE_SALE_NOTE_EXPORT} underline="none">
                  <Grid className={'enlacesDoc'}>
                     <ArticleIcon />
                     <Typography variant="subtitle1" ml={2}>
                        NOTA DE VENTA EXPORTACION
                     </Typography>
                  </Grid>
               </Link>
            </Grid>
            <Grid container item>
               <Link href={ROUTE_EV_DE_OFFICE_GUIDE} underline="none">
                  <Grid className={'enlacesDoc'}>
                     <ArticleIcon />
                     <Typography variant="subtitle1" ml={2}>
                        GUIA DE DESPACHO ELECTRONICA
                     </Typography>
                  </Grid>
               </Link>
            </Grid>

         </Grid>
      </>
   );
}