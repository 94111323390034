import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "./GeneralAdvances.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './GeneralAdvances_styles.css'
import Modal from '@mui/material/Modal';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';


interface Column {
   id:  'rut' | 'nombre' | 'periodo'|'montoAnticipo'|'estado';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

const columns:readonly Column[] = [
   { id: 'rut', label: 'Rut', minWidth: 100 },
   { id: 'nombre', label: 'Nombre', minWidth: 100 },
   { id: 'periodo', label: 'Periodo', minWidth: 100 },
   { id: 'montoAnticipo', label: 'Monto Anticipo', minWidth: 100 },
   { id: 'estado', label: 'Estado', minWidth: 100 },
   ];

interface Data {
   rut: string;
   nombre: string;
   periodo: string;
   montoAnticipo: string;
   estado: string;
}

function createDataAdvance(
   rut: string,
   nombre: string,
   periodo: string,
   montoAnticipo: string,
   estado: string,
   ):Data {
   return { rut, nombre, periodo, montoAnticipo, estado };
}

const rowsDataAdvanceGeneral = [
   createDataAdvance('12345678-9', 'Juan Perez', '2020-01', '$1.000.000', 'Pendiente'),
   createDataAdvance('12345678-9', 'Juan Perez', '2020-01', '$1.000.000', 'Pendiente'),
   createDataAdvance('12345678-9', 'Juan Perez', '2020-01', '$1.000.000', 'Pendiente'),
   createDataAdvance('12345678-9', 'Juan Perez', '2020-01', '$1.000.000', 'Pendiente'),
   createDataAdvance('12345678-9', 'Juan Perez', '2020-01', '$1.000.000', 'Pendiente'),
   createDataAdvance('12345678-9', 'Juan Perez', '2020-01', '$1.000.000', 'Pendiente'),
   createDataAdvance('12345678-9', 'Juan Perez', '2020-01', '$1.000.000', 'Pendiente'),
]

const users=[
   {
      value: '1',
      label: 'Juan Perez',
   },
   {
      value: '2',
      label: 'Pedro Medina',
   },
   {
      value: '3',
      label: 'Marco Cornejo',
   },
   {
      value: '4',
      label: 'Jhon Valencia',
   },
   {
      value: '5',
      label: 'Juan Gomez',
   },
   {
      value: '6',
      label: 'Pedro Perez',
   },
   {
      value: '7',
      label: 'Marco Dias',
   },
   {
      value: '8',
      label: 'Jhon Palomares',
   },
   {
      value: '9',
      label: 'Juan Perez',
   },
   {
      value: '10',
      label: 'Pedro Medina',
   },
]

export const GeneralAdvances:React.FC<Props> = (props: Props): JSX.Element => {
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [loading, setLoading] = useState(false);
   const [familyProduct, setFamilyProduct] = useState('');
   const [estado, setEstado]= useState('');

   const [openModal, setOpenModal] = useState(false);
   const [value, setValue] = useState<Date | null>(new Date());

   const handleOpenModal = () => setOpenModal(true);
   const handleCloseModal = () => setOpenModal(false);

   function handleClick() {
     setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const estados= [
      { value: '1', label: 'Activo' },
      { value: '2', label: 'Inactivo' },
   ]

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 920,
      height:600,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };
    return (
        <Protected>
        <Container maxWidth='xl'>
           <Grid container spacing={3}>
               <Grid item xs={12} md={12} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        DATOS ANTICIPO MASIVO
                     </Typography>
                  </Box>
                  
               </Grid>

               <Grid item xs={12} md={3} style={{marginTop:'4px'}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={3}>
                    <DatePicker
                        views={['year', 'month']}
                        label="Mes y Año"
                        minDate={new Date('2012-03-01')}
                        maxDate={new Date('2023-06-01')}
                        value={value}
                        onChange={(newValue) => {
                            setValue(newValue);
                        }}
                        renderInput={(params) => <TextField  {...params} helperText={null} />}
                        />
                    </Stack>
                    </LocalizationProvider>
               </Grid>

               <Grid item xs={12} sm={3}>
               <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={users}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Trabajador" />}
                     />
               </Grid> 

               <Grid item xs={12} sm={3}>
                  <TextField
                     label="Anticipo"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                     value='$1.000.000'
                  />
               </Grid>

               <Grid item xs={12} md={3}>
                    <Button
                        type='submit'
                        variant='contained'
                        // onClick={handleSubmit}
                        // disabled={loading}
                        disableElevation
                        className='btn_next'
                        style={{backgroundColor:'#ff9700', color:'#fff'}}
                        >
                        <span>Agregar</span>
                    </Button>
                </Grid>

            </Grid>

            
          
            <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:440}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                                 {column.label}
                              </TableCell>
                           ))}
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsDataAdvanceGeneral.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.rut}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}

                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsDataAdvanceGeneral.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
            </Paper>

         </Container>
     </Protected>
    );
   };

