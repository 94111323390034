import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, } from '@mui/material';
import { ArrowBackIcon, ArrowDownIcon, ArrowRightIcon, MoreIcon } from "@toolbox/constants/icons";
import { Props } from './FormPersonalData.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import Entity from "@assets/img/entity.png";
import './style.sass'
import "./FormPersonalData_style.css";
import BadgeIcon from '@mui/icons-material/Badge';
import HeightIcon from '@mui/icons-material/Height';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';


export const FormPersonalData: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false)
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const [estado, setEstado] = useState<any>({ rut: false, nombre_rz: false })

   const [valueRevalorizable, setValueRevalorizable] = React.useState('Si');
   const [valueDepreciable, setValueDepreciable] = React.useState('Si');
   const [valueTipoDepreciacion, setValueTipoDepreciacion] = React.useState('Lineal');
   const [valueUnidadMedina, setValueUnidadMedina] = React.useState('Mensual');


   const handleChangeRevalorizable = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueRevalorizable((event.target as HTMLInputElement).value);
   };

   const handleChangeDepreciable = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueDepreciable((event.target as HTMLInputElement).value);
   };
   const handleTipoDepreciacion = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueTipoDepreciacion((event.target as HTMLInputElement).value);
   };
   const handleUnidadMedina = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueUnidadMedina((event.target as HTMLInputElement).value);
   };

   const grupos = [
      { label: 'Grupo 1' },
      { label: 'Grupo 2' },
      { label: 'Grupo 3' },
      { label: 'Grupo 4' }
   ]
   const estadoCivil = [
      { label: 'Casado(a)' },
      { label: 'Divorciado(a)' },
      { label: 'Separado(a)' },
      { label: 'Soltero(a)' },
      { label: 'Viudo(a)' },
   ]
   const sexoValue = [
      { label: 'Masculino' },
      { label: 'Femenino' },
   ]
   const estadoTrabajador = [
      { label: 'ACTIVADO' },
      { label: 'DESACTIVADO' },
   ]
   const nacionalidadValue = [
      { label: 'CHILENO' },
      { label: 'EXTRANJERO' },
   ]
   const situacionMilitar = [
      { label: 'Efectuado' },
      { label: 'Eximido' },
      { label: 'Postergado' },
      { label: 'Inscrito Disponible' },
      { label: 'Sin Servicio Militar' }
   ]
   const nivelEstudios = [
      { label: 'Certificaciones Profesionales' },
      { label: 'Post Grado' },
      { label: 'Universitaria' },
      { label: 'Técnica' },
      { label: 'Básica' }
   ]
   const comunaValue = [
      { label: 'Comuna 1' },
      { label: 'Comuna 2' },
      { label: 'Comuna 3' },
      { label: 'Comuna 4' },
      { label: 'Comuna 5' }
   ]
   const regionValue = [
      { label: 'Región 1' },
      { label: 'Región 2' },
      { label: 'Región 3' },
      { label: 'Región 4' },
      { label: 'Región 5' }
   ]

   const rules = useMemo(() => ({
      codigoInsumo: [
         VALIDATORS.REQUIRED,
      ],
      nombreInsumo: [
         VALIDATORS.REQUIRED,
      ],
      detalleLargo: [
         VALIDATORS.REQUIRED
      ],
      grupo: [
         VALIDATORS.REQUIRED,
      ],
      estadoProducto: [
         VALIDATORS.REQUIRED,
      ],
      clasificacionCompra: [
         VALIDATORS.REQUIRED,
      ],

   }), []);


   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'codigoInsumo':
            props.setvalueGeneralInformation(prev => ({ ...prev, codigoInsumo: value }));
            break;
         case 'nombreInsumo':
            props.setvalueGeneralInformation(prev => ({ ...prev, nombreInsumo: value }))
            break;
         case 'detalleLargo':
            props.setvalueGeneralInformation(prev => ({ ...prev, detalleLargo: value }))
            break;
         case 'grupo':
            props.setvalueGeneralInformation(prev => ({ ...prev, grupo: value }));
            break;
         case 'estadoProducto':
            props.setvalueGeneralInformation(prev => ({ ...prev, estadoProducto: value }));
            break;
         case 'clasificacionCompra':
            props.setvalueGeneralInformation(prev => ({ ...prev, clasificacionCompra: value }));
            break;
         case 'ubicacion':
            props.setvalueGeneralInformation(prev => ({ ...prev, ubicacion: value }));
            break;
         case 'nombreInsumo_vencimiento':
            props.setvalueGeneralInformation(prev => ({ ...prev, nombreInsumo_vencimiento: value }));
            break;
         case 'revalorizable':
            props.setvalueGeneralInformation(prev => ({ ...prev, revalorizable: value }));
            break;
         case 'tipo_depreciacion':
            props.setvalueGeneralInformation(prev => ({ ...prev, tipo_depreciacion: value }));
            break;
         case 'depreiable':
            props.setvalueGeneralInformation(prev => ({ ...prev, depreiable: value }));
            break;
         case 'unidad_medida':
            props.setvalueGeneralInformation(prev => ({ ...prev, unidad_medida: value }));
            break;
         default:
            break;
      }
   };
   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      props.setvalueGeneralInformation(prev => ({ ...prev, [name]: newVal }));
   }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      props.handleNext();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }

   return (
      <Container maxWidth="xl">
         <form >
            <Grid container spacing={3} className={`e-mb-lg`}>

               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="codigoInsumo"
                     type='text'
                     placeholder="Rut"
                     value={props.valueGeneralInformation.codigoInsumo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.codigoInsumo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />

               </Grid>

               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="nombreInsumo"
                     type='text'
                     placeholder="Nombres"
                     value={props.valueGeneralInformation.nombreInsumo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.nombreInsumo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />

               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="nombreInsumo"
                     type='text'
                     placeholder="Apellido Paterno"
                     value={props.valueGeneralInformation.nombreInsumo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.nombreInsumo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />

               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="nombreInsumo"
                     type='text'
                     placeholder="Apellido Materno"
                     value={props.valueGeneralInformation.nombreInsumo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.nombreInsumo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />

               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={estadoCivil}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Estado Civil" />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={sexoValue}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Sexo " />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} color="primary" focused>
                     <Stack>
                        <DatePicker
                           openTo="year"
                           // views={['year', 'month', 'day']}
                           label="Fecha de Nacimiento"
                           value={value}
                           onChange={(newValue) => {
                              setValue(newValue);
                           }}
                           renderInput={(params) => <TextField {...params} helperText={null} />}
                        />
                     </Stack>
                  </LocalizationProvider>
               </Grid>
               <Grid item xs={12} sm={3}>
                  <FormControl >
                     <FormControlLabel control={<Checkbox />} label="Pensionado" />                     
                  </FormControl>
               </Grid> 
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={estadoTrabajador}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Estado del Trabajador" />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={nacionalidadValue}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Nacionalidad" />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={situacionMilitar}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Situación Militar" />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={nivelEstudios}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Último nivel de Estudios" />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     ref={ref => inputRefs.current[1] = ref}
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <HeightIcon />

                        </InputAdornment>
                     }
                     name="detalleLargo"
                     placeholder="Dirección"
                     value={props.valueGeneralInformation.detalleLargo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.detalleLargo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={comunaValue}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Comuna" />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     ref={ref => inputRefs.current[1] = ref}
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <PhoneIcon />

                        </InputAdornment>
                     }
                     name="detalleLargo"
                     placeholder="Teléfono Fijo"
                     value={props.valueGeneralInformation.detalleLargo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.detalleLargo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     ref={ref => inputRefs.current[1] = ref}
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <LocationCityIcon />
                        </InputAdornment>
                     }
                     name="detalleLargo"
                     placeholder="Ciudad"
                     value={props.valueGeneralInformation.detalleLargo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.detalleLargo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     ref={ref => inputRefs.current[1] = ref}
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <PhoneAndroidIcon />                           
                        </InputAdornment>
                     }
                     name="detalleLargo"
                     placeholder="Celular"
                     value={props.valueGeneralInformation.detalleLargo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.detalleLargo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     ref={ref => inputRefs.current[1] = ref}
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <AlternateEmailIcon />                          
                        </InputAdornment>
                     }
                     name="detalleLargo"
                     placeholder="Email"
                     value={props.valueGeneralInformation.detalleLargo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.detalleLargo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={regionValue}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Tegión" />}
                  />
               </Grid>              

               <Grid item container
                  xs={12}
                  justifyContent='right'
               >
                  <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                        className='btn_save'
                        endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>Siguiente</span>
                        }
                     </Button>

                  </Grid>
               </Grid>
            </Grid>
         </form>
      </Container>
   );
}
