import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container } from "@mui/material";
import { useState } from "react";
import { Props } from "../ElectronicSaleDocuments.type";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

import { Link } from "react-router-dom";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {
   ROUTE_F_ED_ELECTRONIC_BILL,
   ROUTE_F_ED_ELECTRONIC_CREDIT,
   ROUTE_F_ED_ELECTRONIC_EXEMPT,
   ROUTE_F_ED_ELECTRONIC_PURCHASE,
   ROUTE_F_DE_PURCHASE_ORDER,
   ROUTE_F_ED_EXPORT_SALE_NOTE,
   ROUTE_F_ED_SALE_NOTE,
   ROUTE_F_ED_QUOTES,
   ROUTE_F_ED_ELECTRONIC_DEBIT_NOTE,
   ROUTE_F_ED_ELECTRONIC_DISPATCH_GUIDE,
} from "@/toolbox/constants/route-map";

export const ElectronicDocument: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const style = {
      width: "100%",
      // maxWidth: 500,
      bgcolor: "background.paper",
      color: "#000",
      borderRadius: "10px",
   };
   const disable = {
      bgcolor: "#EEEEEB",
   };
   const StyleIcon = {
      color: "rgb(228, 152, 13)",
   };

   return (
      <>
         <List sx={style} component="nav" aria-label="mailbox folders">
            <ListItem sx={disable}>
               <ListItemAvatar>
                  <AssignmentIcon style={StyleIcon} />
               </ListItemAvatar>
               <ListItemText
                  primary="BANCO"
                  secondary="No tiene Rangos Disponibles"
               />
            </ListItem>
            <Divider />
            <ListItem sx={disable}>
               <ListItemAvatar>
                  <AssignmentIcon style={StyleIcon} />
               </ListItemAvatar>
               <ListItemText
                  primary="Comprobante Gasto"
                  secondary="No tiene Rangos Disponibles"
               />
            </ListItem>
            <Divider />
            <ListItem button component={Link} to={"#"}>
               <ListItemAvatar>
                  <AssignmentIcon style={StyleIcon} />
               </ListItemAvatar>
               <ListItemText primary="NOTA DE COBRO" />
            </ListItem>
            <Divider />
            <ListItem button component={Link} to={ROUTE_F_ED_ELECTRONIC_BILL}>
               <ListItemAvatar>
                  <AssignmentIcon style={StyleIcon} />
               </ListItemAvatar>
               <ListItemText primary="FACTURA ELECTRÓNICA" />
            </ListItem>
            <Divider />
            <ListItem button component={Link} to={ROUTE_F_ED_ELECTRONIC_EXEMPT}>
               <ListItemAvatar>
                  <AssignmentIcon style={StyleIcon} />
               </ListItemAvatar>
               <ListItemText primary="FACTURA EXENTA ELECTRÓNICA" />
            </ListItem>
            <Divider />
            <ListItem sx={disable}>
               <ListItemAvatar>
                  <AssignmentIcon style={StyleIcon} />
               </ListItemAvatar>
               <ListItemText
                  primary="Boleta"
                  secondary="No tiene Rangos Disponibles"
               />
            </ListItem>
            <Divider />
            <ListItem sx={disable}>
               <ListItemAvatar>
                  <AssignmentIcon style={StyleIcon} />
               </ListItemAvatar>
               <ListItemText
                  primary="LIQUIDACIÓN FACTURA ELECTRÓNICA"
                  secondary="No tiene Rangos Disponibles"
               />
            </ListItem>
            <Divider />
            <ListItem
               button
               component={Link}
               to={ROUTE_F_ED_ELECTRONIC_PURCHASE}
            >
               <ListItemAvatar>
                  <AssignmentIcon style={StyleIcon} />
               </ListItemAvatar>
               <ListItemText primary="FACTURA DE COMPRA ELECTRONICA" />
            </ListItem>
            <Divider />
            <ListItem
               button
               component={Link}
               to={ROUTE_F_ED_ELECTRONIC_DISPATCH_GUIDE}
            >
               <ListItemAvatar>
                  <AssignmentIcon style={StyleIcon} />
               </ListItemAvatar>
               <ListItemText primary="GUIA DE DESPACHO ELECTRONICA" />
            </ListItem>
            <Divider />
            <ListItem
               button
               component={Link}
               to={ROUTE_F_ED_ELECTRONIC_DEBIT_NOTE}
            >
               <ListItemAvatar>
                  <AssignmentIcon style={StyleIcon} />
               </ListItemAvatar>
               <ListItemText primary="NOTA DE DÉBITO ELECTRÓNICA" />
            </ListItem>
            <Divider />
            <ListItem button component={Link} to={"#"}>
               <ListItemAvatar>
                  <AssignmentIcon style={StyleIcon} />
               </ListItemAvatar>
               <ListItemText primary="OT PRODUCCIÓN" />
            </ListItem>
            <Divider />
            <ListItem sx={disable}>
               <ListItemAvatar>
                  <AssignmentIcon style={StyleIcon} />
               </ListItemAvatar>
               <ListItemText
                  primary="ORDEN DE COMPRA IMPORTACIÓN"
                  secondary="No tiene Rangos Disponibles"
               />
            </ListItem>
            <Divider />
            <ListItem button component={Link} to={ROUTE_F_ED_ELECTRONIC_CREDIT}>
               <ListItemAvatar>
                  <AssignmentIcon style={StyleIcon} />
               </ListItemAvatar>
               <ListItemText primary="NOTA DE CRÉDITO ELECTRÓNICA" />
            </ListItem>
            <Divider />
            <ListItem button component={Link} to={ROUTE_F_ED_QUOTES}>
               <ListItemAvatar>
                  <AssignmentIcon style={StyleIcon} />
               </ListItemAvatar>
               <ListItemText primary="COTIZACIONES" />
            </ListItem>
            <Divider />
            <ListItem sx={disable}>
               <ListItemAvatar>
                  <AssignmentIcon style={StyleIcon} />
               </ListItemAvatar>
               <ListItemText
                  primary="COTIZACIONES MULTIMONEDA"
                  secondary="No tiene Rangos Disponibles"
               />
            </ListItem>
            <Divider />
            <ListItem button component={Link} to={ROUTE_F_ED_SALE_NOTE}>
               <ListItemAvatar>
                  <AssignmentIcon style={StyleIcon} />
               </ListItemAvatar>
               <ListItemText primary="NOTA DE VENTA" />
            </ListItem>
            <Divider />
            <ListItem button component={Link} to={ROUTE_F_ED_EXPORT_SALE_NOTE}>
               <ListItemAvatar>
                  <AssignmentIcon style={StyleIcon} />
               </ListItemAvatar>
               <ListItemText primary="NOTA DE VENTA DE EXPORTACIÓN" />
            </ListItem>
            <Divider />
            <ListItem button component={Link} to={ROUTE_F_DE_PURCHASE_ORDER}>
               <ListItemAvatar>
                  <AssignmentIcon style={StyleIcon} />
               </ListItemAvatar>
               <ListItemText primary="ÓRDENES DE COMPRA" />
            </ListItem>
            <Divider />
         </List>
      </>
   );
};
