import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "../AbsencesTardies.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import '../AbsencesTardies_styles.css'
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { PictureAsPdf } from "@mui/icons-material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import LocalizationProvider from '@mui/lab/LocalizationProvider';


interface Column {
   id: 'dia' | 'mes' | 'anio' | 'dias'|'obserbacion'|'tipoRegistro'|'tipoAsistencia';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

const columns:readonly Column[] = [
   { id: 'dia', label: 'Dia', minWidth: 50 },
   { id: 'mes', label: 'Mes', minWidth: 50 },
   { id: 'anio', label: 'Año', minWidth: 50 },
   { id: 'dias', label: 'Dias', minWidth: 50 },
   { id: 'obserbacion', label: 'Observacion', minWidth: 50 },
   { id: 'tipoRegistro', label: 'Tipo Registro', minWidth: 50 },
   { id: 'tipoAsistencia', label: 'Tipo Asistencia', minWidth: 50 },

   ];

interface Data {
   dia: number;
   mes: number;
   anio: number;
   dias: number;
   obserbacion: string;
   tipoRegistro: string;
   tipoAsistencia: string;
}

function createDataAbsense(
   dia: number,
   mes: number,
   anio: number,
   dias: number,
   obserbacion: string,
   tipoRegistro: string,
   tipoAsistencia: string,
   ):Data {
   return { dia, mes, anio, dias, obserbacion, tipoRegistro, tipoAsistencia };
}

const rowsDataAbsenses = [
   createDataAbsense(1,1,2020,1,'Prueba litro nuveo','Descuento Liquidacion','Licencia'),
   createDataAbsense(2,1,2020,1,'Prueba litro nuveo','Descuento Liquidacion','Inasistencia'),
   createDataAbsense(3,1,2020,1,'Prueba litro nuveo','Descuento Liquidacion','Licencia'),
   createDataAbsense(4,1,2020,1,'Prueba litro nuveo','Descuento Liquidacion','Inasistencia'),
   createDataAbsense(5,1,2020,1,'Prueba litro nuveo','Descuento Liquidacion','Licencia'),
   createDataAbsense(6,1,2020,1,'Prueba litro nuveo','Descuento Liquidacion','Inasistencia'),
   createDataAbsense(7,1,2020,1,'Prueba litro nuveo','Descuento Liquidacion','Licencia'),
   createDataAbsense(8,1,2020,1,'Prueba litro nuveo','Descuento Liquidacion','Inasistencia'),
   createDataAbsense(9,1,2020,1,'Prueba litro nuveo','Descuento Liquidacion','Licencia'),
]
const tipoRegistrado=[
   {value: 'Descuento a Liquidacion', label: 'Descuento a Liquidacion'},
   {value: 'Descuento a Vacaciones', label: 'Descuento a Vacaciones'},
]
const TipoInasistencia = [
   {value: 'Inasistencia', label: 'Inasistencia'},
   {value: 'Licencia', label: 'Licencia'},
]


export const ModalTardies:React.FC<Props> = (props: Props): JSX.Element => {
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value2, setValue2] = useState('');
   const [loading, setLoading] = useState(false);
   const [familyProduct, setFamilyProduct] = useState('');
   const [estado, setEstado]= useState('');
   const [value, setValue] = useState<Date | null>(new Date());

    const handleChangeFamilyProduct = (event: SelectChangeEvent) => {
      setFamilyProduct(event.target.value);
    };
    const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
    };
   function handleClick() {
     setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };


    return (
        <Container maxWidth='xl'>
           <Grid container spacing={3}>
               <Grid item xs={12} md={12} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                     ATRASOS
                     </Typography>
                  </Box>
                  <Typography  variant="caption" display="block" gutterBottom mt={1}>
                  Trabajador Seleccionado
                  </Typography>
               </Grid>
               <Grid item xs={6} md={3} mb={-2}>
               <Box>
                     <Typography variant='subtitle2'  gutterBottom>
                        RUT TRABAJADOR
                     </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={9} mb={-2}>
                    <Box>
                        <Typography variant='caption'  gutterBottom>
                        16976751-3
                        </Typography>
                    </Box> 
            </Grid>

            <Grid item xs={6} md={3} mb={2}>
               <Box>
                     <Typography variant='subtitle2'  gutterBottom>
                        APELLIDOS
                     </Typography>
                  </Box>
                </Grid>
            <Grid item xs={6} md={9} mb={2}>
                    <Box>
                        <Typography variant='caption'  gutterBottom>
                        ALARCON - GONZALEZ - FERNANDO
                        </Typography>
                    </Box> 
            </Grid>
               <Grid item xs={6} md={4}>
               <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack spacing={3}>
                     <DatePicker
                        views={['year', 'month']}
                        label="Mes"
                        minDate={new Date('2012-03-01')}
                        maxDate={new Date('2023-06-01')}
                        value={value}
                        onChange={(newValue) => {
                           setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} helperText={null} />}
                     />
                  </Stack>
               </LocalizationProvider>
               </Grid>
               <Grid item xs={3} md={4}>
                  <TextField
                     label="Total Dias"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                     value='3'
                  />
               </Grid>

               <Grid item xs={12} sm={6}>
                  <TextField
                     label="Observaciones"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                     value='...'
                  />
               </Grid>
               

               <Grid item xs={12} md={5}>
                    <Button
                        type='submit'
                        
                        variant='contained'
                        // onClick={handleSubmit}
                        // disabled={loading}
                        disableElevation
                        className='btn_next'
                        style={{backgroundColor:'#ff9700', color:'#fff'}}
                        >
                        <span>Agregar</span>
                    </Button>
                </Grid>

            </Grid>
            
            <div style={{background:'#16556b', borderRadius:'5px', color:'#ffffff',}} >
                        <Typography 
                        mt={2}
                        mb={2}
                        style={{padding:'3px'}}
                        variant="subtitle2"
                        align='center'
                        >HISTORICO DE INASISTENCIAS INGRESADAS
                        </Typography>
         </div>

            <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:230}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                                 {column.label}
                              </TableCell>
                           ))}
                           <TableCell>Opcion</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsDataAbsenses.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.anio}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                       <IconButton aria-label="delete">
                                          <DeleteIcon style={{color:'red'}}/>
                                       </IconButton>
                                      
         
                                 </TableCell>

                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsDataAbsenses.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
            </Paper>

         </Container>
     
    );
   };

