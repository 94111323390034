import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "./GcSubFamily.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './GcSubFamily_styles.css'
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { PictureAsPdf } from "@mui/icons-material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';

interface Column {
   id: 'numero' | 'nombre' | 'descripcion' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

const columns:readonly Column[] = [
   { id: 'numero', label: 'Número', minWidth: 100 },
   { id: 'nombre', label: 'Nombre', minWidth: 100 },
   { id: 'descripcion', label: 'Descripción', minWidth: 100 },
   ];

interface Data {
   numero: number;
   nombre: string;
   descripcion: string;
}

function createDataBusiness(
   numero: number,
   nombre: string,
   descripcion: string,
   ):Data {
   return { numero, nombre, descripcion };
}

const rowsDataBusiness = [
   createDataBusiness(1, 'Gestión de la Calidad', 'Gestión de la Calidad'),
   createDataBusiness(2, 'Gestión de la Seguridad', 'Gestión de la Seguridad'),
   createDataBusiness(3, 'Gestión de la Calidad', 'Gestión de la Calidad'),
   createDataBusiness(4, 'Gestión de la Seguridad', 'Gestión de la Seguridad'),
   createDataBusiness(5, 'Gestión de la Calidad', 'Gestión de la Calidad'),
   createDataBusiness(6, 'Gestión de la Seguridad', 'Gestión de la Seguridad'),
]


export const GcSubFamily:React.FC<Props> = (props: Props): JSX.Element => {
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [familyProduct, setFamilyProduct] = useState('');
   const [estado, setEstado]= useState('');

    const handleChangeFamilyProduct = (event: SelectChangeEvent) => {
      setFamilyProduct(event.target.value);
    };
    const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
    };
   function handleClick() {
     setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const familyProducts= [
      { value: '1', label: 'products' },
      { value: '2', label: 'product1' },
      { value: '3', label: 'product2' },
      { value: '4', label: 'product3' },
      { value: '5', label: 'product4' },
      { value: '6', label: 'product5' },
   ];
   const estados= [
      { value: '1', label: 'Activo' },
      { value: '2', label: 'Inactivo' },
   ]


    return (
        <Protected>
        <Container maxWidth='xl'>
           <Grid container spacing={3}>
               <Grid item xs={12} md={6} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        MANTENEDOR DE SUB FAMILIA DE PRODUCTOS
                     </Typography>
                  </Box>
                  
               </Grid>
                     
               <Grid item xs={12} md={6} className="btn-download">
                  <Box pb={2}>
                  <LoadingButton
                     color="warning"
                     onClick={handleClick}
                     loading={loading}
                     loadingPosition="start"
                     startIcon={<FileDownloadIcon />}
                     variant="contained"
                     className="btn-download2"
                     >
                     Exportar Fam, Subfam y Prods
                     </LoadingButton>
                  </Box>
               </Grid>

               <Grid item xs={12} sm={3}>
               <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={familyProducts}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Familia de Productos" />}
                     />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <TextField
                     label="Nombre Sub Familia"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
               <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={estados}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Estado" />}
                     />
               </Grid>
            </Grid>
            <Grid item xs={12} sm={6} mt ={ 2}>
               <TextField
                  id="filled-multiline-flexible"
                  label="Descripción Sub Familia"
                  multiline
                  size='small'
                  maxRows={4}
                  fullWidth
                  // value={value}
                  // onChange={handleChange}
               />
               </Grid>       
            <Grid item container
                  xs={12}
                  mt={2}
                 justifyContent='right'
                 >
                     <Grid item>
                        <Button
                           type='submit'
                           variant='contained'
                           // onClick={handleSubmit}
                           // disabled={loading}
                           disableElevation
                           className='btn_save'
                        >
                                 <span>Agregar</span>
                        </Button>
                     </Grid>
               </Grid> 
            <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={2}>
               LISTADO DE REGISTRO INGRESADO
            </Typography>

            <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:440}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           <TableCell>N</TableCell>
                           <TableCell>Nombre</TableCell>
                           <TableCell>Descripcion</TableCell>
                           <TableCell>Estado</TableCell>
                           <TableCell>Exportar</TableCell>
                           <TableCell>Opcion</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsDataBusiness.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.numero}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                       <IconButton
                                          >
                                             <CheckCircleIcon />
                                       </IconButton>
         
                                 </TableCell>
                                 <TableCell>
                                       <IconButton
                                          >
                                             <ArticleIcon />
                                       </IconButton>
                                       <IconButton
                                          >
                                             <PictureAsPdfIcon />
                                       </IconButton>
         
                                 </TableCell>
                                 <TableCell>
                                       <IconButton
                                          >
                                             <EditIcon />
                                       </IconButton>
         
                                 </TableCell>

                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsDataBusiness.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
            </Paper>

         </Container>
     </Protected>
    );
   };

