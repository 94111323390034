import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, InputAdornment, FormControl, FormControlLabel, Checkbox, } from '@mui/material'
import { useState, useRef } from "react";
import { Props } from "../GcProvider.type"
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import NumbersIcon from '@mui/icons-material/Numbers';
import TableContainer from '@mui/material/TableContainer';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PhoneIcon from '@mui/icons-material/Phone';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import AssignmentIcon from '@mui/icons-material/Assignment';
import NextWeekIcon from '@mui/icons-material/NextWeek';
import TableRow from '@mui/material/TableRow';
import '../GcProvider.css'
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';

interface Column {
   id: 'numero' | 'nombre' | 'cargo' | 'email';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: 'numero', label: 'N', minWidth: 100 },
   { id: 'nombre', label: 'Nombre', minWidth: 100 },
   { id: 'cargo', label: 'Cargo', minWidth: 100 },
   { id: 'email', label: 'Email', minWidth: 100 },
];

interface Data {
   numero: number;
   nombre: string;
   cargo: string;
   email: string;
}

function createDataContacto(
   numero: number,
   nombre: string,
   cargo: string,
   email: string,
): Data {
   return { numero, nombre, cargo, email };
}

const rowsListado = [

]

export const DataModalEnterprise: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [ejecutivoComercial, setEjecutivoComercial] = useState('');
   const [estado, setEstado] = useState('');
   const inputRefs = useRef<Array<InputRef | null>>([]);


   const handleChangeEjecutivoComercial = (event: SelectChangeEvent) => {
      setEjecutivoComercial(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const estadoValue = [
      { label: 'ACTIVADO', value: 'activado' },
      { label: 'DESACTIVADO', value: 'desactivado' }
   ]
   const ejecutivoValue = [
      { label: 'ACTIVADO', value: 'activado' },
      { label: 'DESACTIVADO', value: 'desactivado' }
   ]

   return (
      <Container maxWidth='xl'>
         <Grid container spacing={3} mt={2} >
            <Grid container mb={2}>
               <Grid item xs={10}>
                  <Typography variant="h6" id="modal-modal-title" className='tittle'>
                     Contactos Empresa
                  </Typography>
               </Grid>
               <Grid item xs={2} textAlign="end">
                  <AssignmentIcon style={{ color: '#FF9700' }} />
               </Grid>
            </Grid>
            <Grid container spacing={3} className={`e-mb-lg`}>

               <Grid item xs={12} sm={4}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <NumbersIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="resumen"
                     type='text'
                     placeholder="Nombre Contacto"
                     // onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     // rules={rules.resumen}
                     disableElevation
                     validateOnBlur
                     dense
                     // disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={4}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <NextWeekIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="resumen"
                     type='text'
                     placeholder="Cargo"
                     // onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     // rules={rules.resumen}
                     disableElevation
                     validateOnBlur
                     dense
                     // disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={4}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={ejecutivoValue}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Ejecutivo Comercial" />}
                  />
               </Grid>
               <Grid item xs={12} sm={4}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={estadoValue}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Estado" />}
                  />
               </Grid>

               <Grid item xs={12} sm={4}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <AlternateEmailIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="resumen"
                     type='text'
                     placeholder="Email"
                     // onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     // rules={rules.resumen}
                     disableElevation
                     validateOnBlur
                     dense
                     // disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={4}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <PhoneIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="resumen"
                     type='text'
                     placeholder="Teléfono Fijo"
                     // onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     // rules={rules.resumen}
                     disableElevation
                     validateOnBlur
                     dense
                     // disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={4}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <PhoneAndroidIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="resumen"
                     type='text'
                     placeholder="Celular"
                     // onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     // rules={rules.resumen}
                     disableElevation
                     validateOnBlur
                     dense
                     // disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>

            </Grid>
            <Grid container mt={2}>
               <Grid item xs={12} textAlign="center">
                  <button className='btn_add'>
                     Agregar
                  </button>
               </Grid>

            </Grid>
            <Grid xs={12}>
               <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={2}>
                  LISTADO DE REGISTRO INGRESADO
               </Typography>
            </Grid>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
               <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           <TableCell>N</TableCell>
                           <TableCell>Nombre</TableCell>
                           <TableCell>Cargo</TableCell>
                           <TableCell>Email</TableCell>
                           <TableCell>Estado</TableCell>
                           <TableCell>Cobrar</TableCell>
                           <TableCell>Opciones</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsListado.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.numero}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                    <IconButton
                                    >
                                       <CheckCircleIcon />
                                    </IconButton>
                                 </TableCell>
                                 <TableCell>
                                    <IconButton
                                    >
                                       <EditIcon />
                                    </IconButton>
                                 </TableCell>
                              </TableRow>
                           );
                        })}

                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsListado.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>
         </Grid>




      </Container >
   );
};

