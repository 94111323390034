import React, { useState, useEffect } from 'react';
import { FunctionComponent } from "react";
import { Protected } from "@/components/layout/Protected";
import "./RouteCheets.sass";
import "./RouteCheets-styles.css"
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { LoadingButton } from '@mui/lab';
import { FormControl } from '@mui/material';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SearchIcon from '@mui/icons-material/Search';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Stack from '@mui/material/Stack';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {  DataRut } from './Modal/DataRut';
import TablePagination from '@mui/material/TablePagination';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

interface Column {
   id: 'ejecutivo' | 'razonsocial' | 'fecha'|'asunto' | 'contacto'|'email' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

 const columns:readonly Column[] = [
   { id: 'ejecutivo', label: 'Ejecutivo', minWidth: 50 },
   { id: 'razonsocial', label: 'Razon Social', minWidth: 50 },
   { id: 'fecha', label: 'Fecha', minWidth: 50 },
   { id: 'asunto', label: 'Asunto', minWidth: 50 },
   { id: 'contacto', label: 'Contacto', minWidth: 50 },
   { id: 'email', label: 'Email', minWidth: 50 },
   
   ];

   interface Data {
      ejecutivo: string;
      razonsocial: string;
      fecha: string;
      asunto: string;
      contacto: string;
      email: string;
      
   }

   function createData(
      ejecutivo: string,
      razonsocial: string,
      fecha: string,
      asunto: string,
      contacto: string,
      email: string,
      
      ):Data {
      return { ejecutivo, razonsocial, fecha, asunto,contacto , email};
   }

   const rowsData = [
      createData('DEMO ERP', 'Cotizacion', '29-04-2022', 'Venta', 'Ramon', 'Ramon@gmail.com'),
      createData('DEMO ERP', 'Cotizacion', '29-04-2022', 'Despacho', 'Felipe', 'Felipe@gmail.com'),
      createData('DEMO ERP', 'Cotizacion', '29-04-2022', 'Venta', 'Andres', 'Andres@gmail.com'),
      createData('DEMO ERP', 'Cotizacion', '29-04-2022', 'Venta', 'Mario', 'Mario@gmail.com'),
      createData('DEMO ERP', 'Cotizacion', '29-04-2022', 'Despacho', 'Carlos', 'Carlos@gmail.com'),
      createData('DEMO ERP', 'Cotizacion', '29-04-2022', 'Despacho', 'Sales', 'Sales@gmail.com'),
   ]

export const RouteCheets: FunctionComponent = (props: any) => {
   const [value, setValue] = useState<Date | null>(new Date());
   const [selectTypeResume, setSelectTypeResume] = useState('');
   const [data, setData] = React.useState<Date | null>(new Date());
   const [show, setShow] = useState(false);
   const [valueCalendar, setValueCalendar] = React.useState<Date | null>(new Date('2018-01-01T00:00:00.000Z'),);
   const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

   const handleChangeShow = () => {
      setShow(!show);
   }

   //modal Rit
   const [openRut, setOpenRut] = React.useState(false);
   const handleOpenRut = () => setOpenRut(true);
   const handleCloseRut = () => setOpenRut(false);

     //modal articulos
     const [openArticulo, setOpenArticulo] = useState(false);
     const handleOpenArticulo = () => setOpenArticulo(true);
     const handleCloseArticulo = () => setOpenArticulo(false);
  
     //modal Informe
     const [openInforme, setOpenInforme] = React.useState(false);
     const handleOpenInforme = () => setOpenInforme(true);
     const handleCloseInforme = () => setOpenInforme(false);
  
     const [cambiarEstado, setCambiarEstado] = useState(false);
     const handleCambiarEstado = () => setCambiarEstado(true);
  
     const [openAgregar, setOpenAgregar] = useState(false);
     const handleOpenAgregar = () => setOpenAgregar(true);
     const handleCloseAgregar = () => setOpenAgregar(false);
  
     const [openEditar, setOpenEditar] = useState(false);
     const handleOpenEditar = () => setOpenEditar(true);
     const handleCloseEditar = () => setOpenEditar(false);
  
     const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 870,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        borderRadius: '20px'
     };
  
     const handleChange = (event: any) => {
        setValue(event.target.value);
     };
     const handleChangeTypeResume = (event: SelectChangeEvent) => {
        setSelectTypeResume(event.target.value);
     };
  
     const [page, setPage] = React.useState(0);
     const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
     const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
     };
  
     const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
     };
     const monedas=[
        {label:'Dolar',value:'Dolar'},
        {label:'Peso',value:'Peso'},
        {label:'Euro',value:'Euro'},
        {label:'Libra',value:'Libra'},
     ]

   return (
      <Protected>
         <div className='Generator'>
        <Grid container spacing={2}>
         <Grid item xs={10}  >
            <Typography className="tittle" variant="h6" gutterBottom>
               Hoja de ruta Diaria Equipo de Venta
            </Typography>

            <Typography variant="subtitle2" gutterBottom>
               Incorpóre la información diariamente
            </Typography>
         </Grid>

         <Grid item xs={2} >
               <LoadingButton
                     color="warning"
                     
                     // onClick={handleClick}
                     // loading={loading}
                     // startIcon={<FileDownloadIcon />}
                     variant="contained"
                     className="btn-download2"
                     >
                     Exportar en Excel
               </LoadingButton>
         </Grid>
         
         <Grid item xs={12}>
               <Grid container spacing={2}>
                     <Grid item xs={3}>
                           <FormControl fullWidth sx={{ minWidth: 200 }}>
                              <TextField
                                 id="input-with-sx"
                                 // label="Folio Doc"
                                 placeholder="Cliente..."
                                 size="small"
                              />
                           </FormControl>
                     </Grid>
                     <Grid item xs={1}>
                           <Tooltip title="Buscar Cliente">
                              <IconButton onClick={handleOpenRut}  className='btnIcoE'>
                                 <SearchIcon />
                              </IconButton>
                           </Tooltip>

                        </Grid>
                     <Grid item xs={4}>
                     <FormControl fullWidth sx={{ minWidth: 200 }}>
                              <TextField
                                 id="input-with-sx"
                                 // label="Folio Doc"
                                 onChange={handleChange}
                                 placeholder="Nombre de Contacto"
                                 size="small"
                              />
                           </FormControl>
                     </Grid>
                     <Grid item xs={2}>
                           <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Stack spacing={3}>
                                 <DatePicker
                                 // views={['year', 'month']}
                                 label="Fecha"
                                 // minDate={new Date('2012-03-01')}
                                 // maxDate={new Date('2023-06-01')}
                                 
                                 value={value}
                                 onChange={(newValue) => {
                                    setValue(newValue);
                                 }}
                                 renderInput={(params) => <TextField  {...params} helperText={null} />}
                                 />
                              </Stack>
                           </LocalizationProvider>
                     </Grid>
                     <Grid item xs={2}>
                           <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Stack spacing={3}>
                                 <DatePicker
                                 // views={['year', 'month']}
                                 label="Fecha Siguiente"
                                 // minDate={new Date('2012-03-01')}
                                 // maxDate={new Date('2023-06-01')}
                                 value={value}
                                 onChange={(newValue) => {
                                    setValue(newValue);
                                 }}
                                 renderInput={(params) => <TextField  {...params} helperText={null} />}
                                 />
                              </Stack>
                           </LocalizationProvider>
                     </Grid>
               </Grid>
         </Grid>

         <Grid item xs={12}>
               <Grid container spacing={2}>
                        <Grid item xs={4}>
                              <TextField
                                 id="input-with-sx"
                                 label="Email"
                                 defaultValue="Ingresa Email"
                                 // onChange={handleChange}
                                 // placeholder="Cargando..."
                                 size="small"
                                 fullWidth
                              />
                           </Grid>
                           <Grid item xs={4}>
                                 <FormControl fullWidth sx={{ minWidth: 200 }}>
                                       <TextField
                                          id="input-with-sx"
                                          // label="Folio Doc"
                                          onChange={handleChange}
                                          placeholder="Observacion"
                                          size="small"
                                       />
                                 </FormControl>
                           </Grid>

                     <Grid item xs={4}>
                     <Grid container spacing={6} >
                           <Grid item xs={3}>
                                 <FormControlLabel
                                       value="bottom"
                                       control={<Checkbox defaultChecked/>}
                                       label="Venta"
                                       labelPlacement="bottom"
                                 />
                           </Grid>

                           <Grid item xs={3}>
                                 <FormControlLabel
                                       value="bottom"
                                       control={<Checkbox/>}
                                       label="Despacho"
                                       labelPlacement="bottom"
                                 />
                              
                           </Grid>

                           <Grid item xs={3}>
                                 <FormControlLabel
                                       value="bottom"
                                       control={<Checkbox/>}
                                       label="Cobranza"
                                       labelPlacement="bottom"
                                 />
                           </Grid>

                           <Grid item xs={3}>
                                 <FormControlLabel
                                       value="bottom"
                                       control={<Checkbox/>}
                                       label="Otros"
                                       labelPlacement="bottom"
                                 />
                           </Grid>
                        </Grid>
                     </Grid>
                  </Grid>
            </Grid>

         <Grid item container
                     xs={12}
                     justifyContent='left'
                  >
                     <Grid item>
                        <Button
                           type='submit'
                           variant='contained'
                           onClick={handleChangeShow}
                           // disabled={loading}
                           disableElevation
                           className='btnSend'
                        >
                           Agregar
                        </Button>
                     </Grid>
         </Grid>
{/* SEGUNDA TABLA */}
         {show &&
         <Grid item xs={12} sm={12} mt={1}>
                        <div className='bg-tittle3'>
                           <Typography
                              mt={1}
                              variant="h6"
                              align='center'
                              className='tittle-text'
                           >	
                           LISTADO CLIENTES Y PROVEEDORES INGRESADOS
                           </Typography>
                        </div>
         <Paper sx={{ width: '100%', overflow: 'hidden'}} >
               
               <TableContainer sx={{maxHeight:440}}>
                  <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                           <TableRow>
                           {columns.map((column) => (
                              <TableCell
                              
                                 key={column.id}
                                 align={column.align}
                                 style={{ minWidth: column.minWidth }}
                              >
                                 {column.label}
                              </TableCell>
       
                           ))}
                                 <TableCell align='center'>
                                    Opcion
                                 </TableCell>
                                             
                           </TableRow>
                        </TableHead>
                     <TableBody>
                        {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                                 <TableRow hover role="checkbox" tabIndex={-1} key={row.fecha}>
                                    {columns.map((column) => {
                                       const value = row[column.id];
                                       return (
                                          <TableCell key={column.id} align={column.align}>
                                             {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                          </TableCell>
                                          );                
                                       })}
                                       
                                       <TableCell>
                                             <div className='sepaEle'>
                                                   <button className='btnIcoE'><EditIcon /></button>
                                             </div>
                                       </TableCell>
                                 </TableRow>
                                 );
                              })}
                              
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
                           <TablePagination
                                 rowsPerPageOptions={[10, 25, 100]}
                                 component="div"
                                 count={rowsData.length}
                                 rowsPerPage={rowsPerPage}
                                 page={page}
                                 onPageChange={handleChangePage}
                                 onRowsPerPageChange={handleChangeRowsPerPage}
                           />
            </Paper> 
        </Grid>}
      </Grid>
    
               <Modal
                  open={openRut}
                  onClose={handleCloseRut}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <DataRut entity={undefined}/>
                  </Box>
               </Modal>
              {/* <Modal
                  open={openInforme}
                  onClose={handleCloseInforme}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <DataInforme />
                  </Box>
               </Modal> */}
         </div>
      </Protected >
   )
}

