import React, { MouseEvent, useCallback, useEffect, useRef, useState } from "react";
// import { Protected } from "@/components/layout/Protected";
import { Button, Grid, IconButton, InputBase, Modal, Paper, Typography } from "@mui/material";
import { Props } from './Provider.type';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SearchIcon from '@mui/icons-material/Search';
import CarouselProvider from "./CarouselProvider";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { Link } from 'react-router-dom';
import { ROUTE_RAPPI_CATEGORY } from "@/toolbox/constants/route-map";

export const ProviderNew: React.FC<Props> = (props: Props): JSX.Element => {

   const [open, setOpen] = useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);

   return (
      // <Protected>
      <>
         <Grid container sx={{ marginBottom: '25px', alignContent: 'center', marginTop: '20px', overflow: 'hidden' }}>
            <Grid xs={12} container item justifyContent={'space-between'} mb={3}>
               <Grid item xs={3} textAlign="left" >
                  <Button variant='contained' className='btn_back' component={Link} to={ROUTE_RAPPI_CATEGORY}>
                     <ArrowBackIosIcon />
                     <Typography variant='subtitle2'>Regresar</Typography>
                  </Button>
               </Grid>
               <Grid item xs={6} display="flex" justifyContent="center">
                  <Paper
                     component="form"
                     sx={{ p: '0px 4px', display: 'flex', alignItems: 'center', width: 600, border: '1.7px solid #FFA200', boxShadow: 'none' }}
                  >
                     <InputBase

                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Buscar Proveedores"
                        inputProps={{ 'aria-label': 'search google maps' }}
                     />
                     <IconButton type="submit" sx={{ p: '10px' }} aria-label="search" style={{ backgroundColor: '#fff', color: '#3f3f3f' }}>
                        <SearchIcon />
                     </IconButton>
                  </Paper>
               </Grid>
               <Grid item container xs={3} justifyContent="right" alignItems='center'>
                  <Grid item mr={1}>
                     <Button variant='contained' color='error' >
                        <Typography variant='subtitle2'>Otra opción</Typography>
                     </Button>
                  </Grid>
                  {'|'}
                  <Grid item ml={1}>
                     <Button variant='contained' color='success' onClick={() => handleOpen()} sx={{ marginRight: '15px' }}>
                        <ShoppingCartIcon /> <Typography variant='subtitle2'>{'0'}</Typography>
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
         </Grid>
         <CarouselProvider></CarouselProvider>
         <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            className='modalCard'
         >
            <Grid container p={2} className="cardSell">
               <Grid xs={12} container className="text" item justifyContent={'space-between'} alignItems='left'>
                  <Typography className="textMain" text-align="start" >Tu canasta</Typography>
                  <CloseIcon onClick={() => handleClose()} style={{ cursor: 'pointer' }} />
               </Grid>

               <Grid xs={12} item justifyContent={'space-between'} alignItems='left'>
                  <Button startIcon={<LocationOnIcon sx={{ color: '#ff4d00' }} className="ico" />}>
                     <Typography sx={{ color: '#ff4d00 ' }} className="ubication">Ingresar mi ubicacion</Typography>
                  </Button>
               </Grid>

               <Grid item xs={12} className="icoCard" alignContent='center' >
                  <IconButton  >
                     <AddShoppingCartIcon sx={{ fontSize: '50px' }} />
                  </IconButton>
               </Grid >

               <Grid xs={12} item justifyContent={'space-between'} alignItems='center' >
                  <Typography className='textbody' sx={{ color: '#3f3f3f ' }} >Aun no tienes productos en tu canasta</Typography>
               </Grid>

               <Grid xs={12} item justifyContent={'space-between'} alignItems='center'  >
                  <Button className='btnCard' >
                     Comenzar a comprar
                  </Button>
               </Grid>

            </Grid>
         </Modal>
         {/* </Protected> */}
      </>
   )
}
