import React, { FunctionComponent, useState } from "react"
import { Alert, Button, FormControl, Grid, MenuItem, Snackbar, TableContainer, Typography, TableBody, Table, Tooltip, } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InputLabel from '@mui/material/InputLabel';
import EditIcon from '@mui/icons-material/Edit';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Protected } from "@/components/layout/Protected";

export const DecisionState: FunctionComponent = (props: any) => {

   const [data, setData] = useState('');
   const [dataIndicator, setDataIndicator] = useState('');
   const [open, setOpen] = useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);
   const [select, setSelect] = useState('');

   const handleChange = (event: any) => {
      setData(event.target.value as string);
   };
   const handleChangeIndicator = (event: any) => {
      setDataIndicator(event.target.value as string);
   };


   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 450,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };
   return (
      <Protected>
         <div className='spacing' style={{height:'100vh'}}>
            <Grid container>
               <Grid item xs={12} className='end'>
                  <Grid container>
                     <Grid item xs={10} sm={4}>
                        <Typography
                           mt={1}
                           variant="h3"
                           className='tittle'
                           sx={{ textTransform: 'uppercase' }}
                        >Indicadores de Decision</Typography>
                     </Grid>

                     <Grid item xs={2} sm={1} className=''>
                        <Button className='btnData' variant="contained" onClick={handleOpen}>
                           {<AddIcon />}
                        </Button>
                     </Grid>
                  </Grid>
               </Grid>

               <Grid container mt={2}>
                  <Grid item xs={12}>
                     <Box mt={1} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <FormControl fullWidth >
                           <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                              <TableContainer sx={{ maxHeight: 540 }}>
                                 <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                       <TableRow>
                                          <TableCell align="center">Num.</TableCell>
                                          <TableCell align="center">Nombre Indicador</TableCell>

                                          <TableCell align="center">Porcentaje</TableCell>
                                          <TableCell align="center">Estado</TableCell>
                                          <TableCell align="center">Opciones</TableCell>
                                       </TableRow>
                                    </TableHead>

                                    <TableBody>
                                       <TableRow >
                                          <TableCell align="center">
                                             534
                                          </TableCell>

                                          <TableCell align="center"> 
                                             Facilidad de Pago
                                          </TableCell>

                                          <TableCell align="center">
                                             30
                                          </TableCell>
                                          <TableCell align="center">

                                             <FormControlLabel control={<Checkbox defaultChecked />} label="" />

                                          </TableCell>

                                          <TableCell align="left">
                                             <div className='sepaEle'>
                                                <Tooltip title="Editar">
                                                <button className='btnIcoD'><EditIcon /></button>   
                                                </Tooltip>
                                                                                             
                                             </div>
                                          </TableCell>
                                       </TableRow>

                                    </TableBody>
                                 </Table>
                              </TableContainer>

                           </Paper>
                        </FormControl>
                     </Box>
                  </Grid>
               </Grid>
            </Grid>
         </div>
         <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <Grid container>
                  <Grid item xs={10}>
                     <Typography variant="h6" id="modal-modal-title" mb={2} className='tittle'>
                        Mantenedor Indicadores de Decision
                     </Typography>
                  </Grid>
                  <Grid item xs={2} textAlign="end">
                     <AssignmentIcon style={{ color: '#FF9700' }} />
                  </Grid>
               </Grid>
               <Grid container xs={12} sx={{ justifyContent: 'space-between' }}>
                  <Grid item xs={12} mt={2}>
                     <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-helper-label">Seleccione Indicador</InputLabel>
                        <Select
                           labelId="demo-simple-select-helper-label"
                           id="demo-simple-select-helper"
                           value={dataIndicator}
                           label="Seleccione Indicador"
                           onChange={handleChangeIndicator}
                        >
                           <MenuItem value={1}>Calidad</MenuItem>
                           <MenuItem value={2}>Cantidad</MenuItem>
                           <MenuItem value={3}>Facilidad de Pago</MenuItem>
                           <MenuItem value={4}>Plazo de Entrega</MenuItem>
                           <MenuItem value={5}>Precio</MenuItem>
                        </Select>
                     </FormControl>
                  </Grid>
                  <Grid item xs={4} mt={2}>
                     <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <FormControl fullWidth >
                           <TextField
                              id="input-with-sx"
                              label="Porcentaje"
                              placeholder="(%)"
                              rows={2}
                           />
                        </FormControl>
                     </Box>
                  </Grid>
                  <Grid item xs={7} mt={2}>
                     <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-helper-label">Seleccione Estado</InputLabel>
                        <Select
                           labelId="demo-simple-select-helper-label"
                           id="demo-simple-select-helper"
                           value={data}
                           label="Seleccione Estado"
                           onChange={handleChange}
                        >
                           <MenuItem value={1}>Activo</MenuItem>
                           <MenuItem value={2}>Desactivado</MenuItem>
                        </Select>
                     </FormControl>
                  </Grid>
               </Grid>
               <Grid container mt={2}>
                  <Grid item xs={6} textAlign="center">
                     <Button variant="contained" className='btnColorCanceled' onClick={handleClose}>
                        Cancelar
                     </Button>
                  </Grid>
                  <Grid item xs={6} textAlign="center">
                     <Button variant="contained" className='btnColorAccepeted' onClick={handleClose}>
                        Guardar
                     </Button>
                  </Grid>
               </Grid>
            </Box>

         </Modal>

      </Protected>
   )
}