import React, { useState, useEffect } from 'react';
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Protected } from "@/components/layout/Protected";
import { FormControl, Grid, Stack, Typography, Button, Input, Tooltip, Autocomplete, IconButton, InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import {ROUTE_F_DE_PURCHASE_ORDER,ROUTE_F_DE_CONFIRM_EMISION } from "@/toolbox/constants/route-map";
import "./PurchaseOrder.sass";
// import "./Home-styles.css";
import "./PurchaseOrder-styles.css"
import { TableData } from "@/components/common/Table/Table";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';
import SearchIcon from '@mui/icons-material/Search';
import { DateTimePicker } from '@mui/lab';
import {  DataRut } from './Modal/DataRut';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { getDay } from 'date-fns';
import { DataProductos } from './Modal/DataProductos';
import { DataInforme } from './Modal/DataInforme';



interface Column1 {
   id: 'codigo' | 'detalle' | 'cantidad' | 'valor' | 'tipo' | 'valorDescuento' | 'total';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}
const columns1: readonly Column1[] = [
   { id: 'codigo', label: 'Código', minWidth: 10 },
   { id: 'detalle', label: 'Detalle', minWidth: 50 },
   { id: 'cantidad', label: 'Cantidad', minWidth: 5 },
   { id: 'valor', label: 'Valor Descuento', minWidth: 10 },
   { id: 'tipo', label: 'Tipo', minWidth: 10 },
   { id: 'valorDescuento', label: 'Valor Descuento', minWidth: 10 },
   { id: 'total', label: 'Total', minWidth: 10 },
];
interface Data {
   numero: number;
   codigo: string;
   detalle: string;
   cantidad: number;
   valor: number;
   tipo: string;
   valorDescuento: number;
   total: number;
   unidad: string;
   descuento: number;
   subtotal: number;
}

function createData(
   numero: number,
   codigo: string,
   detalle: string,
   cantidad: number,
   valor: number,
   tipo: string,
   valorDescuento: number,
   total: number,
): Data {
   return { numero, codigo, detalle, cantidad, valor, tipo, valorDescuento, total, unidad: '', descuento: 0, subtotal: 0 };
}

const rowsData = [
   createData(1, '123', 'Prueba 1', 10, 5, 'C/U Serv', 0, 1000),
   createData(2, '123', 'Prueba 2', 10, 5, 'C/U Serv', 0, 1000),
   createData(3, '123', 'Prueba 3', 10, 5, 'C/U Serv', 0, 1000),
   createData(4, '123', 'Prueba 4', 10, 5, 'C/U Serv', 0, 1000),
]

export const PurchaseOrder: FunctionComponent = (props: any) => {
   const [value, setValue] = useState<Date | null>(new Date());
   const [selectTypeResume, setSelectTypeResume] = useState('');
   const [data, setData] = React.useState<Date | null>(new Date());
   const [valueCalendar, setValueCalendar] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

   //modal Rit
   const [openRut, setOpenRut] = React.useState(false);
   const handleOpenRut = () => setOpenRut(true);
   const handleCloseRut = () => setOpenRut(false);

   //modal articulos
   const [openArticulo, setOpenArticulo] = useState(false);
   const handleOpenArticulo= () => { 
      setOpenArticulo(true);
   }
   const handleCloseArticulo=()=> setOpenArticulo(false);

   //modal  listado productos

   const [openListado, setOpenListado] = useState(false);
   const handleOpenListado = () => setOpenListado(true);
   const handleCloseListado= () => setOpenListado(false);


   //modal Informe
   const [openInforme, setOpenInforme] = React.useState(false);
   const handleOpenInforme = () => setOpenInforme(true);
   const handleCloseInforme = () => setOpenInforme(false);

   const [cambiarEstado, setCambiarEstado] = useState(false);
   const handleCambiarEstado = () => setCambiarEstado(true);

   const [openAgregar, setOpenAgregar] = useState(false);
   const handleOpenAgregar = () => setOpenAgregar(true);
   const handleCloseAgregar = () => setOpenAgregar(false);

   const [openEditar, setOpenEditar] = useState(false);
   const handleOpenEditar = () => setOpenEditar(true);
   const handleCloseEditar = () => setOpenEditar(false);

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 870,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 4,
      borderRadius: '20px'
   };

   const handleChange = (event: any) => {
      setValue(event.target.value);
   };
   const handleChangeTypeResume = (event: SelectChangeEvent) => {
      setSelectTypeResume(event.target.value);
   };

   const [page, setPage] = React.useState(0);
   const [rowsPerPage, setRowsPerPage] = React.useState(10);

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };
   const monedas=[
      {label:'Dolar',value:'Dolar'},
      {label:'Peso',value:'Peso'},
      {label:'Euro',value:'Euro'},
      {label:'Libra',value:'Libra'},
   ]
   const condicionPago = [
      { label: 'EFECTIVO' },
      { label: '15 DÍAS' },
      { label: '30 DÍAS STE' },
      { label: 'OTROS' }
   ]
   const areaNegocio = [
      { label: 'Categorizada' },
      { label: 'DESARROLLO' },
      { label: 'INSUMOS' },
      { label: 'OTROS' }
   ]
   const contactoValue = [
      { label: 'Santiago Ormeño' },
      { label: 'Gianluca Quispedula' },
      { label: 'Francisco Mamani' },
      { label: 'Pedrito Alcachofas' },
      { label: 'La tía Mey' }
   ]
   return (
      <Protected>
         <div style={{marginLeft:'5px', marginRight:'5px'}}>
            <Grid container spacing={2} >
               
                  <Grid item xs={12} md={12} >
                     <Box>
                        <Typography variant='h6' component='h1' sx={{ textTransform: 'uppercase' }} gutterBottom className="tittle">
                           ORDEN DE COMPRA
                        </Typography>
                     </Box>
                     <Box>
                        <Typography variant='subtitle2' gutterBottom >
                           Seleccione proveedor
                        </Typography>
                     </Box>

                  </Grid>

                  <Grid item xs={12} md={4} >

                     <Grid container >

                        <Grid item xs={10}>
                        <TextField fullWidth
                           label="Rut Proveedor"
                           id="outlined-size-small"
                           placeholder='76333222-1' 
                           size="small"  />
                        
                        </Grid>

                        <Grid item xs={1}>
                           <Tooltip title="Buscar Cliente">
                              <IconButton onClick={handleOpenRut} className='btnIcoE'>
                                 <SearchIcon />
                              </IconButton>
                           </Tooltip>

                        </Grid>

                     </Grid>
                  </Grid>


                  <Grid item container
                        xs={12}
                        justifyContent='left'
                     >
                           <Button
                           type='submit'
                           variant='contained'
                           onClick={handleCambiarEstado}
                           // disabled={loading}
                           disableElevation
                           className='btn_save'
                        >
                           Seleccionar
                        </Button>

                  </Grid>
            </Grid>
               {cambiarEstado &&
                                    <Grid className='Generator' container mt={5} spacing={2}>

                                    <Grid item xs={12} mt={1}>
                                       <Grid>
                                          <Grid item xs={12} sm={12} mt={1} >
                                             <Box>
                                                <Typography variant='h6' component='h1' gutterBottom className="tittle">
                                                   Emitiendo Ordenes de Compra
                                                </Typography>
                                             </Box>
                                             <div className='bg-tittle3'>
                                                <Typography
                                                   mt={1}
                                                   variant="h6"
                                                   align='center'
                                                   className='tittle-text'
                                                   >Órdenes de Compra
                                                </Typography>
                                             </div>
               
                                          </Grid>
                                          <Grid item xs={12} md={3}>
                                             <Tooltip title="Histórico Ventas">
                                                <IconButton>
                                                   <ContentPasteSearchIcon onClick={handleOpenInforme} />
                                                </IconButton>
                                             </Tooltip>
                                             <Tooltip title="Descargar">
                                                <IconButton>
                                                   <FileDownloadIcon />
                                                </IconButton>
                                             </Tooltip>
                                          </Grid>
                                       </Grid>
               
                                       <Grid container spacing={2} mt={1} className='margin'>
                                       <Grid item xs={12} sm={3}>
                                             <FormControl fullWidth sx={{ minWidth: 200 }}>
                                             <TextField
                                                   id="input-with-sx"
                                                   label="Rut Empresa"
                                                   onChange={handleChange}
                                                   // placeholder="Cargando..."
                                                   size="small"
                                                />
                                             </FormControl>
                                          </Grid>
                                          <Grid item xs={12} sm={3}>
                                             <FormControl fullWidth sx={{ minWidth: 200 }}>
                                                <TextField
                                                   id="input-with-sx"
                                                   label="Razón Social"
                                                   onChange={handleChange}
                                                   // placeholder="Cargando..."
                                                   size="small"
                                                />
                                             </FormControl>
                                          </Grid>
               
                                          <Grid item xs={12} sm={3}>
                                             <FormControl fullWidth sx={{ minWidth: 200 }}>
                                                <TextField
                                                   id="input-with-sx"
                                                   label="Giro"
                                                   onChange={handleChange}
                                                   placeholder="Cargando..."
                                                   //value="Cargando..."
                                                   size="small"
                                                />
                                             </FormControl>
                                          </Grid>
                                          
                                          <Grid item xs={12} container sm={3} sx={{ alignItems: 'center' }} >
                                             <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <Stack spacing={4} >
                                                   <DatePicker 
                                                      views={['year', 'month', 'day']}
                                                      label="Periodo y Mes"
                                                      inputFormat="dd/MM/yyyy"
                                                      minDate={new Date('2012-03-01')}
                                                      maxDate={new Date('2023-06-01')}
                                                      value={valueCalendar}
               
                                                      onChange={(newValue) => {
                                                         setValueCalendar(newValue);
                                                      }}
                                                      renderInput={(params) => <TextField {...params} helperText={null} />}
                                                   />
                                                </Stack>
                                             </LocalizationProvider>
                                          </Grid>
                                          <Grid item xs={12} sm={3}>
                                             <FormControl fullWidth sx={{ minWidth: 200 }}>
                                                <TextField
                                                   id="input-with-sx"
                                                   label="Email"
                                                   size="small"
                                                   onChange={handleChange}
                                                   placeholder="Cargando..."
                                                />
                                             </FormControl>
                                          </Grid>
               
                                          <Grid item xs={12} sm={3}>
                                             <FormControl fullWidth sx={{ minWidth: 200 }}>
                                                <Autocomplete
                                                   disablePortal
                                                   size='small'
                                                   // id="combo-box-demo"
                                                   options={contactoValue}
                                                   renderInput={(params) => <TextField {...params} label="Contacto" />}
                                                />
                                             </FormControl>
                                          </Grid>
                                          
                                          <Grid item xs={12} sm={3}>
                                             <FormControl fullWidth sx={{ minWidth: 200 }}>
                                                <TextField
                                                   id="input-with-sx"
                                                   label="Validez"
                                                   onChange={handleChange}
                                                   // value="Cargando..."
                                                   size="small"
                                                />
                                             </FormControl>
                                          </Grid>
                                          <Grid item xs={12} sm={3}>
                                             <FormControl fullWidth sx={{ minWidth: 200 }}>
                                                <TextField
                                                   id="input-with-sx"
                                                   label="Dirección"
                                                   onChange={handleChange}
                                                   //value="Cargando..."
                                                   size="small"
                                                />
                                             </FormControl>
                                          </Grid>
               
                                          <Grid item xs={12} sm={3}>
                                             <FormControl fullWidth sx={{ minWidth: 200 }}>
                                                <TextField
                                                   id="input-with-sx"
                                                   label="Entrega"
                                                   onChange={handleChange}
                                                   // value="Cargando..."
                                                   size="small"
                                                />
                                             </FormControl>
                                          </Grid>
                                          <Grid item xs={12} sm={3}>
                                             <FormControl fullWidth sx={{ minWidth: 200 }}>
                                                <Autocomplete
                                                   disablePortal
                                                   size='small'
                                                   id="combo-box-demo"
                                                   options={condicionPago}
                                                   renderInput={(params) => <TextField {...params} label="Condición de Pago" />}
                                                />
                                             </FormControl>
                                          </Grid>
                                          <Grid item xs={12} sm={3}>
                                             <FormControl fullWidth sx={{ minWidth: 200 }}>
                                             <Autocomplete
                                                   disablePortal
                                                   size='small'
                                                   // id="combo-box-demo"
                                                   options={contactoValue}
                                                   renderInput={(params) => <TextField {...params} label="Clasificación de Compra" />}
                                                />
                                             </FormControl>
                                          </Grid>
                                          <Grid item xs={12} sm={3}>
                                             <FormControl fullWidth sx={{ minWidth: 200 }}>
                                             <Autocomplete
                                                   disablePortal
                                                   size='small'
                                                   // id="combo-box-demo"
                                                   options={contactoValue}
                                                   renderInput={(params) => <TextField {...params} label="Centro de costo" />}
                                                />
                                             </FormControl>
                                          </Grid>
                                          <Grid item xs={12} sm={3}>
                                             <FormControl fullWidth sx={{ minWidth: 200 }}>
                                             <Autocomplete
                                                   disablePortal
                                                   size='small'
                                                   // id="combo-box-demo"
                                                   options={contactoValue}
                                                   renderInput={(params) => <TextField {...params} label="Solicitado Por" />}
                                                />
                                             </FormControl>
                                          </Grid>
                                          <Grid item xs={12} sm={3}>
                                             <FormControl fullWidth sx={{ minWidth: 200 }}>
                                                <TextField
                                                   id="input-with-sx"
                                                   label="Ejecutado por:"
                                                   onChange={handleChange}
                                                   size="small"
                                                // value="Cargando..."
                                                />
                                             </FormControl>
                                          </Grid>
                                          <Grid item xs={12} sm={3}>
                                             <FormControl fullWidth sx={{ minWidth: 200 }}>
                                                <TextField
                                                   id="input-with-sx"
                                                   label="N° Cotización Aut."
                                                   onChange={handleChange}
                                                   size="small"
                                                // placeholder="Cargando..."
                                                />
                                             </FormControl>
                                          </Grid>
                                          <Grid item xs={12} sm={3}>
                                             <FormControl fullWidth sx={{ minWidth: 200 }}>
                                                <TextField
                                                   id="input-with-sx"
                                                   label="Lugar de Entrega"
                                                   onChange={handleChange}
                                                   size="small"
                                                // placeholder="Cargando..."
                                                />
                                             </FormControl>
                                          </Grid>
               
                                       </Grid>
                                    </Grid>
               
                                    <Grid item xs={12} sm={12} mt={1}>
                                       <div className='bg-tittle3'>
                                          <Typography
                                             mt={1}
                                             variant="h6"
                                             align='center'
                                             className='tittle-text'
                                          >Insertar líneas de detalle
                                          </Typography>
                                       </div>
                                       <Grid item xs={12} >
                                          <Box mt={1} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                             <FormControl fullWidth >
                                                { /*<TextField
                                                   id="input-with-sx"
                                                   onChange={handleChange}
                                                   defaultValue="Buscar"
                                                   /> */}
                                                <Paper sx={{ width: '100%', overflow: 'hidden' }} >
               
                                                   <TableContainer sx={{ maxHeight: 440 }}>
                                                      <Table stickyHeader aria-label="sticky table">
                                                         <TableHead>
                                                            <TableRow>
                                                               {columns1.map((column) => (
                                                                  <TableCell
                                                                     key={column.id}
                                                                     align={column.align}
                                                                     style={{ minWidth: column.minWidth }}
                                                                  >
                                                                     {column.label}
                                                                  </TableCell>
                                                               ))}
                                                               <TableCell>Opciones</TableCell>
                                                            </TableRow>
                                                         </TableHead>
                                                         <TableBody>
                                                            <TableRow>
                                                               <TableCell>
                                                                  <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                                     <Tooltip title="Buscar Articulo">
                                                                     <IconButton
                                                                        onClick={handleOpenArticulo} className='btnBP'
                                                                     >
                                                                        <SearchIcon />
                                                                     </IconButton>
                                                                     </Tooltip>
                                                                     <TextField
                                                                        id="rut"
                                                                        // label="ORDEN DE COMPRA"
                                                                        type="text"
                                                                        fullWidth
                                                                        variant="outlined"
                                                                        size="small"
                                                                     // defaultValue="jmora@test.cl"
                                                                     />
                                                                  </div>
               
                                                               </TableCell>
                                                               <TableCell>
                                                                  <TextField
                                                                     id="rut"
                                                                     // label="ORDEN DE COMPRA"
                                                                     type="text"
                                                                     fullWidth
                                                                     variant="outlined"
                                                                     size="small"
                                                                  // defaultValue="jmora@test.cl"
                                                                  />
                                                               </TableCell>
                                                               <TableCell>
                                                                  <TextField
                                                                     id="rut"
                                                                     // label="ORDEN DE COMPRA"
                                                                     type="text"
                                                                     fullWidth
                                                                     variant="outlined"
                                                                     size="small"
                                                                  // defaultValue="jmora@test.cl"
                                                                  />
                                                               </TableCell>
                                                               <TableCell>
                                                                  <TextField
                                                                     id="rut"
                                                                     // label="ORDEN DE COMPRA"
                                                                     type="text"
                                                                     fullWidth
                                                                     variant="outlined"
                                                                     size="small"
                                                                  // defaultValue="jmora@test.cl"
                                                                  />
                                                               </TableCell>
                                                               <TableCell>
                                                                  <FormControl fullWidth>
                                                                     <InputLabel id="demo-simple-select-label">%</InputLabel>
                                                                     <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        // label="fdffd%"
                                                                        onChange={handleChange}
                                                                        size="small"
                                                                     >
                                                                        <MenuItem value={10}>%</MenuItem>
                                                                        <MenuItem value={20}>$</MenuItem>
               
                                                                     </Select>
                                                                  </FormControl>
                                                               </TableCell>
                                                               <TableCell>
                                                                  <TextField
                                                                     id="rut"
                                                                     // label="ORDEN DE COMPRA"
                                                                     type="text"
                                                                     fullWidth
                                                                     variant="outlined"
                                                                     size="small"
                                                                     defaultValue="0"
                                                                  />
                                                               </TableCell>
                                                               <TableCell>
                                                                  <TextField
                                                                     id="rut"
                                                                     // label="ORDEN DE COMPRA"
                                                                     type="text"
                                                                     fullWidth
                                                                     variant="outlined"
                                                                     size="small"
                                                                  // defaultValue="jmora@test.cl"
                                                                  />
                                                               </TableCell>
               
                                                               <TableCell>
                                                                  <Button
                                                                     type='submit'
                                                                     variant='contained'
               
                                                                     // onClick={handleSubmit}
                                                                     // disabled={loading}
                                                                     disableElevation
                                                                     // component={Link} to={ROUTE_EV_QUOTE_BOOK_RESUME}
                                                                     className='btn_opcion'
                                                                  >
                                                                     AGREGAR
                                                                  </Button>
                                                                  <Button
                                                                     type='submit'
                                                                     variant="outlined"
               
                                                                     // onClick={handleSubmit}
                                                                     // disabled={loading}
                                                                     disableElevation
                                                                     // component={Link} to={ROUTE_EV_QUOTE_BOOK_RESUME}
                                                                     className='btn_opcion1'
                                                                     style={{ marginLeft: 10 }}
                                                                  >
                                                                     LIMPIAR
                                                                  </Button>
                                                               </TableCell>
               
                                                            </TableRow>
                                                         </TableBody>
                                                      </Table>
                                                   </TableContainer>
                                                   <TablePagination
                                                      rowsPerPageOptions={[10, 25, 100]}
                                                      component="div"
                                                      count={rowsData.length}
                                                      rowsPerPage={rowsPerPage}
                                                      page={page}
                                                      onPageChange={handleChangePage}
                                                      onRowsPerPageChange={handleChangeRowsPerPage}
                                                   />
                                                </Paper>
                                             </FormControl>
                                          </Box>
                                       </Grid>
                                    </Grid>
                                    {/* SEGUNDA TABLA */}
                                    <Grid item xs={12} mt={2}>
                                       <Box mt={1} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                          <FormControl fullWidth >
                                             <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                                <TableContainer sx={{ maxHeight: 540 }}>
                                                   <Table stickyHeader aria-label="sticky table">
                                                      <TableHead>
                                                         <TableRow>
                                                            <TableCell>Detalle / Descripción</TableCell>
                                                            <TableCell>Cantidad</TableCell>
                                                            <TableCell>Valor Neto</TableCell>
                                                            <TableCell>Descuento</TableCell>
                                                            <TableCell>SubTotal</TableCell>
                                                            <TableCell>Selección</TableCell>
                                                            <TableCell>Exento</TableCell>
                                                            <TableCell align='center'>Opciones</TableCell>
                                                         </TableRow>
                                                      </TableHead>
               
                                                      <TableBody>
                                                         <TableRow >
                                                            <TableCell>
                                                               Lorem ipsum dolor sit
                                                            </TableCell>
               
                                                            <TableCell>
                                                               14
                                                            </TableCell>
               
                                                            <TableCell>
                                                               28
                                                            </TableCell>
               
                                                            <TableCell>
                                                               0
                                                            </TableCell>
               
                                                            <TableCell>
                                                               $600
                                                            </TableCell>
               
                                                            <TableCell>
                                                               <FormControlLabel control={<Checkbox defaultChecked />} label="" />
                                                            </TableCell>
               
                                                            <TableCell>
                                                               0
                                                            </TableCell>
               
                                                            <TableCell>
                                                               <div className='sepaEle'>
                                                                  <button className='btnIcoE'><EditIcon /></button>
                                                                  <button className='btnIcoD'><DeleteIcon /></button>
                                                               </div>
                                                            </TableCell>
                                                         </TableRow>
               
                                                      </TableBody>
                                                   </Table>
                                                </TableContainer>
                                             </Paper>
                                          </FormControl>
                                       </Box>
                                    </Grid>
                                    {/* RESUMEN DE DOCUMENTO */}
                                    <Grid item xs={12} mt={2}>
                                       <div className='bg-tittle2'>
                                          <Typography
                                             mt={2}
                                             variant="h6"
                                             align='center'
                                             className='tittle-text'
                                          >Resumen del Documento
                                          </Typography>
                                       </div>
                                       <Grid container spacing={2} mt={1} className='margin'>
                                          {/* Columna 1 */}
                                          <Grid item xs={12} sm={3}>
                                             <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                                <FormControl fullWidth >
                                                   <TextField
                                                      id="outlined-multiline-static"
                                                      label="Insertar Observación"
                                                      multiline
                                                      rows={5}
                                                      size="small"
                                                   />
                                                </FormControl>
                                             </Box>
                                          </Grid>
                                          {/* Columna 2 */}
                                          <Grid item xs={12} sm={1}>
                                             <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                                <FormControl fullWidth>
                                                   <InputLabel id="demo-simple-select-helper-label">Tipo</InputLabel>
                                                   <Select
                                                      labelId="demo-simple-select-helper-label"
                                                      id="demo-simple-select-helper"
                                                      value={selectTypeResume}
                                                      label="Tipo"
                                                      size="small"
                                                      onChange={handleChangeTypeResume}
                                                   >
                                                      <MenuItem value="Centro Costo">
                                                         <em>None</em>
                                                      </MenuItem>
                                                      <MenuItem value={1}>%</MenuItem>
                                                      <MenuItem value={2}>$</MenuItem>
                                                   </Select>
                                                </FormControl>
                                             </Box>
                                          </Grid>
                                          {/* Columna 3 */}
                                          <Grid item xs={12} sm={2}>
                                             <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                                <FormControl fullWidth >
                                                   <TextField
                                                      id="input-with-sx"
                                                      label="Descuento"
                                                      onChange={handleChange}
                                                      placeholder="Cargando..."
                                                      size="small"
                                                   />
                                                </FormControl>
                                             </Box>
                                          </Grid>
                                          {/* Columna 4 */}
                                          <Grid item xs={12} sm={3}>
                                             <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                                <FormControl fullWidth >
                                                   <TextField
                                                      id="input-with-sx"
                                                      label="Subtotal"
                                                      onChange={handleChange}
                                                      placeholder="Cargando..."
                                                      size="small"
                                                   />
                                                </FormControl>
                                             </Box>
                                             <br />
                                             <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                                <FormControl fullWidth >
                                                   <TextField
                                                      id="input-with-sx"
                                                      label="Descuento"
                                                      onChange={handleChange}
                                                      placeholder="Cargando..."
                                                      size="small"
                                                   />
                                                </FormControl>
                                             </Box>
                                          </Grid>
                                          {/* Columna 5 */}
                                          <Grid item xs={12} sm={3}>
                                             <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                                <FormControl fullWidth >
                                                   <TextField
                                                      id="input-with-sx"
                                                      label="Total Afecto"
                                                      onChange={handleChange}
                                                      placeholder="Cargando..."
                                                      size="small"
                                                   />
                                                </FormControl>
                                             </Box>
                                             <br />
                                             <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                                <FormControl fullWidth >
                                                   <TextField
                                                      id="input-with-sx"
                                                      label="19%"
                                                      onChange={handleChange}
                                                      placeholder="Cargando..."
                                                      size="small"
                                                   />
                                                </FormControl>
                                             </Box>
                                             <br />
                                             <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                                <FormControl fullWidth >
                                                   <TextField
                                                      id="input-with-sx"
                                                      label="Exento"
                                                      onChange={handleChange}
                                                      placeholder="Cargando..."
                                                      size="small"
                                                   />
                                                </FormControl>
                                             </Box>
                                             <br />
                                             <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                                <FormControl fullWidth >
                                                   <TextField
                                                      id="input-with-sx"
                                                      label="Total"
                                                      onChange={handleChange}
                                                      placeholder="Cargando..."
                                                      size="small"
                                                   />
                                                </FormControl>
                                             </Box>
               
                                          </Grid>
                                       </Grid>
               
                                       <Grid item>
                                 <Button
                                    type='submit'
                                    variant='contained'
                                    
                                    // onClick={handleSubmit}
                                    // disabled={loading}
                                    disableElevation
                                    component={Link} to={ROUTE_F_DE_CONFIRM_EMISION}
                                    className='btn_next'
                                 >
                                          <span>INGRESAR</span>
                                 </Button>
                              </Grid>
                                    </Grid>
                                 </Grid>
                                 

                     
               }
               
               <Modal
                  open={openRut}
                  onClose={handleCloseRut}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <DataRut entity={undefined}/>
                  </Box>
               </Modal>
               <Modal
                  open={openArticulo}
                  onClose={handleCloseArticulo}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <DataProductos entity={undefined} />
                  </Box>
               </Modal>
               <Modal
                  open={openInforme}
                  onClose={handleCloseInforme}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <DataInforme entity={undefined} />
                  </Box>
               </Modal>
 

               
         </div>
      </Protected >
   )
}

