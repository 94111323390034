import { http } from '../http/http';
import { LoginDTO, LogoutDTO, UserDTO } from '../http/dto/AuthenticationDTO';
import { Login, Logout, Authentication } from '../models/Authentication';
import { API_URL_BASE,APP_API_LOYVERSE_URL } from '@/toolbox/defaults/app';



export const authenticationRepository = {


   login: async (username: string, rut: string, password: string): Promise<any>=> {
      const resp = await http.post<any>(`${API_URL_BASE}/login`, {
         username: username,
         rut: rut,
         password: password
      })
      return resp;
      // {
      //    error: resp.error,
      //    status: resp.status,
      //    message: resp.message,
      //    token: resp.token
      // }
   },
   loginLoverse : async (username: string, rut: string, password: string): Promise<any>=> {
      const resp = await http.post<any>(`${APP_API_LOYVERSE_URL}/api/empresas/check?key=azureservicemap`, {
         usuario: username,
         rut: rut,
         password: password
      })
      return resp;
   },
   register: async (username: string, rut: string, password: string,key_loyverse: any): Promise<any>=> {
      const resp = await http.post<any>(`${APP_API_LOYVERSE_URL}/api/empresas/registrar?key=azureservicemap`, {
         usuario: username,
         rut: rut,
         password: password,
         key_loyverse: key_loyverse
      })
      return resp;
         // error: resp.error,
         // status: resp.status,
         // message: resp.message,
         // token: resp.token


   },
   logout: async (): Promise<Logout>=> {
      const resp = await http.post<LogoutDTO>(`${API_URL_BASE}/sign-out`)
      return {
         error: resp?.error,
         status: resp?.status,
         message: resp?.message
      }
   },
   profile: async (access_token: string): Promise<any>=> {
      const resp = await http.get<any>(`${API_URL_BASE}/datoUsuario`)
       return resp
      //{
      //    user: {
      //       admin: resp.data?.admin,
      //       cajero: resp.data?.cajero,
      //       email: resp.data?.email,
      //       nombre: resp.data?.nombre,
      //       id_sucursal: resp.data?.id_sucursal,
      //       rut_usuario: resp.data?.rut_usuario,
      //       sucursal: resp.data?.sucursal,
      //       usuario: resp.data?.usuario,
      //    },
      //    error: resp.error,
      //    token: access_token
      // }
   },
   dataUser,
   dataEmpresa,

}

function dataUser(token){
   return fetch(`${API_URL_BASE}/datoUsuario`, {
     method: 'GET',
     headers: {
       'Access-Control-Allow-Origin':'*',
       'Content-Type': 'application/x-www-form-urlencoded',
       token: token,
     },
   })
     .then(datosUsuarios => datosUsuarios.json())
     .then(datosUsuarios => {
       localStorage.setItem('dataUser', JSON.stringify(datosUsuarios[0]))
       return datosUsuarios[0];
     })
     .catch(error => {
       return false;
     })
 }

 function dataEmpresa(token){
   return fetch(`${API_URL_BASE}/datoEmpresa`, {
     method: 'GET',
     headers: {
       'Access-Control-Allow-Origin':'*',
       'Content-Type': 'application/x-www-form-urlencoded',
       token: token,
     },
   })
     .then(datosEmpresa => datosEmpresa.json())
     .then(datosEmpresa => {
      // localStorage.setItem('dataUser', JSON.stringify(datosEmpresa[0]))
       return datosEmpresa[0];
     })
     .catch(error => {
       return false;
     })
 }
