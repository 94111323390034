import React, { useState } from "react";
// import { Protected } from "@/components/layout/Protected";
import {
   Button,
   Grid,
   IconButton,
   InputBase,
   Modal,
   Paper,
   Typography,
} from "@mui/material";
import { Props } from "../AllProducts.type";
import "../AllProducts_style.css";
import CloseIcon from "@mui/icons-material/Close";

import { FormControl } from "@mui/material";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import SearchIcon from "@mui/icons-material/Search";
import { ModalPruebados } from "./ModalPruebados";
import { ModalPruebatres } from "./ModalPruebatres";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import Box from "@mui/material/Box";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import LocationOnIcon from "@mui/icons-material/LocationOn";

export const ModalPrueba: React.FC<Props> = (props: Props): JSX.Element => {

   const [open, setOpen] = useState(false);
   const handleClose = () => setOpen(false);
   const [openModalPruebados, setOpenModalPruebados] = React.useState(false);
   const [openModalPruebatres, setOpenModalPruebatres] = React.useState(false);

   const handleOpenPruebados = () => setOpenModalPruebados(true);
   const handleClosePruebados = () => setOpenModalPruebados(false);

   const handleOpenPruebatres = () => setOpenModalPruebatres(true);
   const handleClosePruebatres = () => setOpenModalPruebatres(false);

   const style = {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      height: "150px",
      transform: "translate(-50%, -50%)",
      width: "500px",
      borderRadius: "10px",
      bgcolor: "background.paper",
      boxShadow: "rgba(100,100,111,0.2) 0px 7px 29px 0px",
      p: 3,
      background: "#F5F5F5",
   };

   return (
      <Grid container p={1} sx={style}>
         <Grid item xs={8}>
            <Typography className="tittle" variant="body2" gutterBottom>
               INGRESE DATOS
            </Typography>
         </Grid>

         <Grid item xs={3} textAlign="left">
            <Button
               variant="contained"
               className="btn-guardar"
               onClick={handleOpenPruebatres}
               //component={Link}
               // to={"/Proveedores"}
            >
               <AppRegistrationIcon />
               <Typography variant="subtitle2">Registrar</Typography>
            </Button>
         </Grid>

         <Grid item xs={12}>
            <Grid item container spacing={1}>
               <Grid item xs={9}>
                  <FormControl fullWidth>
                     <TextField
                        id="input-with-sx"
                        // label="Folio Doc"
                        placeholder="Rut de Cliente..."
                        size="small"
                     />
                  </FormControl>
               </Grid>

               <Grid item xs={1}>
                  <Tooltip title="Buscar Cliente">
                     <IconButton
                        onClick={handleOpenPruebados}
                        className="btnIcoE"
                     >
                        <SearchIcon />
                     </IconButton>
                  </Tooltip>
               </Grid>
               <Grid item xs={2} justifyContent="flex-end">
                  <Tooltip title="Cargar Datos">
                     <Button
                        variant="outlined"
                        //component={Link}
                        // to={"/Proveedores"}
                     >
                        <SaveAltIcon />
                     </Button>
                  </Tooltip>
               </Grid>
            </Grid>
         </Grid>
         <Modal
            open={openModalPruebados}
            onClose={handleClosePruebados}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            className="modalCard"
         >
            <ModalPruebados />
         </Modal>

         <Modal
            open={openModalPruebatres}
            onClose={handleClosePruebatres}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            className="modalCard"
         >
            <ModalPruebatres />
         </Modal>
      </Grid>
   );
};
