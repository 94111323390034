import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "./HoursExtraordinaries.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './HoursExtraordinaries_styles.css'
import Modal from '@mui/material/Modal';
import { ModalAdvance } from './Modal/ModalHoursExtraordinaries'
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';


interface Column {
   id: 'numero' | 'rut' | 'apellidoPaterno' | 'apellidoMaterno'|'nombre';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

const columns:readonly Column[] = [
   { id: 'numero', label: 'N°', minWidth: 10 },
   { id: 'rut', label: 'Rut', minWidth: 10 },
   { id: 'apellidoPaterno', label: 'Apellido Paterno', minWidth: 10 },
   { id: 'apellidoMaterno', label: 'Apellido Materno', minWidth: 10 },
   { id: 'nombre', label: 'Nombre', minWidth: 10 },
   ];

interface Data {
   numero: number;
   rut: string;
   apellidoPaterno: string;
   apellidoMaterno: string;
   nombre: string;
}

function createDataAdvance(
   numero: number,
   rut: string,
   apellidoPaterno: string,
   apellidoMaterno: string,
   nombre: string,
   ):Data {
   return { numero, rut, apellidoPaterno, apellidoMaterno, nombre };
}

const rowsDataAdvance = [
   createDataAdvance(1, '12345678-9', 'Perez', 'Perez', 'Juan'),
   createDataAdvance(2, '12345678-8', 'Medina', 'Torres', 'Pedro'),
   createDataAdvance(3, '12345678-1', 'Cornejo', 'Dias', 'Marco'),
   createDataAdvance(4, '12345678-4', 'Valencia', 'Palomares', 'Jhon'),
   createDataAdvance(5, '12345678-7', 'Gomez', 'Perez', 'Juan'),
   createDataAdvance(6, '12345678-6', 'Perez', 'Medina', 'Pedro'),
   createDataAdvance(7, '12345678-3', 'Dias', 'Cornejo', 'Marco'),
   createDataAdvance(8, '12345678-2', 'Palomares', 'Valencia', 'Jhon'),
   createDataAdvance(9, '12345678-5', 'Perez', 'Gomez', 'Juan'),
   createDataAdvance(10, '12345678-0', 'Medina', 'Perez', 'Pedro'),
   createDataAdvance(11, '12345678-7', 'Cornejo', 'Dias', 'Marco'),
   createDataAdvance(12, '12345678-6', 'Valencia', 'Palomares', 'Jhon'),
   createDataAdvance(13, '12345678-3', 'Gomez', 'Perez', 'Juan'),
   createDataAdvance(14, '12345678-2', 'Perez', 'Medina', 'Pedro'),
   createDataAdvance(15, '12345678-5', 'Dias', 'Cornejo', 'Marco'),
]


export const HoursExtraordinaries:React.FC<Props> = (props: Props): JSX.Element => {
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [familyProduct, setFamilyProduct] = useState('');
   const [estado, setEstado]= useState('');

   const [openModal, setOpenModal] = useState(false);

   const handleOpenModal = () => setOpenModal(true);
   const handleCloseModal = () => setOpenModal(false);

   function handleClick() {
     setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const estados= [
      { value: '1', label: 'Activo' },
      { value: '2', label: 'Inactivo' },
   ]

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 920,
      height:650,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };
    return (
        <Protected>
        <Container maxWidth='xl'>
           <Grid container spacing={3} mb={2}>
               <Grid item xs={12} md={12} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        HORAS EXTRAORDINARIAS
                     </Typography>
                  </Box>
                  <Typography  variant="caption" display="block" gutterBottom mt={1}>
                  Trabajadores Ingresados
                  </Typography>
                  
               </Grid>
               <Grid item xs={12} sm={3}>
                  <TextField
                     label="Busqueda Avanzada ( Rut o Nombre):	"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                     value='22222222-2'
                  />
               </Grid>
             
               <Grid item xs={12} md={3}>
                    <Button
                        type='submit'
                        variant='contained'
                        // onClick={handleSubmit}
                        // disabled={loading}
                        disableElevation
                        className='btn_next'
                        style={{backgroundColor:'#ff9700', color:'#fff'}}
                        >
                        <span>BUSCAR</span>
                    </Button>
                </Grid>
            </Grid>
          
            <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:440}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                                 {column.label}
                              </TableCell>
                           ))}
                           <TableCell>Opcion</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsDataAdvance.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.numero}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                      {/* <Button onClick={handleOpenModal} variant="contained" style={{background:'#16556b', color:'#ffffff'}} className="btn_next">
                                         AGREGAR
                                       </Button> */}

                                 <Button onClick={handleOpenModal} className="btn_ico" variant="outlined" startIcon={<AddIcon />}>
                                    AGREGAR
                                    </Button>
                                 </TableCell>

                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsDataAdvance.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
            </Paper>

         </Container>
         <Modal
                  open={openModal}
                  onClose={handleCloseModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <ModalAdvance entity={undefined}/>
                  </Box>
            </Modal>
     </Protected>
    );
   };

