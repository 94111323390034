import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, InputAdornment, FormControl, FormControlLabel, Checkbox, Stack, } from '@mui/material'
import React, { useState, useRef } from "react";
import { Props } from "../Workers.type"
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TableBody from '@mui/material/TableBody';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TableCell from '@mui/material/TableCell';
import NumbersIcon from '@mui/icons-material/Numbers';
import TableContainer from '@mui/material/TableContainer';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PhoneIcon from '@mui/icons-material/Phone';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import AssignmentIcon from '@mui/icons-material/Assignment';
import NextWeekIcon from '@mui/icons-material/NextWeek';
import TableRow from '@mui/material/TableRow';
import '../Workers.css'
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import { DeleteIcon } from "@/toolbox/constants/icons";

interface Column {
   id:  'nombre' | 'nacimineto' | 'inicio'|'termino'|'tipo';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: 'nombre', label: 'Nombre', minWidth: 100 },
   { id: 'nacimineto', label: 'Nacimiento', minWidth: 100 },
   { id: 'inicio', label: 'Inicio', minWidth: 100 },
   { id: 'termino', label: 'Termino', minWidth: 100 },
   { id: 'tipo', label: 'Tipo', minWidth: 100 },
];

interface Data {
   nombre: string;
   nacimineto: string;
   inicio: string;
   termino: string;
   tipo: string;
}

function createDataContacto(
   nombre: string,
   nacimineto: string,
   inicio: string,
   termino: string,
   tipo: string,
): Data {
   return { nombre, nacimineto, inicio, termino,tipo };
}

const rowsListado = [
   createDataContacto('Pedrito Alcachofas', '11-02-2022','15-05-2022','20-06-2022','simple'),
   createDataContacto('Juanita Almendra', '08-02-2022','01-06-2022','16-07-2022','doble'),
   createDataContacto('Carlos V', '08-02-2022','01-06-2022','16-07-2022','doble')

]

export const DataModalFamily: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [ejecutivoComercial, setEjecutivoComercial] = useState('');
   const [estado, setEstado] = useState('');
   // const [data, setData] = React.useState<Date | null>(new Date());
   const [data, setData] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const inputRefs = useRef<Array<InputRef | null>>([]);


   const handleChangeEjecutivoComercial = (event: SelectChangeEvent) => {
      setEjecutivoComercial(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const estadoValue = [
      { label: 'ACTIVADO', value: 'activado' },
      { label: 'DESACTIVADO', value: 'desactivado' }
   ]
   const parentescoValue = [
      { label: 'Esposa'},
      { label: 'Esposo' },
      { label: 'Hija'},
      { label: 'Hijo' },
      { label: 'Padre'},
      { label: 'Madre' },
      { label: 'Otro'}
   ]
   const sexoValue = [
      { label: 'Masculino'},
      { label: 'Femenino' }
   ]
   const cargaValue = [
      { label: 'Simple'},
      { label: 'Duplo' },
      { label: 'Maternal' }
   ]

   return (
      <Container maxWidth='xl'>
         <Grid container spacing={3} mt={2} >
            <Grid container mb={2}>
               <Grid item xs={10}>
                  <Typography variant="h6" id="modal-modal-title" className='tittle'>
                     Mantenedor de Cargas Familiares del Trabajador
                  </Typography>
               </Grid>
               <Grid item xs={2} textAlign="end">
                  <AssignmentIcon style={{ color: '#FF9700' }} />
               </Grid>
            </Grid>
            <Grid container spacing={3} className={`e-mb-lg`}>

               <Grid item xs={12} sm={4}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <NumbersIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="resumen"
                     type='text'
                     placeholder="Nombre"
                     // onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     // rules={rules.resumen}
                     disableElevation
                     validateOnBlur
                     dense
                     // disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={4}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} color="primary" focused>
                     <Stack>
                        <DatePicker
                           openTo="year"
                           // views={['year', 'month', 'day']}
                           label="Fecha de Nacimiento"
                           value={data}
                           onChange={(newValue) => {
                              setData(newValue);
                           }}
                           renderInput={(params) => <TextField {...params} helperText={null} />}
                        />
                     </Stack>
                  </LocalizationProvider>
               </Grid>
               <Grid item xs={12} sm={4}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} color="primary" focused>
                     <Stack>
                        <DatePicker
                           openTo="year"
                           // views={['year', 'month', 'day']}
                           label="Vigencia desde"
                           value={data}
                           onChange={(newValue) => {
                              setData(newValue);
                           }}
                           renderInput={(params) => <TextField {...params} helperText={null} />}
                        />
                     </Stack>
                  </LocalizationProvider>
               </Grid>
               <Grid item xs={12} sm={4}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} color="primary" focused>
                     <Stack>
                        <DatePicker
                           openTo="year"
                           // views={['year', 'month', 'day']}
                           label="Hasta"
                           value={data}
                           onChange={(newValue) => {
                              setData(newValue);
                           }}
                           renderInput={(params) => <TextField {...params} helperText={null} />}
                        />
                     </Stack>
                  </LocalizationProvider>
               </Grid>
               <Grid item xs={12} sm={4}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={parentescoValue}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Parentesco" />}
                  />
               </Grid>               
               <Grid item xs={12} sm={4}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={sexoValue}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Sexo" />}
                  />
               </Grid>
               <Grid item xs={12} sm={4}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={cargaValue}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Tipo Carga Familiar" />}
                  />
               </Grid>
            </Grid>
            <Grid container mt={2}>
               <Grid item xs={12} textAlign="center">
                  <button className='btn_add'>
                     Agregar
                  </button>
               </Grid>

            </Grid>
            <Grid xs={12}>
               <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={2}>
                  LISTADO DE CARGAS FAMILIARES
               </Typography>
            </Grid>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
               <TableContainer sx={{ maxHeight: 200 }}>
                  <Table size="small" stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>                        
                           <TableCell>Nombre</TableCell>
                           <TableCell>Fecha de Nacimiento</TableCell>
                           <TableCell>Vigencia Inicio</TableCell>
                           <TableCell>Vigencia Término</TableCell>
                           <TableCell>Tipo</TableCell>
                           <TableCell>Opciones</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsListado.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.nombre}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}                           
                                 <TableCell>
                                    <Grid>
                                    <IconButton
                                    >
                                       <EditIcon />
                                    </IconButton>
                                    <IconButton
                                    >
                                       <DeleteIcon />
                                    </IconButton>
                                    </Grid>
                                    
                                 </TableCell>
                              </TableRow>
                           );
                        })}

                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsListado.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>
         </Grid>




      </Container >
   );
};

