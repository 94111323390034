import React, { FunctionComponent, useState } from "react"
import { Alert, Button, FormControl, Grid, MenuItem, Snackbar, TableContainer, Typography, useTheme, useMediaQuery, IconButton, Backdrop, CircularProgress, TableBody, Table, Tooltip, } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InputLabel from '@mui/material/InputLabel';
import EditIcon from '@mui/icons-material/Edit';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Protected } from "@/components/layout/Protected";

export const CurrentAccount: FunctionComponent = (props: any) => {

   const [data, setData] = useState('');
   const [dataBanco, setDataBanco] = useState('');
   const [open, setOpen] = useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);
   const [select, setSelect] = useState('');

   const handleChange = (event: any) => {
      setData(event.target.value as string);
   };
   const handleChangeBanco = (event: any) => {
      setDataBanco(event.target.value as string);
   };


   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 450,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };
   return (
      <Protected>
         <div className='spacing' style={{height:'100vh'}}>
            <Grid container>
               <Grid item xs={12} className='end'>
                  <Grid container>
                     <Grid item xs={10} sm={4}>
                        <Typography
                           mt={1}
                           variant="h3"
                           className='tittle'
                           sx={{ textTransform: 'uppercase' }}
                        >Cuenta Corriente	</Typography>
                     </Grid>

                     <Grid item xs={2} sm={1} className=''>
                        <Button className='btnData' variant="contained" onClick={handleOpen}>
                           {<AddIcon />}
                        </Button>
                     </Grid>
                  </Grid>
               </Grid>

               <Grid container mt={2}>
                  <Grid item xs={12}>
                     <Box mt={1} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <FormControl fullWidth >
                           <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                              <TableContainer sx={{ maxHeight: 540 }}>
                                 <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                       <TableRow>
                                          <TableCell align="center">Num.</TableCell>
                                          <TableCell align="center">N cuenta</TableCell>

                                          <TableCell align="center">Banco</TableCell>
                                          <TableCell align="center">Tipo Cuenta</TableCell>
                                          <TableCell align="center">Estado</TableCell>
                                          <TableCell align="center">Opciones</TableCell>
                                       </TableRow>
                                    </TableHead>

                                    <TableBody>
                                       <TableRow >
                                          <TableCell align="center">
                                             1
                                          </TableCell>

                                          <TableCell align="center">
                                             123322562
                                          </TableCell>

                                          <TableCell align="center">
                                             Banco Edwars
                                          </TableCell>
                                          <TableCell align="center">
                                             Cuenta Visa
                                          </TableCell>
                                          <TableCell align="center">

                                             <FormControlLabel control={<Checkbox defaultChecked />} label="" />

                                          </TableCell>

                                          <TableCell align="left">
                                             <div className='sepaEle'>
                                                <Tooltip title="Editar">
                                                   <button className='btnIcoD'><EditIcon /></button>
                                                </Tooltip>

                                             </div>
                                          </TableCell>
                                       </TableRow>

                                    </TableBody>
                                 </Table>
                              </TableContainer>

                           </Paper>
                        </FormControl>
                     </Box>
                  </Grid>
               </Grid>
            </Grid>
         </div>
         <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <Grid container>
                  <Grid item xs={10}>
                     <Typography variant="h6" id="modal-modal-title" mb={2} className='tittle'>
                        Mantenedor Cuenta Corriente
                     </Typography>
                  </Grid>
                  <Grid item xs={2} textAlign="end">
                     <AssignmentIcon style={{ color: '#FF9700' }} />
                  </Grid>
               </Grid>
               <Grid container xs={12} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                  <Grid item xs={12} mt={2}>
                     <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <FormControl fullWidth >
                           <TextField
                              id="input-with-sx"
                              label="Número de cuenta"
                              // placeholder="(%)"
                              rows={2}
                           />
                        </FormControl>
                     </Box>
                  </Grid>
                  <Grid item xs={12} mt={2}>
                     <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-helper-label">Seleccione Banco</InputLabel>
                        <Select
                           labelId="demo-simple-select-helper-label"
                           id="demo-simple-select-helper"
                           value={dataBanco}
                           label="Seleccione Banco"
                           onChange={handleChangeBanco}
                        >
                           <MenuItem value={1}>BANCO 1</MenuItem>
                           <MenuItem value={2}>BANCO 2</MenuItem>
                           <MenuItem value={3}>BANCO 3</MenuItem>
                           <MenuItem value={4}>BANCO 4</MenuItem>
                           <MenuItem value={5}>BANCO 5</MenuItem>
                           <MenuItem value={6}>BANCO 6</MenuItem>
                           <MenuItem value={7}>BANCO 7</MenuItem>
                        </Select>
                     </FormControl>
                  </Grid>
                  
                  <Grid item xs={7} mt={2}>
                     <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-helper-label">Seleccione Tipo</InputLabel>
                        <Select
                           labelId="demo-simple-select-helper-label"
                           id="demo-simple-select-helper"
                           value={data}
                           label="Seleccione Tipo"
                           onChange={handleChange}
                        >
                           <MenuItem value={1}>Sin tipo de cuenta</MenuItem>
                           <MenuItem value={2}>Cuenta de ahorro</MenuItem>
                           <MenuItem value={2}>Cuenta corriente</MenuItem>
                           <MenuItem value={2}>Línea de crédito</MenuItem>
                        </Select>
                     </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                     <FormControlLabel control={<Checkbox defaultChecked />} label="Activo" />
                  </Grid>
               </Grid>
               <Grid container mt={2}>
                  <Grid item xs={6} textAlign="center">
                     <Button variant="contained" className='btnColorCanceled' onClick={handleClose}>
                        Cancelar
                     </Button>
                  </Grid>
                  <Grid item xs={6} textAlign="center">
                     <Button variant="contained" className='btnColorAccepeted' onClick={handleClose}>
                        Guardar
                     </Button>
                  </Grid>
               </Grid>
            </Box>

         </Modal>

      </Protected>
   )
}