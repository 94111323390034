import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, } from '@mui/material';
import {  ArrowRightIcon } from "@toolbox/constants/icons";
import { Props } from './FormGeneral.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import './style.sass'
import "./FormGeneral_style.css";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import BusinessIcon from '@mui/icons-material/Business';
import FaxIcon from '@mui/icons-material/Fax';
import LanguageIcon from '@mui/icons-material/Language';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import HomeIcon from '@mui/icons-material/Home';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import DnsIcon from '@mui/icons-material/Dns';

export const FormGeneral: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);

    
   
   const estados = [
      { label: 'Activo'},
      { label: 'Bloqueo Comercial'},
   ]
   const paises = [
      { label: 'Argentina'},
      { label: 'Brasil'},
      { label: 'Chile'},
      { label: 'Colombia'},
      { label: 'Ecuador'},
      { label: 'Peru'},
      { label: 'Uruguay'},
   ]

  
   const rules = useMemo(() => ({
      rut: [
         VALIDATORS.REQUIRED,
      ],
      razon_social: [
         VALIDATORS.REQUIRED,
      ],
      giro_empresa: [
         VALIDATORS.REQUIRED
      ],
      email: [
         VALIDATORS.REQUIRED,
      ],
      pagina_web: [
         VALIDATORS.REQUIRED,
      ],
      observacion: [
         VALIDATORS.REQUIRED,
      ],
      tipo:[
         VALIDATORS.REQUIRED,
      ],
      fantasia: [
         VALIDATORS.REQUIRED,
      ],
      telefono_fijo: [
         VALIDATORS.REQUIRED,
      ],
      fax: [
         VALIDATORS.REQUIRED,
      ],
      activo:[
         VALIDATORS.REQUIRED,
      ],
      direccion:[
         VALIDATORS.REQUIRED,
      ],
      ciudad:[
         VALIDATORS.REQUIRED,
      ],
      geocolizacion:[
         VALIDATORS.REQUIRED,
      ],
      pais:[
         VALIDATORS.REQUIRED,
      ],
      comuna:[
         VALIDATORS.REQUIRED,
      ],


   }), []);


   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'rut':
            props.setvalueEntry(prev => ({ ...prev, rut: value }));
            break;
         case 'razon_social':
            props.setvalueEntry(prev => ({ ...prev, razon_social: value }))
            break;
         case 'giro_empresa':
            props.setvalueEntry(prev => ({ ...prev, giro_empresa: value }))
            break;
         case 'email':
            props.setvalueEntry(prev => ({ ...prev, email: value }));
            break;
         case 'pagina_web':
            props.setvalueEntry(prev => ({ ...prev, pagina_web: value }));
            break;
         case 'observacion':
            props.setvalueEntry(prev => ({ ...prev, observacion: value }));
            break;
         case 'tipo':
            props.setvalueEntry(prev => ({ ...prev, tipo: value }));
            break;
         case 'fantasia':
            props.setvalueEntry(prev => ({ ...prev, fantasia: value }));
            break;
         case 'telefono_fijo':
            props.setvalueEntry(prev => ({ ...prev, telefono_fijo: value }));
            break;
         case 'fax':
            props.setvalueEntry(prev => ({ ...prev, fax: value }));
            break;
         case 'activo':
            props.setvalueEntry(prev => ({ ...prev, activo: value }));
            break;
         case 'direccion':
            props.setvalueEntry(prev => ({ ...prev, direccion: value }));
            break;
         case 'ciudad':
            props.setvalueEntry(prev => ({ ...prev, ciudad: value }));
            break;
         case 'geocolizacion':
            props.setvalueEntry(prev => ({ ...prev, geocolizacion: value }));
            break;
         case 'comuna':
            props.setvalueEntry(prev => ({ ...prev, comuna: value }));
            break;
         case 'pais':
            props.setvalueEntry(prev => ({ ...prev, pais: value }));
            break;
         default:
            break;
      }
   };
   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      props.setvalueEntry(prev => ({ ...prev, [name]: newVal }));
   }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      props.handleNext();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }

   return (
      <Container maxWidth="xl">
         <form >
            <Grid container spacing={3} className={`e-mb-lg`}>
            
               <Grid item xs={12} sm={3}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <BusinessIcon/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="rut"
                     type='text'
                     placeholder="Rut"
                     value={props.valueEntry.rut}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.rut}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />

               </Grid>
        
               <Grid item xs={12} sm={3}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <BusinessCenterIcon/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="razon_social"
                     type='text'
                     placeholder="Razon Social"
                     value={props.valueEntry.razon_social}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.razon_social}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />

               </Grid>
              
               <Grid item xs={12} sm={3}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <DriveFileRenameOutlineIcon/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="nombre_fantasia"
                     type='text'
                     placeholder="Nombre Fantasia"
                     value={props.valueEntry.nombre_fantasia}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.rut}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
               <FormControl>
                  <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value='prospecto'
                  >
                  <FormControlLabel value="cliente" control={<Radio />} label="Cliente" />
                  <FormControlLabel value="prospecto" control={<Radio />} label="Prospecto" />
                  </RadioGroup>
               </FormControl>
               </Grid>

               <Grid item xs={12} sm={3}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <BubbleChartIcon/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="giro_empresa"
                     type='text'
                     placeholder="Giro Empresa"
                     value={props.valueEntry.giro_empresa}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.giro_empresa}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <LocalPhoneIcon/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="telefono_fijo"
                     type='text'
                     placeholder="Telefono Fijo"
                     value={props.valueEntry.telefono_fijo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.telefono_fijo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />

               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <AlternateEmailIcon/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="email"
                     type='text'
                     placeholder="Email"
                     value={props.valueEntry.email}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.email}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <FaxIcon/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="fax"
                     type='text'
                     placeholder="Fax"
                     value={props.valueEntry.fax}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.fax}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <LanguageIcon/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="pagina_web"
                     type='text'
                     placeholder="Pagina Web"
                     value={props.valueEntry.pagina_web}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.pagina_web}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={estados}
                           renderInput={(params) => <TextField {...params} label="Estado" />}
                  />
               </Grid>

               <Grid item xs={12} sm={6}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <HomeIcon/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="direccion"
                     type='text'
                     placeholder="Direccion"
                     value={props.valueEntry.direccion}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.direccion}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <DnsIcon/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="comuna"
                     type='text'
                     placeholder="Comuna"
                     value={props.valueEntry.comuna}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.comuna}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <LocationCityIcon/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="ciudad"
                     type='text'
                     placeholder="Ciudad"
                     value={props.valueEntry.ciudad}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.ciudad}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
               <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={paises}
                     renderInput={(params) => <TextField {...params} label="Pais" />}
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
                  <Input
                    prependInnerAdornment={
                     <InputAdornment position="end">
                        <ImportContactsIcon/>
                    </InputAdornment>
                    }
                    ref={ref => inputRefs.current[0] = ref}
                     name="geocolizacion"
                     type='text'
                     placeholder="Geocolizacion"
                     value={props.valueEntry.geocolizacion}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.geocolizacion}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>

               <Grid item container
                  xs={12}
                 justifyContent='right'
                 >
                     <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                        className='btn_save'
                        
                        endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>Siguiente</span>
                        }
                     </Button>

                     </Grid>
               </Grid>
            </Grid>
         </form>
      </Container>
   );
}
