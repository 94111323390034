import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, IconButton, SelectChangeEvent, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, } from '@mui/material';
import {  ArrowRightIcon } from "@toolbox/constants/icons";
import { Props } from './FormPay.type';
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import './style.sass'
import "./FormPay_style.css";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

import { MonetizationOn } from '@mui/icons-material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';


export const FormPay: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   // const [value, setValue] = useState<Date | null>(new Date());
   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
    );

   const rules = useMemo(() => ({
      margen: [
         VALIDATORS.REQUIRED,
      ],
      precio_producto: [
         VALIDATORS.REQUIRED,
      ],
      precio_alternativo: [
         VALIDATORS.REQUIRED
      ],
      costo: [
         VALIDATORS.REQUIRED,
      ],
      precio_oferta: [
         VALIDATORS.REQUIRED,
      ],
      fecha_oferta: [
         VALIDATORS.REQUIRED,
      ],

   }), []);

   interface Column {
      id: 'rut' | 'n' | 'documento' | 'fecha' | 'monto' | 'abonos' | 'saldo'  ;
      label: string;
      minWidth?: number;
      align?: 'right';
      format?: (value: number) => string;
    }
   
   const columns:readonly Column[] = [
      { id: 'rut', label: 'Rut', minWidth: 100 },
      { id: 'n', label: 'Nombre', minWidth: 100 },
      { id: 'documento', label: 'Documento', minWidth: 100 },
      { id: 'fecha', label: 'Fecha', minWidth: 100 },
      { id: 'monto', label: 'Monto', minWidth: 100 },
      { id: 'abonos', label: 'Abonos', minWidth: 100 },
      { id: 'saldo', label: 'Saldo', minWidth: 100 },
      ];
   
   interface Data {
      rut: string;
      n: string;
      documento: string;
      fecha: string;
      monto: string;
      abonos: string;
      saldo: string;
   }
   
   function createData(
      rut: string,
      n: string,
      documento: string,
      fecha: string,
      monto: string,
      abonos: string,
      saldo: string,
      ):Data {
      return { rut, n, documento, fecha, monto, abonos, saldo };
   }
   
   const rowsData = [
      createData('1545219', 'Juan', '12345', '12/12/12', '$12.000', '$12.000', '$0.00'),
      createData('1425429', 'Juan', '12345', '12/12/12', '$12.000', '$12.000', '$0.00'),
      createData('1545219', 'Juan', '12345', '12/12/12', '$12.000', '$12.000', '$0.00'),
      createData('1425429', 'Juan', '12345', '12/12/12', '$12.000', '$12.000', '$0.00'),
      createData('1545219', 'Juan', '12345', '12/12/12', '$12.000', '$12.000', '$0.00'),
      createData('1425429', 'Juan', '12345', '12/12/12', '$12.000', '$12.000', '$0.00'),

   ]

   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'margen':
            props.setvalueEntry(prev => ({ ...prev, margen: value }));
            break;
         case 'precio_producto':
            props.setvalueEntry(prev => ({ ...prev, precio_producto: value }))
            break;
         case 'precio_alternativo':
            props.setvalueEntry(prev => ({ ...prev, precio_alternativo: value }))
            break;
         case 'costo':
            props.setvalueEntry(prev => ({ ...prev, costo: value }));
            break;
         case 'precio_venta':
            props.setvalueEntry(prev => ({ ...prev, precio_venta: value }));
            break;
         case 'precio_oferta':
            props.setvalueEntry(prev => ({ ...prev, precio_oferta: value }));
            break;
         case 'fecha_oferta':
            props.setvalueEntry(prev => ({ ...prev, fecha_oferta: value }));
            break;
         default:
            break;
      }
   };
   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      props.setvalueEntry(prev => ({ ...prev, [name]: newVal }));
   }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      props.handleNext();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }


   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const bancosDestino = [
      { label: 'Banco Interbank', value: 'Banco 1' },
      { label: 'Banco de Chile', value: 'Banco 2' },
      { label: 'Banco de la Nación', value: 'Banco 3' },
      { label: 'Banco Santander', value: 'Banco 4' },
      { label: 'Banco de Crédito e Inversiones', value: 'Banco 5' },
      { label: 'Banco de la Provincia', value: 'Banco 6' },
      { label: 'Banco Falabella', value: 'Banco 7' },
      { label: 'Banco Itaú', value: 'Banco 8' },
      { label: 'Banco Santander', value: 'Banco 9' },
      { label: 'Banco Santander', value: 'Banco 10' },
      { label: 'Banco Santander', value: 'Banco 11' },

   ]
   const bancosOrigen = [
      { label: 'Banco Interbank', value: 'Banco 1' },
      { label: 'Banco de Chile', value: 'Banco 2' },
      { label: 'Banco de la Nación', value: 'Banco 3' },
      { label: 'Banco Santander', value: 'Banco 4' },
      { label: 'Banco de Crédito e Inversiones', value: 'Banco 5' },
      { label: 'Banco de la Provincia', value: 'Banco 6' },
      { label: 'Banco Falabella', value: 'Banco 7' },
      { label: 'Banco Itaú', value: 'Banco 8' },
      { label: 'Banco Santander', value: 'Banco 9' },
      { label: 'Banco Santander', value: 'Banco 10' },
      { label: 'Banco Santander', value: 'Banco 11' },

   ]

   return (
      <Container maxWidth="xl">
         <form >
            <Grid container spacing={3} className={`e-mb-lg`}>
           

               <Grid item xs={6} md={3} >
                  <Box>
                     <Typography variant='subtitle2'  gutterBottom>
                        TIPO DE CANCELACION
                     </Typography>
                  </Box>
               </Grid>

               <Grid item xs={6} md={9} >
                  <Box>
                     <Typography variant='caption'  gutterBottom>
                           Pago con Transferencia
                     </Typography>
                  </Box> 
               </Grid>



               <Grid item xs={6} md={4} >
                  
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={bancosDestino}
                     renderInput={(params) => <TextField {...params} label="Banco Destino / Numero de Cuenta" />}
                  />
                  
               </Grid>


               <Grid item xs={6} md={4} >
                  
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={bancosOrigen}
                     renderInput={(params) => <TextField {...params} label="Banco de Origen" />}
                  />
                  
               </Grid>


               <Grid item xs={6} md={4} >
                  
                 <TextField
                     size='small'
                     variant='outlined'
                     name='serie_transferencia'
                     value="TRA-28032022-337867-62414f3ed2c7c"
                     label="Serie Transferencia"
                     fullWidth
                     />
 
               </Grid>

               <Grid item xs={6} md={4} >
               <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={3}>
                     <DatePicker
                           views={['year', 'month']}
                           label="Fecha de Ingreso"
                           // minDate={new Date('2012-03-01')}
                           // maxDate={new Date('2023-06-01')}
                           value={value}
                           onChange={(newValue) => {
                              setValue(newValue);
                           }}
                           renderInput={(params) => <TextField  {...params} helperText={null} />}
                           />
                    </Stack>
                    </LocalizationProvider>
                 
               </Grid>

               <Grid item xs={6} md={4} >
               <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={3}>
                     <DatePicker
                           views={['year', 'month']}
                           label="Fecha de Trasferencia"
                           // minDate={new Date('2012-03-01')}
                           // maxDate={new Date('2023-06-01')}
                           value={value}
                           onChange={(newValue) => {
                              setValue(newValue);
                           }}
                           renderInput={(params) => <TextField  {...params} helperText={null} />}
                           />
                    </Stack>
                    </LocalizationProvider>
                 
               </Grid>
            </Grid>
         </form>

         <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={2}>
               DETALLE DOCUMENTOS SELECCIONADOS
            </Typography>


         <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:250}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                                 {column.label}
                              </TableCell>
                           ))}
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.rut}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}

                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>


               {/* <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  /> */}
               
            </Paper>
            <Grid container spacing={3} mt={1}>

                  <Grid item xs={12} md={12} className="end" >
                     <TextField 
                        size='small'
                        variant='outlined'
                        name='serie_transferencia'
                        value="$ 123.546"
                        label="Serie Transferencia"
                        
                     />
                  </Grid>
                  <Grid item xs={12} md={12} className="center">
                     <Button
                        type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                        // className='btn_save'
                        className='btn_save'
                        endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>Cancelar Documento</span>
                        }
                     </Button>

                  </Grid>
               </Grid>

      </Container>
   );
}
