import React, { useState, useEffect, FunctionComponent, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Protected } from '@/components/layout/Protected';
import { Alert, Button, FormControl, Grid, MenuItem, Snackbar, TableContainer, Typography,useTheme ,useMediaQuery, IconButton, Backdrop, CircularProgress, } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import InputLabel from '@mui/material/InputLabel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import PeopleIcon from '@mui/icons-material/People';
import { ROUTE_PROVIDER_CREATE } from '@/toolbox/constants/route-map';
import { Link } from 'react-router-dom';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export const RutNotRejected: FunctionComponent=(props:any)=>{
    
   return (
      <Protected>
            <div className='spacing' style={{height:'100vh'}}>
            <Grid container>
               <Grid item xs={12} className='end'>
                  <Grid container>
                     <Grid item xs={10} sm={3}>
                        <Typography
                        mt={1}
                        variant="h3"
                        className='tittle'
                        sx={{ textTransform: 'uppercase'}}
                        >Vista Rut Sin Rechazar</Typography>
                     </Grid> 
                     
                  </Grid>
               </Grid>
               
            </Grid>
         </div>
      </Protected>
   )
}