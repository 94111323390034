import { Protected } from "@/components/layout/Protected";
import { Box, Stepper, Step, StepLabel, Button, CircularProgress, Grid, CssBaseline, Container, StepIconProps, Typography, FormControl } from '@mui/material'
import { FunctionComponent, useEffect, useState } from "react";
import {Props} from "./FixedAssets.type"
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import InfoIcon from '@mui/icons-material/Info';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { FormDataEntry } from "./FormDataEntry";
import { FormContable } from "./FormContable";
import { FormSituationInitial } from "./FormSituationInitial";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';


const steps= [{name:'Datos Ingreso', icon:null},
{name:'Información Contable', icon:null},
{name:'Situación Inicial', icon:null}]

export const FixedAssets:React.FC<Props> = (props: Props): JSX.Element => {
    
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [error, setError] = useState(null);
    const [valueEntry,setvalueEntry]=useState({
        resumen:'',
        fecha:'',
        detalle:'',
        concepto_ingreso:'',
        area_negocio:'',
        centro_costo:'',
        ubicacion:'',
        fecha_vencimiento:'',
        revalorizable:'',
        tipo_depreciacion:'',
        depreiable:'',
        unidad_medida:''
    });
    
    const [valueContable,setvalueContable]=useState({
        valor_residual:'',
        tipo_adquisicion:'',
        compra_activo:'',
        revalorizacion:'',
        depreciacion:'',
        readecuaciones:'',
        monto_credito:'',
        credito_adquisicion:''
    });

    const [valueSituation,setvalueSituation]=useState({
        situacion_inicial:'',
        fecha_compra:'',
        valor_compra:'',
        numero_factura:'',
        orden_compra:'',
        proveedor:'',
        vida_tributaria:'',
        vida_financiera:'',
        inicio_valorizacion:'',
        inicio_depreciacion:'',
        descripcion:''
    });

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
  
    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    interface Column {
      id: 'Descripcion' | 'Fecha Ingreso' | 'Fecha Compra' | 'Concepto';
      label: string;
      minWidth?: number;
      align?: 'right';
      format?: (value: number) => string;
    }
    const columns: Column[] = [
      { id: 'Descripcion', label: 'Descripcion', minWidth: 100 },
      { id: 'Fecha Ingreso', label: 'Fecha Ingreso' },
      { id: 'Fecha Compra', label: 'Fecha Compra' },
      { id: 'Concepto', label: 'Concepto', minWidth: 100 },

    ];
    
    interface Data{
         Descripcion:string;
         FechaIngreso:string;
         FechaCompra:string;
         Concepto:string;
    }
    
    function createData(
       Descripcion:string,
       FechaIngreso:string,
       FechaCompra:string,
       Concepto:string):Data{
         return {Descripcion,FechaIngreso,FechaCompra,Concepto};
      }

      const rows=[
         createData('Descripcion 1','Fecha Ingreso 1','Fecha Compra 1','Concepto 1'),
         createData('Descripcion 2','Fecha Ingreso 2','Fecha Compra 2','Concepto 2'),
         createData('Descripcion 3','Fecha Ingreso 3','Fecha Compra 3','Concepto 3'),
         createData('Descripcion 4','Fecha Ingreso 4','Fecha Compra 4','Concepto 4'),
         createData('Descripcion 5','Fecha Ingreso 5','Fecha Compra 5','Concepto 5'),
         createData('Descripcion 6','Fecha Ingreso 6','Fecha Compra 6','Concepto 6'),
         createData('Descripcion 7','Fecha Ingreso 7','Fecha Compra 7','Concepto 7'),
         createData('Descripcion 8','Fecha Ingreso 8','Fecha Compra 8','Concepto 8'),
      ]
    useEffect(() => {
        if(props.entity !== undefined){
            setvalueEntry(props.entity);
            setvalueContable(props.entity);
            setvalueSituation(props.entity);
        }
    }, []);

    const isStepSkipped = (step) => {
        return skipped.has(step);
     };
  
     const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
           newSkipped = new Set(newSkipped.values());
           newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
     };
  
     const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
     };


     const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
          top: 22,
        },
        [`&.${stepConnectorClasses.active}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
              'linear-gradient( 95deg,#19c1d1 0%,#1976D2 50%,#1996d1 100%)',
          },
        },
        [`&.${stepConnectorClasses.completed}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
              'linear-gradient( 95deg,#19c1d1 0%,#1976D2 50%,#1996d1 100%)',
          },
        },
        [`& .${stepConnectorClasses.line}`]: {
          height: 3,
          border: 0,
          backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
          borderRadius: 1,
        },
      }));
  
      const ColorlibStepIconRoot = styled('div')<{
        ownerState: { completed?: boolean; active?: boolean };
      }>(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
          backgroundImage:
            'linear-gradient( 136deg, #1976D2 0%, #1996d1 50%, #19c1d1 100%)',
          boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
          backgroundImage:
            'linear-gradient( 136deg, #1976D2 0%, #1996d1 50%, #19c1d1 100%)',
        }),
      }));


      function ColorlibStepIcon(props: StepIconProps) {
        const { active, completed, className } = props;
  
        const icons: { [index: string]: React.ReactElement } = {
          1: <AssignmentIcon />,
          2:<AnalyticsIcon/>,
          3: <InfoIcon />          
        };
  
        return (
          <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
          </ColorlibStepIconRoot>
        );
      }

      const mostrarFormulario = () => {
        if (activeStep === 0) {
           return (
              <FormDataEntry
                 handleNext={handleNext}
                 valueEntry={valueEntry}
                 setvalueEntry={setvalueEntry}
              />
           )
        }
        else if (activeStep === 1) {
           return (
            <FormContable
            handleNext={handleNext}
            valueEntry={valueEntry}
            setvalueEntry={setvalueEntry}
            />
           )
        }
        else if (activeStep === 2) {
           return (
            <FormSituationInitial
            handleNext={handleNext}
            valueEntry={valueEntry}
            setvalueEntry={setvalueEntry}
            />
           )
        }
     }
    return (
        <Protected>
        <Container maxWidth='xl'>
           <Typography variant="h6" gutterBottom mb={3} className="tittle">
               MANTENEDOR DE ACTIVOS
             </Typography>
           <Grid container flexDirection='row' spacing={2}>
                 <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                       <Box>
                          <Box sx={{ mb: 2 }}>
                             <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
                                {steps.map((label, index) => {
                                   return (
                                      <Step key={index}>
                                         <StepLabel StepIconComponent={ColorlibStepIcon} error={error === `Usuario${index}noencontrado` ? true : false}>{label.name}</StepLabel>
                                      </Step>
                                   );
                                })}
                             </Stepper>
                          </Box>
                          {activeStep === steps.length ? (
                             <div>AGREGADO</div>
                             //VALIDAR QUE EL ENVIO DE DATOS
                             // history.push(ROUTE_ACCOUNT)
                          ) : (
                             mostrarFormulario()
                          )}
                       </Box>
                    </Grid>
                 </Grid>
           </Grid>
           
           <div className='bg-tittle'>
                        <Typography 
                        mt={3} 
                        variant="h6"
                        align='center'
                        className='tittle-text'
                        >Listado de Activos Ingresados
                        </Typography>
         </div>

               <Box mt={3} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                              <FormControl fullWidth >
                                 <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                       <TableContainer sx={{ maxHeight: 540 }}>
                                       <Table stickyHeader aria-label="sticky table">
                                          <TableHead>
                                             <TableRow>
                                                <TableCell>Descripcion</TableCell>
                                                <TableCell>Fecha Ingreso</TableCell>
                                                <TableCell>Fecha Compra</TableCell>
                                                <TableCell>Concepto</TableCell>
                                                <TableCell>Opciones</TableCell>
                                             </TableRow>
                                          </TableHead>
               
                                          <TableBody>
                                             <TableRow >  
                                                <TableCell>
                                                   Descripcion 1
                                                </TableCell>

                                                <TableCell>
                                                   02/10/2020
                                                </TableCell>
                                                         
                                                <TableCell>
                                                   25/01/2020
                                                </TableCell>

                                                <TableCell>
                                                   Concepto 1
                                                </TableCell>
  
                                                <TableCell>
                                                   <div className='sepaEle'>
                                                      <button className='btnIcoE'><EditIcon/></button>
                                                   </div>
                                                </TableCell>
                                             </TableRow>
                                              
                                          </TableBody>
                                       </Table>
                                       </TableContainer>
                                       
                                 </Paper>
                              </FormControl>
               </Box>
           {/* <Paper  sx={{ width: '100%', overflow: 'hidden' }}>
               <TableContainer  sx={{ maxHeight: 280 }}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{ minWidth: column.minWidth }}
                                 >
                                 {column.label}
                              </TableCell>
                              ))}
                              <TableCell>Opciones</TableCell>
                        </TableRow>
                     </TableHead>
         
                     <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                           .map((rows) => {
                              return (
                                 <TableRow hover role="checkbox" tabIndex={-1} key={rows.Descripcion}>
                                    {columns.map((column) => {
                                    const value = rows[column.id];
                                    return (
                                    <TableCell  key={column.id} align={column.align}>
                                    {column.format && typeof value === 'number'
                                    ? column.format(value)
                                    : value}
                                    </TableCell>
                                   
                                    );
                                    })}
                                    <TableCell>
                                       <div className='sepaEle'>
                                          <button className='btnIcoD'><EditIcon /></button>
                                       </div>
                                    </TableCell>
                                 </TableRow>
                                          );
                                       })}
                        </TableBody>
                     </Table>
                  </TableContainer>
                                 
               </Paper> */}
         </Container>
     </Protected>

    );
};

