
import React, { FunctionComponent, useState } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Modal, IconButton, Tooltip } from '@mui/material';
import '../EvMarginSell_styles.css';
import TextField from '@mui/material/TextField';
import { Autocomplete, Box, Button, Grid } from "@mui/material";
import { Protected } from "@/components/layout/Protected";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Container, } from '@mui/material'
import Typography from '@mui/material/Typography';
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowSquareIcon from '@mui/icons-material/Square';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from 'react-router-dom';
import { ROUTE_EV_DE_MARGIN_SELL } from '@/toolbox/constants/route-map';

interface TableMOnthProductProps {

}
interface Column {
   id: 'grc' | 'codigo' | 'descripcion' | 'fisico' | 'valorizado' | 'fisico1' | 'valorizado1' | 'costoVenta' | 'margen';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}
interface Column1 {
   id: 'grc' | 'codigo' | 'descripcion' | 'fisico' | 'valorizado' | 'fisico1' | 'valorizado1' | 'costoVenta' | 'margen';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: 'grc', label: 'GRC', minWidth: 10 },
   { id: 'codigo', label: 'Código', minWidth: 100 },
   { id: 'descripcion', label: 'Descripción', minWidth: 100 },
   { id: 'fisico', label: 'Fisico', minWidth: 100 },
   { id: 'valorizado', label: 'Valorizado', minWidth: 100 },
   { id: 'fisico1', label: 'Fisico1', minWidth: 100 },
   { id: 'valorizado1', label: 'Valorizado1', minWidth: 100 },
   { id: 'costoVenta', label: 'Costo Venta', minWidth: 100 },
   { id: 'margen', label: 'Margen Venta', minWidth: 100 },
];

const columns1: readonly Column1[] = [
   { id: 'grc', label: 'GRC', minWidth: 10 },
   { id: 'codigo', label: 'Código', minWidth: 100 },
   { id: 'descripcion', label: 'Descripción', minWidth: 100 },
   { id: 'fisico', label: 'Fisico', minWidth: 100 },
   { id: 'valorizado', label: 'Valorizado', minWidth: 100 },
   { id: 'fisico1', label: 'Fisico1', minWidth: 100 },
   { id: 'valorizado1', label: 'Valorizado1', minWidth: 100 },
   { id: 'costoVenta', label: 'Costo Venta', minWidth: 100 },
   { id: 'margen', label: 'Margen Venta', minWidth: 100 },
];

interface Data {
   grc: string;
   codigo: string;
   descripcion: string;
   fisico: string;
   valorizado: string;
   fisico1: string;
   valorizado1: string;
   costoVenta: string;
   margen: string;
}

interface Data1 {
   grc: string;
   codigo: string;
   descripcion: string;
   fisico: string;
   valorizado: string;
   fisico1: string;
   valorizado1: string;
   costoVenta: string;
   margen: string;
}

function createData(
   grc: string,
   codigo: string,
   descripcion: string,
   fisico: string,
   valorizado: string,
   fisico1: string,
   valorizado1: string,
   costoVenta: string,
   margen: string,
): Data {
   return { grc, codigo, descripcion, fisico, valorizado, fisico1, valorizado1, costoVenta, margen };
}

function createData1(
   grc: string,
   codigo: string,
   descripcion: string,
   fisico: string,
   valorizado: string,
   fisico1: string,
   valorizado1: string,
   costoVenta: string,
   margen: string,
): Data {
   return { grc, codigo, descripcion, fisico, valorizado, fisico1, valorizado1, costoVenta, margen };
}

const rowsData = [
   createData('1', '0000001', 'Oferta JCO', '0', '0', '29', '$ 12.548', '$ 15.454', '45.1545'),
   createData('2', '0000002', 'Oferta JCO', '0', '0', '29', '$ 12.548', '$ 15.454', '45.1545'),
   createData('3', '0000003', 'Oferta JCO', '0', '0', '29', '$ 12.548', '$ 15.454', '45.1545'),
   createData('4', '0000004', 'Oferta JCO', '0', '0', '29', '$ 12.548', '$ 15.454', '45.1545'),
   createData('5', '0000005', 'Oferta JCO', '0', '0', '29', '$ 12.548', '$ 15.454', '45.1545'),
   createData('6', '0000006', 'Oferta JCO', '0', '0', '29', '$ 12.548', '$ 15.454', '45.1545'),
]
const rowsData1 = [
   createData('', '', '', '0', '0', '0', '$ 0', '$ 0', '0.00'),
]



export const MonthDaily: FunctionComponent<TableMOnthProductProps> = () => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };


   return (
      <>
         <Protected >
            <Grid container mb={4}>
               <Grid item xs={12} sm={6}>
                  <Typography variant="h6" id="modal-modal-title" mb={2} className='tittle'>
                     Margen de Venta 10 Junio - 2022
                  </Typography>
                  <Typography variant="subtitle2">
                     Detalle según Ventas Realizadas
                  </Typography>
               </Grid>
               <Grid item xs={12} sm={4} textAlign="end">
                  <Button>
                     Exportar
                  </Button>
               </Grid>

               <Grid item xs={12} sm={2} textAlign="end">
                  <Button variant="outlined" component={Link} to={ROUTE_EV_DE_MARGIN_SELL} startIcon={<ArrowBackIosIcon />}>
                     Volver
                  </Button>
               </Grid>

            </Grid>

            <Paper sx={{ width: '100%', overflow: 'hidden' , marginBottom: '50px'}}>
               <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                     <TableHead>
                        <TableRow>
                           {columns.map((column) => (
                              <TableCell

                                 key={column.id}
                                 align={column.align}
                                 style={{ minWidth: column.minWidth }}
                              >
                                 {column.label}
                              </TableCell>

                           ))}
                           <TableCell>%MV</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.codigo}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell> %70
                                    <ArrowUpwardIcon style={{ fontSize: '2rem', color: 'green' }} />
                                 </TableCell>
                              </TableRow>
                           );
                        })}
                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>

            <Grid container mb={4}>
               <Grid item xs={12} sm={6}>
                  <Typography variant="h6" id="modal-modal-title" mb={2} className='tittle'>
                     Margen de Venta
                  </Typography>
                  <Typography variant="subtitle2">
                     Detalle Segun Devoluciones o Notas de Credito Generadas.
                  </Typography>
               </Grid>

            </Grid>

            <Paper sx={{ width: '100%', overflow: 'hidden', marginBottom: '50px' }}>
               <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                     <TableHead>
                        <TableRow>
                           <TableCell>GR</TableCell>
                           <TableCell>Codigo</TableCell>
                           <TableCell>Descripcion</TableCell>
                           <TableCell>Fisico Compras</TableCell>
                           <TableCell>Valorizado Compras</TableCell>
                           <TableCell>Fisico Ventas</TableCell>
                           <TableCell>Valorizado Ventas</TableCell>
                           <TableCell>Costo Venta</TableCell>
                           <TableCell>Margen</TableCell>
                           <TableCell>%MV</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData1.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.codigo}>
                                 {columns1.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                              </TableRow>
                           );
                        })}
                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsData1.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>

            <Grid container mb={4}>
               <Grid item xs={4} sm={4}>
                  <Typography variant="h6" id="modal-modal-title" mb={2} className='tittle'>
                     Indicador del Mes
                  </Typography>
               </Grid>

               <Grid item xs={3} sm={3} textAlign="end" sx={{ marginRight: '20px' }}>
                  <Typography variant="h6" id="modal-modal-title" mb={2} className='tittle'>
                     -491,32 %
                  </Typography>
               </Grid>

               <Grid item xs={4} sm={4} className='columnsSquar' >

                  <Grid style={{ display: 'flex' }}>
                     <ArrowSquareIcon style={{ fontSize: '1.5rem', color: 'green' }} />
                     <Typography variant="subtitle2" id="modal-modal-title" mb={2}>
                        Muy Bueno
                     </Typography>
                  </Grid>

                  <Grid style={{ display: 'flex' }} >
                     <ArrowSquareIcon style={{ fontSize: '1.5rem', color: 'blue' }} />
                     <Typography variant="subtitle2" id="modal-modal-title" mb={2}>
                        Bueno
                     </Typography>
                  </Grid>

                  <Grid style={{ display: 'flex' }} >
                     <ArrowSquareIcon style={{ fontSize: '1.5rem', color: 'yellow' }} />
                     <Typography variant="subtitle2" id="modal-modal-title" mb={2}>
                        Regular
                     </Typography>
                  </Grid>

                  <Grid style={{ display: 'flex' }} >
                     <ArrowSquareIcon style={{ fontSize: '1.5rem', color: 'pink' }} />
                     <Typography variant="subtitle2" id="modal-modal-title" mb={2}>
                        Malo
                     </Typography>
                  </Grid>

                  <Grid style={{ display: 'flex' }} >
                     <ArrowSquareIcon style={{ fontSize: '1.5rem', color: 'red' }} />
                     <Typography variant="subtitle2" id="modal-modal-title" mb={2}>
                        Muy Malo
                     </Typography>
                  </Grid>

               </Grid>

            </Grid>

         </Protected>
      </>
   );
}

