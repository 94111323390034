import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { ArrowRightIcon } from "@/toolbox/constants/icons";
import { VALIDATORS } from "@/toolbox/helpers/validation-rules";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, TablePagination, TableFooter, } from '@mui/material';
import { Input } from '@components/common/Input';
import { Props } from "./SelectionDates.type";
import BusinessIcon from '@mui/icons-material/Business';
import { InputRef } from '@/components/common/Input/InputInterfaces';
import './SelectionDates_style.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from 'react-router-dom';
import { ROUTE_EV_SALES_DATES, ROUTE_GC_FAMILIES, ROUTE_GC_PRICE_LIST } from '@/toolbox/constants/route-map';

// Para la tabla de la vista principal
interface Column {
   id:  'fecha' | 'folio' | 'tipo' | 'rut' | 'razonSocial' | 'total' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columnsPrincipal: readonly Column[] = [
   { id: 'fecha', label: 'Fecha', minWidth: 100 },
   { id: 'folio', label: 'Tipo', minWidth: 100 },
   { id: 'tipo', label: 'Folio', minWidth: 100 },
   { id: 'rut', label: 'Rut', minWidth: 100 },
   { id: 'razonSocial', label: 'Razón Social', minWidth: 100 },
   { id: 'total', label: 'Total', minWidth: 100 }   
];

interface Data {
   fecha: string;
   folio: string;
   tipo: string;
   rut: string;
   razonSocial: string;
   total: string;
}
function createData(
   fecha: string,
   folio: string,
   tipo: string,
   rut: string,
   razonSocial: string,
   total: string,
) :Data{
   return {  fecha, folio, tipo, rut, razonSocial, total };
}

const rowsListado = [
   createData('2022-03-01', '7647', '39', '6666666-9', 'A.C. BADIACHILE', '$5000'),
   createData('2021-03-01', '7646', '39', '6666666-9', 'Otra razón', '$500'),
   createData('2022-06-01', '7645', '39', '6666666-9', 'JiJI razón', '$5800'),
   createData('2022-04-01', '7644', '39', '6666666-9', 'Me olvidé la razón', '$5000'),
   createData('2022-02-01', '7643', '39', '6666666-9', 'Sin razón', '$5000'),
]

export const SelectionDates: React.FC<Props> = (props: Props): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [openEnviador, setOpenEnviador] = useState(false);
   const [openEmail, setOpenEmail] = useState(false);
   const [openStateSll, setOpenStateSll] = useState(false);
   const [openVoucher, setOpenVoucher] = useState(false);

   //Modal Enviador masivo
   const handleOpenEnviador = () => setOpenEnviador(true);
   const handleCloseEnviador = () => setOpenEnviador(false);
   //Modal Email
   const handleOpenEmail = () => setOpenEmail(true);
   const handleCloseEmail = () => setOpenEmail(false);
   //Modal Enviar Sll
   const handleOpenStateSll = () => setOpenStateSll(true);
   const handleCloseStateSll = () => setOpenStateSll(false);
   //Modal Voucher
   const handleOpenVoucher = () => setOpenVoucher(true);
   const handleCloseVoucher = () => setOpenVoucher(false);

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 750,
      // height: 600,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const rules = useMemo(() => ({
      rut_proveedor: [
         VALIDATORS.REQUIRED,
      ],
      numero_contrato: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      titulo: [
         VALIDATORS.REQUIRED
      ],
      descripcion: [
         VALIDATORS.REQUIRED,
      ]

   }), []);
   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'rut_proveedot':
            props.setvalueContrato(prev => ({ ...prev, rut_proveedot: value }));
            break;
         case 'numero_contrato':
            props.setvalueContrato(prev => ({ ...prev, numero_contrato: value }))
            break;
         case 'titulo':
            props.setvalueContrato(prev => ({ ...prev, titulo: value }))
            break;
         case 'descripcion':
            props.setvalueContrato(prev => ({ ...prev, descripcion: value }));
            break;

         default:
            break;
      }
   };

   function createData(
      name: string,
      cantidad: number,
      exento: number,
      especifico: number,
      valorNeto: number,
      iva: number,
      total: number,
   ) {
      return { name, cantidad, exento, especifico, valorNeto, iva, total };
   }

   const rows = [
      createData('Factura Electrónica', 13, 0, 0, 800.323, 152.000, 12462.0),
      createData('Factura Exenta Electrónica ', 1, 315.530, 0, 0, 0, 10002.0),
      createData('Boleta Electrónica', 13, 0, 0, 76546.02, 1400.2, 21292.56),
   ];

   // const handleInputBlur = (event: any) => {
   //    const newVal = (event.target.value).trim();
   //    const name = event.target.name;
   //    props.setvalueContrato(prev => ({ ...prev, [name]: newVal }));
   // }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }
   return (
      <Protected>
         <Container maxWidth='xl'>

            <Grid container mb={4}>
               <Grid item xs={12} sm={6}>
                  <Typography variant="h6" id="modal-modal-title" mb={2} className='tittle'>
                     LIBRO DE VENTAS POR CLIENTES
                  </Typography>
                  <Typography variant="subtitle2">
                     Sucursal | Todas
                  </Typography>
                  <Typography variant="subtitle2">
                     Desde | 2022-02-28
                  </Typography>
                  <Typography variant="subtitle2">
                     Hasta | 2022-03-05
                  </Typography>
               </Grid>
               <Grid item xs={12} sm={4} textAlign="end">
                  <Button>
                     Exportar
                  </Button>
               </Grid>
               <Grid item xs={12} sm={2} textAlign="end">
                  <Button variant="outlined" component={Link} to={ROUTE_EV_SALES_DATES} startIcon={<ArrowBackIosIcon />}>
                     Volver
                  </Button>
               </Grid>
            </Grid>

            <Grid xs={12}>
               <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  <TableContainer component={Paper}>
                     <Table aria-label="simple table">
                        <TableHead>
                           <TableRow>
                              <TableCell colSpan={8}>
                                 Resumen Por tipo de Documento
                              </TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell>Tipo de Documentos</TableCell>
                              <TableCell>Cantidad</TableCell>
                              <TableCell>Exento</TableCell>
                              <TableCell>Específico</TableCell>
                              <TableCell>Valor Neto</TableCell>
                              <TableCell>IVA</TableCell>
                              <TableCell>Total</TableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {rows.map((row) => (
                              <TableRow
                                 key={row.name}
                                 sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                 <TableCell component="th" scope="row">
                                    {row.name}
                                 </TableCell>
                                 <TableCell >{row.cantidad}</TableCell>
                                 <TableCell >{row.exento}</TableCell>
                                 <TableCell >{row.especifico}</TableCell>
                                 <TableCell >{row.valorNeto}</TableCell>
                                 <TableCell >{row.iva}</TableCell>
                                 <TableCell >{row.total}</TableCell>
                              </TableRow>
                           ))}
                        </TableBody>
                        <TableFooter>
                           <TableRow>
                              <TableCell align="right"> Totales</TableCell>
                              <TableCell > 27</TableCell>
                              <TableCell > $315.530.000</TableCell>
                              <TableCell > $0</TableCell>
                              <TableCell > $745677</TableCell>
                              <TableCell > $14.00876</TableCell>
                              <TableCell > $44.00876</TableCell>
                           </TableRow>
                        </TableFooter>
                     </Table>
                  </TableContainer>
               </Paper>
            </Grid>

            <Grid xs={12} mt={5}>
               <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  <TableContainer component={Paper}>
                     <Table aria-label="simple table">
                        <TableHead>
                           <TableRow>
                              <TableCell colSpan={6}>Listado de Documentos ingresados</TableCell>
                           </TableRow>
                           <TableRow>                              
                              <TableCell>Fecha</TableCell>
                              <TableCell>Folio</TableCell>
                              <TableCell>Tipo</TableCell>                                                           
                              <TableCell>Rut</TableCell>
                              <TableCell>Razón Social</TableCell>
                              <TableCell>Total</TableCell>                             
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {rowsListado.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                              return (
                                 <TableRow hover role="checkbox" tabIndex={-1} key={row.fecha}>
                                    {columnsPrincipal.map((column) => {
                                       const value = row[column.id];
                                       return (
                                          <TableCell key={column.id} align={column.align}>
                                             {column.format && typeof value === 'number'
                                                ? column.format(value)
                                                : value}
                                          </TableCell>
                                       );
                                    })}                           
                                 </TableRow>
                              );
                           })}
                        </TableBody>
                     </Table>
                  </TableContainer>
                  <TablePagination
                     rowsPerPageOptions={[10, 25, 100]}
                     component="div"
                     count={rowsListado.length}
                     rowsPerPage={rowsPerPage}
                     page={page}
                     onPageChange={handleChangePage}
                     onRowsPerPageChange={handleChangeRowsPerPage}
                  />
               </Paper>
            </Grid>


         </Container>
      </Protected>
   );
}