import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, } from '@mui/material';
import { ArrowBackIcon, ArrowDownIcon, ArrowRightIcon, MoreIcon } from "@toolbox/constants/icons";
import { Props } from './FormLaborData.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import Entity from "@assets/img/entity.png";
import './style.sass'
import "./FormLaborData_style.css";
import BadgeIcon from '@mui/icons-material/Badge';

export const FormLaborData: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false)
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const [estado, setEstado] = useState<any>({ rut: false, nombre_rz: false })

   const [valueConsiderable, setValueConsiderable] = React.useState('Si');
   const [valueTipoAdquisicion, setValueTipoAvalueTipoAdquisicion] = React.useState('Activo Propio');


   const handleChangeConsiderable = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueConsiderable((event.target as HTMLInputElement).value);
   };

   const handleTipoAvalueTipoAdquisicion = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueTipoAvalueTipoAdquisicion((event.target as HTMLInputElement).value);
   };


   const cargoValue = [
      { label: 'Cargo  1' },
      { label: 'Cargo  2' },
      { label: 'Cargo  3' },
      { label: 'Cargo  4' },
      { label: 'Cargo  5' },
   ]

   const departamentoValue = [
      { label: 'FINANZAS' },
      { label: 'RECURSOS HUMANOS' },
      { label: 'CONTABILIDAD' }
   ]
   const tipoContrato = [
      { label: 'Indefinido' },
      { label: 'Plazo Fijo' }
   ]
   const estadoValue = [
      { label: 'Finiquitado/Cesado' },
      { label: 'VIgente/Activo' }
   ]
   const subsidioEstado = [
      { label: 'Si tiene subsidio' },
      { label: 'No tiene subsidio' }
   ]
   const comunaPrestacion = [
      { label: 'Algarrobo' },
      { label: 'Alburre' },
      { label: 'Angol' },
      { label: 'otro' }
   ]
   const tipoGratificacion = [
      { label: '25% MENSUAL CON TOPE' },
      { label: '25% MENSUAL SIN TOPE' },
      { label: 'ANUAL' },
      { label: 'SIN(NO APLICA)' }
   ]
   const tipoCesantia = [
      { label: 'NO' },
      { label: 'SI' },
   ]
   const tipoTrabajador = [
      { label: 'Activo' },
      { label: 'Pensionado y Cotiza' },
      { label: 'Pensionado y No Cotiza' },
   ]
   const tipoDiscapacidad = [
      { label: 'NO' },
      { label: 'Discapacidad Certificada por la Compin' },
      { label: 'Asignatorio Pension por Invalidez Total' },
   ]
   const centroCosto = [
      { label: 'Operaciones' },
      { label: 'Ventas' },
      { label: 'Desarrollo' },
   ]
   const rotPrivado = [
      { label: 'SI' },
      { label: 'NO' },
   ]
   const certificadoRenta = [
      { label: 'Honorario' },
      { label: 'Participación' },
      { label: 'Sueldo' },
   ]


   const rules = useMemo(() => ({
      unidadMedida: [
         VALIDATORS.REQUIRED,
      ],
      stockMinimo: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      stockMaximo: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      ubicacionFisica: [
         VALIDATORS.REQUIRED,
      ],

   }), []);


   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'unidadMedida':
            props.setvalueInventario(prev => ({ ...prev, unidadMedida: value }));
            break;
         case 'stockMinimo':
            props.setvalueInventario(prev => ({ ...prev, stockMinimo: value }))
            break;
         case 'compra_activo':
            props.setvalueInventario(prev => ({ ...prev, compra_activo: value }))
            break;
         case 'revalorizacion':
            props.setvalueInventario(prev => ({ ...prev, revalorizacion: value }));
            break;
         case 'depreciacion':
            props.setvalueInventario(prev => ({ ...prev, depreciacion: value }));
            break;
         case 'readecuaciones':
            props.setvalueInventario(prev => ({ ...prev, readecuaciones: value }));
            break;
         case 'monto_credito':
            props.setvalueInventario(prev => ({ ...prev, monto_credito: value }));
            break;
         case 'credito_adquisicion':
            props.setvalueInventario(prev => ({ ...prev, credito_adquisicion: value }));
            break;
         case 'revalorizable':
            props.setvalueInventario(prev => ({ ...prev, revalorizable: value }));
            break;
         default:
            break;
      }
   };
   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      props.setvalueInventario(prev => ({ ...prev, [name]: newVal }));
   }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
      props.handleNext();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }

   return (
      <Container maxWidth="xl">
         <form >
            <Grid container spacing={3} className={`e-mb-lg`}>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={cargoValue}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Cargo" />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={departamentoValue}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Departamento" />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={tipoContrato}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Tipo Contrato" />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={estadoValue}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Estado" />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={subsidioEstado}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Subsidio trabajador Joven" />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={comunaPrestacion}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Comuna de prestacion de servicios" />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="stockMinimo"
                     type='text'
                     placeholder="Sueldo Pactado"
                     value={props.valueInventario.stockMinimo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.stockMinimo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={tipoGratificacion}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Tipo Gratificación" />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={tipoCesantia}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Tipo Cesantía" />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={tipoTrabajador}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Tipo Trabajador" />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={tipoDiscapacidad}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Persona con discapacidad/pensionado por invalidez" />}
                  />
               </Grid>
               {/* <Grid item container mb={3} xs={12} >
                  <Typography variant="subtitle1" >
                     Bonos
                  </Typography>
               </Grid> */}
               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="stockMinimo"
                     type='text'
                     placeholder="Colación"
                     value={props.valueInventario.stockMinimo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.stockMinimo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="stockMinimo"
                     type='text'
                     placeholder="Movilización"
                     value={props.valueInventario.stockMinimo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.stockMinimo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={centroCosto}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Centro Costo" />}
                  />
               </Grid>
               {/* <Grid item container mb={3} xs={12} >
                  <Typography variant="subtitle1" >
                     Perfil del Empleado
                  </Typography>
               </Grid> */}
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={rotPrivado}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Rot Privado" />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="stockMinimo"
                     type='text'
                     placeholder="N° tarjeta control horario"
                     value={props.valueInventario.stockMinimo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.stockMinimo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={rotPrivado}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Sujeto a control horario" />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={certificadoRenta}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Certificado Renta" />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="stockMinimo"
                     type='text'
                     placeholder="Años otros empleadores"
                     value={props.valueInventario.stockMinimo}
                     onChange={handleInput}
                     // backgroundColor="#F3F3F3"
                     rules={rules.stockMinimo}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid>
               
               <Grid item container
                  xs={12}
                  justifyContent='right'
               >
                  <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                        className='btn_save'
                        endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>Siguiente</span>
                        }
                     </Button>

                  </Grid>
               </Grid>
               {/* <Grid item container
                  xs={12}
                 justifyContent='right'
                 >
                     <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                        className='btn_save2'
                        endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>Agregar</span>
                        }
                     </Button>

                     </Grid>
               </Grid> */}
            </Grid>
         </form>
      </Container>
   );
}
