import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { ArrowRightIcon } from "@/toolbox/constants/icons";
import { VALIDATORS } from "@/toolbox/helpers/validation-rules";
import { Grid, Container, Button, Modal, Box, Typography, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, TablePagination, TableFooter, } from '@mui/material';
import { Input } from '@components/common/Input';
import { Props } from "./MonthAndPeriod.type";
import { InputRef } from '@/components/common/Input/InputInterfaces';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import './MonthAndPeriod_style.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EMobiledataIcon from '@mui/icons-material/EMobiledata';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { Link } from 'react-router-dom';
import { ROUTE_EV_SALES_DATES} from '@/toolbox/constants/route-map';
import { DataModalSendMassive } from './ModalSendMassive/DataModalSendMassive';
import { DataModalEmail } from './ModalEmail/DataModalEmail';
import { DataModalStateSll } from './ModalStateSll/ModalStateSll';
import { DataModalVoucher } from './ModalVoucher/ModalVoucher';

// Para la tabla de la vista principal
interface Column {
   id: 'codigo' | 'fecha' | 'tipo' | 'folio' | 're' | 'rut' | 'razonSocial' | 'total' | 'ref' | 'cta';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columnsPrincipal: readonly Column[] = [
   { id: 'codigo', label: 'Codigo', minWidth: 100 },
   { id: 'fecha', label: 'Fecha', minWidth: 100 },
   { id: 'tipo', label: 'Tipo', minWidth: 100 },
   { id: 'folio', label: 'Folio', minWidth: 100 },
   { id: 're', label: 'Re', minWidth: 100 },
   { id: 'rut', label: 'Rut', minWidth: 100 },
   { id: 'razonSocial', label: 'Razón Social', minWidth: 100 },
   { id: 'total', label: 'Total', minWidth: 100 },
   { id: 'ref', label: 'Ref', minWidth: 100 },
   { id: 'cta', label: 'Cta', minWidth: 100 },
];

interface Data {
   codigo: string;
   fecha: string;
   tipo: string;
   folio: string;
   re: string;
   rut: string;
   razonSocial: string;
   total: string;
   ref: string;
   cta: string;
}
function createData(
   codigo: string,
   fecha: string,
   tipo: string,
   folio: string,
   re: string,
   rut: string,
   razonSocial: string,
   total: string,
   ref: string,
   cta: string
) {
   return { codigo, fecha, tipo, folio, re, rut, razonSocial, total, ref, cta };
}

const rowsListado = [
   createData('1', '2022-03-01', '39', '7647', '', '6666666-9', 'sin razón', '$5000', '', 'usuario'),
   createData('2', '2021-03-01', '39', '7646', '', '6666666-9', 'sin razón', '$500', '', 'usuario'),
   createData('3', '2022-06-01', '39', '7645', '', '6666666-9', 'sin razón', '$5800', '', 'usuario'),
   createData('4', '2022-04-01', '39', '7644', '', '6666666-9', 'sin razón', '$5000', '', 'usuario'),
   createData('5', '2022-02-01', '39', '7643', '', '6666666-9', 'sin razón', '$5000', '', 'usuario'),
]

export const MonthAndPeriod: React.FC<Props> = (props: Props): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [openEnviador, setOpenEnviador] = useState(false);
   const [openEmail, setOpenEmail] = useState(false);
   const [openStateSll, setOpenStateSll] = useState(false);
   const [openVoucher, setOpenVoucher] = useState(false);

   //Modal Enviador masivo
   const handleOpenEnviador = () => setOpenEnviador(true);
   const handleCloseEnviador = () => setOpenEnviador(false);
   //Modal Email
   const handleOpenEmail = () => setOpenEmail(true);
   const handleCloseEmail = () => setOpenEmail(false);
   //Modal Enviar Sll
   const handleOpenStateSll = () => setOpenStateSll(true);
   const handleCloseStateSll = () => setOpenStateSll(false);
   //Modal Voucher
   const handleOpenVoucher = () => setOpenVoucher(true);
   const handleCloseVoucher = () => setOpenVoucher(false);

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 750,
      // height: 600,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const rules = useMemo(() => ({
      rut_proveedor: [
         VALIDATORS.REQUIRED,
      ],
      numero_contrato: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      titulo: [
         VALIDATORS.REQUIRED
      ],
      descripcion: [
         VALIDATORS.REQUIRED,
      ]

   }), []);
   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'rut_proveedot':
            props.setvalueContrato(prev => ({ ...prev, rut_proveedot: value }));
            break;
         case 'numero_contrato':
            props.setvalueContrato(prev => ({ ...prev, numero_contrato: value }))
            break;
         case 'titulo':
            props.setvalueContrato(prev => ({ ...prev, titulo: value }))
            break;
         case 'descripcion':
            props.setvalueContrato(prev => ({ ...prev, descripcion: value }));
            break;

         default:
            break;
      }
   };

   function createData(
      name: string,
      cantidad: number,
      exento: number,
      especifico: number,
      valorNeto: number,
      iva: number,
      total: number,
   ) {
      return { name, cantidad, exento, especifico, valorNeto, iva, total };
   }

   const rows = [
      createData('Factura Electrónica', 13, 0, 0, 800.323, 152.000, 12462.0),
      createData('Factura Exenta Electrónica ', 1, 315.530, 0, 0, 0, 10002.0),
      createData('Boleta Electrónica', 13, 0, 0, 76546.02, 1400.2, 21292.56),
   ];

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }
   return (
      <Protected>
         <Container maxWidth='xl'>

            <Grid container mb={4}>
               <Grid item xs={12} sm={6}>
                  <Typography variant="h6" id="modal-modal-title" mb={2} className='tittle'>
                     LIBRO DE VENTAS
                  </Typography>
                  <Typography variant="subtitle2">
                     Periodo: 2022 | Mes: 03 Estado de Libro : Abierto
                  </Typography>
               </Grid>
               <Grid item xs={12} sm={4} textAlign="end">
                  <Button>
                     Exportar
                  </Button>
                  {'|'}
                  <Button>
                     Exportar 2
                  </Button>
               </Grid>
               <Grid item xs={12} sm={2} textAlign="end">
                  <Button variant="outlined" component={Link} to={ROUTE_EV_SALES_DATES} startIcon={<ArrowBackIosIcon />}>
                     Volver
                  </Button>
               </Grid>
            </Grid>
            <Grid container spacing={3} className={`e-mb-lg`} >
               <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                     <TableHead>
                        <TableRow>
                           <TableCell>Tipo de Documentos</TableCell>
                           <TableCell>Cantidad</TableCell>
                           <TableCell>Exento</TableCell>
                           <TableCell>Específico</TableCell>
                           <TableCell>Valor Neto</TableCell>
                           <TableCell>IVA</TableCell>
                           <TableCell>Total</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rows.map((row) => (
                           <TableRow
                              key={row.name}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                           >
                              <TableCell component="th" scope="row">
                                 {row.name}
                              </TableCell>
                              <TableCell >{row.cantidad}</TableCell>
                              <TableCell >{row.exento}</TableCell>
                              <TableCell >{row.especifico}</TableCell>
                              <TableCell >{row.valorNeto}</TableCell>
                              <TableCell >{row.iva}</TableCell>
                              <TableCell >{row.total}</TableCell>
                           </TableRow>
                        ))}
                     </TableBody>
                     <TableFooter>
                        <TableRow>
                           <TableCell align="right"> Totales</TableCell>
                           <TableCell > 27</TableCell>
                           <TableCell > $315.530.000</TableCell>
                           <TableCell > $0</TableCell>
                           <TableCell > $745677</TableCell>
                           <TableCell > $14.00876</TableCell>
                           <TableCell > $44.00876</TableCell>
                        </TableRow>
                     </TableFooter>
                  </Table>
               </TableContainer>

               <Grid item container
                  xs={12}
                  justifyContent='right'
               >
                  <Grid item>
                     <Button
                        // type='submit'
                        variant='contained'
                        // onClick={handleSubmit}
                        onClick={handleOpenEnviador}
                        // disabled={loading}
                        // disableElevation
                        className='btn_save'
                     >
                        Enviar masivo
                     </Button>

                  </Grid>
               </Grid>
            </Grid>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
               <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                     <TableHead>
                        <TableRow>
                           <TableCell>Codigo</TableCell>
                           <TableCell>Fecha</TableCell>
                           <TableCell>Tipo</TableCell>
                           <TableCell>Folio</TableCell>
                           <TableCell>Re</TableCell>
                           <TableCell>Rut</TableCell>
                           <TableCell>Razón Social</TableCell>
                           <TableCell>Total</TableCell>
                           <TableCell>REF</TableCell>
                           <TableCell>Cta</TableCell>
                           <TableCell align="center">Opc</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsListado.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.codigo}>
                                 {columnsPrincipal.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}


                                 <TableCell>
                                    <div className='sepaEle' style={{ display: "flex", justifyContent: "space-evenly" }}>
                                       <Tooltip title='Enviar por Email'>
                                          <IconButton onClick={handleOpenEmail}>
                                             <ForwardToInboxIcon />
                                          </IconButton>
                                       </Tooltip>
                                       <Tooltip title='Estado Sll'>
                                          <IconButton onClick={handleOpenStateSll} >
                                             <EMobiledataIcon />
                                          </IconButton>
                                       </Tooltip>
                                       <Tooltip title='Notificar Electrónicamente'>
                                          <IconButton aria-label="delete">
                                             <NotificationsActiveIcon />
                                          </IconButton>
                                       </Tooltip>
                                       <Tooltip title='Comprobantes de Pago y Anulaciones'>
                                          <IconButton onClick={handleOpenVoucher}>
                                             <ReceiptIcon />
                                          </IconButton>
                                       </Tooltip>
                                    </div>
                                 </TableCell>
                              </TableRow>
                           );
                        })}
                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsListado.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>

         </Container>
         <Modal
            open={openEnviador}
            onClose={handleCloseEnviador}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataModalSendMassive entity={undefined} />
            </Box>
         </Modal>
         <Modal
            open={openEmail}
            onClose={handleCloseEmail}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataModalEmail entity={undefined} />
            </Box>
         </Modal>
         <Modal
            open={openStateSll}
            onClose={handleCloseStateSll}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataModalStateSll entity={undefined} />
            </Box>
         </Modal>
         <Modal
            open={openVoucher}
            onClose={handleCloseVoucher}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style} >
               <DataModalVoucher entity={undefined} />
            </Box>
         </Modal>
      </Protected>
   );
}