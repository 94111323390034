import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "./PaymentCommercial.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './PaymentCommercial_styles.css'
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import SearchIcon from '@mui/icons-material/Search';
import { LoadingButton } from "@mui/lab";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

interface Column {
   id: 'codigo' | 'nombre' | 'diasVencimiento'|'diasCobranza' | 'estado'|'Opstxt'| 'asignaCaja' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

const columns:readonly Column[] = [
   { id: 'codigo', label: 'Código', minWidth: 10 },
   { id: 'nombre', label: 'Nombre', minWidth: 50 },
   { id: 'diasVencimiento', label: 'Días Vencimiento', minWidth: 50 },
   { id: 'diasCobranza', label: 'Días Cobranza Pro Activa', minWidth: 50 },
   { id: 'estado', label: 'Estado', minWidth: 50 },
   { id: 'Opstxt', label: 'OPS txt', minWidth: 50 },
   { id: 'asignaCaja', label: 'Asigna Caja', minWidth: 50 },
   ];

interface Data {
   codigo: number;
   nombre: string;
   diasVencimiento: string;
   diasCobranza: string;
   estado: string;
   Opstxt: string;
   asignaCaja: string;
}

function createData(
   codigo: number,
   nombre: string,
   diasVencimiento: string,
   diasCobranza: string,
   estado: string,
   Opstxt: string,
   asignaCaja: string,

   ):Data {
   return { codigo, nombre, diasVencimiento, diasCobranza, estado, Opstxt, asignaCaja };
}

const rowsData = [
   createData(1, 'PRUEBA1', '2', '30', 'ACTIVO', 'OPS Txt', 'Inactiva'),
   createData(2, 'PRUEBA2', '2', '30', 'ACTIVO', 'OPS Txt', 'Inactiva'),
   createData(3, 'PRUEBA3', '2', '30', 'ACTIVO', 'OPS Txt', 'Inactiva'),
   createData(4, 'PRUEBA4', '2', '30', 'ACTIVO', 'OPS Txt', 'Inactiva'),
   createData(5, 'PRUEBA5', '2', '30', 'ACTIVO', 'OPS Txt', 'Inactiva'),
   createData(6, 'PRUEBA6', '2', '30', 'ACTIVO', 'OPS Txt', 'Inactiva'),
   createData(7, 'PRUEBA7', '2', '30', 'ACTIVO', 'OPS Txt', 'Inactiva'),
   createData(8, 'PRUEBA8', '2', '30', 'ACTIVO', 'OPS Txt', 'Inactiva'),
   createData(9, 'PRUEBA9', '2', '30', 'ACTIVO', 'OPS Txt', 'Inactiva'),
   createData(10, 'PRUEBA10', '2', '30', 'ACTIVO', 'OPS Txt', 'Inactiva'),

]


export const PaymentCommercial:React.FC<Props> = (props: Props): JSX.Element => {
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   // const [value, setValue] = useState('');
   const [value, setValue] = useState<Date | null>(new Date());
   const [loading, setLoading] = useState(false);
   const [pais, setPais] = useState('');
   const [estado, setEstado]= useState('');
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);
   const [open, setOpen] = useState(false);
   const [openRut, setOpenRut] = useState(false);
   const handleOpenRut = () => setOpenRut(true);
   const handleCloseRut = () => setOpenRut(false);

    const handleChangePais = (event: SelectChangeEvent) => {
      setPais(event.target.value);
    };
    const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
    };
   function handleClick() {
     setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const bancos= [
      { value: '1', label: 'Banco 1' },
      { value: '2', label: 'Banco 2' },
      { value: '3', label: 'Banco 3' },
      { value: '4', label: 'Banco 4' },
      { value: '5', label: 'Banco 5' },
      { value: '6', label: 'Banco 6' },

   ];
   const estados= [
      { value: '1', label: 'Activo' },
      { value: '2', label: 'Desactivado' },
   ]

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 720,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

    const formasPago = [
      { value: '1', label: 'Efectivo' },
      { value: '2', label: 'Cheque' },
      { value: '3', label: 'Transferencia' },
      { value: '4', label: 'Tarjeta' },
      { value: '5', label: 'Otro' },

    ]

    const transacciones = [
      { value: '1', label: 'Ingreso' },
      { value: '2', label: 'Egreso' },
      { value: '3', label: 'Otro' },
    ]


    return (
        <Protected>
        <Container maxWidth='xl'>
           <Grid container spacing={3}>
               <Grid item xs={12} md={12} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        MANTENEDOR FORMAS DE PAGO
                     </Typography>
                  </Box>
                  <Box>
                     {/* <Typography variant='subtitle2'  gutterBottom >
                     Resumen de mes de 1 - 2022
                     </Typography> */}
                  </Box>
                  
               </Grid>
                     
               <Grid item xs={12} sm={3} >
                  <TextField
                     label="Nombre"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
                  <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={formasPago}
                           renderInput={(params) => <TextField {...params} label="Motivo Bloqueo" />}
                  />
               </Grid>
               <Grid item xs={12} sm={3} >
                  <TextField
                     label="Dias Vencimiento"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>
               <Grid item xs={12} sm={3} >
                  <TextField
                     label="Dias Pro-Activo"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={estados}
                           renderInput={(params) => <TextField {...params} label="Estado" />}
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
                  <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={transacciones}
                           renderInput={(params) => <TextField {...params} label="Seleccione" />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Typography variant='subtitle2'  gutterBottom mt={1}>
                     *Banco Origen de la Transferencia
                  </Typography>
               </Grid>

               <Grid item xs={12} sm={3}>
                  <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={bancos}
                           renderInput={(params) => <TextField {...params} label="Bancos" />}
                  />
               </Grid>
               <Grid item xs={12} sm={3} >
               <FormGroup>
                  <FormControlLabel control={<Checkbox defaultChecked />} label="Condiciona Credito" />
               </FormGroup>
               </Grid>

               <Grid item xs={12} sm={3} >
               <FormGroup>
                  <FormControlLabel control={<Checkbox defaultChecked />} label="Woopy" />
               </FormGroup>
               </Grid>




               <Grid item container
                     xs={12}
                     mt={2}
                  justifyContent='right'
                  >
                        <Grid item>
                           <Button
                              type='submit'
                              variant='contained'
                              // onClick={handleSubmit}
                              // disabled={loading}
                              disableElevation
                              className='btn_save'
                           >
                                    <span>Agregar</span>
                           </Button>
                        </Grid>
               </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={2}>
               LISTADO FORMAS DE PAGO INGRESADAS
            </Typography>

            <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:440}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {columns.map((column) => (
                              <TableCell
                              
                                 key={column.id}
                                 align={column.align}
                                 style={{ minWidth: column.minWidth }}
                              >
                                 {column.label}
                              </TableCell>
                           ))}
                           <TableCell>
                              Historial
                           </TableCell>

                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.codigo}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                       <IconButton 
                                          >
                                             <ModeEditIcon 
                                          // onClick={handleOpen}
                                       />
                                       </IconButton>
                                 </TableCell>
                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
            </Paper>

         </Container>
   
     </Protected>
    );
   };

