import React, { useState } from "react";
import {
   Button,
   Grid,
   IconButton,
   InputBase,
   Modal,
   Paper,
   Typography,
} from "@mui/material";
// import { Input } from '@components/common/Input';
import { Props } from "./CategoryProducts.type";
import "./CategoryProducts_style.css";
import DeleteIcon from "@mui/icons-material/Delete";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import categoriasJson from "./categorias.json";
// import { Link } from 'react-router-dom';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
   ROUTE_RAPPI_PROVIDER,
   ROUTE_RAPPI_PRODUCTS,
} from "@/toolbox/constants/route-map";
import { Height } from "@mui/icons-material";

export const CategoryProducts: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const [open, setOpen] = useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);

   let categorias = categoriasJson;

   const [data, setData] = useState([]);
   const [form, setForm] = useState({
      id: "",
      nombre: "",
      direccion: "",
      tema: "",
      tipoModal: "",
   });

   return (
      // <Protected>
      <Grid container sx={{}}>
         <Grid
            xs={12}
            container
            item
            justifyContent={"space-between"}
            mb={3}
            sx={{
               background: "#2D71E8",
               marginBottom: "25px",
               alignContent: "center",
               height: "80px",
            }}
         >
            <Grid item xs={3} textAlign="left">
               <Button
                  variant="contained"
                  className="btn_back2"
                  sx={{ ml: 16, mt: 0.3 }}
               >
                  <ArrowBackIosIcon />
                  <Typography variant="subtitle2">Regresar</Typography>
               </Button>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="center">
               <Paper
                  component="form"
                  sx={{
                     p: "0px 4px",
                     display: "flex",
                     alignItems: "center",
                     width: 500,
                     border: "1.8px solid #2D71E8",
                     boxShadow: "none",
                  }}
               >
                  <InputBase
                     sx={{ ml: 1, flex: 1 }}
                     placeholder="Buscar Proveedores"
                     inputProps={{ "aria-label": "search google maps" }}
                  />
                  <IconButton
                     type="submit"
                     sx={{ p: "10px" }}
                     aria-label="search"
                     style={{ backgroundColor: "#fff", color: "#8d8d8d" }}
                  >
                     <SearchIcon />
                  </IconButton>
               </Paper>
            </Grid>
            <Grid
               item
               container
               xs={3}
               justifyContent="right"
               alignItems="center"
            >
               <Grid item mr={2}>
                  <Button variant="contained" className="btn_back3">
                     <Typography variant="subtitle2">Otra opción</Typography>
                  </Button>
               </Grid>
               {"|"}
               <Grid item ml={2}>
                  <Button
                     variant="contained"
                     color="success"
                     onClick={() => handleOpen()}
                     sx={{ marginRight: "35px" }}
                  >
                     <ShoppingCartIcon />{" "}
                     <Typography variant="subtitle2">{"0"}</Typography>
                  </Button>
               </Grid>
            </Grid>
         </Grid>
         <Grid item xs={12} textAlign="center" mb={1}>
            <Typography variant="h2" gutterBottom mb={1} className="textTitle2">
               Servicios dentro de nuestras soluciones ERP
            </Typography>
            {/*  <Typography
               variant="subtitle2"
               className="textTitle3"
               style={{ color: "#3F3F3F" }}
            >
               ¡Empecemos!
            </Typography>*/}
         </Grid>

         <Grid container item xs={11} sx={{ display: "flex", margin: "auto" }}>
            {categorias.map((categoriasCard, i) => {
               return (
                  <Grid
                     item
                     xs={12}
                     sm={4}
                     md={2}
                     p={5}
                     key={i}
                     ml={5}
                     component={Link}
                     to={ROUTE_RAPPI_PRODUCTS}
                  >
                     {/* <Grid>
                        <IconButton aria-label="delete" size="small">
                           <DeleteIcon fontSize="inherit"/>
                        </IconButton>
                        <Typography>Boton eliminar</Typography>
                     </Grid>*/}
                     <Card
                        key={i}
                        className={"card-container"}
                        sx={{ borderRadius: "10px" }}
                     >
                        <CardMedia
                           component="img"
                           // height="190"
                           image={categoriasCard.imagen}
                           alt="categorias"
                           // sx={{padding:'25px'}}
                           sx={{
                              padding: "5px",
                              margin: "auto",
                              background: `${categoriasCard.fondo}`,
                           }}
                        />
                     </Card>
                     <Typography
                        className="card-container__text"
                        textAlign={"center"}
                     >
                        {categoriasCard.descripcion}
                     </Typography>
                     <Typography
                        className="card-container__text2"
                        textAlign={"center"}
                     >
                        {categoriasCard.descripcion2}
                     </Typography>
                  </Grid>
               );
            })}
         </Grid>
         <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            className="modalCard"
         >
            <Grid container p={2} className="cardSell">
               <Grid
                  xs={12}
                  container
                  className="text"
                  item
                  justifyContent={"space-between"}
                  alignItems="left"
               >
                  <Typography className="textMain" text-align="start">
                     Tu canasta
                  </Typography>
                  <CloseIcon
                     onClick={() => handleClose()}
                     style={{ cursor: "pointer" }}
                  />
               </Grid>

               <Grid
                  xs={12}
                  item
                  justifyContent={"space-between"}
                  alignItems="left"
               >
                  <Button
                     startIcon={
                        <LocationOnIcon
                           sx={{ color: "#ff4d00" }}
                           className="ico"
                        />
                     }
                  >
                     <Typography
                        sx={{ color: "#ff4d00 " }}
                        className="ubication"
                     >
                        Ingresar mi ubicacion
                     </Typography>
                  </Button>
               </Grid>

               <Grid item xs={12} className="icoCard" alignContent="center">
                  <IconButton>
                     <AddShoppingCartIcon sx={{ fontSize: "50px" }} />
                  </IconButton>
               </Grid>

               <Grid
                  xs={12}
                  item
                  justifyContent={"space-between"}
                  alignItems="center"
               >
                  <Typography className="textbody" sx={{ color: "#3f3f3f " }}>
                     Aun no tienes productos en tu canasta
                  </Typography>
               </Grid>

               <Grid
                  xs={12}
                  item
                  justifyContent={"space-between"}
                  alignItems="center"
               >
                  <Button className="btnCard">Comenzar a comprar</Button>
               </Grid>
            </Grid>
         </Modal>
      </Grid>
      // </Protected>
   );
};
