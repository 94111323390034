import { Protected } from "@/components/layout/Protected";
import { Box, Stepper, InputAdornment, IconButton, Step, StepLabel, Button, CircularProgress, Grid, CssBaseline, Container, StepIconProps, Typography, Tooltip, FormControl } from '@mui/material'
import { useEffect, useState } from "react";
import {Props} from "./GcProductService.type"
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import InfoIcon from '@mui/icons-material/Info';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { FormWeb } from "./FormWeb/FormWeb";
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import Modal from '@mui/material/Modal';
import { DataModalColorTalla } from "./DataModalColorTalla";
import { DataModalProvider } from "./DataModalProvider";
import { DataModalSegment } from "./DataModalSegment";
import { FormDetall } from "./FormDetall/FormDetall";
import LanguageIcon from '@mui/icons-material/Language';
import InventoryIcon from '@mui/icons-material/Inventory';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PersonIcon from '@mui/icons-material/Person';
import { FormPrize } from "./FormPrize";
import { FormProvider } from "./FormProvider/FormProvider";
import { FormEspecial } from "./FormEspecial";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { AccountBalance, AccountBalanceWallet } from "@mui/icons-material";

const steps= [{name:'Detalle del Producto', icon:null},
{name:'Web', icon:null},
{name:'Precio', icon:null},
{name:'Proveedores', icon:null},
{name:'Especial', icon:null},
]

interface Column {
   id: 'CodigoProducto' | 'CodigoProveedor' |'Nombre' | 'Precio' | 'Inventario';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

const columns:readonly Column[] = [
   { id: 'CodigoProducto', label: 'Codigo Producto (SKU)', minWidth: 100 },
   { id: 'CodigoProveedor', label: 'Codigo Proveedor', minWidth: 100 },
   { id: 'Nombre', label: 'Nombre', minWidth: 100 },
   { id: 'Precio', label: 'Precio', minWidth: 100 },
   { id: 'Inventario', label: 'Inventario', minWidth: 100 },
   ];

interface Data {
   CodigoProducto: string;
   CodigoProveedor: string;
   Nombre: string;
   Precio: string;
   Inventario: string;
}

function createDataProducts(
   CodigoProducto: string,
   CodigoProveedor: string,
   Nombre: string,
   Precio: string,
   Inventario: string,
): Data {
   return { CodigoProducto, CodigoProveedor, Nombre, Precio, Inventario };
}

const rowsDataProducts = [
   createDataProducts('11524', '2144554', 'Nombre 1', '44.00', '55.00'),
]


export const GcProductService:React.FC<Props> = (props: Props): JSX.Element => {
    
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [error, setError] = useState(null);
    const [page , setPage]= useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [openColorTalla, setOpenColorTalla] = useState(false);
    const [openProvider, setOpenProvider] = useState(false);
    const [openSegmento, setOpenSegmento] = useState(false);

    const [loading, setLoading] = useState(false);

    const handleOpenColorTalla = () => setOpenColorTalla(true);
    const handleCloseColorTalla = () => setOpenColorTalla(false);

    const handleOpenProvider = () => setOpenProvider(true);
    const handleCloseProvider = () => setOpenProvider(false);

    const handleOpenSegmento = () => setOpenSegmento(true);
    const handleCloseSegmento = () => setOpenSegmento(false);
    
    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };
    const [valueDetall,setValueDetall]=useState({
      nombre_producto:'',
      codigo_producto:'',
      descripcion:'',
      linea_producto:'',
      codigo_proveedor:'',
      codigo_barra:'',
      familia_producto:'',
      subfamilia_producto:'',
      tipo_producto:'',
      impuesto_producto:'',
      estado_producto:'',
    });

    const [valueWeb,setValueWeb]=useState({
        estado_web:'',
        destacar_producto:'',
        marcar_nuevo:'',
        ruta_video:'',
        file_adjunto:'',
        fecha_liminte:'',
        ficha_producto:'',
    });

    const [valueProvider,setValueProvider]=useState({


    });

    const [valuePrice,setValuePrice]=useState({
      margen:'',
      precio_producto:'',
      precio_alternativo:'',
      costo:'',
      precio_oferta:'',
      fecha_oferta:'',
   });

   const [valueEspecial,setValueEspecial]=useState({
      nombre_producto_ingles:'',
      segmentacion:'',
      markup:'',
      origen:'',
      destino:'',
      marca:'',
   });
    const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 720,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

    useEffect(() => {
        if(props.entity !== undefined){
            setValueDetall(props.entity);
            setValueWeb(props.entity);
            setValuePrice(props.entity);
            setValueProvider(props.entity);
            setValueEspecial(props.entity);
        }
    }, []);

    const isStepSkipped = (step) => {
        return skipped.has(step);
     };

     function handleClick() {
      setLoading(true);
    }
 
     const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
           newSkipped = new Set(newSkipped.values());
           newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
     };
  
     const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
     };

     const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
          top: 22,
        },
        [`&.${stepConnectorClasses.active}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
              'linear-gradient( 95deg,#19c1d1 0%,#1976D2 50%,#1996d1 100%)',
          },
        },
        [`&.${stepConnectorClasses.completed}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
              'linear-gradient( 95deg,#19c1d1 0%,#1976D2 50%,#1996d1 100%)',
          },
        },
        [`& .${stepConnectorClasses.line}`]: {
          height: 3,
          border: 0,
          backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
          borderRadius: 1,
        },
      }));
  
      const ColorlibStepIconRoot = styled('div')<{
        ownerState: { completed?: boolean; active?: boolean };
      }>(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
          backgroundImage:
            'linear-gradient( 136deg, #1976D2 0%, #1996d1 50%, #19c1d1 100%)',
          boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
          backgroundImage:
            'linear-gradient( 136deg, #1976D2 0%, #1996d1 50%, #19c1d1 100%)',
        }),
      }));


      function ColorlibStepIcon(props: StepIconProps) {
        const { active, completed, className } = props;
  
        const icons: { [index: string]: React.ReactElement } = {
          1: <AssignmentIcon />,
          2: <LanguageIcon />,
          3: <MonetizationOnIcon />,
          4: <PersonIcon />,
          5: <InfoIcon />,
        };
  
        return (
          <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
          </ColorlibStepIconRoot>
        );
      }

      const mostrarFormulario = () => {
        if (activeStep === 0) {
           return (
              <FormDetall
                 handleNext={handleNext}
                 valueEntry={valueDetall}
                 setvalueEntry={setValueDetall}
              />
           )
        }
        else if (activeStep === 1) {
           return (
            <FormWeb
            handleNext={handleNext}
            valueEntry={valueWeb}
            setvalueEntry={setValueWeb}
            />
           )
        }
        else if (activeStep === 2) {
           return (
            <FormPrize
            handleNext={handleNext}
            valueEntry={valuePrice}
            setvalueEntry={setValuePrice}
            />
           )
        }
        else if (activeStep === 3) {
         return (
          <FormProvider
          handleNext={handleNext}
          valueEntry={valueProvider}
          setvalueEntry={setValueProvider}
          />
         )
      }
      else if (activeStep === 4) {
         return (
          <FormEspecial
          handleNext={handleNext}
          valueEntry={valueEspecial}
          setvalueEntry={setValueEspecial}
          />
         )
      }
     }
    return (
        <Protected>
        <Container maxWidth='xl'>
           <Typography variant="h6" gutterBottom mb={3} className="tittle">
               MAESTRO PRODUCTOS Y SERVICIOS
             </Typography>
           <Grid container flexDirection='row' spacing={2}>
                 <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                       <Box>
                          <Box sx={{ mb: 2 }}>
                             <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
                                {steps.map((label, index) => {
                                   return (
                                      <Step key={index}>
                                         <StepLabel StepIconComponent={ColorlibStepIcon} error={error === `Usuario${index}noencontrado` ? true : false}>{label.name}</StepLabel>
                                      </Step>
                                   );
                                })}
                             </Stepper>
                          </Box>
                          {activeStep === steps.length ? (
                             <div>AGREGADO</div>
                             //VALIDAR QUE EL ENVIO DE DATOS
                             // history.push(ROUTE_ACCOUNT)
                          ) : (
                             mostrarFormulario()
                          )}
                       </Box>
                    </Grid>
                 </Grid>
           </Grid>
           
           <div className='bg-tittle'>
                        <Typography 
                        mt={3} 
                        variant="h6"
                        align='center'
                        className='tittle-text'
                        >LISTADO DE PRODUCTOS Y SERVICIOS
                        </Typography>
         </div>
         </Container>

         <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:440}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           <TableCell>Codigo Producto (SKU) </TableCell>
                           <TableCell>Codigo Proveedor</TableCell>
                           <TableCell>Nombre</TableCell>
                           <TableCell>Precio</TableCell>
                           <TableCell>Inventario</TableCell>
                           <TableCell>Estado</TableCell>
                           <TableCell>Opciones</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsDataProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.CodigoProducto}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                    <IconButton 
                                             >
                                                <CheckCircleIcon
                                          />
                                    </IconButton>
                                 </TableCell>

                                 <TableCell >
                                    <Grid container direction="row" justifyContent="space-evenly">
                                      
                                       <Grid item xs={4}>
                                          <LoadingButton
                                             color="warning"
                                             onClick={handleClick}
                                             
                                             loadingPosition="start"
                                             startIcon={<EditIcon />}
                                             variant="contained"
                                             className="btn-icon"
                                             >
                                             Edit
                                             </LoadingButton>
                                       </Grid>
                                       <Grid item xs={4}>
                                          <LoadingButton
                                             color="warning"
                                             onClick={handleClick}
                                             
                                             loadingPosition="start"
                                             startIcon={<AssignmentIcon />}
                                             variant="contained"
                                             className="btn-icon"
                                             >
                                             Fich
                                             </LoadingButton>
                                       </Grid>
                                       <Grid item xs={4}>
                                          <LoadingButton
                                             color="warning"
                                             onClick={handleOpenProvider}
                                             loadingPosition="start"
                                             startIcon={<DirectionsCarFilledIcon />}
                                             variant="contained"
                                             className="btn-icon"
                                             >
                                             Prov
                                             </LoadingButton>
                                       </Grid>
                                       <Grid item xs={4} mt={1} >
                                          <LoadingButton
                                             color="warning"
                                             onClick={handleOpenSegmento}
                                             
                                             loadingPosition="start"
                                             startIcon={<AccountBalanceWalletIcon />}
                                             variant="contained"
                                             className="btn-icon"
                                             >
                                             Seg
                                             </LoadingButton>
                                       </Grid>
                                        <Grid item xs={4} mt={1}>
                                          <LoadingButton
                                             color="warning"
                                             onClick={handleOpenColorTalla}
                                             
                                             loadingPosition="start"
                                             startIcon={<EditIcon />}
                                             variant="contained"
                                             className="btn-icon"
                                             >
                                             Col/Tal
                                             </LoadingButton>
                                       </Grid>
                                    </Grid>
                                 </TableCell>
                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsDataProducts.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
            </Paper>
            <Modal
                  open={openColorTalla}
                  onClose={handleCloseColorTalla}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <DataModalColorTalla entity={undefined}/>
                  </Box>
            </Modal>
            <Modal
                  open={openProvider}
                  onClose={handleCloseProvider}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <DataModalProvider entity={undefined}/>
                  </Box>
            </Modal>
            <Modal
                  open={openSegmento}
                  onClose={handleCloseSegmento}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                     <DataModalSegment entity={undefined}/>
                  </Box>
            </Modal>
     </Protected>

    );
};

