import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import cervezas from './cervezas.json';
import gaseosas from './gaseosas.json';
import ofertas from './ofertas.json';

import './AllProducts_style.css';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import { Grid, Typography } from '@mui/material';

export default class CarouselProvider extends Component {
   render() {
      var settings = {
         dots: false,
         infinite: false,
         speed: 500,
         slidesToScroll: 5,
         slidesToShow: 5,
         arrows: false,
         useCSS:true
         
      };
      return (
         < >
            {/* OFERTAS */}
            <Grid container>
               <Typography variant="h2" textAlign={'left'} className="textSubTitle">Ofertas</Typography>
               <Grid item xs={12} mb={6}>
                  <Slider {...settings} >
                     {ofertas.map((ofertasCard, i) => (
                        <Grid container spacing={2} mr={0.1} mt={1} key={i}>
                           <Grid item >
                              <Card className={"card-contenedor"} >
                                 <CardMedia
                                    component="img"
                                    image={ofertasCard.imagen}
                                    alt="categorias"
                                    className='card-contenedor__img'
                                    style={{ backgroundColor: '#ffc800' }}
                                    // sx={{padding:'25px'}}
                                    sx={{ width: '50', margin: 'auto' }}
                                 />
                                 <CardContent >
                                    <Typography variant="subtitle2" textAlign={'center'} >
                                       {ofertasCard.name}
                                    </Typography>
                                    <span className="card-contenedor__descuento">
                                       {ofertasCard.descuento}
                                    </span>
                                    <span className="card-contenedor__oferta">
                                       {ofertasCard.precioOferta}
                                    </span>
                                    <span className="card-contenedor__antes">
                                       {ofertasCard.precioAntes}
                                    </span>
                                    <span className="card-contenedor__carritoOferta">
                                       Lo quiero
                                    </span>
                                    
                                                                   
                                 </CardContent>

                              </Card>
                           </Grid>
                        </Grid>
                     ))}
                  </Slider>
               </Grid>
            </Grid>
            {/* GASEOSAS */}
            <Grid container>
               <Typography variant="h2" textAlign={'left'} className="textSubTitle">Gaseosas y Bebidas</Typography>
               <Grid item xs={12} mb={6}>
                  <Slider {...settings} >
                     {gaseosas.map((gaseosasCard, i) => (
                        <Grid container spacing={2} mr={0.1} mt={1} key={i}>
                           <Grid item >
                              <Card className={"card-contenedor"} >
                                 <CardMedia
                                    component="img"
                                    image={gaseosasCard.imagen}
                                    alt="categorias"
                                    className='card-contenedor__img'
                                    style={{ backgroundColor: '#ffc800' }}
                                    // sx={{padding:'25px'}}
                                    sx={{ width: '50', margin: 'auto' }}
                                 />
                                 <CardContent >
                                    <Typography variant="subtitle2" textAlign={'center'} >
                                       {gaseosasCard.name}
                                    </Typography>
                                    <span className="card-contenedor__actual">
                                       {gaseosasCard.precio}
                                    </span>                                    
                                    <span className="card-contenedor__carritoNormal">
                                       Lo quiero
                                    </span>                                                                                                       
                                 </CardContent>

                              </Card>
                           </Grid>
                        </Grid>
                     ))}
                  </Slider>
               </Grid>
            </Grid>
            {/* CERVEZAS  */}
            <Grid container >
               <Typography variant="h2" textAlign={'left'}  className="textSubTitle">Cervezas</Typography>
               <Grid item xs={12} mb={6} className={"slider-container"}>
                  <Slider {...settings}>
                     {cervezas.map((cervezasCard, i) => (
                        <Grid container spacing={2} mr={0.1} mt={1} key={i}>
                           <Grid item >
                              <Card className={"card-contenedor"} >
                                 <CardMedia
                                    component="img"
                                    image={cervezasCard.imagen}
                                    alt="categorias"
                                    className='card-contenedor__img'
                                    style={{ backgroundColor: '#ffc800' }}
                                    // sx={{padding:'25px'}}
                                    sx={{ width: '50', margin: 'auto' }}
                                 />
                                 <CardContent >
                                    <Typography variant="subtitle2"  gutterBottom textAlign={'center'} >
                                       {cervezasCard.name}
                                    </Typography>
                                    <span className="card-contenedor__actual">
                                       {cervezasCard.precio}
                                    </span>                                    
                                    <span className="card-contenedor__carritoNormal">
                                       Lo quiero
                                    </span>                                                                                                       
                                 </CardContent>

                              </Card>
                           </Grid>
                        </Grid>
                     ))}
                  </Slider>
               </Grid>
            </Grid>
         </>
      );
   }
}
