import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, } from '@mui/material'
import { useState } from "react";
import { Props } from "../QuoteBook.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import '../QuoteBook_styles.css'
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { AddCircle } from "@mui/icons-material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BookIcon from '@mui/icons-material/Book';

interface Column {
   id: 'folio' | 'documento' | 'fecha'|'monto' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: 'folio', label: 'Folio', minWidth: 10 },
   { id: 'documento', label: 'Documento', minWidth: 100 },
   { id: 'fecha', label: 'Fecha', minWidth: 50 },
   { id: 'monto', label: 'Monto', minWidth: 100 },
];

interface Data {
   folio: string;
   documento: string;
   fecha: string;
   monto: string;
}

function createData(
   folio: string,
   documento: string,
   fecha: string,
   monto: string,
): Data {
   return { folio, documento, fecha, monto };
}

const rowsData = [
   createData('6608', 'NOTA DE VENTA', '02/05/2022', '$ 100.00')
]


export const DataCotizacionGrafico: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [ejecutivoComercial, setEjecutivoComercial] = useState('');
   const [estado, setEstado] = useState('');


   const handleChangeEjecutivoComercial = (event: SelectChangeEvent) => {
      setEjecutivoComercial(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const estados=[
      {label:'No Adjudicada',value:'Enviado'},
      {label:'Adjudicada',value:'Adjudicada'},
      {label:'Anulada',value:'No Adjudicada'},
   ]
   return (
         <Container maxWidth='xl'>   
           <Grid item xs={12} md={12} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        DETALLE DE COTIZACION POR ESTADO
                     </Typography>
                  </Box>
                  <Box>
                     <Typography variant='subtitle2'  gutterBottom >
                     Detalle por Estado                         
                      </Typography>
                  </Box>
            </Grid>   


         </Container>
   );
};

