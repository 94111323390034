// import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "../PurchasesByDates.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import '../PurchasesByDates_styles.css'
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { PictureAsPdf } from "@mui/icons-material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import DownloadIcon from '@mui/icons-material/Download';

interface Column {
   id: 'resumen' | 'cantidad' | 'totales' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

 interface Columns1{
   id: 'resumne' | 'totales' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

 interface Column2{
   id: 'exportar'  ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

interface Column3{
   id: 'detalle' | 'debe' | 'haber' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns:readonly Column[] = [
   { id: 'resumen', label: 'Resumen Cuenta Corriente Proveedor', minWidth: 50 },
   { id: 'cantidad', label: 'Cantidad', minWidth: 10 },
   { id: 'totales', label: 'Totales', minWidth: 10 },
   ];
const columns1:readonly Columns1[] = [
   { id: 'resumne', label: 'Resumen a Nivel de Total', minWidth: 10 },
   { id: 'totales', label: 'Totales', minWidth: 10 },
   ];
const columns2:readonly Column2[] = [
   { id: 'exportar', label: 'Exportar Excel', minWidth: 10 },
   ];

const columns3:readonly Column3[] = [
   { id: 'detalle', label: 'Detalle', minWidth: 10 },
   { id: 'debe', label: 'Debe', minWidth: 10 },
   { id: 'haber', label: 'Haber', minWidth: 10 },
   ];

interface Data {
   resumen: string;
   cantidad: number;
   totales: number;
}
interface Data1 {
   resumne: string;
   totales: number;
}
interface Data2 {
   exportar: string;
}
interface Data3 {
   detalle: string;
   debe: number;
   haber: number;
}


function createData(
   resumen: string,
   cantidad: number,
   totales: number,
   ):Data {
   return { resumen, cantidad, totales };
}
function createData1(
   resumne: string,
   totales: number,
   ):Data1 {
   return { resumne, totales };
}
function createData2(
   exportar: string,
   ):Data2 {
   return { exportar };
}

function createData3(
   detalle: string,
   debe: number,
   haber: number,
   ):Data3 {
   return { detalle, debe, haber };
}

const rowsData = [
   createData('Cantidad Documentos del Mes', 1, 1),
   createData('Facturas Canceladas', 1, 1),
   createData('Facturas Pendientes ', 1, 1),
]

const rowsData1 = [
   createData1('Exento', 0),
   createData1('Neto', 0),
   createData1('Impuesto Especifico ', 0),
   createData1('IVA Credito Fiscal', 0),
   createData1('Total Bruto', 0),
   createData1('Activos Fijos', 0),
]
const rowsData2=[
   createData2('Excel de Libro'),
   createData2('Excel de Envio SII'),
   createData2('PDF para Archivador'),
   createData2('PDF para Archivador sin membrete'),

]

const rowsData3=[
   createData3('COMPRAS EXENTA ', 0, 0),
   createData3('COMPRAS AFECTAS ', 0, 0),
   createData3('IVA CREDITO FISCAL', 0, 0),
   createData3('IMPUESTO ESPECIFICO ', 0, 0),
   createData3('COMPRAS TOTALES  ', 0, 0),

]

export const ModalInforms:React.FC<Props> = (props: Props): JSX.Element => {
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value2, setValue2] = useState('');
   const [loading, setLoading] = useState(false);
   const [familyProduct, setFamilyProduct] = useState('');
   const [estado, setEstado]= useState('');
   const [value, setValue] = useState<Date | null>(new Date());

    const handleChangeFamilyProduct = (event: SelectChangeEvent) => {
      setFamilyProduct(event.target.value);
    };
    const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
    };
   function handleClick() {
     setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };


    return (
        <Container maxWidth='xl'>
           <Grid container spacing={3}>
               <Grid item xs={12} md={12} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        RESUMEN LIBRO DE COMPRAS
                     </Typography>
                  </Box>
                  <Typography  variant="caption" display="block" gutterBottom mt={1}>
                  Informe documentos ingresados de Compra
                  Mes : 03 Año 2022
                  </Typography>
               </Grid>
            </Grid>

         <Grid container spacing={3}>
               <Grid item xs={12} md={7} >
               <div style={{background:'#16556b', borderRadius:'5px', color:'#ffffff',}} >
                           <Typography 
                           mt={2}
                           mb={2}
                           style={{padding:'3px'}}
                           variant="subtitle2"
                           align='center'
                           >RESUMEN CUENTA CORRIENTE PROVEEDOR
                           </Typography>
               </div>

               <Paper sx={{ width: '100%', overflow: 'hidden'}}>
                  <TableContainer sx={{maxHeight:130}}>
                     <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                           <TableRow>
                              {/* {columns.map((column) => (
                                 <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{minWidth: column.minWidth}}
                                    >
                                 </TableCell>
                              ))} */}
                              {columns.map((column) => (
                                 <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{minWidth: column.minWidth}}
                                    >
                                    {column.label}
                                 </TableCell>
                              ))}
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                              return (
                                 <TableRow hover role="checkbox" tabIndex={-1} key={row.resumen}>
                                    {columns.map((column) => {
                                       const value = row[column.id];
                                       return (
                                          <TableCell key={column.id} align={column.align}>
                                             {column.format && typeof value === 'number'
                                                ? column.format(value)
                                                : value}
                                          </TableCell>
                                       );
                                    })}
                                    <TableCell>
                                          {/* <IconButton aria-label="delete">
                                             <DeleteIcon style={{color:'red'}}/>
                                          </IconButton>
                                          <IconButton aria-label="edit">
                                             <FactCheckIcon  style={{color:'green'}}/>
                                          </IconButton>
                                          <IconButton aria-label="edit">
                                             <PrintIcon style={{color:'blue'}}/>
                                          </IconButton>
            */}
                              
                                    </TableCell>

                                 </TableRow>
                              );
                           })}
                        
                        </TableBody>   
                     </Table>  
                  </TableContainer>
               </Paper>

               <div style={{background:'#16556b', borderRadius:'5px', color:'#ffffff',}} >
                           <Typography 
                           mt={2}
                           mb={2}
                           style={{padding:'3px'}}
                           variant="subtitle2"
                           align='center'
                           >RESUMEN CUENTA CORRIENTE PROVEEDOR
                           </Typography>
            </div>

               <Paper sx={{ width: '100%', overflow: 'hidden'}}>
                  <TableContainer sx={{maxHeight:130}}>
                     <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                           <TableRow>
                              {/* {columns.map((column) => (
                                 <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{minWidth: column.minWidth}}
                                    >
                                 </TableCell>
                              ))} */}
                              {columns1.map((column) => (
                                 <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{minWidth: column.minWidth}}
                                    >
                                    {column.label}
                                 </TableCell>
                              ))}
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {rowsData1.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                              return (
                                 <TableRow hover role="checkbox" tabIndex={-1} key={row.resumne}>
                                    {columns1.map((column) => {
                                       const value = row[column.id];
                                       return (
                                          <TableCell key={column.id} align={column.align}>
                                             {column.format && typeof value === 'number'
                                                ? column.format(value)
                                                : value}
                                          </TableCell>
                                       );
                                    })}
                                    <TableCell>
                                          {/* <IconButton aria-label="delete">
                                             <DeleteIcon style={{color:'red'}}/>
                                          </IconButton>
                                          <IconButton aria-label="edit">
                                             <FactCheckIcon  style={{color:'green'}}/>
                                          </IconButton>
                                          <IconButton aria-label="edit">
                                             <PrintIcon style={{color:'blue'}}/>
                                          </IconButton>
            */}
                                    
            
                                    </TableCell>

                                 </TableRow>
                              );
                           })}
                        
                        </TableBody>   
                     </Table>  
                  </TableContainer>
               </Paper>

               <div style={{background:'#16556b', borderRadius:'5px', color:'#ffffff',}} >
                           <Typography 
                           mt={2}
                           mb={2}
                           style={{padding:'3px'}}
                           variant="subtitle2"
                           align='center'
                           >RESUMEN CUENTA CORRIENTE PROVEEDOR
                           </Typography>
            </div>

               <Paper sx={{ width: '100%', overflow: 'hidden'}}>
                  <TableContainer sx={{maxHeight:130}}>
                     <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                           <TableRow>
                              {/* {columns.map((column) => (
                                 <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{minWidth: column.minWidth}}
                                    >
                                 </TableCell>
                              ))} */}
                              {columns2.map((column) => (
                                 <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{minWidth: column.minWidth}}
                                    >
                                    {column.label}
                                 </TableCell>
                              ))}
                              <TableCell>
                                 Ext
                              </TableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {rowsData2.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                              return (
                                 <TableRow hover role="checkbox" tabIndex={-1} key={row.exportar}>
                                    {columns2.map((column) => {
                                       const value = row[column.id];
                                       return (
                                          <TableCell key={column.id} align={column.align}>
                                             {column.format && typeof value === 'number'
                                                ? column.format(value)
                                                : value}
                                          </TableCell>
                                       );
                                    })}
                                    <TableCell>
                                          <IconButton aria-label="download">
                                             <DownloadIcon />
                                          </IconButton>
            
                                    </TableCell>

                                 </TableRow>
                              );
                           })}
                        
                        </TableBody>   
                     </Table>  
                  </TableContainer>
               </Paper>
               </Grid>
               <Grid item xs={12} md={5} >
               <div style={{background:'#16556b', borderRadius:'5px', color:'#ffffff',}} >
                           <Typography 
                           mt={2}
                           mb={2}
                           style={{padding:'3px'}}
                           variant="subtitle2"
                           align='center'
                           >CENTRALIZACION PROPUESTA COMPRA MES: 03/2022
                           </Typography>
               </div>

               <Paper sx={{ width: '100%', overflow: 'hidden'}}>
                  <TableContainer sx={{maxHeight:350}}>
                     <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                           <TableRow>
                              {/* {columns.map((column) => (
                                 <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{minWidth: column.minWidth}}
                                    >
                                 </TableCell>
                              ))} */}
                              {columns3.map((column) => (
                                 <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{minWidth: column.minWidth}}
                                    >
                                    {column.label}
                                 </TableCell>
                              ))}
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {rowsData3.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                              return (
                                 <TableRow hover role="checkbox" tabIndex={-1} key={row.detalle}>
                                    {columns3.map((column) => {
                                       const value = row[column.id];
                                       return (
                                          <TableCell key={column.id} align={column.align}>
                                             {column.format && typeof value === 'number'
                                                ? column.format(value)
                                                : value}
                                          </TableCell>
                                       );
                                    })}
                                    <TableCell>

                              
                                    </TableCell>

                                 </TableRow>
                              );
                           })}
                        
                        </TableBody>   
                     </Table>  
                  </TableContainer>
               </Paper>
               </Grid>

         </Grid>

         </Container>
     
    );
   };

