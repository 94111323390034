import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { Box, Stepper, InputAdornment, IconButton, Step, StepLabel, Button, CircularProgress, Grid, CssBaseline, Container, Modal, StepIconProps, Typography, Tooltip, FormControl, Autocomplete, FormLabel, RadioGroup, FormControlLabel, Radio, Checkbox } from '@mui/material'
import { Props } from "./GcMasterSearch.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import { AddCircle, Assignment } from "@mui/icons-material";
import { InputRef } from '@components/common/Input/InputInterfaces';
import "./GcMasterSearch.css";
import ArticleIcon from '@mui/icons-material/Article';

interface Column {
   id: 'rut' | 'razonSocial' | 'fantasia' | 'giro' | 'pros' | 'producto' | 'cliente' | 'telefono' | 'vendedor';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: 'rut', label: 'Rut', minWidth: 100 },
   { id: 'razonSocial', label: 'Razón Social', minWidth: 100 },
   { id: 'fantasia', label: 'Fantasia', minWidth: 100 },
   { id: 'giro', label: 'Giro', minWidth: 100 },
   { id: 'pros', label: 'Pros', minWidth: 100 },
   { id: 'producto', label: 'Producto', minWidth: 100 },
   { id: 'cliente', label: 'Cliente', minWidth: 100 },
   { id: 'telefono', label: 'Telefono', minWidth: 100 },
   { id: 'vendedor', label: 'Vendedor', minWidth: 100 },
];

interface Data {
   rut: string;
   razonSocial: string;
   fantasia: string;
   giro: string;
   pros: string;
   producto: string;
   cliente: string;
   telefono: string;
   vendedor: string;
}
// Valores de la tabla 
function createDataProvider(
   rut: string,
   razonSocial: string,
   fantasia: string,
   giro: string,
   pros: string,
   producto: string,
   cliente: string,
   telefono: string,
   vendedor: string
): Data {
   return { rut, razonSocial, fantasia, giro, pros, producto, cliente, telefono, vendedor };
}


const rowsGlobalList = [
   createDataProvider('111111-K', 'Vestiguero S.A', 'Codelgo', 'Personas', 'Si', 'Si', 'Si', '998754210', 'Vendedor 1'),
   createDataProvider('116666-S', 'Otra Razón Social S.A', 'Codelgo', 'Personas', 'Si', 'Si', 'Si', '998754210', 'Vendedor 2'),
   createDataProvider('000001-K', 'Nombres S.A', 'Codelgo', 'Personas', 'Si', 'Si', 'Si', '998754210', 'Vendedor 3'),
   createDataProvider('917152-8', 'Hombres de Negro S.A', 'Codelgo', 'Personas', 'Si', 'Si', 'Si', '998754210', 'Vendedor 4'),
   createDataProvider('098765-M', 'Vestiguero S.A', 'Codelgo', 'Personas', 'Si', 'Si', 'No', '990754210', 'Vendedor 5'),
   createDataProvider('1212289-K', 'Vestiguero S.A', 'Codelgo', 'Personas', 'Si', 'Si', 'Si', '998754210', 'Vendedor 6'),
   createDataProvider('12137983-P', 'Razón Social', 'Codelgo', 'Personas', 'Si', 'Si', 'No', '998754210', 'Vendedor 7'),
   createDataProvider('222222222-2', 'Empresa Amortiguadora de Golpes S.A', 'Codelgo', 'Personas', 'Si', 'Si', 'Si', '998754210', 'Vendedor 8'),

]

export const GcMasterSearch: React.FC<Props> = (props: Props): JSX.Element => {

   const [data, setData] = useState('');
   const [open, setOpen] = useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);
   const [select, setSelect] = useState('');
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [openContact, setOpenContact] = useState(false);
   const [openProduct, setOpenProduct] = useState(false);
   const [openBienes, setOpenBienes] = useState(false);


   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const handleChange = (event: any) => {
      setData(event.target.value as string);
   };
   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 600,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };
   //  Títulos y estilo a columnas de la tabla
   interface Column {
      id: 'Descripcion' | 'Fecha Ingreso' | 'Fecha Compra' | 'Concepto';
      label: string;
      minWidth?: number;
      align?: 'right';
      format?: (value: number) => string;
   }
   return (
      <Protected>
         <Container maxWidth='xl'>
            <Typography variant="h6" gutterBottom mb={3} className="tittle">
               Mantenedor Global
            </Typography>
            <Grid container mt={2}>
               <Grid item xs={12} textAlign="right">
                  <button className='btn_botones'>
                     Clientes Activos
                  </button>
                  <button className='btn_botones'>
                     Lista General
                  </button>
                  <button className='btn_botones'>
                     Sucursales
                  </button>
               </Grid>

            </Grid>

            <div className='bg-tittle'>
               <Typography
                  mt={3}
                  variant="h6"
                  align='center'
                  className='tittle-text'
               >
                  Listado Global
               </Typography>
            </div>
         </Container>

         <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer component={Paper}>
               <Table aria-label="simple table">
                  <TableHead>
                     <TableRow>
                        <TableCell align="center">Rut</TableCell>
                        <TableCell align="center">Razón Social</TableCell>
                        <TableCell align="center">Fantasía</TableCell>
                        <TableCell align="center">Giro</TableCell>
                        <TableCell align="center">Pros</TableCell>
                        <TableCell align="center">Producto</TableCell>
                        <TableCell align="center">Cliente</TableCell>
                        <TableCell align="center">Teléfono</TableCell>
                        <TableCell align="center">Vendedor</TableCell>
                        <TableCell align="center">Opciones</TableCell>
                        <TableCell align="center">Tipo</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {rowsGlobalList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        return (
                           <TableRow hover role="checkbox" tabIndex={-1} key={row.rut}>
                              {columns.map((column) => {
                                 const value = row[column.id];
                                 return (
                                    <TableCell key={column.id} align={column.align}>
                                       {column.format && typeof value === 'number'
                                          ? column.format(value)
                                          : value}
                                    </TableCell>
                                 );
                              })}
                              <TableCell align="center">
                                 <IconButton
                                    aria-label="Editar"
                                    // onClick={() => handleDelete(row.id)}
                                    className='btn_delete'
                                 // disabled={loading}
                                 >
                                    <EditIcon />
                                 </IconButton>

                                 <IconButton
                                    aria-label="Ver Ficha"
                                    // onClick={() => handleDelete(row.id)}
                                    className='btn_delete'
                                 // disabled={loading}
                                 >
                                    <Assignment />
                                 </IconButton>


                              </TableCell>
                              <TableCell align="center">
                                 <IconButton
                                    aria-label="Ingresar Proveedor"
                                    onClick={() => handleOpen()}
                                    className='btn_delete'
                                 // disabled={loading}
                                 >
                                    <ArticleIcon className="btn_add_provider" />

                                 </IconButton>
                              </TableCell>

                           </TableRow>
                        );
                     })}
                  </TableBody>
               </Table>
            </TableContainer>
            <TablePagination
               rowsPerPageOptions={[10, 25, 100]}
               component="div"
               count={rowsGlobalList.length}
               rowsPerPage={rowsPerPage}
               page={page}
               onPageChange={handleChangePage}
               onRowsPerPageChange={handleChangeRowsPerPage}
            />
         </Paper>

         <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box sx={style}>
               <Grid container>
                  <Grid item xs={10}>
                     <Typography variant="h6" id="modal-modal-title" mb={2} >
                        96545040-8 VENTISQUERO S.A.
                     </Typography>
                  </Grid>
               </Grid>

               <Grid container spacing={3} className={`e-mb-lg`}>


                  <Grid item xs={12} sm={4}>
                     <FormControl>
                        <FormControlLabel control={<Checkbox defaultChecked />} label="Cliente" />
                        <FormControlLabel control={<Checkbox defaultChecked />} label="Proveedor" />
                     </FormControl>
                  </Grid>


               </Grid>
               <Grid container>
                  <Grid item xs={12} sx={{ textAlign: 'center' }}>
                     <Typography variant="caption" id="modal-modal-title" mb={2} >
                        Cambio válido solo para cliente o proveedor
                     </Typography>
                  </Grid>
               </Grid>

               <Grid container mt={2}>
                  <Grid item xs={12} textAlign="center">
                     <button className='btn_add'>
                        Ingresar
                     </button>
                  </Grid>

               </Grid>
            </Box>

         </Modal>

      </Protected>

   );
};