import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button, } from '@mui/material'
import { useState } from "react";
import { Props } from "./GcProductService.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './GcProductService_styles.css'
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { AddCircle } from "@mui/icons-material";
import AddCircleIcon from '@mui/icons-material/AddCircle';

interface Column {
   id: 'numero' | 'codigoProducto' | 'segmento' | 'valorSegmento' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: 'numero', label: 'Número', minWidth: 100 },
   { id: 'codigoProducto', label: 'Código Producto', minWidth: 100 },
   { id: 'segmento', label: 'Segmento', minWidth: 100 },
   { id: 'valorSegmento', label: 'Valor Segmento', minWidth: 100 },

];

interface Data {
   numero: number;
   codigoProducto: string;
   segmento: string;
   valorSegmento: string;
   }

function createDataSegments(
   numero: number,
   codigoProducto: string,
   segmento: string,
   valorSegmento: string,
): Data {
   return { numero, codigoProducto, segmento, valorSegmento };
}

const rowsDataSegments= [
   createDataSegments(1, 'COD001', 'SEGMENTO 1', '$1.000'),
   createDataSegments(2, 'COD002', 'SEGMENTO 2', '$2.000'),
   createDataSegments(3, 'COD003', 'SEGMENTO 3', '$3.000'),
   createDataSegments(4, 'COD004', 'SEGMENTO 4', '$4.000'),
   createDataSegments(5, 'COD005', 'SEGMENTO 5', '$5.000'),
]


export const DataModalSegment: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState('');
   const [loading, setLoading] = useState(false);
   const [ejecutivoComercial, setEjecutivodComercial] = useState('');
   const [estado, setEstado] = useState('');

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const segments=[
      {label: 'Segmento 1', value: 'Segmento 1'},
      {label: 'Segmento 2', value: 'Segmento 2'},
      {label: 'Segmento 3', value: 'Segmento 3'},
      {label: 'Segmento 4', value: 'Segmento 4'},
      {label: 'Segmento 5', value: 'Segmento 5'},
   ]
   return (
         <Container maxWidth='xl'>
            <Grid container spacing={3}>
            <Grid item xs={12}>
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        MANTENEDOR DE SEGMENTOS
                     </Typography>
                  </Box>
                  
            </Grid>
                  
               <Grid item xs={12} sm={4}>
                  <TextField
                     value='COD001'
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={4}>
                  <TextField
                     label="Valor Segmento"
                     id="outlined-size-small"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={4}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={segments}
                     renderInput={(params) => <TextField {...params} label="Segmento" />}
                  />
               </Grid>

               <Grid item 
                  xs={4}
                  // mt={1}
                  justifyContent='center'
                  
               >
                  <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        // onClick={handleSubmit}
                        // disabled={loading}
                        disableElevation
                        className='btn_save'
                     >
                        <span>Ingresar</span>
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={2}>
               LISTADO DE PRODUCTOS
            </Typography>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
               <TableContainer sx={{ maxHeight: 440 }} >
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {/* {columns.map((column) => (
                              <TableCell
                                 key={column.id}
                                 align={column.align}
                                 style={{minWidth: column.minWidth}}
                                 >
                              </TableCell>
                           ))} */}
                           <TableCell>Numero</TableCell>
                           <TableCell>Codigo Producto</TableCell>
                           <TableCell>Segmento</TableCell>
                           <TableCell>Valor Segmento</TableCell>
                           <TableCell>Opciones</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsDataSegments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.numero}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                          {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                    <IconButton
                                    >
                                       <EditIcon />
                                    </IconButton>
                                 </TableCell>
                              </TableRow>
                           );
                        })}

                     </TableBody>
                  </Table>
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsDataSegments.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
               />
            </Paper>

         </Container>
   );
};

