import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { Box, Stepper, InputAdornment, IconButton, Step, StepLabel, Button, CircularProgress, Grid, CssBaseline, Container, Modal, StepIconProps, Typography, Tooltip, FormControl, Autocomplete } from '@mui/material'
import {Props} from "./InsumosOther.type"
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import InfoIcon from '@mui/icons-material/Info';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { FormDetalleInsumo } from "./FormDetalleInsumo";
import { FormInventario } from "./FormInventario";
import { FormProveedores } from "./FormProveedores";
import BadgeIcon from '@mui/icons-material/Badge';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import { AddCircle, Assignment } from "@mui/icons-material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import { DeleteIcon } from '@/toolbox/constants/icons';
import "./InsumosOther.css"

const steps= [{name:'Detalle de Insumo', icon:null},
{name:'Inventario', icon:null},
// {name:'Proveedores', icon:null}
]

export const InsumosOther:React.FC<Props> = (props: Props): JSX.Element => {
    
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [error, setError] = useState(null);
    const [value, setValue] = useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
    const [valueDetall,setvalueDetall]=useState({
        codigoInsumo:'',
        nombreInsumo:'',
        detalleLargo:'',
        grupo:'',
        estadoProducto:'',
        clasificacion:'',
    });
    
    const [valueInventario,setvalueInventario]=useState({
        unidadMedida:'',
        stockMinimo:'',
        stockMaximo:'',
        UbicacionFisica:'',
    });

    const [valueProveedor,setvalueProveedor]=useState({
        situacion_inicial:'',
        fecha_compra:'',
        valor_compra:'',
        numero_factura:'',
        orden_compra:'',
        proveedor:'',
        vida_tributaria:'',
        vida_financiera:'',
        inicio_valorizacion:'',
        inicio_depreciacion:'',
        descripcion:''
    });

    const [data, setData] = useState('');
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [select, setSelect] = useState('');
    const inputRefs = useRef<Array<InputRef | null>>([]);

    const handleChange = (event: any) => {
      setData(event.target.value as string);
    };
      const style = {
       position: 'absolute' as 'absolute',
       top: '50%',
       left: '50%',
       transform: 'translate(-50%, -50%)',
       width: 720,
       bgcolor: 'background.paper',
       // border: '2px solid #000',
       boxShadow: 24,
       p: 2,
       borderRadius: '20px'
    };
    interface Column {
      id: 'Descripcion' | 'Fecha Ingreso' | 'Fecha Compra' | 'Concepto';
      label: string;
      minWidth?: number;
      align?: 'right';
      format?: (value: number) => string;
    }
    const columns: Column[] = [
      { id: 'Descripcion', label: 'Descripcion', minWidth: 100 },
      { id: 'Fecha Ingreso', label: 'Fecha Ingreso' },
      { id: 'Fecha Compra', label: 'Fecha Compra' },
      { id: 'Concepto', label: 'Concepto', minWidth: 100 },

    ];
    const proveedores=[
         {label:'Proveedor 1',value:'Proveedor 1'},
         {label:'Proveedor 2',value:'Proveedor 2'},
         {label:'Proveedor 3',value:'Proveedor 3'},
         {label:'Proveedor 4',value:'Proveedor 4'},
    ]
    const tipos = [
       { label:'Ultima Cotizacion'},
       { label: 'Ultima Compra'}
    ]
    
   
    function createDataInsumos(
        codigoInsumo: string,
        proveedor: string,
        descripcion: string,
        precio: number,
        inventario:number ,
    ){
       return {codigoInsumo,proveedor,descripcion,precio,inventario};
    }


      const rowsDataInsumo=[
         createDataInsumos('1','Proveedor 1','Descripcion 1',100,1),
         createDataInsumos('2','Proveedor 2','Descripcion 2',200,2),
      ]
    useEffect(() => {
        if(props.entity !== undefined){
            setvalueDetall(props.entity);
            setvalueInventario(props.entity);
            setvalueProveedor(props.entity);
        }
    }, []);

    const isStepSkipped = (step) => {
        return skipped.has(step);
     };
  
     const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
           newSkipped = new Set(newSkipped.values());
           newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
     };
  
     const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
     };


     const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
          top: 22,
        },
        [`&.${stepConnectorClasses.active}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
              'linear-gradient( 95deg,#19c1d1 0%,#1976D2 50%,#1996d1 100%)',
          },
        },
        [`&.${stepConnectorClasses.completed}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
              'linear-gradient( 95deg,#19c1d1 0%,#1976D2 50%,#1996d1 100%)',
          },
        },
        [`& .${stepConnectorClasses.line}`]: {
          height: 3,
          border: 0,
          backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
          borderRadius: 1,
        },
      }));
  
      const ColorlibStepIconRoot = styled('div')<{
        ownerState: { completed?: boolean; active?: boolean };
      }>(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
          backgroundImage:
            'linear-gradient( 136deg, #1976D2 0%, #1996d1 50%, #19c1d1 100%)',
          boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
          backgroundImage:
            'linear-gradient( 136deg, #1976D2 0%, #1996d1 50%, #19c1d1 100%)',
        }),
      }));


      function ColorlibStepIcon(props: StepIconProps) {
        const { active, completed, className } = props;
  
        const icons: { [index: string]: React.ReactElement } = {
          1: <AssignmentIcon />,
          2:<AnalyticsIcon/>,
          3: <InfoIcon />          
        };
  
        return (
          <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
          </ColorlibStepIconRoot>
        );
      }

      const mostrarFormulario = () => {
        if (activeStep === 0) {
           return (
              <FormDetalleInsumo
                 handleNext={handleNext}
                 valueDetall={valueDetall}
                 setvalueDetall={setvalueDetall}
              />
           )
        }
        else if (activeStep === 1) {
           return (
            <FormInventario
            handleNext={handleNext}
            valueInventario={valueInventario}
            setvalueInventario={setvalueInventario}
            />
           )
        }
      //   else if (activeStep === 2) {
      //      return (
      //       <FormProveedores
      //       handleNext={handleNext}
      //       valueProveedores={valueProveedor}
      //       setvalueProveedores={setvalueProveedor}
      //       />
      //      )
      //   }
     }
    return (
        <Protected>
        <Container maxWidth='xl'>
           <Typography variant="h6" gutterBottom mb={3} className="tittle">
               MANTENEDOR DE INSUMOS
             </Typography>
           <Grid container flexDirection='row' spacing={2}>
                 <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                       <Box>
                          <Box sx={{ mb: 2 }}>
                             <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
                                {steps.map((label, index) => {
                                   return (
                                      <Step key={index}>
                                         <StepLabel StepIconComponent={ColorlibStepIcon} error={error === `Usuario${index}noencontrado` ? true : false}>{label.name}</StepLabel>
                                      </Step>
                                   );
                                })}
                             </Stepper>
                          </Box>
                          {activeStep === steps.length ? (
                             <div>AGREGADO</div>
                             //VALIDAR QUE EL ENVIO DE DATOS
                             // history.push(ROUTE_ACCOUNT)
                          ) : (
                             mostrarFormulario()
                          )}
                       </Box>
                    </Grid>
                 </Grid>
           </Grid>
           
           <div className='bg-tittle'>
                        <Typography 
                        mt={3} 
                        variant="h6"
                        align='center'
                        className='tittle-text'
                        >Listado de Insumos
                        </Typography>
         </div>

         <TableContainer component={Paper}>
            <Table aria-label="simple table">
               <TableHead>
                  <TableRow>
                     <TableCell align="center">Código Insumo</TableCell>
                     <TableCell align="center">Proveedor</TableCell>
                     <TableCell align="center">Descripción</TableCell>
                     <TableCell align="center">Precio</TableCell>
                     <TableCell align="center">Inventario</TableCell>
                     <TableCell align="center">Opciones</TableCell>
                     <TableCell align="center">Agregar Proveedor</TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                     {rowsDataInsumo.map((row, index) => (
                        <TableRow key={index} >
                           <TableCell align="center">{row.codigoInsumo}</TableCell>
                           <TableCell align="center">{row.proveedor}</TableCell>
                           <TableCell align="center">{row.descripcion}</TableCell>
                           <TableCell align="center">{row.precio}</TableCell>
                           <TableCell align="center">{row.inventario}</TableCell>
                           <TableCell align="center">
                              <IconButton
                                 aria-label="Editar"
                                 // onClick={() => handleDelete(row.id)}
                                 className='btn_delete'
                                 // disabled={loading}
                              >
                                 <EditIcon />
                              </IconButton>

                              <IconButton
                                 aria-label="Ver Ficha"
                                 // onClick={() => handleDelete(row.id)}
                                 className='btn_delete'
                                 // disabled={loading}
                              >
                                 <Assignment />
                              </IconButton>

                              {/* <IconButton
                                 aria-label="Ingresar Poveedor"
                                 // onClick={() => handleDelete(row.id)}
                                 className='btn_delete'
                                 // disabled={loading}
                              >
                                 <AddBoxIcon />
                              </IconButton> */}


                           </TableCell>
                           <TableCell align="center">
                              <IconButton
                                 aria-label="Ingresar Proveedor"
                                 onClick={() => handleOpen()}
                                 className='btn_delete'
                                 // disabled={loading}
                              >
                                 <AddCircleIcon  className="btn_add_provider"/>
                              </IconButton>
                           </TableCell>
                           
                        </TableRow>
                     ))}
               </TableBody> 
            </Table>
         </TableContainer> 

         </Container>
         <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box sx={style} >
                  <Grid container>
                        <Grid item xs={10}>
                           <Typography variant="h6" id="modal-modal-title" mb={2} className='tittle'>
                              Mantenedor de Productos
                           </Typography>
                        </Grid>
                        <Grid item xs={2} textAlign="end">
                           <AssignmentIcon style={{color:'#FF9700'}}/>
                        </Grid>
                     </Grid>

                     <Grid container spacing={3} className={`e-mb-lg`}>
            
                     <Grid item xs={12} sm={4}>
                           <Input
                           prependInnerAdornment={
                              <InputAdornment position="end">
                                 {/* <BadgeIcon /> */}
                              </InputAdornment>
                           }
                           ref={ref => inputRefs.current[0] = ref}
                           name="resumen"
                           type='text'
                           value='C425 | Descripcion 1'
                           // onChange={handleInput}
                           // backgroundColor="#F3F3F3"
                           // rules={rules.resumen}
                           disableElevation
                           validateOnBlur
                           dense
                           // disabled={isDisabled}
                           className='borderInput'
                        />
                        </Grid>

                     <Grid item xs={12} sm={4}>
                           <Input
                           prependInnerAdornment={
                              <InputAdornment position="end">
                                 <BadgeIcon />
                              </InputAdornment>
                           }
                           ref={ref => inputRefs.current[0] = ref}
                           name="resumen"
                           type='text'
                           placeholder="Codigo Producto"
                           // value={props.valueEntry.resumen}
                           // onChange={handleInput}
                           // backgroundColor="#F3F3F3"
                           // rules={rules.resumen}
                           disableElevation
                           validateOnBlur
                           dense
                           // disabled={isDisabled}
                           className='borderInput'
                        />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                        <Autocomplete
                              disablePortal
                              size='small'
                              id="combo-box-demo"
                              options={proveedores}
                              // sx={{ bgcolor: '#F3F3F3'}}
                              renderInput={(params) => <TextField {...params} label="Seleccione Proveedor" />}
                           />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                        <Input
                           prependInnerAdornment={
                              <InputAdornment position="end">
                                 <BadgeIcon />
                              </InputAdornment>
                           }
                           ref={ref => inputRefs.current[0] = ref}
                           name="resumen"
                           type='text'
                           placeholder="Precio"
                           // value={props.valueEntry.resumen}
                           // onChange={handleInput}
                           // backgroundColor="#F3F3F3"
                           // rules={rules.resumen}
                           disableElevation
                           validateOnBlur
                           dense
                           // disabled={isDisabled}
                           className='borderInput'
                        />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                        <Autocomplete
                              disablePortal
                              size='small'
                              id="combo-box-demo"
                              options={tipos}
                              // sx={{ bgcolor: '#F3F3F3'}}
                              renderInput={(params) => <TextField {...params} label="Seleccione Tipo" />}
                           />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                           <DatePicker
                              label="Fecha"
                              value={value}
                              onChange={(newValue) => {
                                 setValue(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                           />
                        </LocalizationProvider>
                        </Grid>

         
                     </Grid>
         

                        <Grid container mt={2}>
                           <Grid item xs={12} textAlign="center">
                              <button  className='btn_add'>
                              Agregar
                              </button>
                           </Grid>
                           
                        </Grid>

                        <Grid container mt={2}>
                           <Grid item xs={12}>
                              <Box mt={1} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <FormControl fullWidth >
                                                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                                      <TableContainer sx={{ maxHeight: 540 }}>
                                                      <Table stickyHeader aria-label="sticky table">
                                                         <TableHead>
                                                            <TableRow>
                                                               <TableCell>Num.</TableCell>
                                                               <TableCell>Codigo</TableCell>
                                                               <TableCell>Proveedor</TableCell>
                                                               <TableCell>Tipo</TableCell>
                                                               <TableCell>Fecha</TableCell>
                                                               <TableCell>Precio</TableCell>
                                                               <TableCell>Opciones</TableCell>
            
                                                            </TableRow>
                                                         </TableHead>
                              
                                                         <TableBody>
                                                            <TableRow >  
                                                               <TableCell>
                                                                  1
                                                               </TableCell>

                                                               <TableCell>
                                                                  48746845
                                                               </TableCell>

                                                               <TableCell>
                                                               Alain monsalve	
                                                               </TableCell>   
                                                               
                                                               <TableCell>
                                                               Ultima Cotizacion	
                                                               </TableCell>

                                                               <TableCell>
                                                               10/02/2022	
                                                               </TableCell>

                                                               <TableCell>
                                                               55	
                                                               </TableCell>

                                                               <TableCell>
                                                                  <div className='sepaEle'>
                                                                     <button className='btnIcoD'><EditIcon /></button>
                                                                     <button className='btnIcoD'><DeleteIcon /></button>
                                                                  </div>
                                                               </TableCell>
                                                            </TableRow>
                                                            
                                                         </TableBody>
                                                      </Table>
                                                      </TableContainer>
                                                      
                                                </Paper>
                                    </FormControl>
                              </Box>
                           </Grid>                 
                           </Grid>
                  </Box>
                     
            </Modal>                     

     </Protected>

    );
};