import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { ArrowRightIcon } from "@/toolbox/constants/icons";
import { VALIDATORS } from "@/toolbox/helpers/validation-rules";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, } from '@mui/material';
import { Input } from '@components/common/Input';
import { Props } from "./OfertRequest.type";
import BusinessIcon from '@mui/icons-material/Business';
import { InputRef } from '@/components/common/Input/InputInterfaces';
import NumbersIcon from '@mui/icons-material/Numbers';
import ArticleIcon from '@mui/icons-material/Article';
import Stack from '@mui/material/Stack';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import './OfertRequest_style.css';
import FormGroup from '@mui/material/FormGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
export const OfertRequest: React.FC<Props> = (props:Props):JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);

   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const [valueContrato,setvalueContrato]=useState({
      rut_proveedor:'',
      numero:'',
      numero_contrato:'',
      descripcion:'',
  });

  const usuarios=[
  { label: 'Todos' },
  { label: 'Usuario 1' },
   { label: 'Usuario 2' },
   { label: 'Usuario 3' },
   { label: 'Usuario 4' },
   { label: 'Usuario 5' },
   { label: 'Usuario 6' },
   { label: 'Usuario 7' },
   { label: 'Usuario 8' },
   { label: 'Usuario 9' },
   { label: 'Usuario 10' },
  ]

  
  const condicionPago=[
  { label: 'Contado' },
  ]    

  const aprobacion=[
  { label: 'Aprobacion 1' },
  { label: 'Aprobacion 2' },
  { label: 'Aprobacion 3' },
  { label: 'Aprobacion 4' },

  ]

  function createDataContrato(
     id:number,
     numero:number,
     PDF:string,
     Fecha:string,
     Descripcion:string,
     Prioridad:string,
     Estado:string,
     
  ){
   return {id,numero,PDF,Fecha,Descripcion,Prioridad,Estado};
}

  const rowsContrato = [
      ]

  const rules = useMemo(() => ({
    rut_proveedor: [
       VALIDATORS.REQUIRED,
    ],
    numero_contrato: [
       VALIDATORS.REQUIRED,
       VALIDATORS.CODE.ONLY_NUMBER
    ],
    numero: [
       VALIDATORS.REQUIRED
    ],
    descripcion: [
       VALIDATORS.REQUIRED,
    ]

 }), []);
    const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'rut_proveedot':
            props.setvalueOfertRequest(prev => ({ ...prev, rut_proveedot: value }));
            break;
         case 'numero_contrato':
            props.setvalueOfertRequest(prev => ({ ...prev, numero_contrato: value }))
            break;
         case 'numero':
            props.setvalueOfertRequest(prev => ({ ...prev, numero: value }))
            break;
         case 'descripcion':
            props.setvalueOfertRequest(prev => ({ ...prev, descripcion: value }));
            break;
         
         default:
            break;
      }
   };

   // const handleInputBlur = (event: any) => {
   //    const newVal = (event.target.value).trim();
   //    const name = event.target.name;
   //    props.setvalueOfertRequest(prev => ({ ...prev, [name]: newVal }));
   // }

const handleSubmit = async (event: React.SyntheticEvent) => {
   event.preventDefault();
}
const validate = () => {
   const refs = inputRefs.current;
   const valid = refs.reduce((prev, ref) => {
      const isValid = ref && ref.validate(true) || false
      return prev && isValid;
   }, true);
   return valid;
}
  return (
    <Protected>
      <form style={{margin:'8px'}}>
            <Typography variant="h6" gutterBottom mb={3} className="tittle">
               SOLICITUDES DE OFERTA
             </Typography>               
            <Grid container spacing={3} className={`e-mb-lg`} >

               <Grid item xs={12} sm={2}>
                     <LocalizationProvider dateAdapter={AdapterDateFns}>
                     <DatePicker
                        views={['year', 'month']}
                        label="Periodo y Mes" 
                        minDate={new Date('2012-03-01')}
                        maxDate={new Date('2023-06-01')}
                        value={value}
                        onChange={(newValue) => {
                           setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} helperText={null} />}
                     />
                     </LocalizationProvider>
               </Grid>

               <Grid item xs={12} sm={2}>
               <Autocomplete
                           disablePortal
                           size='small'
                           id="combo-box-demo"
                           options={usuarios}
                           // sx={{ bgcolor: '#F3F3F3'}}
                           renderInput={(params) => <TextField {...params} label="Solicitado Por" />}
                        />
               </Grid>

               <Grid item xs={12} sm={2}>
               <FormGroup>
                  <FormControlLabel control={<Checkbox defaultChecked />} label="Ver Sólo Genéricas" />
               </FormGroup>
               </Grid>

               <Grid item xs={12} sm={6} alignItems="center" >

                  <Button
                           type='submit'
                           variant='contained'
                           onClick={handleSubmit}
                           disabled={loading}
                           disableElevation
                           className='btn_save'
                                                   >
                                 <span>Visualizar</span>
                  </Button>
               </Grid>

            </Grid>
         </form>

         <div className='bg-tittle'>
               <Typography 
               mt={3} 
               variant="h6"
               align='center'
               className='tittle-text'
               >Listado Solicitudes de Compra
               </Typography>
         </div>


         <div>
               <Typography 
               mt={1}
               mb={2}
               align='left'
               className='tittle-text2'
               >Periodo 2022 | Mes Junio
               </Typography>
         </div>

         <TableContainer component={Paper}>
            <Table aria-label="simple table">
               <TableHead>
                  <TableRow>
                     <TableCell>N°</TableCell>
                     <TableCell align="center">Numero</TableCell>
                     <TableCell align="center">PDF</TableCell>
                     <TableCell align="center">Fecha</TableCell>
                     <TableCell align="center">Descripcion</TableCell>
                     <TableCell align="center">Prioridad</TableCell>
                     <TableCell align="center">Solicitante</TableCell>
                     <TableCell align="center">Opciones</TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                     {rowsContrato.map((row, index) => (
                        <TableRow key={index} >
                           <TableCell component="th" scope="row">
                              {index + 1}
                           </TableCell>
                           <TableCell align="center">{row.numero}</TableCell>
                           <TableCell align="center">{row.PDF}</TableCell>
                           <TableCell align="center">{row.Fecha}</TableCell>
                           <TableCell align="center">{row.Descripcion}</TableCell>
                           <TableCell align="center">{row.Prioridad}</TableCell>
                           <TableCell align="center">{row.prioridad}</TableCell>
                           <TableCell align="center">
                              <IconButton
                                 aria-label="delete"
                                 // onClick={() => handleDelete(row.id)}
                                 className='btn_delete'
                                 // disabled={loading}
                              >
                                 <DeleteIcon />
                              </IconButton>

                              <IconButton
                                 aria-label="delete"
                                 // onClick={() => handleDelete(row.id)}
                                 className='btn_delete'
                                 // disabled={loading}
                              >
                                 <EditIcon />
                              </IconButton>

                           </TableCell>
                        </TableRow>
                     ))}
               </TableBody> 
            </Table>
         </TableContainer> 
    </Protected>
  );
}