import { Protected } from "@/components/layout/Protected";
import {
   Box,
   Grid,
   Container,
   Typography,
   IconButton,
   SelectChangeEvent,
   Autocomplete,
   Button,
} from "@mui/material";
import { useState } from "react";
import { Props } from "../EmitElectronicExportDebitNote.type";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import "../ElectronicExportDebitNote_styles.css";
import TextField from "@mui/material/TextField";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import LoadingButton from "@mui/lab/LoadingButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { AddCircle, PictureAsPdf } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BookIcon from "@mui/icons-material/Book";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

interface Column {
   id: "accion" | "documento" | "folio" | "fechaEmision" | "montoTotal";
   label: string;
   minWidth?: number;
   align?: "right";
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: "accion", label: "Acción", minWidth: 10 },
   { id: "documento", label: "Documento", minWidth: 10 },
   { id: "folio", label: "Folio", minWidth: 10 },
   { id: "fechaEmision", label: "Fecha Emision", minWidth: 10 },
   { id: "montoTotal", label: "Monto Total", minWidth: 10 },
];

interface Data {
   accion: string;
   documento: string;
   folio: string;
   fechaEmision: string;
   montoTotal: string;
}

function createData(
   accion: string,
   documento: string,
   folio: string,
   fechaEmision: string,
   montoTotal: string
): Data {
   return { accion, documento, folio, fechaEmision, montoTotal };
}

const rowsData = [
   createData("1", "01/01/2020", "1", "Factura", "1"),
   createData("2", "01/01/2020", "1", "Factura", "1"),
   createData("3", "01/01/2020", "1", "Factura", "1"),
   createData("4", "01/01/2020", "1", "Factura", "1"),
   createData("5", "01/01/2020", "1", "Factura", "1"),
   createData("6", "01/01/2020", "1", "Factura", "1"),
   createData("7", "01/01/2020", "1", "Factura", "1"),
];

export const ResultBusque: React.FC<Props> = (props: Props): JSX.Element => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [value, setValue] = useState("");
   const [loading, setLoading] = useState(false);
   const [ejecutivoComercial, setEjecutivoComercial] = useState("");
   const [estado, setEstado] = useState("");

   const handleChangeEjecutivoComercial = (event: SelectChangeEvent) => {
      setEjecutivoComercial(event.target.value);
   };
   const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
   };
   function handleClick() {
      setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
   ) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const estados = [
      { label: "No Adjudicada", value: "Enviado" },
      { label: "Adjudicada", value: "Adjudicada" },
      { label: "Anulada", value: "No Adjudicada" },
   ];
   return (
      <Grid container>
         <Grid></Grid>
      </Grid>
   );
};
