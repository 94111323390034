import React, { useState, useRef } from 'react';
import { Protected } from "@/components/layout/Protected";
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
// import { InputRef } from '@/components/common/Input/InputInterfaces';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import './DocumentsEmission_styles.css';
import { Sale } from './Sale/Sale';
import Checkbox from '@mui/material/Checkbox';
import { Billing } from './Billing/Billing';
import { Others } from './Others/Others';
import { Tickets } from './Tickets/Tickets';
// import { TabMonthProducts } from './InformsMonthProduct/TabMonthProducts'
// import { TableMOnth } from './InformsMonth/TableMonth';

interface EcSellConsolidateProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}


function TabPanel(props: EcSellConsolidateProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export const DocumentsEmission: React.FunctionComponent<EcSellConsolidateProps> = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
  }

  return (
    <Protected>
      <Grid  style={{ margin: '8px', height:'100vh' }}>
        {/* <form style={{ margin: '8px' }}> */}
        <Grid item xs={12} md={12} textAlign="start">
          <Typography mt={1} variant='h6' component='h1' gutterBottom className="tittle">
            EQUIPO DE VENTA GESTIÓN COMERCIAL
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" display="block" gutterBottom >
            Seleccione el Item a consultar
          </Typography>
        </Grid>
        {/* </form> */}

        <Grid item xs={12} mt={3}>
          <Box  >
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              // className='tabMain'
              >
                <Tab className='tab' label="Venta" {...a11yProps(0)} />
                <Tab className='tab' label="Facturación" {...a11yProps(1)} />
                <Tab className='tab' label="Boletas" {...a11yProps(2)} />
                <Tab className='tab' label="Otros" {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <Sale />
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <Billing />
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction}>
                <Tickets/>
              </TabPanel>
              <TabPanel value={value} index={3} dir={theme.direction}>
                <Others/>
              </TabPanel>              
            </SwipeableViews>
          </Box>
        </Grid>
      </Grid>

    </Protected>
  );
}
