import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { ArrowRightIcon } from "@/toolbox/constants/icons";
import { VALIDATORS } from "@/toolbox/helpers/validation-rules";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, TablePagination, } from '@mui/material';
import { Input } from '@components/common/Input';
import { Props } from "./GcPriceList.type";
import BusinessIcon from '@mui/icons-material/Business';
import { InputRef } from '@/components/common/Input/InputInterfaces';
import './GcPriceList_style.css';
import EditIcon from '@mui/icons-material/Edit';
import AbcIcon from '@mui/icons-material/Abc';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { Link } from 'react-router-dom';
import { ROUTE_GC_FAMILIES, ROUTE_GC_PRICE_LIST_CART, ROUTE_GC_PRICE_LIST_CHANEL, ROUTE_GC_PRICE_LIST_SEGMENTATION } from '@/toolbox/constants/route-map';
import RoundaboutRightIcon from '@mui/icons-material/RoundaboutRight';
import CompressIcon from '@mui/icons-material/Compress';

interface Column {
   id: 'id' | 'nombre';
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
}

const columns: readonly Column[] = [
   { id: 'id', label: 'Rut', minWidth: 100 },
   { id: 'nombre', label: 'Razón Social', minWidth: 100 }
];

interface Data {
   id: string;
   nombre: string;
}
function createDataContrato(
   id: string,
   nombre: string
): Data {
   return { id, nombre };
}

const rowsListado = [
   createDataContrato('123', 'Nombre 1'),
   createDataContrato('111', 'Nombre 2'),
   createDataContrato('2222', 'Nombre 3'),
   createDataContrato('870', 'Nombre 4')
]

export const GcPriceList: React.FC<Props> = (props: Props): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);

   const [open, setOpen] = useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 720,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const [valueContrato, setvalueContrato] = useState({
      rut_proveedor: '',
      titulo: '',
      numero_contrato: '',
      descripcion: '',
   });

   const tipoContrato = [
      { label: 'Publico' },
      { label: 'Privado' },
   ]
   const estado = [
      { label: 'ACTIVADO' },
      { label: 'DESACTIVADO' },
   ]

   const tipoFacturacion = [
      { label: 'Mensual' },
      { label: 'Trimestral' },
      { label: 'Semestral' },
      { label: 'Anual' },
   ]
   const ejecutivoComercial = [
      { label: 'ACTIVADO' },
      { label: 'DESACTIVADO' }
   ]

   const centroCosto = [
      { label: 'Centro de Costo 1' },
      { label: 'Centro de Costo 2' },
      { label: 'Centro de Costo 3' },
      { label: 'Centro de Costo 4' }
   ]

   const prioridad = [
      { label: 'Alta' },
      { label: 'Media' },
      { label: 'Baja' },
      { label: 'Urgente' }
   ]
   const bancos = [
      { label: 'Banco 1', value: 'Banco 1' },
      { label: 'Banco 2', value: 'Banco 2' },
      { label: 'Banco 3', value: 'Banco 3' },
      { label: 'Banco 4', value: 'Banco 4' },
   ]
   const tipoCuenta = [
      { label: 'Tipo Cuenta 1', value: 'tipoCuenta 1' },
      { label: 'Tipo Cuenta 2', value: 'tipoCuenta 2' },
      { label: 'Tipo Cuenta 3', value: 'tipoCuenta 3' },
      { label: 'Tipo Cuenta 4', value: 'tipoCuenta 4' },
   ]

   const modeda = [
      { label: 'UF' }
   ]
   const vendedor = [
      { label: 'El chavo' },
      { label: 'Vendedor 2' },
      { label: 'Vendedor 3' },
      { label: 'Vendedor 4' }
   ]

   const condicionPago = [
      { label: 'Contado' },
   ]

   const aprobacion = [
      { label: 'Aprobacion 1' },
      { label: 'Aprobacion 2' },
      { label: 'Aprobacion 3' },
      { label: 'Aprobacion 4' },

   ]
   const estadoComercial = [
      { label: 'ACTIVO' },
      { label: 'BLOQUEO COMERCIAL' }
   ]



   const rules = useMemo(() => ({
      rut_proveedor: [
         VALIDATORS.REQUIRED,
      ],
      numero_contrato: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      titulo: [
         VALIDATORS.REQUIRED
      ],
      descripcion: [
         VALIDATORS.REQUIRED,
      ]

   }), []);
   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      switch (name) {
         case 'rut_proveedot':
            props.setvalueContrato(prev => ({ ...prev, rut_proveedot: value }));
            break;
         case 'numero_contrato':
            props.setvalueContrato(prev => ({ ...prev, numero_contrato: value }))
            break;
         case 'titulo':
            props.setvalueContrato(prev => ({ ...prev, titulo: value }))
            break;
         case 'descripcion':
            props.setvalueContrato(prev => ({ ...prev, descripcion: value }));
            break;

         default:
            break;
      }
   };

   // const handleInputBlur = (event: any) => {
   //    const newVal = (event.target.value).trim();
   //    const name = event.target.name;
   //    props.setvalueContrato(prev => ({ ...prev, [name]: newVal }));
   // }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }
   return (
      <Protected>
         <Container maxWidth='xl'>
            <form style={{ margin: '8px' }}>
               <Typography variant="h6" gutterBottom mb={3} className="tittle">
                  MANTENEDOR DE LISTA DE PRECIOS
               </Typography>
               <Grid container mt={2} mb={2}>
                  <Typography variant="caption" gutterBottom mb={3} className="tittle">
                     Incorpore sus Listas de Precios, en el siguiente Formulario.
                  </Typography>

               </Grid>



               <Grid container spacing={3} className={`e-mb-lg`} >
                  <Grid item xs={12} sm={3}>
                     <Input
                        ref={ref => inputRefs.current[2] = ref}
                        prependInnerAdornment={
                           <InputAdornment position="end">
                              <AbcIcon />

                           </InputAdornment>
                        }
                        name="valor_compra"
                        placeholder="Nombre Lista"
                        //value={data.nombre_rz}
                        // value={props.valueContrato.valor_compra}
                        onChange={handleInput}
                        //onBlur={handleInputBlur}
                        // backgroundColor="#F3F3F3"
                        // rules={rules.valor_compra}
                        disableElevation
                        validateOnBlur
                        dense
                        disabled={isDisabled}
                        className='borderInput'
                     />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                     <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={estado}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Estado" />}
                     />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                     <FormControl sx={{ display: 'inline' }}>
                        <FormControlLabel control={<Checkbox defaultChecked />} label="Recordar Precios" />
                        <FormControlLabel control={<Checkbox />} label="Woopy" />
                     </FormControl>
                  </Grid>


                  <Grid item container
                     xs={12}
                     justifyContent='right'
                  >
                     <Grid item>
                        <Button
                           type='submit'
                           variant='contained'
                           onClick={handleSubmit}
                           disabled={loading}
                           disableElevation
                           className='btn_save'
                        >
                           <span>Agregar</span>
                        </Button>

                     </Grid>
                  </Grid>
               </Grid>
            </form>

            <div className='bg-tittle'>
               <Typography
                  mt={3}
                  variant="h6"
                  align='center'
                  className='tittle-text'
               >Listas de Precio ingresadas
               </Typography>
            </div>
         </Container>

         <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer component={Paper}>
               <Table aria-label="simple table">
                  <TableHead>
                     <TableRow>
                        <TableCell>Id Lista</TableCell>
                        <TableCell>Nombre Lista</TableCell>
                        <TableCell>Estado</TableCell>
                        <TableCell>Opción</TableCell>
                        <TableCell>Seleccionar producto a Lista de Precio</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {rowsListado.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        return (
                           <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                              {columns.map((column) => {
                                 const value = row[column.id];
                                 return (
                                    <TableCell key={column.id} align={column.align}>
                                       {column.format && typeof value === 'number'
                                          ? column.format(value)
                                          : value}
                                    </TableCell>
                                 );
                              })}
                              <TableCell>
                                 <FormControlLabel control={<Checkbox defaultChecked />} label="" />
                              </TableCell>

                              <TableCell >

                                 <IconButton
                                    aria-label="delete"
                                    // onClick={() => handleDelete(row.id)}
                                    className='btn_delete'
                                    onClick={() => handleOpen()}
                                 // disabled={loading}
                                 >
                                    <EditIcon />
                                 </IconButton>

                              </TableCell>
                              <TableCell>
                                 <div className='sepaEle' style={{ display: "flex", justifyContent: "space-evenly" }}>
                                    <Tooltip title='Sin Segmentación'>
                                       <IconButton aria-label="delete" component={Link} to={ROUTE_GC_PRICE_LIST_SEGMENTATION}>
                                          <CompressIcon />
                                       </IconButton>
                                    </Tooltip>
                                    <Tooltip title='Canal Venta'>
                                       <IconButton aria-label="delete" component={Link} to={ROUTE_GC_PRICE_LIST_CHANEL}>
                                          <RoundaboutRightIcon />
                                       </IconButton>
                                    </Tooltip>
                                    <Tooltip title='Agregar al Carrito'>
                                       <IconButton aria-label="delete" component={Link} to={ROUTE_GC_PRICE_LIST_CART}>
                                          <AddShoppingCartIcon />
                                       </IconButton>
                                    </Tooltip>

                                 </div>
                              </TableCell>
                           </TableRow>
                        );
                     })}
                  </TableBody>
               </Table>
            </TableContainer>
            <TablePagination
               rowsPerPageOptions={[10, 25, 100]}
               component="div"
               count={rowsListado.length}
               rowsPerPage={rowsPerPage}
               page={page}
               onPageChange={handleChangePage}
               onRowsPerPageChange={handleChangeRowsPerPage}
            />
         </Paper>


      </Protected>
   );
}