import { Protected } from "@/components/layout/Protected";
import { Box, Grid, Container, Typography, IconButton, SelectChangeEvent, Autocomplete, Button,} from '@mui/material'
import {  useState } from "react";
import {Props} from "./RequestBook.type"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import './RequestBook_styles.css'
import TextField from '@mui/material/TextField';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import Modal from '@mui/material/Modal';
import { DataModal } from "./DataModal";
import { DataRut } from "./DataRut";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import SearchIcon from '@mui/icons-material/Search';
import { LoadingButton } from "@mui/lab";

interface Column {
   id: 'numero' | 'fecha' | 'numeroPedido'|'rut' | 'razon'|'tipo'| 'folio'|'monto' ;
   label: string;
   minWidth?: number;
   align?: 'right';
   format?: (value: number) => string;
 }

const columns:readonly Column[] = [
      {id:'numero', label:'Numero', minWidth:10},
      {id:'fecha', label:'Fecha', minWidth:20},
      {id:"numeroPedido", label:'Numero Pedido', minWidth:20},
      {id:'rut', label:'Rut', minWidth:50},
      {id:'razon', label:'Razon', minWidth:50},
      {id:'tipo', label:'Tipo', minWidth:50},
      {id:'folio', label:'Folio', minWidth:50},
      {id:'monto', label: 'Monto', minWidth:50}
   ];

interface Data {
   numero: number,
   fecha: string,
   numeroPedido: number,
   rut:string,
   razon:string,
   tipo:number,
   folio:number,
   monto:string
}

function createData(
   numero: number,
   fecha: string,
   numeroPedido: number,
   rut:string,
   razon:string,
   tipo:number,
   folio:number,
   monto:string
   ):Data {
   return { numero, fecha, numeroPedido, rut, razon, tipo, folio, monto  };
}

const rowsData = [
   createData(1,"25/02/2022",18392,'1234567-8',"A.C BALDRICH CHILE", 32, 124,"$ 60154" ),
   createData(2,"15/02/2022",18392,'1234567-8',"A.C BALDRICH CHILE", 32, 124,"$ 60154" ),
   createData(3,"17/03/2022",18392,'1234567-8',"A.C BALDRICH CHILE", 32, 124,"$ 60154" ),
   createData(4,"12/04/2022",18392,'1234567-8',"A.C BALDRICH CHILE", 32, 124,"$ 60154" ),
   createData(5,"13/05/2022",18392,'1234567-8',"A.C BALDRICH CHILE", 32, 124,"$ 60154" ),
   createData(6,"12/06/2022",18392,'1234567-8',"A.C BALDRICH CHILE", 32, 124,"$ 60154" ),
   createData(7,"12/07/2022",18392,'1234567-8',"A.C BALDRICH CHILE", 32, 124,"$ 60154" ),
   createData(8,"15/08/2022",18392,'1234567-8',"A.C BALDRICH CHILE", 32, 124,"$ 60154" ),

]


export const RequestBook:React.FC<Props> = (props: Props): JSX.Element => {
   const [page , setPage]= useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   // const [value, setValue] = useState('');
   const [value, setValue] = useState<Date | null>(new Date());
   const [loading, setLoading] = useState(false);
   const [pais, setPais] = useState('');
   const [estado, setEstado]= useState('');
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);
   const [open, setOpen] = useState(false);
   const [openRut, setOpenRut] = useState(false);
   const handleOpenRut = () => setOpenRut(true);
   const handleCloseRut = () => setOpenRut(false);

    const handleChangePais = (event: SelectChangeEvent) => {
      setPais(event.target.value);
    };
    const handleChangeEstado = (event: SelectChangeEvent) => {
      setEstado(event.target.value);
    };
   function handleClick() {
     setLoading(true);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const paises= [
      { value:1, label:'Chile'},
      { value:2, label:'Argentina'},
      { value:3, label:'Brasil'},
      { value:4, label:'Colombia'},
      { value:5, label:'Peru'},
      { value:6, label:'Venezuela'},
   ];
   const estados= [
      { value: '1', label: 'Activo' },
      { value: '2', label: 'Bloqueo Comercial' },
   ]

   const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 720,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

    const motivosBloqueos = [
      { value: '1', label: 'Cliente Activo' },
      { value: '2', label: 'Cliente con Deuda' },
      { value: '3', label: 'Cliente Inactivo' },
      { value: '4', label: 'Cliente Quiebra' },
      { value: '5', label: 'Morosos' },
      { value: '6', label: 'Problematicos' },
    ]
    return (
        <Protected>
        <Container maxWidth='xl'>
           <Grid container spacing={3}>
               <Grid item xs={12} md={6} >
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        LIBRO PEDIDO
                     </Typography>
                  </Box>
                  <Box>
                     {/* <Typography variant='subtitle2'  gutterBottom >
                     Resumen de mes de 1 - 2022
                     </Typography> */}
                  </Box>
                  
               </Grid>
                     
               <Grid item xs={12} md={6} className="btn-download">
                  <Grid container spacing={3}>
                     <Grid item xs={12} md={6}>
                        <Box pb={2}>
                           <LoadingButton
                              color="warning"
                              // onClick={handleOpenModal}
                              // loading={loading}
                              // loadingPosition="start"
                              // startIcon={<FileDownloadIcon />}
                              variant="contained"
                              className="btn-download2"
                              >
                              EXPORTAR EXCEL
                           </LoadingButton>
                        </Box>
                     </Grid>
                     <Grid item xs={12} md={6}>
                        <Box pb={2}>
                           <LoadingButton
                              color="warning"
                              // onClick={handleOpenModal}
                              // loading={loading}
                              // loadingPosition="start"
                              // startIcon={<FileDownloadIcon />}
                              variant="contained"
                              className="btn-download2"
                              >
                              EXPORTAR PDF
                           </LoadingButton>
                        </Box>
                     </Grid>
                  </Grid>
               </Grid>


               <Grid item xs={12} sm={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Stack spacing={3}>
                                 <DatePicker
                                       views={['year', 'month']}
                                       label="Periodo"
                                       minDate={new Date('2012-03-01')}
                                       maxDate={new Date('2023-06-01')}
                                       value={value}
                                       onChange={(newValue) => {
                                          setValue(newValue);
                                       }}
                                       renderInput={(params) => <TextField  {...params} helperText={null} />}
                                       />
                           </Stack>
                  </LocalizationProvider>
               </Grid>

               <Grid item xs={12} sm={3}>
                  <TextField
                     label="Numero pedido inicio"
                     // defaultValue="12345678-9"
                     size="small"
                     fullWidth
                  />
               </Grid>

               <Grid item xs={12} sm={3}>
                  <TextField
                     label="Numero pedido fin"
                     // defaultValue="12345678-9"
                     size="small"
                     fullWidth
                  />
               </Grid>
            
               <Grid item container
                     xs={12}
                     mt={2}
                  justifyContent='right'
                  >
                        <Grid item>
                           <Button
                              type='submit'
                              variant='contained'
                              // onClick={handleSubmit}
                              // disabled={loading}
                              disableElevation
                              className='btn_save'
                           >
                                    <span>Agregar</span>
                           </Button>
                        </Grid>
               </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom mb={3} className="tittle2" align="center" mt={2}>
               LISTADO
            </Typography>

            <Paper sx={{ width: '100%', overflow: 'hidden'}}>
               <TableContainer sx={{maxHeight:440}}>
                  <Table stickyHeader aria-label="sticky table">
                     <TableHead>
                        <TableRow>
                           {columns.map((column) => (
                              <TableCell
                              
                                 key={column.id}
                                 align={column.align}
                                 style={{ minWidth: column.minWidth }}
                              >
                                 {column.label}
                              </TableCell>
                           ))}
                           <TableCell>
                              Historial
                           </TableCell>

                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                           return (
                              <TableRow hover role="checkbox" tabIndex={-1} key={row.numero}>
                                 {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                       <TableCell key={column.id} align={column.align}>
                                           {column.format && typeof value === 'number'
                                             ? column.format(value)
                                             : value}
                                       </TableCell>
                                    );
                                 })}
                                 <TableCell>
                                       <IconButton 
                                          >
                                             <SearchIcon 
                                          onClick={handleOpen}
                                       />
                                       </IconButton>
                                 </TableCell>
                              </TableRow>
                           );
                        })}
                       
                     </TableBody>   
                  </Table>  
               </TableContainer>
               <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rowsData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />
            </Paper>

         </Container>
      
     </Protected>
    );
   };

