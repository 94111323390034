import { Protected } from "@/components/layout/Protected";
import { Box, Stepper, InputAdornment, IconButton, Step, StepLabel, Button, CircularProgress, Grid, CssBaseline, Container, StepIconProps, Typography, Tooltip, FormControl } from '@mui/material'
import { useEffect, useState } from "react";
import {Props} from "./PaymentWorker.type"
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import InfoIcon from '@mui/icons-material/Info';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import Modal from '@mui/material/Modal';
import { FormDetall } from "./FormDetall/FormDetall";
import LanguageIcon from '@mui/icons-material/Language';
import InventoryIcon from '@mui/icons-material/Inventory';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PersonIcon from '@mui/icons-material/Person';
import { FormPay } from "./FormPay";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { AccountBalance, AccountBalanceWallet } from "@mui/icons-material";
import LooksOneIcon from '@mui/icons-material/LooksOne';
import Link from '@mui/material/Link';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';

const steps= [
{name:'Detalle de Documentos', icon:null},
{name:'Ingreso de Pagos', icon:null},
]



export const PaymentWorkerStepper:React.FC<Props> = (props: Props): JSX.Element => {
    
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [error, setError] = useState(null);
    const [page , setPage]= useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [openColorTalla, setOpenColorTalla] = useState(false);
    const [openProvider, setOpenProvider] = useState(false);
    const [openSegmento, setOpenSegmento] = useState(false);

    const [loading, setLoading] = useState(false);

    const handleOpenColorTalla = () => setOpenColorTalla(true);
    const handleCloseColorTalla = () => setOpenColorTalla(false);

    const handleOpenProvider = () => setOpenProvider(true);
    const handleCloseProvider = () => setOpenProvider(false);

    const handleOpenSegmento = () => setOpenSegmento(true);
    const handleCloseSegmento = () => setOpenSegmento(false);
    
    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };
    const [valueDetall,setValueDetall]=useState({
      nombre_producto:'',
      codigo_producto:'',
      descripcion:'',
      linea_producto:'',
      codigo_proveedor:'',
      codigo_barra:'',
      familia_producto:'',
      subfamilia_producto:'',
      tipo_producto:'',
      impuesto_producto:'',
      estado_producto:'',
    });

    const [valueWeb,setValueWeb]=useState({
        estado_web:'',
        destacar_producto:'',
        marcar_nuevo:'',
        ruta_video:'',
        file_adjunto:'',
        fecha_liminte:'',
        ficha_producto:'',
    });

    const [valueProvider,setValueProvider]=useState({


    });

    const [valuePrice,setValuePrice]=useState({
      margen:'',
      precio_producto:'',
      precio_alternativo:'',
      costo:'',
      precio_oferta:'',
      fecha_oferta:'',
   });

   const [valueEspecial,setValueEspecial]=useState({
      nombre_producto_ingles:'',
      segmentacion:'',
      markup:'',
      origen:'',
      destino:'',
      marca:'',
   });
    const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 720,
      bgcolor: 'background.paper',
      // border: '2px solid #000',
      boxShadow: 24,
      p: 2,
      borderRadius: '20px'
   };

    useEffect(() => {
        if(props.entity !== undefined){
            setValueDetall(props.entity);
            setValueWeb(props.entity);
            setValuePrice(props.entity);
            setValueProvider(props.entity);
            setValueEspecial(props.entity);
        }
    }, []);

    const isStepSkipped = (step) => {
        return skipped.has(step);
     };

     function handleClick() {
      setLoading(true);
    }
 
     const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
           newSkipped = new Set(newSkipped.values());
           newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
     };
  
     const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
     };

     const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
          top: 22,
        },
        [`&.${stepConnectorClasses.active}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
              'linear-gradient( 95deg,#19c1d1 0%,#1976D2 50%,#1996d1 100%)',
          },
        },
        [`&.${stepConnectorClasses.completed}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
              'linear-gradient( 95deg,#19c1d1 0%,#1976D2 50%,#1996d1 100%)',
          },
        },
        [`& .${stepConnectorClasses.line}`]: {
          height: 3,
          border: 0,
          backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
          borderRadius: 1,
        },
      }));
  
      const ColorlibStepIconRoot = styled('div')<{
        ownerState: { completed?: boolean; active?: boolean };
      }>(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
          backgroundImage:
            'linear-gradient( 136deg, #1976D2 0%, #1996d1 50%, #19c1d1 100%)',
          boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        }),
        ...(ownerState.completed && {
          backgroundImage:
            'linear-gradient( 136deg, #1976D2 0%, #1996d1 50%, #19c1d1 100%)',
        }),
      }));


      function ColorlibStepIcon(props: StepIconProps) {
        const { active, completed, className } = props;
  
        const icons: { [index: string]: React.ReactElement } = {
          1: <AssignmentIcon />,
          2: <LanguageIcon />,
          3: <MonetizationOnIcon />,
        };
  
        return (
          <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
          </ColorlibStepIconRoot>
        );
      }

      const mostrarFormulario = () => {
       if (activeStep === 0) {
         return (
            <FormDetall
            handleNext={handleNext}
            valueEntry={valueDetall}
            setvalueEntry={setValueDetall}
         />
         )
      }

      else if (activeStep === 1) {
         return (
            <FormPay
            handleNext={handleNext}
            valueEntry={valueDetall}
            setvalueEntry={setValueDetall}
         />
         )
      }
   
     }
    return (
        <Protected>
        <Container maxWidth='xl'>
          
           <Grid container flexDirection='row' spacing={2}>
                 <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                       <Box>
                          <Box sx={{ mb: 2 }}>
                             <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
                                {steps.map((label, index) => {
                                   return (
                                      <Step key={index}>
                                         <StepLabel StepIconComponent={ColorlibStepIcon} error={error === `Usuario${index}noencontrado` ? true : false}>{label.name}</StepLabel>
                                      </Step>
                                   );
                                })}
                             </Stepper>
                          </Box>
                          {activeStep === steps.length ? (
                             
                             <Grid container spacing={3}>
                                 <Grid item xs={12} md={12} >
                                 
                                 <Box>
                                    <Typography variant='h6' component='h1' gutterBottom className="tittle">
                                       CONFIRMACION DE PAGO
                                    </Typography>
                                 </Box>

                                 <Typography  variant="caption" display="block" gutterBottom mt={1} mb={3}>
                                 Seleccione las opciones disponibles
                                 </Typography>

                                 <Grid container style={{backgroundColor:'#f5b658' , borderRadius:"5px"}}>
                                    <Grid item p={1} xs={12} md={4}>

                                       <Typography  variant="h6" display="block" gutterBottom mt={1}>
                                       CONFIRMACION
                                       </Typography>

                                    </Grid>
                                    <Grid item p={1} xs={12} md={8}  mt={2}>
                                       <Typography  variant="caption" display="block" gutterBottom>
                                          Pago Realizado Correctamente, Comprobante de pago generado NUMERO 1784.
                                       </Typography>

                                    </Grid>
                                 </Grid>
                              </Grid>


                              <Grid item xs={12} md={3}>
                                 <Grid container spacing={3} style={{background:''}}>
                                    <Grid item xs={2} md={3}>
                                       <IconButton aria-label="opcion1" >
                                          <LooksOneIcon  style={{fontSize:"50px" ,color:'#16556b'}}/>
                                       </IconButton>
                                    </Grid>
                                    <Grid item xs={10} md={9} >
                                       <Typography  variant="caption" display="block" gutterBottom mt={1}>
                                          Opcion 1- Imprimir!
                                          {/* Imprimir Comprobante                                        */}
                                       </Typography>
                                       <Link href="#" color="inherit">
                                          <Typography  variant="subtitle2" display="block" gutterBottom mt={1} style={{color:'#16556b'}}>
                                          IMPRIMIR COMPROBANTE
                                          {/* Imprimir Comprobante                                        */}
                                       </Typography>
                                       </Link>
                                    </Grid>
                                 </Grid>
                              </Grid>
                              <Grid item xs={12} md={3}>
                                 <Grid container spacing={3} style={{background:''}}>
                                    <Grid item xs={2} md={3}>
                                       <IconButton aria-label="opcion1" >
                                          <LooksTwoIcon  style={{fontSize:"50px" ,color:'#16556b'}}/>
                                       </IconButton>
                                    </Grid>
                                    <Grid item xs={10} md={9} >
                                       <Typography  variant="caption" display="block" gutterBottom mt={1}>
                                          Opcion 2                                          {/* Imprimir Comprobante                                        */}
                                       </Typography>
                                       <Link href="#" color="inherit">
                                          <Typography  variant="subtitle2" display="block" gutterBottom mt={1} style={{color:'#16556b'}}>
                                          MISMO PROVEEDOR
                                          {/* Imprimir Comprobante                                        */}
                                       </Typography>
                                       </Link>
                                    </Grid>
                                 </Grid>
                              </Grid>
                              <Grid item xs={12} md={3}>
                                 <Grid container spacing={3} style={{background:''}}>
                                    <Grid item xs={2} md={3}>
                                       <IconButton aria-label="opcion1" >
                                          <Looks3Icon  style={{fontSize:"50px" ,color:'#16556b'}}/>
                                       </IconButton>
                                    </Grid>
                                    <Grid item xs={10} md={9} >
                                       <Typography  variant="caption" display="block" gutterBottom mt={1}>
                                          Opcion 3 - Cancelar!
                                          {/* Imprimir Comprobante                                        */}
                                       </Typography>
                                       <Link href="#" color="inherit">
                                          <Typography  variant="subtitle2" display="block" gutterBottom mt={1} style={{color:'#16556b'}}>
                                          NUEVO PROVEEDOR
                                          {/* Imprimir Comprobante                                        */}
                                       </Typography>
                                       </Link>
                                    </Grid>
                                 </Grid>
                              </Grid>

                              <Grid item xs={12} md={3}>
                                 <Grid container spacing={3} style={{background:''}}>
                                    <Grid item xs={2} md={3}>
                                       <IconButton aria-label="opcion1" >
                                          <Looks4Icon  style={{fontSize:"50px" ,color:'#16556b'}}/>
                                       </IconButton>
                                    </Grid>
                                    <Grid item xs={10} md={9} >
                                       <Typography  variant="caption" display="block" gutterBottom mt={1}>
                                          Opcion 4- Ingresar!
                                          {/* Imprimir Comprobante                                        */}
                                       </Typography>
                                       <Link href="#" color="inherit">
                                          <Typography  variant="subtitle2" display="block" gutterBottom mt={1} style={{color:'#16556b'}}>
                                          NUEVO DOC
                                          {/* Imprimir Comprobante                                        */}
                                       </Typography>
                                       </Link>
                                    </Grid>
                                 </Grid>
                              </Grid>
                           </Grid>
                             //VALIDAR QUE EL ENVIO DE DATOS
                             // history.push(ROUTE_ACCOUNT)
                          ) : (
                             mostrarFormulario()
                          )}
                       </Box>
                       
                    </Grid>
                 </Grid>
           </Grid>
           

         </Container>

     </Protected>

    );
};

